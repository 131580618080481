define("plutof/components/filerepository/upload-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Headerless>
      <Tables::List
          @records={{@uploader.uploads}}
          as |Field upload|
      >
          <Field @label={{i18n-t "FileRepository.colFileName"}}>
              {{#if upload.finished}}
                  <LinkTo @route="filerepository.view" @model={{upload.record.id}}>
                      {{upload.file.name}}
                  </LinkTo>
              {{else}}
                  {{upload.file.name}}
              {{/if}}
  
              {{#if upload.working}}
                  <span class={{icon "loading"}}></span>
              {{/if}}
          </Field>
  
          <Field @label={{i18n-t "FileRepository.colProgress"}}>
              <ProgressBar @progress={{upload.progress}} />
          </Field>
      </Tables::List>
  
      {{#unless (is-equal @uploader.uploads.length @localFilesCount)}}
          <span class={{icon "loading"}}></span>
      {{/unless}}
  </PlutofPanel::Headerless>
  
  */
  {
    "id": "7aT3ltJg",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@records\"],[[30,1,[\"uploads\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"FileRepository.colFileName\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"finished\"]],[[[1,\"                \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"filerepository.view\",[30,3,[\"record\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,3,[\"file\",\"name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[30,3,[\"file\",\"name\"]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,3,[\"working\"]],[[[1,\"                \"],[10,1],[15,0,[28,[37,5],[\"loading\"],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"FileRepository.colProgress\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@progress\"],[[30,3,[\"progress\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\\n\"],[41,[51,[28,[37,8],[[30,1,[\"uploads\",\"length\"]],[30,4]],null]],[[[1,\"        \"],[10,1],[15,0,[28,[37,5],[\"loading\"],null]],[12],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"@uploader\",\"Field\",\"upload\",\"@localFilesCount\"],false,[\"plutof-panel/headerless\",\"tables/list\",\"i18n-t\",\"if\",\"link-to\",\"icon\",\"progress-bar\",\"unless\",\"is-equal\"]]",
    "moduleName": "plutof/components/filerepository/upload-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});