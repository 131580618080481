define("plutof/components/agent/-auto-complete-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="person-autocomplete">
      {{#if @result.full.valid_name}}
          <i>
              {{!shrinkText name}}
              {{hl-subs @result.value @context.query}}
          </i>
          ({{@result.full.valid_name}})
      {{else}}
          {{!shrinkText name}}
          {{hl-subs @result.value @context.query}}
      {{/if}}
  
      <i>
          {{#if @result.full.user_username}}
              {{#if @result.full.is_ambiguous}}
                  <span class="icon-person"></span>
              {{else}}
                  <span class="icon-user"></span>
              {{/if}}
              <span class="username-highlight">{{@result.full.user_username}}</span>
          {{else}}
              {{#if @result.full.is_collection}}
                  <span class="fas fa-archive"></span>
              {{/if}}
  
              {{#unless (is-equal @result.full.owner_username 'plutof')}}
                  <span class="username-highlight">{{@result.full.owner_username}}</span>
              {{/unless}}
          {{/if}}
      </i>
  </span>
  
  */
  {
    "id": "7IsblQcF",
    "block": "[[[10,1],[14,0,\"person-autocomplete\"],[12],[1,\"\\n\"],[41,[30,1,[\"full\",\"valid_name\"]],[[[1,\"        \"],[10,\"i\"],[12],[1,\"\\n\"],[1,\"            \"],[1,[28,[35,1],[[30,1,[\"value\"]],[30,2,[\"query\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        (\"],[1,[30,1,[\"full\",\"valid_name\"]]],[1,\")\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[[30,1,[\"value\"]],[30,2,[\"query\"]]],null]],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,\"i\"],[12],[1,\"\\n\"],[41,[30,1,[\"full\",\"user_username\"]],[[[41,[30,1,[\"full\",\"is_ambiguous\"]],[[[1,\"                \"],[10,1],[14,0,\"icon-person\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[14,0,\"icon-user\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"            \"],[10,1],[14,0,\"username-highlight\"],[12],[1,[30,1,[\"full\",\"user_username\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"full\",\"is_collection\"]],[[[1,\"                \"],[10,1],[14,0,\"fas fa-archive\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[28,[37,3],[[30,1,[\"full\",\"owner_username\"]],\"plutof\"],null]],[[[1,\"                \"],[10,1],[14,0,\"username-highlight\"],[12],[1,[30,1,[\"full\",\"owner_username\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@result\",\"@context\"],false,[\"if\",\"hl-subs\",\"unless\",\"is-equal\"]]",
    "moduleName": "plutof/components/agent/-auto-complete-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});