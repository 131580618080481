define("plutof/components/information/content/analysis/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::CollapsedSection @label="Programs">
      <Information::MarkdownReader @path="analysis/programs" />
  </Information::CollapsedSection>
  
  <Information::CollapsedSection @label="Matching sequences to Species Hypotheses">
      <Information::Content::Analysis::ShMatching />
  </Information::CollapsedSection>
  
  */
  {
    "id": "CKDjUd4C",
    "block": "[[[8,[39,0],null,[[\"@label\"],[\"Programs\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@path\"],[\"analysis/programs\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@label\"],[\"Matching sequences to Species Hypotheses\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"information/collapsed-section\",\"information/markdown-reader\",\"information/content/analysis/sh-matching\"]]",
    "moduleName": "plutof/components/information/content/analysis/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});