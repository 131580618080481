define("plutof/components/sample/measurement-fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::MeasurementView::Wrapper
      @mainform={{@mainform}}
      @object={{@object}}
      as |measurements empty expand|
  >
      {{#each measurements as |item|}}
          <Measurements::MeasurementView::Single
              @measurement={{item}}
              @expand={{unless item.valueIsEmpty (fn expand item)}} />
      {{/each}}
  </Measurements::MeasurementView::Wrapper>
  
  */
  {
    "id": "cqD1xvf0",
    "block": "[[[8,[39,0],null,[[\"@mainform\",\"@object\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@measurement\",\"@expand\"],[[30,6],[52,[51,[30,6,[\"valueIsEmpty\"]]],[28,[37,5],[[30,5],[30,6]],null]]]],null],[1,\"\\n\"]],[6]],null]],[3,4,5]]]]],[1,\"\\n\"]],[\"@mainform\",\"@object\",\"measurements\",\"empty\",\"expand\",\"item\"],false,[\"measurements/measurement-view/wrapper\",\"each\",\"-track-array\",\"measurements/measurement-view/single\",\"unless\",\"fn\"]]",
    "moduleName": "plutof/components/sample/measurement-fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});