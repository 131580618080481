define("plutof/components/collection/view/annotations/record", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{this.route}} @model={{@record.id}}>
      {{@record.name}}
  </LinkTo>
  
  */
  {
    "id": "kSOdjWSO",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[30,0,[\"route\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@record\"],false,[\"link-to\"]]",
    "moduleName": "plutof/components/collection/view/annotations/record.hbs",
    "isStrictMode": false
  });
  class CollectionAnnotationsRecordField extends _component2.default {
    get route() {
      return _modules.default[this.args.record.content_type].routes.view;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionAnnotationsRecordField);
});