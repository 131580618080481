define("plutof/components/area/geometry/form", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "ol/Feature", "ol/geom/MultiPolygon", "ol/geom/Polygon", "ol/format", "ol/source", "plutof/utils/errors", "plutof/utils/file-serialization", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop, _service, _utils, _Feature, _MultiPolygon, _Polygon, _format, _source, _errors, _fileSerialization, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORMATS = void 0;
  _exports.convertGeometry = convertGeometry;
  _exports.default = void 0;
  _exports.downloadGeometry = downloadGeometry;
  _exports.importFromFile = importFromFile;
  _exports.serializeFileContent = serializeFileContent;
  _exports.validateGeometry = validateGeometry;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::RadioGroup
      @active={{this.activeFormat}}
      @activate={{this.switchFormat}}
      as |radioGroup|
  >
      {{#each-in this.formats as |key format|}}
          <radioGroup.choice
              @name={{format.format}}
              @disable={{not this.inputIsValid}}
              data-test={{format.name}}
          >
              {{format.name}}
          </radioGroup.choice>
      {{/each-in}}
  </Common::RadioGroup>
  
  <Common::LocalLabel @label="Map.import.geometry" />
  
  <div class="{{if (not this.inputIsValid) ' has-error'}}">
      <FlexibleTextarea
          {{on "input" this.inputChanged}}
          @value={{this.geometry}}
          @placeholder={{i18n-t "Map.import.placeholder"}}
          class="form-control" />
  </div>
  
  <Common::LocalLabel
      @label="Map.import.file"
      @tooltip="Map.import.fileTooltip" />
  
  <div class="inline-flex">
      <Filerepository::AutoComplete
          @value={{this.file}}
          @update={{this.fileChanged}}
          @filters={{hash access="edit"}} />
  
      &nbsp;
  
      <ToolTip @tooltip={{i18n-t "Map.import.uploadTooltip"}}>
          <FileSelector
              @multiple={{false}}
              @accept={{this.acceptedFileExtensions}}
              @selected={{this.fileSelected}} />
      </ToolTip>
  </div>
  
  */
  {
    "id": "CKvdULaC",
    "block": "[[[8,[39,0],null,[[\"@active\",\"@activate\"],[[30,0,[\"activeFormat\"]],[30,0,[\"switchFormat\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[30,0,[\"formats\"]]],null],null,[[[1,\"        \"],[8,[30,1,[\"choice\"]],[[16,\"data-test\",[30,2,[\"name\"]]]],[[\"@name\",\"@disable\"],[[30,2,[\"format\"]],[28,[37,3],[[30,0,[\"inputIsValid\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2,3]],null]],[1]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@label\"],[\"Map.import.geometry\"]],null],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[52,[28,[37,3],[[30,0,[\"inputIsValid\"]]],null],\" has-error\"]]]],[12],[1,\"\\n    \"],[8,[39,6],[[24,0,\"form-control\"],[4,[38,8],[\"input\",[30,0,[\"inputChanged\"]]],null]],[[\"@value\",\"@placeholder\"],[[30,0,[\"geometry\"]],[28,[37,7],[\"Map.import.placeholder\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@label\",\"@tooltip\"],[\"Map.import.file\",\"Map.import.fileTooltip\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"inline-flex\"],[12],[1,\"\\n    \"],[8,[39,9],null,[[\"@value\",\"@update\",\"@filters\"],[[30,0,[\"file\"]],[30,0,[\"fileChanged\"]],[28,[37,10],null,[[\"access\"],[\"edit\"]]]]],null],[1,\"\\n\\n     \\n\\n    \"],[8,[39,11],null,[[\"@tooltip\"],[[28,[37,7],[\"Map.import.uploadTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,12],null,[[\"@multiple\",\"@accept\",\"@selected\"],[false,[30,0,[\"acceptedFileExtensions\"]],[30,0,[\"fileSelected\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"radioGroup\",\"format\",\"key\"],false,[\"common/radio-group\",\"each\",\"-each-in\",\"not\",\"common/local-label\",\"if\",\"flexible-textarea\",\"i18n-t\",\"on\",\"filerepository/auto-complete\",\"hash\",\"tool-tip\",\"file-selector\"]]",
    "moduleName": "plutof/components/area/geometry/form.hbs",
    "isStrictMode": false
  });
  const i18n = (0, _i18n.getI18n)();
  const FORMATS = _exports.FORMATS = {
    json: {
      name: 'GeoJSON',
      format: new _format.GeoJSON(),
      extension: 'geojson',
      acceptedExtensions: '.json,.geojson'
    },
    wkt: {
      name: 'WKT',
      format: new _format.WKT(),
      extension: 'wkt',
      acceptedExtensions: '.wkt,.txt'
    },
    kml: {
      name: 'KML',
      format: new _format.KML(),
      extension: 'kml',
      acceptedExtensions: '.kml,.xml'
    }
  };
  const TYPE_FORMAT_MAP = {
    text: 'wkt',
    xml: 'kml',
    json: 'json'
  };
  function downloadGeometry(geometry, name, format) {
    const extension = FORMATS[TYPE_FORMAT_MAP[format.getType()]].extension;
    return (0, _fileSerialization.saveFile)([geometry], `${name}.${extension}`);
  }
  function wktToJson(geom) {
    const features = FORMATS.wkt.format.readFeatures(geom);

    // Multiple features.
    if (features.length > 1) {
      return FORMATS.json.format.writeFeatures(features);
      // Multiple features in geometry collection.
    } else if (features[0].getGeometry().getType() === 'GeometryCollection') {
      const geoms = features[0].getGeometry().getGeometries();
      const newFeatures = geoms.map(geom => new _Feature.default({
        geometry: geom
      }));
      return FORMATS.json.format.writeFeatures(newFeatures);
      // Actually a single feature.
    } else {
      return FORMATS.json.format.writeFeature(features[0]);
    }
  }
  function jsonToWkt(geom) {
    const features = FORMATS.json.format.readFeatures(geom);
    if (features.length > 1) {
      return FORMATS.wkt.format.writeFeatures(features);
    } else {
      return FORMATS.wkt.format.writeFeature(features[0]);
    }
  }
  function kmlToJson(geom) {
    const features = FORMATS.kml.format.readFeatures(geom);
    features.forEach(feature => {
      feature.setGeometry(removeThirdCoordinate(feature.getGeometry()));
    });
    if (features.length > 1) {
      return FORMATS.json.format.writeFeatures(features);
    } else {
      return FORMATS.json.format.writeFeature(features[0]);
    }
  }
  function jsonToKml(geom) {
    const features = FORMATS.json.format.readFeatures(geom);
    const vectorSource = new _source.Vector({
      features: features
    });
    return FORMATS.kml.format.writeFeatures(vectorSource.getFeatures(), {
      featureProjection: 'EPSG:4326'
    });
  }
  function removeThirdCoordinate(geometry) {
    function removeThird(coordinates) {
      return coordinates.splice(0, 2);
    }
    const type = geometry.getType();
    if (type === 'GeometryCollection') {
      return geometry.getGeometries().map(removeThirdCoordinate);
    }
    if (type === 'MultiPolygon') {
      const newPolygons = geometry.getPolygons().map(polygon => {
        const coordinates = polygon.getCoordinates();
        return new _Polygon.default(coordinates.map(c => c.map(removeThird)));
      });
      return new _MultiPolygon.default(newPolygons);
    }
    let coordinates;
    if (type === 'Polygon') {
      coordinates = geometry.getCoordinates().map(c => c.map(removeThird));
    }
    if (type === 'Point') {
      coordinates = removeThird(geometry.getCoordinates());
    }
    if (type === 'LineString') {
      coordinates = geometry.getCoordinates().map(removeThird);
    }
    geometry.setCoordinates(coordinates);
    return geometry;
  }
  function convertGeometry(geometry, fromFormat, toFormat, area) {
    if ((0, _utils.isEmpty)(geometry)) {
      return null;
    }
    const from = fromFormat.getType();
    const to = toFormat.getType();

    // Assume json.
    let json = geometry;
    if (from === 'text') {
      json = wktToJson(geometry);
    }
    if (from === 'xml') {
      json = kmlToJson(geometry);
    }
    if (to === 'text') {
      return jsonToWkt(json);
    }
    if (to === 'xml') {
      return jsonToKml(json);
    }
    if (area) {
      const geojson = JSON.parse(json);
      geojson.properties = {
        id: area.id,
        name: area.name
      };
      return JSON.stringify(geojson);
    }

    // Assume json.
    return json;
  }
  function serializeFileContent(content, type) {
    if (type === 'text') {
      return content;
    }
    if (type === 'xml') {
      return new XMLSerializer().serializeToString(content);
    }
    return JSON.stringify(content);
  }
  async function importFromFile(store, ajax, file, type) {
    const geoFile = await store.findRecord('filerepository/file', file.id);
    const url = await geoFile.download_links.link;

    // XXX It's uncertain what the limit should be.
    if (geoFile.size > 20000) {
      const message = i18n.t('Map.import.fileSizeError');
      (0, _notifications.displayNotification)('error', message);
      throw (0, _errors.userError)(message);
    }
    return ajax.request(url, {
      dataType: type
    });
  }
  function validateGeometry(geometry, fromFormat, toFormat) {
    if ((0, _utils.isEmpty)(geometry)) {
      return true;
    }
    try {
      return Boolean(convertGeometry(geometry, fromFormat, toFormat));
    } catch (e) {
      return false;
    }
  }
  let GeometryForm = (_dec = (0, _object.computed)('inputIsValid', 'geometry'), _class = class GeometryForm extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "geometry", _descriptor3, this);
      _initializerDefineProperty(this, "activeFormat", _descriptor4, this);
      _initializerDefineProperty(this, "file", _descriptor5, this);
      _initializerDefineProperty(this, "inputIsValid", _descriptor6, this);
      _defineProperty(this, "formats", FORMATS);
      _defineProperty(this, "baseFormat", FORMATS.json.format);
      _defineProperty(this, "backendFormat", FORMATS.wkt.format);
      _defineProperty(this, "initGeomFromArea", async function () {
        const area = await this.args.area;
        const loadedGeometry = convertGeometry(area.geom, this.backendFormat, this.activeFormat, area);
        this.geometry = loadedGeometry;
        this.validateAndUpdate();
      });
      this.initGeomFromArea();
    }
    get baseGeometry() {
      if (this.inputIsValid) {
        return convertGeometry(this.geometry, this.activeFormat, this.backendFormat);
      } else {
        return null;
      }
    }
    get acceptedFileExtensions() {
      return FORMATS[TYPE_FORMAT_MAP[this.activeFormat.getType()]].acceptedExtensions;
    }
    updateGeometry() {
      this.args.geometryChanged(this.baseGeometry, this.geometry, this.activeFormat);
    }
    validateAndUpdate() {
      const geometryIsValid = validateGeometry(this.geometry, this.activeFormat, this.backendFormat);
      this.inputIsValid = geometryIsValid;
      if (geometryIsValid) {
        this.updateGeometry();
      }
      this.args.validityChanged(geometryIsValid);
    }
    inputChanged() {
      (0, _runloop.debounce)(this, this.validateAndUpdate, 333);
    }
    switchFormat(newFormat) {
      this.geometry = convertGeometry(this.geometry, this.activeFormat, newFormat);
      this.activeFormat = newFormat;
      this.file = null;
      this.validateAndUpdate();
    }
    async fileChanged(file) {
      this.file = file;
      if (!file) {
        return;
      }
      const format = this.activeFormat;
      const type = format.getType();
      try {
        const content = await importFromFile(this.store, this.ajax, file, type);
        this.geometry = convertGeometry(serializeFileContent(content, type), format, format);
        this.validateAndUpdate();
      } catch (err) {
        const message = i18n.t('Map.import.fileParseError');
        (0, _notifications.displayNotification)('error', message);
        throw (0, _errors.userError)(message);
      }
    }
    fileSelected(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.geometry = reader.result;
        this.validateAndUpdate();
      };
      reader.readAsText(file);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "geometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeFormat", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return FORMATS.json.format;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "file", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inputIsValid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "baseGeometry", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "baseGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inputChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchFormat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fileChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fileSelected"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeometryForm);
});