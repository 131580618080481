define("plutof/utils/errors", ["exports", "@ember/utils", "ember-ajax/errors", "rollbar", "plutof/config/environment", "plutof/utils/i18n"], function (_exports, _utils, _errors, _rollbar, _environment, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RequestParseError = void 0;
  _exports.configureRollbar = configureRollbar;
  _exports.getErrorMessage = getErrorMessage;
  _exports.processError = processError;
  _exports.publishError = publishError;
  _exports.userError = userError;
  const i18n = (0, _i18n.getI18n)();
  const USER_ERROR_MARKER = '__plutof-user-error';

  // Error that is not meant to be sent to rollbar, just shown to user
  function userError(message) {
    let error = new Error(message);
    error[USER_ERROR_MARKER] = true;
    return error;
  }
  const ERROR_TYPES = {
    Adapter: 'adapter',
    Ajax: 'ajax',
    Local: 'local',
    Raw: 'raw',
    Unknown: 'unknown'
  };
  const PUBLISHED_ERROR_TYPES = [ERROR_TYPES.Local, ERROR_TYPES.Unknown];
  class RequestParseError extends Error {
    constructor(xhr, error) {
      super(error.message);
      this.debugFields = {
        url: xhr.url,
        response: xhr.responseText
      };
    }
  }

  // TODO: This is just a stub for now. Need to parse backend responses as well
  _exports.RequestParseError = RequestParseError;
  function getErrorMessage(error) {
    if ((0, _utils.isEmpty)(error)) {
      return 'Unknown error';
    }
    if ((0, _utils.typeOf)(error) === 'string') {
      return error;
    }
    if (error.detail || error.title) {
      return [error.title, error.detail].filter(v => Boolean(v)).join(' - ');
    }
    if (!(0, _utils.isNone)(error.errors)) {
      return error.errors.map(getErrorMessage).join('; ');
    }
    if ((0, _utils.typeOf)(error) === 'error') {
      return error.status === 500 ? i18n.t('queryError.status.500') : error.toString();
    }
    if (!(0, _utils.isNone)(error.errorThrown)) {
      return error.errorThrown;
    }
    if (!(0, _utils.isNone)(error.message)) {
      return error.message;
    }
    return JSON.stringify(error);
  }
  function getErrorType(error) {
    if (typeof error !== 'object' || error[USER_ERROR_MARKER]) {
      return ERROR_TYPES.Raw;
    }
    if (error.isAdapterError) {
      return ERROR_TYPES.Adapter;
    }
    if ((0, _errors.isAjaxError)(error) || !(0, _utils.isNone)(error.jqXHR)) {
      return ERROR_TYPES.Ajax;
    }
    if (error instanceof Error) {
      return ERROR_TYPES.Local;
    }
    return ERROR_TYPES.Unknown;
  }
  const ERROR_FILTERS = [error => {
    const type = getErrorType(error);
    return PUBLISHED_ERROR_TYPES.includes(type);
  }, error => error.name !== 'TransitionAborted'];
  function shouldPublishError(error) {
    return ERROR_FILTERS.every(filter => filter(error));
  }
  function processError(error) {
    if (!_environment.default.ENABLE_ERROR_REPORTING) {
      return;
    }
    if (shouldPublishError(error)) {
      publishError(error);
    }
  }

  // Can't put this into service (as ember-cli-rollbar does), because most of the calls to processError
  // will come from reportError, which has no connection to ember's DI container
  const rollbar = new _rollbar.default({
    enabled: _environment.default.ENABLE_ERROR_REPORTING,
    accessToken: _environment.default.ERROR_REPORTING_TOKEN,
    captureUncaught: false,
    payload: {
      environment: _environment.default.environment,
      build: _environment.default.currentRevision,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: `${_environment.default.environment}-${_environment.default.currentRevision}`
        }
      }
    }
  });
  function publishError(error) {
    rollbar.error(error, error.debugFields);
  }
  function configureRollbar(options) {
    rollbar.configure(options);
  }
});