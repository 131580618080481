define("plutof/components/generic-forms/record", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      field=(component "generic-forms/-record/field" model=@record.constructor.modelName)
      bind=(component "generic-forms/-record/bind" record=@record)
  )}}
  
  */
  {
    "id": "npJprq1L",
    "block": "[[[18,2,[[28,[37,1],null,[[\"field\",\"bind\"],[[50,\"generic-forms/-record/field\",0,null,[[\"model\"],[[30,1,[\"constructor\",\"modelName\"]]]]],[50,\"generic-forms/-record/bind\",0,null,[[\"record\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[\"@record\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/generic-forms/record.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});