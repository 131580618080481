define("plutof/components/collection/view/transactions", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _object, _service, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @title={{i18n-t "transactions.title"}}
      @pagination={{this.pagination}}
      @add={{this.add}}
      @canModify={{true}}
      @searchModule="transaction"
      @searchQuery={{this.searchQuery}}
  >
      <RelatedObjects::Content::Transactions @pagination={{this.pagination}} />
  </RelatedObjects::View>
  
  */
  {
    "id": "ogtqTwwr",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@pagination\",\"@add\",\"@canModify\",\"@searchModule\",\"@searchQuery\"],[[28,[37,1],[\"transactions.title\"],null],[30,0,[\"pagination\"]],[30,0,[\"add\"]],true,\"transaction\",[30,0,[\"searchQuery\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content/transactions\"]]",
    "moduleName": "plutof/components/collection/view/transactions.hbs",
    "isStrictMode": false
  });
  let CollectionViewTransactions = _exports.default = (_dec = (0, _object.computed)('collectionId'), _dec2 = (0, _object.computed)('collectionId'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class CollectionViewTransactions extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "updatePagination", _descriptor2, this);
    }
    add() {
      return this.router.transitionTo('transaction.add', {
        queryParams: {
          collection_id: this.collectionId
        }
      });
    }
    get queryParams() {
      return {
        collection: this.collectionId,
        ordering: '-start_date'
      };
    }
    get searchQuery() {
      return {
        collection: this.collectionId
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updatePagination.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "searchQuery"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updatePagination", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'transaction/transaction', this.queryParams);
        this.set('pagination', pagination);
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionViewTransactions);
});