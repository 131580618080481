define("plutof/components/analysis/matching-source-clipboard-items", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let MatchingSourceClipboardItems = (_dec = (0, _component2.tagName)(''), _dec(_class = class MatchingSourceClipboardItems extends _component.default {}) || _class);
  var _default = _exports.default = MatchingSourceClipboardItems;
});