define("plutof/templates/components/experiment/dna/view/record-metadata", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "OY9fmL21",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[8,[39,4],null,[[\"@path\"],[\"agent.organization.managing_group\"]],null],[1,\"\\n\\n\"],[41,[30,1,[\"collection\",\"id\"]],[[[1,\"                    \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"profile.groups.group\",[30,1,[\"collection\",\"managing_group\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[30,1,[\"collection\",\"managing_group\",\"representation\"]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"profile.groups.group\",[30,1,[\"dna_extraction\",\"laboratory\",\"managing_group\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[30,1,[\"dna_extraction\",\"laboratory\",\"managing_group\",\"representation\"]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n\\n            \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n                \"],[8,[30,2,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[30,1,[\"created_by\",\"representation\"]],[30,1,[\"created_at\"]],[30,1,[\"updated_by\",\"representation\"]],[30,1,[\"updated_at\"]]]],null],[1,\"\\n            \"]],[2]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@record\",\"vr\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"plutof-labelc\",\"if\",\"link-to\",\"access/view-rights-base\"]]",
    "moduleName": "plutof/templates/components/experiment/dna/view/record-metadata.hbs",
    "isStrictMode": false
  });
});