define("plutof/components/search/filter-panels/common-properties/agents", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::FilterInput
          @filter={{@filterInputs.owner}}
          @autocompletePreset="users/user"
          @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.created_by}}
          @autocompletePreset="users/user"
          @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
  
      {{#if @filterInputs.gathering_agents}}
          <Search::Filters::Person @filter={{@filterInputs.gathering_agents}} />
      {{/if}}
  
      {{#if @filterInputs.determiners}}
          <Search::Filters::Person @filter={{@filterInputs.determiners}} />
      {{/if}}
  
      <Search::FilterInput @filter={{@filterInputs.group}} @autocompletePreset="users/workgroup" />
  </Layout::RegularGrid>
  
  */
  {
    "id": "205copuk",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[30,1,[\"owner\"]],\"users/user\",[28,[37,2],[\"Agents.personTooltip\"],null]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[30,1,[\"created_by\"]],\"users/user\",[28,[37,2],[\"Agents.personTooltip\"],null]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"gathering_agents\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"gathering_agents\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"determiners\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"determiners\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"group\"]],\"users/workgroup\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"layout/regular-grid\",\"search/filter-input\",\"i18n-t\",\"if\",\"search/filters/person\"]]",
    "moduleName": "plutof/components/search/filter-panels/common-properties/agents.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});