define("plutof/components/generic-forms/-record/field", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _GenericRecordFormField;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{unless this.isValid "has-error"}}>
      {{plutof-labelc this.fieldPath}}
  
      {{yield}}
  </div>
  
  */
  {
    "id": "ey6zki4D",
    "block": "[[[10,0],[15,0,[52,[51,[30,0,[\"isValid\"]]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"fieldPath\"]]],null]],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"unless\",\"plutof-labelc\",\"yield\"]]",
    "moduleName": "plutof/components/generic-forms/-record/field.hbs",
    "isStrictMode": false
  });
  let GenericRecordFormField = (_dec = (0, _object.computed)('valid'), _dec2 = (0, _object.computed)('model', 'field'), _class = (_GenericRecordFormField = class GenericRecordFormField extends _component.default {
    get isValid() {
      return this.valid || (0, _utils.isNone)(this.valid);
    }
    get fieldPath() {
      return `${this.model.replace(/\//g, '.')}.${this.field}`;
    }
  }, _defineProperty(_GenericRecordFormField, "positionalParams", ['field']), _GenericRecordFormField), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fieldPath", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fieldPath"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GenericRecordFormField);
});