define("plutof/templates/trait-lab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oEo0GEtj",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\"],[[28,[37,1],[\"trait\"],null],[28,[37,2],[\"Forms.traitsLabel\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@route\",\"@icon\"],[[28,[37,2],[\"Forms.traitsLabel\"],null],\"trait.index\",[28,[37,1],[\"trait\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@route\",\"@icon\"],[[28,[37,2],[\"Forms.title\"],null],\"forms.index\",[28,[37,1],[\"form\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"hub\"],false,[\"layout/module-hub\",\"icon\",\"i18n-t\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/trait-lab.hbs",
    "isStrictMode": false
  });
});