define("plutof/controllers/profile/defaults", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "plutof/mixins/component-validations", "plutof/models/agent/organization", "plutof/utils/i18n", "plutof/utils/model", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _service, _componentValidations, _organization, _i18n, _model, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let ProfileDefaultsController = (_dec = (0, _model.choices)('profile.personalsettings.personalsetting.default_map'), _dec2 = (0, _computed.alias)('model.profile.user'), _class = class ProfileDefaultsController extends _controller.default.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "mapChoices", _descriptor2, this);
      _initializerDefineProperty(this, "userAccount", _descriptor3, this);
      _defineProperty(this, "laboratoryFilters", {
        organization_type: _organization.OrganizationType.Laboratory,
        is_member: true
      });
    }
    updateProfile() {
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return this.model.profile.save().then(() => (0, _notifications.displayNotification)('success', i18n.t('General.done'))).catch(_notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mapChoices", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProfile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProfile"), _class.prototype), _class);
  var _default = _exports.default = ProfileDefaultsController;
});