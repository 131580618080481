define("plutof/components/info-link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="{{@infoLink}}" target="_blank">
      <span class="icon-info"></span>
  </a>
  
  */
  {
    "id": "hdxGQqsx",
    "block": "[[[10,3],[15,6,[29,[[30,1]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"icon-info\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@infoLink\"],false,[]]",
    "moduleName": "plutof/components/info-link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});