define("plutof/templates/components/map/locate-me", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "XzyKmmvx",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"title\",[28,[37,0],[\"Map.locateMe\"],null]],[24,4,\"button\"],[4,[38,1],[[30,0],\"locateMe\"],null],[12],[1,\"\\n\"],[41,[30,0,[\"inProgress\"]],[[[1,\"        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"fas fa-crosshairs\"],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"i18n-t\",\"action\",\"if\"]]",
    "moduleName": "plutof/templates/components/map/locate-me.hbs",
    "isStrictMode": false
  });
});