define("plutof/templates/components/occurrences-table/-responsive-row/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "RSd7/iQF",
    "block": "[[[1,[28,[35,0],[\"General.controls\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"occ-table-responsive-row__actions\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,2],null,[[\"button\"],[[50,\"occurrences-table/-responsive-row/action-button\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"common/local-label\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/actions.hbs",
    "isStrictMode": false
  });
});