define("plutof/components/photobank/taxa", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{and (not @taxa.length) (not @open)}}
      ...attributes
      as |panel|
  >
      <panel.header
          @title={{i18n-t "Photobank.taxa"}}
          @count={{@taxa.length}} />
  
      <panel.content>
          {{yield}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "lE0MNTDJ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@collapse\"],[[28,[37,1],[[28,[37,2],[[30,2,[\"length\"]]],null],[28,[37,2],[[30,3]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,4,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,3],[\"Photobank.taxa\"],null],[30,2,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,4,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"&attrs\",\"@taxa\",\"@open\",\"panel\",\"&default\"],false,[\"plutof-panel/item-list\",\"and\",\"not\",\"i18n-t\",\"yield\"]]",
    "moduleName": "plutof/components/photobank/taxa.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});