define("plutof/utils/annotation/routes", ["exports", "@ember/controller", "@ember/service", "@ember/object", "rsvp", "plutof/config/environment", "plutof/misc/content_types", "plutof/models/annotation/annotation", "plutof/utils/annotation/format", "plutof/utils/annotation/elixir", "plutof/utils/errors", "plutof/utils/notifications", "plutof/utils/annotation/moderation"], function (_exports, _controller, _service, _object, _rsvp, _environment, _content_types, _annotation, _format, _elixir, _errors, _notifications, _moderation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addAnnotationSubroutes = addAnnotationSubroutes;
  _exports.annotationAddController = annotationAddController;
  _exports.annotationAddControllerWithElixir = annotationAddControllerWithElixir;
  _exports.annotationAddRoute = annotationAddRoute;
  _exports.annotationEditController = annotationEditController;
  _exports.annotationEditControllerWithElixir = annotationEditControllerWithElixir;
  _exports.annotationEditRoute = annotationEditRoute;
  _exports.annotationViewRoute = annotationViewRoute;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // Be careful with recordIDParams, because ember has a bizarre legacy leftover functionality
  // that generates default model handler if route has param of form foo_id (calls
  // store.findRecord('foo', params.foo_id)
  function addAnnotationSubroutes(router) {
    router.route('annotation', {
      path: 'annotations/:recordID'
    }, function () {
      this.route('add');
      this.route('edit', {
        path: 'edit/:annotation_id'
      });
      this.route('view', {
        path: 'view/:annotation_id'
      });
    });
  }
  const RECORD_ID_FIELD = '__ANNOTATION_RECORD_ID_FIELD';
  const ANNOTATION_ID_FIELD = '__ANNOTATION_ID_FIELD';
  function annotationAddRoute(RecordEditRoute, {
    parentRoute
  }) {
    var _class;
    return _class = class extends RecordEditRoute {
      async model(params) {
        this.store.startAnnotation();
        const {
          recordID
        } = this.paramsFor(`${parentRoute}.annotation`);
        this[RECORD_ID_FIELD] = recordID;
        try {
          const model = await super.model({
            id: recordID
          });
          return model;
        } catch (err) {
          this.store.endAnnotation();
          throw err;
        }
      }
      transitionToDefaultRoute() {
        return this.transitionTo(`${parentRoute}.view`, this[RECORD_ID_FIELD]);
      }

      // XXX: Overrides route mixin willTransition
      willTransition() {
        this.store.endAnnotation();
      }
    }, _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _class;
  }
  function annotationEditRoute(RecordEditRoute, {
    parentRoute
  }) {
    var _class2, _descriptor;
    return _class2 = class _class2 extends RecordEditRoute {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor, this);
      }
      // TODO: annotation_id => annotationID
      async model({
        annotation_id
      }) {
        const annotation = await this.emberDataStore.findRecord('annotation/client-annotation', annotation_id);
        this.store.startAnnotation(annotation);
        try {
          const {
            recordID
          } = this.paramsFor(`${parentRoute}.annotation`);
          this[RECORD_ID_FIELD] = recordID;
          this[ANNOTATION_ID_FIELD] = annotation_id;
          let model = await super.model({
            id: recordID
          });
          model.annotation = annotation;
          return model;
        } catch (err) {
          this.store.endAnnotation();
          throw err;
        }
      }
      transitionToDefaultRoute() {
        return this.transitionTo(`${parentRoute}.annotation.view`, this[RECORD_ID_FIELD], this[ANNOTATION_ID_FIELD]);
      }

      // XXX: Overrides route mixin willTransition
      willTransition() {
        this.store.endAnnotation();
      }
    }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class2.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "willTransition"), _class2.prototype), _class2;
  }
  function annotationViewRoute(RecordViewRoute, {
    parentRoute
  }) {
    var _class3, _descriptor2, _descriptor3;
    return _class3 = class _class3 extends RecordViewRoute {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor2, this);
        _initializerDefineProperty(this, "settings", _descriptor3, this);
      }
      async model({
        annotation_id
      }) {
        const annotation = await this.emberDataStore.findRecord('annotation/client-annotation', annotation_id);
        this.store.startAnnotation(annotation, {
          showRemoved: true
        });
        try {
          const {
            recordID
          } = this.paramsFor(`${parentRoute}.annotation`);
          this[RECORD_ID_FIELD] = recordID;
          let model = await super.model({
            id: recordID
          });
          model.annotation = annotation;
          model.permissions = await (async () => {
            const [author, currentUser] = await _rsvp.default.all([annotation.author, this.settings.wait().then(profile => profile.user)]);
            let perms = {
              canModify: false,
              canDelete: false,
              canModerate: false,
              canComment: false
            };
            if (author.id === currentUser.id) {
              perms.canDelete = true;
              perms.canModify = annotation.status === _annotation.AnnotationStatus.Rejected;
              perms.canComment = true;
              if (_environment.default.ENABLE_SELF_ANNOTATION) {
                perms.canModerate = model.accessRights.canModify && annotation.status === _annotation.AnnotationStatus.BeingReviewed;
              }
            } else if (model.accessRights.canModify) {
              perms.canDelete = true;
              perms.canComment = true;
              perms.canModerate = annotation.status === _annotation.AnnotationStatus.BeingReviewed;
            }
            return perms;
          })();
          return model;
        } catch (err) {
          this.store.endAnnotation();
          throw err;
        }
      }
      transitionToDefaultRoute() {
        return this.transitionTo(`${parentRoute}.view`, this[RECORD_ID_FIELD]);
      }

      // XXX: Overrides route mixin willTransition
      willTransition() {
        this.store.endAnnotation();
      }
    }, _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "settings", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class3.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "willTransition"), _class3.prototype), _class3;
  }
  function notifyProposal(promise, i18n) {
    (0, _notifications.clearNotifications)();
    (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
    return promise.then(annotation => {
      const message = annotation.is_self_annotation ? i18n.t('annotation.selfAnnotationCreated') : i18n.t('annotation.annotationSent');
      (0, _notifications.displayNotification)('success', message);
      return annotation;
    }).catch(err => {
      (0, _notifications.reportError)(err);
      throw err;
    });
  }
  function annotationAddController({
    save,
    contentType,
    getRecordID
  }) {
    var _class4, _descriptor4;
    return _class4 = class AnnotationAddController extends _controller.default {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor4, this);
      }
      async _prepareAnnotationChanges() {
        // XXX: Tentative solution to extract -> cancel -> extract invalid state
        // If we don't encounter any problems with this, should have it as a store method
        this.store.annotatedStore._changes = [];
        await save(this.model);
        const changes = (0, _format.coalesceChanges)(this.store.annotatedStore.extractChanges());
        if (changes.length === 0) {
          throw (0, _errors.userError)(this.i18n.t('annotation.noChangesMade'));
        }
        return changes;
      }
      async _annotate(comment) {
        const changes = await this._prepareAnnotationChanges();
        const ctype = await (0, _content_types.get_ctype_by_name)(this.emberDataStore, contentType);
        const annotation = this.emberDataStore.createRecord('annotation/client-annotation', {
          content_type: ctype,
          object_id: getRecordID(this.model),
          comment,
          format: _format.AnnotationFormats.APIdiff2,
          annotation: {
            changes
          }
        });
        await annotation.save();
        if (annotation.is_self_annotation) {
          await (0, _moderation.acceptAnnotation)(annotation, comment);
        }
        this.send('goBack');
        return annotation;
      }
      annotate(comment) {
        return notifyProposal(this._annotate(comment), this.i18n);
      }
    }, _descriptor4 = _applyDecoratedDescriptor(_class4.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class4.prototype, "annotate", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "annotate"), _class4.prototype), _class4;
  }
  function annotationEditController(save) {
    var _class5, _descriptor5;
    return _class5 = class AnnotationEditController extends _controller.default {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor5, this);
      }
      async _prepareAnnotationChanges() {
        this.store.annotatedStore._changes = []; // XXX see annotationAddController._prepareAnnotationChanges

        await save(this.model);
        const oldChanges = this.model.annotation.annotation.changes;
        const newChanges = this.store.annotatedStore.extractChanges();
        const changes = (0, _format.coalesceChanges)(oldChanges.concat(newChanges));
        if (changes.length === 0) {
          throw (0, _errors.userError)(this.i18n.t('annotation.noChangesMade'));
        }
        return changes;
      }
      async _annotate(comment) {
        const changes = await this._prepareAnnotationChanges();
        this.model.annotation.set('comment', comment);
        this.model.annotation.set('format', _format.AnnotationFormats.APIdiff2);
        this.model.annotation.set('annotation', {
          changes
        });
        await this.model.annotation.save();
        this.send('goBack');
        return this.model.annotation;
      }
      annotate(comment) {
        return notifyProposal(this._annotate(comment), this.i18n);
      }
    }, _descriptor5 = _applyDecoratedDescriptor(_class5.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class5.prototype, "annotate", [_object.action], Object.getOwnPropertyDescriptor(_class5.prototype, "annotate"), _class5.prototype), _class5;
  }
  function annotationAddControllerWithElixir({
    save,
    contentType,
    getRecordID,
    extract
  }) {
    var _class6, _descriptor6;
    const AnnotationAddController = annotationAddController({
      save,
      contentType,
      getRecordID
    });
    return _class6 = class AnnotationAddControllerWithElixir extends AnnotationAddController {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor6, this);
      }
      async extractElixirCurations(previousCurations = []) {
        try {
          const changes = await this._prepareAnnotationChanges();
          const extractor = new _elixir.ElixirExtractor(this.store, changes, {
            previousCurations
          });
          await extract.call(this, extractor, changes);
          return extractor.finish();
        } catch (err) {
          (0, _notifications.reportError)(err);
          throw err;
        }
      }
      async sendElixirAnnotation({
        format,
        annotation
      }) {
        const ctype = await (0, _content_types.get_ctype_by_name)(this.emberDataStore, contentType);
        const clientAnnotation = this.emberDataStore.createRecord('annotation/client-annotation', {
          content_type: ctype,
          object_id: getRecordID(this.model),
          comment: '',
          format,
          annotation
        });
        await clientAnnotation.save();
        if (clientAnnotation.is_self_annotation) {
          await (0, _moderation.acceptAnnotation)(clientAnnotation);
        }
        this.send('goBack');
      }
    }, _descriptor6 = _applyDecoratedDescriptor(_class6.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class6.prototype, "extractElixirCurations", [_object.action], Object.getOwnPropertyDescriptor(_class6.prototype, "extractElixirCurations"), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, "sendElixirAnnotation", [_object.action], Object.getOwnPropertyDescriptor(_class6.prototype, "sendElixirAnnotation"), _class6.prototype), _class6;
  }
  function annotationEditControllerWithElixir({
    save,
    extract
  }) {
    var _class7, _descriptor7;
    const EditController = annotationEditController(save);
    return _class7 = class AnnotationEditControllerWithElixir extends EditController {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "emberDataStore", _descriptor7, this);
      }
      async extractElixirCurations(previousCurations = []) {
        if (previousCurations.length === 0) {
          previousCurations = this.model.annotation.annotation.annotation.elixirChanges;
        }
        const changes = await this._prepareAnnotationChanges();
        const extractor = new _elixir.ElixirExtractor(this.store, changes, {
          previousCurations
        });
        await extract.call(this, extractor, changes);
        return extractor.finish();
      }
      async sendElixirAnnotation(annotationContent) {
        this.model.annotation.set('annotation', annotationContent);
        await this.model.annotation.save();
        this.send('goBack');
      }
    }, _descriptor7 = _applyDecoratedDescriptor(_class7.prototype, "emberDataStore", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class7.prototype, "extractElixirCurations", [_object.action], Object.getOwnPropertyDescriptor(_class7.prototype, "extractElixirCurations"), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, "sendElixirAnnotation", [_object.action], Object.getOwnPropertyDescriptor(_class7.prototype, "sendElixirAnnotation"), _class7.prototype), _class7;
  }
});