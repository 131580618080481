define("plutof/components/dmp/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Dmp::General
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.general}} />
  
  <Dmp::Contributors
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.contributors}} />
  
  <Dmp::Costs
      @dmp={{@data.dmp}}
      @validationContext={{this.validationContext.costs}} />
  
  <Dmp::Project
      @data={{@data}}
      @validationContext={{this.validationContext.project}} />
  
  <Dmp::Dataset @dataset={{@data.dmp.dataset}} />
  
  <Dmp::Distribution
      @distribution={{@data.dmp.dataset.distribution}}
      @validationContext={{this.validationContext.distribution}} />
  
  <Dmp::Host @host={{@data.dmp.dataset.distribution.host}} />
  
  <Access::EditRights
      @rights={{@accessRights}}
      @validationContext={{@validationContext.access}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{this.validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{action @save}} @disabled={{this.validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{action @cancel}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "gIF/4BSB",
    "block": "[[[8,[39,0],null,[[\"@dmp\",\"@validationContext\"],[[30,1,[\"dmp\"]],[30,0,[\"validationContext\",\"general\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@dmp\",\"@validationContext\"],[[30,1,[\"dmp\"]],[30,0,[\"validationContext\",\"contributors\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@dmp\",\"@validationContext\"],[[30,1,[\"dmp\"]],[30,0,[\"validationContext\",\"costs\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@data\",\"@validationContext\"],[[30,1],[30,0,[\"validationContext\",\"project\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@dataset\"],[[30,1,[\"dmp\",\"dataset\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@distribution\",\"@validationContext\"],[[30,1,[\"dmp\",\"dataset\",\"distribution\"]],[30,0,[\"validationContext\",\"distribution\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@host\"],[[30,1,[\"dmp\",\"dataset\",\"distribution\",\"host\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@rights\",\"@validationContext\"],[[30,2],[30,3,[\"access\"]]]],null],[1,\"\\n\\n\"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,9],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,10],null,[[\"@save\",\"@disabled\"],[[28,[37,11],[[30,0],[30,4]],null],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n        \"],[8,[39,12],null,[[\"@cancel\"],[[28,[37,11],[[30,0],[30,5]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@data\",\"@accessRights\",\"@validationContext\",\"@save\",\"@cancel\"],false,[\"dmp/general\",\"dmp/contributors\",\"dmp/costs\",\"dmp/project\",\"dmp/dataset\",\"dmp/distribution\",\"dmp/host\",\"access/edit-rights\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"action\",\"common/cancel-button\"]]",
    "moduleName": "plutof/components/dmp/edit.hbs",
    "isStrictMode": false
  });
  class DMPEditForm extends _component.default.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'general',
        label: 'dmp.panels.general'
      }, {
        name: 'contributors',
        label: 'dmp.panels.contributors'
      }, {
        name: 'costs',
        label: 'dmp.panels.costs'
      }, {
        name: 'project',
        label: 'dmp.panels.project'
      }, {
        name: 'distribution',
        label: 'dmp.panels.distribution'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPEditForm);
});