define("plutof/mixins/search-filter", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX TODO: No longer necessary
  const SearchFilterMixin = _mixin.default.create({});
  var _default = _exports.default = SearchFilterMixin;
});