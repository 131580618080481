define("plutof/components/search/filters/location", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="display-flex">
      <div class="plutof-tooltip-hover">
          <div class="plutof-label">
              {{i18n-t "Sample.mapTitle"}}
          </div>
  
          <span class="plutof-tooltip">{{i18n-t "Sample.mapTitleTooltip"}}</span>
      </div>
  
      {{information/dialog-trigger
          title=(i18n-t "Sample.mapTitle")
          content="information/content/general/map-controls"}}
  </div>
  
  <Map::EditMap
      @geodata={{@filter.value}}
      @wktPrecision={{5}} />
  
  <div class="spacer-large-below"></div>
  
  <Layout::RegularGrid @columns={{2}}>
      <div>
          {{common/local-label "Search.geometry.relation.relation"}}
  
          <PlutofSelect::Value
              @content={{this.RELATION_CHOICES}}
              @oneWay={{true}}
              @value={{default-to @filter.operator "intersects"}}
              @selectionChanged={{this.updateRelation}}
              @disabled={{not @filter.value}}
              @optionLabelPath="label"
              @prompt={{false}} />
      </div>
  
      <div class={{unless this.bufferValid "has-error"}}>
          {{common/local-label
              "Search.geometry.buffer.label"
              "Search.geometry.buffer.tooltip"}}
  
          <Input
              @value={{@filter.buffer}}
              @disabled={{not @filter.value}}
              class="form-control" />
      </div>
  </Layout::RegularGrid>
  
  */
  {
    "id": "7mKNyon0",
    "block": "[[[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"Sample.mapTitle\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,[28,[35,0],[\"Sample.mapTitleTooltip\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"title\",\"content\"],[[28,[37,0],[\"Sample.mapTitle\"],null],\"information/content/general/map-controls\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@geodata\",\"@wktPrecision\"],[[30,1,[\"value\"]],5]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"Search.geometry.relation.relation\"],null]],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@content\",\"@oneWay\",\"@value\",\"@selectionChanged\",\"@disabled\",\"@optionLabelPath\",\"@prompt\"],[[30,0,[\"RELATION_CHOICES\"]],true,[28,[37,6],[[30,1,[\"operator\"]],\"intersects\"],null],[30,0,[\"updateRelation\"]],[28,[37,7],[[30,1,[\"value\"]]],null],\"label\",false]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[52,[51,[30,0,[\"bufferValid\"]]],\"has-error\"]],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"Search.geometry.buffer.label\",\"Search.geometry.buffer.tooltip\"],null]],[1,\"\\n\\n        \"],[8,[39,9],[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[30,1,[\"buffer\"]],[28,[37,7],[[30,1,[\"value\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filter\"],false,[\"i18n-t\",\"information/dialog-trigger\",\"map/edit-map\",\"layout/regular-grid\",\"common/local-label\",\"plutof-select/value\",\"default-to\",\"not\",\"unless\",\"input\"]]",
    "moduleName": "plutof/components/search/filters/location.hbs",
    "isStrictMode": false
  });
  let SearchLocationFilter = (_dec = (0, _object.computed)('args.filter.buffer', 'args.filter.bufferValid'), _class = class SearchLocationFilter extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _defineProperty(this, "RELATION_CHOICES", [{
        value: 'intersects',
        label: this.i18n.translate('Search.geometry.relation.intersects')
      }, {
        value: 'within',
        label: this.i18n.translate('Search.geometry.relation.within')
      }, {
        value: 'contains',
        label: this.i18n.translate('Search.geometry.relation.contains')
      }]);
    }
    get bufferValid() {
      return this.args.filter && (!this.args.filter.buffer || this.args.filter.bufferValid);
    }
    updateRelation(choice) {
      this.args.filter.operator = choice.value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bufferValid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "bufferValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRelation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateRelation"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchLocationFilter);
});