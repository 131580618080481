define("plutof/components/sequence/view/unite-clusters", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "ember-concurrency", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/promises", "plutof/utils/store", "plutof/utils/unite"], function (_exports, _component, _templateFactory, _service, _emberConcurrency, _environment, _abstract, _promises, _store, _unite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.UNITESH"}}
      @collapse={{not this.codes.length}}
  >
      <Tables::List @records={{this.codes}} as |Field code|>
          <Field @label={{i18n-t "UniteSH.shCode"}}>
              <LinkTo @route="unite.view" @model={{code.id}}>
                  {{code.name}}
              </LinkTo>
          </Field>
  
          <Field @label={{i18n-t "moderation.taxonName"}}>
              {{code.taxon_node.taxon_name}}
          </Field>
  
          <Field @label={{i18n-t "UniteSH.treshold"}}>
              {{code.threshold}} %
          </Field>
  
          <Field @label={{i18n-t "UniteSH.nrOfSeq"}}>
              {{code.sequence_count}}
          </Field>
  
          <Field @label={{i18n-t "UniteSH.refseqChosenByII"}}>
              <ArrayView @array={{code.designators}} @limit={{999}} />
          </Field>
      </Tables::List>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "+T7VEdNR",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"General.UNITESH\"],null],[28,[37,2],[[30,0,[\"codes\",\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@records\"],[[30,0,[\"codes\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,1],[\"UniteSH.shCode\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"unite.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,1],[\"moderation.taxonName\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"taxon_node\",\"taxon_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,1],[\"UniteSH.treshold\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"threshold\"]]],[1,\" %\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,1],[\"UniteSH.nrOfSeq\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"sequence_count\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1],null,[[\"@label\"],[[28,[37,1],[\"UniteSH.refseqChosenByII\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@array\",\"@limit\"],[[30,2,[\"designators\"]],999]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"Field\",\"code\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"not\",\"tables/list\",\"link-to\",\"array-view\"]]",
    "moduleName": "plutof/components/sequence/view/unite-clusters.hbs",
    "isStrictMode": false
  });
  const UNITE_DATABASES = ['2', '3', '4'];
  let SequenceUNITEIdentifications = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class SequenceUNITEIdentifications extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "load", _descriptor3, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.load.perform();
    }
    async getUNITEAccessionNumber(sequence) {
      const sdbs = await (0, _store.query)(this.store, 'taxonoccurrence/sequence/sequencedatabase', {
        sequence: sequence.id
      });
      const unitesdb = await _promises.default.find(sdbs, async sdb => UNITE_DATABASES.includes((await sdb.database).id));
      return unitesdb ? unitesdb.id_in_database : null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const accno = yield this.getUNITEAccessionNumber(this.sequence);
        if (!accno) {
          this.set('codes', []);
          return;
        }
        const version = yield (0, _unite.getLastUNITEVersion)(this.store);
        const url = (0, _abstract.construct_request)(`${_environment.default.API_HOST}/globalkey/dshclusters/search/`, {
          version: version ? version.id : 'undefined',
          sequence_accession_nr: accno,
          ordering: 'id' // TODO: Should be threshold, but there's some index problem atm
        });
        const codes = (yield this.ajax.request(url)).results;
        this.set('codes', codes.filter(code => code.threshold !== '0.0'));
      };
    }
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SequenceUNITEIdentifications);
});