define("plutof/components/pagination/editable/toggle-dirty-items", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" this.toggle}}
      disabled={{this.disabled}}
      class="btn btn-default btn-xs"
  >
      {{#if @pagination.showingDirtyItems}}
          {{i18n-t "Agents.associations.showAll"}}
  
          <span class="count-badge">
              {{@pagination.objectCount}}
          </span>
      {{else}}
          {{i18n-t "Agents.associations.showChanged"}}
  
          <span class="count-badge">
              {{@pagination.dirtyItems.length}}
          </span>
      {{/if}}
  </button>
  
  */
  {
    "id": "Gbt0lhQQ",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,0,\"btn btn-default btn-xs\"],[4,[38,0],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"showingDirtyItems\"]],[[[1,\"        \"],[1,[28,[35,2],[\"Agents.associations.showAll\"],null]],[1,\"\\n\\n        \"],[10,1],[14,0,\"count-badge\"],[12],[1,\"\\n            \"],[1,[30,1,[\"objectCount\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"Agents.associations.showChanged\"],null]],[1,\"\\n\\n        \"],[10,1],[14,0,\"count-badge\"],[12],[1,\"\\n            \"],[1,[30,1,[\"dirtyItems\",\"length\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@pagination\"],false,[\"on\",\"if\",\"i18n-t\"]]",
    "moduleName": "plutof/components/pagination/editable/toggle-dirty-items.hbs",
    "isStrictMode": false
  });
  let ToggleDirtyItems = _exports.default = (_dec = (0, _object.computed)('args.pagination.showingFilteredItems', 'args.pagination.dirtyItems.[]', 'args.pagination.showingDirtyItems'), _class = class ToggleDirtyItems extends _component2.default {
    get disabled() {
      const pagination = this.args.pagination;
      return pagination.showingFilteredItems || !pagination.showingDirtyItems && pagination.dirtyItems.length === 0;
    }
    toggle() {
      if (this.args.pagination.showingDirtyItems) {
        this.args.pagination.clearView();
      } else {
        this.args.pagination.switchToDirtyItems();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ToggleDirtyItems);
});