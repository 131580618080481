define("plutof/templates/components/search/range-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xsjww1ch",
    "block": "[[[10,0],[14,0,\"input-group time-filter-group\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@type\",\"@value\",\"@input\",\"@disabled\",\"@class\"],[\"text\",[30,1,[\"start\"]],[30,2],[30,3],\"form-control time-filter-group__input\"]],null],[1,\"\\n\\n    \"],[10,1],[14,0,\"input-group-addon time-filter-group__addon\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@content\",\"@selection\",\"@selectionChanged\",\"@optionValuePath\",\"@optionLabelPath\",\"@prompt\",\"@disabled\",\"@selectClass\"],[[30,0,[\"operators\"]],[30,1,[\"operator\"]],[30,2],\"name\",\"name\",null,[30,3],\"time-filter-group__addon__select clickable\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,0],null,[[\"@type\",\"@value\",\"@input\",\"@disabled\",\"@class\"],[\"text\",[30,1,[\"end\"]],[30,2],[30,3],\"form-control time-filter-group__input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@range\",\"@changed\",\"@disabled\"],false,[\"input\",\"plutof-select/object\"]]",
    "moduleName": "plutof/templates/components/search/range-input.hbs",
    "isStrictMode": false
  });
});