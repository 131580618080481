define("plutof/templates/publishing/gbif/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tj1wJYt6",
    "block": "[[[10,0],[14,0,\"route-marker-publishing-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[28,[37,1],[\"gbif\"],null],[28,[37,2],[\"dataResource.general.editDataset\"],null],[30,0,[\"model\",\"dataset\"]],\"information/content/publishing/gbif/general\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"dataset\",\"is_deleted\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert--warning alert--in-page\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"alert__icon fas fa-exclamation-triangle\"],[12],[13],[1,\"\\n\\n        \"],[1,[28,[35,2],[\"dataResource.general.deleted\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@dataset\",\"@sequences\",\"@specimens\",\"@observations\",\"@referencebased\",\"@agents\",\"@rights\",\"@validationContext\",\"@cancel\",\"@save\",\"@setLogo\"],[[30,0,[\"model\",\"dataset\"]],[30,0,[\"model\",\"sequences\"]],[30,0,[\"model\",\"specimens\"]],[30,0,[\"model\",\"observations\"]],[30,0,[\"model\",\"referencebased\"]],[30,0,[\"model\",\"agents\"]],[30,0,[\"model\",\"rights\"]],[30,0,[\"validationContext\"]],[28,[37,5],[[30,0],\"cancel\"],null],[30,0,[\"saveDataset\"]],[30,0,[\"setLogo\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"if\",\"publishing/gbif/edit-form\",\"action\"]]",
    "moduleName": "plutof/templates/publishing/gbif/edit.hbs",
    "isStrictMode": false
  });
});