define("plutof/templates/person/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yetf7w56",
    "block": "[[[10,0],[14,0,\"route-marker-person-view\"],[14,\"data-test\",\"route-person.view\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@accessRights\",\"@deletable\",\"@neverDeleteRelatedFiles\",\"@infoContent\"],[[28,[37,1],[\"person\"],null],[28,[37,2],[\"Agents.person.person\"],null],[30,0,[\"model\",\"person\"]],\"agent/person\",\"person.edit\",[30,0,[\"model\",\"accessRights\"]],[30,0,[\"canDelete\"]],true,\"information/content/person/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@person\",\"@synonyms\"],[[30,0,[\"model\",\"person\"]],[30,0,[\"model\",\"synonyms\"]]]],null],[1,\"\\n\\n\"],[1,[28,[35,4],null,[[\"agentassociations\"],[[33,5,[\"agentassociations\"]]]]]],[1,\"\\n\\n\"],[1,[28,[35,6],null,[[\"person\",\"statistics\",\"statisticsOptions\"],[[33,5,[\"person\"]],[33,5,[\"statistics\"]],[33,5,[\"statisticsOptions\"]]]]]],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@object\",\"@rights\"],[[30,0,[\"model\",\"person\"]],[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"person/person-view-general\",\"person/person-view-associations\",\"model\",\"person/person-view-statistics\",\"access/view-rights\"]]",
    "moduleName": "plutof/templates/person/view.hbs",
    "isStrictMode": false
  });
});