define("plutof/components/common/data-quality/warning/timespan-after-created", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "plutof/utils/formatters"], function (_exports, _component, _templateFactory, _object, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip
      @tooltip={{i18n-t
          'DataQuality.warnings.timespanAfterCreated'
          timespanBegin=this.formatteedTimespanBegin
          createdAt=this.formattedCreatedAt}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="{{icon 'event'}} data-quality-warning data-quality-warning--icon"></span>
  </ToolTip>
  
  */
  {
    "id": "49bTKmWQ",
    "block": "[[[8,[39,0],null,[[\"@tooltip\",\"@class\"],[[28,[37,1],[\"DataQuality.warnings.timespanAfterCreated\"],[[\"timespanBegin\",\"createdAt\"],[[30,0,[\"formatteedTimespanBegin\"]],[30,0,[\"formattedCreatedAt\"]]]]],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[29,[[28,[37,2],[\"event\"],null],\" data-quality-warning data-quality-warning--icon\"]]],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"tool-tip\",\"i18n-t\",\"icon\"]]",
    "moduleName": "plutof/components/common/data-quality/warning/timespan-after-created.hbs",
    "isStrictMode": false
  });
  let TimespanAfterCreated = _exports.default = (_dec = (0, _object.computed)('timespanBegin'), _dec2 = (0, _object.computed)('createdAt'), _class = class TimespanAfterCreated extends _component.default {
    get formatteedTimespanBegin() {
      return (0, _formatters.getFormattedAmbiguousDate)(this.timespanBegin);
    }
    get formattedCreatedAt() {
      return (0, _formatters.getFormattedAmbiguousDate)(this.createdAt);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "formatteedTimespanBegin", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formatteedTimespanBegin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedCreatedAt", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formattedCreatedAt"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TimespanAfterCreated);
});