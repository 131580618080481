define("plutof/components/related-objects/content/events", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <I18n::ModelStrings @model="sample.samplingevent" as |translations|>
      <RelatedObjects::Content @pagination={{@pagination}} as |Field event|>
          <Field @label={{i18n-t "RelatedObjectsViewColumn.name"}}>
              <LinkTo @route="event.view" @model={{event.id}}>
                  {{@area.representation}} {{formatted-timespan event.timespan_begin event.timespan_end}}
              </LinkTo>
          </Field>
  
          <Field @label={{translations.event_id.label}}>
              {{event.event_id}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.collectionDate"}}>
              {{formatted-date event.timespan_begin}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.collectedBy"}}>
              {{event.collected_by}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
              {{formatted-date event.updated_at}}
          </Field>
      </RelatedObjects::Content>
  </I18n::ModelStrings>
  
  */
  {
    "id": "ezSKzeaZ",
    "block": "[[[8,[39,0],null,[[\"@model\"],[\"sample.samplingevent\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@pagination\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"RelatedObjectsViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"event.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,5,[\"representation\"]]],[1,\" \"],[1,[28,[35,4],[[30,4,[\"timespan_begin\"]],[30,4,[\"timespan_end\"]]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[30,1,[\"event_id\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"event_id\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"RelatedObjectsViewColumn.collectionDate\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,5],[[30,4,[\"timespan_begin\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"RelatedObjectsViewColumn.collectedBy\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"collected_by\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,5],[[30,4,[\"updated_at\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[3,4]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"@pagination\",\"Field\",\"event\",\"@area\"],false,[\"i18n/model-strings\",\"related-objects/content\",\"i18n-t\",\"link-to\",\"formatted-timespan\",\"formatted-date\"]]",
    "moduleName": "plutof/components/related-objects/content/events.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});