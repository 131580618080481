define("plutof/translations/eng/occurrence-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    samplingevent: 'Sampling Area and Event',
    specimen: 'Specimen',
    strain: 'Living Specimen',
    materialsample: 'Material Sample',
    none: 'None'
  };
});