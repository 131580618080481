define("plutof/templates/components/navbar/-navbar-base/info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DA3tS0Q3",
    "block": "[[[6,[39,0],null,[[\"clicked\"],[[28,[37,1],[[30,0],[30,0,[\"show\"]]],null]]],[[\"default\"],[[[[1,\"    \"],[10,1],[15,0,[28,[37,2],[\"info\"],null]],[12],[13],[1,\"\\n    \"],[1,[28,[35,3],[\"General.Info\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"navbar/-navbar-base/button\",\"action\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/info.hbs",
    "isStrictMode": false
  });
});