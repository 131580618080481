define("plutof/components/plutof-panel/item-list/toggle", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" (fn @toggle (not @checked))}}
      class="panel-control-toggle {{if @checked 'panel-control-toggle--checked'}}"
  >
      {{yield}}
  </button>
  
  */
  {
    "id": "+L6NSBqS",
    "block": "[[[11,\"button\"],[16,0,[29,[\"panel-control-toggle \",[52,[30,1],\"panel-control-toggle--checked\"]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,2],[28,[37,3],[[30,1]],null]],null]],null],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@checked\",\"@toggle\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"not\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/item-list/toggle.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});