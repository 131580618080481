define("plutof/components/publishing/gbif/records/add-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PromiseButton
      @clicked={{@add}}
      class="plutof-btn__controls plutof-btn__controls--xs"
      data-test="add-button"
  >
      {{i18n-t "General.Add"}}
  </PromiseButton>
  
  */
  {
    "id": "xVpsUtvV",
    "block": "[[[8,[39,0],[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"],[24,\"data-test\",\"add-button\"]],[[\"@clicked\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@add\"],false,[\"promise-button\",\"i18n-t\"]]",
    "moduleName": "plutof/components/publishing/gbif/records/add-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});