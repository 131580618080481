define("plutof/components/trait/list/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "rsvp", "plutof/utils/model", "plutof/utils/store"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _rsvp, _model, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTraitListModel = createTraitListModel;
  _exports.default = void 0;
  _exports.loadTraitListModel = loadTraitListModel;
  var _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::AddExtra::WrapGeneric @add={{@model.add}} as |externalTraits|>
      <PlutofPanel::ItemList ...attributes as |panel|>
          <panel.header
              @title={{i18n-t "traits.traits"}}
              @count={{@model.traits.length}}
          >
              <panel.button @clicked={{externalTraits.request}}>
                  {{i18n-t "General.Add"}}
              </panel.button>
          </panel.header>
  
          <panel.content>
              <Tables::List @records={{@model.traitLinks}} as |Field link|>
                  <Trait::List::-Fields
                      @field={{Field}}
                      @trait={{link.measurement}} />
  
                  <Field>
                      {{!--
                      TODO: Find a single instance of this thing that looks
                      good and make it a css object or component or something
                      --}}
                      <button
                          {{on "click" (fn @model.remove link)}}
                          class="btn btn-link no-padding"
                      >
                          <span class={{icon "remove"}}></span>
                      </button>
                  </Field>
              </Tables::List>
          </panel.content>
      </PlutofPanel::ItemList>
  </Measurements::AddExtra::WrapGeneric>
  
  */
  {
    "id": "vnuWCX9L",
    "block": "[[[8,[39,0],null,[[\"@add\"],[[30,1,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[17,3]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"traits.traits\"],null],[30,1,[\"traits\",\"length\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,4,[\"button\"]],null,[[\"@clicked\"],[[30,2,[\"request\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,4,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@records\"],[[30,1,[\"traitLinks\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],null,[[\"@field\",\"@trait\"],[[30,5],[30,6,[\"measurement\"]]]],null],[1,\"\\n\\n                \"],[8,[30,5],null,null,[[\"default\"],[[[[1,\"\\n\"],[1,\"                    \"],[11,\"button\"],[24,0,\"btn btn-link no-padding\"],[4,[38,5],[\"click\",[28,[37,6],[[30,1,[\"remove\"]],[30,6]],null]],null],[12],[1,\"\\n                        \"],[10,1],[15,0,[28,[37,7],[\"remove\"],null]],[12],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[5,6]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@model\",\"externalTraits\",\"&attrs\",\"panel\",\"Field\",\"link\"],false,[\"measurements/add-extra/wrap-generic\",\"plutof-panel/item-list\",\"i18n-t\",\"tables/list\",\"trait/list/-fields\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/trait/list/edit.hbs",
    "isStrictMode": false
  });
  let TraitListModel = (_class = class TraitListModel {
    constructor({
      store,
      form,
      linkType,
      traitLinks = []
    }) {
      _defineProperty(this, "toDelete", []);
      _defineProperty(this, "presentTraits", new Set());
      this.store = store;
      this.form = form;
      this.linkType = linkType;
      this.traitLinks = traitLinks;
      for (const link of traitLinks) {
        this.presentTraits.add((0, _model.relationID)(link, 'measurement'));
      }
    }
    add(traits) {
      const links = traits.filter(trait => !this.presentTraits.has(trait.id)).map(trait => this.store.createRecord('measurement/measurementform', {
        measurement: trait,
        mainform: this.form,
        form_type: this.linkType
      }));
      this.traitLinks.pushObjects(links);
    }
    remove(traitLink) {
      this.traitLinks.removeObject(traitLink);
      this.toDelete.push(traitLink);
      this.presentTraits.delete((0, _model.relationID)(traitLink, 'measurement'));
    }

    // This should take accessRights, but the redlist case then becomes circular:
    // - redlist workgroup is made only after redlist is saved
    // - redlist saving requires the form
    // - form requires workgroup for permissions
    // So access handling is on the caller side for now
    async save(name) {
      // NB: As public, with edit rights set to
      // managing group
      await _rsvp.default.Promise.all(this.toDelete.map(l => l.destroyRecord()));
      this.toDelete = [];
      this.form.set('name', name);
      await this.form.save();
      await _rsvp.default.Promise.all(this.traitLinks.map(l => l.save()));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class); // Have to convert mainform types to link form_type choices
  const FORM_LINK_TYPES = {
    'materialsample': 6,
    'specimen': 10,
    'observation': 10,
    'sequence': 4,
    'strain': 10,
    'samplingarea': 7,
    'reference_based': 10,
    'samplingevent': 8,
    'red_list': 9
  };
  function createTraitListModel(store, type) {
    const form = store.createRecord('measurement/mainform', {
      type
    });
    return new TraitListModel({
      store,
      form,
      linkType: FORM_LINK_TYPES[type]
    });
  }
  async function loadTraitListModel(store, form) {
    const traitLinks = await (0, _store.query)(store, 'measurement/measurementform', {
      mainform: form.id
    });
    traitLinks.sort((x, y) => x.id - y.id);
    return new TraitListModel({
      store,
      form,
      linkType: FORM_LINK_TYPES[form.type],
      traitLinks
    });
  }
  let TraitListEdit = (_class2 = class TraitListEdit extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "searchOpen", _descriptor, this);
    }
    showSearch() {
      this.searchOpen = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "searchOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "showSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showSearch"), _class2.prototype), _class2);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TraitListEdit);
});