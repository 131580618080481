define("plutof/components/collection/repositories", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @repositories.length}}
      as |panel|
  >
      <panel.header
          @title={{i18n-t "collection.repository.repositories"}}
          @count={{@repositories.length}}
      >
  
          <panel.button @clicked={{this.add}}>
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      <panel.content>
          <div class="table-responsive">
              <table class="data-table table-hover">
                  <thead>
                      <th colspan="2">
                          {{i18n-t "collection.repository.name"}}
                      </th>
                  </thead>
  
                  <tbody>
                      <Validation::SplitContext
                          @context={{@validationContext}}
                          @items={{@repositories}}
                          as |repository context|
                      >
                          <Collection::Repository
                              @repository={{repository}}
                              @validationContext={{context}}
                              @remove={{fn this.remove repository}} />
                      </Validation::SplitContext>
                  </tbody>
              </table>
          </div>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "QNBfLMPR",
    "block": "[[[8,[39,0],null,[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"collection.repository.repositories\"],null],[30,1,[\"length\"]]]],[[\"default\"],[[[[1,\"\\n\\n        \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[30,0,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n            \"],[10,\"table\"],[14,0,\"data-table table-hover\"],[12],[1,\"\\n                \"],[10,\"thead\"],[12],[1,\"\\n                    \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[1,\"\\n                        \"],[1,[28,[35,2],[\"collection.repository.name\"],null]],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[10,\"tbody\"],[12],[1,\"\\n                    \"],[8,[39,3],null,[[\"@context\",\"@items\"],[[30,3],[30,1]]],[[\"default\"],[[[[1,\"\\n                        \"],[8,[39,4],null,[[\"@repository\",\"@validationContext\",\"@remove\"],[[30,4],[30,5],[28,[37,5],[[30,0,[\"remove\"]],[30,4]],null]]],null],[1,\"\\n                    \"]],[4,5]]]]],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@repositories\",\"panel\",\"@validationContext\",\"repository\",\"context\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"validation/split-context\",\"collection/repository\",\"fn\"]]",
    "moduleName": "plutof/components/collection/repositories.hbs",
    "isStrictMode": false
  });
  let CollectionRepositories = _exports.default = (_class = class CollectionRepositories extends _component.default {
    add() {
      const newRepository = this.store.createRecord('agent/repository');
      this.repositories.pushObject(newRepository);
    }
    remove(repository) {
      this.objectsToDelete.pushObject(repository);
      this.repositories.removeObject(repository);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionRepositories);
});