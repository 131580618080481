define("plutof/components/information/content/taxon/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="taxon/general" @locale={{@locale}} />
  
  <h4> GBIF import </h4>
  
  <Information::MarkdownReader @path="taxon/gbif-import" @locale={{@locale}} />
  
  */
  {
    "id": "o8a3rayR",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@locale\"],[\"taxon/general\",[30,1]]],null],[1,\"\\n\\n\"],[10,\"h4\"],[12],[1,\" GBIF import \"],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@path\",\"@locale\"],[\"taxon/gbif-import\",[30,1]]],null],[1,\"\\n\"]],[\"@locale\"],false,[\"information/markdown-reader\"]]",
    "moduleName": "plutof/components/information/content/taxon/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});