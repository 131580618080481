define("plutof/components/plutof-panel/item-list/footer", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel-title-container">
      {{! h4 ensures uniform line height with the panel header }}
      <h4 class="panel-title">
          <span>&nbsp;</span>
      </h4>
  </div>
  
  <div class="panel-header-controls panel-header-controls--flat">
      <div class="plutof-btn__controls-container">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "VEbOZwqb",
    "block": "[[[10,0],[14,0,\"panel-title-container\"],[12],[1,\"\\n\"],[1,\"    \"],[10,\"h4\"],[14,0,\"panel-title\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\" \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"panel-header-controls panel-header-controls--flat\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-btn__controls-container\"],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/item-list/footer.hbs",
    "isStrictMode": false
  });
  let Footer = (_dec = (0, _component2.classNames)('panel-footer', 'simplified-selection__footer'), _dec(_class = class Footer extends _component.default {}) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Footer);
});