define("plutof/templates/components/menu/side-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Mf5GEHpS",
    "block": "[[[10,0],[15,0,[29,[[52,[30,1],\"sidebar collapsed\",\"sidebar\"]]]],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[14,0,\"sidebar__brand visible-xs\"],[14,\"tabindex\",\"1\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n            \"],[10,\"img\"],[14,0,\"sidebar__brand__logo\"],[14,\"src\",\"/assets/images/PlutoF-assets/plutof-logo-green-cropped.svg\"],[12],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[11,\"button\"],[24,0,\"sidebar__toggle-btn visible-xs\"],[4,[38,2],[\"click\",[30,0,[\"toggleMenuCollapse\"]]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"fas fa-bars fa-2x\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[11,0],[24,0,\"visible-xs\"],[4,[38,3],[[30,0,[\"burgerMenuOpen\"]]],null],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@collapseMenu\",\"@logOut\",\"@changeLanguage\",\"@menuItemClicked\"],[[30,1],[30,0,[\"_logOut\"]],[30,0,[\"_changeLanguage\"]],[30,0,[\"collapseBurgerMenu\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[10,0],[14,0,\"hidden-xs\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@collapseMenu\",\"@logOut\",\"@changeLanguage\",\"@menuItemClicked\"],[[30,1],[30,0,[\"_logOut\"]],[30,0,[\"_changeLanguage\"]],[30,0,[\"collapseBurgerMenu\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[11,\"button\"],[16,0,[29,[\"sidebar__toggle-btn hidden-xs \",[52,[30,1],\"collapsed\"]]]],[4,[38,2],[\"click\",[30,0,[\"toggleSidebar\"]]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"fas fa-bars fa-2x\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@collapseMenu\"],false,[\"if\",\"link-to\",\"on\",\"collapse\",\"menu/menu-tree\"]]",
    "moduleName": "plutof/templates/components/menu/side-bar.hbs",
    "isStrictMode": false
  });
});