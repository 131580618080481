define("plutof/components/auto-complete/bound-model", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO: Just inline this everywhere? }}
  {{! Replacement for a lot of classic auto-complete uses}}
  {{#async/bind-relation @value as |value update|}}
      <AutoComplete::Model
          @model={{@model}}
          @value={{value}}
          @update={{update}}
          @disabled={{@disabled}}
          @filters={{@filters}}
          @params={{@params}}
          ...attributes />
  {{/async/bind-relation}}
  
  */
  {
    "id": "ShVZJ0ic",
    "block": "[[[6,[39,0],[[30,1]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],[[17,4]],[[\"@model\",\"@value\",\"@update\",\"@disabled\",\"@filters\",\"@params\"],[[30,5],[30,2],[30,3],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[2,3]]]]]],[\"@value\",\"value\",\"update\",\"&attrs\",\"@model\",\"@disabled\",\"@filters\",\"@params\"],false,[\"async/bind-relation\",\"auto-complete/model\"]]",
    "moduleName": "plutof/components/auto-complete/bound-model.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});