define("plutof/components/auth/password-change", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "plutof/controllers/step-one-register", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _computed, _service, _utils, _stepOneRegister, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form class="password-change-form">
      {{#if @showOldPasswordField}}
          <Login::FormRow
              @label={{i18n-t "Settings.oldPw"}}
              @value={{this.oldPassword}}
              @isValid={{this.oldPasswordIsValid}}
          >
              <Input
                  @value={{this.oldPassword}}
                  @type="password"
                  autocomplete="current-password"
                  placeholder={{i18n-t "Settings.oldPw"}}
                  class="form-control"
                  required="true" />
          </Login::FormRow>
      {{/if}}
  
      <Login::FormRow
          @label={{i18n-t "Settings.newPw"}}
          @help={{i18n-t "Registration.help.password"}}
          @value={{this.newPassword}}
          @isValid={{this.newPasswordIsValid}}
      >
          <Input
              @type="password"
              @value={{this.newPassword}}
              autocomplete="new-password"
              placeholder={{i18n-t "Settings.newPw"}}
              class="form-control" />
      </Login::FormRow>
  
      <Login::FormRow
          @label={{i18n-t "Settings.repeatPw"}}
          @value={{this.confirmation}}
          @isValid={{this.confirmationIsValid}}
      >
          <Input
              @type="password"
              @value={{this.confirmation}}
              autocomplete="new-password"
              placeholder={{i18n-t "Settings.repeatPw"}}
              class="form-control" />
      </Login::FormRow>
  </form>
  
  <div class="spacer-large-below"></div>
  
  <div>
      <Common::SaveButton
          @save={{this.changePassword}}
          @disabled={{not this.isValid}} />
  </div>
  
  */
  {
    "id": "RfO5fHPM",
    "block": "[[[10,\"form\"],[14,0,\"password-change-form\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@isValid\"],[[28,[37,2],[\"Settings.oldPw\"],null],[30,0,[\"oldPassword\"]],[30,0,[\"oldPasswordIsValid\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],[[24,\"autocomplete\",\"current-password\"],[16,\"placeholder\",[28,[37,2],[\"Settings.oldPw\"],null]],[24,0,\"form-control\"],[24,\"required\",\"true\"]],[[\"@value\",\"@type\"],[[30,0,[\"oldPassword\"]],\"password\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@label\",\"@help\",\"@value\",\"@isValid\"],[[28,[37,2],[\"Settings.newPw\"],null],[28,[37,2],[\"Registration.help.password\"],null],[30,0,[\"newPassword\"]],[30,0,[\"newPasswordIsValid\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"autocomplete\",\"new-password\"],[16,\"placeholder\",[28,[37,2],[\"Settings.newPw\"],null]],[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"newPassword\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@label\",\"@value\",\"@isValid\"],[[28,[37,2],[\"Settings.repeatPw\"],null],[30,0,[\"confirmation\"]],[30,0,[\"confirmationIsValid\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"autocomplete\",\"new-password\"],[16,\"placeholder\",[28,[37,2],[\"Settings.repeatPw\"],null]],[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"confirmation\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@save\",\"@disabled\"],[[30,0,[\"changePassword\"]],[28,[37,5],[[30,0,[\"isValid\"]]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@showOldPasswordField\"],false,[\"if\",\"login/form-row\",\"i18n-t\",\"input\",\"common/save-button\",\"not\"]]",
    "moduleName": "plutof/components/auth/password-change.hbs",
    "isStrictMode": false
  });
  let PasswordChange = _exports.default = (_dec = (0, _computed.notEmpty)('oldPassword'), _dec2 = (0, _computed.match)('newPassword', _stepOneRegister.PASSWORD_REGEX), _class = class PasswordChange extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "i18n", _descriptor2, this);
      _initializerDefineProperty(this, "oldPassword", _descriptor3, this);
      _initializerDefineProperty(this, "newPassword", _descriptor4, this);
      _initializerDefineProperty(this, "confirmation", _descriptor5, this);
      _initializerDefineProperty(this, "oldPasswordIsValid", _descriptor6, this);
      _initializerDefineProperty(this, "newPasswordIsValid", _descriptor7, this);
    }
    get confirmationIsValid() {
      return this.newPassword === this.confirmation && !(0, _utils.isEmpty)(this.newPassword) && this.newPasswordIsValid;
    }
    get isValid() {
      return (!this.args.showOldPasswordField || this.oldPasswordIsValid) && this.newPasswordIsValid && this.confirmationIsValid;
    }
    get displayPasswordConfimHint() {
      return (0, _utils.isPresent)(this.newPassword) && (0, _utils.isPresent)(this.confirmation) && !this.confirmationIsValid;
    }
    async changePassword() {
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_repeat: this.confirmation
      };
      if (this.args.key) {
        data.key = this.args.key;
      }
      try {
        await this.ajax.put('/profile/personalsettings/change-password/', {
          data
        });
        (0, _notifications.displayNotification)('success', this.i18n.translate('General.done'));
        if (this.args.changed) {
          await this.args.changed();
        }
      } catch (error) {
        (0, _notifications.reportError)(error);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "oldPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "oldPasswordIsValid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "newPasswordIsValid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changePassword", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePassword"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PasswordChange);
});