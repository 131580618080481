define("plutof/components/clipboard/map", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-concurrency", "plutof/misc/config", "plutof/utils/loading"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberConcurrency, _config, _loading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@tabGroup.header @name="map">
      {{i18n-t "Clipboard.showOnMap"}}
  
      <span class="count-badge">
          {{locale-string this.selectedGeomsCount}}
      </span>
  </@tabGroup.header>
  
  <@tabGroup.tab @name="map" @dontPrecreate={{true}}>
      {{on-change this.updateIfNecessary @version}}
  
      <Layout::SplitRow>
          <:right>
              <button
                  {{on "click" this.toggleFullMap}}
                  class="plutof-btn__controls plutof-btn__controls--xs"
              >
                  {{#if this.fullMapEnabled}}
                      {{i18n-t "Clipboard.unloadFullMap"}}
                  {{else}}
                      {{i18n-t "Clipboard.loadFullMap"}}
                  {{/if}}
              </button>
          </:right>
      </Layout::SplitRow>
  
      <div class="spacing"></div>
  
      <Clipboard::ClipboardMap
          @entries={{this.mapEntries}}
          @viewRoute={{@viewRoute}}
          @loading={{this.updateFullMap.isRunning}} />
  </@tabGroup.tab>
  
  */
  {
    "id": "qIIj6wQ4",
    "block": "[[[8,[30,1,[\"header\"]],null,[[\"@name\"],[\"map\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"Clipboard.showOnMap\"],null]],[1,\"\\n\\n    \"],[10,1],[14,0,\"count-badge\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"selectedGeomsCount\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1,[\"tab\"]],null,[[\"@name\",\"@dontPrecreate\"],[\"map\",true]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"updateIfNecessary\"]],[30,2]],null]],[1,\"\\n\\n    \"],[8,[39,3],null,null,[[\"right\"],[[[[1,\"\\n            \"],[11,\"button\"],[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"],[4,[38,4],[\"click\",[30,0,[\"toggleFullMap\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"fullMapEnabled\"]],[[[1,\"                    \"],[1,[28,[35,0],[\"Clipboard.unloadFullMap\"],null]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[28,[35,0],[\"Clipboard.loadFullMap\"],null]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacing\"],[12],[13],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@entries\",\"@viewRoute\",\"@loading\"],[[30,0,[\"mapEntries\"]],[30,3],[30,0,[\"updateFullMap\",\"isRunning\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@tabGroup\",\"@version\",\"@viewRoute\"],false,[\"i18n-t\",\"locale-string\",\"on-change\",\"layout/split-row\",\"on\",\"if\",\"clipboard/clipboard-map\"]]",
    "moduleName": "plutof/components/clipboard/map.hbs",
    "isStrictMode": false
  });
  // XXX: This is all just preserving the way the original map
  // was written, with its tight connection to clipboard controller.
  //
  // It should, of course, be changed to heatmap, same as search, and
  // instead of iterating through clipboard records, just get summarized
  // data from the API
  //
  // Anyway, not here to refactor things properly atm
  let ClipboardMapTab = _exports.default = (_dec = (0, _object.computed)('fullMapEnabled', 'args.selectedEntries.[]', 'fullMap'), _dec2 = (0, _object.computed)('args.selectedEntries.[]'), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class ClipboardMapTab extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fullMapEnabled", _descriptor, this);
      _initializerDefineProperty(this, "fullMap", _descriptor2, this);
      _defineProperty(this, "fullMapVersion", 0);
      _initializerDefineProperty(this, "updateFullMap", _descriptor3, this);
    }
    get mapEntries() {
      return this.fullMapEnabled ? this.fullMap : this.args.selectedEntries;
    }
    get selectedGeomsCount() {
      return this.args.selectedEntries.filterBy('geom').length;
    }
    updateIfNecessary() {
      if (this.fullMapEnabled && !this.args.version !== this.fullMapVersion) {
        this.updateFullMap.perform();
      }
    }
    toggleFullMap() {
      this.fullMapEnabled = !this.fullMapEnabled;
      this.updateIfNecessary();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fullMapEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fullMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "mapEntries", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "mapEntries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedGeomsCount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectedGeomsCount"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updateFullMap", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const getCount = () => this.args.pagination.objectCount;
        const entries = yield (0, _loading.loadAllGeneric)(getCount, (num, size) => this.args.pagination.loadAndProcessPage(num, size), _config.default.Clipboard.MAP_BATCH_SIZE);
        this.fullMapVersion = this.args.version;
        this.fullMap = entries;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateIfNecessary", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateIfNecessary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleFullMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFullMap"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ClipboardMapTab);
});