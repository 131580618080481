define("plutof/mixins/route-title", ["exports", "@ember/object/mixin", "@ember/utils"], function (_exports, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    buildRouteInfoMetadata() {
      return {
        // XXX: Why is currentModel sometimes null?
        // XXX: this.title workaround for modelless routes
        title: this.currentModel || this.title ? this.getTitle(this.currentModel) : null
      };
    },
    getTitle(model) {
      if (!(0, _utils.isNone)(this.title)) {
        return this.i18n.t(this.title);
      }
      if (!(0, _utils.isNone)(this.modelTitle) && !(0, _utils.isNone)(model)) {
        return model[this.modelTitle].representation;
      }
      return null;
    }
  });
});