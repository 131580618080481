define("plutof/components/publishing/gbif/records/new-record", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _component2, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="{{if validations.attrs.record.record.isInvalid 'has-error'}}">
      {{#async/bind-relation @record.record as |value update|}}
          <AutoComplete::Generic
              @model={{@type.model}}
              @value={{value}}
              @update={{update}}
              @filters={{hash access="edit"}}
              @mini={{true}} />
      {{/async/bind-relation}}
  </td>
  
  <td colspan="5">
      {{! Nothing here, just aligning with existing record rows !}}
  </td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-link data-table-btn"
      >
          <span class="icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "BJ0Z2NQv",
    "block": "[[[10,\"td\"],[15,0,[29,[[52,[33,1,[\"attrs\",\"record\",\"record\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n\"],[6,[39,2],[[30,1,[\"record\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,3],null,[[\"@model\",\"@value\",\"@update\",\"@filters\",\"@mini\"],[[30,4,[\"model\"]],[30,2],[30,3],[28,[37,4],null,[[\"access\"],[\"edit\"]]],true]],null],[1,\"\\n\"]],[2,3]]]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,\"colspan\",\"5\"],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,5],[\"click\",[30,5]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\",\"value\",\"update\",\"@type\",\"@remove\"],false,[\"if\",\"validations\",\"async/bind-relation\",\"auto-complete/generic\",\"hash\",\"on\"]]",
    "moduleName": "plutof/components/publishing/gbif/records/new-record.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'record.record': (0, _emberCpValidations.validator)('presence', true)
  });
  let NewRecord = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class NewRecord extends _component.default.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NewRecord);
});