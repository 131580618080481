define("plutof/templates/components/clipboard/navbar-button-new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AYI2KfJu",
    "block": "[[[8,[30,1,[\"button\"]],[[24,\"data-test\",\"toggle-clipboard\"]],[[\"@icon\",\"@clicked\",\"@disabled\"],[[30,0,[\"icon\"]],[30,0,[\"toggle\"]],[30,0,[\"resolve\",\"isRunning\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"General.Clipboard\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@navbar\"],false,[\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/clipboard/navbar-button-new.hbs",
    "isStrictMode": false
  });
});