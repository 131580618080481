define("plutof/components/search/filters/-label-control-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @filter}}
      <ToolTip @tooltip={{@tooltip}}>
          <button
              {{on "click" this.toggleFilter}}
              disabled={{@disabled}}
              class="label-control__btn {{if @filter.value 'label-control__btn--active'}}"
          >
              {{@label}}
          </button>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "wpZ26pKN",
    "block": "[[[41,[30,1],[[[1,\"    \"],[8,[39,1],null,[[\"@tooltip\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[30,3]],[16,0,[29,[\"label-control__btn \",[52,[30,1,[\"value\"]],\"label-control__btn--active\"]]]],[4,[38,2],[\"click\",[30,0,[\"toggleFilter\"]]],null],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@filter\",\"@tooltip\",\"@disabled\",\"@label\"],false,[\"if\",\"tool-tip\",\"on\"]]",
    "moduleName": "plutof/components/search/filters/-label-control-toggle.hbs",
    "isStrictMode": false
  });
  let SearchLabelControlToggle = _exports.default = (_class = class SearchLabelControlToggle extends _component2.default {
    toggleFilter() {
      const newValue = !this.args.filter.value;
      (0, _object.set)(this.args.filter, 'value', newValue);
      if (this.args.valueChanged) {
        this.args.valueChanged(newValue);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "toggleFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilter"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchLabelControlToggle);
});