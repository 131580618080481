define("plutof/components/access/-view-rights/view-selected", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      <div class="plutof-label">
          {{i18n-t "Access.canView"}}
      </div>
  
      <p>
          <span class="icon-access-rights"></span>
  
          {{access/-view-rights/agent-list
              usernames=@usernames
              groups=@groups}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "BEPkHoFb",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"Access.canView\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-access-rights\"],[12],[13],[1,\"\\n\\n        \"],[1,[28,[35,2],null,[[\"usernames\",\"groups\"],[[30,1],[30,2]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@usernames\",\"@groups\"],false,[\"access/-view-rights/column\",\"i18n-t\",\"access/-view-rights/agent-list\"]]",
    "moduleName": "plutof/components/access/-view-rights/view-selected.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});