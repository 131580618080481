define("plutof/templates/components/sample/navbar-buttons/detach", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZMtb1Iwk",
    "block": "[[[41,[30,0,[\"ownedByCurrentUser\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"Sample.detach.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@clicked\"],[[28,[37,3],[\"detach\"],null],[30,0,[\"detach\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"Sample.detach.label\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@navbar\"],false,[\"if\",\"tool-tip\",\"i18n-t\",\"icon\"]]",
    "moduleName": "plutof/templates/components/sample/navbar-buttons/detach.hbs",
    "isStrictMode": false
  });
});