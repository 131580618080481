define("plutof/components/profile/groups/managing-group/-view/row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td>{{@membership.user_full_name}}</td>
  
      {{! Unapproved status is not in choices}}
      {{#if @pending}}
          <td class="user-pending-approval">
              {{i18n-t "group.status.0"}}
          </td>
      {{else}}
          <td >
              {{get @translations.status.choices @membership.status}}
          </td>
      {{/if}}
  
      {{#if @includeModerationRights}}
          <td>
              {{get @translations.rights.choices @membership.rights}}
          </td>
      {{/if}}
  
      {{#if @includeAcceptedBy}}
          <td>{{@membership.accepted_by_name}}</td>
      {{/if}}
  
      <td>{{formatted-date @membership.created_at}}</td>
  </tr>
  
  */
  {
    "id": "Nv+wnkrc",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[12],[1,[30,1,[\"user_full_name\"]]],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"td\"],[14,0,\"user-pending-approval\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"group.status.0\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"td\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[[30,3,[\"status\",\"choices\"]],[30,1,[\"status\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,\"td\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[[30,3,[\"rights\",\"choices\"]],[30,1,[\"rights\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[10,\"td\"],[12],[1,[30,1,[\"accepted_by_name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,\"td\"],[12],[1,[28,[35,3],[[30,1,[\"created_at\"]]],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@membership\",\"@pending\",\"@translations\",\"@includeModerationRights\",\"@includeAcceptedBy\"],false,[\"if\",\"i18n-t\",\"get\",\"formatted-date\"]]",
    "moduleName": "plutof/components/profile/groups/managing-group/-view/row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});