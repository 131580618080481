define("plutof/controllers/sequence/parse-file", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SequenceParseFileController = (_dec = (0, _object.computed)('model.content'), _class = class SequenceParseFileController extends _controller.default {
    get sequences() {
      return this.model.content.map(([id, sequence]) => ({
        id,
        sequence
      }));
    }
    buildAlignmentConfig(fields) {
      return {
        noClusterMap: true,
        stickyColumns: 1,
        fields: [fields.text(this.i18n.t('Sequences.id'), seq => seq.id),
        // XXX: If entire table is sticky, layout gets messed up
        // TODO: Fix in renderer, it's a bit more involved than just CSS
        fields.text('', seq => '')],
        nucleotides: {
          width: 12,
          palette: {
            'A': ['white', '#fb0018'],
            'G': ['black', '#fffd33'],
            'C': ['black', '#38fd2a'],
            'T': ['white', '#0022fb'],
            'default': ['black', '#dddddd']
          }
        },
        guideline: {
          step: 10
        }
      };
    }
  }, _applyDecoratedDescriptor(_class.prototype, "sequences", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sequences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildAlignmentConfig", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "buildAlignmentConfig"), _class.prototype), _class);
  var _default = _exports.default = SequenceParseFileController;
});