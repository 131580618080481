define("plutof/templates/components/unite/tree-statistics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "efVSg3bI",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"tree-statistics\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"isTerminal\"]],[[[1,\"            \"],[10,1],[14,0,\"fas fa-asterisk headerIcon\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,1],[16,0,[29,[\"headerIcon clickable\\n                \",[52,[33,1],\"expanded\",\"collapsed\"],\"\\n                \",[52,[33,2,[\"isPending\"]],\"loading\",\"not-loading\"]]]],[4,[38,3],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[11,3],[16,0,[29,[\"clickable \",[52,[30,0,[\"inPath\"]],\"in-path\"],\" \",[52,[30,0,[\"isLastInPath\"]],\"last-in-path\"]]]],[4,[38,3],[\"click\",[30,0,[\"select\"]]],null],[12],[1,\"\\n            \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"              (\"],[1,[30,1]],[1,\")\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"        \"],[10,0],[14,0,\"children\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"children\"]]],null]],null],null,[[[1,\"                \"],[8,[39,6],null,[[\"@path\",\"@threshold\",\"@version\",\"@includeSingletons\",\"@parentTaxon\",\"@parentLevel\",\"@level\",\"@taxon\",\"@count\",\"@nodeSelected\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,2,[\"level\"]],[30,2,[\"taxon\"]],[30,2,[\"count\"]],[30,9]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@count\",\"child\",\"@path\",\"@threshold\",\"@version\",\"@includeSingletons\",\"@taxon\",\"@level\",\"@nodeSelected\"],false,[\"if\",\"expanded\",\"children\",\"on\",\"each\",\"-track-array\",\"unite/tree-statistics\"]]",
    "moduleName": "plutof/templates/components/unite/tree-statistics.hbs",
    "isStrictMode": false
  });
});