define("plutof/components/search/filters/-range-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "plutof/utils/search/filters"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{this.styleClasses}}">
      <div class="plutof-label">
          {{@filter.label}}
      </div>
  
      <div class="input-group {{@inputGroupClass}}">
          {{yield to="lower"}}
  
          <span class="input-group-addon {{@inputGroupClass}}__addon">
              <PlutofSelect::Object
                  @content={{this.operators}}
                  @oneWay={{true}}
                  @selection={{@filter.rangeOperator}}
                  @selectionChanged={{this.updateOperator}}
                  @optionValuePath="id"
                  @optionLabelPath="id"
                  @prompt={{null}}
                  @selectClass="{{@inputGroupClass}}__addon__select clickable" />
          </span>
  
          {{yield this.disableUpperBound to="upper"}}
      </div>
  </div>
  
  */
  {
    "id": "8vLmZTY8",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"styleClasses\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"input-group \",[30,2]]]],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n\\n        \"],[10,1],[15,0,[29,[\"input-group-addon \",[30,2],\"__addon\"]]],[12],[1,\"\\n            \"],[8,[39,1],null,[[\"@content\",\"@oneWay\",\"@selection\",\"@selectionChanged\",\"@optionValuePath\",\"@optionLabelPath\",\"@prompt\",\"@selectClass\"],[[30,0,[\"operators\"]],true,[30,1,[\"rangeOperator\"]],[30,0,[\"updateOperator\"]],\"id\",\"id\",null,[29,[[30,2],\"__addon__select clickable\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[18,4,[[30,0,[\"disableUpperBound\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@filter\",\"@inputGroupClass\",\"&lower\",\"&upper\"],false,[\"yield\",\"plutof-select/object\"]]",
    "moduleName": "plutof/components/search/filters/-range-input.hbs",
    "isStrictMode": false
  });
  let SearchGenericRangeInput = (_class = class SearchGenericRangeInput extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "operators", [_filters.RangeOperator.Equal, _filters.RangeOperator.Inclusive, _filters.RangeOperator.Exclusive]);
    }
    get disableUpperBound() {
      return this.args.filter.rangeOperator === _filters.RangeOperator.Equal;
    }
    updateOperator(operator) {
      this.args.filter.rangeOperator = operator;
      if (operator === _filters.RangeOperator.Equal) {
        const {
          lowerBound,
          upperBound
        } = this.args.filter;
        if ((0, _utils.isEmpty)(lowerBound) && !(0, _utils.isEmpty)(upperBound)) {
          this.args.filter.lowerBound = upperBound;
        }
        this.args.filter.upperBound = '';
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateOperator", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateOperator"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchGenericRangeInput);
});