define("plutof/components/search/filter-panels/reference-based-properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput
              @filter={{@filterInputs.study}}
              @autocompletePreset="study/study" />
  
          <Search::FilterInput @filter={{@filterInputs.references}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}}  />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "UgN0l+pU",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"study\"]],\"study/study\"]],null],[1,\"\\n\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"references\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"keywords\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"search/filter-panels/common-properties/agents\",\"layout/regular-grid\",\"search/filter-input\",\"search/filter-panels/common-properties/checkboxes\"]]",
    "moduleName": "plutof/components/search/filter-panels/reference-based-properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});