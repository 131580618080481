define("plutof/components/tables/-list-view/clipboard", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.clipboard"}}>
      <Clipboard::ListViewClipboardButton
          @contentType={{@contentType}}
          @entry={{@record}} />
  </@Field>
  
  */
  {
    "id": "2jzO+hGZ",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"PlutofListViewColumn.clipboard\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@contentType\",\"@entry\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@Field\",\"@contentType\",\"@record\"],false,[\"i18n-t\",\"clipboard/list-view-clipboard-button\"]]",
    "moduleName": "plutof/components/tables/-list-view/clipboard.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});