define("plutof/components/information/content/area/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::Content::General::ListView @locale={{@locale}} />
  <Information::Content::General::AreaEvent @locale={{@locale}} />
  
  */
  {
    "id": "NzJlOgxK",
    "block": "[[[8,[39,0],null,[[\"@locale\"],[[30,1]]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@locale\"],[[30,1]]],null],[1,\"\\n\"]],[\"@locale\"],false,[\"information/content/general/list-view\",\"information/content/general/area-event\"]]",
    "moduleName": "plutof/components/information/content/area/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});