define("plutof/components/login/form-row", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="registration-form-row">
      <div class="registration-form-row__input">
          {{#if (and @isValid (not @isConfirmed))}}
              <div class="login-input-status__warning visible-xs">
                  {{@warning}}
              </div>
          {{/if}}
  
          <Login::FormInput
              @label={{@label}}
              @help={{@help}}
              @value={{@value}}
              @isValid={{and @isValid this._isConfirmed}}
          >
              {{yield}}
          </Login::FormInput>
      </div>
  
      <Login::FormInputStatus
          @isValid={{@isValid}}
          @isConfirmed={{this._isConfirmed}}
          @warning={{@warning}} />
  </div>
  
  */
  {
    "id": "MWQyyjvz",
    "block": "[[[10,0],[14,0,\"registration-form-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"registration-form-row__input\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],[28,[37,2],[[30,2]],null]],null],[[[1,\"            \"],[10,0],[14,0,\"login-input-status__warning visible-xs\"],[12],[1,\"\\n                \"],[1,[30,3]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@label\",\"@help\",\"@value\",\"@isValid\"],[[30,4],[30,5],[30,6],[28,[37,1],[[30,1],[30,0,[\"_isConfirmed\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[18,7,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@isValid\",\"@isConfirmed\",\"@warning\"],[[30,1],[30,0,[\"_isConfirmed\"]],[30,3]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isValid\",\"@isConfirmed\",\"@warning\",\"@label\",\"@help\",\"@value\",\"&default\"],false,[\"if\",\"and\",\"not\",\"login/form-input\",\"yield\",\"login/form-input-status\"]]",
    "moduleName": "plutof/components/login/form-row.hbs",
    "isStrictMode": false
  });
  class LoginFormRow extends _component2.default {
    get _isConfirmed() {
      if ((0, _utils.isPresent)(this.args.isConfirmed)) {
        return this.args.isConfirmed;
      }
      return this.args.isValid;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LoginFormRow);
});