define("plutof/templates/components/resolve-promise", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "k7ipvoQS",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,1]],null],[30,0,[\"resolve\",\"last\",\"isSuccessful\"]]],null],[[[1,\"    \"],[18,2,[[30,0,[\"resolve\",\"last\",\"value\"]],[30,0,[\"resolve\",\"last\",\"isSuccessful\"]],[30,0,[\"resolve\",\"isRunning\"]]]],[1,\"\\n\"]],[]],null]],[\"@wait\",\"&default\"],false,[\"if\",\"or\",\"not\",\"yield\"]]",
    "moduleName": "plutof/templates/components/resolve-promise.hbs",
    "isStrictMode": false
  });
});