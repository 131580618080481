define("plutof/templates/components/tooltip-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rzqw1os+",
    "block": "[[[18,1,[[30,0],[30,0,[\"shown\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "plutof/templates/components/tooltip-wrapper.hbs",
    "isStrictMode": false
  });
});