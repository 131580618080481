define("plutof/components/my-menu/measurements", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "@ember-decorators/component", "@ember-decorators/object", "ember-concurrency", "plutof/utils/notifications"], function (_exports, _component, _object, _service, _utils, _component2, _object2, _emberConcurrency, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MyMenuMeasurements = (_dec = (0, _component2.classNames)('my-menu-measurements'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec3 = (0, _object.computed)('query', 'resultsQuery', 'results.[]'), _dec4 = (0, _object2.observes)('personalMenu.measurementsRequestModule'), _dec(_class = (_class2 = class MyMenuMeasurements extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "personalMenu", _descriptor2, this);
      _defineProperty(this, "query", '');
      _defineProperty(this, "resultsQuery", null);
      _defineProperty(this, "results", []);
      _initializerDefineProperty(this, "search", _descriptor3, this);
    }
    get noResults() {
      return this.query === this.resultsQuery && this.results.length === 0;
    }
    clearResultsOnModuleChange() {
      this.setProperties({
        query: '',
        resultsQuery: null,
        results: []
      });
    }
    runSearch() {
      this.search.perform();
    }
    removeSelected(measurement) {
      this.personalMenu.selectedMeasurements.removeObject(measurement);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "personalMenu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        let params = {
          name: this.query
        };
        if (!(0, _utils.isEmpty)(this.personalMenu.measurementsRequestModule)) {
          params['module'] = this.personalMenu.measurementsRequestModule;
        }
        try {
          const response = yield this.ajax.request('/measurement/measurements/autocomplete/', {
            data: params
          });
          this.set('results', response.results);
          this.set('resultsQuery', this.query);
        } catch (err) {
          (0, _notifications.reportError)(err);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "noResults", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "noResults"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearResultsOnModuleChange", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "clearResultsOnModuleChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "runSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "runSearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeSelected", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "removeSelected"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = MyMenuMeasurements;
});