define("plutof/components/glossary/collection/general-data", ["exports", "@ember/component", "@ember/template-factory", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Glossary::Collection::GeneralData"
  >
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <div class="{{has-error this 'collection.name'}}">
              <PlutofLabelc @path="glossary.collection.name" />
  
              <Input
                  @type="text"
                  @value={{@collection.name}}
                  class="form-control default-focus-control"
                  data-test="name" />
  
          </div>
  
          <div class="{{has-error this 'collection.description'}}">
              <PlutofLabelc @path="glossary.collection.description" />
  
              <FlexibleTextarea
                  @type="text"
                  @value={{@collection.description}}
                  class="form-control"
                  data-test="description" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "DysswED/",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Glossary::Collection::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[[28,[37,3],[[30,0],\"collection.name\"],null]]]],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@path\"],[\"glossary.collection.name\"]],null],[1,\"\\n\\n            \"],[8,[39,5],[[24,0,\"form-control default-focus-control\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"name\"]]]],null],[1,\"\\n\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[15,0,[29,[[28,[37,3],[[30,0],\"collection.description\"],null]]]],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@path\"],[\"glossary.collection.description\"]],null],[1,\"\\n\\n            \"],[8,[39,6],[[24,0,\"form-control\"],[24,\"data-test\",\"description\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"description\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@collection\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"has-error\",\"plutof-labelc\",\"input\",\"flexible-textarea\"]]",
    "moduleName": "plutof/components/glossary/collection/general-data.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'collection.name': [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('length', {
      max: 1024
    })],
    'collection.description': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  class GlossaryCollectionGeneral extends _component.default.extend(Validations, _componentValidations.default) {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlossaryCollectionGeneral);
});