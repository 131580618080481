define("plutof/templates/components/datacite/related-identifier-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "9njh/Yj/",
    "block": "[[[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"data-test\",\"related_dataset\"]],[[\"@model\",\"@value\",\"@params\"],[\"datacite/metadata\",[30,1,[\"related_dataset\"]],[28,[37,1],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[28,[37,2],[[30,0],\"identifier.relation_type\"],null]]]],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"selectClass\"],[[33,4],[30,1,[\"relation_type\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[28,[37,2],[[30,0],\"identifierValidity\"],null]]]],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"type\",\"value\",\"disabled\",\"class\"],[\"text\",[30,1,[\"related_identifier\"]],[33,6],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[28,[37,2],[[30,0],\"identifierTypeValidity\"],null]]]],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"disabled\",\"selectClass\"],[[33,7],[30,1,[\"related_identifier_type\"]],[33,6],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[28,[37,2],[[30,0],\"resourceTypeValidity\"],null]]]],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"value\",\"disabled\",\"selectClass\"],[[33,8],[30,1,[\"resource_type_general\"]],[33,6],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,9],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@identifier\",\"@remove\"],false,[\"auto-complete/bound-model\",\"hash\",\"has-error\",\"plutof-select/value\",\"relationTypes\",\"input\",\"isExisting\",\"relatedIdentifierTypes\",\"resourceTypes\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/related-identifier-row-form.hbs",
    "isStrictMode": false
  });
});