define("plutof/templates/components/common/button-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iV965tXG",
    "block": "[[[11,0],[24,0,\"button-group\"],[17,1],[12],[1,\"\\n    \"],[18,4,[[28,[37,1],null,[[\"button\"],[[50,\"common/-button-group-button\",0,null,[[\"active\",\"activate\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@active\",\"@activate\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/common/button-group.hbs",
    "isStrictMode": false
  });
});