define("plutof/components/transaction/view/general-data", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Transaction::View::GeneralData"
  >
      <GenericViews::Record @record={{@transaction}} as |view|>
          <Layout::RegularGrid @columns={{4}}>
              {{view.field "type"}}
              {{view.attr "number"}}
              {{view.link "deposited_in" route="collection.view"}}
              {{view.field "purpose"}}
              {{view.attr "number_of_specimens"}}
              {{view.attr "content"}}
              {{view.attr "comment"}}
              {{view.attr "start_date"}}
              {{view.attr "end_date"}}
              {{view.attr "expected_end_date"}}
              {{view.attr "is_locked"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Xjt/86bL",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Transaction::View::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"type\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"number\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"deposited_in\"],[[\"route\"],[\"collection.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"purpose\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"number_of_specimens\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"content\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"comment\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"start_date\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"end_date\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"expected_end_date\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"is_locked\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@transaction\",\"view\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/transaction/view/general-data.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});