define("plutof/templates/profile/_map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "t9rpVjPH",
    "block": "[[],[],false,[]]",
    "moduleName": "plutof/templates/profile/_map.hbs",
    "isStrictMode": false
  });
});