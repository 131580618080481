define("plutof/components/transaction/view/objects/row/item-view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
      {{@item.item_id}}
  </td>
  
  {{#if @itemType.contains.additionalID}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  {{#if @itemType.contains.taxon}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  <td>
      {{! new_name - Nothing here. }}
  </td>
  
  <td>
      {{! gathering_agents_repr - Nothing here. }}
  </td>
  
  {{#if @itemType.contains.deposited_as}}
      <td>
          {{! Nothing here. }}
      </td>
  {{/if}}
  
  <td>
      {{@item.comment}}
  </td>
  
  <td>
      {{read-only-boolean @item.can_extract_dna}}
  </td>
  
  <td>
      {{read-only-boolean @item.is_returned}}
  </td>
  
  {{#unless @disableControls}}
      <td>
          <Transaction::View::Objects::Row::ViewControls
              @disable={{@disableEdit}}
              @edit={{@edit}}
              @delete={{@delete}} />
      </td>
  {{/unless}}
  
  */
  {
    "id": "RY5jTPFJ",
    "block": "[[[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"item_id\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"contains\",\"additionalID\"]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"contains\",\"taxon\"]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"contains\",\"deposited_as\"]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"comment\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"can_extract_dna\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"is_returned\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[51,[30,3]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@disable\",\"@edit\",\"@delete\"],[[30,4],[30,5],[30,6]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@item\",\"@itemType\",\"@disableControls\",\"@disableEdit\",\"@edit\",\"@delete\"],false,[\"if\",\"read-only-boolean\",\"unless\",\"transaction/view/objects/row/view-controls\"]]",
    "moduleName": "plutof/components/transaction/view/objects/row/item-view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});