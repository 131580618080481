define("plutof/models/taxonomy/taxonnode", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "@ember-data/model", "plutof/models/plutof-model", "plutof/utils/model"], function (_exports, _object, _computed, _utils, _emberData, _model, _plutofModel, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TaxonNode = (_dec = (0, _model.belongsTo)('taxonomy/tree'), _dec2 = (0, _model.belongsTo)('taxonomy/taxonnode', {
    inverse: null
  }), _dec3 = (0, _model.belongsTo)('taxonomy/taxonnode', {
    inverse: null
  }), _dec4 = (0, _model.belongsTo)('taxonomy/taxonnode', {
    inverse: null
  }), _dec5 = (0, _model.belongsTo)('taxonomy/taxonnode', {
    inverse: null
  }), _dec6 = (0, _model.attr)('enum'), _dec7 = (0, _model.belongsTo)('taxonomy/taxonnameconcept'), _dec8 = (0, _model.belongsTo)('taxonomy/taxonnode', {
    inverse: null
  }), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string', {
    defaultValue: ''
  }), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.belongsTo)('taxonomy/taxonrank'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('number'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _model.attr)('boolean'), _dec23 = (0, _model.attr)('boolean'), _dec24 = (0, _computed.reads)('full_taxon_name'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _object.computed)('parent', 'parent.lineage'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.hasMany)('taxonomy/commonname'), _dec30 = (0, _model2.hasmany_property)('taxonomy/act', {
    taxon_node: 'id'
  }), _dec31 = (0, _model.hasMany)('taxonomy/taxonnode', {
    inverse: null
  }), _class = class TaxonNode extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "tree", _descriptor, this);
      _initializerDefineProperty(this, "parent", _descriptor2, this);
      _initializerDefineProperty(this, "parent_described_in", _descriptor3, this);
      _initializerDefineProperty(this, "hybrid_parent_1", _descriptor4, this);
      _initializerDefineProperty(this, "hybrid_parent_2", _descriptor5, this);
      _initializerDefineProperty(this, "notho_rank", _descriptor6, this);
      _initializerDefineProperty(this, "taxon_name_concept", _descriptor7, this);
      _initializerDefineProperty(this, "valid_name", _descriptor8, this);
      _initializerDefineProperty(this, "synonym_type", _descriptor9, this);
      _initializerDefineProperty(this, "epithet", _descriptor10, this);
      _initializerDefineProperty(this, "epithet_author", _descriptor11, this);
      _initializerDefineProperty(this, "year_described_in", _descriptor12, this);
      _initializerDefineProperty(this, "taxon_name", _descriptor13, this);
      _initializerDefineProperty(this, "use_parentheses", _descriptor14, this);
      _initializerDefineProperty(this, "taxon_rank", _descriptor15, this);
      _initializerDefineProperty(this, "nomenclatural_status", _descriptor16, this);
      _initializerDefineProperty(this, "gbif_id", _descriptor17, this);
      _initializerDefineProperty(this, "mycobank_id", _descriptor18, this);
      _initializerDefineProperty(this, "col_id", _descriptor19, this);
      _initializerDefineProperty(this, "is_fossil", _descriptor20, this);
      _initializerDefineProperty(this, "is_informal", _descriptor21, this);
      _initializerDefineProperty(this, "is_valid", _descriptor22, this);
      _initializerDefineProperty(this, "is_deleted", _descriptor23, this);
      _initializerDefineProperty(this, "representation", _descriptor24, this);
      // XXX: Those fields are not saved with node, but rather in the "taxon act"
      // that sometimes (rarely) gets created when taxon is changed
      _initializerDefineProperty(this, "citation_text", _descriptor25, this);
      _initializerDefineProperty(this, "remarks", _descriptor26, this);
      _initializerDefineProperty(this, "full_taxon_name", _descriptor27, this);
      _initializerDefineProperty(this, "vernacular_names", _descriptor28, this);
      _initializerDefineProperty(this, "acts", _descriptor29, this);
      _initializerDefineProperty(this, "synonyms", _descriptor30, this);
    }
    get lineage() {
      return _emberData.default.PromiseArray.create({
        promise: this.parent.then(parent => {
          if ((0, _utils.isNone)(parent)) {
            return [this];
          }
          return parent.lineage.then(parentLineage => {
            return parentLineage.concat(this);
          });
        })
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tree", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent_described_in", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hybrid_parent_1", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hybrid_parent_2", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notho_rank", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "taxon_name_concept", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "valid_name", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "synonym_type", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "epithet", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "epithet_author", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "year_described_in", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "taxon_name", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "use_parentheses", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "taxon_rank", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "nomenclatural_status", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "gbif_id", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "mycobank_id", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "col_id", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "is_fossil", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "is_informal", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "is_valid", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "is_deleted", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "citation_text", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lineage", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "lineage"), _class.prototype), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "full_taxon_name", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "vernacular_names", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "acts", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "synonyms", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = TaxonNode;
});