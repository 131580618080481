define("plutof/mixins/pagination", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/runloop", "@ember/service", "@ember/utils", "ember-concurrency", "ember-inflector", "plutof/misc/config", "plutof/utils/notifications", "plutof/utils/store"], function (_exports, _object, _computed, _mixin, _runloop, _service, _utils, _emberConcurrency, _emberInflector, _config, _notifications, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    ajax: (0, _service.inject)(),
    pageNumber: 1,
    pageCount: 1,
    objectsCount: 0,
    pageLength: _config.default.Common.PAGE_SIZE,
    previousPageNumber: 1,
    isLastPage: false,
    isFirstPage: false,
    pageLoading: false,
    pageError: false,
    pageFound: false,
    multiplePages: false,
    getEmberObjects: true,
    initialLoadFinished: false,
    ordering: '-updated_at',
    // In case fetched records neet to be put somewhere besides <content>
    contentProperty: 'content',
    moreThanOnePage: (0, _object.observer)('pageCount', function () {
      this.set('multiplePages', this.pageCount > 1);
    }),
    disableNextPage: (0, _computed.or)('isLastPage', 'pageLoading'),
    disablePreviousPage: (0, _computed.or)('isFirstPage', 'pageLoading'),
    constructQuery: function () {
      return {
        page: this.pageNumber,
        page_size: this.pageLength,
        ordering: this.ordering,
        owner: 'self'
      };
    },
    // pageLoading is false before the initial load starts
    paginationLoading: (0, _object.computed)('pageLoading', 'initialLoadFinished', function () {
      return this.pageLoading || !this.initialLoadFinished;
    }),
    newPage() {
      return this._newPage.perform();
    },
    // XXX: This is just to prevent ever-present set-on-destroyed errors, not a proper refactor. Which won't
    // happen, because this whole mixin needs to be replaced with Pagination objects ASAP
    _newPage: (0, _emberConcurrency.task)(function* () {
      if (this.pageNumber < 1) {
        return;
      }
      let queryParams = this.constructQuery();
      if (!(0, _utils.isNone)(this.filterProperty) && !(0, _utils.isNone)(this.filterPropertyId)) {
        queryParams[this.filterProperty] = this.filterPropertyId;
      }
      this.setProperties({
        pageError: false,
        pageLoading: true,
        pageFound: false
      });
      const baseURL = (0, _emberInflector.pluralize)(this.modelToPaginate);
      function* updateCount() {
        const {
          objects_count
        } = yield this.ajax.request(`${baseURL}/count/`, {
          data: queryParams
        });
        this.set('pageCount', Math.ceil(objects_count / this.pageLength));
        this.set('objectsCount', objects_count);
        if (this.pageCount === 0) {
          this.setProperties({
            [this.contentProperty]: [],
            pageLoading: false,
            isFirstPage: true,
            isLastPage: true,
            pageNumber: 1,
            pageCount: 1,
            initialLoadFinished: true
          });
        } else {
          this.set('isFirstPage', this.pageNumber === 1);
          this.set('isLastPage', this.pageCount === this.pageNumber);
        }
      }
      function* loadPage() {
        let records;
        if (this.getEmberObjects) {
          records = yield (0, _store.query)(this.store, this.modelToPaginate, queryParams);
        } else {
          records = yield this.ajax.request(`${baseURL}/`, {
            data: queryParams
          });
        }
        this.setProperties({
          [this.contentProperty]: records,
          pageError: false,
          pageLoading: false,
          pageFound: true,
          previousPageNumber: this.pageNumber,
          initialLoadFinished: true
        });
      }
      try {
        yield* updateCount.call(this);
        if (this.pageCount >= this.pageNumber) {
          yield* loadPage.call(this);
        }
      } catch (error) {
        this.setProperties({
          pageError: true,
          pageLoading: false,
          pageFound: false,
          pageNumber: this.previousPageNumber,
          initialLoadFinished: true
        });
        (0, _notifications.reportError)(error);
      }
    }).restartable(),
    pageNumberObserver: (0, _object.observer)('pageNumber', function () {
      (0, _runloop.debounce)(this, this.newPage, 333);
    }),
    setPageNumber: function (newPageNumber) {
      function isValidNumber(input) {
        /*
        Unlike user input, numbers generated by the next and previous page
        actions are not strings, therefore the input is converterd to a string
        at comparison.
        */
        const n = Number(input);
        return !isNaN(n) && n > 0;
      }
      if (isValidNumber(newPageNumber)) {
        this.set('pageNumber', newPageNumber);
      } else {
        this.set('pageError', true);
        this.set('pageLoading', false);
        this.set('pageFound', false);
      }
    },
    actions: {
      nextPage: function () {
        if (this.pageLoading) {
          return;
        }
        let pageNumber = this.pageNumber;
        if (pageNumber < this.pageCount) {
          pageNumber++;
          this.setPageNumber(pageNumber);
        }
      },
      previousPage: function () {
        if (this.pageLoading) {
          return;
        }
        let pageNumber = this.pageNumber;
        if (pageNumber - 1 >= 1) {
          pageNumber--;
          this.setPageNumber(pageNumber);
        }
      },
      firstPage: function () {
        if (!this.pageLoading) {
          this.setPageNumber(1);
        }
      },
      lastPage: function () {
        if (!this.pageLoading) {
          this.set('pageNumber', this.pageCount);
        }
      },
      iFeelLucky: function () {
        // this.set('pageNumber', Math.floor((Math.random() * this.get('pageCount')) + 1));
        this.notifyPropertyChange('pageNumber');
      },
      updateOrdering(value) {
        this.set('ordering', value);
        this.set('pageNumber', 1);
        this.notifyPropertyChange('pageNumber');
      }
    }
  });
});