define("plutof/templates/components/experiment/sequencing/material-bulk-update", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZjT7jlof",
    "block": "[[[6,[39,0],null,[[\"update\"],[[30,1]]],[[\"default\"],[[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"sequencing-result.primer\"]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@model\",\"@value\"],[\"dna-lab/primer\",[30,2,[\"primer\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"sequencing-result.comments\"]]]],[1,\"\\n\\n        \"],[1,[28,[35,3],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,2,[\"comments\"]],\"form-control\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n    \"],[1,[30,3]],[1,\"\\n\"]],[2,3]]]]]],[\"@update\",\"values\",\"updateButton\"],false,[\"tables/bulk-fields-update\",\"plutof-labelc\",\"auto-complete/bound-model\",\"input\"]]",
    "moduleName": "plutof/templates/components/experiment/sequencing/material-bulk-update.hbs",
    "isStrictMode": false
  });
});