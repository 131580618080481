define("plutof/components/person/person-view-statistics", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "plutof/utils/search/modules"], function (_exports, _component, _object, _computed, _utils, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PersonViewStatisticsComponent = (_dec = (0, _computed.alias)('statisticsOptions.actions.statistics.fields'), _dec2 = (0, _object.computed)('statistics'), _class = class PersonViewStatisticsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "statistics", null);
      _initializerDefineProperty(this, "options", _descriptor, this);
    }
    get personStatistics() {
      const stats = this.get('statistics.results');
      const options = this.options;
      if ((0, _utils.isNone)(stats) || (0, _utils.isNone)(options)) {
        return [];
      }
      const contentData = Object.keys(stats).map(moduleKey => {
        if (!options[moduleKey] || !options[moduleKey].fields) {
          return;
        }
        const optionForKey = options[moduleKey]['fields'];
        const mainProps = optionForKey['owner']; // Main object properties.

        // XXX: Temporary, until MetadataAgent is removed
        if (!mainProps) {
          return;
        }
        const moduleName = optionForKey['owner']['model_label'];
        const module = {
          name: moduleName
        };
        module.data = Object.keys(stats[moduleKey]).filter(key => stats[moduleKey][key] !== 0).filter(key => optionForKey[key]).map(childrenKey => {
          const sModule = _modules.default.find(m => m.id === mainProps.model_name || m.id === mainProps.app_name);
          const sArg = optionForKey[childrenKey]['search_filter'];
          return _object.default.create({
            count: stats[moduleKey][childrenKey],
            label: optionForKey[childrenKey]['label'],
            isOwner: childrenKey === 'owner',
            searchExists: (0, _utils.isPresent)(sModule) && (0, _utils.isPresent)(sArg),
            searchModule: sModule,
            searchArg: sArg
          });
        }).sortBy('count').reverse();
        return module;
      }).filter(m => m && !(0, _utils.isEmpty)(m.data));
      return this.createEmptyRows(contentData);
    }
    createEmptyRows(results) {
      const mostItems = Math.max(...results.mapBy('data.length'));
      results.forEach(module => {
        const itemsToAdd = mostItems - module.data.length;
        module.data.pushObjects(new Array(itemsToAdd));
      });
      return results;
    }
    sortRowsByCount() {
      let sortedData = this.tableRows.sortBy('count');
      if (this.countAsc) {
        sortedData.reverse();
      }
      this.toggleProperty('countAsc');
      this.set('tableRows', sortedData);
    }
    invokeSearch(data) {
      // @data can be null because of createEmptyRows
      if (!data || !data.get('searchExists')) {
        return;
      }
      const person = this.person;
      const value = data.get('isOwner') ? person.get('user.id') : person.get('id');
      const arg = data.get('searchArg');
      const module = data.get('searchModule.id');
      const url = `${window.location.origin}/search?module=${module}&${arg}=${value}`;
      window.open(url, '_blank');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "personStatistics", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "personStatistics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "invokeSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "invokeSearch"), _class.prototype), _class);
  var _default = _exports.default = PersonViewStatisticsComponent;
});