define("plutof/controllers/observation/view", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "plutof/controllers/observation/edit", "plutof/misc/abstract", "plutof/mixins/moderation"], function (_exports, _controller, _object, _computed, _utils, _emberData, _edit, _abstract, _moderation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ObservationViewController = (_dec = (0, _computed.alias)('model.observation.can_comment'), _dec2 = (0, _object.computed)('canDiscuss', 'model.observation.id'), _dec3 = (0, _object.computed)('model.observation.samplingevent.samplingarea.geom'), _class = class ObservationViewController extends _controller.default.extend(_moderation.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "comments", []);
      _defineProperty(this, "scrollOnCommentary", true);
      _defineProperty(this, "areaEventTranslations", (0, _edit.getAreaEventTranslations)());
      _initializerDefineProperty(this, "canDiscuss", _descriptor, this);
    }
    get moderationComments() {
      const observationID = this.get('model.observation.id');
      const promise = this.canDiscuss && !(0, _utils.isNone)(observationID) ? this.loadComments(observationID) : (0, _abstract.wrap_as_promise)([]);
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }
    get mapData() {
      const geom = this.get('model.observation.samplingevent.samplingarea.geom');
      return [{
        geom: geom
      }];
    }
    discuss() {
      document.getElementById('commentsPanel').scrollIntoView({
        behavior: 'smooth'
      });
    }
    sendAccept(observationID, message) {
      this.acceptPromise(observationID, message);
    }
    sendReject(observationID, reason) {
      this.rejectPromise(observationID, reason);
    }
    sendReply(observationID, message) {
      this.reply(observationID, message).then(msg => {
        this.moderationComments.pushObject(msg);
      });
    }
    cancelReject() {
      // Do nothing
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "canDiscuss", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "moderationComments", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "moderationComments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapData", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "mapData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discuss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discuss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendAccept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendAccept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendReject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendReject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendReply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendReply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelReject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelReject"), _class.prototype), _class);
  var _default = _exports.default = ObservationViewController;
});