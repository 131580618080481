define("plutof/helpers/route-action", ["exports", "@ember/array", "@ember/component/helper", "@ember/runloop", "@ember/service"], function (_exports, _array, _helper, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor; // XXX - This is pretty much a copy paste of "ember-route-action-helper" library with some minor tweaks.
  // Someone with brains should refactor or solve what it's trying to do in the app. For now copy-ing it here
  // removes build blockers for NodeJS upgrades
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function getCurrentInfos(router) {
    /* eslint-disable */
    let routerLib = router._routerMicrolib || router.router;
    /* eslint-enable */

    return {
      currentInfos: routerLib.currentRouteInfos || routerLib.currentHandlerInfos,
      mapBy: routerLib.currentRouteInfos && 'route' || 'handler'
    };
  }
  function getRoutes(router) {
    const {
      currentInfos,
      mapBy
    } = getCurrentInfos(router);
    return (0, _array.A)(currentInfos).mapBy(mapBy).reverse();
  }
  function getRouteWithAction(router, actionName) {
    let action;
    let handler = (0, _array.A)(getRoutes(router)).find(route => {
      let actions = route.actions || route._actions;
      action = actions[actionName];
      return typeof action === 'function';
    });
    return {
      action,
      handler
    };
  }
  let RouteAction = _exports.default = (_class = class RouteAction extends _helper.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    compute([actionName, ...params]) {
      let router = this.router._router;
      let routeAction = (...invocationArgs) => {
        let {
          action,
          handler
        } = getRouteWithAction(router, actionName);
        let args = params.concat(invocationArgs);
        return _runloop.run.join(handler, action, ...args);
      };
      return routeAction;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});