define("plutof/templates/livingspecimen/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0xC9BywR",
    "block": "[[[10,0],[14,0,\"route-marker-livingspecimen-add\"],[14,\"data-test\",\"route-livingspecimen.add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[28,[37,1],[\"livingspecimen\"],null],[28,[37,2],[\"LivingCultures.newLivingC\"],null],[30,0,[\"model\",\"strain\"]],[28,[37,3],[\"triggerReset\"],null],\"information/content/living-specimen/add\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@object_id\",\"@contentType\"],[[30,0,[\"object_id\"]],[30,0,[\"model\",\"queryContentType\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@model\",\"@hideSourceSelection\",\"@quickFormChoices\",\"@validationContext\",\"@showFormSelector\",\"@usingDefaultForm\",\"@allowAreaReset\",\"@preserveZoom\"],[[30,0,[\"model\",\"livingSpecimenModel\"]],[28,[37,6],[[30,0,[\"object_id\"]],[30,0,[\"template_id\"]]],null],[30,0,[\"plutofMainforms\"]],[30,0,[\"validationContext\"]],true,[30,0,[\"model\",\"usingDefaultForm\"]],true,[30,0,[\"preserveZoom\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@save\",\"@clone\",\"@cancel\",\"@ctrl\"],[[30,0,[\"save\"]],[30,0,[\"saveAndClone\"]],[28,[37,3],[\"goBack\"],null],[30,0]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"parent-object-path\",\"livingspecimen/edit\",\"or\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"save-panel\"]]",
    "moduleName": "plutof/templates/livingspecimen/add.hbs",
    "isStrictMode": false
  });
});