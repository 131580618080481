define("plutof/components/study/view/area-tree/node", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/object/computed", "ember-concurrency", "@ember-decorators/component", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _object, _computed, _emberConcurrency, _component2, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="area-tree__vertical-notch"></div>
  <div class="area-tree__horizontal-notch"></div>
  
  {{#if this.hasChildren}}
      <button
          {{on "click" this.toggleExpanded}}
          class="btn-reset area-tree__inner-node-marker {{if this.expanded 'area-tree__inner-node-marker--expanded'}}"
      >
          {{!--
          <div class="area-tree__inner-node-marker-horizontal-notch"></div>
  
          {{#unless this.expanded}}
              <div class="area-tree__inner-node-marker-vertical-notch"></div>
          {{/unless}}
          --}}
      </button>
  {{/if}}
  
  <div class="area-tree__node">
      <LinkTo @route="area.view" @model={{@area.id}}>
          <span class="area-tree__area-name {{if this.notInProject 'area-tree__area-name--not-in-project'}}">
              {{@area.representation}}
          </span>
      </LinkTo>
  
      {{#if this.notInProject}}
          ({{i18n-t "Projects.areaHierarchy.notInProject"}})
      {{/if}}
  </div>
  
  {{#if this.expanded}}
      <ol class="area-tree__children">
          {{#each this.loadChildren.last.value.objects as |child|}}
              <Study::View::AreaTree::Node
                  @area={{child}}
                  @project={{@project}} />
          {{/each}}
  
          <Study::View::AreaTree::Pagination @pagination={{this.loadChildren.last.value}} />
      </ol>
  {{/if}}
  
  */
  {
    "id": "TZImeyAw",
    "block": "[[[10,0],[14,0,\"area-tree__vertical-notch\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasChildren\"]],[[[1,\"    \"],[11,\"button\"],[16,0,[29,[\"btn-reset area-tree__inner-node-marker \",[52,[30,0,[\"expanded\"]],\"area-tree__inner-node-marker--expanded\"]]]],[4,[38,1],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"area-tree__node\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"area.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[29,[\"area-tree__area-name \",[52,[30,0,[\"notInProject\"]],\"area-tree__area-name--not-in-project\"]]]],[12],[1,\"\\n            \"],[1,[30,1,[\"representation\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"notInProject\"]],[[[1,\"        (\"],[1,[28,[35,3],[\"Projects.areaHierarchy.notInProject\"],null]],[1,\")\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"    \"],[10,\"ol\"],[14,0,\"area-tree__children\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"loadChildren\",\"last\",\"value\",\"objects\"]]],null]],null],null,[[[1,\"            \"],[8,[39,6],null,[[\"@area\",\"@project\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[39,7],null,[[\"@pagination\"],[[30,0,[\"loadChildren\",\"last\",\"value\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@area\",\"child\",\"@project\"],false,[\"if\",\"on\",\"link-to\",\"i18n-t\",\"each\",\"-track-array\",\"study/view/area-tree/node\",\"study/view/area-tree/pagination\"]]",
    "moduleName": "plutof/components/study/view/area-tree/node.hbs",
    "isStrictMode": false
  });
  let AreaTreeNode = (_dec = (0, _component2.tagName)('li'), _dec2 = (0, _component2.classNames)('area-tree__child'), _dec3 = (0, _computed.gt)('area.child_count', 0), _dec4 = (0, _object.computed)('area.study.id', 'project.id'), _dec(_class = _dec2(_class = (_class2 = class AreaTreeNode extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "expanded", false);
      _initializerDefineProperty(this, "hasChildren", _descriptor, this);
      _initializerDefineProperty(this, "loadChildren", _descriptor2, this);
    }
    get notInProject() {
      return this.area.get('study.id') !== this.project.id;
    }
    expand() {
      if (!this.pagination && this.loadChildren.isIdle) {
        this.loadChildren.perform();
      }
      this.set('expanded', true);
    }
    toggleExpanded() {
      if (this.expanded) {
        this.set('expanded', false);
      } else {
        this.expand();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasChildren", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "notInProject", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "notInProject"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "loadChildren", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        // Query doesn't filter by project, because we want all children on @project areas here
        const query = {
          parent_samplingarea: this.area.id
        };
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'sample/samplingarea', query, [_pagination.AccumulatingPaginationMixin]);
        return pagination;
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleExpanded", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleExpanded"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaTreeNode);
});