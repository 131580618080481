define("plutof/components/taxonomy/taxon/view/external-links-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header @title={{i18n-t "Taxonomy.externalLinksTitle"}} />
  
      <panel.content>
          <GenericViews::Record @record={{@taxon}} as |view|>
              <Layout::RegularGrid @columns={{4}}>
                  <Taxonomy::Taxon::View::ExternalLinksView
                      @view={{view}}
                      @taxon={{@taxon}} />
              </Layout::RegularGrid>
          </GenericViews::Record>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "aNP4S8vv",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[28,[37,1],[\"Taxonomy.externalLinksTitle\"],null]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@record\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],null,[[\"@view\",\"@taxon\"],[[30,3],[30,2]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@taxon\",\"view\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"taxonomy/taxon/view/external-links-view\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/view/external-links-panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});