define("plutof/templates/components/observation/taxon-sheet/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gMr+JdPb",
    "block": "[[[11,0],[16,0,[29,[\"\\n        taxon-sheet-item\\n        \",[52,[30,1],\"taxon-sheet-item--selected\"],\"\\n        taxon-sheet-item--category-\",[30,2,[\"category\"]],\"\\n    \"]]],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n\"],[1,\"    \"],[10,1],[14,0,\"taxon-sheet-item__scientific-name\"],[12],[1,[30,2,[\"taxon_name\"]]],[13],[41,[30,2,[\"commonName\"]],[[[1,\";\"]],[]],null],[1,\"\\n\\n\"],[41,[30,2,[\"commonName\"]],[[[1,\"        \"],[10,1],[14,0,\"taxon-sheet-item__common-name\"],[12],[1,\"\\n            \"],[1,[30,2,[\"commonName\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@selected\",\"@item\",\"@toggle\"],false,[\"if\",\"on\"]]",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/item.hbs",
    "isStrictMode": false
  });
});