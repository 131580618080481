define("plutof/templates/components/common/confirmation-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mwPyToDS",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Common::ConfirmationDialog\"]],[[\"@open\",\"@close\"],[[30,0,[\"confirmationDialog\",\"visible\"]],[30,0,[\"cancel\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[30,0,[\"confirmationDialog\",\"dialog\",\"title\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[1,[30,0,[\"confirmationDialog\",\"dialog\",\"content\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"confirmationDialog\",\"dialog\",\"options\"]]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"choose\"]],[30,1]],null]],null],[12],[1,\"\\n                \"],[1,[30,1,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[\"dialogOption\"],false,[\"ui/modal/dialog\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "plutof/templates/components/common/confirmation-dialog.hbs",
    "isStrictMode": false
  });
});