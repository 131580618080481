define("plutof/utils/annotation/elixir", ["exports", "@ember/utils", "rsvp", "plutof/misc/config", "plutof/utils/annotation/format", "plutof/utils/formatters", "plutof/utils/reflection", "plutof/utils/structures"], function (_exports, _utils, _rsvp, _config, _format, _formatters, _reflection, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ElixirExtractor = _exports.DEFAULT_CDCH_ASSERTION = _exports.CDCH_ASSERTION_LABELS = _exports.CDCH_ASSERTIONS = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const CDCH_ASSERTIONS = _exports.CDCH_ASSERTIONS = [{
    value: 'ECO:0000088',
    label: 'biological system reconstruction evidence'
  }, {
    value: 'ECO:0006055',
    label: 'high throughput evidence'
  }, {
    value: 'ECO:0007672 ',
    label: 'computational evidence'
  }, {
    value: 'ECO:0006151',
    label: 'documented statement evidence'
  }, {
    value: 'ECO:0000006',
    label: 'experimental evidence'
  }, {
    value: 'ECO:0000352',
    label: 'evidence used in manual assertion'
  }, {
    value: 'ECO:0000361',
    label: 'inferential evidence'
  }, {
    value: 'ECO:0000501',
    label: 'evidence used in automatic assertion'
  }, {
    value: 'ECO:0000212',
    label: 'combinatorial evidence'
  }, {
    value: 'ECO:0000041',
    label: 'similarity evidence'
  }];
  const CDCH_ASSERTION_LABELS = _exports.CDCH_ASSERTION_LABELS = function () {
    const labels = {};
    for (const assertion of CDCH_ASSERTIONS) {
      labels[assertion.value] = assertion.label;
    }
    return labels;
  }();
  const DEFAULT_CDCH_ASSERTION = _exports.DEFAULT_CDCH_ASSERTION = CDCH_ASSERTIONS[5].value;
  class ElixirExtractor {
    constructor(store, changes, {
      previousCurations = []
    } = {}) {
      _defineProperty(this, "_curations", []);
      _defineProperty(this, "_errors", []);
      _defineProperty(this, "_measurementsPromise", null);
      this._changes = changes;
      this._previousCurations = new Map(previousCurations.map(c => [c.field, c]));
      this._store = store;
      this._ajax = (0, _reflection.getService)(store, 'ajax');
      this._i18n = (0, _reflection.getService)(store, 'i18n');
    }
    add(field, value, {
      comment,
      assertion,
      assertionSource
    } = {}) {
      if (!comment) {
        comment = (this._previousCurations.get(field) || {}).comment || '';
      }
      if (!assertion) {
        assertion = (this._previousCurations.get(field) || {}).assertion || DEFAULT_CDCH_ASSERTION;
      }
      if (!assertionSource) {
        assertionSource = (this._previousCurations.get(field) || {}).assertionSource || '';
      }
      this._curations.push({
        field,
        value,
        comment,
        assertion,
        assertionSource
      });
    }
    addError(msg) {
      this._errors.push(msg);
    }
    async addIdentifications(identificationData) {
      const identificationChanges = this._changes.filter(change => change.model === 'determination/determination');
      const determinerChanges = this._changes.filter(change => change.model === 'determination/determiner');
      if (identificationChanges.length === 0 && determinerChanges.length === 0) {
        return;
      }
      if (identificationChanges.some(change => change.operation === _format.Operation.Remove)) {
        this.addError('Removing identifications is not supported in ENA annotations');
        return;
      }
      if (identificationChanges.some(change => change.operation === _format.Operation.Add)) {
        // An identification was added, just send its fields to Elixir
        const currentIdentificationEntry = identificationData.entries[0];
        const taxon = await currentIdentificationEntry.determination.taxon_node;
        const enaTaxon = await this._resolveENATaxon(taxon.id);
        if (!enaTaxon) {
          return;
        }
        this.add('organism', enaTaxon.name);
        this.add('db_xref', `taxon:${enaTaxon.id}`);
        const typification = await currentIdentificationEntry.determination.typification;
        this.add('type_material', typification ? `${typification.name} of ${enaTaxon.name}` : '');
        const determiners = await _rsvp.default.Promise.all(currentIdentificationEntry.determiners.map(det => det.person));
        this.add('identified_by', determiners.map(person => person.representation).join(', '));
      } else {
        // Existing identification was changed, send only actual changes.
        // This branch is much more complex because of what conceptually amounts to diffing
        // NB: Only *current* identification changes are used
        const currentIdentificationEntry = identificationData.entries[0];
        const identification = currentIdentificationEntry.determination;
        const change = identificationChanges.find(change => change.operation === _format.Operation.Modify && change.id === identification.id);
        if (change) {
          const changedFields = new Set(Object.keys(change.fields));
          if (changedFields.has('taxon_node')) {
            const enaTaxon = await this._resolveENATaxon(change.fields.taxon_node);
            if (!enaTaxon) {
              return;
            }
            this.add('organism', enaTaxon.name);
            this.add('db_xref', `taxon:${enaTaxon.id}`);
          }
          if (changedFields.has('typification')) {
            const taxon = await identification.taxon_node;
            const typification = await identification.typification;
            if (typification) {
              this.add('type_material', `${typification.name} of ${taxon.taxon_name}`);
            } else {
              this.add('type_material', '');
            }
          }
        }
        if (determinerChanges.length > 0) {
          // The question "have determiners changed" is not as easy as seems
          let determinersChanged = false;
          const determinersAdded = determinerChanges.some(change => change.operation === _format.Operation.Add && change.fields.determination === identification.id);
          if (determinersAdded) {
            // If a determiner is added to this identification, no need to dig deeper
            determinersChanged = true;
          } else if (determinerChanges.some(change => change.operation === _format.Operation.Remove)) {
            // Nothing added, but something was removed *somewhere*, need to check whether is was
            // removed for *this* identification.
            // Because removes don't carry field content, the only way to check is to compare the counts
            const dbCount = (await this._ajax.request(`/determination/determiners/count/?determination=${identification.id}`)).objects_count;
            determinersChanged = dbCount !== currentIdentificationEntry.determiners.length;
          }
          if (determinersChanged) {
            const determiners = await _rsvp.default.Promise.all(currentIdentificationEntry.determiners.map(det => det.person));
            this.add('identified_by', determiners.map(person => person.representation).join(', '));
          }
        }
      }
    }
    async _resolveENATaxon(taxonID) {
      try {
        return await this._ajax.request(`/annotation/elixir/resolve-taxon/${taxonID}`);
      } catch (err) {
        this.addError(this._i18n.translate('annotation.elixir.errors.taxonResolutionFailed'));
        return null;
      }
    }
    async addSample(event) {
      const eventChange = this._changes.find(change => (change.operation === _format.Operation.Modify || change.operation === _format.Operation.Add) && change.model === 'sample/samplingevent' && change.id === event.id);
      if (eventChange) {
        const changedFields = new Set(Object.keys(eventChange.fields));
        if (changedFields.has('timespan_begin') || changedFields.has('timespan_end')) {
          const timespan = [event.timespan_begin, event.timespan_end].compact().map(date => {
            const format = (0, _formatters.getAmbiguousDateFormat)(date);
            return (0, _formatters.parseAmbiguousDate)(date).format(format);
          }).join('/');
          this.add('collection_date', timespan);
        }
        if (changedFields.has('gathering_agents')) {
          const collectors = event.gathering_agents.map(agent => agent.name).join(', ');
          this.add('collected_by', collectors);
        }
      }
      const area = await event.samplingarea;
      const areaChange = this._changes.find(change => (change.operation === _format.Operation.Modify || change.operation === _format.Operation.Add) && change.model === 'sample/samplingarea' && change.id === area.id);
      if (areaChange) {
        const changedFields = new Set(Object.keys(areaChange.fields));
        if (changedFields.has('latitude') || changedFields.has('longitude')) {
          let latlon = '';
          if (!(0, _utils.isEmpty)(area.latitude) && !(0, _utils.isEmpty)(area.longitude)) {
            let lat = area.latitude;
            let latDirection = 'N';
            let lon = area.longitude;
            let lonDirection = 'E';
            if (lat < 0) {
              lat = -lat;
              latDirection = 'S';
            }
            if (lon < 0) {
              lon = -lon;
              lonDirection = 'W';
            }
            latlon = `${lat} ${latDirection} ${lon} ${lonDirection}`;
          }
          this.add('lat_lon', latlon);
        }
        const LOCATION_FIELDS = ['state', 'district', 'parish', 'commune', 'locality_text'];
        if (changedFields.has('country') || LOCATION_FIELDS.some(field => changedFields.has(field))) {
          let location = (await area.country).name;
          const preciseLocationFields = LOCATION_FIELDS.map(field => area[field]).filter(part => part && part.trim());
          if (preciseLocationFields.length > 0) {
            location = `${location};${preciseLocationFields.join(', ')}`;
          }
          this.add('country', location);
        }
      }

      // Area measurements
      const altitudeMeasurements = await _rsvp.default.Promise.all(_config.default.Clipboard.ALTITUDE_MEASUREMENTS.map(traitID => this.getMeasurementChange('sample/samplingarea', area.id, traitID)));
      const altitudeChange = altitudeMeasurements.find(change => change.changed);
      if (altitudeChange) {
        this.add('altitude', altitudeChange.value ? `${altitudeChange.value} m` : '');
      }
    }
    async _extractMeasurementChanges() {
      const values = new Map();
      const measurementChanges = this._changes.filter(change => change.model === 'measurement/objectmeasurement');
      for (const change of measurementChanges) {
        switch (change.operation) {
          case _format.Operation.Add:
            {
              const ctype = await this._store.findRecord('contenttype', change.fields.content_type).full_name;
              (0, _structures.mapSet)(values, [ctype, change.fields.object_id, change.measurement], change.fields.value);
              break;
            }
          case _format.Operation.Modify:
            {
              if (change.fields.hasOwnProperty('value')) {
                const om = this._ajax.request(`/measurements/objectmeasurements/${change.id}`);
                const ctype = await this._store.findRecord('contenttype', om.content_type).full_name;
                (0, _structures.mapSet)(values, [ctype, om.object_id, om.measurement_id], change.fields.value);
              }
              break;
            }
          case _format.Operation.Remove:
            {
              const om = this._ajax.request(`/measurements/objectmeasurements/${change.id}`);
              const ctype = await this._store.findRecord('contenttype', om.content_type).full_name;
              (0, _structures.mapSet)(values, [ctype, om.object_id, om.measurement_id], '');
              break;
            }
          default:
            throw new Error(`Uknown operation: ${change.operation}`);
        }
      }
      return values;
    }
    async getMeasurementChange(ctype, id, traitID) {
      if (!this._measurementsPromise) {
        this._measurementsPromise = await this._extractMeasurementChanges();
      }
      const measurements = await this._measurementsPromise;
      const value = (0, _structures.mapGetWithPresense)(measurements, [ctype, id, traitID]);
      return {
        changed: value.present,
        value: value.value
      };
    }
    finish() {
      return {
        changes: this._changes,
        curations: this._curations,
        errors: this._errors
      };
    }
  }
  _exports.ElixirExtractor = ElixirExtractor;
});