define("plutof/templates/components/block-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dp2Zc6X0",
    "block": "[[[10,\"label\"],[15,0,[29,[[36,0]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\\n    \"],[1,[33,2,[\"labelText\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"    \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[[33,2,[\"tooltipText\"]]],null]],[1,\"\\n\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,2,[\"infoLinks\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,8],null,[[\"infoLink\"],[[30,1,[\"url\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"info\",\"&default\"],false,[\"labelClasses\",\"yield\",\"data\",\"if\",\"enableTooltip\",\"break-line\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/templates/components/block-label.hbs",
    "isStrictMode": false
  });
});