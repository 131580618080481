define("plutof/components/area/geometry/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember/service", "plutof/components/area/geometry/form"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils, _service, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.open}}
      <Ui::Modal::Dialog
          @open={{this.open}}
          @close={{this.cancel}}
          @smallerBodyFont={{true}}
      >
          <:header>
              {{i18n-t "Map.import.geometry"}}
          </:header>
  
          <:body>
              <Area::Geometry::Form
                  @area={{@area}}
                  @geometryChanged={{this.geometryChanged}}
                  @validityChanged={{this.validityChanged}} />
          </:body>
  
          <:footer>
              <PromiseButton
                  @clicked={{this.saveGeometry}}
                  @restIcon={{icon "ok"}}
                  @disabled={{not this.isValid}}
                  class="btn btn-default"
              >
                  {{i18n-t "General.ok"}}
              </PromiseButton>
  
              <PromiseButton
                  @clicked={{this.downloadGeometry}}
                  @restIcon={{icon "download"}}
                  @disabled={{not this.isValid}}
                  class="btn btn-default"
              >
                  {{i18n-t "General.Download"}}
              </PromiseButton>
  
              <Common::CancelButton @cancel={{this.cancel}} />
          </:footer>
      </Ui::Modal::Dialog>
  {{/if}}
  
  */
  {
    "id": "PUL9u3nv",
    "block": "[[[41,[30,0,[\"open\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@open\",\"@close\",\"@smallerBodyFont\"],[[30,0,[\"open\"]],[30,0,[\"cancel\"]],true]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"Map.import.geometry\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,3],null,[[\"@area\",\"@geometryChanged\",\"@validityChanged\"],[[30,1],[30,0,[\"geometryChanged\"]],[30,0,[\"validityChanged\"]]]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,4],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,0,[\"saveGeometry\"]],[28,[37,5],[\"ok\"],null],[28,[37,6],[[30,0,[\"isValid\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.ok\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,4],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,0,[\"downloadGeometry\"]],[28,[37,5],[\"download\"],null],[28,[37,6],[[30,0,[\"isValid\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.Download\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,7],null,[[\"@cancel\"],[[30,0,[\"cancel\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@area\"],false,[\"if\",\"ui/modal/dialog\",\"i18n-t\",\"area/geometry/form\",\"promise-button\",\"icon\",\"not\",\"common/cancel-button\"]]",
    "moduleName": "plutof/components/area/geometry/edit.hbs",
    "isStrictMode": false
  });
  let AreaGeometryEdit = (_dec = (0, _object.computed)('args.dialogOpen'), _class = class AreaGeometryEdit extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "geometry", _descriptor2, this);
      _initializerDefineProperty(this, "baseGeometry", _descriptor3, this);
      _initializerDefineProperty(this, "format", _descriptor4, this);
      _initializerDefineProperty(this, "isValid", _descriptor5, this);
    }
    get open() {
      return this.args.dialogOpen;
    }
    set open(value) {
      return value;
    }
    geometryChanged(baseGeometry, geometry, format) {
      this.baseGeometry = baseGeometry;
      this.geometry = geometry;
      this.format = format;
    }
    validityChanged(validity) {
      this.isValid = validity;
    }
    async saveGeometry() {
      await this.setAreaGeometry(this.args.area, this.baseGeometry);
      this.cancel();
    }
    async setAreaGeometry(area, geometry) {
      if (!(0, _utils.isNone)(this.args.geometryAdded)) {
        await this.args.geometryAdded(geometry);
      }
    }
    cancel() {
      this.args.closeDialog();
    }
    downloadGeometry() {
      (0, _form.downloadGeometry)(this.geometry, this.args.area.name, this.format);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "baseGeometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "format", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "geometryChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "geometryChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validityChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validityChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAreaGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setAreaGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadGeometry"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaGeometryEdit);
});