define("plutof/components/search/filter-panels/common-properties/checkboxes", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Search::FilterInput @filter={{@filterInputs.sub_studies}} />
  <Search::FilterInput @filter={{@filterInputs.is_verified}} />
  <Search::FilterInput @filter={{@filterInputs.is_public}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_sound_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_video_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_images}} />
  <Search::FilterInput @filter={{@filterInputs.has_3d_model}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_externallinks}} />
  <Search::FilterInput @filter={{@filterInputs.has_sequences}} />
  <Search::FilterInput @filter={{@filterInputs.has_dna}} />
  <Search::FilterInput @filter={{@filterInputs.has_cultures}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_accepted_annotations}} />
  
  */
  {
    "id": "ncTm77cB",
    "block": "[[[8,[39,0],null,[[\"@filter\"],[[30,1,[\"sub_studies\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"is_verified\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"is_public\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_files\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_sound_files\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_video_files\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_images\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_3d_model\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_externallinks\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_sequences\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_dna\"]]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_cultures\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@filter\"],[[30,1,[\"has_accepted_annotations\"]]]],null],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"search/filter-input\"]]",
    "moduleName": "plutof/components/search/filter-panels/common-properties/checkboxes.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});