define("plutof/components/measurements/form-selector", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "ember-data", "@ember-decorators/component", "ember-concurrency", "ember-cp-validations", "plutof/components/forms/utils", "plutof/mixins/component-validations"], function (_exports, _component, _object, _computed, _service, _utils, _emberData, _component2, _emberConcurrency, _emberCpValidations, _utils2, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'valid': (0, _emberCpValidations.validator)('is-truthy')
  });
  let FormSelector = (_dec = (0, _component2.classNames)('form-selector'), _dec2 = (0, _object.computed)('localSelection', 'whitelisted'), _dec3 = (0, _computed.empty)('mainform.id'), _dec4 = (0, _object.computed)('notSelected', 'invalidForm', 'invalidProject'), _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec6 = (0, _object.computed)('mainform.name', 'mainform.description'), _dec7 = (0, _object.computed)('mainform'), _dec8 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec(_class = (_class2 = class FormSelector extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "localSelection", 'manual');
      _defineProperty(this, "whitelisted", false);
      _defineProperty(this, "whitelist", []);
      _defineProperty(this, "invalidForm", false);
      _defineProperty(this, "invalidProject", false);
      _initializerDefineProperty(this, "notSelected", _descriptor2, this);
      _initializerDefineProperty(this, "setupInitialSelection", _descriptor3, this);
      _initializerDefineProperty(this, "resolveQuickChoice", _descriptor4, this);
    }
    get manualSelection() {
      return !this.whitelisted && this.localSelection === 'manual';
    }
    get valid() {
      return !this.notSelected && !this.invalidForm && !this.invalidProject;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.setupInitialSelection.perform();
    }
    get haveDistinctDescription() {
      const description = this.get('mainform.description');
      return !(0, _utils.isEmpty)(description) && this.get('mainform.name') !== description;
    }
    get moderators() {
      if (!this.mainform) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _utils2.getModerators)(this.ajax, this.store, this.mainform)
      });
    }
    selectQuickChoice(choice) {
      this.resolveQuickChoice.perform(choice);
    }
    selectProjectForm(form) {
      this.resolveQuickChoice.cancelAll();
      this.update(form);
    }
    showManualSelector() {
      this.resolveQuickChoice.cancelAll();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "manualSelection", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "manualSelection"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "notSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "valid", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "valid"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "setupInitialSelection", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const mainform = yield this.mainform;
        // Select button immediately for faster feedback. Correct in most cases. Being sure
        // requires waiting for whitelist to resolve
        this.set('localSelection', mainform ? mainform.id : 'manual');
        const project = yield this.project;
        let whitelist = [];
        if (project) {
          whitelist = (yield project.mainforms).toArray();
        }
        this.set('whitelisted', whitelist.length > 0);
        if (this.whitelisted) {
          // With whitelist present, form cannot be selected manually and any out-of-whitelist
          // form is shown as a validation failure
          const invalid = mainform && !whitelist.some(form => form.id === mainform.id);
          this.set('invalidForm', invalid);
          const usableWhitelistForms = whitelist.filter(form => form.type === this.type);
          this.set('whitelist', usableWhitelistForms);
          this.set('invalidProject', usableWhitelistForms.length === 0);
          if (this.invalidProject && mainform) {
            this.update(null);
          } else if (!mainform && usableWhitelistForms.length === 1) {
            // Autoselect a singular whitelist mainform. If user has already selecting something, we keep
            // it (showing an error, of course)
            this.update(usableWhitelistForms[0]);
          }
        } else {
          // Without whitelist, quickChoices are used and manual selection is available. Any form
          // passes validation
          if (mainform && this.quickChoices && !this.quickChoices.some(form => form.id === mainform.id)) {
            this.set('localSelection', 'manual');
          }
          this.setProperties({
            invalidForm: false,
            invalidProject: false,
            whitelist: []
          });
        }
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "haveDistinctDescription", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "haveDistinctDescription"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "moderators", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "moderators"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "resolveQuickChoice", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (choice) {
        const form = yield this.store.findRecord('measurement/mainform', choice.id);
        this.update(form);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "selectQuickChoice", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectQuickChoice"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectProjectForm", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectProjectForm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showManualSelector", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "showManualSelector"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = FormSelector;
});