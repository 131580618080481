define("plutof/components/specimen/exsiccata", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.exsiccata"}}
      @collapse={{and (not touched) (not @specimen.exsiccata.id)}}
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc "exsiccata.title"}}
  
              {{#resolve-promise @specimen.exsiccata as |value|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/specimen/exsiccata"
                      @value={{value}}
                      @update={{this.update}} />
              {{/resolve-promise}}
          </div>
  
          <div>
              {{plutof-labelc "specimen.exsiccata_no"}}
  
              <Input @type="text" @value={{@specimen.exsiccata_no}} class="form-control" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "CZmkq1B5",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"General.exsiccata\"],null],[28,[37,2],[[28,[37,3],[[33,4]],null],[28,[37,3],[[30,1,[\"exsiccata\",\"id\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,6],[\"exsiccata.title\"],null]],[1,\"\\n\\n\"],[6,[39,7],[[30,1,[\"exsiccata\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,8],null,[[\"@model\",\"@value\",\"@update\"],[\"taxonoccurrence/specimen/exsiccata\",[30,2],[30,0,[\"update\"]]]],null],[1,\"\\n\"]],[2]]]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,6],[\"specimen.exsiccata_no\"],null]],[1,\"\\n\\n            \"],[8,[39,9],[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"exsiccata_no\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@specimen\",\"value\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"and\",\"not\",\"touched\",\"layout/regular-grid\",\"plutof-labelc\",\"resolve-promise\",\"auto-complete/model\",\"input\"]]",
    "moduleName": "plutof/components/specimen/exsiccata.hbs",
    "isStrictMode": false
  });
  let SpecimenExsiccata = _exports.default = (_class = class SpecimenExsiccata extends _component2.default {
    constructor(...args) {
      super(...args);
      // Without all this, clearing AC collapses the panel
      _initializerDefineProperty(this, "touched", _descriptor, this);
    }
    update(exsiccata) {
      this.touched = true;
      this.args.specimen.set('exsiccata', exsiccata);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "touched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenExsiccata);
});