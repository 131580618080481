define("plutof/templates/components/search/repository-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VSF34YOa",
    "block": "[[[8,[39,0],[[24,0,\"filter-input\"]],[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,1],null,[[\"@value\",\"@update\"],[[30,0,[\"collection\"]],[30,0,[\"updateCollection\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,[30,2,[\"config\",\"label\"]]],[13],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@model\",\"@update\",\"@filters\",\"@disabled\"],[\"agent/repository\",[30,3],[30,0,[\"subrepositoryAutocompleteFilters\"]],[30,0,[\"noRepository\"]]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@repositoryFilter\",\"@subrepositoryFilter\",\"add\"],false,[\"layout/regular-grid\",\"collection/auto-complete\",\"auto-complete/bind-multiple\",\"auto-complete/model\"]]",
    "moduleName": "plutof/templates/components/search/repository-input.hbs",
    "isStrictMode": false
  });
});