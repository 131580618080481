define("plutof/adapters/public/file", ["exports", "@ember/object", "@ember-data/adapter/json-api", "plutof/config/environment"], function (_exports, _object, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _jsonApi.default.extend({
    host: (0, _object.computed)(function () {
      return _environment.default.API_HOST;
    }),
    namespace: (0, _object.computed)(function () {
      return _environment.default.API_NAMESPACE;
    })
  });
});