define("plutof/components/annotation/annotation-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/models/annotation/annotation"], function (_exports, _component, _templateFactory, _component2, _object, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Annotation::-Link
      @annotation={{@annotation}}
      @highlight={{this.highlight}}
  >
      {{yield}}
  </Annotation::-Link>
  
  */
  {
    "id": "jEeEKONn",
    "block": "[[[8,[39,0],null,[[\"@annotation\",\"@highlight\"],[[30,1],[30,0,[\"highlight\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@annotation\",\"&default\"],false,[\"annotation/-link\",\"yield\"]]",
    "moduleName": "plutof/components/annotation/annotation-link.hbs",
    "isStrictMode": false
  });
  let AnnotationLink = (_dec = (0, _object.computed)('args.annotation.status', 'args.accessRights.canModify'), _class = class AnnotationLink extends _component2.default {
    get highlight() {
      return this.args.accessRights.canModify && this.args.annotation.status === _annotation.AnnotationStatus.BeingReviewed;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "highlight", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "highlight"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationLink);
});