define("plutof/components/taxonomy/edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Taxonomy.attributes"}}
      data-test="Taxonomy::TaxonNode::GeneralData"
  >
      <Taxonomy::TaxonNode
          @taxon={{@taxon}}
          @disableRankField={{@disableRankField}}
          @validationContext={{@validationContext.taxon}} />
  </PlutofPanel::Simple>
  
  <Taxonomy::CommonNames::Edit
      @commonNames={{@commonNames}}
      @add={{@addVernacularName}}
      @remove={{@removeVernacularName}} />
  
  <LinkedItems::Edit
      @data={{@linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  */
  {
    "id": "n8xtjUsI",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Taxonomy::TaxonNode::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"Taxonomy.attributes\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@taxon\",\"@disableRankField\",\"@validationContext\"],[[30,1],[30,2],[30,3,[\"taxon\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@commonNames\",\"@add\",\"@remove\"],[[30,4],[30,5],[30,6]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@data\",\"@validationContext\"],[[30,7],[30,3,[\"linkedItems\"]]]],null],[1,\"\\n\"]],[\"@taxon\",\"@disableRankField\",\"@validationContext\",\"@commonNames\",\"@addVernacularName\",\"@removeVernacularName\",\"@linkedItems\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"taxonomy/taxon-node\",\"taxonomy/common-names/edit\",\"linked-items/edit\"]]",
    "moduleName": "plutof/components/taxonomy/edit.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});