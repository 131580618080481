define("plutof/templates/components/experiment/common/labware-tube", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NkXQMGIT",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,3]],[16,0,[29,[\"labware-tube-container \",[52,[30,4],\"labware-tube-container--selected\"]]]],[4,[38,2],[[30,0],[30,0,[\"selectMaterial\"]],[30,5]],null],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"labware-tube \",[52,[30,4],\"labware-tube--selected\"]]]],[12],[1,\"\\n            \"],[10,0],[14,0,\"labware-tube__label\"],[12],[1,\"\\n                \"],[1,[30,0,[\"tubeLabel\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[15,1,[29,[[30,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n\"],[41,[30,2],[[[41,[28,[37,3],[[30,6],\"dna\"],null],[[[1,\"                    \"],[8,[39,4],null,[[\"@material\",\"@source\"],[[30,5],[30,0,[\"sourceRecord\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,6],\"pcr\"],null],[[[1,\"                    \"],[8,[39,5],null,[[\"@material\",\"@source\"],[[30,5],[30,0,[\"sourceRecord\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,6],\"sequencing\"],null],[[[1,\"                    \"],[8,[39,6],null,[[\"@material\",\"@source\"],[[30,5],[30,0,[\"sourceRecord\"]]]],null],[1,\"\\n                \"]],[]],null]],[]]]],[]]]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"]],[\"t\",\"shown\",\"@disable\",\"@selected\",\"@material\",\"@materialType\"],false,[\"tooltip-wrapper\",\"if\",\"action\",\"is-equal\",\"experiment/dna-extraction/view/tube-summary\",\"experiment/pcr/view/tube-summary\",\"experiment/sequencing/view/tube-summary\"]]",
    "moduleName": "plutof/templates/components/experiment/common/labware-tube.hbs",
    "isStrictMode": false
  });
});