define("plutof/components/ui/modal/dialog", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Modal::Frame
      @open={{@open}}
      @close={{@close}}
  >
      <div
          class="
              modal-dialog
              {{if @wide 'modal-dialog--wide'}}
              {{if @fitContent 'modal-dialog--fit-content'}}
          "
          ...attributes
      >
          {{#if (has-block "header")}}
              <header class="modal-dialog__header">
                  <div class="modal-dialog__title">
                      {{yield to="header"}}
                  </div>
  
                  {{#if (has-block "header-buttons")}}
                      {{yield to="header-buttons"}}
                  {{else}}
                      {{#if @close}}
                          <button
                              {{on "click" @close}}
                              class="modal-dialog__close-button"
                          >
                              <span class={{icon "close"}}></span>
                          </button>
                      {{/if}}
                  {{/if}}
              </header>
          {{/if}}
  
          <div class="modal-dialog__body {{if @smallerBodyFont 'modal-dialog__body--smaller-font'}}">
              {{yield to="body"}}
          </div>
  
          {{#if (has-block "footer")}}
              <footer class="modal-dialog__footer">
                  {{yield to="footer"}}
              </footer>
          {{/if}}
      </div>
  </Ui::Modal::Frame>
  
  */
  {
    "id": "GIMr6KJI",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[11,0],[16,0,[29,[\"\\n            modal-dialog\\n            \",[52,[30,3],\"modal-dialog--wide\"],\"\\n            \",[52,[30,4],\"modal-dialog--fit-content\"],\"\\n        \"]]],[17,5],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"            \"],[10,\"header\"],[14,0,\"modal-dialog__header\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"modal-dialog__title\"],[12],[1,\"\\n                    \"],[18,7,null],[1,\"\\n                \"],[13],[1,\"\\n\\n\"],[41,[48,[30,8]],[[[1,\"                    \"],[18,8,null],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"                        \"],[11,\"button\"],[24,0,\"modal-dialog__close-button\"],[4,[38,4],[\"click\",[30,2]],null],[12],[1,\"\\n                            \"],[10,1],[15,0,[28,[37,5],[\"close\"],null]],[12],[13],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,0],[15,0,[29,[\"modal-dialog__body \",[52,[30,6],\"modal-dialog__body--smaller-font\"]]]],[12],[1,\"\\n            \"],[18,9,null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[48,[30,10]],[[[1,\"            \"],[10,\"footer\"],[14,0,\"modal-dialog__footer\"],[12],[1,\"\\n                \"],[18,10,null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@open\",\"@close\",\"@wide\",\"@fitContent\",\"&attrs\",\"@smallerBodyFont\",\"&header\",\"&header-buttons\",\"&body\",\"&footer\"],false,[\"ui/modal/frame\",\"if\",\"has-block\",\"yield\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/ui/modal/dialog.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});