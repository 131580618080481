define("plutof/components/unite/determinations/detail-field", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/utils", "plutof/utils/validations", "plutof/utils/formatters"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _computed, _utils, _validations, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{unless this.isValid "has-error"}}>
      {{common/local-label @field.label}}
  
      <OneWayInput
          @type="text"
          @value={{get @details @field.field}}
          @update={{this.update}}
          @class="form-control" />
  </div>
  
  */
  {
    "id": "ku/tL6Fz",
    "block": "[[[10,0],[15,0,[52,[51,[30,0,[\"isValid\"]]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"label\"]]],null]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@type\",\"@value\",\"@update\",\"@class\"],[\"text\",[28,[37,3],[[30,2],[30,1,[\"field\"]]],null],[30,0,[\"update\"]],\"form-control\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@field\",\"@details\"],false,[\"unless\",\"common/local-label\",\"one-way-input\",\"get\"]]",
    "moduleName": "plutof/components/unite/determinations/detail-field.hbs",
    "isStrictMode": false
  });
  // XXX: Only numbers for now
  let UniteIdentificationDetailField = (_dec = (0, _computed.reads)('valid'), _class = class UniteIdentificationDetailField extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "valid", _descriptor, this);
      _initializerDefineProperty(this, "isValid", _descriptor2, this);
    }
    update(value) {
      (0, _object.set)(this.args.details, this.args.field.field, value);
      this.valid = (0, _utils.isEmpty)(value) || _formatters.DECIMAL_REGEX.test(value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "valid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UniteIdentificationDetailField);
});