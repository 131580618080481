define("plutof/controllers/photobank/edit", ["exports", "@ember/controller", "@ember/object", "rsvp", "plutof/misc/content_types", "plutof/mixins/edit-controller", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/promises"], function (_exports, _controller, _object, _rsvp, _content_types, _editController, _access, _i18n, _notifications, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PhotobankEditController = (_class = class PhotobankEditController extends _controller.default.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "recordsToUnload", [{
        record: 'object'
      }, {
        record: 'preparationsData',
        subRecords: ['preparations'],
        unloadSelf: false
      }]);
      _defineProperty(this, "showAreaEditButton", true);
    }
    init() {
      super.init(...arguments);
      const i18n = (0, _i18n.getI18n)();

      // TODO: Views should allow to override their strings partially
      this.set('areaEventViewStrings', {
        SamplingEvent: {
          gatheringAgents: 'Photobank.gatheringAgents'
        },
        General: {
          samplingAreaEvent: 'Photobank.areaEventTitle'
        }
      });
      this.set('observationTableStrings', {
        panelTitle: i18n.t('Observations.title'),
        individual_count: i18n.t('Observations.placeholders.observationCount'),
        formType: i18n.t('General.formType')
      });
    }
    async saveObject() {
      const {
        store,
        objectsToDelete
      } = this.getProperties(['store', 'objectsToDelete']);
      const {
        object,
        eventFormData,
        additionalIdentifiers,
        preparationsData,
        itemData,
        classificationItems,
        agentItems
      } = this.model;
      const accessRights = await (0, _access.createDerivedRecordRights)(this.store, object, {
        collection: await object.deposited_in
      });
      await eventFormData.save();
      eventFormData.apply(object);
      await object.save();
      await _rsvp.default.all(objectsToDelete.invoke('destroyRecord'));
      this.set('objectsToDelete', []);
      const saveAgentsItems = (0, _content_types.get_ctype_by_name)(store, 'photobank/collectionobject').then(ctype => {
        agentItems.filterBy('isNew').forEach(item => {
          item.set('content_type', ctype);
          item.set('object_id', object.get('id'));
        });
        return _promises.default.saveSequentially(agentItems);
      });
      return _rsvp.default.all([_rsvp.default.all(additionalIdentifiers.filterBy('isNew').invoke('save')), preparationsData.save(), itemData.save(object, accessRights), accessRights.saveWithTarget(object), _promises.default.saveSequentially(classificationItems), saveAgentsItems]);
    }
    _save() {
      var i18n = (0, _i18n.getI18n)();
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return this.saveObject().then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        // TODO: Better place for this
        this.set('routeHasBeenLoaded', false);
      });
    }
    save() {
      return this._save().then(() => {
        this.send('goBack');
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = PhotobankEditController;
});