define("plutof/controllers/clipboard/sequence", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/components/clipboard/export", "plutof/controllers/clipboard/clipboard", "plutof/controllers/clipboard/utils/bulk-update", "plutof/helpers/icon", "plutof/misc/abstract", "plutof/utils/loading", "plutof/utils/pagination"], function (_exports, _controller, _object, _service, _utils, _environment, _export, _clipboard, _bulkUpdate, _icon, _abstract, _loading, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // Panel listing choices From "BULK_UPDATE_PANELS" (clipboard/bulk-update.js)
  const SEQUENCE_CLIPBOARD_PANELS = ['sequences', 'samplingareas', 'samplingevents', 'sequences-access'];
  function extractMatchingInput(sequence) {
    return {
      id: sequence.id,
      accno: sequence.name
    };
  }
  let ClipboardSequenceController = (_class = class ClipboardSequenceController extends _controller.default.extend(_clipboard.ClipboardControllerMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "clipboardURL", _environment.default.API_HOST + '/clipboard/clipboards/sequences/');
      _defineProperty(this, "contentType", 'sequence/sequence');
      _defineProperty(this, "modelPath", 'taxonoccurrence/sequence/sequence');
      _defineProperty(this, "viewRoute", 'sequence.view');
      _defineProperty(this, "countField", 'sequences');
      _defineProperty(this, "bulkUpdatePanels", (0, _bulkUpdate.getBulkUpdatePanels)(SEQUENCE_CLIPBOARD_PANELS));
      _defineProperty(this, "bulkEndpoint", 'bulk-update/sequences/');
      _defineProperty(this, "mainformType", 'sequence');
      _defineProperty(this, "clipboardModule", 'clipboard_sequence');
      _defineProperty(this, "canBulkAnnotate", _environment.default.ENABLE_BULK_ANNOTATIONS);
    }
    get externalActions() {
      const actions = [{
        icon: (0, _icon.icon)('analysis'),
        label: 'Clipboard.actions.startBLASTSHMatching.label',
        action: () => this.createSHAnalysis('blastn-sh')
      }];
      if (!_environment.default.DISABLED_ANALYSES.includes('sh_match_processed')) {
        actions.push({
          icon: (0, _icon.icon)('analysis'),
          label: 'Clipboard.actions.startSHMatching.label',
          action: () => this.createSHAnalysis('sh_match_processed')
        });
      }
      if (_environment.default.ENABLE_BULK_ANNOTATIONS) {
        actions.push({
          icon: (0, _icon.icon)('annotation'),
          label: 'Clipboard.actions.bulkAnnotate.label',
          action: () => this.bulkAnnotate()
        });
      }
      return actions;
    }
    loadAllSequences() {
      const getCount = () => this.getClipboardCount();
      const requestPage = (pageNum, pageSize) => {
        // TODO: Throw away ember-ajax. That joke of a package can't even serialize QP properly
        const url = (0, _abstract.construct_request)(this.clipboardURL, {
          page: pageNum,
          page_size: pageSize
        });
        return this.ajax.request(url).then(data => data.results);
      };
      return (0, _loading.loadAllGeneric)(getCount, requestPage, 100);
    }

    // TODO: Use clipboard service
    async createSHAnalysis(type) {
      let selection = this.selectedEntries;
      if ((0, _utils.isEmpty)(selection)) {
        // TODO: Don't load entire clipboard immediately. initClipboardMatching now accepts
        // pagination, so we can init with only one page requested and finish during saving.
        // This, however, requires resized pagination views (e.g. paginate by 10, but on the
        // background, query by 100).
        selection = await this.loadAllSequences();
      }

      // 100-s of sequences are expected here, so can't shove them into QP: URL might overflow and
      // in any case, fetching 100 full sequences and rendering 100 rows is a waste. So SH matching
      // treats clipboard items differently during analysis add
      const analysisRoute = await this.transitionToRoute('analysis.add');
      const pagination = (0, _pagination.paginateLocalObjects)(selection.map(extractMatchingInput), {
        pageSize: 10
      });
      return analysisRoute.initClipboardMatching(pagination, {
        type
      });
    }
    bulkAnnotate() {
      return this.router.transitionTo('annotations.bulk.add', encodeURIComponent(this.contentType), {
        queryParams: {
          selection_only: !(0, _utils.isEmpty)(this.selectedEntries)
        }
      });
    }
    get exportModel() {
      return new _export.ClibpoardExportModel({
        store: this.store,
        enabledFormats: ['csv', 'list-of-species', 'darwin-core', 'fasta', 'genbank'],
        endpointPrefix: '/export/sequences/'
      });
    }
    setAnalyser() {
      this.set('analysisMain', true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "externalActions", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "externalActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportModel", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "exportModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAnalyser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setAnalyser"), _class.prototype), _class);
  var _default = _exports.default = ClipboardSequenceController;
});