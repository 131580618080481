define("plutof/components/taxonomy/taxon/view/external-links-view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#@view.attr "col_id"}}
      {{#if @taxon.col_id}}
          <a href="https://www.catalogueoflife.org/data/taxon/{{@taxon.col_id}}" target="_blank">
              {{@taxon.col_id}} <span class={{icon "external-link"}}></span>
          </a>
      {{/if}}
  {{/@view.attr}}
  
  {{#@view.attr "gbif_id"}}
      {{#if @taxon.gbif_id}}
          <a href="https://www.gbif.org/species/{{@taxon.gbif_id}}" target="_blank">
              {{@taxon.gbif_id}} <span class={{icon "external-link"}}></span>
          </a>
      {{/if}}
  {{/@view.attr}}
  
  {{#@view.attr "mycobank_id"}}
      {{#if @taxon.mycobank_id}}
          <a href="https://www.mycobank.org/mb/{{@taxon.mycobank_id}}" target="_blank">
              {{@taxon.mycobank_id}} <span class={{icon "external-link"}}></span>
          </a>
      {{/if}}
  {{/@view.attr}}
  
  {{#@view.attr "mycobank_id" label=(i18n-t "Taxonomy.indexFungorumId")}}
      {{#if @taxon.mycobank_id}}
          <a href="https://indexfungorum.org/names/NamesRecord.asp?RecordID={{@taxon.mycobank_id}}" target="_blank">
              {{@taxon.mycobank_id}} <span class={{icon "external-link"}}></span>
          </a>
      {{/if}}
  {{/@view.attr}}
  
  */
  {
    "id": "CJhCuhfC",
    "block": "[[[6,[30,1,[\"attr\"]],[\"col_id\"],null,[[\"default\"],[[[[41,[30,2,[\"col_id\"]],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://www.catalogueoflife.org/data/taxon/\",[30,2,[\"col_id\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,2,[\"col_id\"]]],[1,\" \"],[10,1],[15,0,[28,[37,1],[\"external-link\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"attr\"]],[\"gbif_id\"],null,[[\"default\"],[[[[41,[30,2,[\"gbif_id\"]],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://www.gbif.org/species/\",[30,2,[\"gbif_id\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,2,[\"gbif_id\"]]],[1,\" \"],[10,1],[15,0,[28,[37,1],[\"external-link\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"attr\"]],[\"mycobank_id\"],null,[[\"default\"],[[[[41,[30,2,[\"mycobank_id\"]],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://www.mycobank.org/mb/\",[30,2,[\"mycobank_id\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,2,[\"mycobank_id\"]]],[1,\" \"],[10,1],[15,0,[28,[37,1],[\"external-link\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"attr\"]],[\"mycobank_id\"],[[\"label\"],[[28,[37,2],[\"Taxonomy.indexFungorumId\"],null]]],[[\"default\"],[[[[41,[30,2,[\"mycobank_id\"]],[[[1,\"        \"],[10,3],[15,6,[29,[\"https://indexfungorum.org/names/NamesRecord.asp?RecordID=\",[30,2,[\"mycobank_id\"]]]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,2,[\"mycobank_id\"]]],[1,\" \"],[10,1],[15,0,[28,[37,1],[\"external-link\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@view\",\"@taxon\"],false,[\"if\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/view/external-links-view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});