define("plutof/components/information/content/observation/list-view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="general/list-view" @locale={{@locale}} />
  <Information::MarkdownReader @path="observation/list-view" @locale={{@locale}} />
  
  */
  {
    "id": "oUk62bJu",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@locale\"],[\"general/list-view\",[30,1]]],null],[1,\"\\n\"],[8,[39,0],null,[[\"@path\",\"@locale\"],[\"observation/list-view\",[30,1]]],null],[1,\"\\n\"]],[\"@locale\"],false,[\"information/markdown-reader\"]]",
    "moduleName": "plutof/components/information/content/observation/list-view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});