define("plutof/components/search/query/active-filters", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{common/local-label "Search.activeFilters"}}
  
  <div class="active-filters spacer-large-below">
      {{#each @filters key="name" as |filter|}}
          {{#if (not (is-equal filter.name "textQuery"))}}
              {{#if (not filter.isEmpty)}}
                  <div class="active-filters__filter plutof-fade-in">
                      {{#fade-element}}
                          <div class="plutof-fade-in">
                              <Search::Query::ActiveFilter
                                  @filter={{filter}}
                                  @clear={{fn @clear filter}} />
                          </div>
                      {{/fade-element}}
                  </div>
              {{/if}}
          {{/if}}
      {{/each}}
  
      {{#if @absoluteOrdering}}
          <div class="active-filters__filter">
              {{i18n-t "Search.ordering"}}
              {{! TODO: Button }}
              <span {{on "click" @clearOrdering}} class="icon-remove--red clickable"></span>
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "Qg62Eu7U",
    "block": "[[[1,[28,[35,0],[\"Search.activeFilters\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"active-filters spacer-large-below\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],\"name\",[[[41,[28,[37,4],[[28,[37,5],[[30,2,[\"name\"]],\"textQuery\"],null]],null],[[[41,[28,[37,4],[[30,2,[\"isEmpty\"]]],null],[[[1,\"                \"],[10,0],[14,0,\"active-filters__filter plutof-fade-in\"],[12],[1,\"\\n\"],[6,[39,6],null,null,[[\"default\"],[[[[1,\"                        \"],[10,0],[14,0,\"plutof-fade-in\"],[12],[1,\"\\n                            \"],[8,[39,7],null,[[\"@filter\",\"@clear\"],[[30,2],[28,[37,8],[[30,3],[30,2]],null]]],null],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]]]]],[1,\"                \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[2]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,0],[14,0,\"active-filters__filter\"],[12],[1,\"\\n            \"],[1,[28,[35,9],[\"Search.ordering\"],null]],[1,\"\\n\"],[1,\"            \"],[11,1],[24,0,\"icon-remove--red clickable\"],[4,[38,10],[\"click\",[30,5]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@filters\",\"filter\",\"@clear\",\"@absoluteOrdering\",\"@clearOrdering\"],false,[\"common/local-label\",\"each\",\"-track-array\",\"if\",\"not\",\"is-equal\",\"fade-element\",\"search/query/active-filter\",\"fn\",\"i18n-t\",\"on\"]]",
    "moduleName": "plutof/components/search/query/active-filters.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});