define("plutof/templates/collection/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mvc6HDxv",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"collection\"],null],[28,[37,2],[\"collection.edit.title\"],null],[30,0,[\"model\",\"collection\"]],[28,[37,2],[\"collection.collection\"],null],\"information/content/collection/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@collection\",\"@repositories\",\"@workgroupData\",\"@identifiers\",\"@linkedItems\",\"@objectsToDelete\",\"@validationContext\",\"@setLogo\",\"@save\",\"@cancel\"],[[30,0,[\"model\",\"collection\"]],[30,0,[\"model\",\"repositories\"]],[30,0,[\"model\",\"workgroupData\"]],[30,0,[\"model\",\"identifiers\"]],[30,0,[\"model\",\"linkedItems\"]],[30,0,[\"model\",\"objectsToDelete\"]],[30,0,[\"validationContext\"]],[30,0,[\"setLogo\"]],[30,0,[\"save\"]],[28,[37,4],[[30,0],\"cancel\"],null]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"collection/edit\",\"action\"]]",
    "moduleName": "plutof/templates/collection/edit.hbs",
    "isStrictMode": false
  });
});