define("plutof/components/map/mixins/default-styling", ["exports", "@ember/array", "@ember/object", "@ember/object/mixin", "@ember/utils", "ol/extent", "ol/format", "ol/geom", "ol/style", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/map"], function (_exports, _array, _object, _mixin, _utils, _extent, _format, _geom, _style, _abstract, _config, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.makeCountryCentroidFeature = makeCountryCentroidFeature;
  const STYLE_ATTRIBUTES = ['radius', 'color', 'opacity', 'strokeWidth', 'stokeColor', 'strokeLineDash'];
  function getOpaqueColor(color, alpha = 1) {
    if (!(0, _array.isArray)(color)) {
      return color;
    }
    const [r, g, b] = color;
    return [r, g, b, alpha];
  }
  function getOLStyle(geometryType, styleParams) {
    const strokeColor = styleParams.strokeColor || getOpaqueColor(styleParams.color);
    if (geometryType === 'Point') {
      return new _style.Style({
        text: new _style.Text({
          text: '\uf3c5',
          font: 'normal 900 18px "Font Awesome 5 Free"',
          textBaseline: 'bottom',
          scale: 1.25,
          fill: new _style.Fill({
            color: styleParams.color
          }),
          stroke: new _style.Stroke({
            color: strokeColor,
            width: styleParams.strokeWidth
          })
        })
      });
    } else {
      const fill = new _style.Fill({
        color: getOpaqueColor(styleParams.color, styleParams.opacity)
      });
      const stroke = new _style.Stroke({
        color: strokeColor,
        width: styleParams.strokeWidth,
        lineDash: styleParams.strokeLineDash
      });
      return new _style.Style({
        image: new _style.Circle({
          radius: styleParams.radius,
          fill: fill,
          stroke: stroke
        }),
        fill: fill,
        stroke: stroke
      });
    }
  }

  // TODO: Something less rage-inducing for this. Not sure how exactly, problems being
  //
  // 1) Passing text to a class-based style
  // 2) Collapsing country geometry to a centroid on style level? Feels weird
  //
  // For now, that will do
  function makeCountryCentroidFeature(countryCode, countryGeom) {
    const centroidGeometry = new _geom.Point((0, _map.getWKTCenter)(countryGeom));
    const geom = new _format.WKT().writeGeometry(centroidGeometry);
    const style = selected => {
      const color = selected ? [255, 255, 0, 1] : [255, 0, 0, 1];
      const fillColor = getOpaqueColor(color, 0.3);
      const text = new _style.Text({
        text: countryCode,
        scale: 1.2,
        fill: new _style.Fill({
          color: [0, 0, 0, 1]
        }),
        stroke: new _style.Stroke({
          color: [255, 255, 255, 1]
        })
      });
      return new _style.Style({
        image: new _style.Circle({
          radius: 15,
          stroke: new _style.Stroke({
            color: color
          }),
          fill: new _style.Fill({
            color: fillColor
          })
        }),
        text: text
      });
    };
    return {
      geom: geom,
      rawStyle: style
    };
  }

  // For now, the styling interface is getStyle(type, params, geometry), but should
  // probably make it getFeatureStyle(feature), because the current versions requires
  // base-map to know about defaultStyle/selectedStyle etc
  const DefaultMapStylingMixin = _mixin.default.create({
    collapseSmallPolygons: _config.default.Sample.COLLAPSE_SMALL_POLYGONS,
    baseStyles: {
      base: {
        radius: 7,
        color: [0xff, 0x40, 0, 0.75],
        strokeWidth: 1,
        opacity: 0.3
      },
      selected: {
        radius: 10,
        color: [0xff, 0xcc, 0x33, 0.75],
        opacity: 0.3
      },
      layer: {
        color: [255, 255, 255, 0],
        strokeColor: [0, 0, 0, 1],
        strokeLineDash: [30, 30],
        strokeWidth: 1,
        opacity: 0.1
      },
      parent: {
        color: [255, 255, 255, 0],
        strokeColor: [0, 0, 0, 1],
        strokeLineDash: [15, 15],
        strokeWidth: 1,
        opacity: 0.1
      },
      child: {
        strokeColor: [0, 0, 0, 1],
        strokeWidth: 1
      },
      predefined: {
        strokeWidth: 2
      }
    },
    init: function () {
      this._super(...arguments);
      this.set('_styleCache', {});
      this.set('_styleCacheHits', 0);
      this.set('_styleCacheMisses', 0);
    },
    // Gets merged with baseStyle for overriding through component attrs
    extensionStyles: {},
    styles: (0, _object.computed)('baseStyles', 'extensionStyles', function () {
      return (0, _abstract.merge_objects_rec)(this.baseStyles, this.extensionStyles);
    }),
    // It's a comp.prop because it needs to clear on styles update, but be cached in the meantime
    styleCache: (0, _object.computed)('styles', function () {
      return {};
    }),
    getStyleFromClass: function (className) {
      let cache = this.styleCache;
      if ((0, _utils.isNone)(cache[className])) {
        const styles = this.styles;
        const parts = (className || '').split('.');
        cache[className] = parts.map(partName => styles[partName]).compact().reduce(_abstract.mergeObjects, styles.base);
      }
      return cache[className];
    },
    // TODO: Something better instead of calculating (expensive) hash for each style
    hashStyle: function (type, params) {
      return type + ': ' + STYLE_ATTRIBUTES.map(key => {
        const value = (0, _utils.isNone)(params[key]) ? 'null' : params[key].toString();
        return `${key}:${value}`;
      }).join(';');
    },
    resolveStyle: function (type, styleParams) {
      const styleCache = this._styleCache;
      const styleHash = this.hashStyle(type, styleParams);
      if (!styleCache[styleHash]) {
        styleCache[styleHash] = getOLStyle(type, styleParams);
        this.incrementProperty('_styleCacheMisses');
      } else {
        this.incrementProperty('_styleCacheHits');
      }
      return styleCache[styleHash];
    },
    getStyle: function (type, params, geometry, label) {
      // Trapdoor to just shove raw OL styles in. rawStyle is func (selected => ol.style)
      if (!(0, _utils.isNone)(params.rawStyle)) {
        return params.rawStyle(false);
      }

      // Get style params: class + overrides
      const classStyle = this.getStyleFromClass(params.styleClass || 'base');
      const styleParams = (0, _abstract.mergeObjects)(classStyle, params);
      let normalStyle = this.resolveStyle(type, styleParams);
      const labelStyle = new _style.Style({
        text: new _style.Text({
          text: label,
          textBaseline: 'hanging',
          overflow: true,
          scale: 1.25,
          stroke: new _style.Stroke({
            color: [32, 32, 32, 0],
            width: 1
          })
        })
      });
      if (label) {
        normalStyle = [normalStyle, labelStyle];
      }
      if (type === 'Point') {
        return normalStyle;
      } else {
        // Can't hash this because it depends on the geometry
        if ((0, _utils.isNone)(geometry) || type === 'MultiPolygon' || !this.collapseSmallPolygons) {
          return normalStyle;
        }
        let collapsedStyle = new _style.Style({
          text: new _style.Text({
            text: '\uf3c5',
            textBaseline: 'right',
            overflow: true,
            font: 'normal 900 18px "Font Awesome 5 Free"',
            scale: 1.25,
            fill: new _style.Fill({
              color: styleParams.color
            }),
            stroke: new _style.Stroke({
              color: styleParams.strokeColor || styleParams.color,
              width: styleParams.strokeWidth
            })
          })
        });
        if (label) {
          collapsedStyle = [collapsedStyle, labelStyle];
        }
        const collapsePoint = _config.default.Sample.SMALL_POLYGON_COLLAPSE_POINT;
        return resolution => {
          const [w, h] = this.getGeometryPixelSize(geometry);
          return w > collapsePoint && h > collapsePoint ? normalStyle : collapsedStyle;
        };
      }
    },
    getGeometryPixelSize: function (geometry) {
      const map = this.map;
      const extent = geometry.getExtent();
      const topLeft = (0, _extent.getTopLeft)(extent);
      const bottomRight = (0, _extent.getBottomRight)(extent);
      const [tlx, tly] = map.getPixelFromCoordinate(topLeft);
      const [brx, bry] = map.getPixelFromCoordinate(bottomRight);
      return [brx - tlx, bry - tly];
    }
  });
  var _default = _exports.default = DefaultMapStylingMixin;
});