define("plutof/components/information/article/-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @ordered}}
      <ol>
          {{yield (hash item=(component "information/article/-list-item"))}}
      </ol>
  {{else}}
      <ul>
          {{yield (hash item=(component "information/article/-list-item"))}}
      </ul>
  {{/if}}
  
  */
  {
    "id": "xf5/xw9E",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,\"ol\"],[12],[1,\"\\n        \"],[18,2,[[28,[37,2],null,[[\"item\"],[[50,\"information/article/-list-item\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"ul\"],[12],[1,\"\\n        \"],[18,2,[[28,[37,2],null,[[\"item\"],[[50,\"information/article/-list-item\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[\"@ordered\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/information/article/-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});