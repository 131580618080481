define("plutof/templates/components/observation/substrate-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7d79tJZw",
    "block": "[[[10,\"td\"],[12],[1,\"\\n\"],[6,[39,0],[[30,1,[\"substrate\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,1],[[24,\"data-test\",\"substrate\"]],[[\"@value\",\"@update\",\"@params\"],[[30,2],[30,3],[28,[37,2],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[2,3]]]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,\"data-test\",\"substrate_type\"]],[[\"@content\",\"@value\",\"@selectClass\"],[[30,0,[\"types\"]],[30,1,[\"type\"]],\"mini-measurement-input mini-measurement-select\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,0,\"mini-measurement-input\"],[24,\"data-test\",\"substrate_text\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"text\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@substrate\",\"value\",\"update\"],false,[\"async/bind-relation\",\"taxonomy/taxon/auto-complete\",\"hash\",\"plutof-select/value\",\"input\"]]",
    "moduleName": "plutof/templates/components/observation/substrate-edit.hbs",
    "isStrictMode": false
  });
});