define("plutof/components/experiment/sequencing/view/sequencing-material-tube", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let SequencingExperimentViewTube = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class SequencingExperimentViewTube extends _component.default {}) || _class);
  var _default = _exports.default = SequencingExperimentViewTube;
});