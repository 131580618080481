define("plutof/components/common/data-quality/warning/count", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip
      @tooltip={{i18n-t 'DataQuality.warnings.individualCount' count=@count}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="data-quality-warning data-quality-warning--text">
          {{i18n-t "DataQuality.general.count"}}
      </span>
  </ToolTip>
  
  */
  {
    "id": "reeXALTx",
    "block": "[[[8,[39,0],null,[[\"@tooltip\",\"@class\"],[[28,[37,1],[\"DataQuality.warnings.individualCount\"],[[\"count\"],[[30,1]]]],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"data-quality-warning data-quality-warning--text\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"DataQuality.general.count\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@count\"],false,[\"tool-tip\",\"i18n-t\"]]",
    "moduleName": "plutof/components/common/data-quality/warning/count.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});