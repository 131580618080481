define("plutof/components/trait/view/set-choices", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "Forms.traits.setChoices"}}
          @count={{@choices.length}} />
  
      <panel.content>
          <Tables::List @records={{@choices}} as |Field choice|>
              <Field @label={{backend-translations/label "measurement.measurementsetelement.element"}}>
                  {{choice.element}}
              </Field>
  
              <Field @label={{backend-translations/label "measurement.measurementsetelement.element_translated"}}>
                  {{choice.element_translated}}
              </Field>
  
              <Field @label={{backend-translations/label "measurement.measurementsetelement.description"}}>
                  {{choice.description}}
              </Field>
  
              <Field @label={{backend-translations/label "measurement.measurementsetelement.description_translated"}}>
                  {{choice.description_translated}}
              </Field>
  
              <Field @label={{backend-translations/label "measurement.measurementsetelement.code"}}>
                  {{choice.code}}
              </Field>
          </Tables::List>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "0C24IOv8",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"Forms.traits.setChoices\"],null],[30,2,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"measurement.measurementsetelement.element\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"element\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"measurement.measurementsetelement.element_translated\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"element_translated\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"measurement.measurementsetelement.description\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"description\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"measurement.measurementsetelement.description_translated\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"description_translated\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"measurement.measurementsetelement.code\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"code\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[3,4]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@choices\",\"Field\",\"choice\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"tables/list\",\"backend-translations/label\"]]",
    "moduleName": "plutof/components/trait/view/set-choices.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});