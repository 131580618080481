define("plutof/components/habitat/view-type", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (get @habitat (concat @type ".id"))}}
      {{#@view.attr @type}}
          {{get @habitat (concat @type ".representation")}}
      {{/@view.attr}}
  {{/if}}
  
  */
  {
    "id": "R4aIh84r",
    "block": "[[[41,[28,[37,1],[[30,1],[28,[37,2],[[30,2],\".id\"],null]],null],[[[6,[30,3,[\"attr\"]],[[30,2]],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[[30,1],[28,[37,2],[[30,2],\".representation\"],null]],null]],[1,\"\\n\"]],[]]]]]],[]],null]],[\"@habitat\",\"@type\",\"@view\"],false,[\"if\",\"get\",\"concat\"]]",
    "moduleName": "plutof/components/habitat/view-type.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});