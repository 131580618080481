define("plutof/templates/taxonomy/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "blosi+q4",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"taxonomy.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[28,[37,2],[\"taxon-node\"],null],[28,[37,3],[\"Taxonomy.editTaxon\"],null],[30,0,[\"model\",\"traxon\"]],\"information/content/taxon/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@taxon\",\"@commonNames\",\"@linkedItems\",\"@addVernacularName\",\"@removeVernacularName\",\"@disableRankField\",\"@validationContext\"],[[30,0,[\"model\",\"taxon\"]],[30,0,[\"model\",\"commonNames\"]],[30,0,[\"model\",\"linkedItems\"]],[30,0,[\"addVernacularName\"]],[30,0,[\"removeVernacularName\"]],[30,0,[\"model\",\"hasRank\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@taxon\",\"@title\"],[[30,0,[\"model\",\"taxon\"]],[28,[37,3],[\"Taxonomy.editPanelTitle\"],null]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n    \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,8],null,[[\"@cancel\"],[[30,0,[\"cancel\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"taxonomy/edit\",\"taxonomy/edit-remarks-panel\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/taxonomy/edit.hbs",
    "isStrictMode": false
  });
});