define("plutof/templates/components/referencebased/bio-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DWcDB3TS",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"fields\"]]],null]],null],null,[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"name\",\"label\",\"select\"],[[30,1,[\"name\"]],[50,\"plutof-labelc\",0,null,[[\"path\"],[[30,1,[\"labelPath\"]]]]],[50,\"plutof-select/value\",0,null,[[\"content\",\"oneWay\",\"value\",\"selectionChanged\"],[[30,1,[\"choices\"]],true,[28,[37,5],[[30,2],[30,1,[\"name\"]]],null],[28,[37,6],[[30,0,[\"setField\"]],[30,1]],null]]]]]]]]],[1,\"\\n\"]],[1]],null]],[\"field\",\"@biostatus\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"hash\",\"component\",\"get\",\"fn\"]]",
    "moduleName": "plutof/templates/components/referencebased/bio-status.hbs",
    "isStrictMode": false
  });
});