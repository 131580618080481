define("plutof/templates/components/unite/sequence-guidelines", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QsJ0Tm/t",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],\"@index\",[[[1,\"        \"],[10,\"td\"],[14,0,\"colorized\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,\"td\"],[14,0,\"non-colorized\"],[12],[1,[34,5]],[13],[1,\"\\n\"]],[]]]],[\"char\"],false,[\"if\",\"colorize\",\"each\",\"-track-array\",\"guidelineStringChars\",\"guidelineString\"]]",
    "moduleName": "plutof/templates/components/unite/sequence-guidelines.hbs",
    "isStrictMode": false
  });
});