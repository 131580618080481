define("plutof/components/history/record", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules", "plutof/helpers/icon"], function (_exports, _component, _templateFactory, _component2, _modules, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip @tooltip={{@change.record_content_type}}>
      <span class="{{this.icon}} history__record-type-icon"></span>
      {{@change.record}}
  </ToolTip>
  
  */
  {
    "id": "qG393z9w",
    "block": "[[[8,[39,0],null,[[\"@tooltip\"],[[30,1,[\"record_content_type\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[29,[[30,0,[\"icon\"]],\" history__record-type-icon\"]]],[12],[13],[1,\"\\n    \"],[1,[30,1,[\"record\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@change\"],false,[\"tool-tip\"]]",
    "moduleName": "plutof/components/history/record.hbs",
    "isStrictMode": false
  });
  class HistoryRecordLabel extends _component2.default {
    get icon() {
      const module = _modules.default[this.args.change.record_content_type];
      if (!module || !module.icon) {
        return (0, _icon.icon)('trait');
      }
      return module.icon;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HistoryRecordLabel);
});