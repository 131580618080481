define("plutof/components/save-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed"], function (_exports, _component, _templateFactory, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="save-panel">
      <Common::SaveButton
          @save={{@save}}
          @disabled={{this.validationContext.isInvalid}}
          class="save-panel__btn" />
  
      <Common::CancelButton @cancel={{@cancel}} />
  
      <SaveAndReset
          @targetObj={{@ctrl}}
          @reset={{@reset}}
          @disabled={{this.validationContext.isInvalid}}
          class="save-panel__btn" />
  
      <PromiseButton
          @clicked={{fn (action @clone) true true}}
          @disabled={{this.validationContext.isInvalid}}
          @restIcon={{icon "clone"}}
          @tooltip={{i18n-t "cloning.saveAndCloneTooltip"}}
          class="btn btn-default clone-button save-panel__btn"
      >
          {{i18n-t "cloning.saveAndClone"}}
      </PromiseButton>
  
      <PromiseButton
          @clicked={{fn (action @clone) false true}}
          @disabled={{this.validationContext.isInvalid}}
          @restIcon={{icon "use-as-template"}}
          @tooltip={{i18n-t "cloning.saveUseAreaTooltip"}}
          class="btn btn-default link-to-button save-panel__btn"
      >
          {{i18n-t "cloning.saveUseArea"}}
      </PromiseButton>
  
      <PromiseButton
          @clicked={{fn (action @clone) false false}}
          @disabled={{this.validationContext.isInvalid}}
          @restIcon={{icon "use-as-template"}}
          @tooltip={{i18n-t "cloning.saveUseEventTooltip"}}
          class="btn btn-default save-panel__btn"
      >
          {{i18n-t "cloning.saveUseEvent"}}
      </PromiseButton>
  </div>
  
  */
  {
    "id": "aYBueN98",
    "block": "[[[10,0],[14,0,\"save-panel\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"save-panel__btn\"]],[[\"@save\",\"@disabled\"],[[30,1],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@cancel\"],[[30,2]]],null],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"save-panel__btn\"]],[[\"@targetObj\",\"@reset\",\"@disabled\"],[[30,3],[30,4],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default clone-button save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],true,true],null],[30,0,[\"validationContext\",\"isInvalid\"]],[28,[37,6],[\"clone\"],null],[28,[37,7],[\"cloning.saveAndCloneTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveAndClone\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default link-to-button save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],false,true],null],[30,0,[\"validationContext\",\"isInvalid\"]],[28,[37,6],[\"use-as-template\"],null],[28,[37,7],[\"cloning.saveUseAreaTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveUseArea\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],false,false],null],[30,0,[\"validationContext\",\"isInvalid\"]],[28,[37,6],[\"use-as-template\"],null],[28,[37,7],[\"cloning.saveUseEventTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveUseEvent\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@save\",\"@cancel\",\"@ctrl\",\"@reset\",\"@clone\"],false,[\"common/save-button\",\"common/cancel-button\",\"save-and-reset\",\"promise-button\",\"fn\",\"action\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/save-panel.hbs",
    "isStrictMode": false
  });
  let SavePanel = (_dec = (0, _computed.oneWay)('ctrl.validationContext'), _class = class SavePanel extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "validationContext", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "validationContext", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SavePanel);
});