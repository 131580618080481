define("plutof/templates/components/referencebased/view/bio-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2CpkPXhV",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Referencebased::View::BioStatus\"]],[[\"@title\",\"@collapse\"],[[28,[37,1],[\"ReferenceBased.bioStatus\"],null],[28,[37,2],[[30,0,[\"notEmpty\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"origin\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"originDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"presence\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"presenceDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"persistence\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"persistenceDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"distribution\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"distributionDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"abundance\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"abundanceDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"trend\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"trendDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"rate_of_spread\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"rateOfSpreadDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"harmful\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"harmfulDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"regulatory_listing\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"regulatoryListingDisplayName\"]]],[1,\"\\n\"]],[]]]]],[1,\"        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@status\",\"view\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"not\",\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/components/referencebased/view/bio-status.hbs",
    "isStrictMode": false
  });
});