define("plutof/components/chart/pheno-graph-period", ["exports", "@ember/component", "@ember/object", "@ember/utils", "moment", "plutof/components/chart/scatter-chart", "plutof/utils/chart", "plutof/utils/i18n"], function (_exports, _component, _object, _utils, moment, _scatterChart, _chart, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  function createChatterOptions(data, field) {
    return {
      responsive: true,
      title: {
        display: true,
        text: field.label
      },
      legend: {
        display: true,
        labels: {
          fontSize: 10,
          boxWidth: 20
        }
      },
      elements: {
        point: {
          pointStyle: 'rect'
        }
      },
      hover: {
        mode: 'nearest'
      },
      scales: {
        xAxes: [{
          type: 'linear',
          position: 'bottom',
          scaleLabel: {
            display: true,
            labelString: `${i18n.t('chart.time')} (${i18n.t('chart.month')})`
          },
          ticks: {
            min: 1,
            max: 13,
            stepSize: 1,
            callback: value => value === 13 ? '' : value.toString()
          }
        }],
        yAxes: [{
          ticks: {
            min: Math.min(...(0, _scatterChart.getYears)(data)) - 1,
            max: Math.max(...(0, _scatterChart.getYears)(data)) + 1,
            stepSize: 1
          },
          scaleLabel: {
            display: true,
            labelString: `${i18n.t('chart.time')} (${i18n.t('chart.year')})`
          }
        }]
      },
      tooltips: {
        callbacks: {
          title: function ([tooltipItem], data) {
            const tooltipInfo = (0, _scatterChart.getTooltip)(tooltipItem, data.datasets);
            return tooltipInfo.date;
          },
          label: function (tooltipItem, data) {
            const tooltipInfo = (0, _scatterChart.getTooltip)(tooltipItem, data.datasets);
            return i18n.t('chart.count') + ': ' + tooltipInfo.count;
          }
        }
      }
    };
  }
  function createChatterData(results) {
    const dataKeys = Object.keys(results);
    // Get unique id 11578 from (11578_2016, 11578_2017, 11578_2018)
    const uniqueKeys = (0, _scatterChart.getUniqueIDs)(dataKeys);
    // Creating datasets based on unique ids of data.results

    const datasets = uniqueKeys.map((key, i) => {
      const chartColor = (0, _chart.getChartColor)(key, i);
      return {
        id: key,
        backgroundColor: chartColor,
        borderColor: chartColor,
        data: [],
        pointRadius: [],
        pointHoverRadius: [],
        showLine: false,
        fill: false
      };
    });
    dataKeys.forEach(key => {
      const dataset = results[key];
      const id = (0, _scatterChart.getElementID)(key);
      const correspondingDataset = datasets.findBy('id', id);
      correspondingDataset.label = dataset.name;
      dataset.data.forEach(datum => {
        const date = datum.key;
        const parsedDate = moment(date);
        const dayInNumericScale = (parsedDate.date() - 1) / 31;
        const positionX = parsedDate.month() + 1 + dayInNumericScale;
        const datasetMax = dataKeys.filter(k => k.includes(correspondingDataset.id)).map(k => results[k]).mapBy('data').map(d => d.mapBy('count')).map(counts => Math.max.apply(Math, counts));
        let absoluteMaximum = Math.max.apply(Math, datasetMax);
        if (absoluteMaximum < 10) {
          absoluteMaximum = 10; // Looks pretty silly when count 3 is at 100%
        }
        correspondingDataset.data.pushObject({
          x: positionX,
          y: parseInt(moment(date).format('YYYY')),
          count: datum.count,
          date: moment(date).format('MM.DD')
        });
        const heightPercent = datum.count * 100 / absoluteMaximum;
        let heightInt = heightPercent * _scatterChart.MAX_RECT_HEIGHT / 100;
        if (heightInt < 2) {
          heightInt = 2;
        }
        correspondingDataset.pointRadius.push(heightInt);
        correspondingDataset.pointHoverRadius.push(heightInt + 1);
      });
    });
    return datasets;
  }
  function createScatterConfig({
    context,
    data,
    field
  }) {
    if ((0, _utils.isEmpty)(data)) {
      return {};
    }
    return {
      type: 'line',
      data: {
        labels: _scatterChart.DEFAULT_LABELS,
        datasets: createChatterData(data, this)
      },
      options: createChatterOptions(data, field)
    };
  }
  let ScatterChart = (_dec = (0, _object.computed)('config.isFulfilled'), _class = class ScatterChart extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", null);
    }
    get chartConfig() {
      const config = this.config;
      if (this.get('config.isFulfilled')) {
        return createScatterConfig(config.getProperties('context', 'data', 'field'));
      } else {
        return null;
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "chartConfig", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chartConfig"), _class.prototype), _class);
  var _default = _exports.default = ScatterChart;
});