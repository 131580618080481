define("plutof/components/plutof-panel/item-list/add-buttons", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@button
      @clicked={{fn @add 1}}
      @disabled={{@disable}}
  >
      {{i18n-t "General.Add"}} 1
  </@button>
  
  <@button
      @clicked={{fn @add 10}}
      @disabled={{@disable}}
  >
      {{i18n-t "General.Add"}} 10
  </@button>
  
  */
  {
    "id": "lLLAtM6W",
    "block": "[[[8,[30,1],null,[[\"@clicked\",\"@disabled\"],[[28,[37,0],[[30,2],1],null],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\" 1\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@clicked\",\"@disabled\"],[[28,[37,0],[[30,2],10],null],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\" 10\\n\"]],[]]]]],[1,\"\\n\"]],[\"@button\",\"@add\",\"@disable\"],false,[\"fn\",\"i18n-t\"]]",
    "moduleName": "plutof/components/plutof-panel/item-list/add-buttons.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});