define("plutof/components/information/content/unite/collapse", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>
      Level 7 - all sequences belonging to the SH are shown <br>
      Level 6 - identical sequences for the same country collapsed <br>
      Level 5 - identical sequences for the same continent collapsed <br>
      Level 4 - sequences within the same 0.5% SH for the same country collapsed <br>
      Level 3 - sequences within the same 0.5% SH for the same continent collapsed <br>
      Level 2 - sequences within the same 1.0% SH for the same country collapsed <br>
      Level 1 - sequences within the same 1.0% SH for the same continent collapsed
  </p>
  
  <p>
      The number of sequences after view label shows the number of representative sequences at specific level.
  </p>
  
  <p>
      The number (e.g. +2) after the sequence identifier in the view refers to the number of sequences collapsed under this specific representative sequence.
  </p>
  
  */
  {
    "id": "X/lnfMTo",
    "block": "[[[10,2],[12],[1,\"\\n    Level 7 - all sequences belonging to the SH are shown \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 6 - identical sequences for the same country collapsed \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 5 - identical sequences for the same continent collapsed \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 4 - sequences within the same 0.5% SH for the same country collapsed \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 3 - sequences within the same 0.5% SH for the same continent collapsed \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 2 - sequences within the same 1.0% SH for the same country collapsed \"],[10,\"br\"],[12],[13],[1,\"\\n    Level 1 - sequences within the same 1.0% SH for the same continent collapsed\\n\"],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n    The number of sequences after view label shows the number of representative sequences at specific level.\\n\"],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n    The number (e.g. +2) after the sequence identifier in the view refers to the number of sequences collapsed under this specific representative sequence.\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "plutof/components/information/content/unite/collapse.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});