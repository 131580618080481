define("plutof/components/transaction/view/objects/bulk-edit-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
     {{on "click" @edit}}
     disabled={{@disable}}
     class="btn-link btn-inline"
  >
      <span class={{icon (if @isLoading "loading" "ok")}}></span>
  </button>
  
  */
  {
    "id": "EmUGHlqE",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[24,0,\"btn-link btn-inline\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[28,[37,1],[[52,[30,3],\"loading\",\"ok\"]],null]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disable\",\"@edit\",\"@isLoading\"],false,[\"on\",\"icon\",\"if\"]]",
    "moduleName": "plutof/components/transaction/view/objects/bulk-edit-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});