define("plutof/templates/components/import/navbar-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mZw237uG",
    "block": "[[[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@clicked\"],[[28,[37,0],[\"import\"],null],[30,0,[\"goToImport\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"Import.import\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@navbar\"],false,[\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/import/navbar-button.hbs",
    "isStrictMode": false
  });
});