define("plutof/components/common/check-box", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="plutof-checkbox {{if @light 'plutof-checkbox--light'}}" ...attributes>
      <Input
          @type="checkbox"
          @checked={{@value}}
          @disabled={{@disabled}}
          {{on "input" this._changed}}
          class="plutof-checkbox__checkbox" />
  
      {{yield}}
  </label>
  
  */
  {
    "id": "CjPMO6s1",
    "block": "[[[11,\"label\"],[16,0,[29,[\"plutof-checkbox \",[52,[30,1],\"plutof-checkbox--light\"]]]],[17,2],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"plutof-checkbox__checkbox\"],[4,[38,2],[\"input\",[30,0,[\"_changed\"]]],null]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[30,3],[30,4]]],null],[1,\"\\n\\n    \"],[18,5,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@light\",\"&attrs\",\"@value\",\"@disabled\",\"&default\"],false,[\"if\",\"input\",\"on\",\"yield\"]]",
    "moduleName": "plutof/components/common/check-box.hbs",
    "isStrictMode": false
  });
  let CommonCheckBox = (_class = class CommonCheckBox extends _component2.default {
    _changed(event) {
      if (this.args.changed) {
        this.args.changed(event.target.checked);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_changed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_changed"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CommonCheckBox);
});