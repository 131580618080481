define("plutof/components/agent/organization/associations/-edit-row", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td class={{if @association.fieldErrors.person "has-error"}}>
          {{#async/bind-relation @association.person as |value update|}}
              <Agent::Person::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  @params={{hash mini=true}}
                  @noDefault={{true}} />
          {{/async/bind-relation}}
      </td>
  
      <td>
          {{! Not sure why, but two-way bound inputs stop working here after a while :shrug: }}
          <OneWayInput
              @value={{@association.agent_role}}
              @update={{this.updateRole}}
              class="mini-input" />
      </td>
  
      <td>
          <PikadayDate
              @value={{@association.start_date}}
              @class="mini-input" />
      </td>
  
      <td>
          <PikadayDate
              @value={{@association.end_date}}
              @class="mini-input" />
      </td>
  
      <td>
          <Tables::RemoveButton @clicked={{@remove}} />
      </td>
  </tr>
  
  */
  {
    "id": "iV8UzNjA",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[15,0,[52,[30,1,[\"fieldErrors\",\"person\"]],\"has-error\"]],[12],[1,\"\\n\"],[6,[39,1],[[30,1,[\"person\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],null,[[\"@value\",\"@update\",\"@params\",\"@noDefault\"],[[30,2],[30,3],[28,[37,3],null,[[\"mini\"],[true]]],true]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n\"],[1,\"        \"],[8,[39,4],[[24,0,\"mini-input\"]],[[\"@value\",\"@update\"],[[30,1,[\"agent_role\"]],[30,0,[\"updateRole\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@value\",\"@class\"],[[30,1,[\"start_date\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@value\",\"@class\"],[[30,1,[\"end_date\"]],\"mini-input\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@clicked\"],[[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@association\",\"value\",\"update\",\"@remove\"],false,[\"if\",\"async/bind-relation\",\"agent/person/auto-complete\",\"hash\",\"one-way-input\",\"pikaday-date\",\"tables/remove-button\"]]",
    "moduleName": "plutof/components/agent/organization/associations/-edit-row.hbs",
    "isStrictMode": false
  });
  let OrganizationAssociationsEditRow = (_class = class OrganizationAssociationsEditRow extends _component2.default {
    updateRole(value) {
      this.args.association.set('agent_role', value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateRole", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateRole"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OrganizationAssociationsEditRow);
});