define("plutof/templates/components/validation/split-context", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "B/T0UvzU",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"validationPairs\"]]],null]],null],[30,0,[\"key\"]],[[[1,\"    \"],[18,3,[[30,1,[\"object\"]],[30,1,[\"context\"]],[30,2]]],[1,\"\\n\"]],[1,2]],null]],[\"vpair\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "plutof/templates/components/validation/split-context.hbs",
    "isStrictMode": false
  });
});