define("plutof/components/load-more", ["exports", "@ember/array", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "@ember-decorators/object", "ember-inflector", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/objects"], function (_exports, _array, _component, _object, _computed, _service, _utils, _object2, _emberInflector, _environment, _abstract, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function getCount(ajax, model, args) {
    function queryPath(path) {
      var extension = '';
      Object.keys(args).forEach(function (key) {
        extension += key + '=' + args[key] + '&';
      });
      path += '?' + extension;
      return path;
    }
    return ajax.request(`${_environment.default.API_HOST}/${queryPath((0, _emberInflector.pluralize)(model) + '/count/')}`);
  }
  function areQueriesSame(q1, q2) {
    if ((0, _utils.isNone)(q1) || (0, _utils.isNone)(q2)) {
      return false;
    }
    return q1.get('model') === q2.get('model') && (0, _objects.objects_are_equal)(q1.get('args'), q2.get('args'));
  }
  const PAGE_SIZE = 100;
  const DEFAULT_INITIAL_PAGE_SIZE = 10;
  let LoadMore = (_dec = (0, _computed.alias)('queryObject.args.page'), _dec2 = (0, _computed.alias)('queryObject.count'), _dec3 = (0, _computed.oneWay)('queryObject.count'), _dec4 = (0, _computed.oneWay)('query'), _dec5 = (0, _object2.observes)('query'), _dec6 = (0, _object.computed)('loadedPagesCount', 'isLoading'), _dec7 = (0, _object.computed)('showInterface', 'count', 'loadedRecordsCount'), _dec8 = (0, _object.computed)('count', 'pageSize'), _dec9 = (0, _object.computed)('queryObject.args.page_size'), _dec10 = (0, _object.computed)('count', 'pageNumber', 'pageCount'), _dec11 = (0, _object.computed)('pageNumber'), _class = class LoadMore extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "records", (0, _array.A)());
      _initializerDefineProperty(this, "pageNumber", _descriptor2, this);
      _initializerDefineProperty(this, "count", _descriptor3, this);
      _defineProperty(this, "isLoading", false);
      _defineProperty(this, "showInterface", true);
      _defineProperty(this, "loadPageComponent", false);
      // Load pages and stack them.
      _defineProperty(this, "loadedRecordsCount", 0);
      _initializerDefineProperty(this, "recordCount", _descriptor4, this);
      _initializerDefineProperty(this, "queryObject", _descriptor5, this);
      _defineProperty(this, "initialPageSize", DEFAULT_INITIAL_PAGE_SIZE);
    }
    // Input
    query() {
      return _object.default.create({
        model: '',
        store: null,
        count: 0,
        args: {
          page: 0,
          page_size: this.initialPageSize
        }
      });
    }
    init() {
      super.init(...arguments);
      this.queryChanged();
    }
    queryChanged() {
      var queryObject = this.query;
      var queryObjectPromise = (0, _abstract.wrap_as_promise)(queryObject);
      var records = this.records;
      if ((0, _utils.isEmpty)(queryObject)) {
        return _abstract.EMPTY_PROMISE;
      }
      this.set('loadedRecordsCount', this.count);
      return queryObjectPromise.then(resolvedQueryObject => {
        if (areQueriesSame(this.previousQuery, resolvedQueryObject)) {
          return _abstract.EMPTY_PROMISE;
        }
        this.set('previousQuery', resolvedQueryObject);
        if (!(0, _utils.isNone)(records)) {
          records.clear();
        }
        resolvedQueryObject.set('args.page', 0);
        // In case we have been destroyed.
        if (this.isDestroyed) {
          return _abstract.EMPTY_PROMISE;
        }
        const model = resolvedQueryObject.get('model');
        if ((0, _utils.isEmpty)(model)) {
          return _abstract.EMPTY_PROMISE;
        }
        if ((0, _utils.isNone)(resolvedQueryObject.get('args.page_size'))) {
          resolvedQueryObject.set('args.page_size', this.initialPageSize);
        }
        this.set('isLoading', true);
        return getCount(this.ajax, model, resolvedQueryObject.get('args')).then(countObject => {
          // In case we have been destroyed.
          if (this.isDestroyed) {
            return _abstract.EMPTY_PROMISE;
          }
          this.set('isLoading', false);
          // XXX
          resolvedQueryObject.set('count', countObject.objects_count);
          this.set('queryObject', resolvedQueryObject);
          return this.addPage(resolvedQueryObject.get('args.page_size'), !this.loadPageComponent);
        });
      });
    }
    get isLoadingFirstPage() {
      var loadedRecords = this.loadedRecordsCount || 0;
      var isLoading = this.isLoading;
      return isLoading && loadedRecords === 0;
    }
    get showControls() {
      var loadedRecordsCount = this.loadedRecordsCount || 0;
      var totalRecordsCount = this.count;
      var showInterface = this.showInterface;
      if ((0, _utils.isNone)(totalRecordsCount)) {
        return false;
      } else {
        return loadedRecordsCount !== totalRecordsCount && showInterface;
      }
    }
    get pageCount() {
      var recordCount = this.count || 0;
      var count = Math.ceil(recordCount / this.pageSize);
      return count;
    }
    get pageSize() {
      var size = this.get('queryObject.args.page_size') || PAGE_SIZE;
      return size;
    }
    get isLastPage() {
      var page = this.pageNumber;
      var count = this.count;
      var pages = this.pageCount;
      return page >= pages || count === 0;
    }
    get isFirstPage() {
      var pageNumber = this.pageNumber;
      return (0, _utils.isNone)(pageNumber) || pageNumber < 2;
    }
    getPage(queryObject) {
      return queryObject.store.query(queryObject.model, queryObject.args);
    }
    addPage(pageSize, isInitialPage) {
      if (this.isLoading) {
        return _abstract.EMPTY_PROMISE;
      }

      // TODO: beautification.
      var queryObject = this.queryObject;
      var pageNumber = this.get('queryObject.args.page') || 0;
      var count = this.count;
      // var loadedRecords = this.get('records.length') || 0;
      var isLoadMore = !this.loadPageComponent; // Load more component handles pages differnetly.

      // XXX
      if ((0, _utils.isNone)(queryObject) || count === 0) {
        if (!(0, _utils.isNone)(records)) {
          records.clear();
        }
        return _abstract.EMPTY_PROMISE;
      }
      queryObject.set('args.page', pageNumber + 1); // Update page number.
      queryObject.set('args.page_size', pageSize); // Update page size.

      var records = this.records || (0, _array.A)();
      var newPage = this.getPage(queryObject);
      this.set('isLoading', true);
      return newPage.then(function (response) {
        // In case we have been destroyed.
        if (this.isDestroyed) {
          queryObject.set('args.page', 0); // Why this?
          return;
        }
        this.set('isLoading', false);
        if (isInitialPage && isLoadMore) {
          this.set('queryObject.args.page', 0); // Reset page number.
          this.set('loadedRecordsCount', 0); // Reset loaded records count.
          this.set('queryObject.args.page_size', PAGE_SIZE); // Default long the page size.
        }
        var newRecords;

        // Second page is a bit special.
        if (!isInitialPage && queryObject.get('args.page') === 1 && isLoadMore) {
          newRecords = response.toArray().slice(this.initialPageSize);
        } else {
          newRecords = response.toArray();
        }
        this.incrementProperty('loadedRecordsCount', newRecords.length);
        this.set('records', records.concat(newRecords));
        if (this.pageLoaded) {
          this.pageLoaded(newRecords);
        }
      }.bind(this));
    }
    more() {
      if (!this.isLastPage) {
        this.addPage(this.pageSize, false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pageNumber", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "queryObject", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryChanged", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "queryChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLoadingFirstPage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isLoadingFirstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showControls", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showControls"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageCount", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "pageCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageSize", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "pageSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLastPage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isLastPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFirstPage", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isFirstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "more", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "more"), _class.prototype), _class);
  var _default = _exports.default = LoadMore;
});