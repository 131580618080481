define("plutof/templates/components/observation/moderation-reject-popup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yTeveWS8",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Observation::ModerationRejectPopup\"]],[[\"@open\",\"@close\"],[[30,1],[30,0,[\"cancelReject\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"moderation.reject\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,2],null,[[\"@label\"],[\"moderation.comment\"]],null],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\"],[[30,0,[\"quickReplyOptions\"]],[30,0,[\"reasonOfDenial\"]]]],null],[1,\"\\n\\n        \"],[8,[39,4],[[24,0,\"form-control\"],[24,\"data-test\",\"reason\"]],[[\"@rows\",\"@value\",\"@placeholder\"],[3,[30,0,[\"reasonOfDenial\"]],[28,[37,1],[\"moderation.rejectPlaceholder\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[24,\"data-test\",\"cancel\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"cancelReject\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"fas fa-arrow-left\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,1],[\"General.Cancel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,6],[[24,0,\"btn btn-default\"],[24,\"restIcon\",\"icon-remove--red\"],[24,\"data-test\",\"reject\"]],[[\"@clicked\",\"@disabled\"],[[30,0,[\"reject\"]],[28,[37,7],[[30,0,[\"reasonOfDenial\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Reject\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@open\"],false,[\"ui/modal/dialog\",\"i18n-t\",\"common/local-label\",\"plutof-select/direct\",\"flexible-textarea\",\"on\",\"promise-button\",\"not\"]]",
    "moduleName": "plutof/templates/components/observation/moderation-reject-popup.hbs",
    "isStrictMode": false
  });
});