define("plutof/templates/components/tables/-bulk-update/update-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tyHmx4u0",
    "block": "[[[10,0],[15,0,[29,[\"table-bulk-update__update-panel \",[52,[30,1],\"table-bulk-update__update-panel--open\"]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@open\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "plutof/templates/components/tables/-bulk-update/update-panel.hbs",
    "isStrictMode": false
  });
});