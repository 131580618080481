define("plutof/components/tables/column-control/-remove", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @toggle}}
      disabled={{@disabled}}
      class="column-control-btn"
      ...attributes
  >
      <span class="icon-remove icon"></span>
  </button>
  
  */
  {
    "id": "zBz8asE+",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[24,0,\"column-control-btn\"],[17,2],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"icon-remove icon\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disabled\",\"&attrs\",\"@toggle\"],false,[\"on\"]]",
    "moduleName": "plutof/components/tables/column-control/-remove.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});