define("plutof/components/experiment/pcr/experiment-metadata", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-data", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/store"], function (_exports, _component, _object, _utils, _emberData, _emberCpValidations, _componentValidations, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const DECIMAL_1 = /^[0-9]+(\.[0-9]{1})?$/;
  const DECIMAL_3 = /^[0-9]+(\.[0-9]{1,3})?$/;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'experiment.executed_by': (0, _emberCpValidations.validator)('presence', true),
    'experiment.laboratory': (0, _emberCpValidations.validator)('presence', true),
    'experiment.title': (0, _emberCpValidations.validator)('presence', true),
    'startDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'endDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'experiment.dna_volume': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: DECIMAL_3
    }),
    'experiment.nr_of_cycles': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'experiment.reaction_volume': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'experiment.denaturation_time': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'experiment.annealing_time': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'experiment.extension_time': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'experiment.denaturation_temp': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: DECIMAL_1
    }),
    'experiment.annealing_temp': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: DECIMAL_1
    }),
    'experiment.extension_temp': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: DECIMAL_1
    })
  });
  const POLYMERASE_SOURCED_ATTRIBUTES = ['nr_of_cycles', 'reaction_volume', 'denaturation_temp', 'denaturation_time', 'annealing_temp', 'annealing_time', 'extension_temp', 'extension_time'];
  let ExperimentMetadataComponent = (_dec = (0, _object.computed)(), _class = class ExperimentMetadataComponent extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "startDateValidity", false);
      _defineProperty(this, "endDateValidity", false);
    }
    get polymeraseMixes() {
      return _emberData.default.PromiseArray.create({
        promise: (0, _store.findAll)(this.store, 'dna-lab/polymerase-mix')
      });
    }
    async switchPolymeraseMix(mix) {
      // Update fields that user hasn't filled manually
      const previousMix = await this.experiment.polymerase_mix;
      let update = {
        polymerase_mix: mix
      };
      const mixValues = (0, _utils.isNone)(mix) ? {} : mix;
      POLYMERASE_SOURCED_ATTRIBUTES.forEach(attr => {
        const value = this.experiment.get(attr);
        if ((0, _utils.isEmpty)(value) || !(0, _utils.isNone)(previousMix) && value === previousMix.get(attr)) {
          update[attr] = (0, _object.get)(mixValues, attr);
        }
      });
      this.experiment.setProperties(update);
    }
    updateLaboratory(laboratory) {
      this.experiment.set('laboratory', laboratory);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "polymeraseMixes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "polymeraseMixes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchPolymeraseMix", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchPolymeraseMix"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLaboratory", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateLaboratory"), _class.prototype), _class);
  var _default = _exports.default = ExperimentMetadataComponent;
});