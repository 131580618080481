define("plutof/templates/components/tables/-bulk-update/select-all", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "of9m50ZR",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[30,0,[\"checked\"]],[28,[37,1],[[30,1,[\"length\"]]],null]]]]],[1,\"\\n\"]],[\"@records\"],false,[\"input\",\"not\"]]",
    "moduleName": "plutof/templates/components/tables/-bulk-update/select-all.hbs",
    "isStrictMode": false
  });
});