define("plutof/templates/components/analysis/type-shmatch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lowmHmg0",
    "block": "[[[10,0],[14,0,\"type-shmatch\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@path\"],[\"wrapper-run.sequence_region\"]],null],[1,\"\\n\\n        \"],[8,[39,1],null,[[\"@content\",\"@value\",\"@prompt\",\"@class\",\"@selectClass\"],[[30,0,[\"sequenceRegion\"]],[30,1,[\"sequence_region\"]],null,\"type-shmatch__sequence_region\",\"form-control default-focus-control\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@value\"],[[30,1,[\"itsx_step\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,0],null,[[\"@path\"],[\"wrapper-run.itsx_step\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@value\"],[[30,1,[\"substring_dereplication\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,0],null,[[\"@path\"],[\"wrapper-run.substring_dereplication\"]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@run\"],false,[\"plutof-labelc\",\"plutof-select/value\",\"common/check-box\"]]",
    "moduleName": "plutof/templates/components/analysis/type-shmatch.hbs",
    "isStrictMode": false
  });
});