define("plutof/templates/filerepository/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "bP+OQaFW",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"filerepository.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"file\"],null],[28,[37,3],[\"FileRepository.title\"],null],\"filerepository.upload\",[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"FileRepository.infoTitle\"],null],\"information/content/filerepository/index\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@files\",\"@delete\"],[[30,0,[\"model\",\"pagination\",\"objects\"]],[30,0,[\"delete\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"filerepository/file-list\"]]",
    "moduleName": "plutof/templates/filerepository/index.hbs",
    "isStrictMode": false
  });
});