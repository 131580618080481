define("plutof/templates/components/sample/view-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lEBDQvA6",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[99,1,[\"@route\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@object\"],false,[\"link-to\",\"route\"]]",
    "moduleName": "plutof/templates/components/sample/view-link.hbs",
    "isStrictMode": false
  });
});