define("plutof/templates/components/datacite/contributor-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oocN9grP",
    "block": "[[[10,\"td\"],[15,0,[52,[33,1,[\"attrs\",\"contributor\",\"contributor_name\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n\"],[6,[39,2],[[30,1,[\"contributor_name\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,3],[[24,\"data-test\",\"agent\"]],[[\"@value\",\"@update\",\"@params\"],[[30,2],[30,3],[28,[37,4],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[2,3]]]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[52,[33,1,[\"attrs\",\"contributor\",\"contributor_type\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[8,[39,5],[[24,\"data-test\",\"type\"]],[[\"@content\",\"@value\",\"@selectClass\"],[[30,0,[\"contributorTypes\"]],[30,1,[\"contributor_type\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,6],[[24,0,\"mini-input\"]],[[\"@value\"],[[33,7,[\"affiliation\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,8],[\"click\",[30,4]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@contributor\",\"value\",\"update\",\"@remove\"],false,[\"if\",\"validations\",\"async/bind-relation\",\"agent/auto-complete\",\"hash\",\"plutof-select/value\",\"flexible-textarea\",\"contributor\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/contributor-row-form.hbs",
    "isStrictMode": false
  });
});