define("plutof/templates/components/interaction/view-single", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xL2LK3Om",
    "block": "[[[8,[39,0],null,[[\"@record\",\"@testMarker\"],[[30,1],\"interaction-view\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"object\"],[[\"label\",\"annotated\"],[[28,[37,2],[\"Interaction.interactionObject\"],null],[28,[37,3],[[30,1],\"object_id\",\"interacting_object_id\"],null]]],[[\"default\"],[[[[1,\"            \"],[8,[39,4],null,[[\"@contentType\",\"@id\",\"@name\"],[[30,3,[\"full_name\"]],[30,4,[\"id\"]],[30,4,[\"representation\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[41,[51,[30,5]],[[[1,\"            \"],[1,[28,[30,2,[\"field\"]],[\"interacting_taxon_type\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[1,[28,[30,2,[\"field\"]],[\"interaction_type\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"remarks\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@interaction\",\"view\",\"@contentType\",\"@object\",\"@hideTaxonType\"],false,[\"generic-views/record\",\"layout/regular-grid\",\"i18n-t\",\"annotation/fields-modified\",\"generic-views/pojo-record-link\",\"unless\"]]",
    "moduleName": "plutof/templates/components/interaction/view-single.hbs",
    "isStrictMode": false
  });
});