define("plutof/templates/components/common/cancel-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "OHN5lD6J",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@restIcon\"],[[30,1],[28,[37,1],[\"cancel\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"General.Cancel\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@cancel\"],false,[\"promise-button\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/common/cancel-button.hbs",
    "isStrictMode": false
  });
});