define("plutof/components/common/data-quality/utils", ["exports", "@ember/utils", "@ember/object", "moment", "plutof/misc/config"], function (_exports, _utils, _object, moment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countValidity = countValidity;
  _exports.countryValidity = countryValidity;
  _exports.gatheringAgentsValidity = gatheringAgentsValidity;
  _exports.geomValidity = geomValidity;
  _exports.taxonRankValidity = taxonRankValidity;
  _exports.timespanAfterCreatedValidity = timespanAfterCreatedValidity;
  _exports.timespanAfterNowValidity = timespanAfterNowValidity;
  const UNSPECIFIED_COUNTRY_NAMES = ['Unspecified', 'Täpsustamata'];
  function countValidity(count) {
    return (0, _object.computed)(count, function () {
      return (0, _utils.isEmpty)((0, _object.get)(this, count)) || (0, _object.get)(this, count) < 200;
    });
  }
  function countryValidity(country) {
    return (0, _object.computed)(country, function () {
      return !UNSPECIFIED_COUNTRY_NAMES.includes((0, _object.get)(this, country));
    });
  }
  function geomValidity(geom, restricted) {
    return (0, _object.computed)(geom, restricted, function () {
      return (0, _utils.isNone)((0, _object.get)(this, restricted)) || (0, _object.get)(this, restricted) || (0, _utils.isPresent)((0, _object.get)(this, geom));
    });
  }
  function gatheringAgentsValidity(agentsLength) {
    return (0, _object.computed)(agentsLength, function () {
      return (0, _utils.isNone)((0, _object.get)(this, agentsLength)) || (0, _object.get)(this, agentsLength) > 0;
    });
  }
  function taxonRankValidity(taxonRankId) {
    return (0, _object.computed)(taxonRankId, function () {
      return (0, _utils.isEmpty)((0, _object.get)(this, taxonRankId)) || (0, _object.get)(this, taxonRankId) >= _config.default.Taxonomy.ranks.species;
    });
  }
  function timespanAfterCreatedValidity(timespanBegin, createdAt) {
    return (0, _object.computed)(timespanBegin, createdAt, function () {
      const timespanBeginValue = (0, _object.get)(this, timespanBegin);
      const createdAtValue = (0, _object.get)(this, createdAt);
      if ((0, _utils.isEmpty)(timespanBeginValue)) {
        return true;
      }
      return moment(timespanBeginValue).isBefore(moment(createdAtValue));
    });
  }
  function timespanAfterNowValidity(timespanBegin) {
    return (0, _object.computed)(timespanBegin, function () {
      const date = moment((0, _object.get)(this, timespanBegin));
      const now = moment();
      return !date.isAfter(now);
    });
  }
});