define("plutof/templates/components/unite/centroid-sequence", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZrUxpUbm",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"UniteSH.centroidSequence\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"UniteSH.accessionNumbers\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"sequence.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,1,[\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@sequence\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/templates/components/unite/centroid-sequence.hbs",
    "isStrictMode": false
  });
});