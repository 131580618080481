define("plutof/models/study/study", ["exports", "@ember/object/computed", "@ember-data/model", "plutof/models/plutof-model", "plutof/utils/display-name-from-value", "plutof/utils/model"], function (_exports, _computed, _model, _plutofModel, _displayNameFromValue, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const displayName = (0, _displayNameFromValue.displayNames)('study/study');
  let Study = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('enum'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.belongsTo)('study/study', {
    inverse: null
  }), _dec11 = (0, _model.belongsTo)('geography/language'), _dec12 = (0, _model.belongsTo)('users/workgroup'), _dec13 = (0, _model2.privateGuard)('study_parent'), _dec14 = displayName('study_type'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec17 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.hasMany)('measurement/mainform', {
    inverse: null
  }), _dec25 = (0, _computed.reads)('name'), _class = class Study extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "study_type", _descriptor2, this);
      _initializerDefineProperty(this, "remarks", _descriptor3, this);
      _initializerDefineProperty(this, "size", _descriptor4, this);
      _initializerDefineProperty(this, "start_date", _descriptor5, this);
      _initializerDefineProperty(this, "end_date", _descriptor6, this);
      _initializerDefineProperty(this, "protocol", _descriptor7, this);
      _initializerDefineProperty(this, "http_url", _descriptor8, this);
      _initializerDefineProperty(this, "project_image_url", _descriptor9, this);
      _initializerDefineProperty(this, "study_parent", _descriptor10, this);
      _initializerDefineProperty(this, "language", _descriptor11, this);
      _initializerDefineProperty(this, "managing_group", _descriptor12, this);
      _initializerDefineProperty(this, "guardedParent", _descriptor13, this);
      _initializerDefineProperty(this, "typeDisplayName", _descriptor14, this);
      _initializerDefineProperty(this, "gallery", _descriptor15, this);
      _initializerDefineProperty(this, "public_linking", _descriptor16, this);
      _initializerDefineProperty(this, "is_public", _descriptor17, this);
      _initializerDefineProperty(this, "specimen_identifier_format_prefix", _descriptor18, this);
      _initializerDefineProperty(this, "specimen_identifier_format_width", _descriptor19, this);
      _initializerDefineProperty(this, "material_sample_identifier_format_prefix", _descriptor20, this);
      _initializerDefineProperty(this, "material_sample_identifier_format_width", _descriptor21, this);
      _initializerDefineProperty(this, "living_specimen_identifier_format_prefix", _descriptor22, this);
      _initializerDefineProperty(this, "living_specimen_identifier_format_width", _descriptor23, this);
      _initializerDefineProperty(this, "mainforms", _descriptor24, this);
      _initializerDefineProperty(this, "representation", _descriptor25, this);
      _defineProperty(this, "clipboardProperty", 'project');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "study_type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "start_date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "end_date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "protocol", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "http_url", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "project_image_url", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "study_parent", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "managing_group", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "guardedParent", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "typeDisplayName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "gallery", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "public_linking", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "is_public", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "specimen_identifier_format_prefix", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "specimen_identifier_format_width", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "material_sample_identifier_format_prefix", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "material_sample_identifier_format_width", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "living_specimen_identifier_format_prefix", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "living_specimen_identifier_format_width", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "mainforms", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Study;
});