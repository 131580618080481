define("plutof/components/transaction/-list-route-end-date", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "moment"], function (_exports, _component, _templateFactory, _component2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{if this.warn "list-highlight"}}>
      {{formatted-date @transaction.expected_end_date}}
  
      {{#if this.warn}}
          {{#if this.isLate}}
              ({{i18n-t "PlutofListViewColumn.overdue"}})
          {{else if this.isDue}}
              ({{i18n-t "PlutofListViewColumn.due"}})
          {{/if}}
      {{/if}}
  </div>
  
  */
  {
    "id": "TSpB2mD/",
    "block": "[[[10,0],[15,0,[52,[30,0,[\"warn\"]],\"list-highlight\"]],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"expected_end_date\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"warn\"]],[[[41,[30,0,[\"isLate\"]],[[[1,\"            (\"],[1,[28,[35,2],[\"PlutofListViewColumn.overdue\"],null]],[1,\")\\n\"]],[]],[[[41,[30,0,[\"isDue\"]],[[[1,\"            (\"],[1,[28,[35,2],[\"PlutofListViewColumn.due\"],null]],[1,\")\\n        \"]],[]],null]],[]]]],[]],null],[13],[1,\"\\n\"]],[\"@transaction\"],false,[\"if\",\"formatted-date\",\"i18n-t\"]]",
    "moduleName": "plutof/components/transaction/-list-route-end-date.hbs",
    "isStrictMode": false
  });
  class TransactionListRouteEndDate extends _component2.default {
    get date() {
      return (0, _moment.default)(this.args.transaction.expected_end_date);
    }
    get isLate() {
      return this.date.isValid() && (0, _moment.default)().isAfter(this.date);
    }
    get isDue() {
      if (!this.date.isValid()) {
        return false;
      }
      const offset = this.date.subtract(14, 'day');
      return (0, _moment.default)().isAfter(offset);
    }
    get warn() {
      return !this.args.transaction.is_locked && (this.isLate || this.isDue);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionListRouteEndDate);
});