define("plutof/templates/components/compound/cluster-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/DW4wzSz",
    "block": "[[[6,[39,0],[[30,0,[\"listPagination\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@records\"],[[30,1,[\"objects\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"clusters.title\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"compound.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,3,[\"code\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"clusters.nrOfSeq\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"no_sequences\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"General.taxon\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"mostSpecificTaxonName\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[2,3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[10,2],[14,0,\"align-center\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@pagination\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]]]]],[\"pagination\",\"Field\",\"cluster\"],false,[\"resolve-promise\",\"plutof-panel/headerless\",\"tables/list\",\"i18n-t\",\"link-to\",\"pagination/page-controls\"]]",
    "moduleName": "plutof/templates/components/compound/cluster-list.hbs",
    "isStrictMode": false
  });
});