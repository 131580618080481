define("plutof/components/filerepository/gallery/-preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/models/filerepository/file"], function (_exports, _component, _templateFactory, _component2, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (is-equal @type this.fileType.Image)}}
      <Gallery::Image
          src={{@links.large_link}}
          alt={{@filename}}
          class="img-responsive gallery-media-layer__img" />
  {{else if (is-equal @type this.fileType.Video)}}
      <div class="gallery-media-layer__video-container">
          <video controls src={{@links.link}} class="gallery-media-layer__video" />
      </div>
  {{else if (is-equal @type this.fileType.Sound)}}
      <audio controls="controls" src={{@links.link}} class="gallery-media-layer__audio" />
  {{else}}
      <div class="gallery-media-layer__document">
          <span class="fas fa-file gallery-media-layer__icon"></span>
      </div>
  {{/if}}
  
  */
  {
    "id": "zeXKZDXG",
    "block": "[[[41,[28,[37,1],[[30,1],[30,0,[\"fileType\",\"Image\"]]],null],[[[1,\"    \"],[8,[39,2],[[16,\"src\",[30,2,[\"large_link\"]]],[16,\"alt\",[30,3]],[24,0,\"img-responsive gallery-media-layer__img\"]],null,null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],[30,0,[\"fileType\",\"Video\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"gallery-media-layer__video-container\"],[12],[1,\"\\n        \"],[10,\"video\"],[14,\"controls\",\"\"],[15,\"src\",[30,2,[\"link\"]]],[14,0,\"gallery-media-layer__video\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],[30,0,[\"fileType\",\"Sound\"]]],null],[[[1,\"    \"],[10,\"audio\"],[14,\"controls\",\"controls\"],[15,\"src\",[30,2,[\"link\"]]],[14,0,\"gallery-media-layer__audio\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"gallery-media-layer__document\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"fas fa-file gallery-media-layer__icon\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"@type\",\"@links\",\"@filename\"],false,[\"if\",\"is-equal\",\"gallery/image\"]]",
    "moduleName": "plutof/components/filerepository/gallery/-preview.hbs",
    "isStrictMode": false
  });
  class GalleryFilePreview extends _component2.default {
    constructor(...args) {
      super(...args);
      // XXX TODO: Use string types instead of opaque numbers
      _defineProperty(this, "fileType", _file.FileType);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GalleryFilePreview);
});