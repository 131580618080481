define("plutof/components/agent/view/organization-hierarchy", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p data-test="Agent::View::OrganizationHierarchy">
      {{#each this.organizations as |organization|}}
          <span class={{icon "organization"}}></span>
  
          <LinkTo @route="organization.view" @model={{organization.id}}>
              <span data-test="parent-organization">
                  {{organization.name}}
              </span>
          </LinkTo>
  
          &#47;
      {{/each}}
  
      <span class={{icon @icon}}></span> {{@organization.name}}
  </p>
  
  */
  {
    "id": "ai43tvs3",
    "block": "[[[10,2],[14,\"data-test\",\"Agent::View::OrganizationHierarchy\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"organizations\"]]],null]],null],null,[[[1,\"        \"],[10,1],[15,0,[28,[37,2],[\"organization\"],null]],[12],[13],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"organization.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[14,\"data-test\",\"parent-organization\"],[12],[1,\"\\n                \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        /\\n\"]],[1]],null],[1,\"\\n    \"],[10,1],[15,0,[28,[37,2],[[30,2]],null]],[12],[13],[1,\" \"],[1,[30,3,[\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"organization\",\"@icon\",\"@organization\"],false,[\"each\",\"-track-array\",\"icon\",\"link-to\"]]",
    "moduleName": "plutof/components/agent/view/organization-hierarchy.hbs",
    "isStrictMode": false
  });
  let OrganizationHierarchy = _exports.default = (_dec = (0, _object.computed)('args.organization.organization_hierarchy.[]'), _class = class OrganizationHierarchy extends _component2.default {
    get organizations() {
      const hierarchy = this.args.organization.organization_hierarchy ?? [];
      return [...hierarchy].reverse();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "organizations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "organizations"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OrganizationHierarchy);
});