define("plutof/components/login/form-input-status", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="login-input-status">
      {{#if (and @isValid (not @isConfirmed))}}
          <div class="login-input-status__warning hidden-xs">
              {{@warning}}
          </div>
      {{else}}
          <span class="login-input-status__check
              {{if (and @isValid @isConfirmed) 'login-input-status__check--checked'}}"></span>
      {{/if}}
  </div>
  
  */
  {
    "id": "n2ZwybDz",
    "block": "[[[10,0],[14,0,\"login-input-status\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],[28,[37,2],[[30,2]],null]],null],[[[1,\"        \"],[10,0],[14,0,\"login-input-status__warning hidden-xs\"],[12],[1,\"\\n            \"],[1,[30,3]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[15,0,[29,[\"login-input-status__check\\n            \",[52,[28,[37,1],[[30,1],[30,2]],null],\"login-input-status__check--checked\"]]]],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@isValid\",\"@isConfirmed\",\"@warning\"],false,[\"if\",\"and\",\"not\"]]",
    "moduleName": "plutof/components/login/form-input-status.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});