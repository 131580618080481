define("plutof/components/annotation/list/icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/annotation/format"], function (_exports, _component, _templateFactory, _component2, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isElixirAnnotation}}
      <ToolTip @tooltip="European Nucleotide Archive">
          <span class={{icon "ena"}}></span>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "dfTZ468a",
    "block": "[[[41,[30,0,[\"isElixirAnnotation\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@tooltip\"],[\"European Nucleotide Archive\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[28,[37,2],[\"ena\"],null]],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"tool-tip\",\"icon\"]]",
    "moduleName": "plutof/components/annotation/list/icon.hbs",
    "isStrictMode": false
  });
  class AnnotationIcon extends _component2.default {
    get isElixirAnnotation() {
      return this.args.annotation.annotation.format === _format.AnnotationFormats.Elixir || this.args.annotation.annotation.intended_for_publication;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationIcon);
});