define("plutof/components/forms/view/general-data", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "ember-data", "plutof/components/forms/utils"], function (_exports, _component, _templateFactory, _object, _service, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Forms::View::GeneralData"
  >
      <GenericViews::Record @record={{@mainform}} as |view|>
          <Layout::RegularGrid @columns={{4}} as |grid|>
              {{view.attr "name"}}
              {{view.attr "name_est"}}
              {{view.attr "description"}}
              {{view.field "type"}}
              {{view.attr "substrate"}}
              {{view.attr "interaction"}}
              {{view.attr "requires_moderation"}}
              {{view.attr "common_names"}}
  
              {{#grid.span 4}}
                  {{#view.attr "taxon_list" label=(i18n-t "Forms.taxonList") tooltip=(i18n-t "Forms.taxonListTooltip")}}
                      <Common::RecordListView @records={{@taxa}} as |taxon|>
                          <LinkTo @route="taxonomy.view" @model={{taxon.id}}>
                              {{taxon.full_taxon_name}}
                          </LinkTo>
                      </Common::RecordListView>
                  {{/view.attr}}
              {{/grid.span}}
  
              {{view.field "default_taxon"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "FvnMX38y",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Forms::View::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"name\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"name_est\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"description\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"type\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"substrate\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"interaction\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"requires_moderation\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"common_names\"],null]],[1,\"\\n\\n\"],[6,[30,3,[\"span\"]],[4],null,[[\"default\"],[[[[6,[30,2,[\"attr\"]],[\"taxon_list\"],[[\"label\",\"tooltip\"],[[28,[37,1],[\"Forms.taxonList\"],null],[28,[37,1],[\"Forms.taxonListTooltip\"],null]]],[[\"default\"],[[[[1,\"                    \"],[8,[39,4],null,[[\"@records\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n                        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"taxonomy.view\",[30,5,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                            \"],[1,[30,5,[\"full_taxon_name\"]]],[1,\"\\n                        \"]],[]]]]],[1,\"\\n                    \"]],[5]]]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"default_taxon\"],null]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@mainform\",\"view\",\"grid\",\"@taxa\",\"taxon\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"common/record-list-view\",\"link-to\"]]",
    "moduleName": "plutof/components/forms/view/general-data.hbs",
    "isStrictMode": false
  });
  let FormGeneralDataView = (_dec = (0, _object.computed)('mainform', 'mainform.requires_moderation'), _class = class FormGeneralDataView extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get moderators() {
      return _emberData.default.PromiseArray.create({
        promise: (0, _utils.getModerators)(this.ajax, this.store, this.mainform)
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "moderators", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "moderators"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormGeneralDataView);
});