define("plutof/components/transaction/view/agent", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="view-transaction-agent" data-test="Transaction::View::Agent">
      <td data-test="agent">
          <span class="view-transaction-agent__agent">
              <LinkTo @route={{this.viewRoute}} @model={{relation-id @agent "agent"}}>
                  {{@agent.agent_name}}
              </LinkTo>
          </span>
      </td>
  
      <td data-test="role">
          {{@agent.roleDisplayName}}
      </td>
  
      <td data-test="name">
          {{@agent.name}}
      </td>
  </tr>
  
  */
  {
    "id": "5WdlvQ0q",
    "block": "[[[10,\"tr\"],[14,0,\"view-transaction-agent\"],[14,\"data-test\",\"Transaction::View::Agent\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,\"data-test\",\"agent\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"view-transaction-agent__agent\"],[12],[1,\"\\n            \"],[8,[39,0],null,[[\"@route\",\"@model\"],[[30,0,[\"viewRoute\"]],[28,[37,1],[[30,1],\"agent\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,1,[\"agent_name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,\"data-test\",\"role\"],[12],[1,\"\\n        \"],[1,[30,1,[\"roleDisplayName\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,\"data-test\",\"name\"],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@agent\"],false,[\"link-to\",\"relation-id\"]]",
    "moduleName": "plutof/components/transaction/view/agent.hbs",
    "isStrictMode": false
  });
  class ViewTransactionAgent extends _component2.default {
    get viewRoute() {
      const type = (0, _object.get)(this.args, 'agent.agent_type');
      if ((0, _utils.isNone)(type)) {
        return null;
      }
      switch (type) {
        case 'person':
          return 'person.view';
        case 'organization':
          return 'organization.view';
        case 'collection':
          return 'collection.view';
        default:
          return 'collection.view';
      }
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewTransactionAgent);
});