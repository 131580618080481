define("plutof/templates/components/common/record-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "BeAWO0cn",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"record-list__record\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"            \"],[11,\"button\"],[24,0,\"record-list__remove-button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,3],[30,2]],null]],null],[12],[1,\"\\n                \"],[10,1],[14,0,\"icon-remove\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"            \"],[18,4,[[30,2]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,2,[\"representation\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[2]],null]],[\"@records\",\"record\",\"@remove\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"on\",\"fn\",\"has-block\",\"yield\"]]",
    "moduleName": "plutof/templates/components/common/record-list.hbs",
    "isStrictMode": false
  });
});