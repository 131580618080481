define("plutof/components/observation/moderation/controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="moderation-controls {{if @large 'moderation-controls--large'}}">
      <ToolTip @tooltip={{i18n-t "moderation.controlTooltip.details"}}>
          <button
              {{on "click" @toggleModal}}
              class="moderation-controls__btn"
              data-test="comment"
          >
              <span class="far fa-comment"></span>
          </button>
      </ToolTip>
  
      <ToolTip @tooltip={{i18n-t "moderation.controlTooltip.reject"}}>
          <button
              {{on "click" @reject}}
              class="moderation-controls__btn"
              data-test="reject"
          >
              <span class="icon-remove--red"></span>
          </button>
      </ToolTip>
  
      <ToolTip @tooltip={{i18n-t "moderation.controlTooltip.accept"}}>
          <button
              {{on "click" @accept}}
              class="moderation-controls__btn"
              data-test="accept"
          >
              <span class={{icon "ok"}}></span>
          </button>
      </ToolTip>
  
      <ToolTip @tooltip={{i18n-t "moderation.controlTooltip.forward"}}>
          <button
              {{on "click" @forward}}
              class="moderation-controls__btn"
          >
              <span class={{icon "observation-moderation-forward"}}></span>
          </button>
      </ToolTip>
  </div>
  
  */
  {
    "id": "YZo7eMv4",
    "block": "[[[10,0],[15,0,[29,[\"moderation-controls \",[52,[30,1],\"moderation-controls--large\"]]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"moderation.controlTooltip.details\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"moderation-controls__btn\"],[24,\"data-test\",\"comment\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"far fa-comment\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"moderation.controlTooltip.reject\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"moderation-controls__btn\"],[24,\"data-test\",\"reject\"],[4,[38,3],[\"click\",[30,3]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"moderation.controlTooltip.accept\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"moderation-controls__btn\"],[24,\"data-test\",\"accept\"],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,4],[\"ok\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"moderation.controlTooltip.forward\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"moderation-controls__btn\"],[4,[38,3],[\"click\",[30,5]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,4],[\"observation-moderation-forward\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@large\",\"@toggleModal\",\"@reject\",\"@accept\",\"@forward\"],false,[\"if\",\"tool-tip\",\"i18n-t\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/observation/moderation/controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});