define("plutof/components/import/import-progress", ["exports", "@ember/component", "@ember/object", "@ember/template", "@ember-decorators/component"], function (_exports, _component, _object, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function percentage(n) {
    const percents = n * 100;
    return (0, _template.htmlSafe)(`width: ${percents}%`);
  }
  let ImportProgress = (_dec = (0, _component2.classNames)('import-progress'), _dec2 = (0, _object.computed)('total', 'created'), _dec3 = (0, _object.computed)('total', 'ignored'), _dec4 = (0, _object.computed)('total', 'created', 'ignored'), _dec5 = (0, _object.computed)('total', 'unprocessed'), _dec(_class = (_class2 = class ImportProgress extends _component.default {
    get createdPercentage() {
      return percentage(this.created / this.total);
    }
    get ignoredPercentage() {
      return percentage(this.ignored / this.total);
    }
    get unprocessed() {
      return this.total - this.created - this.ignored;
    }
    get unprocessedPercentage() {
      return percentage(this.unprocessed / this.total);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "createdPercentage", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "createdPercentage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "ignoredPercentage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "ignoredPercentage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unprocessed", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "unprocessed"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "unprocessedPercentage", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "unprocessedPercentage"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ImportProgress;
});