define("plutof/templates/photobank/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QikK3qqB",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"photobank.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\"],[[28,[37,2],[\"photobank\"],null],[28,[37,3],[\"Photobank.editObject\"],null],[30,0,[\"model\",\"object\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@object\",\"@additionalIdentifiers\",\"@objectsToDelete\",\"@observationMainform\",\"@eventFormData\",\"@showAreaEditButton\",\"@preparationsData\",\"@classificationItems\",\"@observationTableStrings\",\"@itemData\",\"@agentItems\",\"@areaEventViewStrings\",\"@validationContext\"],[[30,0,[\"model\",\"object\"]],[30,0,[\"model\",\"additionalIdentifiers\"]],[30,0,[\"objectsToDelete\"]],[30,0,[\"model\",\"observationMainform\"]],[30,0,[\"model\",\"eventFormData\"]],[30,0,[\"showAreaEditButton\"]],[30,0,[\"model\",\"preparationsData\"]],[30,0,[\"model\",\"classificationItems\"]],[30,0,[\"observationTableStrings\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"model\",\"agentItems\"]],[30,0,[\"areaEventViewStrings\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[28,[37,9],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"photobank/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/photobank/edit.hbs",
    "isStrictMode": false
  });
});