define("plutof/components/taxonomy/tree-attributes", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _object, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Taxonomy.treeData"}}
      data-test="Taxonomy::TreeAttributes"
  >
      {{#if @rootNode}}
          <div>
              <label>{{i18n-t "Taxonomy.higherTaxonNodeLineage"}}</label>
              {{this.lineage}}
          </div>
      {{/if}}
  
      <div class="{{unless this.nameIsValid 'has-error'}}">
          {{plutof-labelc "tree.name"}}
  
          <Input
              @type="text"
              @value={{@name}}
              class="form-control default-focus-control"
              data-test="name" />
      </div>
  
      <div>
          {{plutof-labelc "tree.root_node"}}
  
          {{#async/bind-relation @rootNode as |value update|}}
              <Taxonomy::Taxon::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  data-test="root_node" />
          {{/async/bind-relation}}
      </div>
  
      <div>
          {{plutof-labelc "tree.origin_tree"}}
  
          {{#async/bind-relation @originTree as |value update|}}
              <AutoComplete::Model
                  @model="taxonomy/tree"
                  @value={{value}}
                  @update={{update}}
                  data-test="origin_tree" />
          {{/async/bind-relation}}
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "z/WPLGRz",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Taxonomy::TreeAttributes\"]],[[\"@title\"],[[28,[37,1],[\"Taxonomy.treeData\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[12],[1,\"\\n            \"],[10,\"label\"],[12],[1,[28,[35,1],[\"Taxonomy.higherTaxonNodeLineage\"],null]],[13],[1,\"\\n            \"],[1,[30,0,[\"lineage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[51,[30,0,[\"nameIsValid\"]]],\"has-error\"]]]],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"tree.name\"],null]],[1,\"\\n\\n        \"],[8,[39,5],[[24,0,\"form-control default-focus-control\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"tree.root_node\"],null]],[1,\"\\n\\n\"],[6,[39,6],[[30,1]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,7],[[24,\"data-test\",\"root_node\"]],[[\"@value\",\"@update\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[3,4]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"tree.origin_tree\"],null]],[1,\"\\n\\n\"],[6,[39,6],[[30,5]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,8],[[24,\"data-test\",\"origin_tree\"]],[[\"@model\",\"@value\",\"@update\"],[\"taxonomy/tree\",[30,6],[30,7]]],null],[1,\"\\n\"]],[6,7]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@rootNode\",\"@name\",\"value\",\"update\",\"@originTree\",\"value\",\"update\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"if\",\"unless\",\"plutof-labelc\",\"input\",\"async/bind-relation\",\"taxonomy/taxon/auto-complete\",\"auto-complete/model\"]]",
    "moduleName": "plutof/components/taxonomy/tree-attributes.hbs",
    "isStrictMode": false
  });
  let TreeAttributes = (_dec = (0, _object.computed)('args.name'), _dec2 = (0, _object.computed)('args.rootNode.lineage.@each.representation'), _class = class TreeAttributes extends _component2.default {
    get nameIsValid() {
      return this.args.name.length > 0;
    }
    get lineage() {
      return this.args.rootNode.lineage.mapBy('representation').join(' / ');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "nameIsValid", [_validations.validator, _dec], Object.getOwnPropertyDescriptor(_class.prototype, "nameIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lineage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "lineage"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TreeAttributes);
});