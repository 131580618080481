define("plutof/templates/profile/rights", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2ENoJLL/",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.rights\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,1,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"rights\",\"defaultOwner\",\"validationContext\"],[[33,3,[\"profileRights\"]],[33,3,[\"profile\",\"default_owner\"]],[33,4,[\"access\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[6,[39,5],null,null,[[\"default\"],[[[[1,\"            \"],[1,[28,[35,6],null,[[\"path\",\"hideTooltip\"],[\"personalsetting.default_owner\",true]]]],[1,\"\\n\\n            \"],[10,1],[15,1,[29,[[30,2,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n                \"],[1,[28,[35,7],[\"Profile.defaultRightsTooltip\"],null]],[1,\"\\n                \"],[10,\"br\"],[12],[13],[1,\"\\n                \"],[1,[28,[35,7],[\"Agents.personTooltip\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"],[6,[39,8],[[30,0,[\"model\",\"profile\",\"default_owner\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,9],null,[[\"@value\",\"@update\",\"@noDefault\"],[[30,3],[30,4],true]],null],[1,\"\\n\"]],[3,4]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[13],[1,\"\\n\\n\"],[1,[28,[35,10],null,[[\"save\",\"disabled\"],[[28,[37,11],[[30,0],\"save\"],null],[33,4,[\"isInvalid\"]]]]]],[1,\"\\n\"]],[\"grid\",\"t\",\"value\",\"update\"],false,[\"test/route-marker\",\"layout/regular-grid\",\"access/rights-form\",\"model\",\"validationContext\",\"tooltip-wrapper\",\"plutof-labelc\",\"i18n-t\",\"async/bind-relation\",\"agent/user/auto-complete\",\"common/save-button\",\"action\"]]",
    "moduleName": "plutof/templates/profile/rights.hbs",
    "isStrictMode": false
  });
});