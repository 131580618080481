define("plutof/templates/components/tool-tip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "swB4FVfd",
    "block": "[[[18,3,null],[1,\"\\n\\n\"],[10,1],[15,1,[29,[[30,0,[\"tid\"]]]]],[15,0,[29,[\"wrapper-tooltip \",[52,[30,1],\"wrapper-tooltip--pre-wrap\"]]]],[12],[1,[30,2]],[13],[1,\"\\n\"]],[\"@preWrap\",\"@tooltip\",\"&default\"],false,[\"yield\",\"if\"]]",
    "moduleName": "plutof/templates/components/tool-tip.hbs",
    "isStrictMode": false
  });
});