define("plutof/templates/components/tables/bulk-update", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aETYb1Fg",
    "block": "[[[10,0],[14,0,\"table-bulk-update__table\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"isSelected\",\"selection\",\"selectionSize\",\"update\",\"togglePanel\",\"isEmpty\",\"selector\",\"selectAll\",\"updatePanel\"],[[28,[37,2],[[30,0],[30,0,[\"isSelected\"]]],null],[30,0,[\"selection\"]],[30,0,[\"selectionSize\"]],[28,[37,2],[[30,0],[30,0,[\"update\"]]],null],[28,[37,2],[[30,0],[30,0,[\"togglePanel\"]]],null],[28,[37,3],[[30,0,[\"selectionSize\"]]],null],[50,\"tables/-bulk-update/selector\",0,null,[[\"selection\",\"toggle\"],[[30,0,[\"selection\"]],[28,[37,2],[[30,0],[30,0,[\"toggle\"]]],null]]]],[50,\"tables/-bulk-update/select-all\",0,null,[[\"select\",\"clean\",\"selectionSize\"],[[28,[37,2],[[30,0],[30,0,[\"add\"]]],null],[28,[37,2],[[30,0],[30,0,[\"clean\"]]],null],[30,0,[\"selectionSize\"]]]]],[50,\"tables/-bulk-update/update-panel\",0,null,[[\"open\"],[[30,0,[\"isOpen\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"action\",\"not\",\"component\"]]",
    "moduleName": "plutof/templates/components/tables/bulk-update.hbs",
    "isStrictMode": false
  });
});