define("plutof/components/search/column-control", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Tables::ColumnSelector @disabled={{@tableIsEmpty}} as |columnSelector|>
      <columnSelector.column
          @toggle={{this.toggleAllColumns}}
          @visible={{this.allHeadersVisible}}
      >
          {{i18n-t "Search.toggleAllColumns"}}
      </columnSelector.column>
  
      {{#each this.regularHeaders as |header|}}
          <Search::ColumnControl::Column
              @column={{component columnSelector.column}}
              @pin={{component columnSelector.pin}}
              @header={{header}}
              @absoluteOrdering={{@absoluteOrdering}}
              @pinColumn={{@pinColumn}}
              @toggleColumn={{@toggleColumn}} />
      {{/each}}
  
      {{#if this.traitHeaders}}
          <div class="column-control-label">
              {{i18n-t "traits.traits"}}
          </div>
  
          {{#each this.traitHeaders as |header|}}
              <Search::ColumnControl::Column
                  @column={{component columnSelector.column}}
                  @pin={{component columnSelector.pin}}
                  @header={{header}}
                  @absoluteOrdering={{@absoluteOrdering}}
                  @pinColumn={{@pinColumn}}
                  @toggleColumn={{@toggleColumn}} />
          {{/each}}
      {{/if}}
  </Tables::ColumnSelector>
  
  */
  {
    "id": "asnUSvD9",
    "block": "[[[8,[39,0],null,[[\"@disabled\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"column\"]],null,[[\"@toggle\",\"@visible\"],[[30,0,[\"toggleAllColumns\"]],[30,0,[\"allHeadersVisible\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"Search.toggleAllColumns\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"regularHeaders\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@column\",\"@pin\",\"@header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\"],[[50,[30,2,[\"column\"]],0,null,null],[50,[30,2,[\"pin\"]],0,null,null],[30,3],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[3]],null],[1,\"\\n\"],[41,[30,0,[\"traitHeaders\"]],[[[1,\"        \"],[10,0],[14,0,\"column-control-label\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"traits.traits\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"traitHeaders\"]]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,[[\"@column\",\"@pin\",\"@header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\"],[[50,[30,2,[\"column\"]],0,null,null],[50,[30,2,[\"pin\"]],0,null,null],[30,7],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[7]],null]],[]],null]],[2]]]]],[1,\"\\n\"]],[\"@tableIsEmpty\",\"columnSelector\",\"header\",\"@absoluteOrdering\",\"@pinColumn\",\"@toggleColumn\",\"header\"],false,[\"tables/column-selector\",\"i18n-t\",\"each\",\"-track-array\",\"search/column-control/column\",\"component\",\"if\"]]",
    "moduleName": "plutof/components/search/column-control.hbs",
    "isStrictMode": false
  });
  let ColumnControl = (_dec = (0, _object.computed)('args.headers.@each.isVisible'), _dec2 = (0, _object.computed)('args.headers.@each.isTraitHeader'), _dec3 = (0, _object.computed)('args.headers.@each.isTraitHeader'), _class = class ColumnControl extends _component2.default {
    get allHeadersVisible() {
      const headers = this.args.headers;
      return headers && !headers.some(header => !header.isVisible);
    }
    get regularHeaders() {
      return this.args.headers.filter(header => !header.isTraitHeader);
    }
    get traitHeaders() {
      return this.args.headers.filter(header => header.isTraitHeader);
    }
    toggleAllColumns() {
      this.args.setColumnsVisibility(!this.allHeadersVisible);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "allHeadersVisible", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allHeadersVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "regularHeaders", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "regularHeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "traitHeaders", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "traitHeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAllColumns", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllColumns"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColumnControl);
});