define("plutof/templates/components/datacite/resource-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x7AT10cq",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[15,0,[52,[51,[30,0,[\"filePresent\"]]],\"has-error\"]],[12],[1,\"\\n\"],[6,[39,1],[[30,1,[\"linked_file\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],[[24,\"data-test\",\"file\"]],[[\"@value\",\"@update\",\"@access\",\"@params\"],[[30,2],[30,3],\"owner\",[28,[37,3],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"mini-input\"],[24,\"data-test\",\"size\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"size\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,5],[[24,0,\"mini-input\"],[24,\"data-test\",\"remarks\"]],[[\"@value\"],[[30,1,[\"remarks\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,6],[\"click\",[30,4]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@resource\",\"value\",\"update\",\"@remove\"],false,[\"unless\",\"async/bind-relation\",\"filerepository/auto-complete\",\"hash\",\"input\",\"flexible-textarea\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/resource-row-form.hbs",
    "isStrictMode": false
  });
});