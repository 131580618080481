define("plutof/components/sample/event-fields-view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@event}}
      @testMarker="event-fields-view"
      class="event-fields-view"
      as |view|
  >
      {{view.field "event_id"}}
      {{view.field "timespan_begin"}}
      {{view.field "timespan_end"}}
  
      {{#view.attr "description"}}
          <LongText
              @text={{@event.description}}
              @text-height="height-2"
              @tagName="span" />
      {{/view.attr}}
  
      <Sample::MeasurementFields
          @object={{@event}}
          @mainform={{@externalMainform}} />
  </GenericViews::Record>
  
  */
  {
    "id": "HX3q45yq",
    "block": "[[[8,[39,0],[[24,0,\"event-fields-view\"]],[[\"@record\",\"@testMarker\"],[[30,1],\"event-fields-view\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[30,2,[\"field\"]],[\"event_id\"],null]],[1,\"\\n    \"],[1,[28,[30,2,[\"field\"]],[\"timespan_begin\"],null]],[1,\"\\n    \"],[1,[28,[30,2,[\"field\"]],[\"timespan_end\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"description\"],null,[[\"default\"],[[[[1,\"        \"],[8,[39,1],null,[[\"@text\",\"@text-height\",\"@tagName\"],[[30,1,[\"description\"]],\"height-2\",\"span\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@object\",\"@mainform\"],[[30,1],[30,3]]],null],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@event\",\"view\",\"@externalMainform\"],false,[\"generic-views/record\",\"long-text\",\"sample/measurement-fields\"]]",
    "moduleName": "plutof/components/sample/event-fields-view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});