define("plutof/components/related-objects/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{@pagination.isEmpty}}
      data-test="RelatedObjects::View"
      data-test-loaded={{not @pagination.isLoading}}
      ...attributes
      as |panel|
  >
      <panel.header
          @title={{@title}}
          @count={{@pagination.objectCount}}
      >
          <RelatedObjects::SearchButton
              @searchQuery={{@searchQuery}}
              @searchModule={{@searchModule}} />
  
          {{! TODO: Those should be panel.button }}
          {{#if @clipboard}}
              <RelatedObjects::ClipboardButtons
                  @pagination={{@pagination}}
                  @clipboard={{@clipboard}}
                  @clipboardQuery={{@clipboardQuery}}
                  @disabled={{not @pagination.objects}} />
          {{/if}}
  
          {{#if this.canAdd}}
              <panel.button
                  @clicked={{@add}}
                  data-test="add-button"
              >
                  {{i18n-t "General.New"}}
              </panel.button>
          {{/if}}
      </panel.header>
  
      <panel.content>
          {{yield}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "DFoGw+nf",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"RelatedObjects::View\"],[16,\"data-test-loaded\",[28,[37,1],[[30,1,[\"isLoading\"]]],null]],[17,2]],[[\"@collapse\"],[[30,1,[\"isEmpty\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"header\"]],null,[[\"@title\",\"@count\"],[[30,4],[30,1,[\"objectCount\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@searchQuery\",\"@searchModule\"],[[30,5],[30,6]]],null],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"            \"],[8,[39,4],null,[[\"@pagination\",\"@clipboard\",\"@clipboardQuery\",\"@disabled\"],[[30,1],[30,7],[30,8],[28,[37,1],[[30,1,[\"objects\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canAdd\"]],[[[1,\"            \"],[8,[30,3,[\"button\"]],[[24,\"data-test\",\"add-button\"]],[[\"@clicked\"],[[30,9]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,5],[\"General.New\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,10,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@pagination\",\"&attrs\",\"panel\",\"@title\",\"@searchQuery\",\"@searchModule\",\"@clipboard\",\"@clipboardQuery\",\"@add\",\"&default\"],false,[\"plutof-panel/item-list\",\"not\",\"related-objects/search-button\",\"if\",\"related-objects/clipboard-buttons\",\"i18n-t\",\"yield\"]]",
    "moduleName": "plutof/components/related-objects/view.hbs",
    "isStrictMode": false
  });
  let RelatedObjectsView = (_dec = (0, _object.computed)('canModify', 'add'), _class = class RelatedObjectsView extends _component2.default {
    get canAdd() {
      return this.args.canModify && Boolean(this.args.add);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "canAdd", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "canAdd"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelatedObjectsView);
});