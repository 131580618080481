define("plutof/components/specimen/autocomplete-result", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>
      {{hl-subs this.name @query}}
  
      {{#if @result.owner}}
          <span class="plutof-ac-suggestion-description">
              {{@result.owner}}
          </span>
      {{/if}}
  </p>
  
  {{#if @result.tooltip}}
      <div>
          <span class="plutof-tooltip">{{@result.tooltip}}</span>
      </div>
  {{/if}}
  
  */
  {
    "id": "cjFid1nZ",
    "block": "[[[10,2],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"name\"]],[30,1]],null]],[1,\"\\n\\n\"],[41,[30,2,[\"owner\"]],[[[1,\"        \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n            \"],[1,[30,2,[\"owner\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,2,[\"tooltip\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,[30,2,[\"tooltip\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@query\",\"@result\"],false,[\"hl-subs\",\"if\"]]",
    "moduleName": "plutof/components/specimen/autocomplete-result.hbs",
    "isStrictMode": false
  });
  class SpecimenAutocompleteResult extends _component2.default {
    get name() {
      const {
        name,
        additional_identifiers
      } = this.args.result.object;
      return [name].concat(additional_identifiers).join(' | ');
    }
  }
  _exports.default = SpecimenAutocompleteResult;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenAutocompleteResult);
});