define("plutof/components/access/-view-rights/view-public", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      {{#if @linkingEnabled}}
          <div class="plutof-tooltip-hover">
              <div class="plutof-label">
                  {{i18n-t "Access.canView"}}&#47;{{i18n-t "Access.publicLinkingLabel"}}
              </div>
  
              <div class="plutof-tooltip">
                  {{i18n-t "Access.publicLinkingHint"}}
              </div>
          </div>
      {{else}}
          <div class="plutof-label">
              {{i18n-t "Access.canView"}}
          </div>
      {{/if}}
  
      <p>
          <span class="icon-access-public"></span>
          {{i18n-t "Access.public"}}
  
          {{! template-lint-disable block-indentation }}
          {{#if @linkingEnabled}}
              &#47;
  
              {{#if @linkingAllowed}}
                  <span class={{icon "public"}}></span>
                  {{i18n-t "Access.public"}}
              {{else}}
                  <span class={{icon "prviate"}}></span>
                  {{i18n-t "Access.private"}}
              {{/if}}
          {{/if}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "TFWxQSD8",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[28,[35,2],[\"Access.canView\"],null]],[1,\"/\"],[1,[28,[35,2],[\"Access.publicLinkingLabel\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n                \"],[1,[28,[35,2],[\"Access.publicLinkingHint\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"Access.canView\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,2],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-access-public\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,2],[\"Access.public\"],null]],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"            /\\n\\n\"],[41,[30,2],[[[1,\"                \"],[10,1],[15,0,[28,[37,3],[\"public\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,2],[\"Access.public\"],null]],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[15,0,[28,[37,3],[\"prviate\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,2],[\"Access.private\"],null]],[1,\"\\n\"]],[]]]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@linkingEnabled\",\"@linkingAllowed\"],false,[\"access/-view-rights/column\",\"if\",\"i18n-t\",\"icon\"]]",
    "moduleName": "plutof/components/access/-view-rights/view-public.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});