define("plutof/components/login/form-input", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="login-input {{if @value 'login-input--filled'}} {{if @isValid 'login-input--valid'}}">
      <label class="login-input__label">
          {{@label}}
      </label>
  
      {{yield}}
  
      {{#if @help}}
          <div class="login-input__help">
              {{@help}}
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "u4PvBUWb",
    "block": "[[[10,0],[15,0,[29,[\"login-input \",[52,[30,1],\"login-input--filled\"],\" \",[52,[30,2],\"login-input--valid\"]]]],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"login-input__label\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,5,null],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"        \"],[10,0],[14,0,\"login-input__help\"],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@value\",\"@isValid\",\"@label\",\"@help\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "plutof/components/login/form-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});