define("plutof/templates/components/profile/groups/user-groups", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Mo5QGGF7",
    "block": "[[[10,0],[14,0,\"user-groups__controls\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@pagination\"],[[30,1]]],null],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs\"],[4,[38,1],[\"click\",[30,0,[\"createGroup\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"Settings.groups.create\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs\"],[4,[38,1],[\"click\",[30,0,[\"joinGroup\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"Settings.groups.join\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[10,\"table\"],[14,0,\"plutof-table plutof-table__multi-line table-hover\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,2],[\"Settings.groups.fields.group\"],null]],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,2],[\"Settings.groups.fields.rights\"],null]],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,2],[\"Settings.groups.fields.status\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"objects\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,5],null,[[\"membership\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@memberships\",\"membership\"],false,[\"pagination/page-controls\",\"on\",\"i18n-t\",\"each\",\"-track-array\",\"profile/groups/-user-groups-row\"]]",
    "moduleName": "plutof/templates/components/profile/groups/user-groups.hbs",
    "isStrictMode": false
  });
});