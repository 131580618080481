define("plutof/templates/components/study/occurrence-statistics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hhmXu+SZ",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Project::OccurrenceStatistics\"]],[[\"@collapse\"],[[28,[37,1],[[30,0,[\"open\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[28,[37,2],[\"General.Statistics\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\"],[[28,[37,3],[[30,0],[33,4]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[14,0,\"icon-reset\"],[12],[13],[1,\"\\n\\n            \"],[1,[28,[35,2],[\"General.Reset\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"chart-container\"],[12],[1,\"\\n                \"],[1,[28,[35,6],null,[[\"config\",\"resetChart\"],[[33,7],[28,[37,3],[[30,0],\"resetChart\"],null]]]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"chart-container\"],[12],[1,\"\\n                \"],[1,[28,[35,6],null,[[\"config\"],[[33,8]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"action\",\"resetChart\",\"layout/regular-grid\",\"chart/histogram-chart\",\"timespanBeginConfig\",\"createdAtConfig\"]]",
    "moduleName": "plutof/templates/components/study/occurrence-statistics.hbs",
    "isStrictMode": false
  });
});