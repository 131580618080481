define("plutof/components/common/cancel-button", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let CancelButton = (_dec = (0, _component2.tagName)('span'), _dec(_class = class CancelButton extends _component.default {}) || _class);
  var _default = _exports.default = CancelButton;
});