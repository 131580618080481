define("plutof/serializers/jsonapi-base", ["exports", "@ember/utils", "@ember-data/serializer/json-api"], function (_exports, _utils, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RESOURCE_TYPE_TO_MODEL = _exports.MODEL_TO_RESOURCE_TYPE = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // TODO: See if we can define those at models directly and gather during app initialization
  const MODEL_TO_RESOURCE_TYPE = _exports.MODEL_TO_RESOURCE_TYPE = {
    'agent/agent': 'Agent',
    'agent/collection': 'Organization',
    'agent/organization': 'Organization',
    'agent/person': 'Person',
    'dna-lab/dna': 'DNA',
    'dna-lab/dna-extraction': 'DNAExtraction',
    'dna-lab/pcr': 'PCR',
    'dna-lab/pcr-product': 'PCRProduct',
    'dna-lab/polymerase-mix': 'PolymeraseMix',
    'dna-lab/primer': 'Primer',
    'dna-lab/sequencing': 'Sequencing',
    'filerepository/file': 'File',
    'habitat/community': 'Community',
    'measurement/mainform': 'Form',
    'permit/permit': 'Permit',
    'permit/item': 'PermitItem',
    'redbook/red-list': 'RedList',
    'redbook/red-list-assessment': 'RedListAssessment',
    'reference/reference': 'Reference',
    'study/study': 'Project',
    'taxonomy/taxonnode': 'Taxon',
    'taxonoccurrence/livingculture/strain': 'LivingSpecimen',
    'taxonoccurrence/materialsample/materialsample': 'MaterialSample',
    'taxonoccurrence/specimen/specimen': 'Specimen',
    'users/user': 'User'
  };
  const NULLABLE_TYPES = ['number', 'decimal'];
  const RESOURCE_TYPE_TO_MODEL = _exports.RESOURCE_TYPE_TO_MODEL = function () {
    let mapping = {};
    Object.keys(MODEL_TO_RESOURCE_TYPE).forEach(modelName => {
      mapping[MODEL_TO_RESOURCE_TYPE[modelName]] = modelName;
    });
    return mapping;
  }();
  class JSONAPIBaseSerializer extends _jsonApi.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "typeName", null);
      // Override
      _defineProperty(this, "modelName", null);
    }
    // Override

    serialize(snapshot, options) {
      const json = {
        data: {
          attributes: snapshot.attributes(),
          type: this.typeName,
          id: snapshot.id
        }
      };

      // WIP
      snapshot.eachRelationship((key, relationship) => {
        if (relationship.kind === 'belongsTo') {
          // TODO Can you conjure type like this?
          const id = snapshot.belongsTo(key, {
            id: true
          });
          if ((0, _utils.isPresent)(id)) {
            // XXX This is madness.
            // XXX XXX XXX: Those are relationships. Not attributes, relationships.
            json.data.attributes[key] = {
              id: id,
              // XXX
              type: MODEL_TO_RESOURCE_TYPE[relationship.type]
            };
          } else {
            json.data.attributes[key] = null;
          }
        } else {
          const ids = snapshot.hasMany(key, {
            ids: true
          }) || [];
          const type = MODEL_TO_RESOURCE_TYPE[relationship.type];
          json.data.attributes[key] = ids.map(id => ({
            type,
            id
          }));
        }
      });
      snapshot.eachAttribute((key, attribute) => {
        if (NULLABLE_TYPES.includes(attribute.type) && (0, _utils.isEmpty)(json.data.attributes[key])) {
          json.data.attributes[key] = null;
        }
      });
      return json;
    }

    // JSONAPISerializer expectes attribuets to be dasherized; we don't.
    keyForAttribute(key) {
      return key;
    }

    // JSONAPISerializer expectes attribuets to be dasherized; we don't.
    keyForRelationship(key) {
      return key;
    }

    // 3h
    modelNameFromPayloadKey() {
      return this.modelName;
    }

    // XXX This is rather crude.
    extractRelationships(modelClass, resourceHash) {
      if ((0, _utils.isNone)(resourceHash.relationships)) {
        return {};
      }
      const relationships = {};
      modelClass.eachRelationship((key, relationshipMeta) => {
        if (relationshipMeta.kind === 'hasMany' || relationshipMeta.kind === 'belongsTo') {
          const payloadRel = resourceHash.relationships[key];
          if ((0, _utils.isPresent)(payloadRel) && (0, _utils.isPresent)(payloadRel.data)) {
            if (relationshipMeta.kind === 'belongsTo') {
              relationships[key] = {
                data: {
                  id: payloadRel.data.id,
                  type: relationshipMeta.type
                }
              };
            } else {
              relationships[key] = {
                data: payloadRel.data.map(pointer => ({
                  id: pointer.id,
                  type: relationshipMeta.type
                }))
              };
            }
          }
        }
      }, this); // XXX Use this or don't.

      // XXX
      return relationships;
    }
  }
  var _default = _exports.default = JSONAPIBaseSerializer;
});