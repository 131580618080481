define("plutof/models/datacite/metadata", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model", "moment", "plutof/models/plutof-model"], function (_exports, _object, _computed, _model, moment, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Metadata = (_dec = (0, _model.belongsTo)('agent/organization'), _dec2 = (0, _model.belongsTo)('geography/language'), _dec3 = (0, _model.attr)('enum'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('enum'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('text'), _dec12 = (0, _model.attr)(), _dec13 = (0, _model.attr)({
    defaultValue: () => []
  }), _dec14 = (0, _model.attr)({
    defaultValue: () => []
  }), _dec15 = (0, _model.attr)('string', {
    defaultValue: moment().format('YYYY')
  }), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _computed.alias)('citation'), _dec19 = (0, _object.computed)('resource_type', 'resource_type_general'), _class = class Metadata extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "publisher", _descriptor, this);
      _initializerDefineProperty(this, "language", _descriptor2, this);
      _initializerDefineProperty(this, "licence", _descriptor3, this);
      _initializerDefineProperty(this, "identifier", _descriptor4, this);
      _initializerDefineProperty(this, "doi", _descriptor5, this);
      _initializerDefineProperty(this, "version", _descriptor6, this);
      _initializerDefineProperty(this, "resource_type", _descriptor7, this);
      _initializerDefineProperty(this, "resource_type_general", _descriptor8, this);
      _initializerDefineProperty(this, "title", _descriptor9, this);
      _initializerDefineProperty(this, "subtitle", _descriptor10, this);
      _initializerDefineProperty(this, "abstract", _descriptor11, this);
      _initializerDefineProperty(this, "creators", _descriptor12, this);
      _initializerDefineProperty(this, "subjects", _descriptor13, this);
      _initializerDefineProperty(this, "taxa", _descriptor14, this);
      _initializerDefineProperty(this, "publication_year", _descriptor15, this);
      _initializerDefineProperty(this, "citation", _descriptor16, this);
      _initializerDefineProperty(this, "registered", _descriptor17, this);
      _initializerDefineProperty(this, "representation", _descriptor18, this);
    }
    get full_resource_type() {
      const resource_type_general = this.resource_type_general;
      const resource_type = this.resource_type;
      return `${resource_type_general} / ${resource_type}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "publisher", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "licence", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "identifier", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "doi", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "resource_type", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "resource_type_general", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "subtitle", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "abstract", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "creators", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "subjects", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "taxa", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "publication_year", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "citation", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "registered", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "full_resource_type", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "full_resource_type"), _class.prototype), _class);
  var _default = _exports.default = Metadata;
});