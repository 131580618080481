define("plutof/templates/components/clipboard/table-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "j9DIrfvl",
    "block": "[[[10,0],[14,0,\"table-control__info-text table-control__info-text--soft\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"Clipboard.clipboardUsage\"],null]],[1,\": \"],[1,[28,[35,1],[[30,1]],null]],[1,\"/\"],[1,[28,[35,1],[[30,2]],null]],[1,\"\\n\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"Clipboard.clipboardUsageTooltip\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showLimit\"]],[[[1,\"    \"],[10,0],[14,0,\"table-control__info-text table-control__info-text--soft\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"Clipboard.orderingDisabled\"],[[\"limit\"],[[30,3]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@used\",\"@available\",\"@orderableLimit\"],false,[\"i18n-t\",\"locale-string\",\"if\"]]",
    "moduleName": "plutof/templates/components/clipboard/table-info.hbs",
    "isStrictMode": false
  });
});