define("plutof/templates/reset-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ibuoimGs",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"reset-password\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"Registration.recoverPassword\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@showOldPasswordField\",\"@changed\",\"@key\"],[false,[30,0,[\"changed\"]],[30,0,[\"key\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"common/legend\",\"i18n-t\",\"auth/password-change\"]]",
    "moduleName": "plutof/templates/reset-password.hbs",
    "isStrictMode": false
  });
});