define("plutof/templates/components/experiment/dna-extraction/view/general-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "pHuLE2BM",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"title\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"design_description\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"laboratory\"],[[\"route\"],[\"organization.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"extracted_by\"],[[\"route\"],[\"person.view\"]]]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"extraction_method\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"extractionMethodRepresentation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"labware\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"labwareRepresentation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"timespan_begin\"],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[35,4],[[30,1,[\"timespan_begin\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"timespan_end\"],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[35,4],[[30,1,[\"timespan_end\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"protocol\"],[[\"route\"],[\"reference.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"is_finished\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@experiment\",\"view\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"formatted-date\"]]",
    "moduleName": "plutof/templates/components/experiment/dna-extraction/view/general-data.hbs",
    "isStrictMode": false
  });
});