define("plutof/components/profile/groups/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Existing group AC-s default to memberships-only, so replicating here }}
  <AutoComplete::Model
      @model="users/workgroup"
      @value={{@value}}
      @update={{@update}}
      @filters={{if (default-to @membershipsOnly true) (hash user="self")}}
      @params={{@params}}
      @disabled={{@disabled}} />
  
  */
  {
    "id": "5jDhuskH",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@value\",\"@update\",\"@filters\",\"@params\",\"@disabled\"],[\"users/workgroup\",[30,1],[30,2],[52,[28,[37,2],[[30,3],true],null],[28,[37,3],null,[[\"user\"],[\"self\"]]]],[30,4],[30,5]]],null],[1,\"\\n\"]],[\"@value\",\"@update\",\"@membershipsOnly\",\"@params\",\"@disabled\"],false,[\"auto-complete/model\",\"if\",\"default-to\",\"hash\"]]",
    "moduleName": "plutof/components/profile/groups/auto-complete.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});