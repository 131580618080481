define("plutof/components/plutof-panel/inner", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel plutof-panel" ...attributes>
      <div class="panel-body plutof-panel-body {{if @highlight 'colored-panel-body'}} {{@bodyClass}}">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "X47GxGua",
    "block": "[[[11,0],[24,0,\"panel plutof-panel\"],[17,1],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"panel-body plutof-panel-body \",[52,[30,2],\"colored-panel-body\"],\" \",[30,3]]]],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@highlight\",\"@bodyClass\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/inner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});