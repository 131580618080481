define("plutof/templates/components/search/gallery-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mah9oiny",
    "block": "[[[10,0],[15,0,[29,[\"gallery__thumbnail \",[52,[30,1,[\"image\",\"selected\"]],\"gallery__thumbnail--selected\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"image\",\"small_link\"]],[[[1,\"        \"],[11,\"img\"],[16,\"src\",[29,[[30,1,[\"image\",\"small_link\"]]]]],[24,0,\"gallery__thumbnail__img clickable\"],[4,[38,1],[[30,0],[30,2]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,0],[24,0,\"gallery__thumbnail__img clickable\"],[4,[38,1],[[30,0],[30,2]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[29,[[30,1,[\"icon\"]],\" gallery__thumbnail__icon\"]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@url\"],[[30,1,[\"record\",\"viewURL\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"gallery__thumbnail__info\"],[12],[1,\"\\n            \"],[10,1],[15,0,[29,[\"gallery__thumbnail__info__icon \",[30,1,[\"record\",\"icon\"]]]]],[12],[13],[1,\"\\n\\n            \"],[1,[30,1,[\"record\",\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@entry\",\"@select\"],false,[\"if\",\"action\",\"common/local-link\"]]",
    "moduleName": "plutof/templates/components/search/gallery-item.hbs",
    "isStrictMode": false
  });
});