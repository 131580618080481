define("plutof/components/identifiers/related-ids", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _object, _service, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.ids as |ids|}}
      {{#each ids as |id|}}
          <div>
              {{#if id.isRecordId}}
                  {{id.full_code}}
              {{else}}
                  <LinkTo @route={{id.viewRoute}} @model={{id.id}}>
                      {{id.full_code}}
                  </LinkTo>
              {{/if}}
          </div>
      {{/each}}
  {{/resolve-promise}}
  
  */
  {
    "id": "4uRw48C+",
    "block": "[[[6,[39,0],[[30,0,[\"ids\"]]],null,[[\"default\"],[[[[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"\\n\"],[41,[30,2,[\"isRecordId\"]],[[[1,\"                \"],[1,[30,2,[\"full_code\"]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[39,4],null,[[\"@route\",\"@model\"],[[30,2,[\"viewRoute\"]],[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,2,[\"full_code\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[2]],null]],[1]]]]]],[\"ids\",\"id\"],false,[\"resolve-promise\",\"each\",\"-track-array\",\"if\",\"link-to\"]]",
    "moduleName": "plutof/components/identifiers/related-ids.hbs",
    "isStrictMode": false
  });
  const VIEW_ROUTES = {
    'taxonoccurrence/specimen/specimen': 'specimen.view',
    'taxonoccurrence/livingculture/strain': 'livingspecimen.view',
    'taxonoccurrence/materialsample/materialsample': 'materialsample.view'
  };
  let RelatedIds = (_dec = (0, _object.computed)('args.record.id'), _class = class RelatedIds extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
    }
    _load() {
      const record = this.args.record;
      const url = `${record.url}related-ids/`;
      return this.ajax.request(url).then(ids => {
        if (ids.length < 2) {
          return [];
        }
        return ids.map(id => ({
          ...id,
          viewRoute: VIEW_ROUTES[record.constructor.modelName],
          isRecordId: id.id === parseInt(record.id)
        }));
      }).catch(_notifications.reportError);
    }
    get ids() {
      return this._load();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ids", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "ids"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelatedIds);
});