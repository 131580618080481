define("plutof/components/tables/row-selector/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      selector=(component "tables/row-selector/selector" selection=@selection equality=(default-to @equality this.defaultEquality))
  )}}
  
  */
  {
    "id": "e2AL9ONs",
    "block": "[[[18,3,[[28,[37,1],null,[[\"selector\"],[[50,\"tables/row-selector/selector\",0,null,[[\"selection\",\"equality\"],[[30,1],[28,[37,3],[[30,2],[30,0,[\"defaultEquality\"]]],null]]]]]]]]],[1,\"\\n\"]],[\"@selection\",\"@equality\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"default-to\"]]",
    "moduleName": "plutof/components/tables/row-selector/index.hbs",
    "isStrictMode": false
  });
  class TableRowSelector extends _component2.default {
    defaultEquality(x, y) {
      return x === y;
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableRowSelector);
});