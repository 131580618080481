define("plutof/components/annotation/-fields/status", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{i18n-t "annotation.fields.status"}}>
      {{! Not resolving translation here because ModelTexts will unnecessarily instantiate for every row }}
      {{get @translations.status.choices @annotation.status}}
  </@field>
  
  */
  {
    "id": "qe8lr02a",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"annotation.fields.status\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"    \"],[1,[28,[35,1],[[30,2,[\"status\",\"choices\"]],[30,3,[\"status\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@translations\",\"@annotation\"],false,[\"i18n-t\",\"get\"]]",
    "moduleName": "plutof/components/annotation/-fields/status.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});