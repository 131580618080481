define("plutof/components/sequence/view/chimeric-notification", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="fas fa-exclamation-triangle"></span>
  
  {{i18n-t "Sequences.potentiallyChimeric"}}
  
  (
  <a href="https://drive5.com/usearch/manual/uchime_algo.html" target="_blank">
      UCHIME
  </a>
  
  {{i18n-t "Sequences.chimericScore"}} = {{@score.score}}
  ).
  
  {{i18n-t "Sequences.chimeraParents"}}
  <LinkTo @route="sequence.view" @model={{@score.parent_1.id}}>{{@score.parent_1.representation}}</LinkTo> &amp;
  <LinkTo @route="sequence.view" @model={{@score.parent_2.id}}>{{@score.parent_2.representation}}</LinkTo>.
  
  */
  {
    "id": "xsGOmQG8",
    "block": "[[[10,1],[14,0,\"fas fa-exclamation-triangle\"],[12],[13],[1,\"\\n\\n\"],[1,[28,[35,0],[\"Sequences.potentiallyChimeric\"],null]],[1,\"\\n\\n(\\n\"],[10,3],[14,6,\"https://drive5.com/usearch/manual/uchime_algo.html\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n    UCHIME\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,0],[\"Sequences.chimericScore\"],null]],[1,\" = \"],[1,[30,1,[\"score\"]]],[1,\"\\n).\\n\\n\"],[1,[28,[35,0],[\"Sequences.chimeraParents\"],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"sequence.view\",[30,1,[\"parent_1\",\"id\"]]]],[[\"default\"],[[[[1,[30,1,[\"parent_1\",\"representation\"]]]],[]]]]],[1,\" &\\n\"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"sequence.view\",[30,1,[\"parent_2\",\"id\"]]]],[[\"default\"],[[[[1,[30,1,[\"parent_2\",\"representation\"]]]],[]]]]],[1,\".\\n\"]],[\"@score\"],false,[\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/components/sequence/view/chimeric-notification.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});