define("plutof/templates/photobank/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZJO7vvWC",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"photobank.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\"],[[28,[37,2],[\"photobank\"],null],[28,[37,3],[\"Photobank.newObject\"],null],[30,0,[\"model\",\"object\"]],[28,[37,4],[\"triggerReset\"],null]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@object\",\"@additionalIdentifiers\",\"@objectsToDelete\",\"@observationMainform\",\"@eventFormData\",\"@showAreaEditButton\",\"@showObservationForm\",\"@preparationsData\",\"@classificationItems\",\"@observationTableStrings\",\"@itemData\",\"@agentItems\",\"@areaEventViewStrings\",\"@closePanels\",\"@allowAreaReset\",\"@observationData\",\"@cloningRules\",\"@validationContext\"],[[30,0,[\"model\",\"object\"]],[30,0,[\"model\",\"additionalIdentifiers\"]],[30,0,[\"objectsToDelete\"]],[30,0,[\"model\",\"observationMainform\"]],[30,0,[\"model\",\"eventFormData\"]],[30,0,[\"showAreaEditButton\"]],true,[30,0,[\"model\",\"preparationsData\"]],[30,0,[\"model\",\"classificationItems\"]],[30,0,[\"observationTableStrings\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"model\",\"agentItems\"]],[30,0,[\"areaEventViewStrings\"]],[30,0,[\"closePanels\"]],[30,0,[\"allowAreaReset\"]],[30,0,[\"model\",\"observationData\"]],[30,0,[\"cloningRules\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[8,[39,8],null,[[\"@save\",\"@cancel\",\"@clone\",\"@ctrl\"],[[30,0,[\"save\"]],[28,[37,9],[[30,0],\"cancel\"],null],[28,[37,9],[[30,0],\"clone\"],null],[30,0]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"photobank/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"save-panel\",\"action\"]]",
    "moduleName": "plutof/templates/photobank/add.hbs",
    "isStrictMode": false
  });
});