define("plutof/components/tables/-list-view/order", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{component
      "tables/-list-view/order-label"
      label=@label
      active=@active
      ascending=@ascending
      sortBy=@sortBy
      disabled=@disabled
  }}>
      {{yield}}
  </@field>
  
  */
  {
    "id": "jhHwAkuV",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[50,\"tables/-list-view/order-label\",0,null,[[\"label\",\"active\",\"ascending\",\"sortBy\",\"disabled\"],[[30,2],[30,3],[30,4],[30,5],[30,6]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,7,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@label\",\"@active\",\"@ascending\",\"@sortBy\",\"@disabled\",\"&default\"],false,[\"component\",\"yield\"]]",
    "moduleName": "plutof/components/tables/-list-view/order.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});