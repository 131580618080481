define("plutof/translations/est/file-repository", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    file: 'Fail',
    files: 'Failid',
    title: 'Failide repositoorium',
    infoTitle: 'Failide repositoorium',
    newLinkTitle: 'Lisa uus',
    metadata: 'Metaandmed',
    linkedObjects: 'Lingitud objektid',
    FASTACSVButton: 'Salvesta CSV',
    FASTACSVMessage: 'CSV fail salvestati failide repositooriumisse.',
    FASTACSVLink: 'Kuva',
    largeImage: 'Täissuuruses',
    colFileName: 'Faili nimi',
    colSize: 'Suurus',
    colExternalActions: 'Tegevused',
    colProgress: 'Progress',
    type: 'Tüüp',
    object: 'Objekt',
    uploads: 'Üleslaadimised',
    creator: 'Faili looja',
    dimensions: 'Mõõtmed',
    areaSelectDescription: 'Vali pildi osa, mis pilti PlutoFis esindama jääb. Originaalfail talletatakse muudatusteta.',
    fileTooBig: bytes => `Liiga suur fail (maks ${bytes} baiti)`,
    alreadyExists: fileNames => `Fail(id) ${fileNames} on juba failide repositooriumis.`,
    filenameNotUnique: filename => `Failinimi ${filename} ei ole unikaalne. Erinev fail sama nimega on juba lisatud.`,
    storageUsed: 'Failide repositooriumi kasutus',
    sharedFileWarning: 'Failid seotud objektide paneelis seotakse kõikide kirjetega. Faili ühe kirjega sidumiseks eemalda fail seotud objektide paneelist, ning lisa ta vastava kirje juurde kirjete tabelis.',
    preview: 'Eelvaade',
    upload: {
      progress: 'Üleslaadimine: {{progress}}%',
      retrying: 'Faili {{filename}} üleslaadimine katkes. Üleslaadimine jätkub automaatselt.',
      failed: 'Faili {{filename}} üleslaadimine ebaõnnestus: {{reason}}'
    },
    share: {
      button: 'Jaga',
      copied: 'Faili link on lõikelauale kopeeritud'
    },
    types: {
      'other': 'Muu',
      'dataset': 'Andmekogum',
      'sound': 'Heli',
      'text': 'Tekst',
      'application': 'Rakendus',
      'video': 'Video',
      'image': 'Pilt'
    }
  };
});