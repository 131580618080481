define("plutof/templates/components/experiment/pcr/material-bulk-update", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qeCsp55M",
    "block": "[[[6,[39,0],null,[[\"update\"],[[30,1]]],[[\"default\"],[[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"pcr-product.forward_primer\"]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@model\",\"@value\",\"@filters\"],[\"dna-lab/primer\",[30,2,[\"forward_primer\"]],[28,[37,3],null,[[\"type\"],[1]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"pcr-product.reverse_primer\"]]]],[1,\"\\n\\n        \"],[8,[39,2],null,[[\"@model\",\"@value\",\"@filters\"],[\"dna-lab/primer\",[30,2,[\"reverse_primer\"]],[28,[37,3],null,[[\"type\"],[2]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"pcr-product.purification\"]]]],[1,\"\\n\\n        \"],[1,[28,[35,4],null,[[\"content\",\"value\",\"skipDefault\"],[[30,0,[\"purifications\"]],[30,2,[\"purification\"]],true]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"pcr-product.remarks\"]]]],[1,\"\\n\\n        \"],[1,[28,[35,5],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,2,[\"remarks\"]],\"form-control\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n    \"],[1,[30,3]],[1,\"\\n\"]],[2,3]]]]]],[\"@update\",\"values\",\"updateButton\"],false,[\"tables/bulk-fields-update\",\"plutof-labelc\",\"auto-complete/bound-model\",\"hash\",\"plutof-select/value\",\"input\"]]",
    "moduleName": "plutof/templates/components/experiment/pcr/material-bulk-update.hbs",
    "isStrictMode": false
  });
});