define("plutof/components/search/-results-info/normal", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="equal-flex__child">
      <strong class="table-control__info-text">
          {{@info}}
      </strong>
  </div>
  
  */
  {
    "id": "f7Fi6D18",
    "block": "[[[11,0],[17,1],[24,0,\"equal-flex__child\"],[12],[1,\"\\n    \"],[10,\"strong\"],[14,0,\"table-control__info-text\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@info\"],false,[]]",
    "moduleName": "plutof/components/search/-results-info/normal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});