define("plutof/templates/components/chart/pheno-graph-period", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y0/sQgwS",
    "block": "[[[41,[33,1,[\"data\",\"datasets\",\"length\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"type\",\"options\",\"data\"],[[33,1,[\"type\"]],[33,1,[\"options\"]],[33,1,[\"data\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"chartConfig\",\"chart/base-chart\"]]",
    "moduleName": "plutof/templates/components/chart/pheno-graph-period.hbs",
    "isStrictMode": false
  });
});