define("plutof/templates/trait/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6uOkh/vY",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@goBack\"],[[28,[37,1],[\"trait\"],null],[28,[37,2],[\"Forms.traitsLabel\"],null],[52,[30,0,[\"model\",\"canEdit\"]],\"trait.add\"],[30,0,[\"model\",\"pagination\"]],[28,[37,4],[\"goBack\"],null]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@model\"],[\"measurement.measurement\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"name\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,7],null,[[\"@route\",\"@model\"],[\"trait.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,8],[[30,3,[\"name\"]],[30,3,[\"name_translated\"]]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"description\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,9],[[28,[37,8],[[30,3,[\"description\"]],[30,3,[\"description_translated\"]]],null],120],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"type\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,10],[[30,1,[\"type\",\"choices\"]],[30,3,[\"type\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"trait\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"if\",\"route-action\",\"i18n/model-strings\",\"tables/list-route\",\"link-to\",\"localized-field\",\"short-string\",\"get\"]]",
    "moduleName": "plutof/templates/trait/index.hbs",
    "isStrictMode": false
  });
});