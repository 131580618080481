define("plutof/components/substrate/edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/application", "@ember/object", "@ember/utils", "@ember-decorators/component", "ember-cp-validations", "plutof/misc/abstract", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _application, _object, _utils, _component2, _emberCpValidations, _abstract, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createSubstrateData = createSubstrateData;
  _exports.default = void 0;
  var _dec, _class, _dec2, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.substrate"}}
      @collapse={{not @data.substrate.substrate}}
      data-test="Substrate::Edit"
  >
      <GenericForms::Record @record={{@data.substrate}} as |form|>
          <Layout::RegularGrid @columns={{4}}>
              {{form.bind "substrate" filters=(hash access="view")}}
              {{form.bind "substrate_new_name" valid=this.validations.attrs.substrate.substrate_new_name.isValid}}
              {{form.bind "type"}}
              {{form.bind "text"}}
          </Layout::RegularGrid>
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "tR1NoYsn",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Substrate::Edit\"]],[[\"@title\",\"@collapse\"],[[28,[37,1],[\"General.substrate\"],null],[28,[37,2],[[30,1,[\"substrate\",\"substrate\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@record\"],[[30,1,[\"substrate\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"substrate\"],[[\"filters\"],[[28,[37,5],null,[[\"access\"],[\"view\"]]]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"substrate_new_name\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"substrate\",\"substrate_new_name\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"type\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"text\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@data\",\"form\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"not\",\"generic-forms/record\",\"layout/regular-grid\",\"hash\"]]",
    "moduleName": "plutof/components/substrate/edit.hbs",
    "isStrictMode": false
  });
  const DataValidations = (0, _emberCpValidations.buildValidations)({
    'substrate.substrate_new_name': (0, _emberCpValidations.validator)('length', {
      max: 255
    })
  });
  let SubstrateData = (_dec = (0, _object.computed)('substrate.{substrate_new_name,text,substrate.id,type.id}'), _class = class SubstrateData extends _object.default.extend(DataValidations) {
    save() {
      if (this.isSubstrateEmpty && this.get('substrate.isNew')) {
        return _abstract.EMPTY_PROMISE;
      }
      this.occurrence.set('substrate', this.substrate);
      return this.substrate.save();
    }
    get isSubstrateEmpty() {
      const fields = [this.get('substrate.substrate_new_name'), this.get('substrate.text'), this.get('substrate.substrate.id'), this.get('substrate.type')];
      return fields.every(_utils.isEmpty);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isSubstrateEmpty", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isSubstrateEmpty"), _class.prototype), _class);
  function createSubstrateData(store, occurrence) {
    return occurrence.get('substrate').then(existingSubstrate => {
      let substrate = existingSubstrate;
      if ((0, _utils.isNone)(existingSubstrate)) {
        substrate = store.createRecord('substrate/substrate');
      }
      const ownerInjection = (0, _application.getOwner)(store).ownerInjection();
      return SubstrateData.create(ownerInjection, {
        substrate,
        occurrence
      });
    });
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'data.validations.isValid': (0, _emberCpValidations.validator)('is-truthy')
  });
  let SubstrateComponent = (_dec2 = (0, _component2.classNames)('substrate-edit'), _dec2(_class2 = class SubstrateComponent extends _component.default.extend(Validations, _componentValidations.default) {}) || _class2);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SubstrateComponent);
});