define("plutof/components/forms/view/fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @traits.length}}
      <div class="plutof-label">{{@label}}</div>
  
      <div class="mainform-measurements">
          {{#each @traits as |trait|}}
              <LinkTo @route="trait.view" @model={{trait.id}} class={{concat @labelClass " form-items"}}>
                  <div class="form-item__content" data-test="trait">
                      <b>
                          {{trait.name}} [{{trait.type}}]
                      </b>
  
                      <span> - </span>
  
                      {{trait.description}}
                  </div>
              </LinkTo>
          {{/each}}
      </div>
  
      <div class="spacing"></div>
  {{/if}}
  
  */
  {
    "id": "OFwpI1f3",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,[30,2]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"mainform-measurements\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"            \"],[8,[39,3],[[16,0,[28,[37,4],[[30,4],\" form-items\"],null]]],[[\"@route\",\"@model\"],[\"trait.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,0],[14,0,\"form-item__content\"],[14,\"data-test\",\"trait\"],[12],[1,\"\\n                    \"],[10,\"b\"],[12],[1,\"\\n                        \"],[1,[30,3,[\"name\"]]],[1,\" [\"],[1,[30,3,[\"type\"]]],[1,\"]\\n                    \"],[13],[1,\"\\n\\n                    \"],[10,1],[12],[1,\" - \"],[13],[1,\"\\n\\n                    \"],[1,[30,3,[\"description\"]]],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacing\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"@traits\",\"@label\",\"trait\",\"@labelClass\"],false,[\"if\",\"each\",\"-track-array\",\"link-to\",\"concat\"]]",
    "moduleName": "plutof/components/forms/view/fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});