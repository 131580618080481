define("plutof/components/agent/person/edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "rsvp", "@glimmer/component", "plutof/utils/store"], function (_exports, _component, _templateFactory, _object, _service, _rsvp, _component2, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PersonEditModel = void 0;
  var _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Agent::PersonForm
          @person={{@model.person}}
          @givenNameRequired={{@givenNameRequired}}
          @validationContext={{@validationContext.person}} />
  </PlutofPanel::Simple>
  
  <PlutofPanel::ItemList
      @collapse={{not @model.associations.length}}
      data-test="Agent::Person::Edit::Associations"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "Agents.associatedOrg"}}
          @count={{@model.agentassociations.length}}
      >
          <panel.button
              @clicked={{@model.addAssociation}}
              @class="test-marker-btn-add"
          >
              {{i18n-t "General.Add"}}
          </panel.button>
      </panel.header>
  
      <panel.innerPanels>
          <Validation::SplitContext
              @context={{@validationContext.associations}}
              @items={{@model.associations}}
              as |association context|
          >
              <Agent::AgentAssociation
                  @agentassociation={{association}}
                  @remove={{fn @model.removeAssociation association}}
                  @validationContext={{context}} />
          </Validation::SplitContext>
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "ycKMUIRw",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@person\",\"@givenNameRequired\",\"@validationContext\"],[[30,1,[\"person\"]],[30,2],[30,3,[\"person\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],[[24,\"data-test\",\"Agent::Person::Edit::Associations\"]],[[\"@collapse\"],[[28,[37,4],[[30,1,[\"associations\",\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,4,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"Agents.associatedOrg\"],null],[30,1,[\"agentassociations\",\"length\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"button\"]],null,[[\"@clicked\",\"@class\"],[[30,1,[\"addAssociation\"]],\"test-marker-btn-add\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,4,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@context\",\"@items\"],[[30,3,[\"associations\"]],[30,1,[\"associations\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@agentassociation\",\"@remove\",\"@validationContext\"],[[30,5],[28,[37,7],[[30,1,[\"removeAssociation\"]],[30,5]],null],[30,6]]],null],[1,\"\\n        \"]],[5,6]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"@model\",\"@givenNameRequired\",\"@validationContext\",\"panel\",\"association\",\"context\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"agent/person-form\",\"plutof-panel/item-list\",\"not\",\"validation/split-context\",\"agent/agent-association\",\"fn\"]]",
    "moduleName": "plutof/components/agent/person/edit.hbs",
    "isStrictMode": false
  });
  let PersonEditModel = _exports.PersonEditModel = (_class = class PersonEditModel {
    constructor({
      store,
      person,
      associations
    }) {
      _defineProperty(this, "deleted", []);
      this.store = store;
      this.person = person;
      this.associations = associations;
    }
    async save() {
      await _rsvp.default.Promise.all(this.deleted.map(async association => {
        const address = await association.address;
        await address.destroyRecord();
        await association.destroyRecord();
      }));
      this.deleted = [];
      await this.person.save();
      await _rsvp.default.Promise.all(this.associations.map(async association => {
        const address = await association.address;
        await address.save();
        await association.save();
      }));
    }
    addAssociation() {
      const newAgent = this.store.createRecord('agent/agentassociation', {
        person: this.person,
        address: this.store.createRecord('agent/address', {})
      });
      this.associations.pushObject(newAgent);
    }
    removeAssociation(association) {
      this.deleted.push(association);
      this.associations.removeObject(association);
    }
    static create(store) {
      return new PersonEditModel({
        store,
        person: store.createRecord('agent/person'),
        associations: []
      });
    }
    static async load(store, personID) {
      const [person, associations] = await _rsvp.default.Promise.all([store.findRecord('agent/person', personID), (0, _store.query)(store, 'agent/agentassociation', {
        person: personID
      })]);
      return new PersonEditModel({
        store,
        person,
        associations
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "addAssociation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addAssociation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAssociation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAssociation"), _class.prototype), _class);
  let PersonEdit = _exports.default = (_class2 = class PersonEdit extends _component2.default {
    // TODO: Been doing it like this, but does this work if validationContext
    // changes?
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      this.args.validationContext.addChild('person', this.i18n.translate('General.generalData'));
      this.args.validationContext.addChild('associations', this.i18n.translate('Agents.associatedOrg'));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PersonEdit);
});