define("plutof/translations/eng/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    map: 'Map',
    locateMe: 'Locate Me',
    longitude: 'Longitude',
    latitude: 'Latitude',
    convert: 'Convert',
    maxMinute: 'Maximum time (minutes, seconds) is 60 unit',
    maxLatitude: 'Maximum latitude is 90 ',
    maxLongitude: 'Maximum longitude is 180',
    ddmm: 'DD MM SS',
    DDMMM: 'DD MM.MMMMM',
    degreesDD: 'Degrees (DD°)',
    minutesMM: 'Minutes (MM\')',
    secondsSS: 'Seconds (SS\'\')',
    minutesNSeconds: 'Minutes (MM.mmm\')',
    southAndNorth: '-S/N',
    westAndEast: '-W/E',
    helpText: 'Latitude/longitude. You can choose between different coordinate formats: decimal degrees (D, default); degrees and decimal minutes (DM); degrees, minutes, and seconds (DMS). For "South" latitudes and "West" longitudes use negative (-) degree values.',
    fullscreen: 'Fullscreen',
    showOnMap: 'Show on map',
    parsingWentWrong: 'Something went wrong with parsing geoJSON. Check https://geojsonlint.com/ for valid format.',
    shouldContain: 'geoJSON should contain same type of items, e.g Polygons, Lines, Points',
    markers: 'Map markers',
    geoJSON: {
      empty: 'GeoJSON file has no features'
    },
    controls: {
      navigate: 'Navigate',
      modify: 'Modify',
      point: 'Point',
      polygon: 'Polygon',
      box: 'Box',
      radius: 'Radius',
      clearMap: 'Clear Map',
      controls: 'Map controls'
    },
    layers: {
      heatmap: 'Heatmap',
      maaamet: 'Estonian Land Board',
      kaitsealad: 'Nature Reserves',
      waterbirds: 'Water Birds',
      taimeatlas: 'Plant Atlas Grid',
      grid2x2: '2x2 km Grid',
      baseMap: 'Base Map',
      optionalLayers: 'Optional Layers',
      layers: 'Layers'
    },
    import: {
      file: 'File',
      fileTooltip: 'Use a geometry file from PlutoF File Repository.',
      fileParseError: 'Wrong geometry format.',
      fileSizeError: 'File is too large.',
      placeholder: 'Paste/insert geometry here',
      geometry: 'Geometry',
      uploadTooltip: 'Load geometry file from computer.'
    }
  };
});