define("plutof/components/common/record-link-by-url", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "plutof/misc/content_types", "plutof/utils/reflection"], function (_exports, _component, _templateFactory, _component2, _object, _service, _utils, _content_types, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.record}}
      <LinkTo @route={{@viewRoute}} @model={{this.record.id}}>
          {{this.record.representation}}
      </LinkTo>
  {{/resolve-promise}}
  
  */
  {
    "id": "TYzDNN/s",
    "block": "[[[6,[39,0],[[30,0,[\"record\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@route\",\"@model\"],[[30,1],[30,0,[\"record\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"record\",\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@viewRoute\"],false,[\"resolve-promise\",\"link-to\"]]",
    "moduleName": "plutof/components/common/record-link-by-url.hbs",
    "isStrictMode": false
  });
  let RecordViewByUrl = (_dec = (0, _object.computed)('args.url'), _class = class RecordViewByUrl extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get record() {
      if ((0, _utils.isNone)(this.args.url)) {
        return null;
      }
      const id = (0, _reflection.recordURLToID)(this.args.url);
      const path = (0, _content_types.modelPathByUrl)(this.args.url);
      return this.store.findRecord(path, id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "record", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "record"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordViewByUrl);
});