define("plutof/templates/components/sample/navbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4iUaAXvV",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@annotateRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\",\"@accessRights\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[28,[37,1],[[30,7]],null],[30,2],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,11,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,14,[[30,10],[28,[37,3],null,[[\"primaryControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,11,[\"middleControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,14,[[30,10],[28,[37,3],null,[[\"middleControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@navbar\",\"@record\"],[[30,10],[30,3]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,11,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,12]],[[[1,\"            \"],[8,[39,7],null,[[\"@navbar\",\"@addRoute\",\"@record\"],[[30,10],[30,13],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,8],null,[[\"@navbar\",\"@record\"],[[30,10],[30,3]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[10,11]]]]],[1,\"\\n\"]],[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@annotateRoute\",\"@noDelete\",\"@infoContent\",\"@accessRights\",\"navbar\",\"slots\",\"@noCloning\",\"@addRoute\",\"&default\"],false,[\"navbar/view\",\"not\",\"yield\",\"hash\",\"component\",\"clipboard/navbar-button-new\",\"unless\",\"sample/navbar-buttons/cloning\",\"sample/navbar-buttons/detach\"]]",
    "moduleName": "plutof/templates/components/sample/navbar.hbs",
    "isStrictMode": false
  });
});