define("plutof/templates/components/expanded-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mgwFZ025",
    "block": "[[[10,\"ul\"],[14,0,\"checked-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"        \"],[11,\"li\"],[24,0,\"checked-list__item\"],[4,[38,3],[[30,0],\"select\",[30,1]],[[\"on\"],[\"click\"]]],[12],[1,\"\\n            \"],[18,3,[[30,1,[\"object\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"selected\"]],[[[1,\"                \"],[10,1],[14,0,\"fas fa-check-circle checked-list__check\"],[12],[13],[1,\"\\n\"],[41,[33,6],[[[1,\"                    \"],[10,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,6]],null]],null],null,[[[1,\"                            \"],[10,\"b\"],[12],[1,[30,2,[\"name\"]]],[1,\":\"],[13],[1,\" \"],[1,[30,2,[\"value\"]]],[10,\"br\"],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"                    \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"entry\",\"detail\",\"&default\"],false,[\"each\",\"-track-array\",\"entries\",\"action\",\"yield\",\"if\",\"details\"]]",
    "moduleName": "plutof/templates/components/expanded-select.hbs",
    "isStrictMode": false
  });
});