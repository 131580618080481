define("plutof/utils/push-to-store", ["exports", "@ember/array", "@ember/utils"], function (_exports, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.url_to_id = url_to_id;
  function url_to_id(url) {
    var parts = url.split('/');
    let i = parts.length - 1;
    while (i >= 0 && (0, _utils.isEmpty)(parts[i])) {
      i--;
    }
    if (i >= 0) {
      return parts[i];
    } else {
      return null;
    }
  }
  function push_record(store, model, data) {
    store.push(store.normalize(model, data));
    return store.peekRecord(model, data.id);
  }
  function _default(store, model, data) {
    if ((0, _array.isArray)(data)) {
      return data.map(push_record.bind(undefined, store, model));
    } else {
      return push_record(store, model, data);
    }
  }
});