define("plutof/controllers/profile/notifications", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _utils, _rsvp, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  let ProfileNotificationsController = (_class = class ProfileNotificationsController extends _controller.default {
    updateNotifications() {
      const store = this.store;
      let checkboxes = this.get('model.notificationCheckboxes');
      let userNotifications = this.get('model.userNotifications');
      let profile = this.get('model.profile');
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      const toSave = checkboxes.filterBy('isActive', true).filter(notification => {
        return (0, _utils.isNone)(userNotifications.findBy('notification_type', notification.value));
      });
      const disabledNotifications = checkboxes.filterBy('isActive', false);
      const toDelete = userNotifications.filter(uNotification => {
        return (0, _utils.isPresent)(disabledNotifications.findBy('value', uNotification.get('notification_type')));
      });
      const newRecords = toSave.map(notification => {
        return store.createRecord('profile/personalsettings/notificationoption', {
          personal_settings: profile,
          notification_type: notification.value
        });
      });
      return profile.save().then(() => {
        return _rsvp.default.all(toDelete.invoke('destroyRecord')).then(() => {
          return _rsvp.default.all(newRecords.invoke('save')).then(() => {
            userNotifications.removeObjects(toDelete);
            userNotifications.pushObjects(newRecords);
            (0, _notifications.displayNotification)('success', i18n.t('General.done'));
          });
        });
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateNotifications", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateNotifications"), _class.prototype), _class);
  var _default = _exports.default = ProfileNotificationsController;
});