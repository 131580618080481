define("plutof/components/navbar/-navbar-base/go-back", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  // TODO: Should move goBack logic out of route mixin, then can use it here without resorting
  // to route-action
  let NavbarGoBack = (_dec = (0, _component2.tagName)(''), _dec(_class = class NavbarGoBack extends _component.default {}) || _class);
  var _default = _exports.default = NavbarGoBack;
});