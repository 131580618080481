define("plutof/components/access/view-rights-base", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="view-rights">
      {{yield (hash
          panel=(component "access/-view-rights/panel")
          column=(component "access/-view-rights/column")
          owner=(component "access/-view-rights/owner")
          history=(component "access/-view-rights/history")
          derivedFrom=(component "access/-view-rights/derived-from")
  
          view=(hash
              public=(component "access/-view-rights/view-public")
              selected=(component "access/-view-rights/view-selected")
              private=(component "access/-view-rights/view-private")
          )
  
          edit=(hash
              selected=(component "access/-view-rights/edit-selected")
              private=(component "access/-view-rights/edit-private")
          )
      )}}
  </div>
  
  */
  {
    "id": "g6MXLvz1",
    "block": "[[[10,0],[14,0,\"view-rights\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"panel\",\"column\",\"owner\",\"history\",\"derivedFrom\",\"view\",\"edit\"],[[50,\"access/-view-rights/panel\",0,null,null],[50,\"access/-view-rights/column\",0,null,null],[50,\"access/-view-rights/owner\",0,null,null],[50,\"access/-view-rights/history\",0,null,null],[50,\"access/-view-rights/derived-from\",0,null,null],[28,[37,1],null,[[\"public\",\"selected\",\"private\"],[[50,\"access/-view-rights/view-public\",0,null,null],[50,\"access/-view-rights/view-selected\",0,null,null],[50,\"access/-view-rights/view-private\",0,null,null]]]],[28,[37,1],null,[[\"selected\",\"private\"],[[50,\"access/-view-rights/edit-selected\",0,null,null],[50,\"access/-view-rights/edit-private\",0,null,null]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/access/view-rights-base.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});