define("plutof/adapters/auth", ["exports", "@ember/object", "@ember/object/mixin", "@ember/service"], function (_exports, _object, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdapterAuth = _mixin.default.create({
    session: (0, _service.inject)(),
    locale: (0, _service.inject)(),
    headers: (0, _object.computed)('session.isAuthenticated', 'session.data.authenticated.access_token', function () {
      let headers = {
        'Accept-Language': this.locale.locale
      };
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      return headers;
    })
  });
  var _default = _exports.default = AdapterAuth;
});