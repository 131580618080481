define("plutof/templates/series/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lIQPSqPZ",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"series.view\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@accessRights\"],[[28,[37,2],[\"reference\"],null],[28,[37,3],[\"References.referenceSeries\"],null],[30,0,[\"model\",\"series\"]],\"reference/series\",\"series.edit\",true,[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],[[24,\"data-test\",\"Series::View::GeneralData\"]],[[\"@title\"],[[30,0,[\"model\",\"series\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@record\"],[[30,0,[\"model\",\"series\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"title\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"abbreviation\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"acronym\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"series\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"editor\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"dates\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@object\",\"@rights\"],[[30,0,[\"model\",\"series\"]],[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\"]],[\"view\"],false,[\"test/route-marker\",\"navbar/view\",\"icon\",\"i18n-t\",\"plutof-panel/simple\",\"generic-views/record\",\"layout/regular-grid\",\"access/view-rights\"]]",
    "moduleName": "plutof/templates/series/view.hbs",
    "isStrictMode": false
  });
});