define("plutof/templates/components/import/process-list/button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ATKds5LH",
    "block": "[[[8,[39,0],null,[[\"@tooltip\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[28,[37,1],[[30,2],[30,0,[\"handler\",\"isRunning\"]]],null]],[16,0,[29,[\"\\n            btn-link btn-list-view import-process-list__action\\n            \",[52,[30,3],\"import-process-list__action--highlight\"],\"\\n        \"]]],[4,[38,3],[\"click\",[30,0,[\"perform\"]]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[[52,[30,0,[\"handler\",\"isRunning\"]],\"loading-icon\",[30,4]]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@tooltip\",\"@disabled\",\"@highlight\",\"@icon\"],false,[\"tool-tip\",\"or\",\"if\",\"on\"]]",
    "moduleName": "plutof/templates/components/import/process-list/button.hbs",
    "isStrictMode": false
  });
});