define("plutof/templates/taxonomy/edittree", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "t9h0DWrL",
    "block": "[[[10,0],[14,0,\"route-marker-taxonomy-edittree\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\"],[[28,[37,1],[\"taxonomy\"],null],[28,[37,2],[\"Taxonomy.editTree\"],null],[30,0,[\"model\",\"tree\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@rights\",\"@validationContext\"],[[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\",\"access\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[36,6]],[24,0,\"btn btn-default\"],[4,[38,7],[[30,0],\"update\"],null],[12],[1,\"\\n            \"],[10,1],[15,0,[29,[[52,[33,9],\"loading-icon\",[28,[37,1],[\"ok\"],null]]]]],[12],[13],[1,\"\\n            \"],[1,[28,[35,2],[\"General.Save\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[28,[37,7],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"access/edit-rights\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"disableSave\",\"action\",\"if\",\"saving\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/taxonomy/edittree.hbs",
    "isStrictMode": false
  });
});