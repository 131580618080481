define("plutof/templates/components/map/layer-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SXDAs8a4",
    "block": "[[[11,\"button\"],[16,\"title\",[28,[37,0],[\"Map.layers.layers\"],null]],[24,0,\"layer-selector__toggle\"],[4,[38,1],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[28,[37,2],[\"menu-toggle\"],null]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"open\"]],[[[1,\"    \"],[10,0],[14,0,\"layer-selector__content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"spacer-below\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"plutof-label\"],[12],[1,[28,[35,0],[\"Map.layers.baseMap\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"possibleLayersets\"]]],null]],null],null,[[[1,\"                \"],[10,\"label\"],[14,0,\"radio-btn-label\"],[15,3,[29,[[30,2,[\"name\"]]]]],[12],[1,\"\\n                    \"],[1,[28,[35,6],null,[[\"option\",\"selection\"],[[30,2],[30,1,[\"layerset\"]]]]]],[1,\"\\n                    \"],[1,[30,2,[\"name\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"optional-layers\"],[12],[1,\"\\n\"],[41,[30,1,[\"optional\"]],[[[1,\"                \"],[10,\"label\"],[14,0,\"plutof-label\"],[12],[1,[28,[35,0],[\"Map.layers.optionalLayers\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"optional\"]]],null]],null],null,[[[1,\"                    \"],[10,0],[12],[1,\"\\n                        \"],[10,\"label\"],[14,0,\"plutof-label plutof-label--wrap\"],[12],[1,\"\\n                            \"],[1,[28,[35,7],null,[[\"type\",\"checked\"],[\"checkbox\",[30,3,[\"visible\"]]]]]],[1,\"\\n                            \"],[1,[30,3,[\"name\"]]],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[3]],null]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"\\n\"]],[]]]],[\"@selection\",\"layerset\",\"layer\"],false,[\"i18n-t\",\"on\",\"icon\",\"if\",\"each\",\"-track-array\",\"radio-button\",\"input\"]]",
    "moduleName": "plutof/templates/components/map/layer-selector.hbs",
    "isStrictMode": false
  });
});