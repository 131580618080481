define("plutof/components/search/repository-input", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember-decorators/component"], function (_exports, _component, _object, _computed, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RepositoryInput = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _computed.empty)('collection'), _dec3 = (0, _object.computed)('repositoryFilter.value.firstObject'), _dec4 = (0, _object.computed)('subrepositoryFilter'), _dec5 = (0, _object.computed)('collection.id'), _dec(_class = (_class2 = class RepositoryInput extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "classes", '');
      // style classes input
      _defineProperty(this, "repositoryFilter", null);
      _defineProperty(this, "subrepositoryFilter", null);
      _initializerDefineProperty(this, "noRepository", _descriptor, this);
    }
    get collection() {
      return this.get('repositoryFilter.value.firstObject');
    }
    get hasSubrepositoryFilters() {
      return (0, _utils.isPresent)(this.subrepositoryFilter);
    }
    get subrepositoryAutocompleteFilters() {
      const repositoryId = this.get('collection.id');
      if (!(0, _utils.isPresent)(repositoryId)) {
        return null;
      }
      return {
        parent_organization: repositoryId
      };
    }

    // Set filter value
    updateCollection(collection) {
      this.set('repositoryFilter.value', (0, _utils.isPresent)(collection) ? [collection] : []);
      if (this.subrepositoryFilter) {
        this.subrepositoryFilter.clear();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "noRepository", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "collection", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "collection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasSubrepositoryFilters", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasSubrepositoryFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "subrepositoryAutocompleteFilters", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "subrepositoryAutocompleteFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateCollection", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateCollection"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = RepositoryInput;
});