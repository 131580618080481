define("plutof/components/information/content/general/default-licence", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <img src="/assets/images/creativecommons.png" class="img-responsive">
  
  */
  {
    "id": "W9KQdq6E",
    "block": "[[[10,\"img\"],[14,\"src\",\"/assets/images/creativecommons.png\"],[14,0,\"img-responsive\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "plutof/components/information/content/general/default-licence.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});