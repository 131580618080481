define("plutof/components/filerepository/item-list", ["exports", "@ember/component", "@ember/object", "rsvp", "plutof/utils/modules", "plutof/utils/store", "plutof/utils/structures"], function (_exports, _component, _object, _rsvp, _modules, _store, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ItemListData = void 0;
  var _dec, _class, _dec2, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ItemListTab = (_dec = (0, _object.computed)('items.[]'), _class = class ItemListTab {
    constructor({
      store,
      file,
      contentType,
      items
    }) {
      _defineProperty(this, "items", []);
      _defineProperty(this, "toDelete", []);
      this.store = store;
      this.file = file;
      this.contentType = contentType;
      this.items = items;
      this.module = _modules.default[contentType];
    }
    get isEmpty() {
      return this.items.length === 0;
    }
    add(object) {
      if (!object) {
        return;
      }
      if (this.items.some(item => item.object_id === object.id)) {
        return;
      }
      const item = this.store.createRecord('filerepository/item', {
        new_content_type: this.contentType,
        object_id: object.id,
        file: this.file,
        object_name: object.representation
      });
      this.items.pushObject(item);
    }
    remove(item) {
      this.items.removeObject(item);
      this.toDelete.push(item);
    }
    async save() {
      await _rsvp.default.Promise.all(this.toDelete.map(item => item.destroyRecord()));
      this.toDelete = [];
      await _rsvp.default.Promise.all(this.items.map(item => item.save()));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isEmpty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  let ItemListData = _exports.ItemListData = (_dec2 = (0, _object.computed)('tabs.@each.isEmpty'), _class2 = class ItemListData {
    constructor(tabs) {
      this.tabs = tabs;
    }
    get isEmpty() {
      return this.tabs.every(tab => tab.isEmpty);
    }
    save() {
      return _rsvp.default.Promise.all(this.tabs.map(tab => tab.save()));
    }
    static async load(store, file, alwaysPresentContentTypes) {
      const items = await (0, _store.query)(store, 'filerepository/item', {
        file: file.id
      });
      const ctypes = (0, _structures.uniqueVia)(items.mapBy('new_content_type').concat(alwaysPresentContentTypes));
      return new ItemListData(ctypes.map(contentType => new ItemListTab({
        store,
        file,
        contentType,
        items: items.filter(item => item.new_content_type === contentType)
      })));
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "isEmpty", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isEmpty"), _class2.prototype), _class2);
  class ItemList extends _component.default {}
  var _default = _exports.default = ItemList;
});