define("plutof/services/ajax", ["exports", "@ember/application", "ember-ajax/services/ajax", "plutof/adapters/auth", "plutof/config/environment"], function (_exports, _application, _ajax, _auth, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ajaxFromControlled = ajaxFromControlled;
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Ajax extends _ajax.default.extend(_auth.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "host", _environment.default.API_HOST);
      _defineProperty(this, "contentType", 'application/json; charset=utf-8');
    } // Uncomment for local dev. Somehow this line breaks CI test
    // trustedHosts = [(new URL(Config.PUBLISHING_API_HOST)).hostname];
  }

  // Transitionary thing, ideally we want to pass ajax explicitly
  function ajaxFromControlled(object) {
    const owner = (0, _application.getOwner)(object);
    return owner.lookup('service:ajax');
  }
  var _default = _exports.default = Ajax;
});