define("plutof/components/filerepository/view/items", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @pagination={{@items}}
      @title={{i18n-t "FileRepository.linkedObjects"}}
  >
      <RelatedObjects::Content @pagination={{@items}} as |Field item|>
          <Field @label={{i18n-t "FileRepository.type"}}>
              {{item.content_type_name}}
          </Field>
  
          <Field @label={{i18n-t "FileRepository.object"}}>
              <GenericViews::PojoRecordLink
                  @contentType={{item.new_content_type}}
                  @id={{item.object_id}}
                  @name={{item.object_name}} />
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "DXP55VER",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@title\"],[[30,1],[28,[37,1],[\"FileRepository.linkedObjects\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"FileRepository.type\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"content_type_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"FileRepository.object\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@contentType\",\"@id\",\"@name\"],[[30,3,[\"new_content_type\"]],[30,3,[\"object_id\"]],[30,3,[\"object_name\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@items\",\"Field\",\"item\"],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"generic-views/pojo-record-link\"]]",
    "moduleName": "plutof/components/filerepository/view/items.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});