define("plutof/components/common/radio-group", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let RadioGroup = (_dec = (0, _component2.classNames)('radio-group'), _dec(_class = class RadioGroup extends _component.default {}) || _class);
  var _default = _exports.default = RadioGroup;
});