define("plutof/components/annotation/bulk/sections/area", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Sample.samplingArea"}}>
      <Layout::RegularGrid @columns={{2}}>
          <Annotation::Bulk::Field @field={{@fields.country}} as |field|>
              <AutoComplete::Model
                  @model="geography/country"
                  @value={{field.value}}
                  @update={{field.update}}
                  @disabled={{field.disabled}} />
          </Annotation::Bulk::Field>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "1bKhaoQy",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Sample.samplingArea\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@field\"],[[30,1,[\"country\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@model\",\"@value\",\"@update\",\"@disabled\"],[\"geography/country\",[30,2,[\"value\"]],[30,2,[\"update\"]],[30,2,[\"disabled\"]]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@fields\",\"field\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"annotation/bulk/field\",\"auto-complete/model\"]]",
    "moduleName": "plutof/components/annotation/bulk/sections/area.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});