define("plutof/components/measurements/measurement-view/expanded", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@measurement}}
      class="expanded-measurement-view"
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          {{#view.attr "value"}}
              {{@measurement.stringValue}}
          {{/view.attr}}
  
          {{view.attr "method_desc"}}
          {{view.attr "remarks"}}
          {{view.attr "warnings"}}
  
          {{#view.attr "determined_at"}}
              {{formatted-date @measurement.determined_at}}
          {{/view.attr}}
  
          {{view.link "determined_by" route="person.view"}}
  
          {{view.attr "aggregate_measure"}}
          {{view.attr "statistical_method"}}
          {{view.attr "individual_count"}}
          {{view.attr "dispersion"}}
          {{view.attr "measurement_value_min"}}
          {{view.attr "measurement_value_max"}}
          {{view.attr "measurement_accuracy"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "BJIbapQ+",
    "block": "[[[8,[39,0],[[24,0,\"expanded-measurement-view\"]],[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"value\"],null,[[\"default\"],[[[[1,\"            \"],[1,[30,1,[\"stringValue\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"method_desc\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"remarks\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"warnings\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"determined_at\"],null,[[\"default\"],[[[[1,\"            \"],[1,[28,[35,2],[[30,1,[\"determined_at\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[28,[30,2,[\"link\"]],[\"determined_by\"],[[\"route\"],[\"person.view\"]]]],[1,\"\\n\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"aggregate_measure\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"statistical_method\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"individual_count\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"dispersion\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"measurement_value_min\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"measurement_value_max\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"measurement_accuracy\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@measurement\",\"view\"],false,[\"generic-views/record\",\"layout/regular-grid\",\"formatted-date\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/expanded.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});