define("plutof/templates/components/file-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nfden2h+",
    "block": "[[[10,1],[15,0,[29,[[30,0,[\"icon\"]]]]],[12],[13],[1,\"\\n\\n\"],[41,[33,1],[[[1,\"    \"],[1,[28,[35,2],[[33,1]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"name\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/file-selector.hbs",
    "isStrictMode": false
  });
});