define("plutof/mixins/record-reset", ["exports", "@ember/object", "@ember/object/mixin", "rsvp", "plutof/utils/structures"], function (_exports, _object, _mixin, _rsvp, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function discardChanges(record) {
    if (!record || record.isNew) {
      return;
    }
    record.rollbackAttributes();
    return record.reload();
  }
  const RecordResetMixin = _mixin.default.create({
    recordsToUnload: [],
    unloadRecords() {
      return _rsvp.default.all(this.recordsToUnload.map(({
        record,
        unloadSelf = true,
        subRecords = []
      }) => {
        const primary = (0, _object.get)(this.model, record);
        let tobeUnloaded = [];

        // True by default
        if (unloadSelf !== false) {
          tobeUnloaded.push(primary);
        }
        subRecords.forEach(relation => {
          (0, _structures.makeArray)(primary.get(relation)).forEach(record => tobeUnloaded.push(record));
        });
        return _rsvp.default.all(tobeUnloaded.map(async record => {
          await discardChanges(await record);
        }));
      }));
    }
  });
  var _default = _exports.default = RecordResetMixin;
});