define("plutof/components/study/view/area-tree/pagination", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.loading}}
      <li class="area-tree__child">
          <div class="area-tree__vertical-notch"></div>
          <div class="area-tree__horizontal-notch"></div>
  
          <div class="area-tree__node">
              <span class="loading-icon"></span>
              {{i18n-t "General.loading"}}...
          </div>
      </li>
  {{else if (not @pagination.onLastPage)}}
      <li class="area-tree__child">
          <div class="area-tree__vertical-notch"></div>
          <div class="area-tree__horizontal-notch"></div>
  
          <button {{on "click" this.loadMore}} class="btn-reset area-tree__node area-tree__node--action">
              {{i18n-t "General.loadMore"}}
          </button>
      </li>
  {{/if}}
  
  */
  {
    "id": "XQ0KS13o",
    "block": "[[[41,[30,0,[\"loading\"]],[[[1,\"    \"],[10,\"li\"],[14,0,\"area-tree__child\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"area-tree__vertical-notch\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"area-tree__node\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,1],[\"General.loading\"],null]],[1,\"...\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"onLastPage\"]]],null],[[[1,\"    \"],[10,\"li\"],[14,0,\"area-tree__child\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"area-tree__vertical-notch\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[1,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn-reset area-tree__node area-tree__node--action\"],[4,[38,3],[\"click\",[30,0,[\"loadMore\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.loadMore\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"@pagination\"],false,[\"if\",\"i18n-t\",\"not\",\"on\"]]",
    "moduleName": "plutof/components/study/view/area-tree/pagination.hbs",
    "isStrictMode": false
  });
  let AreaTreePagination = (_dec = (0, _object.computed)('args.pagination', 'args.pagination.isLoading'), _class = class AreaTreePagination extends _component2.default {
    get loading() {
      return !this.args.pagination || this.args.pagination.isLoading;
    }
    loadMore() {
      this.args.pagination.nextPage();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loading", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaTreePagination);
});