define("plutof/components/access/edit/only-is-public", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.access"}}
  >
      <Common::LocalLabel @label="Access.canView" />
  
      <Access::Edit::PublicOrPrivate
          @value={{@isPublic}}
          @update={{@updateIsPublic}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "sdRY/Pq4",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\"],[\"Access.canView\"]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@value\",\"@update\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@isPublic\",\"@updateIsPublic\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"common/local-label\",\"access/edit/public-or-private\"]]",
    "moduleName": "plutof/components/access/edit/only-is-public.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});