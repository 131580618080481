define("plutof/components/bio-status", ["exports", "@glimmer/component", "@ember/service", "plutof/utils/model"], function (_exports, _component, _service, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const STATUS_MODEL = 'taxonoccurrence.referencebased.speciesstatus';
  let BioStatus = (_dec = (0, _model.choices)(`${STATUS_MODEL}.origin`), _dec2 = (0, _model.choices)(`${STATUS_MODEL}.presence`), _dec3 = (0, _model.choices)(`${STATUS_MODEL}.persistence`), _dec4 = (0, _model.choices)(`${STATUS_MODEL}.distribution`), _dec5 = (0, _model.choices)(`${STATUS_MODEL}.abundance`), _dec6 = (0, _model.choices)(`${STATUS_MODEL}.trend`), _dec7 = (0, _model.choices)(`${STATUS_MODEL}.rate_of_spread`), _dec8 = (0, _model.choices)(`${STATUS_MODEL}.harmful`), _dec9 = (0, _model.choices)(`${STATUS_MODEL}.regulatory_listing`), _class = class BioStatus extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "originChoices", _descriptor2, this);
      _initializerDefineProperty(this, "presenceChoices", _descriptor3, this);
      _initializerDefineProperty(this, "persistenceChoices", _descriptor4, this);
      _initializerDefineProperty(this, "distributionChoices", _descriptor5, this);
      _initializerDefineProperty(this, "abundanceChoices", _descriptor6, this);
      _initializerDefineProperty(this, "trendChoices", _descriptor7, this);
      _initializerDefineProperty(this, "rateOfSpreadChoices", _descriptor8, this);
      _initializerDefineProperty(this, "harmfulChoices", _descriptor9, this);
      _initializerDefineProperty(this, "regulatoryListingChoices", _descriptor10, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originChoices", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "presenceChoices", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "persistenceChoices", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "distributionChoices", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "abundanceChoices", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "trendChoices", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rateOfSpreadChoices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "harmfulChoices", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "regulatoryListingChoices", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = BioStatus;
});