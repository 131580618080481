define("plutof/helpers/merge-params", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function ([overrides, defaults]) {
    if (!overrides) {
      return defaults;
    }
    return Object.assign({}, defaults, overrides);
  });
});