define("plutof/templates/components/experiment/common/laboratory-autocomplete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aImQhtT1",
    "block": "[[[8,[39,0],[[17,1]],[[\"@backend\",\"@value\",\"@update\",\"@params\"],[[30,0,[\"backend\"]],[30,2],[30,3],[28,[37,1],[[30,4],[28,[37,2],null,[[\"sideAction\",\"sideActionIcon\"],[[30,0,[\"selectDefault\"]],[28,[37,3],[\"molecular-lab\"],null]]]]],null]]],null],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@update\",\"@params\"],false,[\"auto-complete/base\",\"merge-params\",\"hash\",\"icon\"]]",
    "moduleName": "plutof/templates/components/experiment/common/laboratory-autocomplete.hbs",
    "isStrictMode": false
  });
});