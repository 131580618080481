define("plutof/templates/components/occurrences-table/-responsive-row/expand-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jmeaA9TC",
    "block": "[[[10,0],[14,0,\"occ-table-responsive-row__expand-label-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"path\"],[\"referencebased.occurrence.taxon_node\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn-fake-link occ-table-responsive-row__expand-label-button\"],[4,[38,1],[[30,0],[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"fas fa-ellipsis-h\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@toggle\"],false,[\"plutof-labelc\",\"action\"]]",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/expand-label.hbs",
    "isStrictMode": false
  });
});