define("plutof/templates/components/long-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sYrE51V2",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"    \"],[11,0],[16,0,[29,[\"long-text \",[52,[30,0,[\"shortened\"]],\"long-text--shortened\"],\" \",[30,2]]]],[4,[38,1],[\"click\",[30,0,[\"toggleFullText\"]]],null],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"     \\n\"]],[]]]],[\"@text\",\"@text-height\"],false,[\"if\",\"on\"]]",
    "moduleName": "plutof/templates/components/long-text.hbs",
    "isStrictMode": false
  });
});