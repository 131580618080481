define("plutof/templates/event/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "JAz0P6DS",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"event.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\"],[[28,[37,2],[\"event\"],null],[28,[37,3],[\"SamplingEvent.add\"],null],[30,0,[\"model\",\"event\"]],[28,[37,4],[\"triggerReset\"],null]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@event\",\"@habitat\",\"@measurementsData\",\"@gatheringAgents\",\"@habitatMeasurementsData\",\"@associatedData\",\"@validationContext\",\"@save\"],[[30,0,[\"model\",\"event\"]],[30,0,[\"model\",\"habitat\"]],[30,0,[\"model\",\"measurementsData\"]],[30,0,[\"model\",\"gatheringAgents\"]],[30,0,[\"model\",\"habitatMeasurementsData\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"validationContext\"]],[30,0,[\"save\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"event/edit\"]]",
    "moduleName": "plutof/templates/event/add.hbs",
    "isStrictMode": false
  });
});