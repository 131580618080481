define("plutof/components/agent/view/organization-workgroup", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/mixin", "@ember/service", "ember-concurrency", "plutof/config/environment", "plutof/misc/abstract", "plutof/models/users/usergroup", "plutof/utils/group", "plutof/utils/pagination", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _mixin, _service, _emberConcurrency, _environment, _abstract, _usergroup, _group, _pagination, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! XXX }}
  {{on-change this.loadWorkgroupData @workgroupId}}
  
  <Profile::Groups::ManagingGroup::ViewBase
      @workgroupId={{@workgroupId}}
      @acceptedMembers={{this.acceptedMembers}}
      @pendingMembers={{this.pendingMembers}}
      @status={{this.status}}
      {{! extra action wrapper because of the bug where async actions are treated as falsy in templates }}
      @leave={{action this.leave}}
      @includeModerationRights={{@includeModerationRights}} />
  
  */
  {
    "id": "fOE0Fd2W",
    "block": "[[[1,[28,[35,0],[[30,0,[\"loadWorkgroupData\"]],[30,1]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@workgroupId\",\"@acceptedMembers\",\"@pendingMembers\",\"@status\",\"@leave\",\"@includeModerationRights\"],[[30,1],[30,0,[\"acceptedMembers\"]],[30,0,[\"pendingMembers\"]],[30,0,[\"status\"]],[28,[37,2],[[30,0],[30,0,[\"leave\"]]],null],[30,2]]],null],[1,\"\\n\"]],[\"@workgroupId\",\"@includeModerationRights\"],false,[\"on-change\",\"profile/groups/managing-group/view-base\",\"action\"]]",
    "moduleName": "plutof/components/agent/view/organization-workgroup.hbs",
    "isStrictMode": false
  });
  const organizationMembersMixin = _mixin.default.create({
    constructQuery(number) {
      let query = Object.assign({
        'page[number]': number,
        'page[size]': this.pageSize
      }, this.filters);
      return query;
    },
    loadPage(number) {
      const query = this.constructQuery(number);
      const url = (0, _abstract.construct_request)(this.endpoint, query);
      return this.ajax.request(url);
    }
  });
  function paginateOrganizationMembers(ajax, organizationId, filters, endpoint) {
    const completeEndpoint = `${_environment.default.API_HOST}/${endpoint}/${organizationId}/members/`;
    const mixins = [organizationMembersMixin];
    return (0, _pagination.paginateJSONAPI)(ajax, completeEndpoint, {
      filters,
      mixins
    });
  }
  function paginatePendingMembers(ajax, organizationId, endpoint) {
    const filters = {
      status: 0
    };
    return paginateOrganizationMembers(ajax, organizationId, filters, endpoint);
  }
  function paginateAcceptedMembers(ajax, organizationId, endpoint) {
    const filters = {
      status__gt: 0,
      ordering: '-status'
    };
    return paginateOrganizationMembers(ajax, organizationId, filters, endpoint);
  }
  let OrganizationViewWorkgroup = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OrganizationViewWorkgroup extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "groupMembership", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "status", _descriptor4, this);
      _initializerDefineProperty(this, "inWorkgroup", _descriptor5, this);
      _initializerDefineProperty(this, "isModerator", _descriptor6, this);
      _initializerDefineProperty(this, "acceptedMembers", _descriptor7, this);
      _initializerDefineProperty(this, "pendingMembers", _descriptor8, this);
      _initializerDefineProperty(this, "emailDialogOpen", _descriptor9, this);
      _initializerDefineProperty(this, "fetchWorkgroupData", _descriptor10, this);
      _initializerDefineProperty(this, "fetchAcceptedMembers", _descriptor11, this);
      _initializerDefineProperty(this, "fetchPendingMembers", _descriptor12, this);
    }
    loadWorkgroupData() {
      this.fetchWorkgroupData.perform();
    }
    async leave() {
      try {
        await this.groupMembership.leaveGroup(this.args.workgroupId);
      } catch (err) {
        (0, _notifications.reportError)(err);
        throw err;
      }
      this.loadWorkgroupData();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupMembership", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inWorkgroup", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isModerator", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "acceptedMembers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pendingMembers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "emailDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fetchWorkgroupData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const status = yield (0, _group.statusInWorkgroup)(this.store, this.args.workgroupId);
        this.status = status;
        this.inWorkgroup = status > _usergroup.UserStatus.Unverified;
        this.isModerator = status > _usergroup.UserStatus.ReadWrite;
        if (this.inWorkgroup) {
          this.fetchPendingMembers.perform();
        } else {
          this.pendingMembers = _pagination.EmptyPagination.create();
        }
        this.fetchAcceptedMembers.perform();
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchAcceptedMembers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.acceptedMembers = yield paginateAcceptedMembers(this.ajax, this.args.organizationId, this.args.endpoint);
      };
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchPendingMembers", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.pendingMembers = yield paginatePendingMembers(this.ajax, this.args.organizationId, this.args.endpoint);
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadWorkgroupData", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadWorkgroupData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "leave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "leave"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OrganizationViewWorkgroup);
});