define("plutof/templates/components/common/-radio-group-choice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Qa0tKgqh",
    "block": "[[[8,[39,0],[[16,\"disabled\",[30,1]]],[[\"@oneWay\",\"@selection\",\"@changed\",\"@option\"],[true,[30,2],[28,[37,1],[[30,0],[30,0,[\"selected\"]]],null],[30,3]]],null],[1,\"\\n\\n\"],[18,4,null],[1,\"\\n\"]],[\"@disable\",\"@active\",\"@name\",\"&default\"],false,[\"radio-button\",\"action\",\"yield\"]]",
    "moduleName": "plutof/templates/components/common/-radio-group-choice.hbs",
    "isStrictMode": false
  });
});