define("plutof/components/tables/bulk-update/update-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class="btn btn-default plutof-btn-green form-control" {{on "click" @update}}>
      <span class="fas fa-check"></span>
      {{i18n-t "General.tableBulkUpdate.update"}}
  </button>
  
  */
  {
    "id": "mNv5e33a",
    "block": "[[[11,\"button\"],[24,0,\"btn btn-default plutof-btn-green form-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"fas fa-check\"],[12],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"General.tableBulkUpdate.update\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@update\"],false,[\"on\",\"i18n-t\"]]",
    "moduleName": "plutof/components/tables/bulk-update/update-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});