define("plutof/components/search/filter-panels/observation-properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
      data-panel-name="default-properties"
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.study}} />
          <Search::FilterInput @filter={{@filterInputs.mainform}} />
          <Search::FilterInput @filter={{@filterInputs.references}} />
          <Search::FilterInput @filter={{@filterInputs.living_specimen}} @filters={{@filterInputs}} />
          <Search::GbifFilterInput @filter={{@filterInputs.gbif_dataset}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
  
          {{! Range input }}
          <Search::FilterInput @filter={{@filterInputs.individual_count}} @filters={{@filterInputs}} />
  
          <Search::FilterInput
              @filter={{@filterInputs.verifier}}
              @autocompletePreset="users/user"
              @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "TiprTGbU",
    "block": "[[[8,[39,0],[[24,\"data-panel-name\",\"default-properties\"]],[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"study\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"mainform\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"references\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\",\"@filters\"],[[30,1,[\"living_specimen\"]],[30,1]]],null],[1,\"\\n        \"],[8,[39,5],null,[[\"@filter\"],[[30,1,[\"gbif_dataset\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"keywords\"]]]],null],[1,\"\\n\\n\"],[1,\"        \"],[8,[39,4],null,[[\"@filter\",\"@filters\"],[[30,1,[\"individual_count\"]],[30,1]]],null],[1,\"\\n\\n        \"],[8,[39,4],null,[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[30,1,[\"verifier\"]],\"users/user\",[28,[37,1],[\"Agents.personTooltip\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"search/filter-panels/common-properties/agents\",\"layout/regular-grid\",\"search/filter-input\",\"search/gbif-filter-input\",\"search/filter-panels/common-properties/checkboxes\"]]",
    "moduleName": "plutof/components/search/filter-panels/observation-properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});