define("plutof/components/publishing/gbif/bibliography-row-form", ["exports", "@ember/component", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _component2, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'citation.citation': (0, _emberCpValidations.validator)('presence', true)
  });
  let BibliographyRowForm = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class BibliographyRowForm extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "citation", null);
    }
  }) || _class);
  var _default = _exports.default = BibliographyRowForm;
});