define("plutof/components/layout/-module-hub/link-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{@route}} @class="btn btn-default">
      <span class={{@icon}}></span>
      {{@title}}
  </LinkTo>
  
  */
  {
    "id": "uTBlO4Jo",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@class\"],[[30,1],\"btn btn-default\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[30,2]],[12],[13],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@route\",\"@icon\",\"@title\"],false,[\"link-to\"]]",
    "moduleName": "plutof/components/layout/-module-hub/link-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});