define("plutof/components/layout/-module-hub/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="hub-column">
      {{! Unstyled divs for flex grouping purposes }}
      <div>
          <h4>{{@title}}</h4>
  
          <p class="hub-column__description">
              {{@description}}
          </p>
      </div>
  
      <div>
          {{#if (has-block)}}
              {{yield}}
          {{else}}
              <Layout::-ModuleHub::LinkButton
                  @route={{@route}}
                  @icon={{@icon}}
                  @title={{default-to @buttonTitle @title}} />
          {{/if}}
      </div>
  </div>
  
  */
  {
    "id": "/FZYcph9",
    "block": "[[[10,0],[14,0,\"hub-column\"],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[10,\"h4\"],[12],[1,[30,1]],[13],[1,\"\\n\\n        \"],[10,2],[14,0,\"hub-column__description\"],[12],[1,\"\\n            \"],[1,[30,2]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"            \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,3],null,[[\"@route\",\"@icon\",\"@title\"],[[30,3],[30,4],[28,[37,4],[[30,5],[30,1]],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@description\",\"@route\",\"@icon\",\"@buttonTitle\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"layout/-module-hub/link-button\",\"default-to\"]]",
    "moduleName": "plutof/components/layout/-module-hub/link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});