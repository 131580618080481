define("plutof/components/layer/occurrence-areas", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "rsvp", "plutof/config/environment", "plutof/utils/reflection", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _rsvp, _environment, _reflection, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LinkedLayersModel = void 0;
  var _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Layer.layers"}}>
      <Layout::SeparatedRow>
          <Layer::AutoComplete
              @value={{this.layer}}
              @update={{this.updateLayer}}
              @params={{hash
                  clearQueryOnEmptyValue=true
                  placeholder=(i18n-t "Layer.layer")
              }} />
  
          <AutoComplete::Model
              @model="geography/layer-area"
              @update={{this.linkArea}}
              @filters={{hash layer=this.layer.id}}
              @disabled={{not this.layer}}
              @params={{hash placeholder=(i18n-t "Layer.layerArea")}} />
      </Layout::SeparatedRow>
  
      <div class="spacer-below"></div>
  
      <Tables::List @records={{@model.areas}} as |Field area|>
          <Field @label={{i18n-t "Layer.layer"}}>
              {{area.layer.name}}
          </Field>
  
          <Field @label={{i18n-t "Layer.layerArea"}}>
              {{area.area}}
  
              <Layer::OccurrenceAreas::ConstraintsCheck
                  @layerArea={{area}}
                  @samplingArea={{@samplingArea}} />
          </Field>
  
          <Field>
              <Tables::RemoveButton @clicked={{fn @model.unlinkArea area}} />
          </Field>
      </Tables::List>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "/YBUwWfb",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Layer.layers\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@value\",\"@update\",\"@params\"],[[30,0,[\"layer\"]],[30,0,[\"updateLayer\"]],[28,[37,4],null,[[\"clearQueryOnEmptyValue\",\"placeholder\"],[true,[28,[37,1],[\"Layer.layer\"],null]]]]]],null],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@model\",\"@update\",\"@filters\",\"@disabled\",\"@params\"],[\"geography/layer-area\",[30,0,[\"linkArea\"]],[28,[37,4],null,[[\"layer\"],[[30,0,[\"layer\",\"id\"]]]]],[28,[37,6],[[30,0,[\"layer\"]]],null],[28,[37,4],null,[[\"placeholder\"],[[28,[37,1],[\"Layer.layerArea\"],null]]]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacer-below\"],[12],[13],[1,\"\\n\\n    \"],[8,[39,7],null,[[\"@records\"],[[30,1,[\"areas\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"Layer.layer\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"layer\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"Layer.layerArea\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"area\"]]],[1,\"\\n\\n            \"],[8,[39,8],null,[[\"@layerArea\",\"@samplingArea\"],[[30,3],[30,4]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,9],null,[[\"@clicked\"],[[28,[37,10],[[30,1,[\"unlinkArea\"]],[30,3]],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@model\",\"Field\",\"area\",\"@samplingArea\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/separated-row\",\"layer/auto-complete\",\"hash\",\"auto-complete/model\",\"not\",\"tables/list\",\"layer/occurrence-areas/constraints-check\",\"tables/remove-button\",\"fn\"]]",
    "moduleName": "plutof/components/layer/occurrence-areas.hbs",
    "isStrictMode": false
  });
  // This has to serve for observations.add too, with its multiple observations
  // per model, so it's a bit a different
  //
  // areas :: [{ id :: int, area :: str, layer :: { id:: str, name :: str }}]
  // records :: [Occurrence]
  // items :: { occurrence URL -> layer area id -> item id :: number? }
  let LinkedLayersModel = _exports.LinkedLayersModel = (_class = class LinkedLayersModel {
    constructor({
      ajax,
      areas,
      items
    }) {
      _defineProperty(this, "toDelete", new Set());
      this.ajax = ajax;
      this.areas = areas;
      this.items = items;
    }
    static create(ajax) {
      return new LinkedLayersModel({
        ajax,
        areas: [],
        items: {}
      });
    }
    static async load(ajax, occurrence) {
      const recordURL = (0, _reflection.get_record_url)(occurrence);
      const response = await ajax.request(`${recordURL}layers/`);
      const areas = [];
      const items = {
        [recordURL]: {}
      };
      for (const item of response) {
        const areaID = parseInt((0, _reflection.recordURLToID)(item.area));
        areas.push({
          id: areaID,
          area: item.area_name,
          geom: item.geom,
          layer: {
            id: (0, _reflection.recordURLToID)(item.layer),
            name: item.layer_name
          }
        });
        items[recordURL][areaID] = item.id;
      }
      return new LinkedLayersModel({
        ajax,
        areas,
        items
      });
    }
    static async clone({
      ajax,
      templateOccurrence
    }) {
      const source = await LinkedLayersModel.load(ajax, templateOccurrence);
      const cloned = LinkedLayersModel.create(ajax);
      cloned.areas = source.areas.slice();
      return cloned;
    }
    getAreaForLayer(layer) {
      // Using name for this might be brittle, but it's what we have
      return this.areas.find(area => area.layer.id === layer.id);
    }
    async linkArea(areaRecord) {
      if (this.areas.some(area => area.id === areaRecord.id)) {
        return;
      }
      const layer = await areaRecord.layer;
      this.areas.pushObject({
        id: parseInt(areaRecord.id),
        area: areaRecord.name,
        geom: areaRecord.geom,
        layer: {
          id: layer.id,
          name: layer.name
        }
      });
    }
    unlinkArea(area) {
      this.areas.removeObject(area);
      for (const [occurrenceURL, occurrenceItems] of Object.entries(this.items)) {
        const itemID = occurrenceItems[area.id];
        if (itemID) {
          this.toDelete.add(`${occurrenceURL}layers/${itemID}/`);
        }
        occurrenceItems[area.id] = null;
      }
    }
    async _saveItem(occurrence, area) {
      const url = (0, _reflection.get_record_url)(occurrence);
      if (!this.items[url]) {
        this.items[url] = {};
      }
      if (!this.items[url][area.id]) {
        const response = await this.ajax.post(`${url}layers/`, {
          data: {
            area: `${_environment.default.API_HOST}/geography/layer-areas/${area.id}/`
          }
        });
        this.items[url][area.id] = response.id;
      }
    }
    async save(occurrences) {
      await _rsvp.default.Promise.all(Array.from(this.toDelete).map(url => this.ajax.delete(url)));
      this.toDelete.clear();
      await _rsvp.default.Promise.all(occurrences.map(occurrence => {
        return _rsvp.default.Promise.all(this.areas.map(area => this._saveItem(occurrence, area)));
      }));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "unlinkArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unlinkArea"), _class.prototype), _class);
  let LinkedLayers = _exports.default = (_class2 = class LinkedLayers extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "layer", _descriptor2, this);
    }
    linkArea(area) {
      if (!area) {
        return;
      }
      this.args.model.linkArea(area);
      this.layer = null;
    }
    updateLayer(layer) {
      if (layer === null) {
        this.layer = null;
        return;
      }
      const existingArea = this.args.model.getAreaForLayer(layer);
      if (existingArea) {
        (0, _notifications.displayNotification)('error', this.i18n.translate('Layer.info.oneAreaPerLayerError', {
          hash: {
            area: existingArea.area,
            layer: layer.name
          }
        }), null, null, false // scrollToTop
        );
      } else {
        this.layer = layer;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "layer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "linkArea", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "linkArea"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateLayer", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateLayer"), _class2.prototype), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LinkedLayers);
});