define("plutof/mixins/occurrence_row_mixin", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/utils"], function (_exports, _object, _computed, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This mixin holds states of observation and msample validity
  var OccurrenceRowMixin = _mixin.default.create({
    measurementsAreEmpty: (0, _object.computed)('measurementsData.@each.value', function () {
      var measurementsData = this.measurementsData;
      if ((0, _utils.isNone)(measurementsData)) {
        return false;
      }
      return measurementsData.get('objectMeasurements').mapBy('value').every(_utils.isEmpty);
    }),
    occurrenceRowIsEmpty: (0, _computed.and)('measurementsAreEmpty', 'defaultValuesAreEmpty')
  });
  var _default = _exports.default = OccurrenceRowMixin;
});