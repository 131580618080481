define("plutof/components/access/-view-rights/edit-private", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      {{plutof-labelc path="Access.canEdit" source="local"}}
  
      <p>
          <span class={{icon "private"}}></span>
          {{i18n-t "Access.private"}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "wakLniWX",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"path\",\"source\"],[\"Access.canEdit\",\"local\"]]]],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,2],[\"private\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,3],[\"Access.private\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"access/-view-rights/column\",\"plutof-labelc\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/access/-view-rights/edit-private.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});