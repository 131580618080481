define("plutof/components/experiment/common/time-input", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component"], function (_exports, _component, _object, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TimeInput = (_dec = (0, _component2.classNames)('minutes-seconds'), _dec2 = (0, _object.computed)('inputClass'), _dec(_class = (_class2 = class TimeInput extends _component.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let minutes = 0;
      let seconds = 0;
      if (!isNaN(this.value)) {
        minutes = Math.floor(this.value / 60);
        seconds = this.value % 60;
      }
      this.setProperties({
        minutes,
        seconds
      });
    }
    get inputClasses() {
      return (0, _utils.isNone)(this.inputClass) ? 'form-control minutes-seconds__input' : this.inputClass;
    }
    updateMinutes(minutesString) {
      const minutes = parseInt(minutesString);
      if (!isNaN(minutes)) {
        this.set('value', minutes * 60 + this.seconds);
      }
    }
    updateSeconds(secondsString) {
      const seconds = parseInt(secondsString);
      if (!isNaN(seconds)) {
        this.set('value', this.minutes * 60 + seconds);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "inputClasses", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "inputClasses"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateMinutes", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateMinutes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateSeconds", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateSeconds"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TimeInput;
});