define("plutof/templates/components/occurrences-table/responsive-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "201vYtO2",
    "block": "[[[18,1,[[28,[37,1],null,[[\"cells\",\"cell\",\"actions\",\"expand-label\",\"number\",\"selector\"],[[50,\"occurrences-table/-responsive-row/cells\",0,null,null],[50,\"occurrences-table/-responsive-row/cell\",0,null,null],[50,\"occurrences-table/-responsive-row/actions\",0,null,null],[50,\"occurrences-table/-responsive-row/expand-label\",0,null,null],[50,\"occurrences-table/-responsive-row/row-number\",0,null,null],[50,\"occurrences-table/-responsive-row/selector\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/occurrences-table/responsive-row.hbs",
    "isStrictMode": false
  });
});