define("plutof/templates/components/livingspecimen/strain-isolation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "3iyK2Mxz",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"LivingCultures.panelIsolation\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[8,[39,4],null,[[\"@path\"],[\"strain.isolated_by\"]],null],[1,\"\\n\\n\"],[6,[39,5],[[30,1,[\"isolated_by\"]]],null,[[\"default\"],[[[[1,\"                    \"],[8,[39,6],null,[[\"@update\",\"@value\"],[[30,5],[30,4]]],null],[1,\"\\n\"]],[4,5]]]]],[1,\"            \"],[13],[1,\"\\n\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"isolation_date\"],null]],[1,\"\\n\\n\"],[6,[30,3,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"                \"],[8,[39,7],null,[[\"@path\"],[\"strain.isolated_in\"]],null],[1,\"\\n\\n\"],[6,[39,5],[[30,1,[\"isolated_in\"]]],null,[[\"default\"],[[[[1,\"                    \"],[8,[39,8],null,[[\"@update\",\"@value\"],[[30,7],[30,6]]],null],[1,\"\\n\"]],[6,7]]]]]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"isolation_method\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"isolated_at\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"field\"]],[\"isolation_remarks\"],null,[[\"default\"],[[[[1,\"                \"],[8,[39,9],[[24,0,\"form-control\"]],[[\"@value\"],[[30,1,[\"isolation_remarks\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"isolation_monosporic_strains\"],null]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@strain\",\"form\",\"grid\",\"value\",\"update\",\"value\",\"update\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-forms/record\",\"layout/regular-grid\",\"person-label-tooltip\",\"async/bind-relation\",\"agent/person/auto-complete\",\"organization-label-tooltip\",\"agent/organization/auto-complete\",\"flexible-textarea\"]]",
    "moduleName": "plutof/templates/components/livingspecimen/strain-isolation.hbs",
    "isStrictMode": false
  });
});