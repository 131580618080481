define("plutof/components/annotation/summary", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/annotation/format"], function (_exports, _component, _templateFactory, _component2, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "annotation.summary.title"}}
      class="annotation-summary"
  >
      <div>
          {{i18n-t "annotation.summary.annotatorStatus"
              annotator=@annotation.author.representation
              date=(formatted-date @annotation.annotated_at)}}
  
          <p>
              {{@annotation.comment}}
          </p>
      </div>
  
      {{#if @annotation.moderated_at}}
          <Annotation::-Summary::ModerationStatus @annotation={{@annotation}} />
      {{/if}}
  
      {{component this.format.components.view annotation=@annotation.annotation}}
  </PlutofPanel::Simple>
  
  <Annotation::-Summary::Log
      @annotation={{@annotation}}
      @canComment={{@permissions.canComment}} />
  
  */
  {
    "id": "DOUSGK6h",
    "block": "[[[8,[39,0],[[24,0,\"annotation-summary\"]],[[\"@title\"],[[28,[37,1],[\"annotation.summary.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"annotation.summary.annotatorStatus\"],[[\"annotator\",\"date\"],[[30,1,[\"author\",\"representation\"]],[28,[37,2],[[30,1,[\"annotated_at\"]]],null]]]]],[1,\"\\n\\n        \"],[10,2],[12],[1,\"\\n            \"],[1,[30,1,[\"comment\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"moderated_at\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@annotation\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[46,[30,0,[\"format\",\"components\",\"view\"]],null,[[\"annotation\"],[[30,1,[\"annotation\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@annotation\",\"@canComment\"],[[30,1],[30,2,[\"canComment\"]]]],null],[1,\"\\n\"]],[\"@annotation\",\"@permissions\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"formatted-date\",\"if\",\"annotation/-summary/moderation-status\",\"component\",\"annotation/-summary/log\"]]",
    "moduleName": "plutof/components/annotation/summary.hbs",
    "isStrictMode": false
  });
  class AnnotationSummary extends _component2.default {
    get format() {
      return _format.AnnotationFormatImplementations[this.args.annotation.format];
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnotationSummary);
});