define("plutof/components/photobank/view/classifications", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @title={{i18n-t "Photobank.classifications"}}
      @pagination={{@items}}
      data-test="Photobank::View::Classifications"
  >
      <RelatedObjects::Content @pagination={{@items}} as |Field item|>
          <Field @label={{backend-translations/label "customlistitem.custom_classification"}}>
              {{item.custom_list_item.customClassificationyDisplayName}}
          </Field>
  
          <Field @label={{backend-translations/label "customitem.custom_list_item"}}>
              {{item.custom_list_item.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "customitem.remarks"}}>
              {{item.remarks}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "KCuskZfq",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Photobank::View::Classifications\"]],[[\"@title\",\"@pagination\"],[[28,[37,1],[\"Photobank.classifications\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"customlistitem.custom_classification\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"custom_list_item\",\"customClassificationyDisplayName\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"customitem.custom_list_item\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"custom_list_item\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"customitem.remarks\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"remarks\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@items\",\"Field\",\"item\"],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"backend-translations/label\"]]",
    "moduleName": "plutof/components/photobank/view/classifications.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});