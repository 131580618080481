define("plutof/components/related-objects/content/photobank-objects", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field pbo|>
      <Field @label={{i18n-t "Photobank.colID"}}>
          <LinkTo @route="photobank.view" @model={{pbo.id}}>
              {{pbo.object_idprim}}
          </LinkTo>
      </Field>
  
      <Field @label={{i18n-t "Photobank.colTitle"}}>
          {{pbo.title}}
      </Field>
  
      <Field @label={{i18n-t "Photobank.colCategory"}}>
          {{pbo.category}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date pbo.updated_at}}
      </Field>
  
      <Field @label={{i18n-t "PlutofListViewColumn.clipboard"}}>
          <RelatedObjects::ClipboardStatus
              @onClipboard={{pbo.on_clipboard}}
              @recordID={{pbo.id}}
              @clipboard="photobanks" />
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "mtT8Q/8A",
    "block": "[[[8,[39,0],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"Photobank.colID\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"photobank.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"object_idprim\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"Photobank.colTitle\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"title\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"Photobank.colCategory\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"category\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,3,[\"updated_at\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"PlutofListViewColumn.clipboard\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@onClipboard\",\"@recordID\",\"@clipboard\"],[[30,3,[\"on_clipboard\"]],[30,3,[\"id\"]],\"photobanks\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@pagination\",\"Field\",\"pbo\"],false,[\"related-objects/content\",\"i18n-t\",\"link-to\",\"formatted-date\",\"related-objects/clipboard-status\"]]",
    "moduleName": "plutof/components/related-objects/content/photobank-objects.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});