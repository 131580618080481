define("plutof/templates/components/filerepository/select-image", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hEZaYxXW",
    "block": "[[[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-label data-resource-logo-label\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n\\n\"],[41,[33,1],[[[1,\"            \"],[11,\"button\"],[24,0,\"btn btn-link btn-inline\"],[4,[38,2],[[30,0],\"remove\"],null],[12],[1,\"\\n                \"],[10,1],[14,0,\"icon-remove--red clickable\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"        \"],[10,0],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n            \"],[1,[34,3]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"img\"],[15,\"src\",[30,3]],[14,0,\"org-logo img-responsive img-rounded\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[33,4],[[[1,\"    \"],[10,\"img\"],[14,\"src\",\"\"],[14,1,\"selected-image\"],[14,0,\"org-logo img-responsive img-rounded\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"selected-img-name\"],[12],[1,\"\\n        \"],[1,[33,4,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn btn-default img-upload-btn img-rounded\"],[4,[38,2],[[30,0],\"select\"],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"far fa-file-image img-upload-btn__icon\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@label\",\"@tooltip\",\"@url\"],false,[\"if\",\"haveSelection\",\"action\",\"tooltip\",\"pendingImage\"]]",
    "moduleName": "plutof/templates/components/filerepository/select-image.hbs",
    "isStrictMode": false
  });
});