define("plutof/templates/permit/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ueUOLpN7",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[28,[37,1],[\"permit\"],null],[28,[37,2],[\"permit.permit\"],null],[30,0,[\"model\",\"permit\"]],\"permit/permit\",\"permit.edit\",true,[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@permit\",\"@items\"],[[30,0,[\"model\",\"permit\"]],[30,0,[\"model\",\"items\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@object\"],[[30,0,[\"model\",\"permit\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@record\"],[[30,0,[\"model\",\"permit\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@permit\"],[[30,0,[\"model\",\"permit\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"collection-lab/permit/view\",\"linked-items/view\",\"history/view\",\"collection-lab/permit/view-permissions\"]]",
    "moduleName": "plutof/templates/permit/view.hbs",
    "isStrictMode": false
  });
});