define("plutof/components/tables/-bulk-update/update-panel", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let TableBulkUpdatePanel = (_dec = (0, _component2.classNames)('table-bulk-update__update-panel-anchor'), _dec(_class = class TableBulkUpdatePanel extends _component.default {}) || _class);
  var _default = _exports.default = TableBulkUpdatePanel;
});