define("plutof/components/transaction/transaction-agent", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _computed, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class={{has-error this 'agentExists'}}>
      {{#async/bind-relation @agent.agent as |value update|}}
          <Agent::AutoComplete
              @value={{value}}
              @update={{update}}
              @params={{hash mini=true}}
              data-test="agent" />
      {{/async/bind-relation}}
  </td>
  
  <td class="{{has-error this 'agent.role'}}">
      <PlutofSelect::Value
          @content={{@roles}}
          @value={{@agent.role}}
          @selectClass="mini-input" />
  </td>
  
  <td>
      <Input @value={{@agent.name}} @class="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-link data-table-btn"
      >
          <span class="icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "5pSfrSYI",
    "block": "[[[10,\"td\"],[15,0,[28,[37,0],[[30,0],\"agentExists\"],null]],[12],[1,\"\\n\"],[6,[39,1],[[30,1,[\"agent\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,2],[[24,\"data-test\",\"agent\"]],[[\"@value\",\"@update\",\"@params\"],[[30,2],[30,3],[28,[37,3],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[2,3]]]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[[28,[37,0],[[30,0],\"agent.role\"],null]]]],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@content\",\"@value\",\"@selectClass\"],[[30,4],[30,1,[\"role\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@value\",\"@class\"],[[30,1,[\"name\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,6],[\"click\",[30,5]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@agent\",\"value\",\"update\",\"@roles\",\"@remove\"],false,[\"has-error\",\"async/bind-relation\",\"agent/auto-complete\",\"hash\",\"plutof-select/value\",\"input\",\"on\"]]",
    "moduleName": "plutof/components/transaction/transaction-agent.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'agentExists': (0, _emberCpValidations.validator)('is-truthy'),
    'agent.role': (0, _emberCpValidations.validator)('presence', true)
  });
  let TransactionAgent = (_dec = (0, _computed.notEmpty)('agent.agent.id'), _class = class TransactionAgent extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      // XXX
      _defineProperty(this, "tagName", 'tr');
      _initializerDefineProperty(this, "agentExists", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "agentExists", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionAgent);
});