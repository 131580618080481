define("plutof/components/my-menu/-tool", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember-decorators/component"], function (_exports, _component, _object, _runloop, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MyMenuTool = (_dec = (0, _component2.classNames)('my-menu-tools__tool'), _dec2 = (0, _component2.className)('my-menu-tools__tool--active'), _dec3 = (0, _object.computed)('selector.active'), _dec(_class = (_class2 = class MyMenuTool extends _component.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      (0, _runloop.next)(() => {
        if (!this.isDestroyed) {
          this.selector.register(this);
        }
      });
    }
    get isActive() {
      return this.selector.active === this;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "isActive", [_dec2, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = MyMenuTool;
});