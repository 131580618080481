define("plutof/components/trait/list/-fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{backend-translations/label "measurement.measurement.name"}}>
      {{@trait.name}}
  </@field>
  
  <@field @label={{backend-translations/label "measurement.measurement.description"}}>
      {{@trait.description}}
  </@field>
  
  <@field @label={{backend-translations/label "measurement.measurement.type"}}>
      {{@trait.type}}
  </@field>
  
  */
  {
    "id": "KN4zRc9+",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"measurement.measurement.name\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"measurement.measurement.description\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"description\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"measurement.measurement.type\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"type\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@trait\"],false,[\"backend-translations/label\"]]",
    "moduleName": "plutof/components/trait/list/-fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});