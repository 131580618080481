define("plutof/components/tables/-list-view/modified", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.modified"}}>
      {{formatted-date @record.updated_at}}
  </@Field>
  
  */
  {
    "id": "HCP7DHSH",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"PlutofListViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[30,2,[\"updated_at\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@Field\",\"@record\"],false,[\"i18n-t\",\"formatted-date\"]]",
    "moduleName": "plutof/components/tables/-list-view/modified.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});