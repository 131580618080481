define("plutof/components/filerepository/gallery/-footer-left", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@footer.item @label={{i18n-t "gallery.title"}}>
      {{short-string @file.title 150}}
  </@footer.item>
  
  {{#if @file.licence}}
      <@footer.item @label={{i18n-t "gallery.licenceType"}}>
          {{short-string @file.licenceDisplayName 150}}
      </@footer.item>
  {{/if}}
  
  {{#if @file.rights_owner.id}}
      <@footer.item @label={{i18n-t "gallery.rightsOwner"}}>
          {{@file.rights_owner.representation}}
      </@footer.item>
  {{/if}}
  
  {{#if @file.creators.length}}
      <@footer.item @label={{i18n-t "gallery.creators"}}>
          <Common::RecordListView @records={{@file.creators}} />
      </@footer.item>
  {{/if}}
  
  {{#if @file.owner.id}}
      <@footer.item @label={{i18n-t "gallery.rightsHolder"}}>
          {{short-string @file.owner.representation 100}}
      </@footer.item>
  {{/if}}
  
  */
  {
    "id": "YnkDTmPk",
    "block": "[[[8,[30,1,[\"item\"]],null,[[\"@label\"],[[28,[37,0],[\"gallery.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[30,2,[\"title\"]],150],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,2,[\"licence\"]],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@label\"],[[28,[37,0],[\"gallery.licenceType\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[30,2,[\"licenceDisplayName\"]],150],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"rights_owner\",\"id\"]],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@label\"],[[28,[37,0],[\"gallery.rightsOwner\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"rights_owner\",\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"creators\",\"length\"]],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@label\"],[[28,[37,0],[\"gallery.creators\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@records\"],[[30,2,[\"creators\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"owner\",\"id\"]],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@label\"],[[28,[37,0],[\"gallery.rightsHolder\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[30,2,[\"owner\",\"representation\"]],100],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@footer\",\"@file\"],false,[\"i18n-t\",\"short-string\",\"if\",\"common/record-list-view\"]]",
    "moduleName": "plutof/components/filerepository/gallery/-footer-left.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});