define("plutof/templates/conservation-lab/red-list/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "F84X4i/x",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@reset\"],[[28,[37,1],[\"conservation\"],null],[28,[37,2],[\"conservation.redList.add.title\"],null],[28,[37,3],[\"triggerReset\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"redlist\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[28,[37,9],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"conservation/red-list/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/conservation-lab/red-list/add.hbs",
    "isStrictMode": false
  });
});