define("plutof/components/specimen/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Specimen::View::GeneralData
      @specimen={{@specimen}}
      @additionalIdentifiers={{@additionalIdentifiers}} />
  
  <ViewDeterminations
      @targetRecord={{@specimen}}
      @is_culture="False"
      @disableEdit={{not @canModifyDeterminations}} />
  
  <Project::View::RecordProject
      @record={{@specimen}} />
  
  <Sample::AreaEventViewPanel
      @parent={{@specimen}}
      @canModify={{and @accessRights.canModify (not this.store.inAnnotation)}}
      @showAreaType={{false}}
      @layers={{@layersData.layers}} />
  
  {{#if @layersData.layers.length}}
      <Layer::View::OccurrenceLayers
          @layersData={{@layersData}}
          @occurrence={{@speicmen}} />
  {{/if}}
  
  <Habitat::ViewPanel
      @habitat={{@specimen.samplingevent.habitat}}
      @mainform={{@specimen.mainform}} />
  
  <Measurements::MeasurementView::Panel
      @object={{@specimen}}
      @mainform={{@specimen.mainform}} />
  
  {{#if @specimen.mainform.substrate}}
      {{#resolve-promise @specimen.substrate as |substrate|}}
          <Substrate::View @substrate={{substrate}} />
      {{/resolve-promise}}
  {{/if}}
  
  <Specimen::View::Exsiccata @specimen={{@specimen}} />
  
  {{#if @specimen.mainform.interaction}}
      <Interaction::ViewAll @object={{@specimen}} />
  {{/if}}
  
  <ViewPreparations
      @parent={{@specimen}}
      @model="taxonoccurrence/specimen/specimen" />
  
  */
  {
    "id": "MEWlorKh",
    "block": "[[[8,[39,0],null,[[\"@specimen\",\"@additionalIdentifiers\"],[[30,1],[30,2]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@targetRecord\",\"@is_culture\",\"@disableEdit\"],[[30,1],\"False\",[28,[37,2],[[30,3]],null]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@record\"],[[30,1]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@parent\",\"@canModify\",\"@showAreaType\",\"@layers\"],[[30,1],[28,[37,5],[[30,4,[\"canModify\"]],[28,[37,2],[[30,0,[\"store\",\"inAnnotation\"]]],null]],null],false,[30,5,[\"layers\"]]]],null],[1,\"\\n\\n\"],[41,[30,5,[\"layers\",\"length\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@layersData\",\"@occurrence\"],[[30,5],[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,8],null,[[\"@habitat\",\"@mainform\"],[[30,1,[\"samplingevent\",\"habitat\"]],[30,1,[\"mainform\"]]]],null],[1,\"\\n\\n\"],[8,[39,9],null,[[\"@object\",\"@mainform\"],[[30,1],[30,1,[\"mainform\"]]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"mainform\",\"substrate\"]],[[[6,[39,10],[[30,1,[\"substrate\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,11],null,[[\"@substrate\"],[[30,7]]],null],[1,\"\\n\"]],[7]]]]]],[]],null],[1,\"\\n\"],[8,[39,12],null,[[\"@specimen\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"mainform\",\"interaction\"]],[[[1,\"    \"],[8,[39,13],null,[[\"@object\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,14],null,[[\"@parent\",\"@model\"],[[30,1],\"taxonoccurrence/specimen/specimen\"]],null],[1,\"\\n\"]],[\"@specimen\",\"@additionalIdentifiers\",\"@canModifyDeterminations\",\"@accessRights\",\"@layersData\",\"@speicmen\",\"substrate\"],false,[\"specimen/view/general-data\",\"view-determinations\",\"not\",\"project/view/record-project\",\"sample/area-event-view-panel\",\"and\",\"if\",\"layer/view/occurrence-layers\",\"habitat/view-panel\",\"measurements/measurement-view/panel\",\"resolve-promise\",\"substrate/view\",\"specimen/view/exsiccata\",\"interaction/view-all\",\"view-preparations\"]]",
    "moduleName": "plutof/components/specimen/view.hbs",
    "isStrictMode": false
  });
  let SpecimenView = (_class = class SpecimenView extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenView);
});