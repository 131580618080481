define("plutof/components/wrapped-text", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="plutof-tooltip-hover wrapped-text">
      <div class="wrapped-text__text">
          {{@text}}
      </div>
  
      <div class="wrapped-text__tooltip {{if @breakWord 'wrapped-text__tooltip--break'}}">
          <span class="plutof-tooltip">
              {{@text}}
          </span>
      </div>
  </div>
  
  */
  {
    "id": "FK8ajXSC",
    "block": "[[[10,0],[14,0,\"plutof-tooltip-hover wrapped-text\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"wrapped-text__text\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"wrapped-text__tooltip \",[52,[30,2],\"wrapped-text__tooltip--break\"]]]],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n            \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@text\",\"@breakWord\"],false,[\"if\"]]",
    "moduleName": "plutof/components/wrapped-text.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});