define("plutof/components/import/relation-auto-complete", ["exports", "@ember/component", "@ember/object", "@ember/utils", "plutof/misc/config", "plutof/components/import/utils"], function (_exports, _component, _object, _utils, _config, _utils2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const LOCAL_MODELS = {
    'auth/user': 'users/user'
  };
  let RelationAutoComplete = (_dec = (0, _object.computed)('cell.field.model'), _class = class RelationAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "cell", null);
      _defineProperty(this, "table", null);
    }
    get localModel() {
      const model = this.get('cell.field.model');
      return model in LOCAL_MODELS ? LOCAL_MODELS[model] : model;
    }
    inputChanged(selection) {
      if ((0, _utils.isNone)(selection)) {
        return;
      }
      const cell = this.cell;
      const model = (0, _utils2.importModel)(this.get('cell.field.model'));
      const previousValue = cell.get('previous_value');
      if ((0, _utils.isPresent)(previousValue)) {
        this.table.addNameResolution(model, previousValue, selection, cell.field);
      } else {
        const value = selection.get('representation');
        cell.set('value', value);
        this.table.addNameResolution(model, value, selection, cell.field);
      }
    }
    updateInput(value) {
      (0, _object.set)(this.cell, 'relation', value);
      this.inputChanged(value);
    }

    // TODO: Should probably at least add name resolution as well XXX, otherwise
    // can't guarantee that stuff is resolved. There is no previous value to
    // speak of, but mappings part_name => id should be there
    multipleInputChanged(selection) {
      const separator = _config.default.Import.HASMANY_SEPARATOR;
      var table = this.table;
      var cell = this.cell;
      if (_config.default.Import.ENABLE_HASMANY_RESOLUTIONS) {
        const previousValues = this.get('cell.previous_value').split(separator).map(v => v.trim());
        if (selection.length === previousValues.length) {
          const model = (0, _utils2.importModel)(this.get('cell.field.model'));
          selection.forEach(function (resolution, idx) {
            const pvalue = previousValues[idx];
            table.addNameResolution(model, pvalue, resolution, cell.get('field'));
          });
        }
      }
      const value = selection.mapBy('representation').join(separator);
      cell.set('value', value);

      // Fix name -> id map just in case
      selection.forEach(function (object) {
        const model = (0, _utils2.importModel)(object.constructor.modelName);
        table.addNameResolution(model, object.get('representation'), object, cell.get('field'));
      });
    }
    addMultipleInput(value) {
      if (!value) {
        return;
      }
      this.cell.relation.pushObject(value);
      this.multipleInputChanged(this.cell.relation);
    }
    removeMultipleInput(value) {
      this.cell.relation.removeObject(value);
      this.multipleInputChanged(this.cell.relation);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "localModel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "localModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inputChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "multipleInputChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "multipleInputChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMultipleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addMultipleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeMultipleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeMultipleInput"), _class.prototype), _class);
  var _default = _exports.default = RelationAutoComplete;
});