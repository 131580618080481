define("plutof/templates/components/observation/table/header-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DCIqgV73",
    "block": "[[[8,[30,1],null,[[\"@clicked\",\"@disabled\"],[[30,2,[\"togglePanel\"]],[30,2,[\"isEmpty\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"General.tableBulkUpdate.toggle\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@clicked\",\"@disabled\"],[[30,3,[\"request\"]],[30,3,[\"disabled\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"General.addExternalMeasurements\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],[[16,\"data-test\",[52,[30,4],\"add-observation\"]]],[[\"@clicked\",\"@disabled\"],[[28,[37,2],[[30,0,[\"add\"]],1],null],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"General.Add\"],null]],[1,\" 1\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@clicked\",\"@disabled\"],[[28,[37,2],[[30,0,[\"add\"]],10],null],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"General.Add\"],null]],[1,\" 10\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"    \"],[8,[30,1],null,[[\"@clicked\"],[[30,7]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,0],[[30,6,[\"title\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@button\",\"@bulkUpdate\",\"@externalMeasurements\",\"@includeTestMarker\",\"@disabled\",\"@sheetset\",\"@showSheet\"],false,[\"i18n-t\",\"if\",\"fn\"]]",
    "moduleName": "plutof/templates/components/observation/table/header-buttons.hbs",
    "isStrictMode": false
  });
});