define("plutof/templates/components/publishing/gbif/bibliography-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TwuTG2PC",
    "block": "[[[10,\"td\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[33,1,[\"attrs\",\"citation\",\"citation\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"citation\"]],\"mini-input\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"url\"]],\"mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[28,[37,4],[[30,2],[30,1]],null]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@citation\",\"@removeCitation\"],false,[\"if\",\"validations\",\"input\",\"on\",\"fn\"]]",
    "moduleName": "plutof/templates/components/publishing/gbif/bibliography-row-form.hbs",
    "isStrictMode": false
  });
});