define("plutof/controllers/experiment/dna/edit", ["exports", "@ember/controller", "@ember/object", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let DNAEditController = (_class = class DNAEditController extends _controller.default.extend(_editController.default) {
    async save() {
      await this.model.dna.save();
    }
    saveDNA() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      return this.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.set('routeHasBeenLoaded', false);
        this.send('goBack');
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "saveDNA", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveDNA"), _class.prototype), _class);
  var _default = _exports.default = DNAEditController;
});