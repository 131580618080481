define("plutof/templates/components/map/tool-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sic7FArC",
    "block": "[[[10,0],[14,0,\"latlon-container\"],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"tool-container ol-control\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"button-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[16,0,[29,[\"tool-button ol-control \",[52,[30,0,[\"open\"]],\"\",\"hidden\"]]]],[16,\"title\",[30,2,[\"name\"]]],[4,[38,3],[[30,0],\"toggleControl\",[30,2]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[29,[[30,2,[\"icon\"]],\" \",[52,[30,2,[\"isToggled\"]],\"icon-selected\"]]]],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[11,\"button\"],[24,0,\"tool-button ol-control\"],[16,\"title\",[28,[37,4],[\"Map.controls.controls\"],null]],[4,[38,3],[[30,0],\"toggleOpen\"],null],[12],[1,\"\\n            \"],[10,1],[15,0,[29,[\"fas \",[52,[30,0,[\"open\"]],\"fa-chevron-right\",\"fa-chevron-left\"]]]],[12],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@controls\",\"control\"],false,[\"each\",\"-track-array\",\"if\",\"action\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/map/tool-panel.hbs",
    "isStrictMode": false
  });
});