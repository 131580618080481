define("plutof/templates/components/common/save-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MxzpurZ5",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,1],[30,2],[28,[37,1],[\"ok\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"General.Save\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@save\",\"@disabled\"],false,[\"promise-button\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/common/save-button.hbs",
    "isStrictMode": false
  });
});