define("plutof/templates/profile/bookmarks", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vdRahcWp",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.bookmarks\"]],null],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"bookmarks\",\"isLoading\",\"remove\"],[[33,2,[\"bookmarks\"]],false,[28,[37,3],[[30,0],\"removeBookmark\"],null]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"profile/bookmark-list\",\"model\",\"action\"]]",
    "moduleName": "plutof/templates/profile/bookmarks.hbs",
    "isStrictMode": false
  });
});