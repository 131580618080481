define("plutof/components/analysis/ml-sh/-predictions-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="analysis-model-predictions__prediction {{this.confidenceClass}}">
      {{yield}}
  
      {{#if @confidence}}
          ({{this.formattedConfidence}})
      {{/if}}
  </li>
  
  */
  {
    "id": "xTtOjWIi",
    "block": "[[[10,\"li\"],[15,0,[29,[\"analysis-model-predictions__prediction \",[30,0,[\"confidenceClass\"]]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"        (\"],[1,[30,0,[\"formattedConfidence\"]]],[1,\")\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@confidence\",\"&default\"],false,[\"yield\",\"if\"]]",
    "moduleName": "plutof/components/analysis/ml-sh/-predictions-item.hbs",
    "isStrictMode": false
  });
  let AnalysisPredictionsItem = _exports.default = (_dec = (0, _object.computed)('args.confidence'), _dec2 = (0, _object.computed)('args.confidence'), _class = class AnalysisPredictionsItem extends _component2.default {
    get confidenceClass() {
      if (!(0, _utils.isNone)(this.args.confidence)) {
        if (this.args.confidence >= 0.8) {
          return 'analysis-model-predictions__prediction--confident';
        } else if (this.args.confidence < 0.2) {
          return 'analysis-model-predictions__prediction--worthless';
        }
      }
      return '';
    }
    get formattedConfidence() {
      if ((0, _utils.isNone)(this.args.confidence)) {
        return '';
      }
      return `${(this.args.confidence * 100).toFixed(2)}%`;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "confidenceClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "confidenceClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedConfidence", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formattedConfidence"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnalysisPredictionsItem);
});