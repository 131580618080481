define("plutof/components/tables/-list-view/tristate-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Field @label={{component "tables/-list-view/tristate-checkbox-label" label=@label changed=@changed}}>
      {{yield}}
  </@Field>
  
  */
  {
    "id": "Hq1ujc9m",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[50,\"tables/-list-view/tristate-checkbox-label\",0,null,[[\"label\",\"changed\"],[[30,2],[30,3]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@Field\",\"@label\",\"@changed\",\"&default\"],false,[\"component\",\"yield\"]]",
    "moduleName": "plutof/components/tables/-list-view/tristate-checkbox.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});