define("plutof/components/dmp/host-pid-systems", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <MultipleSelectizer
      @content={{@choices}}
      @selected={{@host.pid_system}}
      @hideList={{true}}
      @prompt="---" />
  
  <Common::RecordList @records={{@host.pid_system}} @remove={{this.remove}} as |record|>
      {{record}}
  </Common::RecordList>
  
  */
  {
    "id": "xXMW9G0m",
    "block": "[[[8,[39,0],null,[[\"@content\",\"@selected\",\"@hideList\",\"@prompt\"],[[30,1],[30,2,[\"pid_system\"]],true,\"---\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@records\",\"@remove\"],[[30,2,[\"pid_system\"]],[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,3]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@choices\",\"@host\",\"record\"],false,[\"multiple-selectizer\",\"common/record-list\"]]",
    "moduleName": "plutof/components/dmp/host-pid-systems.hbs",
    "isStrictMode": false
  });
  let DMPHostPidSystems = (_class = class DMPHostPidSystems extends _component.default {
    remove(pid) {
      this.host.pid_system.removeObject(pid);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPHostPidSystems);
});