define("plutof/templates/specimen/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "PgYqVRpl",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"specimen.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@icon\",\"@infoContent\"],[[28,[37,2],[\"Specimen.editS\"],null],[28,[37,3],[\"specimen\"],null],\"information/content/general/map-controls\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@data\",\"@closePanels\",\"@canModifyDeterminations\",\"@showAreaEditButton\",\"@canResetArea\",\"@preserveZoom\",\"@canModifyOwner\",\"@validationContext\"],[[30,0,[\"model\",\"specimenData\"]],[30,0,[\"closePanels\"]],false,true,false,false,true,[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"editSpecimen\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[28,[37,9],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"i18n-t\",\"icon\",\"specimen/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/specimen/edit.hbs",
    "isStrictMode": false
  });
});