define("plutof/templates/components/clipboard/bulk-update-observation-general", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x3hlQCMX",
    "block": "[[[41,[30,0,[\"inputs\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"project\"]]]],[[\"default\"],[[[[6,[39,4],[[30,1,[\"value\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,[[\"@value\",\"@update\",\"@disabled\"],[[30,2],[30,3],[30,1,[\"disabled\"]]]],null],[1,\"\\n\"]],[2,3]]]]]],[1]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"individual_count\"]]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,6],null,[[\"type\",\"value\",\"disabled\",\"class\"],[\"text\",[30,4,[\"value\"]],[30,4,[\"disabled\"]],\"form-control\"]]]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"remarks\"]]]],[[\"default\"],[[[[1,\"            \"],[8,[39,7],[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[30,5,[\"value\"]],[30,5,[\"disabled\"]]]],null],[1,\"\\n\"]],[5]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"identification_assessment\"]]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,8],null,[[\"content\",\"value\",\"disabled\",\"selectClass\"],[[33,9],[30,6,[\"value\"]],[30,6,[\"disabled\"]],\"form-control\"]]]],[1,\"\\n\"]],[6]]]]],[1,\"    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,7,[\"mainformType\"]],[[[1,\"        \"],[8,[39,10],null,[[\"@data\",\"@mainformConfig\",\"@formPath\"],[[30,7],[30,0,[\"inputs\",\"mainform\"]],\"observation_form\"]],null],[1,\"\\n\"]],[]],null]],[]],null]],[\"iw\",\"value\",\"update\",\"iw\",\"iw\",\"iw\",\"@data\"],false,[\"if\",\"layout/regular-grid\",\"clipboard/input-wrapper\",\"inputs\",\"async/bind-relation\",\"project/auto-complete\",\"input\",\"flexible-textarea\",\"plutof-select/value\",\"assessments\",\"clipboard/bulk-edit-measurement-form\"]]",
    "moduleName": "plutof/templates/components/clipboard/bulk-update-observation-general.hbs",
    "isStrictMode": false
  });
});