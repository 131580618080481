define("plutof/helpers/short-string", ["exports", "@ember/component/helper", "@ember/utils", "plutof/utils/string"], function (_exports, _helper, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.shortString = shortString;
  function shortString([string, limit]) {
    // @string can be SafeString, so convert just in case
    if (!(0, _utils.isNone)(string)) {
      string = string.toString();
    }
    return (0, _string.truncate)(string, limit);
  }
  var _default = _exports.default = (0, _helper.helper)(shortString);
});