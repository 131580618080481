define("plutof/components/menu/language-buttons", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const BUTTONS = [{
    locale: 'est',
    label: 'Est',
    // TODO: Find out what uses those (landing page?)
    id: 'lang-est'
  }, {
    locale: 'eng',
    label: 'Eng',
    id: 'lang-eng'
  }];
  let LanguageButtons = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('i18n.locale'), _dec(_class = (_class2 = class LanguageButtons extends _component.default {
    get visibleButtons() {
      return BUTTONS.filter(button => button.locale !== this.i18n.locale);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "visibleButtons", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleButtons"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = LanguageButtons;
});