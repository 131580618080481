define("plutof/controllers/materialsample/view", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MaterialsampleViewController = (_dec = (0, _object.computed)('model.sample.samplingevent.samplingarea.geom'), _dec2 = (0, _object.computed)('model.sample.id'), _dec3 = (0, _object.computed)('model.sample.id', 'model.contentType.id'), _dec4 = (0, _object.computed)('model.sample.id'), _dec5 = (0, _computed.alias)('model.sample.id'), _class = class MaterialsampleViewController extends _controller.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "addRoute", 'materialsample.add');
      _initializerDefineProperty(this, "objectID", _descriptor, this);
    }
    get mapData() {
      var geom = this.get('model.sample.samplingevent.samplingarea.geom');
      return [{
        geom: geom
      }];
    }
    get directlyRelatedOccurrencesQuery() {
      return {
        related_materialsample: this.model.sample.id
      };
    }
    get indirectlyRelatedOccurrencesQuery() {
      return {
        content_type: this.model.contentType.id,
        object_id: this.model.sample.id
      };
    }
    get clipboardQuery() {
      return {
        material_sample: this.model.sample.id
      };
    }
    addDirect(route) {
      return this.transitionToRoute(route, {
        queryParams: {
          materialsample_id: this.get('model.sample.id'),
          event_id: this.get('model.event.id'),
          area_id: this.get('model.area.id')
        }
      });
    }
    addIndirect(route) {
      return this.transitionToRoute(route, {
        queryParams: {
          content_type: this.model.contentType.id,
          object_id: this.model.sample.id
        }
      });
    }
    newSpecimen() {
      this.addDirect('specimen.add');
    }
    newObservation() {
      this.addDirect('observation.add');
    }
    newCulture() {
      this.addIndirect('livingspecimen.add');
    }
    newSequence() {
      this.addIndirect('sequence.add');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "mapData", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "mapData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "directlyRelatedOccurrencesQuery", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "directlyRelatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "indirectlyRelatedOccurrencesQuery", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "indirectlyRelatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clipboardQuery", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clipboardQuery"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "objectID", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newSpecimen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newSpecimen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newObservation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newObservation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newCulture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newCulture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSequence", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newSequence"), _class.prototype), _class);
  var _default = _exports.default = MaterialsampleViewController;
});