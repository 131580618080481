define("plutof/components/common/private-relation", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="private-relation">
      {{i18n-t "Access.forbidden"}}
  </span>
  
  */
  {
    "id": "pdGMiKQp",
    "block": "[[[10,1],[14,0,\"private-relation\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"Access.forbidden\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"i18n-t\"]]",
    "moduleName": "plutof/components/common/private-relation.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});