define("plutof/components/import/selected-fields-table", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="data-table data-table--break table-hover">
      <thead>
          <tr>
              <th>{{i18n-t "Import.template.column"}}</th>
              <th>{{i18n-t "Import.template.unit"}}</th>
              <th>{{i18n-t "Import.template.type"}}</th>
          </tr>
      </thead>
  
      <tbody>
          {{#each @fields as |field|}}
              <tr>
                  <td>{{field.title}}</td>
                  <td>{{field.unit}}</td>
                  <td>{{field.typeName}}</td>
              </tr>
          {{/each}}
      </tbody>
  </table>
  
  */
  {
    "id": "gQoLvm1O",
    "block": "[[[10,\"table\"],[14,0,\"data-table data-table--break table-hover\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,0],[\"Import.template.column\"],null]],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,0],[\"Import.template.unit\"],null]],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,[28,[35,0],[\"Import.template.type\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,2,[\"unit\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,2,[\"typeName\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@fields\",\"field\"],false,[\"i18n-t\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/components/import/selected-fields-table.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});