define("plutof/components/area/geometry/view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "plutof/components/area/geometry/form"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.open}}
      <Ui::Modal::Dialog
          @open={{this.open}}
          @close={{this.cancel}}
          @smallerBodyFont={{true}}
      >
          <:header>
              {{i18n-t "Map.import.geometry"}}
          </:header>
  
          <:body>
              <Common::RadioGroup
                  @active={{this.activeFormat}}
                  @activate={{this.switchFormat}}
                  as |radioGroup|
              >
                  {{#each-in this.formats as |key format|}}
                      <radioGroup.choice
                          @name={{format.format}}
                          data-test={{format.name}}
                      >
                          {{format.name}}
                      </radioGroup.choice>
                  {{/each-in}}
              </Common::RadioGroup>
  
              <FlexibleTextarea @value={{this.geometry}} @disabled={{true}} class="form-control" />
          </:body>
  
          <:footer>
              <PromiseButton
                  @clicked={{this.downloadGeometry}}
                  @restIcon={{icon "download"}}
                  @tagName="span"
                  class="btn btn-default"
              >
                  {{i18n-t "General.Download"}}
              </PromiseButton>
  
              <Common::CancelButton @cancel={{this.cancel}} />
          </:footer>
      </Ui::Modal::Dialog>
  {{/if}}
  
  */
  {
    "id": "roH+0vmC",
    "block": "[[[41,[30,0,[\"open\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@open\",\"@close\",\"@smallerBodyFont\"],[[30,0,[\"open\"]],[30,0,[\"cancel\"]],true]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"Map.import.geometry\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,3],null,[[\"@active\",\"@activate\"],[[30,0,[\"activeFormat\"]],[30,0,[\"switchFormat\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[30,0,[\"formats\"]]],null],null,[[[1,\"                    \"],[8,[30,1,[\"choice\"]],[[16,\"data-test\",[30,2,[\"name\"]]]],[[\"@name\"],[[30,2,[\"format\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[30,2,[\"name\"]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[2,3]],null],[1,\"            \"]],[1]]]]],[1,\"\\n\\n            \"],[8,[39,6],[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[30,0,[\"geometry\"]],true]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,7],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@restIcon\",\"@tagName\"],[[30,0,[\"downloadGeometry\"]],[28,[37,8],[\"download\"],null],\"span\"]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.Download\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,9],null,[[\"@cancel\"],[[30,0,[\"cancel\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"radioGroup\",\"format\",\"key\"],false,[\"if\",\"ui/modal/dialog\",\"i18n-t\",\"common/radio-group\",\"each\",\"-each-in\",\"flexible-textarea\",\"promise-button\",\"icon\",\"common/cancel-button\"]]",
    "moduleName": "plutof/components/area/geometry/view.hbs",
    "isStrictMode": false
  });
  let GeometryView = (_dec = (0, _object.computed)('args.dialogOpen'), _dec2 = (0, _object.computed)(), _class = class GeometryView extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "geometry", _descriptor, this);
      _initializerDefineProperty(this, "activeFormat", _descriptor2, this);
      _defineProperty(this, "formats", _form.FORMATS);
      _defineProperty(this, "baseFormat", _form.FORMATS.json.format);
      _defineProperty(this, "backendFormat", _form.FORMATS.wkt.format);
      _defineProperty(this, "initGeomFromArea", async function () {
        const area = await this.args.area;
        const loadedGeometry = (0, _form.convertGeometry)(area.geom, this.backendFormat, this.activeFormat, area);
        this.geometry = loadedGeometry;
      });
      this.initGeomFromArea();
    }
    get open() {
      return this.args.dialogOpen;
    }
    set open(value) {
      return value;
    }

    // Perhaps this should be just static WKT.
    get baseGeometry() {
      return (0, _form.convertGeometry)(this.geometry, this.activeFormat, this.baseFormat);
    }
    switchFormat(newFormat) {
      this.geometry = (0, _form.convertGeometry)(this.baseGeometry, this.baseFormat, newFormat);
      this.activeFormat = newFormat;
    }
    downloadGeometry() {
      (0, _form.downloadGeometry)(this.geometry, this.args.area.name, this.activeFormat);
    }
    cancel() {
      this.args.closeDialog();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "geometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeFormat", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _form.FORMATS.json.format;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "baseGeometry", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "baseGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchFormat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeometryView);
});