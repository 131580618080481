define("plutof/helpers/hl-subs", ["exports", "@ember/component/helper", "@ember/template", "@ember/utils", "escape-html"], function (_exports, _helper, _template, _utils, _escapeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highlightSubstring = highlightSubstring;
  function escapeRegExp(str) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  function highlightSubstring([string, sub]) {
    if (!(0, _utils.isPresent)(sub)) {
      return string;
    }

    // Case-insensitive, every match.
    const regExp = new RegExp(sub.split(/\s+/).reject(_utils.isEmpty).map(escapeRegExp).join('|'), 'ig');
    const parts = [];
    let match = regExp.exec(string);
    let startIndex = 0;
    while (match) {
      let matchIndex = match.index;
      parts.push((0, _escapeHtml.default)(string.substring(startIndex, matchIndex)));
      parts.push(`<span class="hl-text">${(0, _escapeHtml.default)(match[0])}</span>`);
      startIndex = matchIndex + match[0].length;
      match = regExp.exec(string);
    }
    if (startIndex !== string.length) {
      parts.pushObject((0, _escapeHtml.default)(string.substring(startIndex)));
    }
    return new _template.htmlSafe(parts.join(''));
  }
  var _default = _exports.default = (0, _helper.helper)(highlightSubstring);
});