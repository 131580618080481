define("plutof/templates/components/common/-button-group-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iO1OrlL1",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[16,0,[29,[\"button-group__button\\n        \",[52,[30,0,[\"isActive\"]],\"button-group__button--active\"],\"\\n        \",[52,[30,2],\"plutof-group-btn--invalid\"]]]],[17,3],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"click\"]],[30,0]],null]],null],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disabled\",\"@invalid\",\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "plutof/templates/components/common/-button-group-button.hbs",
    "isStrictMode": false
  });
});