define("plutof/components/plutof-panel/item-list/content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO: Render override. }}
  <div
      {{collapse @open}}
      class="item-list-panel-content"
      ...attributes
  >
      <div class={{default-to @panelBodyClass "panel-body"}}>
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "xbJa9Ztt",
    "block": "[[[11,0],[24,0,\"item-list-panel-content\"],[17,1],[4,[38,0],[[30,2]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[28,[37,1],[[30,3],\"panel-body\"],null]],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@open\",\"@panelBodyClass\",\"&default\"],false,[\"collapse\",\"default-to\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/item-list/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});