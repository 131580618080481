define("plutof/helpers/backend-translations/label", ["exports", "@ember/component/helper", "@ember/service", "plutof/misc/options-getter"], function (_exports, _helper, _service, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    ajax: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.set('translation', '');
      this.set('path', '');
    },
    compute([path]) {
      if (this.path !== path) {
        this.set('path', path);
        (0, _optionsGetter.getTexts)(this.ajax, path, 'model').then(texts => {
          if (!this.isDestroyed && this.path === path) {
            this.set('translation', texts.label);
            this.recompute();
          }
        });
      }
      return this.translation;
    }
  });
});