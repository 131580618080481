define("plutof/components/forms/access", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      {{common/local-label "Access.publicLinkingLabel" "Access.publicLinkingHint"}}
  
      <Access::Edit::PublicOrPrivate
          @value={{@mainform.public_linking}}
          @update={{action (mut @mainform.public_linking)}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "DJSDf65j",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"Access.publicLinkingLabel\",\"Access.publicLinkingHint\"],null]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@value\",\"@update\"],[[30,1,[\"public_linking\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,1,[\"public_linking\"]]],null]],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@mainform\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"common/local-label\",\"access/edit/public-or-private\",\"action\",\"mut\"]]",
    "moduleName": "plutof/components/forms/access.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});