define("plutof/components/study/view/project-areas", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @projectAreas.length}}
      data-test="Project::View::ProjectAreas"
      as |panel|
  >
      <panel.header @title="{{i18n-t "Projects.predefinedAreas"}}" @count="{{@projectAreas.length}}" />
  
      <panel.content>
          {{study/-project-areas-table projectAreas=@projectAreas}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "umyTKm1k",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Project::View::ProjectAreas\"]],[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[29,[[28,[37,2],[\"Projects.predefinedAreas\"],null]]],[29,[[30,1,[\"length\"]]]]]],null],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],null,[[\"projectAreas\"],[[30,1]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@projectAreas\",\"panel\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"study/-project-areas-table\"]]",
    "moduleName": "plutof/components/study/view/project-areas.hbs",
    "isStrictMode": false
  });
  class ProjectAreas extends _component.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProjectAreas);
});