define("plutof/components/collection-lab/permit/autocomplete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "plutof/components/auto-complete/backends", "plutof/components/collection-lab/permit/edit", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _backends, _edit, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @disabled={{@disabled}}
      @params={{merge-params @params (hash
          sideAction=this.openDialog
          sideActionIcon=(icon "add-new")
      )}} />
  
  {{#if this.renderDialog}}
      <Ui::Modal::Dialog
          @open={{this.showDialog}}
          @close={{this.cancel}}
          @smallerBodyFont={{true}}
      >
          <:header>
              {{i18n-t "permit.add"}}
          </:header>
  
          <:body>
              <CollectionLab::Permit::Edit
                  @model={{this.permitModel}}
                  @validationContext={{this.validationContext}} />
          </:body>
  
          <:footer>
              <button
                  {{on "click" this.cancel}}
                  class="btn btn-default"
              >
                  {{i18n-t "General.Cancel"}}
              </button>
  
              <PromiseButton
                  @clicked={{this.save}}
                  @disabled={{and this.validationContext this.validationContext.isInvalid}}
                  class="btn btn-default plutof-btn-green"
              >
                  {{i18n-t "General.Add"}}
              </PromiseButton>
          </:footer>
      </Ui::Modal::Dialog>
  {{/if}}
  
  */
  {
    "id": "bxjKGa3i",
    "block": "[[[8,[39,0],null,[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,0,[\"backend\"]],[30,1],[30,2],[30,3],[28,[37,1],[[30,4],[28,[37,2],null,[[\"sideAction\",\"sideActionIcon\"],[[30,0,[\"openDialog\"]],[28,[37,3],[\"add-new\"],null]]]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"renderDialog\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@open\",\"@close\",\"@smallerBodyFont\"],[[30,0,[\"showDialog\"]],[30,0,[\"cancel\"]],true]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n            \"],[1,[28,[35,6],[\"permit.add\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,7],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"permitModel\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,8],[\"click\",[30,0,[\"cancel\"]]],null],[12],[1,\"\\n                \"],[1,[28,[35,6],[\"General.Cancel\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[8,[39,9],[[24,0,\"btn btn-default plutof-btn-green\"]],[[\"@clicked\",\"@disabled\"],[[30,0,[\"save\"]],[28,[37,10],[[30,0,[\"validationContext\"]],[30,0,[\"validationContext\",\"isInvalid\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,6],[\"General.Add\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@value\",\"@update\",\"@disabled\",\"@params\"],false,[\"auto-complete/base\",\"merge-params\",\"hash\",\"icon\",\"if\",\"ui/modal/dialog\",\"i18n-t\",\"collection-lab/permit/edit\",\"on\",\"promise-button\",\"and\"]]",
    "moduleName": "plutof/components/collection-lab/permit/autocomplete.hbs",
    "isStrictMode": false
  });
  let PermitAutocomplete = (_class = class PermitAutocomplete extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "renderDialog", _descriptor2, this);
      _initializerDefineProperty(this, "showDialog", _descriptor3, this);
      _initializerDefineProperty(this, "permitModel", _descriptor4, this);
      _initializerDefineProperty(this, "validationContext", _descriptor5, this);
      _defineProperty(this, "backend", new _backends.StoreQueryAutocompleteBackend({
        store: this.store,
        model: 'permit/permit'
      }));
    }
    async openDialog() {
      this.permitModel = await (0, _edit.createPermitModel)(this.store);
      this.validationContext = _validations.ValidationContext.create();
      this.renderDialog = true;
      this.showDialog = true;
    }
    closeDialog() {
      this.showDialog = false;
    }
    willDestroy() {
      super.willDestroy();
      if (this.validationContext) {
        this.validationContext.destroy();
      }
    }
    async save() {
      await this.permitModel.save();
      await this.args.update(this.permitModel.permit);
      this.closeDialog();
    }
    cancel() {
      this.closeDialog();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "renderDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permitModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "validationContext", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PermitAutocomplete);
});