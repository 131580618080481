define("plutof/components/referencebased/shared-fields", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _component2, _computed, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Referencebased::SharedFields"
  >
      <div class={{unless this.referenceValid 'has-error'}}>
          {{plutof-labelc "referencebased.occurrence.reference"}}
  
          {{#async/bind-relation @reference as |value update|}}
              <Reference::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  data-test="reference" />
          {{/async/bind-relation}}
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "STt/HzxJ",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Referencebased::SharedFields\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[52,[51,[30,0,[\"referenceValid\"]]],\"has-error\"]],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"referencebased.occurrence.reference\"],null]],[1,\"\\n\\n\"],[6,[39,4],[[30,1]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,5],[[24,\"data-test\",\"reference\"]],[[\"@value\",\"@update\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@reference\",\"value\",\"update\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"unless\",\"plutof-labelc\",\"async/bind-relation\",\"reference/auto-complete\"]]",
    "moduleName": "plutof/components/referencebased/shared-fields.hbs",
    "isStrictMode": false
  });
  let RefbasedSharedFields = (_dec = (0, _computed.notEmpty)('args.reference'), _class = class RefbasedSharedFields extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "referenceValid", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "referenceValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RefbasedSharedFields);
});