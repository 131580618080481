define("plutof/templates/components/study/project-persons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LWBDSFCY",
    "block": "[[[10,0],[14,0,\"add-component\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[1,[28,[35,0],null,[[\"path\"],[\"studyperson.person\"]]]],[1,\"\\n\\n\"],[6,[39,1],[[30,0,[\"person\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],null,[[\"@value\",\"@update\",\"@params\"],[[30,1],[30,2],[28,[37,3],null,[[\"inputClasses\",\"sideActionClasses\"],[\"form-control control\",\"btn btn-default input-group-in-add-component--middle\"]]]]],null],[1,\"\\n\"]],[1,2]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"path\"],[\"studyperson.role\"]]]],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,0,[\"role\"]],\"form-control control project-persons__role\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[28,[37,6],[[30,0,[\"person\"]]],null]],[24,0,\"btn btn-default control project-persons__add-button\"],[4,[38,7],[\"click\",[30,0,[\"_add\"]]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,8],[\"ok\"],null]],[12],[13],[1,\"\\n            \"],[1,[28,[35,9],[\"General.Add\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@records\",\"@remove\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[\"value\",\"update\",\"@projectPersons\",\"@remove\"],false,[\"person-label-tooltip\",\"async/bind-relation\",\"agent/person/auto-complete\",\"hash\",\"plutof-labelc\",\"input\",\"not\",\"on\",\"icon\",\"i18n-t\",\"common/record-list\"]]",
    "moduleName": "plutof/templates/components/study/project-persons.hbs",
    "isStrictMode": false
  });
});