define("plutof/components/agent/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{if
          (is-equal @agent.agent_type "organization")
          "organization.view"
          "person.view"
      }}
      @model={{@agent.id}}
  >
      {{@agent.representation}}
  </LinkTo>
  
  */
  {
    "id": "H/Pp568X",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[52,[28,[37,2],[[30,1,[\"agent_type\"]],\"organization\"],null],\"organization.view\",\"person.view\"],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@agent\"],false,[\"link-to\",\"if\",\"is-equal\"]]",
    "moduleName": "plutof/components/agent/link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});