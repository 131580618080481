define("plutof/components/search/top-results/info", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless @canLoad}}
      <p class="top-results-container__info">
          <span class={{icon "info"}}></span>
          {{i18n-t "Search.topResults.phenoFilterWarning"}}
      </p>
  {{/unless}}
  
  {{#if @isLoading}}
      <p class="top-results-container__info">
          <span class="loading-icon"></span>
      </p>
  {{/if}}
  
  */
  {
    "id": "EzsgYpy8",
    "block": "[[[41,[51,[30,1]],[[[1,\"    \"],[10,2],[14,0,\"top-results-container__info\"],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,1],[\"info\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,2],[\"Search.topResults.phenoFilterWarning\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,2],[14,0,\"top-results-container__info\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@canLoad\",\"@isLoading\"],false,[\"unless\",\"icon\",\"i18n-t\",\"if\"]]",
    "moduleName": "plutof/components/search/top-results/info.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});