define("plutof/templates/components/information/collapsed-section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6bpGLQar",
    "block": "[[[11,\"button\"],[16,0,[29,[\"collapsed-section-btn \",[52,[30,0,[\"open\"]],\"collapsed-section-btn--expanded\"]]]],[4,[38,1],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n\\n    \"],[10,1],[15,0,[29,[\"collapsed-section-caret\\n        \",[52,[30,0,[\"open\"]],\"collapsed-section-caret--expanded\"]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,1,[29,[[30,0,[\"contentId\"]]]]],[15,0,[29,[\"collapsed-section-content \",[52,[30,0,[\"open\"]],\"collapsed-section-content--expanded\"]]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@label\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "plutof/templates/components/information/collapsed-section.hbs",
    "isStrictMode": false
  });
});