define("plutof/templates/taxonomy/addtree", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cgHv53MK",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"taxonomy.add-tree\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\"],[[28,[37,2],[\"Taxonomy.newTree\"],null],[28,[37,3],[\"taxonomy\"],null],\"information/content/taxon/general\",[30,0,[\"reset\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@name\",\"@rootNode\",\"@originTree\",\"@validationContext\"],[[30,0,[\"name\"]],[30,0,[\"model\",\"rootNode\"]],[30,0,[\"model\",\"originTree\"]],[30,0,[\"validationContext\",\"tree\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@rights\",\"@validationContext\"],[[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\",\"access\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"disableSave\"]]]],null],[1,\"\\n\\n        \"],[8,[39,9],null,[[\"@cancel\"],[[30,0,[\"cancel\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"i18n-t\",\"icon\",\"taxonomy/tree-attributes\",\"access/edit-rights\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/taxonomy/addtree.hbs",
    "isStrictMode": false
  });
});