define("plutof/components/annotation/-summary/apidiff1", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Annotation::-Summary::Changes
      @changes={{@annotation.changes}}
      @containsPreviousValues={{false}} />
  
  */
  {
    "id": "HXX/uYJ3",
    "block": "[[[8,[39,0],null,[[\"@changes\",\"@containsPreviousValues\"],[[30,1,[\"changes\"]],false]],null],[1,\"\\n\"]],[\"@annotation\"],false,[\"annotation/-summary/changes\"]]",
    "moduleName": "plutof/components/annotation/-summary/apidiff1.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});