define("plutof/controllers/forms/edit", ["exports", "rsvp", "plutof/mixins/component-validations", "plutof/utils/controllers"], function (_exports, _rsvp, _componentValidations, _controllers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class FormsEditController extends _controllers.EditController.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'generalData',
        label: 'General.generalData'
      }, {
        name: 'taxonSheet',
        label: 'Forms.taxonSheet.title'
      }, {
        name: 'managingGroup',
        label: 'group.panels.workgroup'
      }]);
    }
    async _save() {
      const mainformData = this.model.mainformData;
      if (mainformData.itemsToDelete) {
        await _rsvp.default.all(mainformData.itemsToDelete.invoke('destroyRecord')).then(() => {
          mainformData.itemsToDelete.clear();
        });
      }
      await mainformData.taxonSheetData.save();
      await mainformData.mainform.save();
      await _rsvp.default.all(mainformData.measurementForms.invoke('save'));
      await _rsvp.default.all(mainformData.taxonMainforms.invoke('save'));

      // Doing this last in case the user demotes themselves
      //
      // Keep in mind that this works only because add route has no group panel. If
      // we ever add one then have to save the group before everything else (and the
      // demotion probably will have to be dealt with differently)
      if (this.model.managingGroup) {
        await this.model.managingGroup.save();
      }
    }
  }
  var _default = _exports.default = FormsEditController;
});