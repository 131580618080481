define("plutof/components/search/results-download-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/helpers/locale-string", "plutof/misc/profile_settings"], function (_exports, _component, _templateFactory, _component2, _object, _service, _localeString, _profile_settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.downloadTooltip as |tooltip|}}
      <ToolTip @tooltip={{tooltip}}>
          <PromiseButton
              @clicked={{this.download}}
              @restIcon="panel-header-btn__icon {{icon 'download'}}"
              @disabled={{@disabled}}
              class="panel-header-btn" />
      </ToolTip>
  {{/resolve-promise}}
  
  */
  {
    "id": "Um5tuLLN",
    "block": "[[[6,[39,0],[[30,0,[\"downloadTooltip\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@tooltip\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"panel-header-btn\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,0,[\"download\"]],[29,[\"panel-header-btn__icon \",[28,[37,3],[\"download\"],null]]],[30,2]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"tooltip\",\"@disabled\"],false,[\"resolve-promise\",\"tool-tip\",\"promise-button\",\"icon\"]]",
    "moduleName": "plutof/components/search/results-download-button.hbs",
    "isStrictMode": false
  });
  let SearchResultsDownload = (_class = class SearchResultsDownload extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get downloadTooltip() {
      return (0, _profile_settings.get_personal_settings)(this.store).then(settings => {
        const limit = Math.min(10000, settings.clipboard_size);
        return this.i18n.translate('Search.downloadTooltip')((0, _localeString.localeString)(this.store, limit));
      });
    }
    download() {
      return this.args.download.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "downloadTooltip", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "downloadTooltip"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchResultsDownload);
});