define("plutof/components/transaction/view/objects/row/edit-controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @cancel}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.cancel.tooltip"}}>
          {{i18n-t "transactions.objects.actions.cancel.label"}}
      </ToolTip>
  </button>
  
  <button
      {{on "click" @save}}
      class="btn btn-link observation-table__control"
      disabled={{@disableSave}}
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.save.tooltip"}}>
          {{i18n-t "transactions.objects.actions.save.label"}}
      </ToolTip>
  </button>
  
  */
  {
    "id": "CzFWOxLi",
    "block": "[[[11,\"button\"],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"transactions.objects.actions.cancel.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"transactions.objects.actions.cancel.label\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-link observation-table__control\"],[16,\"disabled\",[30,2]],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"transactions.objects.actions.save.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"transactions.objects.actions.save.label\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@cancel\",\"@disableSave\",\"@save\"],false,[\"on\",\"tool-tip\",\"i18n-t\"]]",
    "moduleName": "plutof/components/transaction/view/objects/row/edit-controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});