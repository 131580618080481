define("plutof/components/filerepository/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "ember-concurrency", "plutof/components/auto-complete/backends", "plutof/misc/fileupload"], function (_exports, _component, _templateFactory, _component2, _service, _object, _emberConcurrency, _backends, _fileupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @params={{merge-params @params (hash
          sideAction=(if @localSelected (perform this.selectLocal))
          sideActionIcon=(icon "upload")
      )}}
      ...attributes
  >
      <:result as |result context|>
          {{#if result.full.type}}
              <Filerepository::TypeIcon @type={{result.full.type}} />
          {{/if}}
  
          {{hl-subs result.value context.query}}
  
          {{#if result.full.owner}}
              <span class="plutof-ac-suggestion-description">
                  {{result.full.owner}}
              </span>
          {{/if}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "jYdD8WHo",
    "block": "[[[8,[39,0],[[17,1]],[[\"@backend\",\"@value\",\"@update\",\"@params\"],[[30,0,[\"backend\"]],[30,2],[30,3],[28,[37,1],[[30,4],[28,[37,2],null,[[\"sideAction\",\"sideActionIcon\"],[[52,[30,5],[28,[37,4],[[30,0,[\"selectLocal\"]]],null]],[28,[37,5],[\"upload\"],null]]]]],null]]],[[\"result\"],[[[[1,\"\\n\"],[41,[30,6,[\"full\",\"type\"]],[[[1,\"            \"],[8,[39,6],null,[[\"@type\"],[[30,6,[\"full\",\"type\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[1,[28,[35,7],[[30,6,[\"value\"]],[30,7,[\"query\"]]],null]],[1,\"\\n\\n\"],[41,[30,6,[\"full\",\"owner\"]],[[[1,\"            \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                \"],[1,[30,6,[\"full\",\"owner\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[6,7]]]]],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@update\",\"@params\",\"@localSelected\",\"result\",\"context\"],false,[\"auto-complete/base\",\"merge-params\",\"hash\",\"if\",\"perform\",\"icon\",\"filerepository/type-icon\",\"hl-subs\"]]",
    "moduleName": "plutof/components/filerepository/auto-complete.hbs",
    "isStrictMode": false
  });
  let FileAutoComplete = (_dec = (0, _object.computed)('args.access', 'args.filters'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class FileAutoComplete extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "selectLocal", _descriptor4, this);
    }
    // TODO: Might as well fold access into filters on call site,
    // now that we just have to pass to them here
    get backend() {
      const filters = Object.assign({}, this.args.filters || {});
      if (this.args.access) {
        filters.access = this.args.access;
      }
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'filerepository/file',
        filters
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectLocal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (update) {
        const files = yield (0, _fileupload.selectFile)({
          multiple: !this.args.localSelectSingle
        });
        if (files && this.args.localSelected) {
          this.args.localSelected(files);
        }
      };
    }
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileAutoComplete);
});