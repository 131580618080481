define("plutof/templates/components/compound/compound-tree", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hwisMcK+",
    "block": "[[[10,0],[14,0,\"tree-statistics\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"isTerminal\"]],[[[1,\"            \"],[10,1],[14,0,\"fas fa-asterisk headerIcon\"],[12],[13],[1,\"\\n\\n            \"],[11,3],[16,0,[29,[\"clickable \",[52,[30,0,[\"inPath\"]],\"in-path\"],\" \",[52,[30,0,[\"isLastInPath\"]],\"last-in-path\"]]]],[4,[38,1],[\"click\",[30,0,[\"select\"]]],null],[12],[1,\"\\n                \"],[1,[30,0,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,1],[16,0,[29,[\"headerIcon clickable\\n                \",[52,[30,0,[\"expanded\"]],\"expanded\",\"collapsed\"],\"\\n                \",[52,[30,0,[\"children\",\"isPending\"]],\"loading\",\"not-loading\"]]]],[4,[38,1],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,1],[15,0,[29,[[52,[30,0,[\"inPath\"]],\"in-path\"],\" \",[52,[30,0,[\"isLastInPath\"]],\"last-in-path\"]]]],[12],[1,\"\\n                \"],[1,[30,0,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,1],[[[1,\"              (\"],[1,[30,1]],[1,\")\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"        \"],[10,0],[14,0,\"children\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"children\"]]],null]],null],null,[[[1,\"                \"],[8,[39,4],null,[[\"@path\",\"@threshold\",\"@version\",\"@parentTaxon\",\"@parentLevel\",\"@includeSingletons\",\"@level\",\"@taxon\",\"@count\",\"@nodeSelected\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,2,[\"level\"]],[30,2,[\"taxon\"]],[30,2,[\"count\"]],[30,9]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@count\",\"child\",\"@path\",\"@threshold\",\"@version\",\"@taxon\",\"@level\",\"@includeSingletons\",\"@nodeSelected\"],false,[\"if\",\"on\",\"each\",\"-track-array\",\"compound/compound-tree\"]]",
    "moduleName": "plutof/templates/components/compound/compound-tree.hbs",
    "isStrictMode": false
  });
});