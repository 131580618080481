define("plutof/templates/experiment/pcr/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5D5LXiaQ",
    "block": "[[[10,0],[14,0,\"route-marker-pcr-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.pcr.routeTitles.edit\"],null],[30,0,[\"model\",\"experiment\"]],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"experiment\",\"materials\",\"linkedItemsData\",\"validationContext\",\"cancel\",\"save\"],[[33,4,[\"experiment\"]],[33,4,[\"materials\"]],[33,4,[\"linkedItemsData\"]],[33,5],[28,[37,6],[[30,0],\"cancel\"],null],[28,[37,6],[[30,0],\"saveExperiment\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"experiment/pcr/edit-form\",\"model\",\"validationContext\",\"action\"]]",
    "moduleName": "plutof/templates/experiment/pcr/edit.hbs",
    "isStrictMode": false
  });
});