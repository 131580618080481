define("plutof/templates/components/observation/taxon-sheet/dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x/KwRJ+N",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\",\"@fitContent\"],[[30,1],[30,0,[\"close\"]],true]],[[\"header\",\"header-buttons\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"Observations.showTaimeatlas\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"hasMultipleSheets\"]],[[[1,\"            \"],[1,[28,[35,3],null,[[\"content\",\"selection\",\"optionLabelPath\",\"optionValuePath\",\"tagName\",\"selectClass\",\"prompt\"],[[30,2,[\"sheets\"]],[30,0,[\"selectedSheet\"]],\"name\",\"name\",\"\",\"\",false]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]],[[[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[18,3,[[28,[37,5],null,[[\"sheet\",\"selection\",\"entries\",\"toggle\"],[[30,0,[\"selectedSheet\"]],[30,0,[\"selectedSheetItems\"]],[30,0,[\"entries\"]],[28,[37,6],[[30,0],[30,0,[\"toggle\"]]],null]]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]],[[[1,\"\\n        \"],[8,[39,7],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@restIcon\"],[[30,0,[\"add\"]],\"fas fa-plus\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[30,0,[\"close\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@show\",\"@sheetset\",\"&default\"],false,[\"ui/modal/dialog\",\"i18n-t\",\"if\",\"plutof-select/object\",\"yield\",\"hash\",\"action\",\"promise-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/dialog.hbs",
    "isStrictMode": false
  });
});