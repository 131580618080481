define("plutof/models/sample/samplingevent", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data", "@ember-data/model", "rsvp", "plutof/misc/abstract", "plutof/models/plutof-model", "plutof/utils/formatters", "plutof/utils/reflection"], function (_exports, _object, _computed, _utils, _emberData, _model, _rsvp, _abstract, _plutofModel, _formatters, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SamplingEvent = (_dec = (0, _model.belongsTo)('sample/samplingarea', {
    inverse: null
  }), _dec2 = (0, _model.belongsTo)('habitat/habitat', {
    own: true,
    inverse: null
  }), _dec3 = (0, _model.attr)('ambiguous-datetime'), _dec4 = (0, _model.attr)('ambiguous-datetime'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('text'), _dec7 = (0, _model.attr)('json', {
    representation(value) {
      return value.map(person => person.name).join(', ');
    }
  }), _dec8 = (0, _object.computed)('gathering_agents.[]'), _dec9 = (0, _object.computed)('timespan_begin', 'timespan_end'), _dec10 = (0, _object.computed)('event_id', 'samplingarea.representation', 'timespanRepresentation'), _dec11 = (0, _computed.alias)('samplingarea.study'), _class = class SamplingEvent extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "samplingarea", _descriptor, this);
      _initializerDefineProperty(this, "habitat", _descriptor2, this);
      _initializerDefineProperty(this, "timespan_begin", _descriptor3, this);
      _initializerDefineProperty(this, "timespan_end", _descriptor4, this);
      _initializerDefineProperty(this, "event_id", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
      _defineProperty(this, "clipboardProperty", 'samplingevent');
      _initializerDefineProperty(this, "gathering_agents", _descriptor7, this);
      _initializerDefineProperty(this, "project", _descriptor8, this);
    }
    // Maps gathering_agents to a promise array with corresponding persons
    // Note: doesn't respond to add/remove, so to prepare event for saving you should
    // assign it an array of people
    get gatheringAgents() {
      const store = this.get('store');
      const agentIDs = (this.get('gathering_agents') || []).mapBy('url').map(_reflection.recordURLToID);
      return _emberData.default.PromiseArray.create({
        promise: _rsvp.default.all(agentIDs.map(id => store.findRecord('agent/person', id)))
      });
    }
    set gatheringAgents(agents) {
      this.set('gathering_agents', agents.map(agent => {
        return {
          url: (0, _reflection.get_record_url)(agent),
          name: agent.get('representation')
        };
      }));
      return _emberData.default.PromiseArray.create({
        promise: (0, _abstract.wrap_as_promise)(agents)
      });
    }
    get timespanRepresentation() {
      return (0, _formatters.formatTimespan)(this.timespan_begin, this.timespan_end);
    }
    get representation() {
      if (this.event_id) {
        return this.event_id;
      }
      let timespan = this.timespanRepresentation;
      let area = this.get('samplingarea.representation');
      return (0, _utils.isPresent)(timespan) ? `${area} ${timespan}` : area;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "samplingarea", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "habitat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timespan_begin", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "timespan_end", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "event_id", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "gathering_agents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "gatheringAgents", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "gatheringAgents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "timespanRepresentation", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "timespanRepresentation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = SamplingEvent;
});