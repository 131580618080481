define("plutof/templates/components/layout/regular-grid", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6ABjAvhD",
    "block": "[[[11,0],[16,5,[30,0,[\"style\"]]],[16,0,[29,[[30,1],\" \",[30,0,[\"dynamicGridClass\"]]]]],[17,2],[12],[1,\"\\n    \"],[18,3,[[28,[37,1],null,[[\"span\"],[[50,\"layout/-grid/span\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/layout/regular-grid.hbs",
    "isStrictMode": false
  });
});