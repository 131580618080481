define("plutof/components/date-time-input", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "moment"], function (_exports, _component, _object, _computed, _runloop, _utils, _component2, _object2, _emberCpValidations, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const FULL_DATE_REGEXP = new RegExp('^([0-9]{4})-(0[0-9]|1[0-2])-([0-2][0-9]|3[0-1])$');
  const SHORT_DATE = 4;
  const MEDIUM_DATE = 7;
  const LONG_DATE = 10;
  const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
  const Validations = (0, _emberCpValidations.buildValidations)({
    'timeIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'dateIsValid': (0, _emberCpValidations.validator)('is-truthy'),
    'isAfter': (0, _emberCpValidations.validator)('is-truthy')
  });
  let DateTimeInput = (_dec = (0, _component2.classNames)('date-time-input'), _dec2 = (0, _computed.or)('dateIsIncomplete', 'disabled'), _dec3 = (0, _object2.observes)('value'), _dec4 = (0, _object2.observes)('dateInput', 'timeInput'), _dec5 = (0, _object.computed)('timeInputIsValid', 'timeInput', 'dateIsIncomplete'), _dec6 = (0, _object.computed)('dateInputIsValid', 'dateInput', 'sweetHack'), _dec7 = (0, _object2.observes)('dateInput'), _dec8 = (0, _object.computed)('value', 'after'), _dec9 = (0, _object2.observes)('dateInput'), _dec10 = (0, _object2.observes)('dateIsIncomplete'), _dec11 = (0, _object2.observes)('validations.isValid'), _dec(_class = (_class2 = class DateTimeInput extends _component.default.extend(Validations) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "inputClass", 'form-control');
      _defineProperty(this, "dateFormat", DEFAULT_DATE_FORMAT);
      _defineProperty(this, "timeFormat", 'HH:mm');
      _defineProperty(this, "after", null);
      // Date input as a string.
      _defineProperty(this, "ambiguous", true);
      _defineProperty(this, "dateIsRequired", false);
      _defineProperty(this, "timeIsRequired", false);
      _defineProperty(this, "dateIsIncomplete", true);
      _defineProperty(this, "dateInputIsValid", true);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "useEmptyLabels", true);
      // Use empty (&nbsp;) labels when label path is not given.
      _defineProperty(this, "dateLabelPath", '');
      _defineProperty(this, "timeLabelPath", '');
      _defineProperty(this, "dateLabelSource", 'model');
      _defineProperty(this, "timeLabelSource", 'model');
      _initializerDefineProperty(this, "disableTime", _descriptor, this);
      // Expected to be overwritten with translated strings, by parent.
      _defineProperty(this, "dateLabel", null);
      _defineProperty(this, "timeLabel", null);
      _defineProperty(this, "dateTooltip", null);
    }
    // Fill inputs with existing date and time.
    init() {
      super.init();
      this.initFromValue();
      // internalValue is used because value/timeInput/dateInput depend on each other
      // and we need to distinguish between value updates that come from inside and outside
      this.set('internalValue', '');
      this.initValidity();
      this.fixme(); // ugh
      this.updateDateCompleteness();
      this.validationChanged();
    }
    initFromValue() {
      var value = this.value;
      var date = '';
      var time = '';
      if (value === this.internalValue) {
        return;
      }
      if (!(0, _utils.isNone)(value)) {
        switch (this.get('value.length')) {
          case SHORT_DATE:
            date = value;
            break;
          case MEDIUM_DATE:
            date = value;
            break;
          case LONG_DATE:
            date = value;
            break;
          default:
            {
              date = moment(value).format(DEFAULT_DATE_FORMAT);
              time = moment(value).format(this.timeFormat);
              date = date === 'Invalid date' ? '' : date;
              time = time === 'Invalid date' ? '' : time;
            }
        }
      }
      this.set('dateInput', date);
      this.set('timeInput', time);
    }
    initValidity() {
      // XXX It seems that component init() call order has changed, therefore time-input sets
      // initial validity before the binding is established
      if ((0, _utils.isNone)(this.timeInputIsValid)) {
        this.set('timeInputIsValid', true);
      }
      if ((0, _utils.isNone)(this.dateInputIsValid)) {
        this.set('dateInputIsValid', true);
      }
    }
    getDateFormat(date, ambiguous) {
      let format;
      switch (date.length) {
        case SHORT_DATE:
          format = 'YYYY';
          break;
        case MEDIUM_DATE:
          format = 'YYYY-MM';
          break;
        default:
          format = DEFAULT_DATE_FORMAT;
          break;
      }
      return ambiguous ? format : DEFAULT_DATE_FORMAT;
    }
    updateValue() {
      let value = '';
      if (!(0, _utils.isEmpty)(this.dateInput)) {
        var dateFormat = this.getDateFormat(this.dateInput, this.ambiguous);
        var date = moment(this.dateInput).format(dateFormat);
        var time = (0, _utils.isEmpty)(this.timeInput) ? '' : moment(date + ' ' + this.timeInput).format(this.timeFormat);
        date = [SHORT_DATE, MEDIUM_DATE, LONG_DATE].includes(date.length) ? date : '';
        time = time === 'Invalid date' ? '' : time;
        value = (date + ' ' + time).trim();
      }
      this.set('internalValue', value);
      this.set('value', value);
    }

    // Set target value with date and time combined from inputs.
    setValue() {
      (0, _runloop.once)(this, 'updateValue');
    }
    get timeIsValid() {
      var required = this.timeIsRequired;
      var empty = (0, _utils.isEmpty)(this.timeInput);
      var valid = this.timeInputIsValid;
      var completeDate = !this.dateIsIncomplete;
      return required ? !empty && completeDate && valid : valid;
    }
    get dateIsValid() {
      var required = this.dateIsRequired;
      var empty = (0, _utils.isEmpty)(this.dateInput);
      var valid = this.dateInputIsValid;
      return required ? !empty && valid : valid;
    }

    // XXX Shameful disgrace
    // I honestly don't know why setting dateInput in init does not trigger
    // re-evalutation of dateIsValid.
    fixme() {
      this.notifyPropertyChange('sweetHack');
    }
    get isAfter() {
      var value = this.value;
      var after = this.after;
      return (0, _utils.isEmpty)(after) || (0, _utils.isEmpty)(value) || moment(value).isAfter(after);
    }
    updateDateCompleteness() {
      this.set('dateIsIncomplete', !FULL_DATE_REGEXP.test(this.dateInput));
    }
    timeResetter() {
      if (this.dateIsIncomplete) {
        this.set('timeInput', null);
      }
    }
    updateValidity() {
      this.set('validity', this.get('validations.isValid'));
    }
    validationChanged() {
      (0, _runloop.once)(this, 'updateValidity');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "disableTime", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "initFromValue", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "initFromValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setValue", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "timeIsValid", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "timeIsValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "dateIsValid", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "dateIsValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fixme", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "fixme"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isAfter", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isAfter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateDateCompleteness", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateDateCompleteness"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "timeResetter", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "timeResetter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "validationChanged", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "validationChanged"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = DateTimeInput;
});