define("plutof/components/tables/column-control/-pin", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @pinColumn}}
      class="column-control-btn {{if @isPinned 'column-control-btn--active'}}"
      ...attributes
  >
      <ToolTip @tooltip={{i18n-t "Table.actions.pinColumnTooltip"}}>
          <span class="fas fa-thumbtack"></span>
      </ToolTip>
  </button>
  
  */
  {
    "id": "8wuACLRE",
    "block": "[[[11,\"button\"],[16,0,[29,[\"column-control-btn \",[52,[30,1],\"column-control-btn--active\"]]]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@tooltip\"],[[28,[37,3],[\"Table.actions.pinColumnTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"fas fa-thumbtack\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isPinned\",\"&attrs\",\"@pinColumn\"],false,[\"if\",\"on\",\"tool-tip\",\"i18n-t\"]]",
    "moduleName": "plutof/components/tables/column-control/-pin.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});