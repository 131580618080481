define("plutof/components/search/filter-panels/trait-properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.module}} />
          <Search::FilterInput @filter={{@filterInputs.type}} />
          <Search::FilterInput @filter={{@filterInputs.language}} />
      </Layout::RegularGrid>
  
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "OkDC84FJ",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"module\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"type\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"language\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"search/filter-input\",\"search/filter-panels/common-properties/agents\"]]",
    "moduleName": "plutof/components/search/filter-panels/trait-properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});