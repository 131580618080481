define("plutof/components/profile/-bookmark-list-row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td class="bookmark-row__bookmark-title">
          {{@bookmark.personal_link_title}}
      </td>
  
      <td>
          <ToolTip @tooltip={{@bookmark.fullURL}}>
              <a href={{@bookmark.fullURL}} target="_blank">
                  {{short-string @bookmark.fullURL 70}}
              </a>
          </ToolTip>
      </td>
  
      <td class="align-right">
          <Tables::RemoveButton @clicked={{@remove}} />
      </td>
  </tr>
  
  */
  {
    "id": "m6iTT1Ry",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,0,\"bookmark-row__bookmark-title\"],[12],[1,\"\\n        \"],[1,[30,1,[\"personal_link_title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@tooltip\"],[[30,1,[\"fullURL\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"fullURL\"]]],[14,\"target\",\"_blank\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[[30,1,[\"fullURL\"]],70],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@clicked\"],[[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@bookmark\",\"@remove\"],false,[\"tool-tip\",\"short-string\",\"tables/remove-button\"]]",
    "moduleName": "plutof/components/profile/-bookmark-list-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});