define("plutof/components/search/results-table", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember/utils", "ember-concurrency", "@ember-decorators/object", "plutof/misc/clipboard", "plutof/mixins/scrollbar-top", "plutof/utils/clipboard"], function (_exports, _component, _object, _computed, _runloop, _service, _utils, _emberConcurrency, _object2, _clipboard, _scrollbarTop, _clipboard2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getClipboardClassName = getClipboardClassName;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function createElementWithClass(tag, className) {
    const e = document.createElement(tag);
    e.className = className;
    return e;
  }
  function toggleClipboard(ajax, store, row, node) {
    node.className = 'loading-icon';
    store.findRecord('contenttype', row.contentTypeId).then(ctype => {
      const status = (0, _clipboard.get_clipboard_status)(ajax, store, ctype, row.recordId);
      status.check(row).then(function () {
        if (!(0, _utils.isNone)(status) && status.get('checked')) {
          status.change(!status.get('included')).then(function () {
            node.className = getClipboardClassName(status.get('included'));
            row.set('on_clipboard', status.get('included'));
          });
        }
      });
    });
  }
  function selectRow(self, row) {
    // TODO Update just one, not all
    (0, _runloop.next)(self, self.updateAllRowsSelected);
    if (self.selectRow) {
      self.selectRow(row);
    }
  }
  function getCellClassNames(isVisible, isPinned) {
    let classNames = 'table-cell';
    if (!isVisible) {
      classNames += ' table-cell--hidden';
    }
    if (isPinned) {
      classNames += ' table-cell--pinned';
    }
    return classNames;
  }
  function getClipboardClassName(state) {
    return state ? 'btn-link icon-clipboard-remove clickable' : 'btn-link icon-clipboard-add clickable';
  }
  let ResultsTable = (_dec = (0, _computed.notEmpty)('contentType.id'), _dec2 = (0, _computed.notEmpty)('rows.firstObject.icon'), _dec3 = (0, _object.computed)('showIconColumn'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = (0, _object2.observes)('rows', 'mustUpdate', 'canSendToClipboard'), _dec6 = (0, _object.computed)('headers.@each.isVisible'), _dec7 = (0, _object.computed)('headers.@each.isPinned'), _dec8 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec9 = (0, _object2.observes)('rows.@each.updatingClipboard', 'canSendToClipboard'), _dec10 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec11 = (0, _object2.observes)('headers.@each.isVisible', 'pinnedColumnName'), _dec12 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec13 = (0, _object2.observes)('rows'), _class = class ResultsTable extends _component.default.extend(_scrollbarTop.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "mustUpdate", false);
      _defineProperty(this, "tableBodyId", 'search-results-table-body');
      _defineProperty(this, "tableHeaderRowId", 'search-results-table-header-row');
      _initializerDefineProperty(this, "canSendToClipboard", _descriptor3, this);
      _initializerDefineProperty(this, "showIconColumn", _descriptor4, this);
      _defineProperty(this, "allRowsSelected", false);
      _defineProperty(this, "enableRowSelection", false);
      /* eslint-disable require-yield */
      _initializerDefineProperty(this, "_updateAllRowsSelected", _descriptor5, this);
      // Visual
      _initializerDefineProperty(this, "updateClipboardLoading", _descriptor6, this);
      // Update table cell visibility
      _initializerDefineProperty(this, "toggleColumns", _descriptor7, this);
      _initializerDefineProperty(this, "_rowObserver", _descriptor8, this);
    }
    get cellOffset() {
      // Two cells for row number and one for record icon
      return this.showIconColumn ? 2 : 1;
    }
    init() {
      super.init(...arguments);
    }
    /* eslint-enable require-yield */

    // TODO: Why is allRowsSelected not just a CP?
    updateAllRowsSelected() {
      this._updateAllRowsSelected.perform();
    }

    // TODO Tidy: better structure for handling necessary conditionals
    // XXX: Gets called from TopScrollbar
    // TODO: This feels like it gets called more than necessary
    generateTable() {
      const rows = this.rows;
      const store = this.store;
      const ajax = this.ajax;
      const self = this; // XXX
      const canSendToClipboard = this.canSendToClipboard;
      const enableRowSelection = this.enableRowSelection;
      const tableBody = document.getElementById('search-results-table-body');
      const bodyFragment = document.createDocumentFragment();
      if (!this.mustUpdate) {
        return;
      } else if ((0, _utils.isEmpty)(rows) || (0, _utils.isNone)(tableBody)) {
        tableBody.innerHTML = '';
        return;
      } else {
        tableBody.innerHTML = '';
      }
      rows.forEach((row, rowIndex) => {
        const tr = createElementWithClass('tr', 'search-table__row');
        const ordinalTd = createElementWithClass('td', 'table-cell table-cell--ordinal');
        const iconTd = createElementWithClass('td', 'table-cell table-cell--center');
        const iconLink = createElementWithClass('a', 'btn-link');
        const clipboardTd = createElementWithClass('td', 'table-cell table-cell--center');
        const clipboardButton = createElementWithClass('span', getClipboardClassName(row.get('on_clipboard')));
        const showIconColumn = this.showIconColumn;
        const hlTrClass = 'search-table__row--highlight';
        if (row.isHighlighted) {
          tr.classList.add(hlTrClass);
        }
        if (row.ordinal) {
          const button = createElementWithClass('button', 'row-highlight-btn');
          button.innerHTML = row.ordinal;
          button.onclick = function (tr) {
            this.toggleProperty('isHighlighted');
            tr.classList.toggle(hlTrClass);
          }.bind(row, tr);
          ordinalTd.appendChild(button);
        }
        if (enableRowSelection) {
          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.checked = row.selected;
          checkbox.id = `row-checkbox-${rowIndex}`;
          checkbox.addEventListener('click', function () {
            selectRow(self, row);
          });
          ordinalTd.appendChild(checkbox);
        }
        const openRecord = event => {
          if (!event.getModifierState('Control') && !event.getModifierState('Meta')) {
            event.preventDefault();
            this.router.transitionTo(row.viewURL);
          }
        };
        iconLink.href = row.viewURL;
        iconLink.addEventListener('click', openRecord);
        iconLink.appendChild(createElementWithClass('span', row.icon));
        iconTd.appendChild(iconLink);
        clipboardButton.addEventListener('click', function () {
          toggleClipboard(ajax, store, row, this);
        });
        clipboardTd.appendChild(clipboardButton);
        tr.appendChild(ordinalTd);
        if (showIconColumn) {
          tr.appendChild(iconTd);
        }
        row.cells.forEach(cell => {
          const isPinned = cell.name === this.get('pinnedColumnName');
          const td = createElementWithClass('td', getCellClassNames(cell.isVisible, isPinned));
          const a = createElementWithClass('a', 'btn-link');
          const cellValue = cell.value ? cell.value : '\u00A0'; // Structual integrity

          a.href = row.viewURL;
          a.addEventListener('click', openRecord);
          if (cell.colSpan) {
            td.colSpan = cell.colSpan;
          }
          if (!(0, _utils.isEmpty)(cell.icons)) {
            const cellFragment = document.createDocumentFragment();
            cell.icons.forEach(function (iconClass) {
              const el = createElementWithClass('span', 'table-cell__related-icon ' + iconClass);
              cellFragment.appendChild(el);
            });
            a.appendChild(cellFragment);
          } else {
            a.appendChild(document.createTextNode(cellValue));
          }
          td.appendChild(a);
          tr.appendChild(td);
        });
        if (canSendToClipboard) {
          tr.appendChild(clipboardTd);
        }
        bodyFragment.appendChild(tr);
      });
      tableBody.appendChild(bodyFragment);
    }
    get canRemoveHeaders() {
      return this.headers.filter(h => h.isVisible).length > 1;
    }
    get pinnedColumnName() {
      const pinnedHeader = this.get('headers').find(header => header.isPinned);
      if ((0, _utils.isNone)(pinnedHeader)) {
        return null;
      }
      return pinnedHeader.name;
    }
    // Visual
    triggerClipboardLoading() {
      this.updateClipboardLoading.perform();
    }
    triggerColumnUpdate() {
      this.toggleColumns.perform();
    }
    // Update checkboxes. It doesn't make me happy.
    rowObserver() {
      this._rowObserver.perform();
    }
    _selectAllRows() {
      const rows = this.rows;
      const tableBody = document.getElementById('search-results-table-body');
      const allRowsSelected = this.allRowsSelected;
      if ([rows, tableBody].any(_utils.isEmpty)) {
        return;
      }
      rows.forEach((row, rowIndex) => {
        const rowCheckbox = document.getElementById(`row-checkbox-${rowIndex}`);
        row.set('selected', !allRowsSelected);
        rowCheckbox.checked = !allRowsSelected;
      });
      (0, _runloop.next)(this, this.updateAllRowsSelected);
      if (this.selectAllRows) {
        this.selectAllRows(allRowsSelected);
      }
    }
    _copyColumn(name) {
      const values = this.rows.map(row => row.cells.findBy('name', name).value);
      (0, _clipboard2.sendToClipboard)(values.join('\n'));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canSendToClipboard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showIconColumn", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cellOffset", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "cellOffset"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_updateAllRowsSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        this.set('allRowsSelected', !(0, _utils.isEmpty)(this.rows) && !this.rows.some(row => !row.selected));
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "generateTable", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "generateTable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canRemoveHeaders", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "canRemoveHeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pinnedColumnName", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "pinnedColumnName"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "updateClipboardLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _emberConcurrency.timeout)(200);
        if (!this.canSendToClipboard) {
          return;
        }
        const rows = this.rows;
        const tableBody = document.getElementById('search-results-table-body');
        if (!(0, _utils.isNone)(rows) && !(0, _utils.isEmpty)(tableBody.childNodes) && rows.length === tableBody.childNodes.length) {
          rows.forEach((row, rowIndex) => {
            const clipboardCell = tableBody.childNodes[rowIndex].lastChild;

            // Under circumstances (not sure which ones) clibboardCell is undefined.
            if ((0, _utils.isPresent)(clipboardCell)) {
              const clipboardNode = clipboardCell.getElementsByTagName('span')[0];
              if (row.get('updatingClipboard')) {
                // Spin
                clipboardNode.className = 'loading-icon';
              } else {
                // Resolve to either checked or unchecked visual state
                clipboardNode.className = getClipboardClassName(row.get('on_clipboard'));
              }
            }
          });
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "triggerClipboardLoading", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "triggerClipboardLoading"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toggleColumns", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _emberConcurrency.timeout)(20);
        const tableBody = document.getElementById('search-results-table-body');
        const headers = this.headers;
        const cellOffset = this.cellOffset;
        const pinnedColumnName = this.get('pinnedColumnName');
        if (!(0, _utils.isEmpty)(headers) && !(0, _utils.isNone)(tableBody)) {
          headers.forEach((header, index) => {
            const isPinned = header.name === pinnedColumnName;

            // XXX Sync header's isPinned status.
            if (isPinned && !header.isPinned) {
              header.set('isPinned', true);
            }
            Array.from(tableBody.childNodes).forEach(rowNode => {
              const cellNode = rowNode.childNodes[index + cellOffset];
              if (cellNode) {
                cellNode.className = getCellClassNames(header.isVisible, isPinned);
              }
            });
          });
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "triggerColumnUpdate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "triggerColumnUpdate"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_rowObserver", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _emberConcurrency.timeout)(200);
        this.updateAllRowsSelected();
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "rowObserver", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "rowObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_selectAllRows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_selectAllRows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_copyColumn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_copyColumn"), _class.prototype), _class);
  var _default = _exports.default = ResultsTable;
});