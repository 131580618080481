define("plutof/components/annotation/record-annotations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/misc/content_types", "plutof/utils/pagination/builder"], function (_exports, _component, _templateFactory, _component2, _content_types, _builder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadAnnotations = loadAnnotations;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @pagination={{@annotations}}
      @title={{i18n-t "annotation.table.title"}}
      data-anchor="annotations"
  >
      <I18n::ModelStrings @model="annotation.client-annotation" as |translations|>
          <RelatedObjects::Content @pagination={{@annotations}} as |Field annotation|>
              <Annotation::Fields
                  @field={{Field}}
                  @annotation={{annotation}}
                  @translations={{translations}}
                  as |fields|
              >
                  <fields.icon />
  
                  <Field @label={{i18n-t "annotation.fields.status"}}>
                      <Annotation::AnnotationLink
                          @annotation={{annotation}}
                          @accessRights={{@accessRights}}
                      >
                          {{get translations.status.choices annotation.status}}
                      </Annotation::AnnotationLink>
                  </Field>
  
                  <fields.author/>
                  <fields.comment />
                  <fields.annotatedAt />
              </Annotation::Fields>
          </RelatedObjects::Content>
      </I18n::ModelStrings>
  </RelatedObjects::View>
  
  */
  {
    "id": "E5Ld375S",
    "block": "[[[8,[39,0],[[24,\"data-anchor\",\"annotations\"]],[[\"@pagination\",\"@title\"],[[30,1],[28,[37,1],[\"annotation.table.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@model\"],[\"annotation.client-annotation\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@field\",\"@annotation\",\"@translations\"],[[30,3],[30,4],[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[30,5,[\"icon\"]],null,null,null],[1,\"\\n\\n                \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"annotation.fields.status\"],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[8,[39,5],null,[[\"@annotation\",\"@accessRights\"],[[30,4],[30,6]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[28,[35,6],[[30,2,[\"status\",\"choices\"]],[30,4,[\"status\"]]],null]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\\n                \"],[8,[30,5,[\"author\"]],null,null,null],[1,\"\\n                \"],[8,[30,5,[\"comment\"]],null,null,null],[1,\"\\n                \"],[8,[30,5,[\"annotatedAt\"]],null,null,null],[1,\"\\n            \"]],[5]]]]],[1,\"\\n        \"]],[3,4]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@annotations\",\"translations\",\"Field\",\"annotation\",\"fields\",\"@accessRights\"],false,[\"related-objects/view\",\"i18n-t\",\"i18n/model-strings\",\"related-objects/content\",\"annotation/fields\",\"annotation/annotation-link\",\"get\"]]",
    "moduleName": "plutof/components/annotation/record-annotations.hbs",
    "isStrictMode": false
  });
  async function loadAnnotations(ajax, record) {
    const ctype = await (0, _content_types.get_object_ctype)(record.store, record);
    const pagination = await (0, _builder.default)({
      ajax
    }).fromEndpoint('annotation/record-annotations/').withCount.fromEndpoint().withFilters({
      content_type: ctype.id,
      object_id: record.id,
      ordering: '-annotated_at'
    }).build();
    return pagination;
  }
  class RecordAnnotations extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordAnnotations);
});