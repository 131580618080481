define("plutof/components/information/content/general/info-empty", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @locale.eng}}
      Info yet to come...
  {{/if}}
  
  {{#if @locale.est}}
      Info saabub peagi...
  {{/if}}
  
  */
  {
    "id": "A2OPEYcW",
    "block": "[[[41,[30,1,[\"eng\"]],[[[1,\"    Info yet to come...\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"est\"]],[[[1,\"    Info saabub peagi...\\n\"]],[]],null]],[\"@locale\"],false,[\"if\"]]",
    "moduleName": "plutof/components/information/content/general/info-empty.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});