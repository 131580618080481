define("plutof/components/common/ui/buttons/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @clicked}}
      class="btn-fake-link"
  >
      {{yield}}
  </button>
  
  */
  {
    "id": "v0QMZXfy",
    "block": "[[[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@clicked\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "plutof/components/common/ui/buttons/link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});