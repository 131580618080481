define("plutof/templates/components/generic-views/record", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "+KrLOaO9",
    "block": "[[[41,[28,[37,1],[[30,1],[30,0,[\"modelPath\"]]],null],[[[1,\"    \"],[18,2,[[28,[37,3],null,[[\"attr\",\"field\",\"link\"],[[50,\"generic-views/-record/attr\",0,null,[[\"context\"],[[30,0]]]],[50,\"generic-views/-record/field\",0,null,[[\"context\"],[[30,0]]]],[50,\"generic-views/-record/link\",0,null,[[\"context\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[]],null]],[\"@record\",\"&default\"],false,[\"if\",\"and\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/generic-views/record.hbs",
    "isStrictMode": false
  });
});