define("plutof/templates/components/additional-identifiers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2zq6QGXl",
    "block": "[[[10,0],[14,0,\"add-component\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[33,1],\"\",\"has-error\"]]]],[12],[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[28,[35,2],[\"Specimen.additionalCode\"],null]],[1,\"\\n\\n\"],[41,[51,[33,1]],[[[1,\"                    (\"],[1,[28,[35,2],[\"Specimen.alreadyExistShort\"],null]],[1,\")\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\\n            \"],[1,[28,[35,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[33,5],\"form-control control additional-identifiers__code\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"path\"],[\"specimen.additionalidentifier.source\"]]]],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"value\",\"class\"],[\"text\",[33,7],\"form-control control additional-identifiers__source\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[28,[37,8],[[33,9]],null]],[16,0,[29,[\"\\n            \",[52,[33,9],\"\",\"disabled\"],\"\\n            btn btn-default control\\n            additional-identifiers__add-button\"]]],[4,[38,10],[\"click\",[30,0,[\"_add\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"fas fa-check\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,11],null,[[\"@records\",\"@remove\"],[[30,1],[28,[37,12],[[30,2],[30,0,[\"defaultRemove\"]]],null]]],null],[1,\"\\n\"]],[\"@additionalIdentifiers\",\"@remove\"],false,[\"if\",\"codeIsUnique\",\"i18n-t\",\"unless\",\"input\",\"code\",\"plutof-labelc\",\"source\",\"not\",\"canAdd\",\"on\",\"common/record-list\",\"or\"]]",
    "moduleName": "plutof/templates/components/additional-identifiers.hbs",
    "isStrictMode": false
  });
});