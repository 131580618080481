define("plutof/templates/experiment/dna/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "40Qc8sFZ",
    "block": "[[[10,0],[14,0,\"route-marker-dna-index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.dna.routeTitles.list\"],null],\"experiment.dna.add\",[28,[37,3],[\"goBack\"],null],[30,0,[\"model\",\"pagination\"]],[28,[37,2],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\"],[\"dna-lab.dna\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"name\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"experiment.dna.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"secondary_identifier\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"secondary_identifier\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,4,[\"modified\"]],null,null,null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"dna\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"route-action\",\"i18n/model-strings\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/experiment/dna/index.hbs",
    "isStrictMode": false
  });
});