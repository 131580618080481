define("plutof/models/taxonoccurrence/referencebased/speciesstatus", ["exports", "@ember-data/model", "plutof/models/plutof-model", "plutof/utils/display-name-from-value"], function (_exports, _model, _plutofModel, _displayNameFromValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FIELDS = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const displayName = (0, _displayNameFromValue.displayNames)('taxonoccurrence/referencebased/speciesstatus');

  // TODO - I guess this should die?
  const FIELDS = _exports.FIELDS = ['origin', 'presence', 'persistence', 'distribution', 'abundance', 'trend', 'rate_of_spread', 'harmful', 'regulatory_listing'];
  let SpeciesStatus = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = displayName('origin'), _dec11 = displayName('presence'), _dec12 = displayName('persistence'), _dec13 = displayName('distribution'), _dec14 = displayName('abundance'), _dec15 = displayName('trend'), _dec16 = displayName('rate_of_spread'), _dec17 = displayName('harmful'), _dec18 = displayName('regulatory_listing'), _class = class SpeciesStatus extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "origin", _descriptor, this);
      _initializerDefineProperty(this, "presence", _descriptor2, this);
      _initializerDefineProperty(this, "persistence", _descriptor3, this);
      _initializerDefineProperty(this, "distribution", _descriptor4, this);
      _initializerDefineProperty(this, "abundance", _descriptor5, this);
      _initializerDefineProperty(this, "trend", _descriptor6, this);
      _initializerDefineProperty(this, "rate_of_spread", _descriptor7, this);
      _initializerDefineProperty(this, "harmful", _descriptor8, this);
      _initializerDefineProperty(this, "regulatory_listing", _descriptor9, this);
      _initializerDefineProperty(this, "originDisplayName", _descriptor10, this);
      _initializerDefineProperty(this, "presenceDisplayName", _descriptor11, this);
      _initializerDefineProperty(this, "persistenceDisplayName", _descriptor12, this);
      _initializerDefineProperty(this, "distributionDisplayName", _descriptor13, this);
      _initializerDefineProperty(this, "abundanceDisplayName", _descriptor14, this);
      _initializerDefineProperty(this, "trendDisplayName", _descriptor15, this);
      _initializerDefineProperty(this, "rateOfSpreadDisplayName", _descriptor16, this);
      _initializerDefineProperty(this, "harmfulDisplayName", _descriptor17, this);
      _initializerDefineProperty(this, "regulatoryListingDisplayName", _descriptor18, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "origin", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "presence", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "persistence", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "distribution", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "abundance", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "trend", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rate_of_spread", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "harmful", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "regulatory_listing", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "originDisplayName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "presenceDisplayName", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "persistenceDisplayName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "distributionDisplayName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "abundanceDisplayName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "trendDisplayName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "rateOfSpreadDisplayName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "harmfulDisplayName", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "regulatoryListingDisplayName", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = SpeciesStatus;
});