define("plutof/components/layer/-map/area-popup", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="map-area-tooltip">
      <tbody>
          <tr>
              <td>
                  <div>
                      {{#if this.selectedArea.isLoaded}}
                          {{this.selectedArea.name}}
  
                          <button
                              {{on "click" (fn @updateFilter this.selectedArea.name)}}
                              title={{i18n-t "Layer.actions.findInList"}}
                              class="btn btn-link no-padding"
                          >
                              <span class={{icon "search"}}></span>
                          </button>
                      {{else}}
                          <span class="loading-icon"></span>
                      {{/if}}
                  </div>
              </td>
          </tr>
      </tbody>
  </table>
  
  */
  {
    "id": "S5B6X0Yb",
    "block": "[[[10,\"table\"],[14,0,\"map-area-tooltip\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n                \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"selectedArea\",\"isLoaded\"]],[[[1,\"                        \"],[1,[30,0,[\"selectedArea\",\"name\"]]],[1,\"\\n\\n                        \"],[11,\"button\"],[16,\"title\",[28,[37,1],[\"Layer.actions.findInList\"],null]],[24,0,\"btn btn-link no-padding\"],[4,[38,2],[\"click\",[28,[37,3],[[30,1],[30,0,[\"selectedArea\",\"name\"]]],null]],null],[12],[1,\"\\n                            \"],[10,1],[15,0,[28,[37,4],[\"search\"],null]],[12],[13],[1,\"\\n                        \"],[13],[1,\"\\n\"]],[]],[[[1,\"                        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@updateFilter\"],false,[\"if\",\"i18n-t\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/layer/-map/area-popup.hbs",
    "isStrictMode": false
  });
  let AreaPopup = (_dec = (0, _object.computed)('args.selectedGeometry.id'), _class = class AreaPopup extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get selectedArea() {
      const id = (0, _object.get)(this.args, 'selectedGeometry.id');
      if ((0, _utils.isNone)(id)) {
        return null;
      }
      return this.store.findRecord('geography/layer-area', id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedArea", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectedArea"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AreaPopup);
});