define("plutof/components/search/filters/time-range-input", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Search::Filters::-RangeInput
      @filter={{@filter}}
      @inputGroupClass="time-filter-group"
  >
      <:lower>
          <DatePicker
              @value={{@filter.lowerBound}}
              @invertLayout={{true}}
              @pickerClass="form-control time-filter-group__input"
              @class="time-filter-date-picker" />
      </:lower>
  
      <:upper as |disabled|>
          <DatePicker
              @value={{@filter.upperBound}}
              @invertLayout={{true}}
              @disabled={{disabled}}
              @pickerClass="form-control time-filter-group__input"
              @class="time-filter-date-picker" />
      </:upper>
  </Search::Filters::-RangeInput>
  
  */
  {
    "id": "6QxF6xbV",
    "block": "[[[8,[39,0],null,[[\"@filter\",\"@inputGroupClass\"],[[30,1],\"time-filter-group\"]],[[\"lower\",\"upper\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@value\",\"@invertLayout\",\"@pickerClass\",\"@class\"],[[30,1,[\"lowerBound\"]],true,\"form-control time-filter-group__input\",\"time-filter-date-picker\"]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,1],null,[[\"@value\",\"@invertLayout\",\"@disabled\",\"@pickerClass\",\"@class\"],[[30,1,[\"upperBound\"]],true,[30,2],\"form-control time-filter-group__input\",\"time-filter-date-picker\"]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[\"@filter\",\"disabled\"],false,[\"search/filters/-range-input\",\"date-picker\"]]",
    "moduleName": "plutof/components/search/filters/time-range-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});