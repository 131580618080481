define("plutof/templates/components/common/radio-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hbjrZGpx",
    "block": "[[[18,4,[[28,[37,1],null,[[\"choice\"],[[50,\"common/-radio-group-choice\",0,null,[[\"active\",\"activate\",\"invalid\"],[[30,1],[28,[37,3],[[30,0],[30,2]],null],[30,3]]]]]]]]],[1,\"\\n\"]],[\"@active\",\"@activate\",\"@invalid\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "plutof/templates/components/common/radio-group.hbs",
    "isStrictMode": false
  });
});