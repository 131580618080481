define("plutof/translations/est/occurrence-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    samplingevent: 'Prooviala ja sündmus',
    specimen: 'Eksemplar',
    strain: 'Eluseksemplar',
    materialsample: 'Proov',
    none: 'Puudub'
  };
});