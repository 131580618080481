define("plutof/components/experiment/common/record-metadata", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel @highlight={{true}}>
              <vr.column>
                  {{plutof-labelc path="agent.organization.managing_group"}}
  
                  <LinkTo @route="profile.groups.group" @model={{@record.laboratory.managing_group.id}}>
                      {{@record.laboratory.managing_group.representation}}
                  </LinkTo>
              </vr.column>
  
              <vr.history
                  @createdBy={{@record.created_by.representation}}
                  @createdAt={{@record.created_at}}
                  @updatedBy={{@record.updated_by.representation}}
                  @updatedAt={{@record.updated_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "MW0cmoqn",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"panel\"]],null,[[\"@highlight\"],[true]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"column\"]],null,null,[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],null,[[\"path\"],[\"agent.organization.managing_group\"]]]],[1,\"\\n\\n                \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"profile.groups.group\",[30,2,[\"laboratory\",\"managing_group\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,2,[\"laboratory\",\"managing_group\",\"representation\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,1,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[30,2,[\"created_by\",\"representation\"]],[30,2,[\"created_at\"]],[30,2,[\"updated_by\",\"representation\"]],[30,2,[\"updated_at\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"vr\",\"@record\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-rights-base\",\"plutof-labelc\",\"link-to\"]]",
    "moduleName": "plutof/components/experiment/common/record-metadata.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});