define("plutof/templates/components/navbar/-navbar-base/upload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SWyRewgU",
    "block": "[[[8,[39,0],null,[[\"@clicked\",\"@pending\",\"@icon\"],[[30,0,[\"upload\"]],[30,0,[\"isUploading\"]],[28,[37,1],[\"add-new\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"pendingUploads\"]],[[[1,\"        \"],[1,[28,[35,3],[\"FileRepository.upload.progress\"],[[\"progress\"],[[30,0,[\"uploadProgress\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,1,null],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"navbar/-navbar-base/button\",\"icon\",\"if\",\"i18n-t\",\"yield\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/upload.hbs",
    "isStrictMode": false
  });
});