define("plutof/components/search/filters/vernacular-name-language", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @filter}}
      <div class="filter-input" data-input={{@filter.config.label}}>
          <div class="plutof-label">
              {{@filter.config.label}}
          </div>
  
          <AutoComplete::Model
              @model="geography/language"
              @update={{this.updateLanguage}}
              @value={{@filter.value.firstObject}}
              class="filter-input-ac" />
      </div>
  {{/if}}
  
  */
  {
    "id": "vFto9cdu",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,1],[[24,0,\"filter-input-ac\"]],[[\"@model\",\"@update\",\"@value\"],[\"geography/language\",[30,0,[\"updateLanguage\"]],[30,1,[\"value\",\"firstObject\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@filter\"],false,[\"if\",\"auto-complete/model\"]]",
    "moduleName": "plutof/components/search/filters/vernacular-name-language.hbs",
    "isStrictMode": false
  });
  let VernacularNameLanguage = (_class = class VernacularNameLanguage extends _component2.default {
    updateLanguage(language) {
      this.args.filter.set('value', language ? [language] : []);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateLanguage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateLanguage"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VernacularNameLanguage);
});