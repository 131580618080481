define("plutof/components/annotation/-fields/annotatedAt", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{i18n-t "annotation.fields.annotated_at"}}>
      {{formatted-date @annotation.annotated_at}}
  </@field>
  
  */
  {
    "id": "NUiUISWz",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"annotation.fields.annotated_at\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[30,2,[\"annotated_at\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@annotation\"],false,[\"i18n-t\",\"formatted-date\"]]",
    "moduleName": "plutof/components/annotation/-fields/annotatedAt.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});