define("plutof/components/occurrences-table/-responsive-row/action-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class="btn btn-link occ-table-responsive-row__action" {{on "click" @clicked}} ...attributes>
      <span class="{{@icon}}"></span>
  </button>
  
  */
  {
    "id": "dPbgHvAP",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[24,0,\"btn btn-link occ-table-responsive-row__action\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[30,3]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@clicked\",\"@icon\"],false,[\"on\"]]",
    "moduleName": "plutof/components/occurrences-table/-responsive-row/action-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});