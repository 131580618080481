define("plutof/components/information/content/observation/add", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="observation/add" @locale={{@locale}} />
  <Information::Content::Observation::General @locale={{@locale}} />
  
  */
  {
    "id": "WtT/BKfV",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@locale\"],[\"observation/add\",[30,1]]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@locale\"],[[30,1]]],null],[1,\"\\n\"]],[\"@locale\"],false,[\"information/markdown-reader\",\"information/content/observation/general\"]]",
    "moduleName": "plutof/components/information/content/observation/add.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});