define("plutof/utils/display-name-from-value", ["exports", "@ember/object", "plutof/misc/options-getter", "plutof/services/ajax"], function (_exports, _object, _optionsGetter, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayNames = displayNames;
  // XXX: This is written in a bullshit way to work with AnnotatedRecord
  // Not for general use (yet! we do need to migrate from DisplayNameMixin)
  function displayNames(model) {
    const optionsProperty = `__${model}-display-names`;
    return function (field) {
      return (0, _object.computed)(field, `${optionsProperty}.${field}`, function () {
        if (!this[optionsProperty]) {
          const ajax = this.ajax || this.emberContainer && this.emberContainer.lookup('service:ajax') || (0, _ajax.ajaxFromControlled)(this);
          (0, _object.set)(this, optionsProperty, {});
          (0, _optionsGetter.getModelTexts)(ajax, model).then(foo => {
            (0, _object.set)(this, optionsProperty, foo);
          });
        }
        const texts = (0, _object.get)(this, `${optionsProperty}.${field}`);
        if (!texts) {
          return null;
        }
        const match = texts.choices.findBy('value', this.get(field));
        return match ? match.display_name : null;
      });
    };
  }
});