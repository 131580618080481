define("plutof/templates/trait/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rLoAEF1Q",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[28,[37,1],[\"trait\"],null],[28,[37,2],[\"Forms.traits.trait\"],null],[30,0,[\"model\",\"trait\"]],\"measurement/measurement\",\"trait.edit\",true,[30,0,[\"model\",\"permissions\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"searchOccurrences\"]],[28,[37,1],[\"search\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.taxonOccurrences\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@trait\",\"@sources\",\"@setChoices\"],[[30,0,[\"model\",\"trait\"]],[30,0,[\"model\",\"sources\"]],[30,0,[\"model\",\"setChoices\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"trait\",\"isSet\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@choices\"],[[30,0,[\"model\",\"setChoices\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,6],null,[[\"@record\"],[[30,0,[\"model\",\"trait\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@trait\"],[[30,0,[\"model\",\"trait\"]]]],null],[1,\"\\n\"]],[\"navbar\",\"slots\"],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"trait/view/general-data\",\"if\",\"trait/view/set-choices\",\"history/view\",\"trait/view/permissions\"]]",
    "moduleName": "plutof/templates/trait/view.hbs",
    "isStrictMode": false
  });
});