define("plutof/templates/components/clipboard/bulk-update-multi-select-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/hBKtNqp",
    "block": "[[[10,1],[12],[1,\" \"],[1,\" \"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[42,[28,[37,1],[[28,[37,1],[[33,3]],null]],null],null,[[[41,[28,[37,5],[[30,2,[\"value\"]],[30,1]],null],[[[6,[39,6],null,[[\"tagName\",\"fade-class\"],[\"span\",\"inline-block\"]],[[\"default\"],[[[[1,\"                \"],[10,1],[14,0,\"plutof-fade-in\"],[12],[1,\"\\n                    \"],[1,[30,2,[\"display_name\"]]],[1,\"\\n                    \"],[11,1],[24,0,\"icon-remove--red clickable\"],[4,[38,7],[[30,0],\"remove\",[33,2],[30,1]],null],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[2]],null]],[1]],null]],[\"selectedVal\",\"type\"],false,[\"each\",\"-track-array\",\"realValues\",\"optionList\",\"if\",\"is-equal\",\"fade-element\",\"action\"]]",
    "moduleName": "plutof/templates/components/clipboard/bulk-update-multi-select-list.hbs",
    "isStrictMode": false
  });
});