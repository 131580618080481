define("plutof/components/linked-items/-edit/-files/uploaded", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
      <td>
          <LinkTo @route="filerepository.view" @model={{@file.id}}>
              {{@file.representation}}
          </LinkTo>
      </td>
  
      <td>
          {{@file.licenceDisplayName}}
      </td>
  
      <td>
          {{@file.rights_owner.representation}}
      </td>
  
      <td>
          <Common::RecordListView @records={{@file.creators}} as |creator|>
              {{creator.representation}}
          </Common::RecordListView>
      </td>
  
      <td class="align-right">
          <button {{on "click" @remove}} class="btn btn-link">
              <span class={{icon "remove"}}></span>
          </button>
      </td>
  </tr>
  
  */
  {
    "id": "poqnqdCs",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"filerepository.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[30,1,[\"licenceDisplayName\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[30,1,[\"rights_owner\",\"representation\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@records\"],[[30,1,[\"creators\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"representation\"]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,3],[\"remove\"],null]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@file\",\"creator\",\"@remove\"],false,[\"link-to\",\"common/record-list-view\",\"on\",\"icon\"]]",
    "moduleName": "plutof/components/linked-items/-edit/-files/uploaded.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});