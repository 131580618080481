define("plutof/components/layer/general-data", ["exports", "@ember/component", "@ember/template-factory", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Layer::GeneralData"
  >
      <GenericForms::Record @record={{@layer}} as |form|>
          <Layout::RegularGrid @columns={{2}}>
              {{form.bind "name" valid=this.validations.attrs.layer.name.isValid}}
              {{form.bind "description" valid=this.validations.attrs.layer.description.isValid}}
              {{form.bind "public_linking"}}
          </Layout::RegularGrid>
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "xszNxPxt",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Layer::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"name\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"layer\",\"name\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"description\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"layer\",\"description\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"public_linking\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@layer\",\"form\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-forms/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/layer/general-data.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'layer.name': (0, _emberCpValidations.validator)('presence', true),
    'layer.description': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  class LayerGeneralData extends _component.default.extend(Validations, _componentValidations.default) {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LayerGeneralData);
});