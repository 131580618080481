define("plutof/templates/components/filerepository/file-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DDAQlcZh",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@update\",\"@access\",\"@localSelected\",\"@localSelectSingle\"],[[30,1],[30,2],\"edit\",[30,0,[\"upload\"]],true]],null],[1,\"\\n\"]],[\"@value\",\"@update\"],false,[\"filerepository/auto-complete\"]]",
    "moduleName": "plutof/templates/components/filerepository/file-selector.hbs",
    "isStrictMode": false
  });
});