define("plutof/templates/profile/groups/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NyKwpDyQ",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.groups.create\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"path\"],[\"workgroup.name\"]]]],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"control-group \",[52,[33,4,[\"attrs\",\"model\",\"group\",\"name\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"type\",\"value\",\"class\"],[\"text\",[33,6,[\"group\",\"name\"]],\"form-control\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"path\"],[\"workgroup.description\"]]]],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"control-group \",[52,[33,4,[\"attrs\",\"model\",\"group\",\"description\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n        \"],[8,[39,7],[[24,0,\"form-control\"]],[[\"@value\"],[[33,6,[\"group\",\"description\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large-below\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n    \"],[1,[28,[35,8],null,[[\"save\",\"disabled\"],[[28,[37,9],[[30,0],\"save\"],null],[33,10,[\"isInvalid\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,11],null,[[\"cancel\"],[[28,[37,12],[\"goBack\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"common/legend\",\"i18n-t\",\"plutof-labelc\",\"if\",\"validations\",\"input\",\"model\",\"flexible-textarea\",\"common/save-button\",\"action\",\"validationContext\",\"common/cancel-button\",\"route-action\"]]",
    "moduleName": "plutof/templates/profile/groups/add.hbs",
    "isStrictMode": false
  });
});