define("plutof/templates/analysis/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5StwrFEE",
    "block": "[[[10,0],[14,0,\"route-marker-analysis-view\"],[14,\"data-test\",\"route-analysis.view\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@permissions\",\"@deletable\",\"@neverDeleteRelatedFiles\",\"@infoContent\"],[[28,[37,1],[\"analysis\"],null],[28,[37,2],[\"Analysis.analysis\"],null],[30,0,[\"model\",\"run\"]],\"analysis/run\",[30,0,[\"permissions\"]],true,true,\"information/content/analysis/general\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"model\",\"run\",\"status\"]],\"prerun\"],null],[[[1,\"            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"start\"]],\"fas fa-play-circle\"]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"Analysis.start\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canGenerateReport\"]],[[[1,\"            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"generateReport\"]],[28,[37,1],[\"file\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"Analysis.generateReport\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canDownloadResults\"]],[[[1,\"            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"downloadResults\"]],[28,[37,1],[\"file\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"Analysis.downloadRawResults\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[1,[28,[35,5],null,[[\"run\"],[[33,6,[\"run\"]]]]]],[1,\"\\n\\n\"],[1,\"\\n\"],[46,[33,6,[\"type\",\"components\",\"contentView\"]],null,[[\"run\"],[[33,6,[\"run\"]]]],null],[1,\"\\n\"]],[\"navbar\",\"slots\"],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"if\",\"is-equal\",\"analysis/view/general-data\",\"model\",\"component\"]]",
    "moduleName": "plutof/templates/analysis/view.hbs",
    "isStrictMode": false
  });
});