define("plutof/templates/components/transaction/transaction-correspondence", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/1PaiCC7",
    "block": "[[[8,[39,0],null,[[\"@remove\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@record\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"type\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"correspondence\",\"type\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"sender\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"senderExists\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"receiver\"],[[\"valid\"],[[30,0,[\"validations\",\"attrs\",\"receiverExists\",\"isValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"sent_date\"],null]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"received_date\"],null]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"content\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@remove\",\"@correspondence\",\"form\"],false,[\"plutof-panel/inner-form\",\"generic-forms/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/components/transaction/transaction-correspondence.hbs",
    "isStrictMode": false
  });
});