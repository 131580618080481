define("plutof/components/annotation/fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      icon=(component "annotation/-fields/icon" field=@field annotation=@annotation)
      link=(component "annotation/-fields/link" field=@field annotation=@annotation)
      author=(component "annotation/-fields/author" field=@field annotation=@annotation)
      comment=(component "annotation/-fields/comment" field=@field annotation=@annotation)
      annotatedAt=(component "annotation/-fields/annotatedAt" field=@field annotation=@annotation)
      status=(component "annotation/-fields/status" field=@field annotation=@annotation translations=@translations)
      moderator=(component "annotation/-fields/moderator" field=@field annotation=@annotation)
  )}}
  
  */
  {
    "id": "Esh1sNJB",
    "block": "[[[18,4,[[28,[37,1],null,[[\"icon\",\"link\",\"author\",\"comment\",\"annotatedAt\",\"status\",\"moderator\"],[[50,\"annotation/-fields/icon\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]],[50,\"annotation/-fields/link\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]],[50,\"annotation/-fields/author\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]],[50,\"annotation/-fields/comment\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]],[50,\"annotation/-fields/annotatedAt\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]],[50,\"annotation/-fields/status\",0,null,[[\"field\",\"annotation\",\"translations\"],[[30,1],[30,2],[30,3]]]],[50,\"annotation/-fields/moderator\",0,null,[[\"field\",\"annotation\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n\"]],[\"@field\",\"@annotation\",\"@translations\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/annotation/fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});