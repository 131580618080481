define("plutof/adapters/publishing/gbif/base", ["exports", "@ember/object", "@ember-data/adapter/json-api", "plutof/config/environment", "plutof/adapters/auth"], function (_exports, _object, _jsonApi, _environment, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _jsonApi.default.extend(_auth.default, {
    buildURL: function (modelName, id, snapshot, requestType, query) {
      // We use model name without the 'publishing' path.
      let url = this._super(this.modelName, id, snapshot, requestType, query);

      // XXX Well, damn.
      if (url.charAt(url.length - 1) !== '/') {
        url += '/';
      }
      return url;
    },
    host: (0, _object.computed)(function () {
      return _environment.default.PUBLISHING_API_HOST;
    })
  });
});