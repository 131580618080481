define("plutof/templates/components/common/local-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tntb02GD",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,2]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"disableTooltip\"]]],[[[1,\"        \"],[10,1],[15,1,[29,[[30,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[[30,0,[\"actualTooltip\"]]],null]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"                \"],[1,[28,[35,4],null,[[\"infoLink\"],[[30,3]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"t\",\"@label\",\"@infoLink\"],false,[\"tooltip-wrapper\",\"i18n-t\",\"unless\",\"if\",\"info-link\"]]",
    "moduleName": "plutof/templates/components/common/local-label.hbs",
    "isStrictMode": false
  });
});