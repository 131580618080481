define("plutof/utils/search/modules", ["exports", "plutof/config/environment", "plutof/helpers/icon", "plutof/misc/config", "plutof/models/annotation/annotation", "plutof/utils/i18n", "plutof/utils/modules", "plutof/utils/search/filters", "plutof/utils/unite"], function (_exports, _environment, _icon, _config, _annotation, _i18n, _modules, _filters, _unite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const i18n = (0, _i18n.getI18n)();
  const CB_MODULES = _config.default.Clipboard.MODULES;
  // It used to be more involved, but now it's delegated to
  // Filter.extractFromQuery. But just in case, leaving the support here
  function modelRecordTopResult(model) {
    return function (store, result) {
      return [result.id];
    };
  }
  const useTopResults = function () {
    const TOP_RESULT_SPECS = {
      taxon_node: {
        id: 'taxon_node',
        filterValue: modelRecordTopResult('taxonomy/taxonnode'),
        label: i18n.t('Search.topResults.taxon')
      },
      country: {
        id: 'country',
        filterValue: modelRecordTopResult('geography/country'),
        label: i18n.t('Search.topResults.country')
      },
      gathering_agents: {
        id: 'gathering_agents',
        filterValue: modelRecordTopResult('agent/person'),
        label: i18n.t('Search.topResults.collector')
      },
      mainform: {
        id: 'mainform',
        filterValue: modelRecordTopResult('measurement/mainform'),
        label: i18n.t('Search.topResults.form')
      },
      deposited_in: {
        id: 'deposited_in',
        filterValue: modelRecordTopResult('agent/collection'),
        label: i18n.t('Search.topResults.collection')
      },
      seq_source: {
        id: 'seq_source',
        filterValue: modelRecordTopResult('taxonoccurrence/sequence/source'),
        label: i18n.t('Search.topResults.isolationSource')
      },
      owner: {
        id: 'owner',
        filterValue: modelRecordTopResult('users/user'),
        label: i18n.t('Search.topResults.owner')
      },
      cluster: {
        id: 'cluster',
        filterValue: modelRecordTopResult('globalkey/cluster'),
        label: i18n.t('Search.topResults.cluster')
      },
      reference: {
        id: 'reference',
        filterValue: modelRecordTopResult('reference/reference'),
        label: i18n.t('Search.topResults.reference')
      }
    };
    return function (...ids) {
      return ids.map(id => {
        const spec = TOP_RESULT_SPECS[id];
        if (!spec) {
          throw new Error(`Unknown top result option: ${id}`);
        }
        return spec;
      });
    };
  }();
  const viewURL = {
    static(route, {
      routeParameter = 'id'
    } = {}) {
      return function (document, {
        store,
        router
      }) {
        return router.urlFor(route, document[routeParameter]);
      };
    },
    usingContentType() {
      return async function (document, {
        store,
        router
      }) {
        const contentType = await store.findRecord('contenttype', document['content_type_id']);
        const route = (0, _modules.get_view_route)(contentType);
        return router.urlFor(route, document['id']);
      };
    }
  };
  const MODULES = [{
    name: i18n.t('searchModule.taxonoccurrence'),
    id: 'taxonoccurrence',
    value: 'taxonoccurrence',
    viewURL: viewURL.usingContentType(),
    topResults: useTopResults('taxon_node', 'country', 'gathering_agents', 'mainform'),
    downloadEndpoint: 'taxonoccurrence/occurrence-search-download',
    filterPanels: ['all-occurrences-taxon', 'occurrence-time', 'location', 'measurements', 'occurrence-properties'],
    chartFields: ['created_at', 'timespan_begin'],
    arguments: {},
    defaultValues: store => ({
      include_taxon_synonyms: true,
      parent_taxon: true
    }),
    icon: (0, _icon.icon)('taxon-occurrence')
  }, {
    name: i18n.t('searchModule.study'),
    id: 'study',
    value: 'study/studies',
    viewURL: viewURL.static('study.view'),
    filterPanels: ['default-time', 'project-properties'],
    arguments: {},
    icon: (0, _icon.icon)('project')
  }, {
    name: i18n.t('Sample.samplingAreas'),
    id: 'samplingarea',
    value: 'sample/samplingareas',
    model: 'sample/samplingarea',
    viewURL: viewURL.static('area.view'),
    icon: (0, _icon.icon)('area'),
    defaultColumns: ['name', 'project', 'country', 'state', 'district', 'parish', 'commune', 'locality_text'],
    filterPanels: ['area-fields', 'measurements']
  }, {
    name: i18n.t('searchModule.specimen'),
    id: 'specimen',
    value: 'taxonoccurrence/specimen/specimens',
    model: 'specimen/specimen',
    viewURL: viewURL.static('specimen.view'),
    defaultColumns: ['name', 'taxon_node', 'last_modified', 'owner'],
    topResults: useTopResults('taxon_node', 'country', 'gathering_agents', 'deposited_in'),
    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'measurements', 'specimen-properties'],
    chartFields: ['created_at', 'timespan_begin'],
    arguments: {
      include_cb: CB_MODULES.includes('specimen')
    },
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: (0, _icon.icon)('specimen')
  }, {
    name: i18n.t('searchModule.observation'),
    id: 'observation',
    value: 'taxonoccurrence/observation/observations',
    model: 'observation/observation',
    viewURL: viewURL.static('observation.view'),
    topResults: useTopResults('taxon_node', 'country', 'gathering_agents', 'mainform'),
    wms: true,
    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'measurements', 'observation-properties'],
    chartFields: ['created_at', 'timespan_begin'],
    contentQueryArguments: {
      taxon_node: {
        include_commonnames: true
      }
    },
    arguments: {
      include_cb: CB_MODULES.includes('observation')
    },
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true,
      is_verified: true
    }),
    icon: (0, _icon.icon)('observation')
  }, {
    name: i18n.t('searchModule.sequence'),
    id: 'sequence',
    value: 'taxonoccurrence/sequence/sequences',
    model: 'sequence/sequence',
    viewURL: viewURL.static('sequence.view'),
    defaultColumns: ['name', 'taxon_node', 'last_modified', 'owner'],
    topResults: useTopResults('taxon_node', 'country', 'seq_source', 'owner', 'cluster'),
    // Top results visible by default.

    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'sequence-unite-insd', 'measurements', 'sequence-properties'],
    chartFields: ['created_at', 'timespan_begin'],
    arguments: {
      include_cb: CB_MODULES.includes('sequence')
    },
    icon: (0, _icon.icon)('sequence')
  }, {
    name: i18n.t('searchModule.livingculture'),
    id: 'livingculture',
    model: 'livingculture/strain',
    value: 'taxonoccurrence/livingculture/strains',
    viewURL: viewURL.static('livingspecimen.view'),
    defaultColumns: ['name', 'taxon_node', 'last_modified', 'owner'],
    topResults: useTopResults('taxon_node', 'country', 'gathering_agents', 'deposited_in'),
    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'measurements', 'specimen-properties'],
    arguments: {
      include_cb: CB_MODULES.includes('livingspecimen')
    },
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: (0, _icon.icon)('livingspecimen')
  }, {
    name: i18n.t('searchModule.referencebased'),
    id: 'referencebased',
    model: 'referencebased/occurrence',
    value: 'taxonoccurrence/referencebased/occurrences',
    viewURL: viewURL.static('referencebased.view'),
    topResults: useTopResults('taxon_node', 'country', 'reference', 'owner'),
    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'measurements', 'reference-based-properties'],
    arguments: {
      include_cb: CB_MODULES.includes('refbased')
    },
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: (0, _icon.icon)('refbased')
  }, {
    name: i18n.t('searchModule.materialsample'),
    id: 'materialsample',
    model: 'materialsample/materialsample',
    value: 'taxonoccurrence/materialsample/materialsamples',
    viewURL: viewURL.static('materialsample.view'),
    topResults: useTopResults('country', 'mainform', 'deposited_in'),
    // taxon_node should be added as first, once available

    filterPanels: ['material-sample-taxon', 'occurrence-time', 'location', 'measurements', 'material-sample-properties'],
    arguments: {
      include_cb: CB_MODULES.includes('materialsample')
    },
    icon: (0, _icon.icon)('materialsample')
  }, {
    name: i18n.t('searchModule.filerepository'),
    id: 'filerepository',
    value: 'filerepository/files',
    viewURL: viewURL.static('filerepository.view'),
    filterPanels: ['file-repository/time', 'file-repository/properties'],
    arguments: {},
    icon: (0, _icon.icon)('file')
  }, {
    name: i18n.t('searchModule.publishing'),
    id: 'publishing',
    value: 'datacite/metadatas',
    viewURL: viewURL.static('datacite.view-private'),
    filterPanels: ['default-time', 'datacite-properties'],
    arguments: {},
    icon: (0, _icon.icon)('datacite')
  }, {
    name: i18n.t('searchModule.photobank'),
    id: 'photobank',
    value: 'photobank/collectionobjects',
    model: 'photobank/collectionobject',
    viewURL: viewURL.static('photobank.view'),
    filterPanels: ['occurrence-taxon', 'occurrence-time', 'location', 'measurements', 'photobank/properties'],
    arguments: {
      include_cb: CB_MODULES.includes('photobank')
    },
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: (0, _icon.icon)('photobank')
  }, {
    name: i18n.t('searchModule.taxonnode'),
    id: 'taxonnode',
    value: 'taxonomy/taxonnodes',
    viewURL: viewURL.static('taxonomy.view', {
      routeParameter: 'id'
    }),
    recordIDField: 'id',
    filterPanels: ['taxon-node/taxon', 'default-time', 'taxon-node/properties'],
    defaultValues: store => ({
      include_commonnames: true,
      is_deleted: false
    }),
    icon: (0, _icon.icon)('taxonomy')
  }, {
    name: i18n.t('searchModule.taxondescription'),
    id: 'taxondescription',
    value: 'laboratory/taxonomylab/taxondescriptions',
    viewURL: viewURL.static('taxon-description.view'),
    filterPanels: ['simple-taxon', 'default-time', 'taxon-description-properties'],
    arguments: {},
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: 'icon-taxon-description'
  }, {
    name: i18n.t('searchModule.uniteSH'),
    id: 'uniteSH',
    value: 'globalkey/dshclusters',
    viewURL: viewURL.static('unite.view'),
    icon: 'icon-unite-hypotheses',
    downloadEndpoint: 'globalkey/dsh-search-download',
    filterPanels: ['unite-sh-taxon', 'unite-sh-properties'],
    defaultColumns: ['name', 'is_singleton', 'taxon_node', 'taxon_name', 'sequence_count', 'threshold', 'version', 'designators', 'form_status'],
    defaultValues: async store => {
      const defaults = {
        parent_taxon: true,
        include_taxon_synonyms: true
      };
      const version = await (0, _unite.getLastUNITEVersion)(store);
      if (version) {
        defaults.version = version.id;
      }
      return defaults;
    },
    contentQueryArguments: {
      threshold: {
        ordering: 'ordering'
      },
      version: {
        available: true
      }
    },
    // TODO: queryArguments is not necessary when this is used
    createFilter: function ({
      store,
      key,
      config,
      queryArguments
    }) {
      if (config.type === 'choice') {
        return new _filters.EnumFilterWithBackendChoices({
          store,
          config,
          name: key
        });
      }
      return (0, _filters.createFilter)({
        store,
        key,
        config,
        queryArguments
      });
    }
  }, {
    name: i18n.t('searchModule.referencesequence'),
    id: 'referencesequence',
    value: 'globalkey/referencesequences',
    viewURL: viewURL.static('unite.view'),
    recordIDField: 'dshcluster_id',
    defaultColumns: ['dshcluster', 'name', 'last_modified', 'owner'],
    viewProperty: 'dshcluster_id',
    filterPanels: ['simple-taxon', 'default-time', 'unite-refseq-properties'],
    arguments: {},
    defaultValues: store => ({
      parent_taxon: true,
      include_taxon_synonyms: true
    }),
    icon: 'icon-unite-hypotheses'
  }, {
    name: i18n.t('searchModule.compoundclusters'),
    id: 'compoundclusters',
    value: 'globalkey/clusters',
    viewURL: viewURL.static('compound.view'),
    defaultColumns: ['name', 'cluster', 'taxon_node', 'version'],
    filterPanels: ['occurrence-taxon', 'unite-compound-cluster-properties'],
    defaultValues: async store => {
      const defaults = {
        parent_taxon: true,
        include_taxon_synonyms: true
      };
      const version = await (0, _unite.getLastUNITEVersion)(store);
      if (version) {
        defaults.version = version.id;
      }
      return defaults;
    },
    contentQueryArguments: {
      threshold: {
        ordering: 'ordering'
      },
      version: {
        available: true
      }
    },
    arguments: {},
    icon: 'icon-compound-cluster'
  }, {
    name: i18n.t('searchModule.reference'),
    id: 'reference',
    value: 'reference/references',
    viewURL: viewURL.static('reference.view'),
    filterPanels: ['default-time', 'reference-properties'],
    arguments: {},
    icon: 'icon-reference'
  }, {
    name: i18n.t('searchModule.referenceseries'),
    id: 'referenceseries',
    value: 'reference/series',
    viewURL: viewURL.static('series.view'),
    filterPanels: ['default-time', 'default-properties'],
    arguments: {},
    icon: 'icon-reference'
  }, {
    name: i18n.t('searchModule.glossary'),
    id: 'glossary',
    value: 'glossary/glossaries',
    viewURL: viewURL.static('glossary.view'),
    filterPanels: ['default-time', 'default-properties'],
    arguments: {},
    icon: 'icon-glossary'
  }, {
    name: i18n.t('searchModule.glossarycollection'),
    id: 'glossarycollection',
    value: 'glossary/collections',
    viewURL: viewURL.static('glossary.collection.view'),
    filterPanels: ['default-time', 'default-properties'],
    arguments: {},
    icon: 'icon-glossary-collection'
  }, {
    name: i18n.t('searchModule.person'),
    id: 'person',
    value: 'agent/persons',
    viewURL: viewURL.static('person.view'),
    filterPanels: ['default-time', 'person-properties'],
    arguments: {},
    icon: 'icon-person'
  }, {
    name: i18n.t('searchModule.organization'),
    id: 'organization',
    value: 'agent/organizations',
    viewURL: viewURL.static('organization.view'),
    filterPanels: ['default-time', 'organization-properties'],
    arguments: {},
    icon: 'icon-organization'
  }, {
    name: i18n.t('searchModule.collection'),
    id: 'collection',
    value: 'agent/collections',
    viewURL: viewURL.static('collection.view'),
    filterPanels: ['default-time', 'collection-properties'],
    arguments: {},
    icon: 'icon-collection'
  }, {
    name: i18n.t('searchModule.transaction'),
    id: 'transaction',
    value: 'transaction/transactions',
    viewURL: viewURL.static('transaction.view'),
    filterPanels: ['transaction/time', 'transaction/properties'],
    arguments: {},
    icon: 'icon-transaction'
  }, {
    name: i18n.t('searchModule.dnaextraction'),
    id: 'dnaextraction',
    value: 'dna-lab/dna-extractions',
    viewURL: viewURL.static('experiment.dna-extraction.view'),
    filterPanels: ['default-time', 'experiment'],
    arguments: {},
    icon: (0, _icon.icon)('experiment')
  }, {
    name: i18n.t('searchModule.dna'),
    id: 'dna',
    value: 'dna-lab/dnas',
    viewURL: viewURL.static('experiment.dna.view'),
    // TODO: This probably should be default
    defaultColumns: ['name', 'laboratory', 'dna_collection'],
    filterPanels: ['dna/time', 'dna/properties'],
    contentQueryArguments: {
      dna_collection: {
        is_collection: true
      }
    },
    arguments: {},
    icon: (0, _icon.icon)('experiment')
  }, {
    name: i18n.t('searchModule.pcr'),
    id: 'pcr',
    value: 'dna-lab/pcrs',
    viewURL: viewURL.static('experiment.pcr.view'),
    filterPanels: ['default-time', 'experiment'],
    arguments: {},
    icon: (0, _icon.icon)('experiment')
  }, {
    name: i18n.t('searchModule.sequencing'),
    id: 'sequencing',
    value: 'dna-lab/sequencings',
    viewURL: viewURL.static('experiment.sequencing.view'),
    filterPanels: ['default-time', 'sequencing-properties'],
    arguments: {},
    icon: (0, _icon.icon)('sequencing')
  }, {
    name: i18n.t('conservation.redListAssessment.assessments'),
    id: 'red-list-assessments',
    value: 'redbook/red-list-assessments',
    viewURL: viewURL.static('conservation-lab.red-list-assessment.view'),
    filterPanels: ['red-list-assessment/taxon', 'red-list-assessment/time', 'red-list-assessment/properties'],
    arguments: {},
    icon: (0, _icon.icon)('conservation')
  }, {
    id: 'annotations',
    value: 'annotation',
    name: i18n.t('annotation.annotations'),
    icon: (0, _icon.icon)('annotation'),
    recordIcon: function (document) {
      const module = _modules.default[document.content_type];
      if (module && module.icon) {
        return module.icon;
      } else {
        return (0, _icon.icon)('annotation');
      }
    },
    viewURL: function (document, {
      store,
      router
    }) {
      if (document.type === 'client') {
        const module = _modules.default[document.content_type];
        return router.urlFor(module.routes.annotations.view, document.record.id, document.id);
      } else if (document.type === 'bulk') {
        return router.urlFor('annotations.bulk.view', document.id);
      } else {
        throw new Error(`Unknown annotation type: ${document.type} for document ${document}`);
      }
    },
    topResults: [{
      id: 'author',
      label: i18n.t('annotation.routes.search.topResults.author'),
      filterValue: modelRecordTopResult('users/user')
    }, {
      id: 'annotated_fields',
      label: i18n.t('annotation.routes.search.topResults.changedField'),
      filterValue: (store, result) => [result.key]
    }],
    defaultColumns: ['name', 'author', 'moderator', 'annotated_at', 'comment'],
    defaultValues: store => ({
      status: [_annotation.AnnotationStatus.Accepted]
    }),
    columnOrder: ['name', 'status', 'author', 'annotated_at', 'comment', 'moderator', 'moderated_at', 'moderator_comment', 'taxon', 'typification', 'collectors', 'collected_at', 'latitude', 'longitude', 'country', 'state', 'district', 'parish', 'commune', 'locality_text'],
    hiddenColumns: ['type', 'record', 'content_type'],
    filterPanels: ['annotation/taxon', 'annotation/time', 'measurements', 'annotation/properties', 'annotation/record-properties'],
    // TODO: queryArguments is not necessary when this is used
    createFilter: function ({
      store,
      key,
      config,
      queryArguments
    }) {
      if (key === 'annotated_at') {
        return new _filters.TimeFilter({
          store,
          config,
          name: key
        });
      }
      if (key === 'content_type') {
        const models = ['sequence/sequence', 'specimen/specimen', 'livingculture/strain', 'materialsample/materialsample', 'observation/observation', 'referencebased/occurrence'];
        return new _filters.EnumFilter({
          store,
          config,
          name: key,
          label: i18n.t('annotation.routes.search.contentType'),
          choices: models.map(model => ({
            value: model,
            label: _modules.default[model].name
          }))
        });
      }
      if (key === 'annotated_fields') {
        return new _filters.EnumFilterWithBackendChoices({
          store,
          config,
          name: key,
          label: i18n.t('annotation.routes.search.changedFields')
        });
      }

      // Make all the choice field multiple (probably the next default)
      if (config.type === 'choice') {
        return new _filters.EnumFilterWithBackendChoices({
          store,
          config,
          name: key
        });
      }
      return (0, _filters.createFilter)({
        store,
        key,
        config,
        queryArguments
      });
    }
  }];
  if (_environment.default.ENABLE_TRAITS) {
    MODULES.push({
      name: i18n.t('Forms.traitsLabel'),
      id: 'trait',
      value: 'measurement/measurements',
      viewURL: viewURL.static('trait.view'),
      filterPanels: ['default-time', 'trait-properties'],
      arguments: {},
      icon: (0, _icon.icon)('trait')
    });
  }
  var _default = _exports.default = MODULES;
});