define("plutof/controllers/person/edit", ["exports", "@ember/object", "plutof/utils/controllers", "plutof/utils/model"], function (_exports, _object, _controllers, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PersonEditController = (_dec = (0, _object.computed)('model.person.person.user'), _class = class PersonEditController extends _controllers.EditController {
    get givenNameRequired() {
      return (0, _model.hasRelation)(this.model.person.person, 'user');
    }
    async _save() {
      await this.model.person.save();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "givenNameRequired", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "givenNameRequired"), _class.prototype), _class);
  var _default = _exports.default = PersonEditController;
});