define("plutof/templates/components/clipboard/clipboard-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6dkOcIyZ",
    "block": "[[[10,3],[12],[1,\"\\n    \"],[11,1],[16,0,[29,[[36,0]]]],[4,[38,1],[[30,0],\"toggleInclusion\"],[[\"bubbles\"],[false]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"iconClass\",\"action\"]]",
    "moduleName": "plutof/templates/components/clipboard/clipboard-button.hbs",
    "isStrictMode": false
  });
});