define("plutof/components/clipboard/bulk-update/permit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Clipboard::InputWrapper @config={{@config}} as |iw|>
      <CollectionLab::Permit::Autocomplete
          @update={{fn this.addPermit iw}}
          @disabled={{iw.disabled}} />
  
      <Common::RecordList
          @records={{iw.value}}
          @remove={{fn this.removePermit iw}} />
  </Clipboard::InputWrapper>
  
  */
  {
    "id": "r99ve6Wf",
    "block": "[[[8,[39,0],null,[[\"@config\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@update\",\"@disabled\"],[[28,[37,2],[[30,0,[\"addPermit\"]],[30,2]],null],[30,2,[\"disabled\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@records\",\"@remove\"],[[30,2,[\"value\"]],[28,[37,2],[[30,0,[\"removePermit\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@config\",\"iw\"],false,[\"clipboard/input-wrapper\",\"collection-lab/permit/autocomplete\",\"fn\",\"common/record-list\"]]",
    "moduleName": "plutof/components/clipboard/bulk-update/permit.hbs",
    "isStrictMode": false
  });
  let BulkUpdatePermit = (_class = class BulkUpdatePermit extends _component2.default {
    addPermit(iw, permit) {
      iw.value.pushObject(permit);
    }
    removePermit(iw, permit) {
      iw.value.removeObject(permit);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "addPermit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPermit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePermit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removePermit"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkUpdatePermit);
});