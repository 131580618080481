define("plutof/templates/components/occurrences-table/-responsive-row/selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "CzwBi5a0",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"plutof-label occ-table-responsive-row__cell-label\"],[12],[1,\" \"],[13],[1,\"\\n\\n    \"],[8,[30,1],null,null,null],[1,\"\\n\"]],[]]]]]],[\"@selector\"],false,[\"occurrences-table/-responsive-row/cell\"]]",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/selector.hbs",
    "isStrictMode": false
  });
});