define("plutof/templates/components/my-menu/measurements", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xFHxW/kF",
    "block": "[[[41,[30,0,[\"personalMenu\",\"measurementsRequestPending\"]],[[[1,\"    \"],[10,0],[14,0,\"my-menu-measurements__selection\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"Profile.personalMenu.measurements.selectGuide\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"personalMenu\",\"selectedMeasurements\"]],[[[1,\"            \"],[10,\"label\"],[12],[1,[28,[35,1],[\"Profile.personalMenu.measurements.selection\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,2],null,[[\"records\",\"remove\"],[[30,0,[\"personalMenu\",\"selectedMeasurements\"]],[28,[37,3],[[30,0],\"removeSelected\"],null]]],[[\"default\"],[[[[1,\"            \"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[1]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"type\",\"value\",\"class\",\"enter\"],[\"text\",[30,0,[\"query\"]],\"form-control\",[28,[37,3],[[30,0],\"runSearch\"],null]]]]],[1,\"\\n\\n    \"],[11,1],[24,0,\"input-group-addon\"],[4,[38,3],[[30,0],\"runSearch\"],null],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[\"icon-search \",[52,[30,0,[\"search\",\"isRunning\"]],\"spinicon\"]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"noResults\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"Profile.personalMenu.measurements.noResults\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"ul\"],[14,0,\"my-menu-measurements__results\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"results\"]]],null]],null],[33,7,[\"id\"]],[[[1,\"        \"],[1,[28,[35,8],null,[[\"result\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"measurement\",\"result\"],false,[\"if\",\"i18n-t\",\"common/record-list\",\"action\",\"input\",\"each\",\"-track-array\",\"result\",\"my-menu/-measurement\"]]",
    "moduleName": "plutof/templates/components/my-menu/measurements.hbs",
    "isStrictMode": false
  });
});