define("plutof/templates/components/datacite/alternate-identifier-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cmZn9lN5",
    "block": "[[[10,\"td\"],[15,0,[52,[33,1,[\"attrs\",\"identifier\",\"alternate_identifier\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"alternate_identifier\"]],\"datacite-edit-alternate-identifiers__identifier mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[33,1,[\"attrs\",\"identifier\",\"alternate_identifier_type\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"alternate_identifier_type\"]],\"datacite-edit-alternate-identifiers__identifier-type mini-input\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@identifier\",\"@remove\"],false,[\"if\",\"validations\",\"input\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/alternate-identifier-row-form.hbs",
    "isStrictMode": false
  });
});