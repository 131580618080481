define("plutof/templates/conservation-lab/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sZoJQiRX",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\"],[[28,[37,1],[\"conservation\"],null],[28,[37,2],[\"conservation.laboratory.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@description\",\"@route\",\"@icon\"],[[28,[37,2],[\"conservation.redList.index.title\"],null],[28,[37,2],[\"conservation.redList.index.description\"],null],\"conservation-lab.red-list\",[28,[37,1],[\"conservation\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@description\",\"@route\",\"@icon\"],[[28,[37,2],[\"conservation.redListAssessment.index.title\"],null],[28,[37,2],[\"conservation.redListAssessment.index.description\"],null],\"conservation-lab.red-list-assessment\",[28,[37,1],[\"conservation\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"hub\"],false,[\"layout/module-hub\",\"icon\",\"i18n-t\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/conservation-lab/index.hbs",
    "isStrictMode": false
  });
});