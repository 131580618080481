define("plutof/templates/profile/identities", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hg8z76I+",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.identities\"]],null],[1,\"\\n\\n\"],[1,[28,[35,1],[\"Settings.identities.listLabel\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\",\"identities\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"spacer-below\"],[12],[1,\"\\n\"],[41,[30,1,[\"identity\"]],[[[1,\"            \"],[1,[28,[35,5],[[30,1,[\"provider\",\"name\"]]],null]],[1,\": \"],[1,[30,1,[\"identity\"]]],[1,\"\\n\\n            \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,6],[[30,0],[30,0,[\"disconnect\"]],[30,1]],null],[12],[1,\"\\n                \"],[1,[28,[35,5],[\"Settings.identities.disconnect\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[46,[30,1,[\"provider\",\"button\"]],null,[[\"clicked\"],[[28,[37,6],[[30,0],[30,0,[\"connect\"]],[30,1,[\"provider\"]]],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null]],[\"identity\"],false,[\"test/route-marker\",\"common/local-label\",\"each\",\"-track-array\",\"if\",\"i18n-t\",\"action\",\"component\"]]",
    "moduleName": "plutof/templates/profile/identities.hbs",
    "isStrictMode": false
  });
});