define("plutof/templates/components/measurements/measurement-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x369Isql",
    "block": "[[[41,[30,1,[\"loading\"]],[[[1,\"    \"],[10,1],[14,0,\"loading-icon measurement-form--loading\"],[12],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"General.loadingMeasurement\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,\"data-test\",\"Measurements::MeasurementForm\"]],[[\"@columns\"],[[28,[37,3],[[30,2],1],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@items\",\"@context\"],[[30,1,[\"objectMeasurements\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[15,0,[29,[[52,[30,4,[\"isExternal\"]],\"measurement-form__input--new\"]]]],[12],[1,\"\\n                \"],[8,[39,5],null,[[\"@field\",\"@expand\",\"@validationContext\"],[[30,4],[28,[37,6],[[30,0,[\"expand\"]],[30,4],[30,5]],null],[30,5]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[4,5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[8,[39,7],null,[[\"@open\",\"@close\"],[[30,0,[\"modalOpen\"]],[30,0,[\"collapse\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[30,0,[\"expandedMeasurement\",\"fullName\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,8],null,[[\"@measurement\",\"@validationContext\"],[[30,0,[\"expandedMeasurement\"]],[30,0,[\"expandedMeasurementContext\"]]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,9],[\"click\",[30,0,[\"collapse\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.Save\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@data\",\"@row_length\",\"@validationContext\",\"field\",\"context\"],false,[\"if\",\"i18n-t\",\"layout/regular-grid\",\"or\",\"validation/split-context\",\"measurements/measurement-input\",\"fn\",\"ui/modal/dialog\",\"measurements/expanded\",\"on\"]]",
    "moduleName": "plutof/templates/components/measurements/measurement-form.hbs",
    "isStrictMode": false
  });
});