define("plutof/components/guided-view", ["exports", "@ember/component", "@ember/template-factory", "plutof/components/guided-form"], function (_exports, _component, _templateFactory, _guidedForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.tooltip}}
      <ToolTip @tooltip={{this.tooltip}}>
          <div class="plutof-label">
              {{this.label}}
          </div>
      </ToolTip>
  {{else}}
      <div class="plutof-label">
          {{this.label}}
      </div>
  {{/if}}
  
  <p>
      {{yield}}
  </p>
  
  */
  {
    "id": "9FJp3w3V",
    "block": "[[[41,[30,0,[\"tooltip\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@tooltip\"],[[30,0,[\"tooltip\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[10,2],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"tool-tip\",\"yield\"]]",
    "moduleName": "plutof/components/guided-view.hbs",
    "isStrictMode": false
  });
  class GuidedView extends _guidedForm.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GuidedView);
});