define("plutof/app", ["exports", "rsvp", "@ember/application", "@ember/routing/router", "ember-inflector", "ember-load-initializers", "ember-resolver", "plutof/config/environment", "plutof/utils/errors"], function (_exports, _rsvp, _application, _router, _emberInflector, _emberLoadInitializers, _emberResolver, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // XXX: Ideally, we'd want all these specified in adapters. But in several places we need to do
  // the transformation ourselves, outside ember-data. Then again, overriding inflector is meh.
  // TODO: write model -> path transformation somewhere accessible
  _emberInflector.default.inflector.singular(/meta$/, 'meta');
  _emberInflector.default.inflector.plural(/thesaurus/, 'thesaurus');
  _emberInflector.default.inflector.singular(/thesaurus/, 'thesaurus');
  _emberInflector.default.inflector.singular(/metadata/, 'metadata');
  _emberInflector.default.inflector.plural(/metadata/, 'metadatas');
  _emberInflector.default.inflector.singular(/media/, 'media');
  _emberInflector.default.inflector.plural(/media/, 'medias');
  _emberInflector.default.inflector.plural(/exsiccata/, 'exsiccata');
  _emberInflector.default.inflector.singular(/exsiccata/, 'exsiccata');
  _emberInflector.default.inflector.singular(/data$/, 'data');
  _emberInflector.default.inflector.irregular('person', 'persons');
  _emberInflector.default.inflector.singular(/estbirds/, 'estbirds');
  _emberInflector.default.inflector.plural(/sheet-taxon/, 'sheet-taxa');
  delete _emberInflector.default.inflector.rules.irregular.men;
  delete _emberInflector.default.inflector.rules.irregularInverse.men;
  class App extends _application.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modulePrefix", 'plutof');
      // TODO: loaded via config
      _defineProperty(this, "podModulePrefix", 'plutof');
      _defineProperty(this, "Resolver", _emberResolver.default);
    }
  }
  _exports.default = App;
  App.initializer({
    name: 'Setup request prefilter',
    initialize: function (application) {
      $.ajaxPrefilter(function (options, original_options, jqxhr) {
        options.xhrFields = options.xhrFields || {};

        // If we manually set withCredentials to false (for non-backend cross-origin requests),
        // don't mess with that
        if (options.xhrFields.withCredentials !== false) {
          options.xhrFields.withCredentials = true;
        }
      });
      $.ajaxSetup({
        beforeSend: function (jqXHR, settings) {
          jqXHR.url = settings.url;
        }
      });
    }
  });
  (0, _emberLoadInitializers.default)(App, 'plutof');
  _rsvp.default.configure('onerror', function (error) {
    if (error instanceof Error) {
      /* eslint-disable no-console */
      console.error(error);
      /* eslint-enable no-console */

      (0, _errors.processError)(error);
    }
  });
  window.onerror = function (message, source, lineno, colno, error) {
    (0, _errors.processError)(error);
  };
  window.addEventListener('unhandledrejection', function (event) {
    (0, _errors.processError)(event.reason);
  });

  // TODO: Move this to our router
  if (_environment.default.ENABLE_ANALYTICS) {
    _router.default.reopen({
      notifyGoogleAnalytics: function () {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('config', window.PlutofENV.ANALYTICS_TRACKING_CODE, {
            page_title: this.get('url'),
            page_path: this.get('url')
          });
        }
      }.on('didTransition')
    });
  }
});