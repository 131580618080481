define("plutof/components/plutof-select/-base", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select {{on "change" this.selectionChanged}} ...attributes>
      {{#if @prompt}}
          {{! Prompt could be foldet into @choices too. Probably not worth duplicating it in the variant components tho }}
          <option value={{null}} selected={{is-equal @value null}}>
              {{@prompt}}
          </option>
      {{/if}}
  
      {{#each @choices as |choice|}}
          <option value={{choice.value}} selected={{is-equal @value choice}}>
              {{choice.label}}
          </option>
      {{/each}}
  </select>
  
  */
  {
    "id": "ClOnELCJ",
    "block": "[[[11,\"select\"],[17,1],[4,[38,0],[\"change\",[30,0,[\"selectionChanged\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"option\"],[15,2,null],[15,\"selected\",[28,[37,2],[[30,3],null],null]],[12],[1,\"\\n            \"],[1,[30,2]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,4]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[30,5,[\"value\"]]],[15,\"selected\",[28,[37,2],[[30,3],[30,5]],null]],[12],[1,\"\\n            \"],[1,[30,5,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[5]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@prompt\",\"@value\",\"@choices\",\"choice\"],false,[\"on\",\"if\",\"is-equal\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/components/plutof-select/-base.hbs",
    "isStrictMode": false
  });
  // This is just a shared logic between the three old components, not to
  // be used directly. And even those are like that as legacy
  //
  // type C extends { value: string; label: string; ... }
  // @choices :: C[]
  // @value :: C | null
  // @update :: (C | null) => void
  // @prompt :: string | null
  //
  // NB: value MUST be a string, no integer ids and such
  let PlutoFSelectBase = _exports.default = (_class = class PlutoFSelectBase extends _component2.default {
    selectionChanged(event) {
      const value = event.target.value;
      const choice = this.args.choices.findBy('value', value);
      this.args.update(choice);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectionChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectionChanged"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PlutoFSelectBase);
});