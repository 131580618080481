define("plutof/components/layout/-grid/span", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/string", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _object, _string, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _GridSpan;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style={{this.style}}>
      {{yield}}
  </div>
  
  */
  {
    "id": "8dr1Zy3+",
    "block": "[[[10,0],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "plutof/components/layout/-grid/span.hbs",
    "isStrictMode": false
  });
  let GridSpan = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('span'), _dec(_class = (_class2 = (_GridSpan = class GridSpan extends _component.default {
    get style() {
      return (0, _string.htmlSafe)(`grid-column: span ${this.span}`);
    }
  }, _defineProperty(_GridSpan, "positionalParams", ['span']), _GridSpan), _applyDecoratedDescriptor(_class2.prototype, "style", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GridSpan);
});