define("plutof/components/plutof-panel/panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _object, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO: Use PlutofPanel::Base for ui}}
  <div class="panel-heading">
      <div
          {{on "click" this.toggle}}
          class="panel-title-container {{unless this.open 'panel-title-container--collapsed'}}"
          data-test="panel-title"
      >
          <h4 class="panel-title {{if @invalid 'panel-title--invalid'}}">
              {{yield (hash
                  title=(component "named-slot")
                  badge=(component "plutof-panel/-panel/badge")
              )}}
          </h4>
      </div>
  
      <div class="panel-header-controls inline-flex">
          {{yield (hash controls=(component "named-slot"))}}
      </div>
  </div>
  
  <div {{collapse this.open}}>
      {{#if this.renderBody}}
          <div class="panel-body {{if @outer 'no-padding'}}">
              {{yield (hash body=(component "named-slot"))}}
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "uG8whuNB",
    "block": "[[[10,0],[14,0,\"panel-heading\"],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"panel-title-container \",[52,[51,[30,0,[\"open\"]]],\"panel-title-container--collapsed\"]]]],[24,\"data-test\",\"panel-title\"],[4,[38,1],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n        \"],[10,\"h4\"],[15,0,[29,[\"panel-title \",[52,[30,1],\"panel-title--invalid\"]]]],[12],[1,\"\\n            \"],[18,3,[[28,[37,4],null,[[\"title\",\"badge\"],[[50,\"named-slot\",0,null,null],[50,\"plutof-panel/-panel/badge\",0,null,null]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"panel-header-controls inline-flex\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,4],null,[[\"controls\"],[[50,\"named-slot\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,0],[4,[38,6],[[30,0,[\"open\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"renderBody\"]],[[[1,\"        \"],[10,0],[15,0,[29,[\"panel-body \",[52,[30,2],\"no-padding\"]]]],[12],[1,\"\\n            \"],[18,3,[[28,[37,4],null,[[\"body\"],[[50,\"named-slot\",0,null,null]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@invalid\",\"@outer\",\"&default\"],false,[\"unless\",\"on\",\"if\",\"yield\",\"hash\",\"component\",\"collapse\"]]",
    "moduleName": "plutof/components/plutof-panel/panel.hbs",
    "isStrictMode": false
  });
  let Panel = (_dec = (0, _component2.classNames)('panel'), _dec2 = (0, _object.computed)('dontRenderCollapsed', 'open'), _dec(_class = (_class2 = class Panel extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "open", true);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (!(0, _utils.isNone)(this.collapse) && this.collapse === this.open) {
        this.toggle();
      }
    }

    // Pass @dontRenderCollapsed to replicate {{plutof-panel}} behaviour of destroying
    // collapsed content
    get renderBody() {
      return !this.dontRenderCollapsed || this.open;
    }
    toggle() {
      this.set('open', !this.open);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "renderBody", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "renderBody"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Panel);
});