define("plutof/templates/recover-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qVeDaD8s",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"recover-password\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"Registration.recoverPassword\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@label\",\"@value\"],[[28,[37,2],[\"Registration.credentials\"],null],[30,0,[\"identification\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"form-control\"]],[[\"@value\",\"@placeholder\"],[[30,0,[\"identification\"]],[28,[37,2],[\"Registration.credentials\"],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[30,0,[\"validations\",\"isInvalid\"]]],[24,0,\"btn btn-default plutof-btn-green\"],[4,[38,5],[\"click\",[30,0,[\"reset\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"Registration.recoverPassword\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"common/legend\",\"i18n-t\",\"login/form-input\",\"input\",\"on\"]]",
    "moduleName": "plutof/templates/recover-password.hbs",
    "isStrictMode": false
  });
});