define("plutof/components/expanded-select", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/object"], function (_exports, _component, _object, _utils, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Entry extends _object.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "parent", null);
      _defineProperty(this, "object", null);
      _defineProperty(this, "selected", false);
    }
  }
  let ExpandedSelect = (_dec = (0, _object.computed)('content.[]'), _dec2 = (0, _object2.observes)('selection', 'content.[]'), _class = class ExpandedSelect extends _component.default {
    constructor(...args) {
      super(...args);
      // inputs
      _defineProperty(this, "content", []);
      _defineProperty(this, "labelPath", '');
      _defineProperty(this, "details", null);
      // Array of { name: String, value: Anything }.
      // outputs:
      _defineProperty(this, "selection", null);
      _defineProperty(this, "idProperty", 'id');
    }
    init() {
      super.init(...arguments);
      this.inputChanged();
    }
    get entries() {
      var content = this.content;
      if ((0, _utils.isNone)(content)) {
        return null;
      }
      return content.map(function (object) {
        return Entry.create({
          parent: this,
          object: object,
          selected: false
        });
      }, this);
    }

    // content/selection might be loaded out of order
    inputChanged() {
      var content = this.content;
      var selection = this.selection;
      if (!(0, _utils.isNone)(content) && !(0, _utils.isNone)(selection)) {
        this.selectObject(selection);
      }
    }
    selectObject(object) {
      var entryId = this.idProperty;
      this.entries.forEach(function (entry) {
        entry.set('selected', entry.get('object.' + entryId) === (0, _object.get)(object, entryId));
      });
      if (object !== this.selection) {
        this.set('selection', object);
      }
    }
    select(entry) {
      this.selectObject(entry.get('object'));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "entries", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputChanged", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "inputChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
  var _default = _exports.default = ExpandedSelect;
});