define("plutof/components/annotation/bulk/field", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      <Common::Ui::ControlLabel>
          <:label>
              <div class="plutof-label">
                  {{@field.label}}
              </div>
          </:label>
  
          <:controls as |controls|>
              <controls.toggle
                  @checked={{@field.active}}
                  @toggle={{@field.toggle}}
              >
                  {{i18n-t "annotation.annotate"}}
              </controls.toggle>
          </:controls>
      </Common::Ui::ControlLabel>
  
      <div>
          {{yield (hash
              value=@field.value
              update=@field.update
              disabled=this.disabled
              boundValue=this.boundValue
          )}}
      </div>
  </div>
  
  */
  {
    "id": "OtoxhKsW",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"label\",\"controls\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[30,1,[\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[30,2,[\"toggle\"]],null,[[\"@checked\",\"@toggle\"],[[30,1,[\"active\"]],[30,1,[\"toggle\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,1],[\"annotation.annotate\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[18,3,[[28,[37,3],null,[[\"value\",\"update\",\"disabled\",\"boundValue\"],[[30,1,[\"value\"]],[30,1,[\"update\"]],[30,0,[\"disabled\"]],[30,0,[\"boundValue\"]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@field\",\"controls\",\"&default\"],false,[\"common/ui/control-label\",\"i18n-t\",\"yield\",\"hash\"]]",
    "moduleName": "plutof/components/annotation/bulk/field.hbs",
    "isStrictMode": false
  });
  let BulkAnnotationField = (_dec = (0, _object.computed)('args.field.value'), _dec2 = (0, _object.computed)('args.field.active'), _class = class BulkAnnotationField extends _component2.default {
    get boundValue() {
      return this.args.field.value;
    }
    set boundValue(value) {
      this.args.field.update(value);
      return value;
    }
    get disabled() {
      return !this.args.field.active;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "boundValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "boundValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkAnnotationField);
});