define("plutof/templates/components/study/project-areas", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8C3jdA+x",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"Projects.predefinedAreas\"],null],[30,2,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@projectAreas\",\"@editable\",\"@remove\"],[[30,2],true,[30,3]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"spacer-below\"],[12],[13],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@update\",\"@site\"],[[30,4],true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@projectAreas\",\"@remove\",\"@add\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"study/-project-areas-table\",\"sample/area/auto-complete\"]]",
    "moduleName": "plutof/templates/components/study/project-areas.hbs",
    "isStrictMode": false
  });
});