define("plutof/templates/components/interaction/target-type-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "RE2Bp6tR",
    "block": "[[[10,0],[14,0,\"interaction-radio-buttons-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"types\"]]],null]],null],null,[[[1,\"        \"],[10,\"label\"],[14,0,\"radio-btn-label\"],[12],[1,\"\\n            \"],[8,[39,2],[[16,0,[29,[\"edit-interactions__interaction__object-type edit-interactions__interaction__object-type--\",[30,1,[\"marker\"]]]]]],[[\"@selection\",\"@option\"],[[30,2],[30,1]]],null],[1,\"\\n\\n            \"],[1,[28,[35,3],[[30,1,[\"label\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"]],[\"type\",\"@selection\"],false,[\"each\",\"-track-array\",\"radio-button\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/interaction/target-type-selector.hbs",
    "isStrictMode": false
  });
});