define("plutof/components/access/edit/public-or-private", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::ButtonGroup as |buttonGroup|>
      <buttonGroup.button
          {{on "click" (fn @update true)}}
          @isActive={{@value}}
          @disabled={{@disabled}}
      >
          <span class={{icon "public"}}></span>
          {{i18n-t "Access.public"}}
      </buttonGroup.button>
  
      <buttonGroup.button
          {{on "click" (fn @update false)}}
          @isActive={{not @value}}
          @disabled={{@disabled}}
      >
          <span class={{icon "private"}}></span>
          {{i18n-t "Access.private"}}
      </buttonGroup.button>
  </Common::ButtonGroup>
  
  */
  {
    "id": "eZyAA4Kx",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"button\"]],[[4,[38,1],[\"click\",[28,[37,2],[[30,4],true],null]],null]],[[\"@isActive\",\"@disabled\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[28,[37,3],[\"public\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,4],[\"Access.public\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"button\"]],[[4,[38,1],[\"click\",[28,[37,2],[[30,4],false],null]],null]],[[\"@isActive\",\"@disabled\"],[[28,[37,5],[[30,2]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[28,[37,3],[\"private\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,4],[\"Access.private\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"buttonGroup\",\"@value\",\"@disabled\",\"@update\"],false,[\"common/button-group\",\"on\",\"fn\",\"icon\",\"i18n-t\",\"not\"]]",
    "moduleName": "plutof/components/access/edit/public-or-private.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});