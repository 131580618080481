define("plutof/components/plutof-panel/simple", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Panel
      @collapse={{@collapse}}
      @invalid={{@invalid}}
      ...attributes
      as |panel|
  >
      <panel.title>
          {{@title}}
      </panel.title>
  
      <panel.body>
          {{yield}}
      </panel.body>
  </PlutofPanel::Panel>
  
  */
  {
    "id": "H4KhaMUy",
    "block": "[[[8,[39,0],[[17,1]],[[\"@collapse\",\"@invalid\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,4,[\"title\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,5]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,4,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"&attrs\",\"@collapse\",\"@invalid\",\"panel\",\"@title\",\"&default\"],false,[\"plutof-panel/panel\",\"yield\"]]",
    "moduleName": "plutof/components/plutof-panel/simple.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});