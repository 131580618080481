define("plutof/components/search/filter-panels/annotation/time", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTime"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{2}}>
          {{#if @filterInputs.annotated_at}}
              <Search::Filters::TimeRangeInput @filter={{@filterInputs.annotated_at}} />
          {{/if}}
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "yt2kRR9D",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelTime\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"annotated_at\"]],[[[1,\"            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"annotated_at\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"if\",\"search/filters/time-range-input\"]]",
    "moduleName": "plutof/components/search/filter-panels/annotation/time.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});