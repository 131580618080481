define("plutof/templates/treatment/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QDY6N62W",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"treatment.view\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@accessRights\"],[[28,[37,2],[\"treatment\"],null],[28,[37,3],[\"CollectionManagement.treatment\"],null],[30,0,[\"model\",\"treatment\"]],\"collection_lab/treatment\",\"treatment.edit\",true,[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],[[24,\"data-test\",\"Treatment::View::GeneralData\"]],[[\"@title\"],[[28,[37,3],[\"CollectionManagement.data\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@record\"],[[30,0,[\"model\",\"treatment\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"start_date\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"end_date\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"link\"]],[\"carried_out_by\"],[[\"route\"],[\"person.view\"]]]],[1,\"\\n            \"],[1,[28,[30,1,[\"attr\"]],[\"description\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[6,[30,1,[\"attr\"]],[\"specimens\"],[[\"label\"],[[28,[37,3],[\"General.Specimens\"],null]]],[[\"default\"],[[[[1,\"            \"],[8,[39,7],null,[[\"@items\"],[[30,0,[\"model\",\"treatmentItems\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[1,[28,[35,8],null,[[\"object\",\"disabledTabs\"],[[33,9,[\"treatment\"]],[28,[37,10],[\"references\",\"glossaries\",\"links\",\"permits\"],null]]]]],[1,\"\\n\\n\"],[8,[39,11],null,[[\"@object\",\"@rights\"],[[30,0,[\"model\",\"treatment\"]],[30,0,[\"model\",\"accessRights\"]]]],null],[1,\"\\n\"]],[\"view\"],false,[\"test/route-marker\",\"navbar/view\",\"icon\",\"i18n-t\",\"plutof-panel/simple\",\"generic-views/record\",\"layout/regular-grid\",\"collection/treatment-items\",\"linked-items/view\",\"model\",\"list\",\"access/view-rights\"]]",
    "moduleName": "plutof/templates/treatment/view.hbs",
    "isStrictMode": false
  });
});