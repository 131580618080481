define("plutof/templates/sequence/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cCJJmL8c",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"sequence.index\"]],null],[1,\"\\n\"],[10,0],[14,0,\"route-marker-sequence-index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\"],[[28,[37,2],[\"sequence\"],null],[28,[37,3],[\"Sequences.title\"],null],\"sequence.add\",[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"Sequences.infoTitle\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@navbar\",\"@module\"],[[30,1],\"sequence\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.sequenceId\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"sequence.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"compound_id\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,5,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"relatedObjects\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"clipboard\"]],null,[[\"@contentType\"],[[30,0,[\"model\",\"sequenceCType\"]]]],null],[1,\"\\n    \"],[8,[30,5,[\"access\"]],null,null,null],[1,\"\\n\"]],[3,4,5]]]]],[1,\"\\n\"]],[\"navbar\",\"slots\",\"Field\",\"sequence\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"import/navbar-button\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/sequence/index.hbs",
    "isStrictMode": false
  });
});