define("plutof/mixins/moderation", ["exports", "@ember/object/mixin", "@ember/service", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/push-to-store"], function (_exports, _mixin, _service, _i18n, _notifications, _pushToStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const i18n = (0, _i18n.getI18n)();
  const statusDeny = 0;
  const statusOk = 1;
  const statusMsg = null;
  const obsRejected = -1;
  const obsAccepted = 1;
  var ModerationMixin = _mixin.default.create({
    ajax: (0, _service.inject)(),
    loadComments: function (observationID) {
      const obsurl = `/taxonoccurrence/observation/observations/${observationID}`;
      return this.ajax.request(`${obsurl}/comments/`).then(commentJSONs => {
        const store = this.store;
        const model = 'taxonoccurrence/observation/observation-moderate/comment';
        return (0, _pushToStore.default)(store, model, commentJSONs);
      });
    },
    moderate: function (observationID, status, comment, verify) {
      const store = this.store;
      return store.findRecord('taxonoccurrence/observation/observation', observationID).then(observation => {
        const moderation = store.createRecord('taxonoccurrence/observation/observation-moderate/comment', {
          moderation_status: status,
          observation: observation,
          comment: comment
        });
        return moderation.save().then(() => {
          if (verify) {
            observation.set('moderation_status', status ? obsAccepted : obsRejected);
          }
          return moderation;
        });
      }).catch(_notifications.reportError);
    },
    accept: function (observationID, message) {
      return this.moderate(observationID, statusOk, message, true);
    },
    reject: function (observationID, message) {
      return this.moderate(observationID, statusDeny, message, true);
    },
    reply: function (observationID, message) {
      return this.moderate(observationID, statusMsg, message, false);
    },
    acceptPromise: function (observationID, message) {
      return this.accept(observationID, message).then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('moderation.successfullyAccepted'));
      }, _notifications.reportError);
    },
    rejectPromise: function (observationID, reason) {
      return this.reject(observationID, reason).then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('moderation.reasonOfDenial'));
      }, _notifications.reportError);
    }
  });
  var _default = _exports.default = ModerationMixin;
});