define("plutof/components/habitat/view-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise @habitat as |habitat|}}
      <Measurements::MeasurementView::Wrapper
          @mainform={{@mainform}}
          @object={{habitat}}
          as |measurements measurementsEmpty expandMeasurement|
      >
          <PlutofPanel::Simple
              @title={{i18n-t "SamplingEvent.habitat"}}
              @collapse={{and (not this.attributesPresent) measurementsEmpty}}
          >
              <Habitat::HabitatView
                  @habitat={{habitat}}
                  @mainform={{@mainform}}
                  @measurements={{measurements}}
                  @expandMeasurement={{expandMeasurement}} />
          </PlutofPanel::Simple>
      </Measurements::MeasurementView::Wrapper>
  {{/resolve-promise}}
  
  */
  {
    "id": "0VibxVdq",
    "block": "[[[6,[39,0],[[30,1]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@mainform\",\"@object\"],[[30,3],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@title\",\"@collapse\"],[[28,[37,3],[\"SamplingEvent.habitat\"],null],[28,[37,4],[[28,[37,5],[[30,0,[\"attributesPresent\"]]],null],[30,5]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@habitat\",\"@mainform\",\"@measurements\",\"@expandMeasurement\"],[[30,2],[30,3],[30,4],[30,6]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[4,5,6]]]]],[1,\"\\n\"]],[2]]]]]],[\"@habitat\",\"habitat\",\"@mainform\",\"measurements\",\"measurementsEmpty\",\"expandMeasurement\"],false,[\"resolve-promise\",\"measurements/measurement-view/wrapper\",\"plutof-panel/simple\",\"i18n-t\",\"and\",\"not\",\"habitat/habitat-view\"]]",
    "moduleName": "plutof/components/habitat/view-panel.hbs",
    "isStrictMode": false
  });
  let HabitatViewPanel = (_dec = (0, _object.computed)('habitat.description', 'habitat.count'), _class = class HabitatViewPanel extends _component.default {
    get attributesPresent() {
      return !(0, _utils.isEmpty)(this.get('habitat.description')) || this.get('habitat.count') > 0;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "attributesPresent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "attributesPresent"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HabitatViewPanel);
});