define("plutof/components/taxonomy/common-names/table", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Tables::List
      @records={{@commonNames}}
      data-test="Taxonomy::CommonNames::Table"
      as |Field commonName|
  >
      <I18n::ModelStrings @model="taxonomy/commonname" as |translations|>
          <Field @label={{translations.common_name.label}}>
              {{commonName.common_name}}
          </Field>
  
          <Field @label={{translations.iso_639.label}}>
              {{commonName.iso_639.representation}}
          </Field>
  
          <Field @label={{translations.is_preferred.label}}>
              {{read-only-boolean commonName.is_preferred}}
          </Field>
  
          {{#if @remove}}
              <Field>
                  <button
                      {{on "click" (fn @remove commonName)}}
                      class="btn-fake-link"
                  >
                      <span class={{icon "remove"}}></span>
                  </button>
              </Field>
          {{/if}}
      </I18n::ModelStrings>
  </Tables::List>
  
  */
  {
    "id": "K8Yv4WAn",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Taxonomy::CommonNames::Table\"]],[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@model\"],[\"taxonomy/commonname\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,4,[\"common_name\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"common_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,4,[\"iso_639\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"iso_639\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,4,[\"is_preferred\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[[30,3,[\"is_preferred\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"            \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,4],[\"click\",[28,[37,5],[[30,5],[30,3]],null]],null],[12],[1,\"\\n                    \"],[10,1],[15,0,[28,[37,6],[\"remove\"],null]],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[4]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@commonNames\",\"Field\",\"commonName\",\"translations\",\"@remove\"],false,[\"tables/list\",\"i18n/model-strings\",\"read-only-boolean\",\"if\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/taxonomy/common-names/table.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});