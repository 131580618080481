define("plutof/components/common/ui/control-label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="label-control">
      <div class="plutof-label label-control__label">
          {{#if (has-block "label")}}
              {{yield to="label"}}
          {{else}}
              {{@label}}
          {{/if}}
      </div>
  
      {{yield (hash
          toggle=(component "common/ui/-control-label/toggle")
      ) to="controls"}}
  </div>
  
  */
  {
    "id": "hnUCkDCK",
    "block": "[[[10,0],[14,0,\"label-control\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-label label-control__label\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"            \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,1]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[18,3,[[28,[37,3],null,[[\"toggle\"],[[50,\"common/ui/-control-label/toggle\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@label\",\"&label\",\"&controls\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/common/ui/control-label.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});