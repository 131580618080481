define("plutof/translations/est/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    map: 'Kaart',
    locateMe: 'Minu asukoht',
    longitude: 'Pikkuskraad',
    latitude: 'Laiuskraad',
    convert: 'Konverteeri',
    maxMinute: 'Maksimum aeg (minutid, sekundid) on 60 ühikut',
    maxLongitude: 'Maksimum pikkuskraad on 180°',
    maxLatitude: 'Maksimum laiuskraad on 90°',
    ddmm: 'DD MM SS',
    DDMMM: 'DD MM.MMMMM',
    degreesDD: 'Kraadid (DD°)',
    minutesMM: 'Minutid (MM\')',
    secondsSS: 'Sekundid (SS\'\')',
    minutesNSeconds: 'Minutid (MM.mmm\')',
    southAndNorth: '-S/N',
    westAndEast: '-W/E',
    helpText: 'Pikkuskraad/Laiuskraad. Võimalik on valida kolme erineva koordinaatide formaadi vahel: kraadid ja kraadi komakohad (D, vaikimisi); kraadid, minutid ja minuti komakohad (DM); kraadid, minutid ja sekundid (DMS). Lõuna- ja läänepoolkera koordinaatide märkimiseks tuleb kasutada miinusmärki kraadi ees.',
    fullscreen: 'Täisekraan',
    showOnMap: 'Kuva kaardil',
    parsingWentWrong: 'Midagi läks valesti geoJSON transleerimisel, vaata https://geojsonlint.com/ valideerimiseks.',
    shouldContain: 'geoJSON peaks sisladama ühte tüüpi objekte, näiteks: Punktid, Hulknurgad.',
    markers: 'Kaardi markerid',
    geoJSON: {
      empty: 'GeoJSON fail on tühi'
    },
    controls: {
      navigate: 'Navigeeri',
      modify: 'Muuda',
      point: 'Punkt',
      polygon: 'Polügoon',
      box: 'Kast',
      radius: 'Raadius',
      clearMap: 'Puhasta kaart',
      controls: 'Kaardi toimingud'
    },
    layers: {
      heatmap: 'Heatmap',
      maaamet: 'Maa-amet',
      kaitsealad: 'Kaitsealad',
      waterbirds: 'Veelinnud',
      taimeatlas: 'Eesti taimede levikuatlas',
      grid2x2: '2x2 km ruudustik',
      baseMap: 'Aluskaart',
      optionalLayers: 'Kaardikihid',
      layers: 'Kaardikihid'
    },
    import: {
      file: 'Fail',
      fileTooltip: 'Kasuta geomeetria faili PlutoFi Failide repositooriumist.',
      fileParseError: 'Vale geomeetria formaat.',
      fileSizeError: 'Fail on liiga suur.',
      placeholder: 'Keebi või sisesta',
      geometry: 'Geomeetria',
      uploadTooltip: 'Lae geomeetria fail arvutist.'
    }
  };
});