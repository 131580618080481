define("plutof/components/search/filter-panels/occurrence-taxon-inputs", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::TaxonFilterInputs
          @filters={{@filterInputs}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.taxon_rank}}
          @autocompletePreset="taxonomy/taxonrank" />
  
      <Search::FilterInput @filter={{@filterInputs.new_name}} />
  
      <Search::Filters::Typification
          @filter={{@filterInputs.typification}}
          @existsFilter={{@filterInputs.has_typification}} />
  
      <Search::Filters::ChoiceOrExists
          @choiceFilter={{@filterInputs.assessment}}
          @existsFilter={{@filterInputs.assessment__exists}} />
  
      <Search::FilterInput @filter={{@filterInputs.interacting_taxa}} />
      <Search::FilterInput @filter={{@filterInputs.prot_category}} />
      <Search::FilterInput @filter={{@filterInputs.substrate}} />
      <Search::FilterInput @filter={{@filterInputs.substrate_type}} />
      <Search::FilterInput @filter={{@filterInputs.exsiccata}} />
      <Search::FilterInput @filter={{@filterInputs.cultivar_groups}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.sequence_taxon}}
          @autocompletePreset="includeCommonnames" />
  
      <Search::FilterInput @filter={{@filterInputs.cluster}} />
  </Layout::RegularGrid>
  
  */
  {
    "id": "5qgz5JDj",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filters\"],[[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"taxon_rank\"]],\"taxonomy/taxonrank\"]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"new_name\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@filter\",\"@existsFilter\"],[[30,1,[\"typification\"]],[30,1,[\"has_typification\"]]]],null],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@choiceFilter\",\"@existsFilter\"],[[30,1,[\"assessment\"]],[30,1,[\"assessment__exists\"]]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"interacting_taxa\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"prot_category\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"substrate\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"substrate_type\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"exsiccata\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"cultivar_groups\"]]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"sequence_taxon\"]],\"includeCommonnames\"]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@filter\"],[[30,1,[\"cluster\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"layout/regular-grid\",\"search/taxon-filter-inputs\",\"search/filter-input\",\"search/filters/typification\",\"search/filters/choice-or-exists\"]]",
    "moduleName": "plutof/components/search/filter-panels/occurrence-taxon-inputs.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});