define("plutof/components/annotation/bulk/sections/event", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.samplingEvent"}}>
      <Layout::RegularGrid @columns={{2}}>
          <Annotation::Bulk::Field @field={{@fields.description}} as |field|>
              <FlexibleTextarea
                  @value={{field.boundValue}}
                  @disabled={{field.disabled}}
                  class="form-control" />
          </Annotation::Bulk::Field>
  
          <Annotation::Bulk::MultipleField @field={{@fields.gathering_agents}}>
              <:input as |field|>
                  <AutoComplete::Model
                      @model="agent/person"
                      @update={{field.add}}
                      @disabled={{field.disabled}} />
              </:input>
          </Annotation::Bulk::MultipleField>
  
          <Annotation::Bulk::Field @field={{@fields.timespan_begin}} as |field|>
              <DateTimeInput
                  @value={{field.boundValue}}
                  @useEmptyLabels={{false}}
                  @disabled={{field.disabled}} />
          </Annotation::Bulk::Field>
  
          <Annotation::Bulk::Field @field={{@fields.timespan_end}} as |field|>
              <DateTimeInput
                  @value={{field.boundValue}}
                  @useEmptyLabels={{false}}
                  @disabled={{field.disabled}} />
          </Annotation::Bulk::Field>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "5Wucu9Jg",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.samplingEvent\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@field\"],[[30,1,[\"description\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],[[24,0,\"form-control\"]],[[\"@value\",\"@disabled\"],[[30,2,[\"boundValue\"]],[30,2,[\"disabled\"]]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@field\"],[[30,1,[\"gathering_agents\"]]]],[[\"input\"],[[[[1,\"\\n                \"],[8,[39,6],null,[[\"@model\",\"@update\",\"@disabled\"],[\"agent/person\",[30,3,[\"add\"]],[30,3,[\"disabled\"]]]],null],[1,\"\\n            \"]],[3]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@field\"],[[30,1,[\"timespan_begin\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,7],null,[[\"@value\",\"@useEmptyLabels\",\"@disabled\"],[[30,4,[\"boundValue\"]],false,[30,4,[\"disabled\"]]]],null],[1,\"\\n        \"]],[4]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@field\"],[[30,1,[\"timespan_end\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,7],null,[[\"@value\",\"@useEmptyLabels\",\"@disabled\"],[[30,5,[\"boundValue\"]],false,[30,5,[\"disabled\"]]]],null],[1,\"\\n        \"]],[5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@fields\",\"field\",\"field\",\"field\",\"field\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"annotation/bulk/field\",\"flexible-textarea\",\"annotation/bulk/multiple-field\",\"auto-complete/model\",\"date-time-input\"]]",
    "moduleName": "plutof/components/annotation/bulk/sections/event.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});