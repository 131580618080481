define("plutof/helpers/i18n-t", ["exports", "@ember/component/helper", "@ember/template", "plutof/utils/i18n"], function (_exports, _helper, _template, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.translate = translate;
  function translate([key], options) {
    return (0, _template.htmlSafe)((0, _i18n.getI18n)().t(key, {
      hash: options
    }));
  }
  var _default = _exports.default = (0, _helper.helper)(translate);
});