define("plutof/components/common/data-quality/warning/taxon-rank", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip
      @tooltip={{i18n-t 'DataQuality.warnings.taxonRank'}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="icon-taxonnode data-quality-warning data-quality-warning--icon"></span>
  </ToolTip>
  
  */
  {
    "id": "RO9/4toh",
    "block": "[[[8,[39,0],null,[[\"@tooltip\",\"@class\"],[[28,[37,1],[\"DataQuality.warnings.taxonRank\"],null],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"icon-taxonnode data-quality-warning data-quality-warning--icon\"],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"tool-tip\",\"i18n-t\"]]",
    "moduleName": "plutof/components/common/data-quality/warning/taxon-rank.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});