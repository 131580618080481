define("plutof/components/tables/column-control/-toggle", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @toggle}}
      class="column-control-toggle"
      ...attributes
  >
      <span class="fas {{if @visible 'fa-toggle-on' 'fa-toggle-off'}}"></span>
  
      {{yield}}
  </button>
  
  */
  {
    "id": "dCMu1Ksx",
    "block": "[[[11,\"button\"],[24,0,\"column-control-toggle\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"fas \",[52,[30,3],\"fa-toggle-on\",\"fa-toggle-off\"]]]],[12],[13],[1,\"\\n\\n    \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@toggle\",\"@visible\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "plutof/components/tables/column-control/-toggle.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});