define("plutof/components/collection/treatment-items", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::RecordListView @records={{@items}} as |item|>
      {{#let (if
          (is-equal item.content_object.app_label 'livingculture')
          'livingspecimen.view'
          'specimen.view'
      ) as |route|}}
          <LinkTo @route={{route}} @model={{item.content_object.id}}>
              {{item.content_object.name}}
          </LinkTo>
      {{/let}}
  </Common::RecordListView>
  
  */
  {
    "id": "h/+Ia8MC",
    "block": "[[[8,[39,0],null,[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[52,[28,[37,3],[[30,2,[\"content_object\",\"app_label\"]],\"livingculture\"],null],\"livingspecimen.view\",\"specimen.view\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[[30,3],[30,2,[\"content_object\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"content_object\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]]]],[2]]]]],[1,\"\\n\"]],[\"@items\",\"item\",\"route\"],false,[\"common/record-list-view\",\"let\",\"if\",\"is-equal\",\"link-to\"]]",
    "moduleName": "plutof/components/collection/treatment-items.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});