define("plutof/components/related-objects/content/areas", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field area|>
      <Field @label={{i18n-t "RelatedObjectsViewColumn.name"}}>
          <LinkTo @route="area.view" @model={{area.id}}>
              {{area.name}}
          </LinkTo>
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.country"}}>
          {{area.country_name}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.timespan"}}>
          {{formatted-timespan area.timespan_begin area.timespan_end}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date area.updated_at}}
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "0LMdRwJn",
    "block": "[[[8,[39,0],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"area.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.country\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"country_name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.timespan\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,3,[\"timespan_begin\"]],[30,3,[\"timespan_end\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[[30,3,[\"updated_at\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@pagination\",\"Field\",\"area\"],false,[\"related-objects/content\",\"i18n-t\",\"link-to\",\"formatted-timespan\",\"formatted-date\"]]",
    "moduleName": "plutof/components/related-objects/content/areas.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});