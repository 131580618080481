define("plutof/templates/reference/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xfybL+KP",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"reference.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\"],[[28,[37,2],[\"reference\"],null],[28,[37,3],[\"References.newReference\"],null],[30,0,[\"model\",\"reference\"]],[28,[37,4],[\"triggerReset\"],null]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@reference\",\"@authors\",\"@accessRights\",\"@itemData\",\"@objectsToDelete\",\"@validationContext\",\"@save\"],[[30,0,[\"model\",\"reference\"]],[30,0,[\"model\",\"authors\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"objectsToDelete\"]],[30,0,[\"validationContext\"]],[30,0,[\"save\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"reference/edit\"]]",
    "moduleName": "plutof/templates/reference/add.hbs",
    "isStrictMode": false
  });
});