define("plutof/components/forms/view/taxon-sheet", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "plutof/utils/has-many"], function (_exports, _component, _templateFactory, _component2, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Forms.taxonSheet.title"}}
      @collapse={{not this.pagination.objectCount}}
  >
      <Pagination::PageControls @pagination={{this.pagination}} />
  
      <ol class="taxon-sheet-view__taxa">
          {{#each this.pagination.objects as |taxon|}}
              <li>
                  <LinkTo @route="taxonomy.view" @model={{taxon.taxon_id}}>
                      {{taxon.taxon_name}}
                  </LinkTo>
              </li>
          {{/each}}
      </ol>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ZmyIYzYC",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Forms.taxonSheet.title\"],null],[28,[37,2],[[30,0,[\"pagination\",\"objectCount\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],null],[1,\"\\n\\n    \"],[10,\"ol\"],[14,0,\"taxon-sheet-view__taxa\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pagination\",\"objects\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"taxonomy.view\",[30,1,[\"taxon_id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,1,[\"taxon_name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"taxon\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"not\",\"pagination/page-controls\",\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "plutof/components/forms/view/taxon-sheet.hbs",
    "isStrictMode": false
  });
  const HasMany = (0, _hasMany.HasManyViewMixin)('measurement/sheet-taxon', {
    parentField: 'mainform'
  });
  let TaxonSheetView = (_dec = (0, _component2.classNames)('taxon-sheet-view'), _dec(_class = class TaxonSheetView extends _component.default.extend(HasMany) {}) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonSheetView);
});