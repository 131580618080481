define("plutof/translations/eng/publishing", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    doi: {
      doi: 'DataCite DOI',
      dois: 'DataCite DOIs',
      files: 'Files',
      creators: 'Creators',
      taxa: 'Taxa',
      subjects: 'Subjects',
      contributors: 'Contributors',
      dates: 'Dates',
      language: 'Language',
      descriptions: 'Descriptions',
      alternateIds: 'Alternate Identifiers',
      sizes: 'Sizes',
      formats: 'Formats',
      rightsList: 'Rights List',
      newDOI: 'New DataCite DOI',
      editDOI: 'Edit DataCite DOI',
      documents: 'Document(s)',
      detailedInformation: 'Detailed information',
      metadata: 'Metadata',
      geography: 'Geography',
      description: 'Description',
      title: 'Title',
      subtitle: 'Subtitle',
      abstract: 'Abstract',
      location: 'Location',
      publisher: 'Publisher',
      publishedIn: 'Published',
      // year/platform
      resourceType: 'Resource Type',
      relatedIdentifiers: 'Related Identifiers',
      bytes: 'Bytes',
      newSet: 'New Set',
      existing: 'Existing',
      existingRights: 'Existing Rights',
      rights: 'Rights',
      rightsURI: 'Rights URI',
      name: 'Name',
      otherElements: 'Other elements',
      recommended: 'Recommended',
      media: 'Media',
      manualSet: 'Manual set',
      size: 'Size of your data in handwritten text, for example: 3 files 5mb.',
      format: 'Describe file formats, for example: pdf, jpg, txt.',
      dataCiteFileFormats: 'DOI file formats',
      fileLabel: 'Uploaded file',
      fileLabelToolT: 'Previously uploaded files that you have access to. They are also available in your Filerepository.',
      manualLocationTooltip: 'Point: "latitude longitude"; Box: "top-latitude top-longitude bottom-latitude bottom-longitude"',
      manualLocationPlaceholder: 'lat lon [lat lon]',
      rightsSetting: 'Rights settings',
      relatedSettings: 'Related dataset settings',
      setLocationInput: 'Set',
      properties: 'Properties',
      author: 'Author',
      downloadEML: 'Download EML',
      fundingRef: 'Funding reference',
      fundingSource: 'Source',
      fundingAward: 'Award',
      fundingType: 'Type',
      notRegistered: 'not registered'
    },
    dataResource: {
      intro: {
        description: 'Manage and publish Global Biodiversity Information Facility datasets',
        publishing: 'Data Publishing to GBIF',
        title: 'GBIF Datasets'
      },
      fields: {
        DwCALink: 'Darwin Core Archive',
        DwCALinkTooltip: 'Download currently published Darwin Core Archive (DwC-A) file.',
        contacts: 'Contacts',
        description: 'Description',
        geographicCoverage: 'Geographic coverage',
        homepage: 'Homepage',
        language: 'Language',
        licence: 'Licence',
        logoURL: 'Logo',
        modified: 'Modified',
        project: 'Project',
        published: 'Published',
        taxonomicCoverage: 'Taxonomic coverage',
        formationPeriod: 'Formation period',
        title: 'Title',
        recordCount: 'Record count',
        recordType: 'Record type',
        datasetType: 'Dataset type',
        shCode: 'UNITE SH code as scientific name'
      },
      tooltips: {
        geographicCoverage: 'Geographic Coverage specifies spatial information about a project, a resource, or an entity within a resource.',
        taxonomicCoverage: 'Taxonomic Coverage specifies taxonomic information about a project, a resource, or an entity within a resource.',
        formationPeriod: 'Text description of the time period during which the collection was assembled (e.g., "Victorian", "1922-1932", "c. 1750"). Use this type to indicate an ongoing collection (e.g., "2010-current").',
        formationPeriodPlaceholder: 'Ex. 2018-08-01, 2018 - current',
        logo: 'Logo associated with the dataset. This image will be used to illustrate dataset page in GBIF.',
        licence: 'The licence that you apply to a dataset provides a standardized way to define appropriate uses of your work. GBIF encourages publishers to adopt the least restrictive licence possible from among three machine-readable options (CC0 1.0, CC-BY 4.0 or CC-BY-NC 4.0) to encourage the widest possible use and application of data. Learn more <a href="https://www.gbif.org/terms" target="_blank">here</a>. If you are unable to choose one of the three options, and your dataset contains occurrence data, you will not be able to register your dataset with GBIF or make it globally discoverable through GBIF.org. If you feel unable to select one of the three options, please contact the GBIF Secretariat at participation@gbif.org.',
        description: 'A brief overview of the resource that is being documented broken into paragraphs.',
        dataLanguage: 'The primary language in which the described data (not the metadata document) is written.',
        projectAbstract: 'Descriptive abstract that summarizes information about the research project.',
        projectFunding: 'The funding field is used to provide information about funding sources for the project such as: grant and contract numbers; names and addresses of funding sources. Other funding-related information may also be included.',
        shCode: 'Use sequence record\'s UNITE SH belonging (instead of SH identification or Identification) as record\'s Scientific name in GBIF dataset.'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        editDataset: 'Edit GBIF Dataset',
        findLogo: 'Find logo',
        uploadLogo: 'Upload logo',
        newDataset: 'New GBIF Dataset',
        viewDataset: 'GBIF Dataset',
        publishingInProgress: 'In progress (collecting data)',
        GBIFUploadInProgress: 'In progress (waiting for GBIF)',
        deleted: 'This dataset is deleted.'
      },
      list: {
        description: (0, _helpers.alias)('dataResource.fields.description'),
        edit: 'Edit',
        language: (0, _helpers.alias)('dataResource.fields.language'),
        modified: (0, _helpers.alias)('dataResource.fields.modified'),
        published: (0, _helpers.alias)('dataResource.fields.published'),
        title: (0, _helpers.alias)('dataResource.fields.title'),
        deleted: 'deleted'
      },
      panels: {
        contacts: (0, _helpers.alias)('dataResource.fields.contacts'),
        bibliography: 'Bibliography',
        general: (0, _helpers.alias)('General.generalData'),
        project: (0, _helpers.alias)('dataResource.fields.project'),
        history: 'History'
      },
      tabs: {
        sequences: 'Related Records: sequences',
        specimens: 'Related Records: specimens',
        observations: 'Related Records: observations',
        referencebased: 'Related Records: reference based'
      },
      project: {
        title: 'Title',
        abstract: 'Abstract',
        funding: 'Funding'
      },
      buttons: {
        publish: 'Publish to GBIF',
        generateDwCA: 'Generate DwCA'
      },
      dialogs: {
        publish: {
          title: 'Publishing data to GBIF',
          content: 'You are about to publish your dataset to GBIF. Published dataset can be amended by consecutive updates, but can not be deleted.',
          confirm: 'Publish',
          cancel: (0, _helpers.alias)('General.Cancel')
        }
      },
      generateDwCA: {
        process: 'Scheduling DwCA export...',
        success: 'DwCA export scheduled. You will be notified via e-mail.'
      },
      history: {
        created: 'Created at',
        publish: 'Publish'
      },
      types: {
        occurrences: 'Occurrences',
        metadata: 'Metadata'
      }
    },
    datasetContact: {
      fields: {
        email: 'E-mail',
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Position',
        agentType: 'Contact type',
        orcid: 'ORCID identifier'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        personSearch: 'Person search'
      },
      tooltips: {
        agentTypes: {
          contact: 'Contact represents the people and organizations that should be contacted to get more information about the resource, that curate the resource or to whom putative problems with the resource or its data should be addressed.',
          resourceCreator: 'Creator represents the people and organizations who created the resource, in priority order.',
          metadataProvider: 'Metadata provider metadata provider represents the people and organizations responsible for producing the resource metadata.'
        },
        orcid: 'The ORCID iD is an https URI with a 16-digit number that is compatible with the ISO Standard (ISO 27729), also known as the International Standard Name Identifier (ISNI), e.g. https://orcid.org/0000-0001-2345-6789'
      }
    },
    dataResourceBibliography: {
      citation: 'Citation',
      url: 'URL'
    },
    datasetRecord: {
      fields: {
        commune: 'Commune',
        createdAt: 'Added to dataset',
        deleted: 'Is deleted',
        district: 'Disctrict',
        eventID: 'Event ID',
        locality_text: 'Locality text',
        name: 'ID',
        project: 'Project',
        published: 'Published',
        remarks: 'Remarks',
        taxonNode: 'Taxon',
        timespan: 'Timespan',
        type: 'Type',
        record: 'Record'
      },
      general: {
        controls: (0, _helpers.alias)('General.controls'),
        createdAtNow: 'Just now'
      },
      recordTypes: {
        'Sequence': 'Sequence'
      },
      addObservationTooltip: 'Observations can be added through Clipboard.'
    },
    ena: {
      title: 'ENA',
      dataset: 'Dataset',
      datasets: 'Datasets',
      newDataset: 'New Dataset',
      editDataset: 'Edit Dataset',
      publishingLabTitle: 'ENA Datasets',
      publishingLabDescription: 'European Nucleotide Archive',
      routeTitle: {
        add: 'New ENA Dataset',
        index: 'ENA Datasets'
      }
    }
  };
});