define("plutof/components/livingspecimen/view/duplicates", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/misc/abstract", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _object, _service, _abstract, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise this.duplicates as |duplicates|}}
      {{#if duplicates.length}}
          <PlutofPanel::ItemList
              @collapse={{not duplicates.length}}
              as |panel|
          >
              <panel.header
                  @title={{i18n-t "LivingCultures.duplicates"}}
                  @count={{duplicates.length}}
              >
              </panel.header>
  
              <panel.content>
                  <table class="plutof-table table-hover">
                      <thead>
                          <tr>
                              <th>
                                  <Common::LocalLabel
                                      @label="LivingCultures.duplicateRecord.duplicateRecord.label"
                                      @tooltip="LivingCultures.duplicateRecord.duplicateRecord.tooltip" />
                              </th>
                          </tr>
                      </thead>
  
                      <tbody>
                          {{#each duplicates as |duplicate|}}
                              <tr>
                                  <td>
                                      <Common::RecordLinkByUrl
                                          @viewRoute="livingspecimen.view"
                                          @url={{duplicate.duplicate_specimen}} />
                                  </td>
                              </tr>
                          {{/each}}
                      </tbody>
                  </table>
              </panel.content>
          </PlutofPanel::ItemList>
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "b4qJ9b1c",
    "block": "[[[6,[39,0],[[30,0,[\"duplicates\"]]],null,[[\"default\"],[[[[41,[30,1,[\"length\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@collapse\"],[[28,[37,3],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,4],[\"LivingCultures.duplicates\"],null],[30,1,[\"length\"]]]],[[\"default\"],[[[[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n                \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[1,\"\\n                    \"],[10,\"thead\"],[12],[1,\"\\n                        \"],[10,\"tr\"],[12],[1,\"\\n                            \"],[10,\"th\"],[12],[1,\"\\n                                \"],[8,[39,5],null,[[\"@label\",\"@tooltip\"],[\"LivingCultures.duplicateRecord.duplicateRecord.label\",\"LivingCultures.duplicateRecord.duplicateRecord.tooltip\"]],null],[1,\"\\n                            \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\\n                    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,1]],null]],null],null,[[[1,\"                            \"],[10,\"tr\"],[12],[1,\"\\n                                \"],[10,\"td\"],[12],[1,\"\\n                                    \"],[8,[39,8],null,[[\"@viewRoute\",\"@url\"],[\"livingspecimen.view\",[30,3,[\"duplicate_specimen\"]]]],null],[1,\"\\n                                \"],[13],[1,\"\\n                            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"duplicates\",\"panel\",\"duplicate\"],false,[\"resolve-promise\",\"if\",\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"common/local-label\",\"each\",\"-track-array\",\"common/record-link-by-url\"]]",
    "moduleName": "plutof/components/livingspecimen/view/duplicates.hbs",
    "isStrictMode": false
  });
  let LivingspecimenViewDuplicates = (_dec = (0, _object.computed)('args.livingspecimen.id'), _class = class LivingspecimenViewDuplicates extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get duplicates() {
      const url = (0, _abstract.construct_request)('/taxonoccurrence/livingculture/living-specimen-duplicates/', {
        original_specimen: this.args.livingspecimen.id
      });
      return this.ajax.request(url).then(duplicates => {
        return duplicates;
      }, _notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "duplicates", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "duplicates"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LivingspecimenViewDuplicates);
});