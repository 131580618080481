define("plutof/controllers/event/view", ["exports", "@ember/controller", "@ember/object", "plutof/utils/reflection"], function (_exports, _controller, _object, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let EventViewController = (_dec = (0, _object.computed)('model.accessRights'), _dec2 = (0, _object.computed)('model.event.gathering_agents.[]'), _dec3 = (0, _object.computed)('model.event.id'), _dec4 = (0, _object.computed)('model.event.id'), _class = class EventViewController extends _controller.default {
    get navbarPermissions() {
      return {
        canModify: this.model.accessRights.canModify,
        canDelete: this.model.accessRights.canDelete
      };
    }
    get gatheringAgents() {
      return this.get('model.event.gathering_agents').map(agent => {
        return {
          id: (0, _reflection.recordURLToID)(agent.url),
          name: agent.name
        };
      });
    }
    get relatedOccurrencesQuery() {
      return {
        samplingevent: this.model.event.id,
        ordering: '-updated_at'
      };
    }
    get relatedOccurrencesClipboardQuery() {
      return {
        samplingevent: this.model.event.id
      };
    }
    makeTransition(routeToTransition) {
      return this.transitionToRoute(routeToTransition, {
        queryParams: {
          event_id: this.get('model.event.id')
        }
      });
    }
    addPhotobank(route) {
      this.transitionToRoute(route, {
        queryParams: {
          event_id: this.get('model.event.id')
        }
      });
    }
    newSpecimen() {
      return this.makeTransition('specimen.add');
    }
    newObservation() {
      return this.makeTransition('observation.add');
    }
    newCulture() {
      return this.makeTransition('livingspecimen.add');
    }
    newMaterialSample() {
      return this.makeTransition('materialsample.add');
    }
    newReferenceOccurrence() {
      return this.makeTransition('referencebased.add');
    }
    newSequence() {
      return this.makeTransition('sequence.add');
    }
    newPhotobank() {
      return this.addPhotobank('photobank.add');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "navbarPermissions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "navbarPermissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gatheringAgents", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "gatheringAgents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesQuery", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSpecimen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newSpecimen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newObservation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newObservation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newCulture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newCulture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newMaterialSample", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newMaterialSample"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newReferenceOccurrence", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newReferenceOccurrence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSequence", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newSequence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newPhotobank", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newPhotobank"), _class.prototype), _class);
  var _default = _exports.default = EventViewController;
});