define("plutof/templates/components/experiment/sequencing/view/tube-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7TkV9TW8",
    "block": "[[[10,\"table\"],[14,0,\"data-table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,0],[\"experiment.extraction.general.source\"],[[\"disableTooltip\"],[true]]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,1,[\"representation\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.primer\",true]]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"primer\",\"representation\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.original_file\",true]]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"source_file\",\"representation\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.raw_sequence\",true]]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"raw_sequence\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,1],null,[[\"path\",\"hideTooltip\"],[\"sequencing-result.quality\",true]]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"quality\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@source\",\"@material\"],false,[\"common/local-label\",\"plutof-labelc\"]]",
    "moduleName": "plutof/templates/components/experiment/sequencing/view/tube-summary.hbs",
    "isStrictMode": false
  });
});