define("plutof/templates/components/experiment/common/material-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "18feCDZ4",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"selectionSize\",\"selection\",\"materialQueryParams\",\"panelIsOpen\",\"toggleMultiple\",\"toggleForwardingPanel\",\"forwardSelection\",\"targetAutocompleteData\",\"selector\"],[[30,0,[\"selectionSize\"]],[30,0,[\"selection\"]],[30,0,[\"materialQueryParams\"]],[30,0,[\"panelIsOpen\"]],[28,[37,2],[[30,0],[30,0,[\"toggleMultiple\"]]],null],[28,[37,2],[[30,0],[30,0,[\"toggleForwardingPanel\"]]],null],[28,[37,2],[[30,0],[30,0,[\"forwardSelection\"]]],null],[30,0,[\"targetAutocompleteData\"]],[50,\"experiment/common/material-selector\",0,null,[[\"selection\",\"toggle\"],[[30,0,[\"selection\"]],[28,[37,2],[[30,0],[30,0,[\"toggle\"]]],null]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"action\",\"component\"]]",
    "moduleName": "plutof/templates/components/experiment/common/material-container.hbs",
    "isStrictMode": false
  });
});