define("plutof/components/collection-lab/condition-assessment/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations", "plutof/components/collection-lab/condition-assessment/-general-data"], function (_exports, _component, _templateFactory, _componentValidations, _generalData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ConditionAssessmentEditModel = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <CollectionLab::ConditionAssessment::-GeneralData
      @data={{@model.generalData}}
      @validationContext={{@validationContext.generalData}} />
  
  */
  {
    "id": "XG3e1lNq",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@validationContext\"],[[30,1,[\"generalData\"]],[30,2,[\"generalData\"]]]],null],[1,\"\\n\"]],[\"@model\",\"@validationContext\"],false,[\"collection-lab/condition-assessment/-general-data\"]]",
    "moduleName": "plutof/components/collection-lab/condition-assessment/edit.hbs",
    "isStrictMode": false
  });
  class ConditionAssessmentEditModel {
    constructor({
      store,
      generalData
    }) {
      this.store = store;
      this.generalData = generalData;
    }
    async save() {
      await this.generalData.save();
    }
    static create(store) {
      const assessment = store.createRecord('collection-lab/condition-assessment');
      const generalData = (0, _generalData.createConditionAssessmentData)(assessment);
      return new ConditionAssessmentEditModel({
        store,
        generalData
      });
    }
    static async load(store, assesmentID) {
      const assessment = await store.findRecord('collection-lab/condition-assessment', assesmentID);
      const generalData = await (0, _generalData.loadConditionAssessmentData)(assessment);
      return new ConditionAssessmentEditModel({
        store,
        generalData
      });
    }
  }
  _exports.ConditionAssessmentEditModel = ConditionAssessmentEditModel;
  class ConditionAssessmentEdit extends _component.default.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'generalData',
        label: 'General.generalData'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConditionAssessmentEdit);
});