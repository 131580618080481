define("plutof/templates/components/materialsample/sub-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ycwwCOXm",
    "block": "[[[8,[30,1],[[16,0,[29,[[52,[30,0,[\"hasError\"]],\"has-error\"]]]]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"MaterialSamples.name\"],null]],[1,\"\\n\\n\"],[41,[51,[30,0,[\"itemIsUnique\"]]],[[[1,\"            (\"],[1,[28,[35,1],[\"Specimen.alreadyExists\"],null]],[1,\")\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],[[24,\"placeholder\",\"Name\"],[24,0,\"mini-input occurrences-table__focus-candidate\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"name\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@cell\",\"@materialsample\"],false,[\"if\",\"i18n-t\",\"unless\",\"input\"]]",
    "moduleName": "plutof/templates/components/materialsample/sub-name.hbs",
    "isStrictMode": false
  });
});