define("plutof/templates/components/promise-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "APygkwkN",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"buttonDisabled\"]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"clicked\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[8,[39,2],null,[[\"@tooltip\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[15,0,[29,[[30,0,[\"icon\"]]]]],[12],[13],[1,\"\\n\\n            \"],[18,3,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[15,0,[29,[[30,0,[\"icon\"]]]]],[12],[13],[1,\"\\n\\n        \"],[18,3,null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@tooltip\",\"&default\"],false,[\"on\",\"if\",\"tool-tip\",\"yield\"]]",
    "moduleName": "plutof/templates/components/promise-button.hbs",
    "isStrictMode": false
  });
});