define("plutof/components/layer/occurrence-areas/constraints-check", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "ol/format"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.areaOutsideOfLayer}}
      <span class="failed-check-message">
          &nbsp;
          <span class={{icon "info"}}></span>
          {{i18n-t "Layer.info.areaOutsideLayerError"}}
      </span>
  {{/if}}
  
  */
  {
    "id": "sPkP8umv",
    "block": "[[[41,[30,0,[\"areaOutsideOfLayer\"]],[[[1,\"    \"],[10,1],[14,0,\"failed-check-message\"],[12],[1,\"\\n         \\n        \"],[10,1],[15,0,[28,[37,1],[\"info\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,2],[\"Layer.info.areaOutsideLayerError\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/layer/occurrence-areas/constraints-check.hbs",
    "isStrictMode": false
  });
  let ConstraintsCheck = _exports.default = (_dec = (0, _object.computed)('args.samplingArea.geom', 'args.layerArea'), _class = class ConstraintsCheck extends _component2.default {
    constructor(...args) {
      super(...args);
      this.wkt = new _format.WKT();
    }
    get areaOutsideOfLayer() {
      const {
        samplingArea,
        layerArea
      } = this.args;
      if ((0, _utils.isNone)(samplingArea) || (0, _utils.isNone)(samplingArea.geom)) {
        return false;
      }
      const samplingAreaFeatures = this.wkt.readFeatures(samplingArea.geom);
      const layerAreaFeatures = this.wkt.readFeatures(layerArea.geom);
      const areaIntersectsLayer = samplingAreaFeatures.some(samplingAreaFeature => {
        return layerAreaFeatures.some(layerAreaFeature => {
          const areaExtent = samplingAreaFeature.getGeometry().getExtent();
          const intersectsGeometry = layerAreaFeature.getGeometry().intersectsExtent(areaExtent);
          return intersectsGeometry;
        });
      });
      return !areaIntersectsLayer;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "areaOutsideOfLayer", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "areaOutsideOfLayer"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConstraintsCheck);
});