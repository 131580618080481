define("plutof/components/transaction/return-date", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "moment"], function (_exports, _component, _templateFactory, _component2, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (formatted-date @transaction.expected_end_date) as |date|}}
      {{#if status.overdue}}
          <span class="list-highlight">
              {{date}} ({{i18n-t "PlutofListViewColumn.overdue"}})
          </span>
      {{else if status.due}}
          <span class="list-highlight">
              {{date}} ({{i18n-t "PlutofListViewColumn.due"}})
          </span>
      {{else}}
          {{date}}
      {{/if}}
  {{/let}}
  
  */
  {
    "id": "jlLBJeE0",
    "block": "[[[44,[[28,[37,1],[[30,1,[\"expected_end_date\"]]],null]],[[[41,[33,3,[\"overdue\"]],[[[1,\"        \"],[10,1],[14,0,\"list-highlight\"],[12],[1,\"\\n            \"],[1,[30,2]],[1,\" (\"],[1,[28,[35,4],[\"PlutofListViewColumn.overdue\"],null]],[1,\")\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[33,3,[\"due\"]],[[[1,\"        \"],[10,1],[14,0,\"list-highlight\"],[12],[1,\"\\n            \"],[1,[30,2]],[1,\" (\"],[1,[28,[35,4],[\"PlutofListViewColumn.due\"],null]],[1,\")\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2]],[1,\"\\n    \"]],[]]]],[]]]],[2]]]],[\"@transaction\",\"date\"],false,[\"let\",\"formatted-date\",\"if\",\"status\",\"i18n-t\"]]",
    "moduleName": "plutof/components/transaction/return-date.hbs",
    "isStrictMode": false
  });
  class TransactionReturnDate extends _component2.default {
    get status() {
      const {
        expected_end_date,
        is_locked
      } = this.args.transaction;
      if (is_locked || !expected_end_date) {
        return {
          due: false,
          overdue: false
        };
      }
      return {
        due: moment().isAfter(moment(expected_end_date).subtract(14, 'days')),
        overdue: moment().isAfter(moment(expected_end_date))
      };
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionReturnDate);
});