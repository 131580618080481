define("plutof/templates/permit/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fAJBhtkc",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\"],[[28,[37,1],[\"permit\"],null],[28,[37,2],[\"permit.permits\"],null],\"permit.add\",[28,[37,3],[\"goBack\"],null],[30,0,[\"model\",\"pagination\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,5],[\"permit.permit.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"permit.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,5],[\"permit.permit.status\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"statusDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,5],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"permitTypeDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,5],[\"permit.permit.created_by\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"created_by\",\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"]],[\"Field\",\"permit\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"route-action\",\"tables/list-route\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/templates/permit/index.hbs",
    "isStrictMode": false
  });
});