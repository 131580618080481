define("plutof/templates/components/unite/options-toggle-control", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "UDHQckDV",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"entries\"]]],null]],null],null,[[[1,\"        \"],[8,[30,1,[\"column\"]],null,[[\"@toggle\",\"@visible\"],[[28,[37,3],[[30,0,[\"toggleOption\"]],[30,2]],null],[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,4],[[30,2,[\"title\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2]],null]],[1]]]]],[1,\"\\n\"]],[\"columnSelector\",\"entry\"],false,[\"tables/column-selector\",\"each\",\"-track-array\",\"fn\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/unite/options-toggle-control.hbs",
    "isStrictMode": false
  });
});