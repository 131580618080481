define("plutof/components/transaction/view/objects/row/view-controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @edit}}
      disabled={{@disable}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.edit.tooltip"}}>
          <span class={{icon "edit"}}></span>
      </ToolTip>
  </button>
  
  <button
      {{on "click" @delete}}
      disabled={{@disable}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.delete.tooltip"}}>
          <span class={{icon "delete"}}></span>
      </ToolTip>
  </button>
  
  */
  {
    "id": "RlF6zvLq",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"transactions.objects.actions.edit.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[28,[37,3],[\"edit\"],null]],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[30,1]],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"transactions.objects.actions.delete.tooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[28,[37,3],[\"delete\"],null]],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disable\",\"@edit\",\"@delete\"],false,[\"on\",\"tool-tip\",\"i18n-t\",\"icon\"]]",
    "moduleName": "plutof/components/transaction/view/objects/row/view-controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});