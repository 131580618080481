define("plutof/templates/components/common/info-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "X4s88lMA",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\"],[[30,0,[\"infoDialog\",\"visible\"]],[30,0,[\"infoDialog\",\"close\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[30,0,[\"infoDialog\",\"title\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"infoDialog\",\"content\"]],[[[1,\"            \"],[46,[30,0,[\"infoDialog\",\"content\"]],null,[[\"locale\"],[[33,3]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,2],[12],[1,[28,[35,4],[\"General.NoInfo\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,5],[\"click\",[30,0,[\"infoDialog\",\"close\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"General.Close\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[],false,[\"ui/modal/dialog\",\"if\",\"component\",\"locale\",\"i18n-t\",\"on\"]]",
    "moduleName": "plutof/templates/components/common/info-dialog.hbs",
    "isStrictMode": false
  });
});