define("plutof/views/sample/event", ["exports", "@ember/object", "rsvp", "plutof/components/measurements/measurement-form", "plutof/misc/abstract", "plutof/utils/area-event"], function (_exports, _object, _rsvp, _measurementForm, _abstract, _areaEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SamplingEventFormData = void 0;
  _exports.create_sampling_event_form_data = create_sampling_event_form_data;
  _exports.get_sampling_event_form_data = get_sampling_event_form_data;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SamplingEventFormData extends _object.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "event", null);
    }
    async save({
      parent = null
    } = {}) {
      const event = this.event;
      const habitat = await event.get('habitat');
      await habitat.save();
      event.set('gatheringAgents', this.gatheringAgents);
      if (parent) {
        await (0, _areaEvent.updateOccurrenceEvent)(parent, event);
      } else {
        await event.save();
      }
      await _rsvp.default.all([this.eventMeasurements.save(), this.habitatMeasurements.save()]);
      return event;
    }
    willDestroy() {
      this.eventMeasurements.destroy();
      this.habitatMeasurements.destroy();
    }
  }
  _exports.SamplingEventFormData = SamplingEventFormData;
  function create_sampling_event_form_data(store, area) {
    const event = store.createRecord('sample/samplingevent', {
      habitat: store.createRecord('habitat/habitat'),
      samplingarea: area
    });
    const sefd = SamplingEventFormData.create({
      event: event,
      gatheringAgents: [],
      eventMeasurements: _measurementForm.MeasurementsData.create({
        store: store
      }),
      habitatMeasurements: _measurementForm.MeasurementsData.create({
        store: store
      })
    });
    return (0, _abstract.wrap_as_promise)(sefd);
  }
  function get_sampling_event_form_data(store, event) {
    // XXX: Maybe habitat resolution isn't needed, but it was here for some reason before. Need to remove
    // and check if anything breaks properly
    return _rsvp.default.all([event.get('gatheringAgents'), event.get('habitat')]).then(([gas]) => {
      return SamplingEventFormData.create({
        event: event,
        gatheringAgents: gas,
        eventMeasurements: _measurementForm.MeasurementsData.create({
          store: store
        }),
        habitatMeasurements: _measurementForm.MeasurementsData.create({
          store: store
        })
      });
    });
  }
});