define("plutof/components/collection-lab/permit/items/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Tables::List
      @records={{@items}}
      as |Field item|
  >
      <Field @label={{i18n-t "permit.source"}}>
          <GenericViews::PojoRecordLink
              @contentType={{item.content_type}}
              @id={{item.object_id}}
              @name={{item.content_object}} />
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.name"}}>
          <LinkTo @route="permit.view" @model={{item.permit.id}}>
              {{item.permit.name}}
          </LinkTo>
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.status"}}>
          {{item.permit.statusDisplayName}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.permit_type"}}>
          {{item.permit.permitTypeDisplayName}}
      </Field>
  </Tables::List>
  
  */
  {
    "id": "P33m9id0",
    "block": "[[[8,[39,0],null,[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"permit.source\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@contentType\",\"@id\",\"@name\"],[[30,3,[\"content_type\"]],[30,3,[\"object_id\"]],[30,3,[\"content_object\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"permit.permit.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"permit.view\",[30,3,[\"permit\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"permit\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"permit.permit.status\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"permit\",\"statusDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"permit\",\"permitTypeDisplayName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@items\",\"Field\",\"item\"],false,[\"tables/list\",\"i18n-t\",\"generic-views/pojo-record-link\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/collection-lab/permit/items/view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});