define("plutof/components/project/view/record-project-fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@project}}
      as |view|
  >
      <Layout::RegularGrid
          @columns={{2}}
          @cellGap={{true}}
      >
          {{#view.attr "name"}}
              <LinkTo @route="study.view" @model={{@project.id}} class={{if @annotated "annotated"}}>
                  {{@project.representation}}
              </LinkTo>
          {{/view.attr}}
  
          {{#view.attr "remarks"}}
              <LongText
                  @text={{@project.remarks}}
                  @text-height="height-1"
                  @tagName="span" />
          {{/view.attr}}
  
          {{view.attr "start_date"}}
          {{view.attr "end_date"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "XsQf2Whf",
    "block": "[[[8,[39,0],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"name\"],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],[[16,0,[52,[30,3],\"annotated\"]]],[[\"@route\",\"@model\"],[\"study.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,1,[\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"remarks\"],null,[[\"default\"],[[[[1,\"            \"],[8,[39,4],null,[[\"@text\",\"@text-height\",\"@tagName\"],[[30,1,[\"remarks\"]],\"height-1\",\"span\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"start_date\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"end_date\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@project\",\"view\",\"@annotated\"],false,[\"generic-views/record\",\"layout/regular-grid\",\"link-to\",\"if\",\"long-text\"]]",
    "moduleName": "plutof/components/project/view/record-project-fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});