define("plutof/services/settings", ["exports", "@ember/service", "rsvp", "plutof/misc/profile_settings", "plutof/utils/store"], function (_exports, _service, _rsvp, _profile_settings, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SettingsService = (_class = class SettingsService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "emberDataStore", _descriptor, this);
      _defineProperty(this, "_classificationsPromise", null);
    }
    // profile :: personalsettings/personalsetting

    // Not sure about this yet, but need a way for service users to wait for profile to be loaded
    wait() {
      if (this.profile) {
        return _rsvp.default.Promise.resolve(this.profile);
      }
      return (0, _profile_settings.get_personal_settings)(this.emberDataStore).then(settings => {
        if (!settings) {
          throw new Error('Not authenticated');
        }
        this.set('profile', settings);
        return settings;
      });
    }
    getUser() {
      return this.wait().then(profile => profile.user);
    }
    getUserPerson() {
      if (!this._person) {
        this._person = this.getUser().then(user => {
          return this.emberDataStore.queryRecord('agent/person', {
            user: user.id
          });
        });
      }
      return this._person;
    }
    async getDefaultClassifications(type) {
      if (!this._classificationsPromise) {
        this._classificationsPromise = this.wait().then(profile => (0, _store.query)(this.emberDataStore, 'profile/personalsettings/defaultclassification', {
          personal_settings: profile.id
        }));
      }
      const classifications = await this._classificationsPromise;
      let match = classifications.find(cl => cl.classification === type);
      if (!match) {
        const profile = await this.wait();
        match = this.emberDataStore.createRecord('profile/personalsettings/defaultclassification', {
          personal_settings: profile.id,
          classification: type,
          object_list: []
        });
        classifications.push(match);
      }
      return match;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = SettingsService;
});