define("plutof/templates/components/navbar/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "12zAKCdv",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,2,[\"header\"]],null,null,[[\"default\"],[[[[1,\"        \"],[1,[28,[30,1,[\"header\"]],null,[[\"icon\",\"title\"],[[30,3],[30,4]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[41,[30,5],[[[1,\"            \"],[8,[30,1,[\"button\"]],[[24,0,\"navbar-list__add\"]],[[\"@clicked\",\"@icon\",\"@disabled\"],[[30,0,[\"add\"]],[28,[37,2],[\"add-new\"],null],[30,6]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"General.New\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[18,11,[[30,1],[28,[37,5],null,[[\"primaryControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n\\n        \"],[1,[28,[30,1,[\"info\"]],null,[[\"title\",\"content\"],[[28,[37,7],[[30,7],[28,[37,3],[\"General.Info\"],null]],null],[28,[37,7],[[30,8],\"information/content/general/list-view\"],null]]]]],[1,\"\\n\\n        \"],[1,[30,1,[\"bookmark\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"        \"],[18,11,[[30,1],[28,[37,5],null,[[\"secondaryControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n\\n        \"],[1,[28,[30,1,[\"pagination\"]],null,[[\"pagination\"],[[30,9]]]]],[1,\"\\n\\n\"],[41,[30,10],[[[6,[30,1,[\"button\"]],null,[[\"clicked\",\"icon\"],[[30,10],[28,[37,2],[\"back\"],null]]],[[\"default\"],[[[[1,\"                \"],[1,[28,[35,3],[\"General.Back\"],null]],[1,\"\\n\"]],[]]]]]],[]],null]],[]]]]]],[1,2]]]]]],[\"navbar\",\"slots\",\"@icon\",\"@title\",\"@addRoute\",\"@disableAdd\",\"@infoTitle\",\"@infoContent\",\"@pagination\",\"@goBack\",\"&default\"],false,[\"navbar/navbar-base\",\"if\",\"icon\",\"i18n-t\",\"yield\",\"hash\",\"component\",\"or\"]]",
    "moduleName": "plutof/templates/components/navbar/list.hbs",
    "isStrictMode": false
  });
});