define("plutof/components/search/tri-state-tooltip", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="monospace">[✔]</span> {{i18n-t "Search.booleanTrue"}} <br />
  <span class="monospace">[&nbsp;]</span> {{i18n-t "Search.booleanFalse"}} <br />
  <span class="monospace">[-]</span> {{i18n-t "Search.booleanIndeterminate"}} <br />
  
  */
  {
    "id": "XbASHvLh",
    "block": "[[[10,1],[14,0,\"monospace\"],[12],[1,\"[✔]\"],[13],[1,\" \"],[1,[28,[35,0],[\"Search.booleanTrue\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,1],[14,0,\"monospace\"],[12],[1,\"[ ]\"],[13],[1,\" \"],[1,[28,[35,0],[\"Search.booleanFalse\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,1],[14,0,\"monospace\"],[12],[1,\"[-]\"],[13],[1,\" \"],[1,[28,[35,0],[\"Search.booleanIndeterminate\"],null]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[],false,[\"i18n-t\"]]",
    "moduleName": "plutof/components/search/tri-state-tooltip.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});