define("plutof/mixins/profile-predefined-columns", ["exports", "@ember/object", "@ember/object/mixin", "@ember/runloop", "@ember/service", "@ember/utils"], function (_exports, _object, _mixin, _runloop, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: Refactor
  const ProfilePrefedinedColumns = _mixin.default.create({
    settings: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.headersLoaded();
    },
    headersLoaded: (0, _object.observer)('headers', 'settings.profile', function () {
      const headers = this.headers;
      const settings = this.get('settings.profile');
      if ((0, _utils.isEmpty)(headers) || (0, _utils.isNone)(settings)) {
        return;
      }

      // Create corresponding property if it doesn't exist (e.g search_specimen = [])
      this.updateSettingsDestination();
      const destination = this.getModuleEndpoint();
      if ((0, _utils.isEmpty)(destination)) {
        this.shoveDefaultVisibleHeaders(headers, destination);
      } else if (this.profileEndPoint) {
        // Dealing with clipboard and moderation, search is done in controllers/search/index.js
        this.hideOrShowProfileHeaders(headers, destination);
      }
    }),
    getCurrentModule: function () {
      return this.profileEndPoint || 'search_' + this.get('selectedModule.id');
    },
    getModuleEndpoint: function () {
      return this.get('settings.profile.default_fields')[this.getCurrentModule()];
    },
    // default_fields.specimen_clipboard is undefined. Create it! Then hustle with fields.
    updateSettingsDestination: function () {
      const destination = this.getCurrentModule();
      const settings = this.get('settings.profile');
      const settingsDestination = settings.get('default_fields')[destination];
      if ((0, _utils.isNone)(settingsDestination)) {
        settings.get('default_fields')[destination] = [];
      }
    },
    hideOrShowProfileHeaders: function (headers, destination) {
      headers.forEach(header => {
        header.set('isVisible', destination.includes(header.get('name')));
      });
    },
    shoveDefaultVisibleHeaders: function (headers, destination) {
      const visibleHeaders = headers.filterBy('isVisible').mapBy('name');
      destination.pushObjects(visibleHeaders);
    },
    saveProfile: function () {
      const settings = this.get('settings.profile');
      if ((0, _utils.isPresent)(settings)) {
        settings.save();
      }
    },
    // Single column to profile and save
    updateProfileToggle: function (header) {
      const state = header.get('isVisible');
      const destination = this.getModuleEndpoint();
      const name = header.get('name');
      if (state) {
        destination.push(name);
      } else {
        destination.removeObject(name);
      }
      (0, _runloop.debounce)(this, 'saveProfile', 3500);
    },
    // Toggle all to profile and save
    updateProfileToggleAll: function () {
      const destination = this.getCurrentModule();
      const settings = this.get('settings.profile');
      const headers = this.headers;
      let newHeaders;
      if (headers.isEvery('isVisible', true)) {
        newHeaders = headers.mapBy('name'); // Get all headers because toggle all "on" was called
      } else {
        // There are some default fields that stay visible eventho toggle all "off" was called
        newHeaders = headers.filterBy('isVisible').mapBy('name');
      }
      settings.get('default_fields')[destination] = newHeaders;
      (0, _runloop.debounce)(this, 'saveProfile', 3500);
    }
  });
  var _default = _exports.default = ProfilePrefedinedColumns;
});