define("plutof/components/common/-button-group-button", ["exports", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ButtonGroupButton = (_dec = (0, _object.computed)('args.name', 'args.active', 'args.isActive'), _class = class ButtonGroupButton extends _component.default {
    get isActive() {
      // If the button is given isActive argument, use it.
      if ((0, _utils.isPresent)(this.args.isActive)) {
        return this.args.isActive;
      }

      // Otherwise use context name matching logic.
      return (0, _utils.isPresent)(this.args.name) && this.args.name === this.args.active;
    }
    click(context) {
      if (!context.args.activate) {
        return;
      }
      context.args.activate(context.args.name);
      if (context.args.clicked) {
        context.args.clicked();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _class);
  var _default = _exports.default = ButtonGroupButton;
});