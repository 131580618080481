define("plutof/templates/components/navbar/-navbar-base/button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NFbhfMG4",
    "block": "[[[11,\"button\"],[24,0,\"btn btn-link navbar-base__button\"],[16,\"disabled\",[30,0,[\"buttonDisabled\"]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"clicked\"]]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,1],[15,0,[29,[\"\\n            \",[52,[30,0,[\"buttonResolving\"]],\"promise-button-icon resolving\",[30,2]],\"\\n            navbar-base__button-icon\\n        \"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@icon\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/button.hbs",
    "isStrictMode": false
  });
});