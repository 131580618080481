define("plutof/components/sample/-map/parent-area-popup", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="map-area-tooltip">
      <tbody>
          <tr>
              <td>{{i18n-t "Sample.parentSamplingArea"}}:</td>
  
              <td>
                  <LinkTo @route="area.view" @model={{@area.id}}>
                      {{@area.name}}
                  </LinkTo>
              </td>
          </tr>
      </tbody>
  </table>
  
  */
  {
    "id": "Fh2wvBQP",
    "block": "[[[10,\"table\"],[14,0,\"map-area-tooltip\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[28,[35,0],[\"Sample.parentSamplingArea\"],null]],[1,\":\"],[13],[1,\"\\n\\n            \"],[10,\"td\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"area.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,1,[\"name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@area\"],false,[\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/components/sample/-map/parent-area-popup.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});