define("plutof/controllers/recover-password", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-cp-validations", "plutof/utils/formatters", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _service, _emberCpValidations, _formatters, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  var i18n = (0, _i18n.getI18n)();
  const Validations = (0, _emberCpValidations.buildValidations)({
    identification: [(0, _emberCpValidations.validator)('format', {
      regex: _formatters.EMAIL_REGEX
    })]
  });
  let PasswordRecoveryController = (_dec = (0, _service.inject)(), _class = class PasswordRecoveryController extends _controller.default.extend(Validations) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "identification", '');
      _defineProperty(this, "i18nCredentials", i18n.t('Registration.credentials'));
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    reset() {
      var self = this;
      self.get('ajax').put('/users/users/password-reset/', {
        data: {
          user: this.identification
        }
      }).then(function () {
        self.set('identification', '');
        (0, _notifications.displayNotification)('success', i18n.t('Registration.resetSuccessMessage'));
      }, _notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _class);
  var _default = _exports.default = PasswordRecoveryController;
});