define("plutof/templates/components/study/area-popup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0kSKqA7Z",
    "block": "[[[10,\"table\"],[14,0,\"map-area-tooltip\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"name\",\"label\"]]],[1,\":\"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"area.view\",[28,[37,2],[[30,1],[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[33,3,[\"name\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"latitude\",\"label\"]]],[1,\": \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,0,[\"coords\",\"lat\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"longitude\",\"label\"]]],[1,\": \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,0,[\"coords\",\"lon\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"district\",\"label\"]]],[1,\": \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"district\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"commune\",\"label\"]]],[1,\": \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"commune\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[33,0,[\"locality_text\",\"label\"]]],[1,\": \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,2,[\"locality_text\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@viewRouteArgument\",\"@area\"],false,[\"areaTexts\",\"link-to\",\"or\",\"area\"]]",
    "moduleName": "plutof/templates/components/study/area-popup.hbs",
    "isStrictMode": false
  });
});