define("plutof/controllers/unite/referencesequences", ["exports", "@ember/controller", "plutof/misc/content_types"], function (_exports, _controller, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReferenceSequencesController extends _controller.default {
    init() {
      super.init(...arguments);
      (0, _content_types.get_ctype_by_name)(this.store, 'sequence/sequence').then(ctype => {
        this.set('sequenceCType', ctype);
      });
    }
  }
  var _default = _exports.default = ReferenceSequencesController;
});