define("plutof/components/collection-lab/permit/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <GenericViews::Record @record={{@permit}} as |view|>
          <Layout::RegularGrid @columns={{3}} as |grid|>
              {{view.field "name"}}
              {{view.field "status"}}
              {{view.field "permit_type"}}
  
              {{#grid.span 3}}
                  {{view.field "status_qualifier"}}
              {{/grid.span}}
  
              {{#grid.span 3}}
                  {{view.field "permit_text"}}
              {{/grid.span}}
  
              {{#grid.span 3}}
                  {{#view.attr "items" label=(i18n-t "permit.usedIn")}}
                      <Common::RecordListView @records={{@items}} as |item|>
                          <GenericViews::PojoRecordLink
                              @contentType={{item.content_type}}
                              @id={{item.object_id}}
                              @name={{item.content_object}} />
                      </Common::RecordListView>
                  {{/view.attr}}
              {{/grid.span}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "2HQ1wwwh",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"name\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"status\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"permit_type\"],null]],[1,\"\\n\\n\"],[6,[30,3,[\"span\"]],[3],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,2,[\"field\"]],[\"status_qualifier\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,3,[\"span\"]],[3],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,2,[\"field\"]],[\"permit_text\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,3,[\"span\"]],[3],null,[[\"default\"],[[[[6,[30,2,[\"attr\"]],[\"items\"],[[\"label\"],[[28,[37,1],[\"permit.usedIn\"],null]]],[[\"default\"],[[[[1,\"                    \"],[8,[39,4],null,[[\"@records\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n                        \"],[8,[39,5],null,[[\"@contentType\",\"@id\",\"@name\"],[[30,5,[\"content_type\"]],[30,5,[\"object_id\"]],[30,5,[\"content_object\"]]]],null],[1,\"\\n                    \"]],[5]]]]],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"        \"]],[3]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@permit\",\"view\",\"grid\",\"@items\",\"item\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"common/record-list-view\",\"generic-views/pojo-record-link\"]]",
    "moduleName": "plutof/components/collection-lab/permit/view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});