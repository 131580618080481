define("plutof/templates/specimen/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fl8K7rCm",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"specimen.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\"],[[28,[37,2],[\"Specimen.newS\"],null],[28,[37,3],[\"specimen\"],null],\"information/content/specimen/add\",[28,[37,4],[\"triggerReset\"],null]]],null],[1,\"\\n\\n\"],[6,[39,5],[[30,0,[\"model\",\"specimenData\",\"specimen\",\"mainform\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,6],null,[[\"@type\",\"@mainform\",\"@project\",\"@update\",\"@quickChoices\",\"@validationContext\"],[\"specimen\",[30,1],[30,0,[\"model\",\"specimenData\",\"sampleData\",\"project\"]],[30,2],[30,0,[\"plutofMainforms\"]],[30,0,[\"validationContext\",\"form\"]]]],null],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"],[8,[39,7],null,[[\"@data\",\"@closePanels\",\"@canModifyDeterminations\",\"@showAreaEditBtn\",\"@canResetArea\",\"@preserveZoom\",\"@canModifyOwner\",\"@validationContext\"],[[30,0,[\"model\",\"specimenData\"]],[30,0,[\"closePanels\"]],true,false,true,[30,0,[\"preserveZoom\"]],false,[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,8],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,9],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[8,[39,10],null,[[\"@save\",\"@cancel\",\"@clone\",\"@ctrl\"],[[30,0,[\"save\"]],[28,[37,11],[[30,0],\"cancel\"],null],[28,[37,11],[[30,0],\"clone\"],null],[30,0]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"mainform\",\"updateMainform\"],false,[\"test/route-marker\",\"navbar/edit\",\"i18n-t\",\"icon\",\"route-action\",\"async/bind-relation\",\"measurements/form-selector\",\"specimen/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"save-panel\",\"action\"]]",
    "moduleName": "plutof/templates/specimen/add.hbs",
    "isStrictMode": false
  });
});