define("plutof/templates/components/tab-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "N4xBpgMt",
    "block": "[[[10,0],[14,0,\"tab-group__tablist-container\"],[12],[1,\"\\n    \"],[10,\"ul\"],[15,1,[30,0,[\"headersContainerId\"]]],[14,0,\"tab-group__tablist\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,2,[[28,[37,1],null,[[\"header\",\"tab\",\"frame\",\"panel\",\"group\"],[[50,\"tab-group/header\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab-frame\",0,null,[[\"group\"],[[30,0]]]],[50,\"tab-group/tab-panel\",0,null,null],[30,0]]]]]],[1,\"\\n\"]],[\"&tablist\",\"&tabs\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/tab-group.hbs",
    "isStrictMode": false
  });
});