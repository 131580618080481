define("plutof/components/clipboard/-export/list-of-species", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking", "plutof/utils/reflection", "plutof/misc/options-getter", "plutof/components/clipboard/-export/utils"], function (_exports, _component, _templateFactory, _object, _component2, _tracking, _reflection, _optionsGetter, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ListOfSpeciesExportModel = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model.options}}
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <Common::CheckBox @value={{@model.separateSynonyms}}>
                  {{plutof-labelc path=@model.options.separate_synonyms source="options"}}
              </Common::CheckBox>
          </div>
  
          <div>
              {{plutof-labelc path=@model.options.vernacular_names_language source="options"}}
  
              {{#async/bind-relation @model.language as |value update|}}
                  <AutoComplete::Model
                      @model="geography/language"
                      @value={{value}}
                      @update={{update}} />
              {{/async/bind-relation}}
          </div>
      </Layout::RegularGrid>
  {{/if}}
  
  */
  {
    "id": "7a7V2C1O",
    "block": "[[[41,[30,1,[\"options\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@value\"],[[30,1,[\"separateSynonyms\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],null,[[\"path\",\"source\"],[[30,1,[\"options\",\"separate_synonyms\"]],\"options\"]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"path\",\"source\"],[[30,1,[\"options\",\"vernacular_names_language\"]],\"options\"]]]],[1,\"\\n\\n\"],[6,[39,4],[[30,1,[\"language\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,[[\"@model\",\"@value\",\"@update\"],[\"geography/language\",[30,2],[30,3]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@model\",\"value\",\"update\"],false,[\"if\",\"layout/regular-grid\",\"common/check-box\",\"plutof-labelc\",\"async/bind-relation\",\"auto-complete/model\"]]",
    "moduleName": "plutof/components/clipboard/-export/list-of-species.hbs",
    "isStrictMode": false
  });
  let ListOfSpeciesExportModel = _exports.ListOfSpeciesExportModel = (_class = class ListOfSpeciesExportModel extends _utils.ExportFormatModel {
    constructor({
      endpoint,
      options
    }) {
      super({
        endpoint
      });
      _initializerDefineProperty(this, "language", _descriptor, this);
      _initializerDefineProperty(this, "separateSynonyms", _descriptor2, this);
      this.options = options;
    }
    static async create(store, endpoint) {
      const ajax = (0, _reflection.getService)(store, 'ajax');
      const options = await (0, _optionsGetter.getPromise)(ajax, endpoint);
      return new ListOfSpeciesExportModel({
        endpoint,
        options: options.actions.POST
      });
    }
    prepareSaveData(objectList) {
      const languageId = (0, _object.get)(this, 'language.id') ?? null;
      return {
        object_list: objectList,
        vernacular_names_language: languageId,
        separate_synonyms: this.separateSynonyms
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "language", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "separateSynonyms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
  class ListOfSpeciesExport extends _component2.default {}
  _exports.default = ListOfSpeciesExport;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ListOfSpeciesExport);
});