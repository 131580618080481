define("plutof/components/clipboard/label-printing", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "ember-concurrency", "rsvp", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/fileupload", "plutof/services/ajax", "plutof/utils/datetime", "plutof/utils/model", "plutof/utils/promises", "plutof/utils/errors", "plutof/utils/notifications", "plutof/utils/i18n"], function (_exports, _component, _object, _service, _utils, _emberConcurrency, _rsvp, _environment, _abstract, _fileupload, _ajax, _datetime, _model, _promises, _errors, _notifications, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const DEFAULT_ENDPOINT = 'export/specimens/labels/';
  const FORMAT_ENDPOINTS = {
    'index-seminum': 'export/specimens/index-seminum-labels/'
  };
  let LabelPrintingForm = (_dec = (0, _model.choices)('export.specimens.labels.format'), _dec2 = (0, _model.choices)('export.specimens.labels.coordinate_format'), _dec3 = (0, _object.computed)('labelFormat', 'entries.[]'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = (0, _object.computed)('entries.[]'), _class = class LabelPrintingForm extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "labelFormat", null);
      _initializerDefineProperty(this, "labelFormats", _descriptor2, this);
      _defineProperty(this, "coordinateFormat", 'decimal');
      _initializerDefineProperty(this, "coordinateFormats", _descriptor3, this);
      _defineProperty(this, "labelsIncludeProjectInfo", false);
      _defineProperty(this, "labelsIncludeAreaName", false);
      _defineProperty(this, "labelsUsePredefinedArea", false);
      _defineProperty(this, "labelsIncludeAllGAs", true);
      _defineProperty(this, "labelsUsePhenophase", false);
      _defineProperty(this, "labelsIncludeAllDeterminers", true);
      // TODO: It's somewhat weird that it has to be a string. Yes, radio button values are strings,
      // but I'd not expect that to propagate through the Common::RadioGroup interface
      _defineProperty(this, "labelsIncludeRemarks", '0');
      _defineProperty(this, "labelsUseAdditionalTaxonName", false);
      _defineProperty(this, "labelsUseValidTaxonName", false);
      _defineProperty(this, "remarksChoices", [{
        label: i18n.t('Clipboard.labelsRemarksNull'),
        value: null
      }, {
        label: i18n.t('Clipboard.labelsRemarksGeneral'),
        value: 1
      }, {
        label: i18n.t('Clipboard.labelsRemarksIdentification'),
        value: 2
      }, {
        label: i18n.t('Clipboard.labelsRemarksBoth'),
        value: 3
      }]);
      _initializerDefineProperty(this, "printLabelsServerside", _descriptor4, this);
    }
    get printingDisabled() {
      return (0, _utils.isNone)(this.labelFormat) || this.get('entries.length') === 0;
    }
    get willBeAsynchronous() {
      return this.entries.length > 250;
    }
    printLabels() {
      return _rsvp.default.Promise.race([this.printLabelsServerside.perform(), (0, _promises.wait)(59500).then(() => {
        throw (0, _errors.userError)(this.i18n.t('Clipboard.labelPrinting.timedOut'));
      })]).catch(err => {
        (0, _notifications.reportError)(err);
        throw err;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "labelFormats", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "coordinateFormats", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "printingDisabled", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "printingDisabled"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "printLabelsServerside", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const language = this.get('i18n.locale');
        const remarks = this.remarksChoices[this.labelsIncludeRemarks].value;
        const params = {
          identifier: 'labels',
          object_list: this.entries.mapBy('id'),
          format: this.labelFormat,
          coordinate_format: this.coordinateFormat,
          timezone: (0, _datetime.getLocalTimezone)(),
          include_project: this.labelsIncludeProjectInfo,
          show_all_collectors: this.labelsIncludeAllGAs,
          show_all_determiners: this.labelsIncludeAllDeterminers,
          include_remarks: remarks,
          use_additional_taxon_name: this.labelsUseAdditionalTaxonName,
          use_valid_taxon_name: this.labelsUseValidTaxonName,
          include_area_name: this.labelsIncludeAreaName,
          use_predefined_area_name: this.labelsUsePredefinedArea,
          include_phenophase: this.labelsUsePhenophase,
          // Language-dependent options
          apply_locality_substitutions: language === 'eng',
          use_original_organization_name: language === 'est'
        };
        let endpoint = FORMAT_ENDPOINTS[this.labelFormat] || DEFAULT_ENDPOINT;
        yield exportData(this.router, params, endpoint).then(response => {
          if ((0, _utils.isNone)(response.id)) {
            return _abstract.EMPTY_PROMISE;
          }
          return this.store.findRecord('filerepository/file', response.id).then(file => {
            return (0, _fileupload.downloadFile)(file.download_links.link);
          });
        });
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "willBeAsynchronous", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "willBeAsynchronous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printLabels", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "printLabels"), _class.prototype), _class); // Previously part of the clipboard export code
  function exportData(router, data, endpoint) {
    const ajax = (0, _ajax.ajaxFromControlled)(router);
    return ajax.request(`${_environment.default.API_HOST}/${endpoint}`, {
      method: 'POST',
      processData: false,
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify(data)
    }).then(response => {
      // TODO: Move the aftermath out of there.
      let message;
      if (response.id) {
        const fileUrl = router.urlFor('filerepository.view', response.id);
        message = `${i18n.t('Clipboard.exportedToFile')} <a href="${fileUrl}">${response.name}</a>`;
      } else {
        message = i18n.t('Clipboard.exportFinishesLater');
      }
      (0, _notifications.displayHtmlNotification)('success', message, null, 12000);
      return response;
    });
  }
  var _default = _exports.default = LabelPrintingForm;
});