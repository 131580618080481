define("plutof/templates/components/analysis/matching-source-clipboard-items", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qZV/7moz",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[1,\"\\n        \"],[8,[39,0],null,null,[[\"left\",\"right\"],[[[[1,\"\\n                \"],[10,\"label\"],[14,0,\"analysis-matching-source__clipboard-items-header-label\"],[12],[1,\"\\n                    \"],[1,[28,[35,1],[\"Analysis.clipboardItems\"],[[\"count\"],[[30,1,[\"objectCount\"]]]]]],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[]],[[[1,\"\\n                \"],[8,[39,2],null,[[\"@pagination\",\"@removeSpacing\"],[[30,1],true]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"objects\"]]],null]],null],null,[[[1,\"    \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"analysis-matching-source__clipboard-cell mini-input item-table__read-only-value\"],[12],[1,\"\\n            \"],[1,[30,2,[\"accno\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null]],[\"@pagination\",\"sequence\"],false,[\"layout/split-row\",\"i18n-t\",\"pagination/page-controls\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/templates/components/analysis/matching-source-clipboard-items.hbs",
    "isStrictMode": false
  });
});