define("plutof/components/measurements/add-extra/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::AddExtra::Wrap
      @measurements={{@measurements}}
      @module={{@module}}
      as |extraMeasurements|
  >
      <div class="label-control">
          <button {{on "click" extraMeasurements.request}} class="label-control__btn">
              <span class="icon-add-new"></span>
  
              {{i18n-t "General.addExternalMeasurements"}}
          </button>
      </div>
  </Measurements::AddExtra::Wrap>
  
  */
  {
    "id": "8vt/Qn5M",
    "block": "[[[8,[39,0],null,[[\"@measurements\",\"@module\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"label-control\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"label-control__btn\"],[4,[38,1],[\"click\",[30,3,[\"request\"]]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"icon-add-new\"],[12],[13],[1,\"\\n\\n            \"],[1,[28,[35,2],[\"General.addExternalMeasurements\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@measurements\",\"@module\",\"extraMeasurements\"],false,[\"measurements/add-extra/wrap\",\"on\",\"i18n-t\"]]",
    "moduleName": "plutof/components/measurements/add-extra/link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});