define("plutof/templates/profile/notifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lcLtqoyy",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.notifications\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-label spacer-below\"],[12],[1,[28,[35,1],[\"Settings.notifications.label\"],null]],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,[28,[35,1],[\"Settings.notifications.toolT\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"label\"],[14,0,\"plutof-label plutof-label--light plutof-label--wrap\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"checked\"],[\"checkbox\",[33,3,[\"profile\",\"general_emails\"]]]]]],[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.notifications.systemNotifications\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,3,[\"notificationCheckboxes\"]]],null]],null],null,[[[1,\"    \"],[10,\"label\"],[14,0,\"plutof-label plutof-label--light plutof-label--wrap\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"checked\"],[\"checkbox\",[30,1,[\"isActive\"]]]]]],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[8,[39,6],[[24,0,\"btn btn-default spacer-large-above\"]],[[\"@clicked\",\"@restIcon\"],[[30,0,[\"updateNotifications\"]],[28,[37,7],[\"ok\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"General.Save\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"nOption\"],false,[\"test/route-marker\",\"i18n-t\",\"input\",\"model\",\"each\",\"-track-array\",\"promise-button\",\"icon\"]]",
    "moduleName": "plutof/templates/profile/notifications.hbs",
    "isStrictMode": false
  });
});