define("plutof/components/measurements/measurement-view/single", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="plutof-tooltip-hover">
      <div class="plutof-label">
          {{@measurement.fullName}}
  
          {{#if @expand}}
              <button
                  {{on "click" @expand}}
                  class="btn-fake-link btn-fake-link--no-underline"
              >
                  ...
              </button>
          {{/if}}
      </div>
  
      <div>
          <span class="plutof-tooltip">
              {{break-line @measurement.fullDescription}}
  
              {{#each @measurement.infoLinks as |info|}}
                  {{info-link infoLink=info}}
              {{/each}}
          </span>
      </div>
  </div>
  
  <p
      data-measurement="{{@measurement.measurementData.name}}"
      class="view-measurements__measurement {{if @measurement.augmented 'annotated'}}"
  >
      {{@measurement.representation}}&nbsp;
  </p>
  
  */
  {
    "id": "nmeiCfC+",
    "block": "[[[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[30,1,[\"fullName\"]]],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"            \"],[11,\"button\"],[24,0,\"btn-fake-link btn-fake-link--no-underline\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n                ...\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[[30,1,[\"fullDescription\"]]],null]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"infoLinks\"]]],null]],null],null,[[[1,\"                \"],[1,[28,[35,5],null,[[\"infoLink\"],[[30,3]]]]],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,2],[15,\"data-measurement\",[29,[[30,1,[\"measurementData\",\"name\"]]]]],[15,0,[29,[\"view-measurements__measurement \",[52,[30,1,[\"augmented\"]],\"annotated\"]]]],[12],[1,\"\\n    \"],[1,[30,1,[\"representation\"]]],[1,\" \\n\"],[13],[1,\"\\n\"]],[\"@measurement\",\"@expand\",\"info\"],false,[\"if\",\"on\",\"break-line\",\"each\",\"-track-array\",\"info-link\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/single.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});