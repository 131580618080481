define("plutof/templates/components/observation/taxon-sheet/form-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "730VnZ4N",
    "block": "[[[6,[39,0],null,[[\"show\",\"data\",\"sheetset\",\"class\"],[[33,1],[30,1],[30,2],\"form-taxon-sheet-dialog\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"sheet\",\"selection\",\"entries\",\"toggle\"],[[30,3,[\"sheet\"]],[30,3,[\"selection\"]],[30,3,[\"entries\"]],[30,3,[\"toggle\"]]]]]],[1,\"\\n\"]],[3]]]]]],[\"@data\",\"@sheetset\",\"sheet\"],false,[\"observation/taxon-sheet/dialog\",\"show\",\"observation/taxon-sheet/form\"]]",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/form-dialog.hbs",
    "isStrictMode": false
  });
});