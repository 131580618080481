define("plutof/components/dmp/view/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "dmp.panels.general"}}>
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <GuidedView @path="dmp.fields.title">
              {{@dmp.title}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.description">
              {{@dmp.description}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.ids">
              <Common::RecordListView @records={{@dmp.ids}} as |record|>
                  {{record.identifier}} ({{record.type}})
              </Common::RecordListView>
          </GuidedView>
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
          <GuidedView @path="dmp.fields.ethical_issues_exist">
              {{read-only-boolean @dmp.ethical_issues_exist}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.ethical_issues_description">
              {{@dmp.ethical_issues_description}}
          </GuidedView>
  
          <GuidedView @path="dmp.fields.ethical_issues_report">
              {{@dmp.ethical_issues_report}}
          </GuidedView>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "JMOs8lrA",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"dmp.panels.general\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.title\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"title\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.description\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ids\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@records\"],[[30,1,[\"ids\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,2,[\"identifier\"]]],[1,\" (\"],[1,[30,2,[\"type\"]]],[1,\")\\n            \"]],[2]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ethical_issues_exist\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,5],[[30,1,[\"ethical_issues_exist\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ethical_issues_description\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"ethical_issues_description\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@path\"],[\"dmp.fields.ethical_issues_report\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"ethical_issues_report\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dmp\",\"record\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"guided-view\",\"common/record-list-view\",\"read-only-boolean\"]]",
    "moduleName": "plutof/components/dmp/view/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});