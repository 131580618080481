define("plutof/templates/components/compound-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "9WgTsF/L",
    "block": "[[[41,[30,0,[\"alignmentSequences\",\"length\"]],[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[11,0],[24,0,\"unite-table__alignment-container\"],[4,[38,2],[[30,0,[\"alignmentSequences\"]],[30,0,[\"buildAlignmentConfig\"]]],null],[12],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"inline-flex\"],[12],[1,\"\\n\"],[41,[33,3],[[[41,[33,4],[[[1,\"            \"],[10,1],[14,0,\"spinicon fas fa-sync-alt\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,2],[24,0,\"btn-link clickable\"],[4,[38,5],[[30,0],\"loadMore\"],null],[12],[1,\"\\n                \"],[1,[28,[35,6],[\"General.loadMore\"],null]],[1,\"\\n            \"],[13],[1,\"\\n              \\n            \"],[11,2],[24,0,\"btn-link clickable\"],[4,[38,5],[[30,0],\"loadAll\"],null],[12],[1,\"\\n                \"],[1,[28,[35,6],[\"General.loadAll\"],null]],[1,\"  (\"],[1,[33,7,[\"objects_count\"]]],[1,\")\\n            \"],[13],[1,\"\\n\"]],[]]]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"plutof-panel/headerless\",\"unite/alignment-renderer\",\"haveLoadMore\",\"loading\",\"action\",\"i18n-t\",\"totalCount\"]]",
    "moduleName": "plutof/templates/components/compound-table.hbs",
    "isStrictMode": false
  });
});