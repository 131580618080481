define("plutof/templates/components/unite/determinations/view-multiple", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gQUeK1Sg",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"UniteSH.determinations\"],null],[30,0,[\"pagination\",\"objectCount\"]]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"pagination\",\"objects\"]]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,[[\"@determination\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[39,5],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"determination\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"each\",\"-track-array\",\"unite/determinations/view-single\",\"pagination/load-more\"]]",
    "moduleName": "plutof/templates/components/unite/determinations/view-multiple.hbs",
    "isStrictMode": false
  });
});