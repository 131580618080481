define("plutof/components/search/filters/number-range-input", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Search::Filters::-RangeInput
      @filter={{@filter}}
      @inputGroupClass="range-filter-group"
  >
      <:lower>
          <Input
              @type="number"
              @value={{@filter.lowerBound}}
              class="form-control" />
      </:lower>
  
      <:upper as |disabled|>
          <Input
              @type="number"
              @value={{@filter.upperBound}}
              @disabled={{disabled}}
              class="form-control" />
      </:upper>
  </Search::Filters::-RangeInput>
  
  */
  {
    "id": "JK+AWFo5",
    "block": "[[[8,[39,0],null,[[\"@filter\",\"@inputGroupClass\"],[[30,1],\"range-filter-group\"]],[[\"lower\",\"upper\"],[[[[1,\"\\n        \"],[8,[39,1],[[24,0,\"form-control\"]],[[\"@type\",\"@value\"],[\"number\",[30,1,[\"lowerBound\"]]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,1],[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@disabled\"],[\"number\",[30,1,[\"upperBound\"]],[30,2]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[\"@filter\",\"disabled\"],false,[\"search/filters/-range-input\",\"input\"]]",
    "moduleName": "plutof/components/search/filters/number-range-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});