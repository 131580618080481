define("plutof/components/measurements/add-extra/panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::AddExtra::Wrap
      @measurements={{@measurements}}
      @module={{@module}}
      as |extraMeasurements|
  >
      <PlutofPanel::ItemList @collapse={{default-to @collapse (not @measurements.objectMeasurements.length)}} as |panel|>
          <panel.header @title={{or @title (i18n-t "General.measurements")}}>
              <panel.button @clicked={{extraMeasurements.request}}>
                  {{i18n-t "General.Add"}}
              </panel.button>
          </panel.header>
  
          <panel.content data-marker="measurements">
              {{yield}}
          </panel.content>
      </PlutofPanel::ItemList>
  </Measurements::AddExtra::Wrap>
  
  */
  {
    "id": "fCgDfqvl",
    "block": "[[[8,[39,0],null,[[\"@measurements\",\"@module\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@collapse\"],[[28,[37,2],[[30,4],[28,[37,3],[[30,1,[\"objectMeasurements\",\"length\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,5,[\"header\"]],null,[[\"@title\"],[[28,[37,4],[[30,6],[28,[37,5],[\"General.measurements\"],null]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,5,[\"button\"]],null,[[\"@clicked\"],[[30,3,[\"request\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,5],[\"General.Add\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,5,[\"content\"]],[[24,\"data-marker\",\"measurements\"]],null,[[\"default\"],[[[[1,\"\\n            \"],[18,7,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@measurements\",\"@module\",\"extraMeasurements\",\"@collapse\",\"panel\",\"@title\",\"&default\"],false,[\"measurements/add-extra/wrap\",\"plutof-panel/item-list\",\"default-to\",\"not\",\"or\",\"i18n-t\",\"yield\"]]",
    "moduleName": "plutof/components/measurements/add-extra/panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});