define("plutof/helpers/annotation/fields-modified", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function ([record, ...fields]) {
    if (!record || !record.fieldAnnotated) {
      return false;
    }
    return fields.some(f => (0, _object.get)(record.fieldAnnotated, f));
  });
});