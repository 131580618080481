define("plutof/templates/study/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SDIyIqDF",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"study.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoContent\"],[[28,[37,2],[\"project\"],null],[28,[37,3],[\"General.Projects\"],null],\"study.add\",[30,0,[\"model\",\"pagination\"]],\"information/content/project/index\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"study.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,3,[\"access\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"project\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/study/index.hbs",
    "isStrictMode": false
  });
});