define("plutof/components/specimen/view/exsiccata", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise @specimen.exsiccata as |exsiccata|}}
      <PlutofPanel::Simple
          @title={{i18n-t "General.exsiccata"}}
          @collapse={{not exsiccata}}
      >
          {{#let (annotation/fields-modified @specimen 'exsiccata') as |annotated|}}
              <GenericViews::Record @record={{exsiccata}} as |view|>
                  <Layout::RegularGrid @columns={{4}} @cellGap={{true}}>
                      {{view.attr "title" annotated=annotated}}
                      {{view.attr "abbreviation" annotated=annotated}}
                      {{view.attr "groups" annotated=annotated}}
  
                      {{#view.attr
                          label=(component "plutof-labelc" path="specimen.exsiccata_no")
                          annotated=(annotation/fields-modified @specimen 'exsiccata_no')
                      }}
                          {{@specimen.exsiccata_no}}
                      {{/view.attr}}
                  </Layout::RegularGrid>
              </GenericViews::Record>
          {{/let}}
      </PlutofPanel::Simple>
  {{/resolve-promise}}
  
  */
  {
    "id": "usH6REpj",
    "block": "[[[6,[39,0],[[30,1,[\"exsiccata\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@collapse\"],[[28,[37,2],[\"General.exsiccata\"],null],[28,[37,3],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,5],[[30,1],\"exsiccata\"],null]],[[[1,\"            \"],[8,[39,6],null,[[\"@record\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,7],null,[[\"@columns\",\"@cellGap\"],[4,true]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[28,[30,4,[\"attr\"]],[\"title\"],[[\"annotated\"],[[30,3]]]]],[1,\"\\n                    \"],[1,[28,[30,4,[\"attr\"]],[\"abbreviation\"],[[\"annotated\"],[[30,3]]]]],[1,\"\\n                    \"],[1,[28,[30,4,[\"attr\"]],[\"groups\"],[[\"annotated\"],[[30,3]]]]],[1,\"\\n\\n\"],[6,[30,4,[\"attr\"]],null,[[\"label\",\"annotated\"],[[50,\"plutof-labelc\",0,null,[[\"path\"],[\"specimen.exsiccata_no\"]]],[28,[37,5],[[30,1],\"exsiccata_no\"],null]]],[[\"default\"],[[[[1,\"                        \"],[1,[30,1,[\"exsiccata_no\"]]],[1,\"\\n\"]],[]]]]],[1,\"                \"]],[]]]]],[1,\"\\n            \"]],[4]]]]],[1,\"\\n\"]],[3]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"@specimen\",\"exsiccata\",\"annotated\",\"view\"],false,[\"resolve-promise\",\"plutof-panel/simple\",\"i18n-t\",\"not\",\"let\",\"annotation/fields-modified\",\"generic-views/record\",\"layout/regular-grid\",\"component\"]]",
    "moduleName": "plutof/components/specimen/view/exsiccata.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});