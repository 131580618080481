define("plutof/components/search/filter-panels/-time-groups", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::RadioGroup
      @active={{this.currentGroup}}
      @activate={{this.switchGroup}}
      as |radioGroup|
  >
      {{#each @groups as |group|}}
          <radioGroup.choice @name={{group}}>
              {{group.label}}
          </radioGroup.choice>
      {{/each}}
  </Common::RadioGroup>
  
  {{! TODO: Specify groups component-style, not by passing a list inside }}
  <Layout::RegularGrid @columns={{2}}>
      {{#each @groups as |group|}}
          {{#if (is-equal this.currentGroup group)}}
              {{#each group.filters as |filter|}}
                  {{#if (get @filters filter)}}
                      <Search::Filters::TimeRangeInput @filter={{get @filters filter}} />
                  {{/if}}
              {{/each}}
          {{/if}}
      {{/each}}
  </Layout::RegularGrid>
  
  */
  {
    "id": "Me5eLgLf",
    "block": "[[[8,[39,0],null,[[\"@active\",\"@activate\"],[[30,0,[\"currentGroup\"]],[30,0,[\"switchGroup\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[30,1,[\"choice\"]],null,[[\"@name\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]],null]],[1]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[41,[28,[37,5],[[30,0,[\"currentGroup\"]],[30,4]],null],[[[42,[28,[37,2],[[28,[37,2],[[30,4,[\"filters\"]]],null]],null],null,[[[41,[28,[37,6],[[30,6],[30,5]],null],[[[1,\"                    \"],[8,[39,7],null,[[\"@filter\"],[[28,[37,6],[[30,6],[30,5]],null]]],null],[1,\"\\n\"]],[]],null]],[5]],null]],[]],null]],[4]],null]],[]]]]],[1,\"\\n\"]],[\"radioGroup\",\"@groups\",\"group\",\"group\",\"filter\",\"@filters\"],false,[\"common/radio-group\",\"each\",\"-track-array\",\"layout/regular-grid\",\"if\",\"is-equal\",\"get\",\"search/filters/time-range-input\"]]",
    "moduleName": "plutof/components/search/filter-panels/-time-groups.hbs",
    "isStrictMode": false
  });
  let SearchFilterPanelTimeGroups = (_class = class SearchFilterPanelTimeGroups extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentGroup", _descriptor, this);
    }
    switchGroup(group) {
      this.currentGroup = group;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentGroup", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "switchGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchGroup"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchFilterPanelTimeGroups);
});