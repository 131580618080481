define("plutof/components/subtree-selector", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component", "@ember-decorators/object", "plutof/utils/structures"], function (_exports, _component, _object, _utils, _component2, _object2, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TreeLeaf = _exports.TreeInnerNode = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _class2, _dec5, _class3, _class4;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  class TreeNode extends _object.default {}
  let TreeLeaf = _exports.TreeLeaf = (_dec = (0, _object.computed)(), _class = class TreeLeaf extends TreeNode {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isLeaf", true);
    }
    get leaves() {
      return [this];
    }
  }, _applyDecoratedDescriptor(_class.prototype, "leaves", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "leaves"), _class.prototype), _class);
  let TreeInnerNode = _exports.TreeInnerNode = (_dec2 = (0, _object2.observes)('required'), _dec3 = (0, _object2.observes)('selected'), _dec4 = (0, _object.computed)('children.@each.leaves'), _class2 = class TreeInnerNode extends TreeNode {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isLeaf", false);
    }
    init() {
      super.init();
      if ((0, _utils.isNone)(this.children)) {
        this.set('children', []);
      }
      this.selectRequired();
    }
    selectRequired() {
      if (this.required) {
        this.set('selected', true);
      }
    }
    propagateSelection() {
      const selected = this.selected;
      this.children.rejectBy('required').forEach(node => node.set('selected', selected));
    }
    addChild(child) {
      this.children.pushObject(child);
    }
    get leaves() {
      return (0, _structures.concat)(this.children.mapBy('leaves'));
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "selectRequired", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selectRequired"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "propagateSelection", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "propagateSelection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "leaves", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "leaves"), _class2.prototype), _class2);
  let SubtreeSelector = (_dec5 = (0, _component2.classNames)('field-selector'), _dec5(_class3 = (_class4 = class SubtreeSelector extends _component.default {
    toggleCollapsed() {
      this.tree.toggleProperty('collapsed');
    }
  }, _applyDecoratedDescriptor(_class4.prototype, "toggleCollapsed", [_object.action], Object.getOwnPropertyDescriptor(_class4.prototype, "toggleCollapsed"), _class4.prototype), _class4)) || _class3);
  var _default = _exports.default = SubtreeSelector;
});