define("plutof/components/observation/moderation-comments", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-data", "@ember-decorators/component", "moment", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/measurements", "plutof/utils/i18n"], function (_exports, _component, _object, _utils, _emberData, _component2, moment, _abstract, _config, _measurements, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QUICK_REPLY_OPTIONS = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _class2, _class3;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  const QUICK_REPLY_OPTIONS = _exports.QUICK_REPLY_OPTIONS = [i18n.t('moderation.quickReplyOptions.needProof'), i18n.t('moderation.quickReplyOptions.needExpertise'), i18n.t('moderation.quickReplyOptions.taxonTime'), i18n.t('moderation.quickReplyOptions.taxonLocation')];
  let CommentEntry = (_dec = (0, _object.computed)('comment.changes'), _class = class CommentEntry extends _object.default {
    get changes() {
      var changes = this.get('comment.changes');
      if ((0, _utils.isNone)(changes)) {
        return [];
      }
      return Object.keys(changes).filter(function (property) {
        const isImportant = _config.default.Observation.SHOWN_CHANGES.includes(property);
        const hasHistory = (0, _utils.isPresent)(changes[property].old_value);
        return isImportant && hasHistory;
      }).map(property => {
        return {
          history: i18n.t('moderation.wasChanged', {
            hash: {
              property: property
            }
          }),
          oldValue: changes[property].old_value,
          newValue: changes[property].new_value
        };
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "changes", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "changes"), _class.prototype), _class);
  let ModerationComments = (_dec2 = (0, _component2.classNames)('moderation-comments'), _dec3 = (0, _object.computed)('observation'), _dec4 = (0, _object.computed)('comments.@each.created_at', 'comments.@each.changes'), _dec2(_class2 = (_class3 = class ModerationComments extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "quickReplyOptions", QUICK_REPLY_OPTIONS);
    }
    get measurementsCommentary() {
      const observation = this.observation;
      const store = this.store;
      let promise;
      if ((0, _utils.isPresent)((0, _object.get)(observation, 'measurements'))) {
        promise = (0, _abstract.wrap_as_promise)([]);
      } else if ((0, _utils.isPresent)(observation)) {
        promise = store.findRecord('taxonoccurrence/observation/observation', (0, _object.get)(observation, 'id')).then(observation => {
          return observation.get('mainform').then(mainform => {
            return (0, _measurements.get_object_form_measurements)(store, observation, mainform);
          });
        });
      } else {
        promise = (0, _abstract.wrap_as_promise)([]);
      }
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }
    get entries() {
      const comments = this.comments;
      return comments.slice().sort((com1, com2) => {
        const com1_timestamp = com1.get('created_at');
        const com2_timestamp = com2.get('created_at');
        if ((0, _utils.isNone)(com1_timestamp)) {
          return -1;
        } else if ((0, _utils.isNone)(com2_timestamp)) {
          return 1;
        }
        return moment(com2_timestamp).diff(moment(com1_timestamp));
      }).map(comment => {
        return CommentEntry.create({
          comment: comment
        });
      });
    }
    sendMessage(type) {
      if (this[type]) {
        this[type](this.observation.id, this.message);
      }
      this.set('message', '');
    }
  }, _applyDecoratedDescriptor(_class3.prototype, "measurementsCommentary", [_dec3], Object.getOwnPropertyDescriptor(_class3.prototype, "measurementsCommentary"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "entries", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "entries"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "sendMessage", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "sendMessage"), _class3.prototype), _class3)) || _class2);
  var _default = _exports.default = ModerationComments;
});