define("plutof/templates/components/pagination/load-more", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vVqkb/zB",
    "block": "[[[41,[30,1,[\"isLoading\"]],[[[41,[33,1],[[[1,\"        \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,0,[\"showControls\"]],[[[41,[51,[30,1,[\"onLastPage\"]]],[[[1,\"            \"],[11,\"button\"],[16,\"disabled\",[30,1,[\"isLoading\"]]],[24,0,\"btn btn-xs btn-link\"],[4,[38,3],[\"click\",[30,0,[\"more\"]]],null],[12],[1,\"\\n                \"],[1,[28,[35,4],[\"General.loadMore\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"                \"],[11,\"button\"],[16,\"disabled\",[30,1,[\"isLoading\"]]],[24,0,\"btn btn-xs btn-link\"],[4,[38,3],[\"click\",[30,0,[\"all\"]]],null],[12],[1,\"\\n                    \"],[1,[28,[35,4],[\"General.loadAll\"],null]],[1,\"\\n\\n\"],[41,[30,1,[\"objectCount\"]],[[[1,\"                        (\"],[1,[30,1,[\"objectCount\"]]],[1,\")\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[]]]],[\"@pagination\",\"@showLoadAll\"],false,[\"if\",\"showInterface\",\"unless\",\"on\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/pagination/load-more.hbs",
    "isStrictMode": false
  });
});