define("plutof/components/forms/palette", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Inner data-test="Forms::Palette" ...attributes>
      <div class="plutof-label">
          {{@title}}
      </div>
  
      <div class="spacer"></div>
  
      <div class="mainform-measurements">
          {{#each @palette key="@index" as |paletteItem|}}
              <div class="plutof-tooltip-hover">
                  <button
                      {{on "click" (fn @use paletteItem)}}
                      class="btn-reset {{@labelClass}} form-items"
                      data-test-trait="{{paletteItem.name}}"
                  >
                      {{paletteItem.name}} [{{paletteItem.type}}]
                  </button>
  
                  {{#if paletteItem.description}}
                      <span class="plutof-tooltip">
                          {{paletteItem.description}}
  
                          {{#if paletteItem.isSet}}
                              <ul class="mainform-measurement-set">
                                  {{#each paletteItem.elements as |setElement|}}
                                      <li>{{setElement.element}}</li>
                                  {{/each}}
                              </ul>
                          {{/if}}
                      </span>
                  {{/if}}
              </div>
          {{/each}}
      </div>
  </PlutofPanel::Inner>
  
  */
  {
    "id": "QuvAqRF6",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Forms::Palette\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"spacer\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"mainform-measurements\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],\"@index\",[[[1,\"            \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n                \"],[11,\"button\"],[16,0,[29,[\"btn-reset \",[30,5],\" form-items\"]]],[16,\"data-test-trait\",[29,[[30,4,[\"name\"]]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,6],[30,4]],null]],null],[12],[1,\"\\n                    \"],[1,[30,4,[\"name\"]]],[1,\" [\"],[1,[30,4,[\"type\"]]],[1,\"]\\n                \"],[13],[1,\"\\n\\n\"],[41,[30,4,[\"description\"]],[[[1,\"                    \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n                        \"],[1,[30,4,[\"description\"]]],[1,\"\\n\\n\"],[41,[30,4,[\"isSet\"]],[[[1,\"                            \"],[10,\"ul\"],[14,0,\"mainform-measurement-set\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4,[\"elements\"]]],null]],null],null,[[[1,\"                                    \"],[10,\"li\"],[12],[1,[30,7,[\"element\"]]],[13],[1,\"\\n\"]],[7]],null],[1,\"                            \"],[13],[1,\"\\n\"]],[]],null],[1,\"                    \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@palette\",\"paletteItem\",\"@labelClass\",\"@use\",\"setElement\"],false,[\"plutof-panel/inner\",\"each\",\"-track-array\",\"on\",\"fn\",\"if\"]]",
    "moduleName": "plutof/components/forms/palette.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});