define("plutof/components/layout/module-hub", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="hub-content">
      <Common::Legend>
          <span class={{@icon}}></span>
          {{@title}}
      </Common::Legend>
  
      {{yield (hash
          link=(component "layout/-module-hub/link")
          button=(component "layout/-module-hub/link-button")
      )}}
  </div>
  
  */
  {
    "id": "BXLSqCXo",
    "block": "[[[10,0],[14,0,\"hub-content\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[30,1]],[12],[13],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[18,3,[[28,[37,2],null,[[\"link\",\"button\"],[[50,\"layout/-module-hub/link\",0,null,null],[50,\"layout/-module-hub/link-button\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@title\",\"&default\"],false,[\"common/legend\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/layout/module-hub.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});