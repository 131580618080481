define("plutof/utils/chart", ["exports", "@ember/object", "@ember/utils", "moment", "plutof/config/environment", "plutof/misc/options-getter", "plutof/utils/i18n"], function (_exports, _object, _utils, moment, _environment, _optionsGetter, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PHENOLOGICAL_ENDPOINT = _exports.INTERVAL_FORMATS = _exports.HISTOGRAM_ENDPOINT = _exports.GEO_DISTRIBUTION_ENDPOINT = _exports.CUMULATIVE_ENDPOINT = void 0;
  _exports.getChartChoices = getChartChoices;
  _exports.getChartColor = getChartColor;
  _exports.getChartData = getChartData;
  _exports.getLabels = getLabels;
  const HISTOGRAM_ENDPOINT = _exports.HISTOGRAM_ENDPOINT = '/search/histogram/';
  const PHENOLOGICAL_ENDPOINT = _exports.PHENOLOGICAL_ENDPOINT = '/search/phenological/';
  const CUMULATIVE_ENDPOINT = _exports.CUMULATIVE_ENDPOINT = '/search/year-statistics/';
  const GEO_DISTRIBUTION_ENDPOINT = _exports.GEO_DISTRIBUTION_ENDPOINT = '/search/distribution/';
  const possibleChartColors = [{
    color: '#a1d99b',
    border: '#74c76b'
  }, {
    color: '#9e9ac8',
    border: '#807bb7'
  }, {
    color: '#808080',
    border: '#ff99e6'
  }, {
    color: '#FFA500',
    border: '#5eba61'
  }, {
    color: '#bdbdbd',
    border: '#a6a6a6'
  }, {
    color: '#ff704d',
    border: '#ff471a'
  }];
  const INTERVAL_FORMATS = _exports.INTERVAL_FORMATS = {
    minute: 'YYYY-MM-DD HH:mm',
    hour: 'YYYY-MM-DD HH:mm',
    day: 'YYYY-MM-DD',
    week: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    quarter: 'YYYY-MM',
    year: 'YYYY'
  };
  const i18n = (0, _i18n.getI18n)();
  function getChartData(ajax, endpoint, fields, query, additionalFilters) {
    const completeQuery = [`${endpoint}?field=${fields.join(',')}`, query, additionalFilters].reject(_utils.isEmpty).join('&');
    return ajax.request(`${_environment.default.API_HOST}/${completeQuery}`).then(response => {
      return response.results;
    });
  }
  function getChartChoices(ajax, endpoint, path) {
    return (0, _optionsGetter.getPromise)(ajax, endpoint).then(response => {
      const fields = (0, _object.get)(response, path);
      return Object.keys(fields).map(key => {
        const config = fields[key];
        (0, _object.set)(config, 'key', key);
        return config;
      });
    });
  }
  function getRandomColor() {
    return '#' + Math.floor(Math.random() * (256 * 256 * 256)).toString(16);
  }
  function getChartColor(property, index) {
    // 1) Check for predefined properties (e.g observation);
    // 2) See if we have possibleChartColor at given index;
    // 3) Index too high, generate random color

    const wrapper = getPredefinedColor(property) || possibleChartColors[index] || {
      color: getRandomColor()
    };
    return wrapper.color;
  }
  function getPredefinedColor(property) {
    const colors = possibleChartColors;
    const wrapper = [{
      key: 'observations',
      color: colors[0].color
    }, {
      key: 'materialsamples',
      color: colors[1].color
    }, {
      key: 'specimens',
      color: colors[2].color
    }, {
      key: 'sequences',
      color: colors[3].color
    }, {
      key: 'occurrences',
      color: colors[4].color
    }, {
      key: 'strains',
      color: colors[5].color
    }];
    return wrapper.findBy('key', property);
  }
  function getDateLabels(data, interval) {
    return data.map(datum => {
      if (datum.key === 'Ambiguous' || datum.key === 'Täpsustamata') {
        return datum.key;
      }
      if (datum.key === null) {
        return i18n.t('chart.unspecified');
      }
      return moment(datum.key).format(INTERVAL_FORMATS[interval]);
    });
  }
  function getRegularLabels(data) {
    return data.map(datum => {
      return datum.key || i18n.t('chart.unspecified');
    });
  }
  function getLabels(data, interval) {
    return interval ? getDateLabels(data, interval) : getRegularLabels(data);
  }
});