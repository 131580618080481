define("plutof/components/taxonomy-lab/conservation-instructions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "TaxonDescription.conservationInstructions"}}
      @collapse={{not @taxonDescription.conservation_instructions}}
      class="conservation-instructions"
      data-test="TaxonomyLab::ConservationInstructions"
  >
      <FlexibleTextarea
          @value={{@taxonDescription.conservation_instructions}}
          class="form-control conservation-instructions__content"
          data-test="content" />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "FXlU59VV",
    "block": "[[[8,[39,0],[[24,0,\"conservation-instructions\"],[24,\"data-test\",\"TaxonomyLab::ConservationInstructions\"]],[[\"@title\",\"@collapse\"],[[28,[37,1],[\"TaxonDescription.conservationInstructions\"],null],[28,[37,2],[[30,1,[\"conservation_instructions\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"form-control conservation-instructions__content\"],[24,\"data-test\",\"content\"]],[[\"@value\"],[[30,1,[\"conservation_instructions\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@taxonDescription\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"not\",\"flexible-textarea\"]]",
    "moduleName": "plutof/components/taxonomy-lab/conservation-instructions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});