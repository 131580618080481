define("plutof/components/radio-button", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component"], function (_exports, _component, _object, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let RadioButton = (_dec = (0, _component2.tagName)('input'), _dec2 = (0, _component2.attributeBindings)('name', 'type', 'value', 'checked:checked', 'disabled:disabled'), _dec3 = (0, _object.computed)('selection', 'option'), _dec4 = (0, _object.computed)('option', 'valueProperty'), _dec(_class = _dec2(_class = (_class2 = class RadioButton extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "type", 'radio');
      _defineProperty(this, "option", null);
      _defineProperty(this, "valueProperty", null);
      _defineProperty(this, "oneWay", false);
      _defineProperty(this, "selection", null);
    }
    click() {
      if (!this.oneWay) {
        this.set('selection', this.option);
      }
      if (!(0, _utils.isNone)(this.changed)) {
        this.changed(this.option);
      }
    }
    get checked() {
      const selection = this.selection;
      if ((0, _utils.isNone)(selection)) {
        return false;
      }
      return selection === this.option;
    }
    get value() {
      var option = this.option;
      var prop = this.valueProperty;
      if ((0, _utils.isNone)(prop)) {
        return option;
      } else {
        return (0, _object.get)(option, prop);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "checked", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "checked"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "value", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = RadioButton;
});