define("plutof/utils/i18n", ["exports", "@ember/object", "@ember/utils", "plutof/translations/eng", "plutof/translations/est", "plutof/misc/config", "plutof/utils/cookies"], function (_exports, _object, _utils, _eng, _est, _config, _cookies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LANG_CODES = void 0;
  _exports.getI18n = getI18n;
  _exports.translate = translate;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // ISO 639-2 language codes.
  const LANG_CODES = _exports.LANG_CODES = ['eng', 'est'];
  function isAlias(code) {
    return (0, _utils.typeOf)(code) === 'string' && code.indexOf(_config.default.I18n.ALIAS_PREFIX) === 0;
  }
  function getAliasedPointer(code) {
    return code.substring(_config.default.I18n.ALIAS_PREFIX.length);
  }
  class I18n extends _object.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "locale", (0, _cookies.getCookie)('language') || 'eng');
    }
    resolve(translations, key) {
      key = translations.get(key);
      while (!(0, _utils.isEmpty)(key) && isAlias(key)) {
        key = translations.get(getAliasedPointer(key));
      }
      return key;
    }
    t(key, options) {
      options = options || {};
      var locale = options.locale || this.locale;
      var keyWithPrefix = locale + '.' + key;
      var translations = locale === 'est' ? _est.default.get('est') : _eng.default.get('eng');
      var string = this.resolve(translations, key);
      if ((0, _utils.isNone)(string)) {
        /* eslint-disable no-console */
        console.warn('No translation ' + keyWithPrefix);
        /* eslint-enable no-console */

        return 'Missing translation ' + keyWithPrefix;
      }
      return this._interpolate(string, options.hash);
    }
    _interpolate(key, hash) {
      if (typeof hash === 'undefined') {
        return key;
      }
      hash = hash || {};
      return key.replace(/\{\{(.*?)\}\}/g, function (i, match) {
        return (0, _utils.isNone)(hash[match]) ? '' : hash[match];
      });
    }
  }
  var singleton;
  function getI18n() {
    if ((0, _utils.isNone)(singleton)) {
      singleton = I18n.create();
    }
    return singleton;
  }
  function translate(code) {
    return getI18n().t(code);
  }
  var _default = _exports.default = I18n;
});