define("plutof/components/auto-complete/scientific-name", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @taxon.kingdom}}
      (<span class="taxon-ac-scientific-name">{{@taxon.taxon_rank}}</span>
  
      {{i18n-t "Autocomplete.rankInKingdom"}}
  
      <span class="taxon-ac-scientific-name">{{@taxon.kingdom}}</span>)
  {{else}}
      (<span class="taxon-ac-scientific-name">{{@taxon.taxon_rank}}</span>)
  {{/if}}
  
  */
  {
    "id": "PfrNQ3aI",
    "block": "[[[41,[30,1,[\"kingdom\"]],[[[1,\"    (\"],[10,1],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[30,1,[\"taxon_rank\"]]],[13],[1,\"\\n\\n    \"],[1,[28,[35,1],[\"Autocomplete.rankInKingdom\"],null]],[1,\"\\n\\n    \"],[10,1],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[30,1,[\"kingdom\"]]],[13],[1,\")\\n\"]],[]],[[[1,\"    (\"],[10,1],[14,0,\"taxon-ac-scientific-name\"],[12],[1,[30,1,[\"taxon_rank\"]]],[13],[1,\")\\n\"]],[]]]],[\"@taxon\"],false,[\"if\",\"i18n-t\"]]",
    "moduleName": "plutof/components/auto-complete/scientific-name.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});