define("plutof/templates/components/unite/singleton-options", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "w/fYuuX6",
    "block": "[[[1,[28,[35,0],[\"UniteSH.includeSingletons\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@content\",\"@value\",\"@optionValuePath\",\"@optionLabelPath\"],[[30,0,[\"options\"]],[30,1],\"value\",\"label\"]],null],[1,\"\\n\"]],[\"@value\"],false,[\"common/local-label\",\"plutof-select/value\"]]",
    "moduleName": "plutof/templates/components/unite/singleton-options.hbs",
    "isStrictMode": false
  });
});