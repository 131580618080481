define("plutof/services/i18n", ["exports", "@ember/service", "plutof/utils/i18n"], function (_exports, _service, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const i18n = (0, _i18n.getI18n)();

  // TODO: Remove autoinjections?
  class I18n extends _service.default {
    translate(key, options) {
      return i18n.t(key, options);
    }
  }
  var _default = _exports.default = I18n;
});