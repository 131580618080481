define("plutof/templates/components/parent-object-path", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "L4YIrUm0",
    "block": "[[[41,[33,1],[[[1,\"    \"],[10,1],[14,0,\"icon-specimen\"],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"specimen.view\",[99,3,[\"@model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[33,4,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[10,1],[14,0,\"icon-livingculture\"],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"livingspecimen.view\",[99,3,[\"@model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[33,4,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,6],[[[1,\"    \"],[10,1],[14,0,\"icon-materialsample\"],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"materialsample.view\",[99,3,[\"@model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[33,4,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,7],[[[1,\"    \"],[10,1],[14,0,\"icon-project\"],[12],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"study.view\",[99,3,[\"@model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[33,4,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"isSpecimen\",\"link-to\",\"object_id\",\"parentObject\",\"isStrain\",\"isMaterialSample\",\"isStudy\"]]",
    "moduleName": "plutof/templates/components/parent-object-path.hbs",
    "isStrictMode": false
  });
});