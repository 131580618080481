define("plutof/templates/components/navbar/navbar-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "+DivcGId",
    "block": "[[[11,\"nav\"],[16,0,[29,[\"navbar-base \",[30,0,[\"kindClass\"]]]]],[17,1],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"header\",\"button\",\"info\",\"bookmark\",\"upload\",\"goBack\",\"pagination\"],[[50,\"navbar/-navbar-base/title\",0,null,null],[50,\"navbar/-navbar-base/button\",0,null,null],[50,\"navbar/-navbar-base/info\",0,null,null],[50,\"navbar/-navbar-base/bookmark\",0,null,null],[50,\"navbar/-navbar-base/upload\",0,null,null],[50,\"navbar/-navbar-base/go-back\",0,null,null],[50,\"pagination/page-controls\",0,null,[[\"split\",\"removeSpacing\",\"alwaysShowInterface\",\"class\"],[true,true,true,\"navbar-base__pagination\"]]]]]]],[[[1,\"        \"],[10,0],[14,0,\"navbar-base__content\"],[12],[1,\"\\n            \"],[18,3,[[30,2],[28,[37,1],null,[[\"header\"],[[50,\"named-slot\",0,null,null]]]]]],[1,\"\\n\\n            \"],[10,0],[14,0,\"navbar-base__block navbar-base__block--primary\"],[12],[1,\"\\n                \"],[18,3,[[30,2],[28,[37,1],null,[[\"primaryControls\"],[[50,\"named-slot\",0,null,null]]]]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"navbar-base__block navbar-base__block--secondary\"],[12],[1,\"\\n                \"],[18,3,[[30,2],[28,[37,1],null,[[\"secondaryControls\"],[[50,\"named-slot\",0,null,null]]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]]],[13],[1,\"\\n\"]],[\"&attrs\",\"parts\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"yield\"]]",
    "moduleName": "plutof/templates/components/navbar/navbar-base.hbs",
    "isStrictMode": false
  });
});