define("plutof/templates/components/pagination/page-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1EYIsQvG",
    "block": "[[[41,[30,0,[\"showInterface\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@pageNumber\",\"@pageCount\",\"@pageLoading\",\"@isFirstPage\",\"@isLastPage\",\"@isMaxPage\",\"@firstPage\",\"@previousPage\",\"@nextPage\",\"@lastPage\",\"@refresh\",\"@canRefresh\",\"@disabled\",\"@split\"],[[30,1,[\"pageNumber\"]],[30,0,[\"browsablePagesCount\"]],[30,1,[\"isLoading\"]],[30,1,[\"onFirstPage\"]],[30,1,[\"onLastPage\"]],[28,[37,2],[[30,1,[\"pageNumber\"]],[30,2]],null],[30,0,[\"firstPage\"]],[30,0,[\"previousPage\"]],[30,0,[\"nextPage\"]],[30,0,[\"lastPage\"]],[30,0,[\"_refresh\"]],[30,0,[\"canRefresh\"]],[30,3],[30,4]]],null],[1,\"\\n\\n\"],[41,[51,[30,5]],[[[1,\"        \"],[10,1],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@pagination\",\"@maxPages\",\"@disabled\",\"@split\",\"@removeSpacing\"],false,[\"if\",\"common/page-navigation\",\"is-equal\",\"unless\"]]",
    "moduleName": "plutof/templates/components/pagination/page-controls.hbs",
    "isStrictMode": false
  });
});