define("plutof/templates/components/publishing/gbif/access-rights/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5QaWQvwV",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[10,0],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"viewUsers\"]],[[[1,\"                \"],[8,[30,1,[\"view\",\"selected\"]],null,[[\"@usernames\"],[[30,0,[\"viewUsers\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[30,1,[\"view\",\"private\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"editUsers\"]],[[[1,\"                \"],[8,[30,1,[\"edit\",\"selected\"]],null,[[\"@usernames\"],[[30,0,[\"editUsers\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[30,1,[\"edit\",\"private\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"\\n            \"],[8,[30,1,[\"owner\"]],null,[[\"@owner\"],[[30,0,[\"owner\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[30,0,[\"owner\"]],[30,2,[\"created\"]],[30,0,[\"owner\"]],[30,2,[\"modified\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"vr\",\"@dataset\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-rights-base\",\"if\"]]",
    "moduleName": "plutof/templates/components/publishing/gbif/access-rights/view.hbs",
    "isStrictMode": false
  });
});