define("plutof/components/search/filter-panels/all-occurrences-taxon-checkboxes", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::FilterInput @filter={{@filterInputs.interacting_lineage}} />
      <Search::FilterInput @filter={{@filterInputs.is_synonym}} />
      <Search::FilterInput @filter={{@filterInputs.phenological}} />
      <Search::FilterInput @filter={{@filterInputs.is_restricted}} />
      <Search::FilterInput @filter={{@filterInputs.sequence_parent_taxon}} />
      <Search::FilterInput @filter={{@filterInputs.has_conflict}} />
      <Search::FilterInput @filter={{@filterInputs.has_cluster}} />
  </Layout::RegularGrid>
  
  */
  {
    "id": "eFpIqWhy",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"interacting_lineage\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"is_synonym\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"phenological\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"is_restricted\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"sequence_parent_taxon\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"has_conflict\"]]]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@filter\"],[[30,1,[\"has_cluster\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"layout/regular-grid\",\"search/filter-input\"]]",
    "moduleName": "plutof/components/search/filter-panels/all-occurrences-taxon-checkboxes.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});