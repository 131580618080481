define("plutof/components/annotation/-fields/icon", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field>
      <Annotation::List::Icon @annotation={{@annotation}} />
  </@field>
  
  */
  {
    "id": "6E72/Atp",
    "block": "[[[8,[30,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,0],null,[[\"@annotation\"],[[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@annotation\"],false,[\"annotation/list/icon\"]]",
    "moduleName": "plutof/components/annotation/-fields/icon.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});