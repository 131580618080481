define("plutof/templates/components/analysis/matching-source-fasta-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bb2WJcJP",
    "block": "[[[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"analysis-matching-source__fasta-cell mini-input item-table__read-only-value\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"Analysis.fastaSequences\"],[[\"filename\"],[[30,1,[\"name\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@file\"],false,[\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/analysis/matching-source-fasta-item.hbs",
    "isStrictMode": false
  });
});