define("plutof/templates/analysis/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AcRVZKWT",
    "block": "[[[10,0],[14,0,\"route-marker-analysis-add\"],[14,\"data-test\",\"route-analysis.add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[28,[37,1],[\"analysis\"],null],[28,[37,2],[\"Analysis.newAnalysis\"],null],[30,0,[\"run\"]],[28,[37,3],[\"triggerReset\"],null],\"information/content/analysis/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,4],null,[[\"run\",\"type\",\"name\",\"sourceData\",\"validationContext\",\"setType\",\"save\",\"saveAndRun\",\"cancel\"],[[33,5],[33,6],[33,7],[33,8],[33,9],[28,[37,10],[[30,0],\"updateType\"],null],[28,[37,10],[[30,0],\"save\"],null],[28,[37,10],[[30,0],\"saveAndRun\"],null],[28,[37,10],[[30,0],\"cancel\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"analysis/edit-form\",\"run\",\"type\",\"name\",\"sourceData\",\"validationContext\",\"action\"]]",
    "moduleName": "plutof/templates/analysis/add.hbs",
    "isStrictMode": false
  });
});