define("plutof/templates/experiment/dna/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FS2KxEy4",
    "block": "[[[10,0],[14,0,\"route-marker-dna-view\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@editRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.dna.routeTitles.view\"],null],[30,0,[\"model\",\"dna\"]],[30,0,[\"model\",\"permissions\"]],\"experiment.dna.edit\",true,[28,[37,2],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@dna\"],[[30,0,[\"model\",\"dna\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"source\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@parentId\",\"@parentCType\",\"@disableEdit\"],[[30,0,[\"model\",\"source\",\"id\"]],[30,0,[\"model\",\"sourceCType\"]],true]],null],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@parent\",\"@canModify\",\"@showAreaType\"],[[30,0,[\"model\",\"source\"]],false,false]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,7],null,[[\"@dna\"],[[30,0,[\"model\",\"dna\"]]]],null],[1,\"\\n\"],[8,[39,8],null,[[\"@dna\"],[[30,0,[\"model\",\"dna\"]]]],null],[1,\"\\n\\n\"],[8,[39,9],null,[[\"@object\",\"@occurrenceType\"],[[30,0,[\"model\",\"dna\"]],\"dna\"]],null],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@record\"],[[30,0,[\"model\",\"dna\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"experiment/dna/view/general-data\",\"if\",\"view-determinations\",\"sample/area-event-view-panel\",\"experiment/dna/view/pcr-products\",\"experiment/dna/view/sequencing-results\",\"collection-lab/view/condition-assessments\",\"experiment/dna/view/record-metadata\"]]",
    "moduleName": "plutof/templates/experiment/dna/view.hbs",
    "isStrictMode": false
  });
});