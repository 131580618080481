define("plutof/components/identifiers/-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
      <h5>{{@title}}</h5>
  
      <Tables::List
          @records={{@objects}}
          as |Field block|
      >
          <Field @label={{i18n-t "identifiers.list.range"}}>
              {{block.value.representation}}
          </Field>
  
          <Field @label={{i18n-t "identifiers.list.reservedFor"}}>
              {{block.value.reserved_for.representation}}
          </Field>
  
          <Field @label={{i18n-t "identifiers.list.used"}}>
              {{block.value.used}} / {{block.value.totalSize}}
          </Field>
  
          <Field>
              <Tables::RemoveButton @clicked={{fn @remove block}} />
          </Field>
      </Tables::List>
  </div>
  
  */
  {
    "id": "w/OBjJRu",
    "block": "[[[10,0],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,[30,1]],[13],[1,\"\\n\\n    \"],[8,[39,0],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"identifiers.list.range\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"value\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"identifiers.list.reservedFor\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"value\",\"reserved_for\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,[[\"@label\"],[[28,[37,1],[\"identifiers.list.used\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"value\",\"used\"]]],[1,\" / \"],[1,[30,4,[\"value\",\"totalSize\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,3],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,2],null,[[\"@clicked\"],[[28,[37,3],[[30,5],[30,4]],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[3,4]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@objects\",\"Field\",\"block\",\"@remove\"],false,[\"tables/list\",\"i18n-t\",\"tables/remove-button\",\"fn\"]]",
    "moduleName": "plutof/components/identifiers/-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});