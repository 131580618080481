define("plutof/components/filerepository/-local-file-preview/button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/filetypes"], function (_exports, _component, _templateFactory, _component2, _filetypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isImage}}
      <button
          {{on "click" (fn @preview @file)}}
          class="btn-fake-link"
      >
          <span class={{icon "preview"}}></span>
      </button>
  {{/if}}
  
  */
  {
    "id": "c0/H/3NT",
    "block": "[[[41,[30,0,[\"isImage\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1],[30,2]],null]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,3],[\"preview\"],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@preview\",\"@file\"],false,[\"if\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/filerepository/-local-file-preview/button.hbs",
    "isStrictMode": false
  });
  class LocalFilePreviewButton extends _component2.default {
    get isImage() {
      return (0, _filetypes.is_file_image)(this.args.file);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LocalFilePreviewButton);
});