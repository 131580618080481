define("plutof/templates/components/agent/organization-view/experiments", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y4WM3Zji",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@add\",\"@canModify\",\"@title\",\"@searchModule\",\"@searchQuery\"],[[30,0,[\"pagination\"]],[30,0,[\"newExperiment\"]],true,[30,1],[30,2],[30,0,[\"searchQuery\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"pagination\"],[[30,0,[\"pagination\"]]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@title\",\"@searchModule\",\"&default\"],false,[\"related-objects/view\",\"yield\",\"hash\"]]",
    "moduleName": "plutof/templates/components/agent/organization-view/experiments.hbs",
    "isStrictMode": false
  });
});