define("plutof/components/access/-view-rights/history", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      {{common/local-label "Access.created"}}
  
      {{! XXX: <p> inside <p>? }}
      <p>
          {{formatted-date @createdAt}}
  
          <p>
              ({{@createdBy}})
          </p>
      </p>
  {{/access/-view-rights/column}}
  
  {{#access/-view-rights/column}}
      {{common/local-label "Access.modified"}}
  
      <p>
          {{formatted-date @updatedAt}}
  
          <p>
              ({{@updatedBy}})
          </p>
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "XlvalwK5",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],[\"Access.created\"],null]],[1,\"\\n\\n\"],[1,\"    \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n\\n        \"],[10,2],[12],[1,\"\\n            (\"],[1,[30,2]],[1,\")\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],[\"Access.modified\"],null]],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,3]],null]],[1,\"\\n\\n        \"],[10,2],[12],[1,\"\\n            (\"],[1,[30,4]],[1,\")\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@createdAt\",\"@createdBy\",\"@updatedAt\",\"@updatedBy\"],false,[\"access/-view-rights/column\",\"common/local-label\",\"formatted-date\"]]",
    "moduleName": "plutof/components/access/-view-rights/history.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});