define("plutof/mixins/subrepository-mixin", ["exports", "@ember/object", "@ember/object/mixin", "@ember/runloop", "@ember/service", "@ember/utils", "plutof/misc/abstract"], function (_exports, _object, _mixin, _runloop, _service, _utils, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // XXX: Remove
  var _default = _exports.default = _mixin.default.create({
    ajax: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.updateIDPrimPrefix();
      this.additionalCodeChanged();
      this.subRepositoryIDChanged();
      this.objectPrimaryIdChanged();
      this.objectSubIdChanged();
      this.depositedInChanged();
    },
    updateIDPrimPrefix: (0, _object.observer)('subRepositoryObject.deposited_in', function () {
      // Weird ass code.
      var subRepoObject = this.subRepositoryObject;
      if ((0, _utils.isNone)(subRepoObject)) {
        return;
      }
      subRepoObject.get('deposited_in').then(organization => {
        if ((0, _utils.isNone)(organization)) {
          return;
        }
        if (subRepoObject.get('deposited_in.id') !== organization.get('id')) {
          return;
        }
        var prefix = organization.get('specimen_identifier_format_prefix') || '';
        var primaryID = this.primaryIdString;
        if ((0, _utils.isEmpty)(subRepoObject.get(primaryID))) {
          subRepoObject.set(primaryID, prefix);
          this.set('previousPrefix', prefix);
        } else {
          var idprim = subRepoObject.get(primaryID);
          var previousPrefix = this.previousPrefix;
          if (!(0, _utils.isEmpty)(previousPrefix) && idprim.indexOf(previousPrefix) === 0) {
            idprim = idprim.substring(previousPrefix.length);
          }
          if ((0, _utils.isEmpty)(prefix) || idprim.indexOf(prefix) !== 0) {
            subRepoObject.set(primaryID, prefix + idprim);
          }
          this.set('previousPrefix', prefix);
        }
      });
    }),
    checkForObjectsUniqueness: function () {
      if (this.isDestroyed) {
        return;
      }
      const objectId = this.primaryId;
      const objectPk = this.get('subRepositoryObject.id');
      const subRepoID = this.subRepositoryId;
      const objectSubID = this.subId;
      if ((0, _utils.isEmpty)(objectId)) {
        this.set('itemIsUnique', true);
        return;
      }

      // Duplication is allowed.
      if ((0, _utils.isNone)(subRepoID)) {
        this.set('itemIsUnique', true);
        return;
      }
      const filterHash = {
        deposited_in: subRepoID,
        object_idprim: objectId
      };
      if (objectSubID) {
        filterHash.specimen_idprim_sub = objectSubID;
      }
      const url = this.uniqueURL;
      const constructedUrl = (0, _abstract.construct_request)(url, filterHash, false);
      this.ajax.request(constructedUrl).then(response => {
        if (parseInt(objectPk) === response.duplicate_object_id) {
          response.is_unique = true;
        }
        (0, _abstract.safe_set)(this, 'itemIsUnique', response.is_unique);
      });
    },
    checkIdentifiersUniqueness: function (x) {
      if (this.isDestroyed) {
        return;
      }
      var code = this.code;
      var subRepositoryID = this.subRepositoryId;
      if ((0, _utils.isEmpty)(subRepositoryID) || (0, _utils.isEmpty)(code)) {
        this.set('codeIsUnique', true);
        return;
      }
      var url = this.additionalURL;
      var hash = {
        'code': code,
        'deposited_in': subRepositoryID
      };
      var constructedUrl = (0, _abstract.construct_request)(url, hash, true);
      this.ajax.request(constructedUrl).then(response => {
        if ((0, _utils.isEmpty)(this.subRepositoryId)) {
          return; // Race condition
        }
        (0, _abstract.safe_set)(this, 'codeIsUnique', response.is_unique);
      });
    },
    additionalCodeChanged: (0, _object.observer)('code', function () {
      if (!(0, _utils.isNone)(this.checkForUniqueness)) {
        (0, _runloop.debounce)(this, this.checkIdentifiersUniqueness, 400);
      }
    }),
    subRepositoryIDChanged: (0, _object.observer)('subRepositoryId', function () {
      if (!(0, _utils.isNone)(this.checkForUniqueness)) {
        this.checkIdentifiersUniqueness();
      }
    }),
    objectPrimaryIdChanged: (0, _object.observer)('primaryId', function () {
      if (!(0, _utils.isNone)(this.primaryId)) {
        (0, _runloop.debounce)(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    objectSubIdChanged: (0, _object.observer)('subId', function () {
      if (!(0, _utils.isNone)(this.subId)) {
        (0, _runloop.debounce)(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    depositedInChanged: (0, _object.observer)('subRepositoryObject.deposited_in', function () {
      const isChanged = this.get('subRepositoryObject.hasDirtyAttributes');
      if (isChanged) {
        (0, _runloop.debounce)(this, this.checkForObjectsUniqueness, 400);
      }
    }),
    subrepositories: (0, _object.computed)('subRepositoryId', function () {
      if ((0, _utils.isNone)(this.subRepositoryId)) {
        return [];
      }
      return this.store.query('agent/repository', {
        parent_organization: this.subRepositoryId,
        ordering: 'name'
      });
    })
  });
});