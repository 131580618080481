define("plutof/components/occurrences-table/-responsive-row/expand-label", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let ResponsiveRowExpandLabel = (_dec = (0, _component2.classNames)('occ-table-responsive-row__expand-label'), _dec(_class = class ResponsiveRowExpandLabel extends _component.default {}) || _class);
  var _default = _exports.default = ResponsiveRowExpandLabel;
});