define("plutof/components/tables/-list-view/order-label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      {{on "click" @sortBy}}
      disabled={{@disabled}}
      class="btn-link {{if @active 'table-header-btn' 'table-header-btn table-header-btn--inactive'}}"
  >
      {{#if @active}}
          <span class="fas {{if @ascending 'fa-sort-up' 'fa-sort-down'}}"></span>
      {{else}}
          <span class="fas fa-sort"></span>
      {{/if}}
  </button>
  
  {{@label}}
  
  */
  {
    "id": "Rb1ruOk/",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[16,0,[29,[\"btn-link \",[52,[30,2],\"table-header-btn\",\"table-header-btn table-header-btn--inactive\"]]]],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,1],[15,0,[29,[\"fas \",[52,[30,4],\"fa-sort-up\",\"fa-sort-down\"]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"fas fa-sort\"],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[1,[30,5]],[1,\"\\n\"]],[\"@disabled\",\"@active\",\"@sortBy\",\"@ascending\",\"@label\"],false,[\"if\",\"on\"]]",
    "moduleName": "plutof/components/tables/-list-view/order-label.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});