define("plutof/templates/components/occurrences-table/-responsive-row/row-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "3KKfQuWW",
    "block": "[[[10,0],[14,0,\"plutof-label occ-table-responsive-row__cell-label\"],[12],[1,\"\\n    #\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"occ-table-responsive-row__row-number\"],[12],[1,\"\\n    \"],[1,[30,0,[\"number\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/row-number.hbs",
    "isStrictMode": false
  });
});