define("plutof/components/search/query/search-string", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{common/local-label "Search.inputLabel" "Search.inputTooltip"}}
  
  <div class="input-group">
      <Input
          @type="text"
          @enter={{@search}}
          @value={{@searchString}}
          class="form-control search-value-input" />
  
      <span class="input-group-addon">
          <span class="plutof-tooltip-hover">
              <label class="search-over-all-label">
                  <Input @type="checkbox" @checked={{@searchAllFields}} />
                  {{i18n-t "Search.searchAllFields"}}
              </label>
  
              <span class="plutof-tooltip">
                  <span class="monospace">[✔]</span> {{i18n-t "Search.searchAllFields"}}
                  <br />
                  <span class="monospace">[&nbsp;]</span> {{i18n-t "Search.searchNameField"}}
              </span>
          </span>
      </span>
  </div>
  
  */
  {
    "id": "xgfGPMhw",
    "block": "[[[1,[28,[35,0],[\"Search.inputLabel\",\"Search.inputTooltip\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"form-control search-value-input\"]],[[\"@type\",\"@enter\",\"@value\"],[\"text\",[30,1],[30,2]]],null],[1,\"\\n\\n    \"],[10,1],[14,0,\"input-group-addon\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"search-over-all-label\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,3]]],null],[1,\"\\n                \"],[1,[28,[35,2],[\"Search.searchAllFields\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"monospace\"],[12],[1,\"[✔]\"],[13],[1,\" \"],[1,[28,[35,2],[\"Search.searchAllFields\"],null]],[1,\"\\n                \"],[10,\"br\"],[12],[13],[1,\"\\n                \"],[10,1],[14,0,\"monospace\"],[12],[1,\"[ ]\"],[13],[1,\" \"],[1,[28,[35,2],[\"Search.searchNameField\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@search\",\"@searchString\",\"@searchAllFields\"],false,[\"common/local-label\",\"input\",\"i18n-t\"]]",
    "moduleName": "plutof/components/search/query/search-string.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});