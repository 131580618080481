define("plutof/utils/has-many", ["exports", "@ember/application", "@ember/object", "@ember/object/mixin", "rsvp", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _application, _object, _mixin, _rsvp, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HasManyDataMixin = void 0;
  _exports.HasManyViewMixin = HasManyViewMixin;
  _exports.hasManyDataConstructors = hasManyDataConstructors;
  function HasManyViewMixin(model, {
    parentField,
    extraFilters = {}
  } = {}) {
    return _mixin.default.create({
      didReceiveAttrs() {
        this._super(...arguments);
        this.update.perform();
      },
      update: (0, _emberConcurrency.task)(function* () {
        const parent = this.get(parentField);
        const filters = Object.assign({
          [parentField]: parent.get('id')
        }, extraFilters);
        const pagination = yield (0, _pagination.paginateModel)(this.store, model, filters);
        this.set('pagination', pagination);
      }, {
        restartable: true
      })
    });
  }
  const HasManyDataMixin = _exports.HasManyDataMixin = _mixin.default.create({
    count: (0, _object.computed)('pagination.objectCount', 'localCount', function () {
      return this.get('pagination.objectCount') + this.localCount;
    }),
    init() {
      this._super(...arguments);
      this.set('localCount', 0);
      this.set('itemsToDelete', []);
    },
    _createEmpty() {
      // Implemented in host class
    },
    // TODO: should add just one. If caller needs many, let it do the loop itself
    add(count = 1) {
      let created = [];
      for (let i = 0; i < count; i++) {
        const item = this._createEmpty();
        created.push(item);
        this.addExisting(item);
      }
      return created;
    },
    addExisting(item) {
      this.get('pagination.objects').pushObject(item);
      this.incrementProperty('localCount');
    },
    remove(record) {
      this.set('pagination.objects', this.get('pagination.objects').without(record));
      this.itemsToDelete.pushObject(record);

      // Even if record is not local, this will hold the total true. localCount is therefore
      // more of a count offset
      this.decrementProperty('localCount');
    },
    save() {
      return _rsvp.default.all(this.itemsToDelete.invoke('destroyRecord')).then(() => {
        this.set('itemsToDelete', []);
      });
    }
  });
  function hasManyDataConstructors(model, dataClass, {
    parentField,
    extraFilters = {}
  } = {}) {
    function createDataClass(params) {
      const ownerInjection = (0, _application.getOwner)(params.store).ownerInjection();
      return dataClass.create(ownerInjection, params);
    }
    function create(store, parent) {
      const pagination = _pagination.EmptyPagination.create();
      const data = createDataClass({
        store,
        pagination,
        model,
        [parentField]: parent
      });
      return _rsvp.default.Promise.resolve(data);
    }
    function load(store, parent) {
      const filters = Object.assign({
        [parentField]: parent.get('id')
      }, extraFilters);
      const mixins = [_pagination.AccumulatingPaginationMixin];
      return (0, _pagination.paginateModel)(store, model, filters, mixins).then(pagination => {
        return createDataClass({
          store,
          pagination,
          model,
          [parentField]: parent
        });
      });
    }
    return {
      create,
      load
    };
  }
});