define("plutof/components/search/column-control/column", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! TODO: Better name? }}
  
  <div class="column-control">
      {{#component @column
          toggle=(fn @toggleColumn @header)
          visible=@header.isVisible
      }}
          {{@header.config.label}}
  
          {{#if (is-equal @absoluteOrdering @header.ordering)}}
              &nbsp;
              <span class="fas {{if @header.ascendingOrder 'fa-arrow-up' 'fa-arrow-down'}}"></span>
          {{/if}}
      {{/component}}
  
      {{#if @pinColumn}}
          {{component @pin
              pinColumn=(fn @pinColumn @header)
              isPinned=@header.isPinned}}
      {{/if}}
  </div>
  
  */
  {
    "id": "uBKT3mAs",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"column-control\"],[12],[1,\"\\n\"],[46,[30,1],null,[[\"toggle\",\"visible\"],[[28,[37,1],[[30,2],[30,3]],null],[30,3,[\"isVisible\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[30,3,[\"config\",\"label\"]]],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,4],[30,3,[\"ordering\"]]],null],[[[1,\"             \\n            \"],[10,1],[15,0,[29,[\"fas \",[52,[30,3,[\"ascendingOrder\"]],\"fa-arrow-up\",\"fa-arrow-down\"]]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[46,[30,6],null,[[\"pinColumn\",\"isPinned\"],[[28,[37,1],[[30,5],[30,3]],null],[30,3,[\"isPinned\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@column\",\"@toggleColumn\",\"@header\",\"@absoluteOrdering\",\"@pinColumn\",\"@pin\"],false,[\"component\",\"fn\",\"if\",\"is-equal\"]]",
    "moduleName": "plutof/components/search/column-control/column.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});