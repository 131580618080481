define("plutof/routes/publishing/gbif/add", ["exports", "rsvp", "plutof/components/publishing/gbif/access-rights/edit", "plutof/components/publishing/gbif/records/records-table", "plutof/utils/routes"], function (_exports, _rsvp, _edit, _recordsTable, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class PublishingGbifAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'publishing.gbif.index');
      _defineProperty(this, "title", 'dataResource.general.newDataset');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const dataset = this.store.createRecord('publishing/gbif/dataset', {
        language: 'en'
      });
      const [sequences, specimens, observations, referencebased, rights] = await _rsvp.default.all([(0, _recordsTable.createRecordsTableData)(this.ajax, dataset, 'Sequence'), (0, _recordsTable.createRecordsTableData)(this.ajax, dataset, 'Specimen'), (0, _recordsTable.createRecordsTableData)(this.ajax, dataset, 'Observation'), (0, _recordsTable.createRecordsTableData)(this.ajax, dataset, 'Reference Based'), (0, _edit.createDatasetRights)(this.ajax, dataset)]);
      return {
        agents: [],
        dataset,
        sequences,
        specimens,
        observations,
        referencebased,
        rights
      };
    }
  }
  var _default = _exports.default = PublishingGbifAddRoute;
});