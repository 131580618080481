define("plutof/components/unite/alignment-columns", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Tables::ColumnSelector as |columnSelector|>
      <columnSelector.column
          @toggle={{this.toggleAll}}
          @visible={{this.allSelected}}
      >
          {{i18n-t "General.all"}}
      </columnSelector.column>
  
      {{#each @all as |column|}}
          <div class="column-control">
              <columnSelector.column
                  @toggle={{fn this.toggle column}}
                  @visible={{in-list @selected column.id}}
              >
                  {{column.name}}
              </columnSelector.column>
          </div>
      {{/each}}
  </Tables::ColumnSelector>
  
  */
  {
    "id": "zRjamy7I",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"column\"]],null,[[\"@toggle\",\"@visible\"],[[30,0,[\"toggleAll\"]],[30,0,[\"allSelected\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"General.all\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"column-control\"],[12],[1,\"\\n            \"],[8,[30,1,[\"column\"]],null,[[\"@toggle\",\"@visible\"],[[28,[37,4],[[30,0,[\"toggle\"]],[30,3]],null],[28,[37,5],[[30,4],[30,3,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null]],[1]]]]],[1,\"\\n\"]],[\"columnSelector\",\"@all\",\"column\",\"@selected\"],false,[\"tables/column-selector\",\"i18n-t\",\"each\",\"-track-array\",\"fn\",\"in-list\"]]",
    "moduleName": "plutof/components/unite/alignment-columns.hbs",
    "isStrictMode": false
  });
  let AlignmentColumns = (_dec = (0, _object.computed)('args.selected.[]', 'args.all.[]'), _class = class AlignmentColumns extends _component2.default {
    get allSelected() {
      return this.args.selected.length === this.args.all.length;
    }
    toggle(column) {
      if (this.args.selected.includes(column.id)) {
        this.args.selected.removeObject(column.id);
      } else {
        this.args.selected.pushObject(column.id);
      }
    }
    toggleAll() {
      if (this.allSelected) {
        this.args.selected.clear();
      } else {
        this.args.selected.replace(0, this.args.selected.length, this.args.all.map(col => col.id));
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "allSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAll"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AlignmentColumns);
});