define("plutof/templates/components/transaction/edit-correspondences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SQSnFYzN",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Transaction::Correspondences\"]],[[\"@collapse\"],[[28,[37,1],[[30,1,[\"count\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"transactions.correspondence\"],null],[30,1,[\"count\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\",\"@class\"],[[28,[37,3],[[30,0],[30,0,[\"add\"]]],null],\"test-marker-btn-add\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@context\",\"@items\"],[[30,3],[30,1,[\"correspondences\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"            \"],[8,[39,5],[[24,\"data-test\",\"Transaction::Correspondence\"]],[[\"@correspondence\",\"@validationContext\",\"@remove\"],[[30,4],[30,5],[28,[37,6],[[30,0,[\"remove\"]],[30,4]],null]]],null],[1,\"\\n        \"]],[4,5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@data\",\"panel\",\"@validationContext\",\"correspondence\",\"context\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"action\",\"validation/split-context\",\"transaction/transaction-correspondence\",\"fn\"]]",
    "moduleName": "plutof/templates/components/transaction/edit-correspondences.hbs",
    "isStrictMode": false
  });
});