define("plutof/components/taxonomy/common-names/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "Taxonomy.vernacularNames"}}
          @count={{@commonNames.length}} />
  
      <panel.content>
          <Taxonomy::CommonNames::Table @commonNames={{@commonNames}} />
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "0ObVybfA",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"Taxonomy.vernacularNames\"],null],[30,2,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@commonNames\"],[[30,2]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@commonNames\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"taxonomy/common-names/table\"]]",
    "moduleName": "plutof/components/taxonomy/common-names/view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});