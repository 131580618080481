define("plutof/components/tables/-list-view/related-objects", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.relatedObjects"}}>
      <Sample::ListRelatedObjects
          @objects={{@record.related_objects}}
          @parentObject={{@record}}
          @openFile={{@open}} />
  </@Field>
  
  */
  {
    "id": "0IUprV5T",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"PlutofListViewColumn.relatedObjects\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@objects\",\"@parentObject\",\"@openFile\"],[[30,2,[\"related_objects\"]],[30,2],[30,3]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@Field\",\"@record\",\"@open\"],false,[\"i18n-t\",\"sample/list-related-objects\"]]",
    "moduleName": "plutof/components/tables/-list-view/related-objects.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});