define("plutof/templates/experiment/pcr/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1KZcIssv",
    "block": "[[[10,0],[14,0,\"route-marker-pcr-view\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@editRoute\",\"@module\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.pcr.routeTitles.view\"],null],[30,0,[\"model\",\"experiment\"]],[30,0,[\"model\",\"permissions\"]],\"experiment.pcr.edit\",\"dnalab/pcr\",true,[28,[37,2],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@experiment\"],[[30,0,[\"model\",\"experiment\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@labID\",\"@materials\",\"@materialType\"],[[30,0,[\"model\",\"experiment\",\"laboratory\",\"id\"]],[30,0,[\"model\",\"materials\"]],\"pcr\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@products\",\"@enableTubeSelection\",\"@materialContainer\"],[[30,0,[\"model\",\"materials\"]],true,[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@experiment\",\"@materials\",\"@materialType\",\"@enableTubeSelection\",\"@materialContainer\"],[[30,0,[\"model\",\"experiment\"]],[30,0,[\"model\",\"materials\"]],\"pcr\",true,[30,1]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,7],null,[[\"@object\"],[[30,0,[\"model\",\"experiment\"]]]],null],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@record\"],[[30,0,[\"model\",\"experiment\"]]]],null],[1,\"\\n\"]],[\"materialContainer\"],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"experiment/pcr/view/general-data\",\"experiment/common/material-container\",\"experiment/pcr/view/experiment-material-view\",\"experiment/common/labware-visualization\",\"linked-items/view\",\"experiment/common/record-metadata\"]]",
    "moduleName": "plutof/templates/experiment/pcr/view.hbs",
    "isStrictMode": false
  });
});