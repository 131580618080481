define("plutof/components/search/filters/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ThreeStateCheckbox @checked={{@filter.value}} @changed={{this.changed}}>
      {{yield}}
  </ThreeStateCheckbox>
  
  */
  {
    "id": "gfh95wQo",
    "block": "[[[8,[39,0],null,[[\"@checked\",\"@changed\"],[[30,1,[\"value\"]],[30,0,[\"changed\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filter\",\"&default\"],false,[\"three-state-checkbox\",\"yield\"]]",
    "moduleName": "plutof/components/search/filters/checkbox.hbs",
    "isStrictMode": false
  });
  let SearchCheckbox = (_class = class SearchCheckbox extends _component2.default {
    changed(value) {
      this.args.filter.value = value;
      if (!(0, _utils.isNone)(this.args.changed)) {
        this.args.changed(value);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "changed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changed"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchCheckbox);
});