define("plutof/templates/layer/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yiaVrXE5",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"layer.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[28,[37,2],[\"area\"],null],[28,[37,3],[\"Layer.editLayer\"],null],[30,0,[\"model\",\"samplingarea\"]],\"information/content/gis-lab/layers\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@layer\",\"@areas\",\"@save\",\"@validationContext\"],[[30,0,[\"model\",\"layer\"]],[30,0,[\"model\",\"areas\"]],[30,0,[\"save\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"layer/edit\"]]",
    "moduleName": "plutof/templates/layer/edit.hbs",
    "isStrictMode": false
  });
});