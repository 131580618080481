define("plutof/components/save-and-reset", ["exports", "@glimmer/component", "@ember/object", "@ember/utils", "plutof/utils/notifications"], function (_exports, _component, _object, _utils, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SaveAndReset = (_class = class SaveAndReset extends _component.default {
    save() {
      const target = this.args.targetObj;
      target.set('closePanels', true);
      target.get('queryParams').forEach(function (qp) {
        if ((0, _utils.isPresent)(target.get(qp))) {
          target.set(qp, null);
        }
      });

      // XXX: This whole target crap needs to go
      return target._save().then(() => {
        target.set('closePanels', false);

        // If the user has manually navigated to other route while save is still
        // going, doRefresh is not available, as it is defined on the (now) inactive
        // route.
        if (!this.isDestroyed) {
          if (this.args.reset) {
            this.args.reset();
          } else {
            target.send('doRefresh');
          }
        }
      }).catch(error => {
        (0, _notifications.reportError)(error);
        target.set('closePanels', false);
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = SaveAndReset;
});