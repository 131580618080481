define("plutof/components/dmp/cost", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/formatters"], function (_exports, _component, _templateFactory, _component2, _emberCpValidations, _componentValidations, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class={{has-error this "cost.title"}}>
      <Input @type="text" @value={{@cost.title}} @class="mini-input" />
  </td>
  
  <td>
      <FlexibleTextarea @value={{@cost.description}} @class="mini-input" />
  </td>
  
  <td class={{has-error this "cost.value"}}>
      <Input @type="number" @value={{@cost.value}} @class="mini-input" />
  </td>
  
  <td class={{has-error this "cost.currency"}}>
      <Input
          @type="text"
          @value={{@cost.currency}}
          @placeholder={{i18n-t "dmp.cost.currency.placeholder"}}
          @class="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" (fn @remove @cost)}}
          class="btn btn-link data-table-btn"
      >
          <span class={{icon "remove"}}></span>
      </button>
  </td>
  
  */
  {
    "id": "OKVQuAac",
    "block": "[[[10,\"td\"],[15,0,[28,[37,0],[[30,0],\"cost.title\"],null]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"text\",[30,1,[\"title\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@class\"],[[30,1,[\"description\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[28,[37,0],[[30,0],\"cost.value\"],null]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@class\"],[\"number\",[30,1,[\"value\"]],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[28,[37,0],[[30,0],\"cost.currency\"],null]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[30,1,[\"currency\"]],[28,[37,3],[\"dmp.cost.currency.placeholder\"],null],\"mini-input\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,4],[\"click\",[28,[37,5],[[30,2],[30,1]],null]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,6],[\"remove\"],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@cost\",\"@remove\"],false,[\"has-error\",\"input\",\"flexible-textarea\",\"i18n-t\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/components/dmp/cost.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'cost.title': (0, _emberCpValidations.validator)('presence', true),
    'cost.value': (0, _emberCpValidations.validator)('format', {
      regex: _formatters.DECIMAL_REGEX
    }),
    'cost.currency': (0, _emberCpValidations.validator)('presence', true)
  });
  let DMPCost = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class DMPCost extends _component.default.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DMPCost);
});