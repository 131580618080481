define("plutof/components/experiment/sequencing/view/sequencing-material", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp", "@ember-decorators/component", "ember-concurrency", "plutof/components/experiment/utils", "plutof/utils/clipboard", "plutof/utils/promises"], function (_exports, _component, _object, _computed, _utils, _rsvp, _component2, _emberConcurrency, _utils2, _clipboard, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SequenceExperimentMaterialView = (_dec = (0, _component2.classNames)('sequencing-material-view'), _dec2 = (0, _computed.equal)('experiment.platform', 1), _dec3 = (0, _computed.filterBy)('results.@each.material', 'hasSource'), _dec4 = (0, _object.computed)('results.[]'), _dec5 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec(_class = (_class2 = class SequenceExperimentMaterialView extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isSanger", _descriptor, this);
      _initializerDefineProperty(this, "filledMaterials", _descriptor2, this);
      _initializerDefineProperty(this, "_copySources", _descriptor3, this);
    }
    get labware() {
      const materials = this.results.sortBy('tube_nr');
      return materials.map((material, index) => {
        return {
          material: material,
          representation: (0, _utils2.labwareTubeLabel)(index)
        };
      });
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('sourcesCopied', false);
    }
    copySources() {
      this._copySources.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "isSanger", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "filledMaterials", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "labware", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "labware"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "_copySources", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const names = (yield _rsvp.default.all(this.labware.map(async tube => {
          const pcr = await tube.material.pcr_product;
          if (!pcr) {
            return null;
          }
          const code = pcr.name.split('|')[0].trim();
          const primer = await tube.material.primer;
          return [code, tube.material.id_suffix, primer.name].reject(_utils.isEmpty).join('_');
        }))).compact();
        (0, _clipboard.sendToClipboard)(names.join('\n'));
        this.set('sourcesCopied', true);
        yield (0, _promises.wait)(1000);
        this.set('sourcesCopied', false);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "copySources", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "copySources"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = SequenceExperimentMaterialView;
});