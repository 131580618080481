define("plutof/templates/components/experiment/sequencing/edit-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/e+IcWFV",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"experiment\",\"materialIsInvalid\",\"validationContext\"],[[30,1],[33,3],[33,4,[\"metadata\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[1,[28,[35,5],null,[[\"experiment\",\"results\",\"isSanger\",\"addLabware\",\"validationContext\"],[[30,1],[30,2],[33,6],[28,[37,7],[[30,0],[33,8]],null],[33,4,[\"material\"]]]]]],[1,\"\\n\\n\"],[41,[33,6],[[[1,\"    \"],[1,[28,[35,10],null,[[\"experiment\",\"materials\",\"materialType\"],[[30,1],[30,2],\"sequencing\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,11],null,[[\"data\",\"validationContext\"],[[30,3],[33,4,[\"associatedData\"]]]]]],[1,\"\\n\\n\"],[8,[39,12],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,13],null,[[\"@validationContext\"],[[30,4]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,14],null,[[\"@save\",\"@disabled\"],[[28,[37,7],[[30,0],[30,5]],null],[30,4,[\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,15],null,[[\"@cancel\"],[[28,[37,7],[[30,0],[30,6]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@experiment\",\"@materials\",\"@linkedItemsData\",\"@validationContext\",\"@save\",\"@cancel\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"experiment/sequencing/experiment-metadata\",\"materialIsInvalid\",\"validationContext\",\"experiment/sequencing/experiment-material\",\"isSanger\",\"action\",\"addLabware\",\"if\",\"experiment/common/labware-visualization\",\"linked-items/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/components/experiment/sequencing/edit-form.hbs",
    "isStrictMode": false
  });
});