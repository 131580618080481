define("plutof/components/gis-lab/import/areas", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TabGroup
      @selected={{this.selectedTab}}
      @tabSwitched={{action (mut this.selectedTab)}}
  >
      <:tabs as |group|>
          <group.tab @name="list">
              <group.header @name="list">
                  {{i18n-t "GisLab.import.tabs.list"}}
              </group.header>
  
              <div>
                  <GisLab::Import::Areas::List
                      @areasData={{@areasData}}
                      @showOnMap={{fn this.showOnMap group.group}} />
              </div>
          </group.tab>
  
          <group.tab @name="map">
              <group.header @name="map">
                  {{i18n-t "GisLab.import.tabs.map"}}
              </group.header>
  
              <div>
                  <GisLab::Import::Areas::Map
                      @areasData={{@areasData}}
                      @zoomToGeometry={{this.zoomToGeometry}} />
              </div>
          </group.tab>
      </:tabs>
  </TabGroup>
  
  */
  {
    "id": "itehCI52",
    "block": "[[[8,[39,0],null,[[\"@selected\",\"@tabSwitched\"],[[30,0,[\"selectedTab\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"selectedTab\"]]],null]],null]]],[[\"tabs\"],[[[[1,\"\\n        \"],[8,[30,1,[\"tab\"]],null,[[\"@name\"],[\"list\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"header\"]],null,[[\"@name\"],[\"list\"]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"GisLab.import.tabs.list\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[10,0],[12],[1,\"\\n                \"],[8,[39,4],null,[[\"@areasData\",\"@showOnMap\"],[[30,2],[28,[37,5],[[30,0,[\"showOnMap\"]],[30,1,[\"group\"]]],null]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"tab\"]],null,[[\"@name\"],[\"map\"]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"header\"]],null,[[\"@name\"],[\"map\"]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"GisLab.import.tabs.map\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[10,0],[12],[1,\"\\n                \"],[8,[39,6],null,[[\"@areasData\",\"@zoomToGeometry\"],[[30,2],[30,0,[\"zoomToGeometry\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[\"group\",\"@areasData\"],false,[\"tab-group\",\"action\",\"mut\",\"i18n-t\",\"gis-lab/import/areas/list\",\"fn\",\"gis-lab/import/areas/map\"]]",
    "moduleName": "plutof/components/gis-lab/import/areas.hbs",
    "isStrictMode": false
  });
  let GisLabImportAreas = (_class = class GisLabImportAreas extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "selectedTab", _descriptor, this);
      _initializerDefineProperty(this, "zoomToGeometry", _descriptor2, this);
    }
    showOnMap(tabGroup, area) {
      tabGroup.switchTab(tabGroup.getTab('map'));
      this.zoomToGeometry = area.geom;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'list';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "zoomToGeometry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showOnMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showOnMap"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GisLabImportAreas);
});