define("plutof/templates/experiment/pcr/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "F/oB3ovc",
    "block": "[[[10,0],[14,0,\"route-marker-pcr-add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.pcr.routeTitles.add\"],null],[30,0,[\"model\",\"experiment\"]],[28,[37,3],[\"triggerReset\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,4],null,[[\"experiment\",\"materials\",\"linkedItemsData\",\"validationContext\",\"cancel\",\"save\"],[[33,5,[\"experiment\"]],[33,5,[\"materials\"]],[33,5,[\"linkedItemsData\"]],[33,6],[28,[37,7],[[30,0],\"cancel\"],null],[28,[37,7],[[30,0],\"saveExperiment\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"experiment/pcr/edit-form\",\"model\",\"validationContext\",\"action\"]]",
    "moduleName": "plutof/templates/experiment/pcr/add.hbs",
    "isStrictMode": false
  });
});