define("plutof/components/auth/identity-provider-buttons/orcid", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="btn btn-default social-login-button" {{on "click" @clicked}}>
      <img src="/assets/images/auth/orcid.png" class="social-login-button__image">
      {{i18n-t "login.signin.orcid"}}
  </div>
  
  */
  {
    "id": "z0QUkt1P",
    "block": "[[[11,0],[24,0,\"btn btn-default social-login-button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/auth/orcid.png\"],[14,0,\"social-login-button__image\"],[12],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"login.signin.orcid\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@clicked\"],false,[\"on\",\"i18n-t\"]]",
    "moduleName": "plutof/components/auth/identity-provider-buttons/orcid.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});