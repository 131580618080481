define("plutof/templates/forms/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "d+BfCFNB",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"forms.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[28,[37,2],[\"form\"],null],[28,[37,3],[\"Forms.newTitle\"],null],[30,0,[\"model\",\"mainformData\",\"mainform\"]],[28,[37,4],[\"triggerReset\"],null],\"information/content/forms/general\"]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@data\",\"@managingGroup\",\"@validationContext\"],[[30,0,[\"model\",\"mainformData\"]],[30,0,[\"model\",\"managingGroup\"]],[30,0,[\"validationContext\",\"generalData\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@mainform\"],[[30,0,[\"model\",\"mainformData\",\"mainform\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[28,[37,11],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"forms/edit\",\"forms/access\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/forms/add.hbs",
    "isStrictMode": false
  });
});