define("plutof/templates/components/filerepository/item-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "H7htKNXF",
    "block": "[[[8,[39,0],null,null,[[\"tabs\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"tabs\"]]],null]],null],null,[[[6,[30,1,[\"tab\"]],null,[[\"name\"],[[30,2,[\"contentType\"]]]],[[\"default\"],[[[[6,[30,1,[\"header\"]],null,[[\"name\"],[[30,2,[\"contentType\"]]]],[[\"default\"],[[[[1,\"                    \"],[1,[30,2,[\"module\",\"name\"]]],[1,\"\\n\\n                    \"],[10,1],[14,0,\"count-badge\"],[12],[1,\"\\n                        \"],[1,[30,2,[\"items\",\"length\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n                \"],[10,0],[14,0,\"out-of-form-field\"],[12],[1,\"\\n                    \"],[8,[39,4],null,[[\"@model\",\"@update\",\"@filters\"],[[28,[37,5],[[30,2,[\"module\",\"model\"]],[30,2,[\"contentType\"]]],null],[30,2,[\"add\"]],[28,[37,6],null,[[\"access\"],[\"edit\"]]]]],null],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[8,[39,7],null,[[\"@records\",\"@remove\"],[[30,2,[\"items\"]],[30,2,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,3,[\"object_name\"]]],[1,\"\\n                \"]],[3]]]]],[1,\"\\n\"]],[]]]]]],[2]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\"]],[\"group\",\"tab\",\"item\"],false,[\"tab-group\",\"each\",\"-track-array\",\"data\",\"auto-complete/model\",\"or\",\"hash\",\"common/record-list\"]]",
    "moduleName": "plutof/templates/components/filerepository/item-list.hbs",
    "isStrictMode": false
  });
});