define("plutof/components/related-objects/content/reference-based", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field refbased|>
      <Field @label={{i18n-t "RelatedObjectsViewColumn.name"}}>
          <LinkTo @route="referencebased.view" @model={{refbased.id}}>
              {{refbased.taxon_name}}
          </LinkTo>
      </Field>
  
      <Field @label={{i18n-t "General.collectedBy"}}>
          {{refbased.collected_by}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.timespan"}}>
          {{formatted-date refbased.timespan_begin}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.areaName"}}>
          {{refbased.area_name}}
      </Field>
  
      <Field @label={{i18n-t "General.owner"}}>
          {{refbased.owner_name}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date refbased.updated_at}}
      </Field>
  
      <Field @label={{i18n-t "PlutofListViewColumn.clipboard"}}>
          <RelatedObjects::ClipboardStatus
              @onClipboard={{refbased.on_clipboard}}
              @recordID={{refbased.id}}
              @clipboard="occurrences" />
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "EP3zGGv3",
    "block": "[[[8,[39,0],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"referencebased.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"taxon_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"General.collectedBy\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"collected_by\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.timespan\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,3,[\"timespan_begin\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.areaName\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"area_name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"General.owner\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"owner_name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[[30,3,[\"updated_at\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"PlutofListViewColumn.clipboard\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@onClipboard\",\"@recordID\",\"@clipboard\"],[[30,3,[\"on_clipboard\"]],[30,3,[\"id\"]],\"occurrences\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@pagination\",\"Field\",\"refbased\"],false,[\"related-objects/content\",\"i18n-t\",\"link-to\",\"formatted-date\",\"related-objects/clipboard-status\"]]",
    "moduleName": "plutof/components/related-objects/content/reference-based.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});