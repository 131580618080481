define("plutof/templates/publishing/ena/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "zwYOh91V",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"ena.view\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@editRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"ena\"],null],[28,[37,3],[\"ena.dataset\"],null],[30,0,[\"model\",\"ena\"]],[28,[37,4],null,[[\"canModify\",\"canEdit\"],[true,true]]],\"publishing.ena.edit\",true,[28,[37,3],[\"ena.title\"],null],\"information/content/ena/general\"]],null],[1,\"\\n\\n\"],[8,[39,5],[[24,\"data-test\",\"Ena::View::GeneralData\"]],[[\"@title\"],[[28,[37,3],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@record\"],[[30,0,[\"model\",\"ena\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,1,[\"attr\"]],[\"project\"],null,[[\"default\"],[[[[1,\"                \"],[8,[39,8],null,[[\"@route\",\"@model\"],[\"study.view\",[30,0,[\"model\",\"ena\",\"project\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,0,[\"model\",\"ena\",\"project\",\"representation\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"depth_missing\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"elevation_missing\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"altitude_missing\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"environmental_medium_missing\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"local_environmental_context_missing\"],null]],[1,\"\\n            \"],[1,[28,[30,1,[\"field\"]],[\"broad_scale_environmental_context_missing\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"view\"],false,[\"test/route-marker\",\"navbar/view\",\"icon\",\"i18n-t\",\"hash\",\"plutof-panel/simple\",\"generic-views/record\",\"layout/regular-grid\",\"link-to\"]]",
    "moduleName": "plutof/templates/publishing/ena/view.hbs",
    "isStrictMode": false
  });
});