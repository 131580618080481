define("plutof/components/observation/general-data", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Specimen::GeneralData"
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <PlutofLabelc @path="observation.related_materialsample" />
  
              {{#async/bind-relation @materialSample as |value update|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/materialsample/materialsample"
                      @value={{value}}
                      @update={{update}}
                      @filters={{hash access="edit"}} />
              {{/async/bind-relation}}
          </div>
  
          <div>
              <PlutofLabelc @path="observation.living_specimen" />
  
              {{#async/bind-relation @livingSpecimen as |value update|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/livingculture/strain"
                      @value={{value}}
                      @update={{update}}
                      @filters={{hash access="edit"}} />
              {{/async/bind-relation}}
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Izq3pXYY",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Specimen::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@path\"],[\"observation.related_materialsample\"]],null],[1,\"\\n\\n\"],[6,[39,4],[[30,1]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,[[\"@model\",\"@value\",\"@update\",\"@filters\"],[\"taxonoccurrence/materialsample/materialsample\",[30,2],[30,3],[28,[37,6],null,[[\"access\"],[\"edit\"]]]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@path\"],[\"observation.living_specimen\"]],null],[1,\"\\n\\n\"],[6,[39,4],[[30,4]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,[[\"@model\",\"@value\",\"@update\",\"@filters\"],[\"taxonoccurrence/livingculture/strain\",[30,5],[30,6],[28,[37,6],null,[[\"access\"],[\"edit\"]]]]],null],[1,\"\\n\"]],[5,6]]]]],[1,\"        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@materialSample\",\"value\",\"update\",\"@livingSpecimen\",\"value\",\"update\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"plutof-labelc\",\"async/bind-relation\",\"auto-complete/model\",\"hash\"]]",
    "moduleName": "plutof/components/observation/general-data.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});