define("plutof/components/clipboard/-export/gen-bank", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "plutof/misc/fileupload", "plutof/utils/notifications", "plutof/components/clipboard/-export/utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _fileupload, _notifications, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ClipboardGenBankExportModel = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{3}} @class="add-component" @gapless={{true}}>
      <div class="component-part">
          <div class="display-flex">
              {{common/local-label "Clipboard.genBankFile"}}
  
              <Information::DialogTrigger
                  @title={{i18n-t "Clipboard.genBankExport"}}
                  @content="information/content/export-genbank/general" />
          </div>
  
          {{#async/bind-relation @model.file as |value update|}}
              <Filerepository::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  @filters={{hash access="edit"}}
                  @params={{hash inputClasses="form-control group-item-left"}} />
          {{/async/bind-relation}}
      </div>
  
      {{! TODO: This shoulds just use filerepo autocomplete's local file action and upload during save }}
      <div class="component-part">
          {{! TODO: This should be a promise button}}
          <FileSelector
              @selected={{@model.uploadTemplate}}
              @multiple={{false}}
              @name="General.Upload"
              @icon={{if @modal.uploadingTemplate "fas fa-sync-alt spinicon" "icon-upload"}}
              @classes="btn btn-default group-item-right" />
      </div>
  </Layout::RegularGrid>
  
  */
  {
    "id": "FI9/QyN4",
    "block": "[[[8,[39,0],null,[[\"@columns\",\"@class\",\"@gapless\"],[3,\"add-component\",true]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"display-flex\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"Clipboard.genBankFile\"],null]],[1,\"\\n\\n            \"],[8,[39,2],null,[[\"@title\",\"@content\"],[[28,[37,3],[\"Clipboard.genBankExport\"],null],\"information/content/export-genbank/general\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[6,[39,4],[[30,1,[\"file\"]]],null,[[\"default\"],[[[[1,\"            \"],[8,[39,5],null,[[\"@value\",\"@update\",\"@filters\",\"@params\"],[[30,2],[30,3],[28,[37,6],null,[[\"access\"],[\"edit\"]]],[28,[37,6],null,[[\"inputClasses\"],[\"form-control group-item-left\"]]]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n\"],[1,\"        \"],[8,[39,7],null,[[\"@selected\",\"@multiple\",\"@name\",\"@icon\",\"@classes\"],[[30,1,[\"uploadTemplate\"]],false,\"General.Upload\",[52,[30,4,[\"uploadingTemplate\"]],\"fas fa-sync-alt spinicon\",\"icon-upload\"],\"btn btn-default group-item-right\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@model\",\"value\",\"update\",\"@modal\"],false,[\"layout/regular-grid\",\"common/local-label\",\"information/dialog-trigger\",\"i18n-t\",\"async/bind-relation\",\"filerepository/auto-complete\",\"hash\",\"file-selector\",\"if\"]]",
    "moduleName": "plutof/components/clipboard/-export/gen-bank.hbs",
    "isStrictMode": false
  });
  let ClipboardGenBankExportModel = _exports.ClipboardGenBankExportModel = (_class = class ClipboardGenBankExportModel extends _utils.ExportFormatModel {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "file", _descriptor, this);
      // TODO: Now that wa model.save, we don't really have to
      // upload immediately
      _initializerDefineProperty(this, "uploadingTemplate", _descriptor2, this);
    }
    uploadTemplate(file) {
      // TODO: Convert to upload service + taskify
      return (0, _fileupload.get_uploader)().then(uploader => {
        const upload = uploader.upload(file);
        this.uploadingTemplate = true;
        return upload.wait().then(file => {
          this.file = file;
        });
      }).catch(_notifications.reportError).finally(() => {
        this.uploadingTemplate = false;
      });
    }
    prepareSaveData(objectList) {
      return {
        object_list: objectList,
        template_file: this.file.url
      };
    }
    async export(ajax, objectList) {
      // TODO: Convert to uploading here
      const params = {
        template_file: this.file.url,
        object_list: objectList
      };
      const response = await ajax.request(this.endpoint, {
        method: 'POST',
        processData: false,
        contentType: 'application/json; charset=UTF-8',
        data: JSON.stringify(params)
      });
      return {
        fileID: response.id
      };
    }
    get valid() {
      return this.file !== null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "file", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uploadingTemplate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "uploadTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "export", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "export"), _class.prototype), _class);
  class ClipboardGenBankExport extends _component2.default {}
  _exports.default = ClipboardGenBankExport;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ClipboardGenBankExport);
});