define("plutof/components/tables/-list-view/access", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.access"}}>
      <span class={{icon (if @record.is_public 'public' 'private')}}></span>
  </@Field>
  
  */
  {
    "id": "TFszTl04",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"PlutofListViewColumn.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[28,[37,1],[[52,[30,2,[\"is_public\"]],\"public\",\"private\"]],null]],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@Field\",\"@record\"],false,[\"i18n-t\",\"icon\",\"if\"]]",
    "moduleName": "plutof/components/tables/-list-view/access.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});