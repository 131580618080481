define("plutof/components/filerepository/local-file-preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      preview=this.preview
      previewButton=(component "filerepository/-local-file-preview/button" preview=this.preview)
  )}}
  
  <Ui::Modal::Dialog
      @open={{this.open}}
      @close={{this.close}}
  >
      <:body>
          <img src={{this.imageContent}} class="local-file-preview-image">
      </:body>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "1t+QVlHr",
    "block": "[[[18,1,[[28,[37,1],null,[[\"preview\",\"previewButton\"],[[30,0,[\"preview\"]],[50,\"filerepository/-local-file-preview/button\",0,null,[[\"preview\"],[[30,0,[\"preview\"]]]]]]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@open\",\"@close\"],[[30,0,[\"open\"]],[30,0,[\"close\"]]]],[[\"body\"],[[[[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"imageContent\"]]],[14,0,\"local-file-preview-image\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"ui/modal/dialog\"]]",
    "moduleName": "plutof/components/filerepository/local-file-preview.hbs",
    "isStrictMode": false
  });
  let LocalFilePreview = (_class = class LocalFilePreview extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "imageContent", _descriptor, this);
      _initializerDefineProperty(this, "open", _descriptor2, this);
    }
    preview(file) {
      const reader = new FileReader();
      reader.onload = event => {
        this.imageContent = event.target.result;
        this.open = true;
      };
      reader.readAsDataURL(file);
    }
    close() {
      this.open = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "imageContent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "preview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "preview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LocalFilePreview);
});