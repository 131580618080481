define("plutof/templates/annotations/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fsIgvzbh",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\"],[[28,[37,1],[\"annotation\"],null],[28,[37,2],[\"annotation.annotations\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[28,[37,2],[\"annotation.routes.created.title\"],null],[28,[37,2],[\"annotation.routes.created.description\"],null],[28,[37,2],[\"annotation.routes.created.buttonTitle\"],[[\"rejected\"],[[30,0,[\"model\",\"rejectedCount\"]]]]],\"annotations.created\",[28,[37,1],[\"annotation\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"link\"]],null,[[\"@title\",\"@description\",\"@buttonTitle\",\"@route\",\"@icon\"],[[28,[37,2],[\"annotation.routes.moderated.title\"],null],[28,[37,2],[\"annotation.routes.moderated.description\"],null],[28,[37,2],[\"annotation.routes.moderated.buttonTitle\"],[[\"count\"],[[30,0,[\"model\",\"pendingCount\"]]]]],\"annotations.moderated\",[28,[37,1],[\"annotation\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"hub\"],false,[\"layout/module-hub\",\"icon\",\"i18n-t\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/annotations/index.hbs",
    "isStrictMode": false
  });
});