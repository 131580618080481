define("plutof/components/tables/-list-view/order-group-field", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Tables::-ListView::Order
      @field={{@field}}
      @active={{is-equal @key @orderedBy}}
      @ascending={{@ascending}}
      @sortBy={{fn @sortBy @key}}
      @label={{@label}}
      @disabled={{@disabled}}
  >
      {{yield}}
  </Tables::-ListView::Order>
  
  */
  {
    "id": "1Lh83LTS",
    "block": "[[[8,[39,0],null,[[\"@field\",\"@active\",\"@ascending\",\"@sortBy\",\"@label\",\"@disabled\"],[[30,1],[28,[37,1],[[30,2],[30,3]],null],[30,4],[28,[37,2],[[30,5],[30,2]],null],[30,6],[30,7]]],[[\"default\"],[[[[1,\"\\n    \"],[18,8,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@key\",\"@orderedBy\",\"@ascending\",\"@sortBy\",\"@label\",\"@disabled\",\"&default\"],false,[\"tables/-list-view/order\",\"is-equal\",\"fn\",\"yield\"]]",
    "moduleName": "plutof/components/tables/-list-view/order-group-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});