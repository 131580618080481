define("plutof/components/analysis/ml-sh/predictions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ol class="analysis-model-predictions">
      {{yield (hash item=(component "analysis/ml-sh/-predictions-item"))}}
  </ol>
  
  */
  {
    "id": "hyLyBqDe",
    "block": "[[[10,\"ol\"],[14,0,\"analysis-model-predictions\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"analysis/ml-sh/-predictions-item\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/analysis/ml-sh/predictions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});