define("plutof/components/search/filters/person", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="filter-input" data-input={{@filter.config.label}}>
      <Common::Ui::ControlLabel>
          <:label>
              <TooltipWrapper as |t|>
                  <div class="plutof-label">
                      {{@filter.config.label}}
                  </div>
  
                  <span id="{{t.tid}}" class="wrapper-tooltip">
                      {{#if @filter.config.help_text}}
                          {{@filter.config.help_text}}
                      {{else}}
                          {{@filter.config.label}}
                      {{/if}}
  
                      {{i18n-t "Agents.personTooltip"}}
                  </span>
              </TooltipWrapper>
          </:label>
  
          <:controls as |controls|>
              <controls.toggle
                  @checked={{@filter.operator}}
                  @toggle={{this.toggleSynonyms}}
              >
                  {{i18n-t "Search.includeSynonyms"}}
              </controls.toggle>
          </:controls>
      </Common::Ui::ControlLabel>
  
      <AutoComplete::BindMultiple @value={{@filter.value}} as |add|>
          <Agent::Person::AutoComplete
              @update={{add}}
              class="filter-input-ac" />
      </AutoComplete::BindMultiple>
  </div>
  
  */
  {
    "id": "P6yuQx2X",
    "block": "[[[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"label\",\"controls\"],[[[[1,\"\\n            \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n                \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                    \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n                \"],[13],[1,\"\\n\\n                \"],[10,1],[15,1,[29,[[30,2,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n\"],[41,[30,1,[\"config\",\"help_text\"]],[[[1,\"                        \"],[1,[30,1,[\"config\",\"help_text\"]]],[1,\"\\n\"]],[]],[[[1,\"                        \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n\"]],[]]],[1,\"\\n                    \"],[1,[28,[35,3],[\"Agents.personTooltip\"],null]],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[2]]]]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[30,3,[\"toggle\"]],null,[[\"@checked\",\"@toggle\"],[[30,1,[\"operator\"]],[30,0,[\"toggleSynonyms\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"Search.includeSynonyms\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@value\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],[[24,0,\"filter-input-ac\"]],[[\"@update\"],[[30,4]]],null],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@filter\",\"t\",\"controls\",\"add\"],false,[\"common/ui/control-label\",\"tooltip-wrapper\",\"if\",\"i18n-t\",\"auto-complete/bind-multiple\",\"agent/person/auto-complete\"]]",
    "moduleName": "plutof/components/search/filters/person.hbs",
    "isStrictMode": false
  });
  let SearchPersonFilter = (_class = class SearchPersonFilter extends _component2.default {
    toggleSynonyms() {
      this.args.filter.operator = this.args.filter.operator ? null : 'with_synonyms';
    }
  }, _applyDecoratedDescriptor(_class.prototype, "toggleSynonyms", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSynonyms"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchPersonFilter);
});