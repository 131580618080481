define("plutof/utils/structures", ["exports", "@ember/array", "@ember/utils"], function (_exports, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chop = chop;
  _exports.compareVia = compareVia;
  _exports.concat = concat;
  _exports.concatMap = concatMap;
  _exports.flatten = flatten;
  _exports.group = group;
  _exports.list_init = list_init;
  _exports.list_tail = list_tail;
  _exports.makeArray = makeArray;
  _exports.mapGetWithPresense = mapGetWithPresense;
  _exports.mapSet = mapSet;
  _exports.objectToMap = objectToMap;
  _exports.reversed = reversed;
  _exports.select = select;
  _exports.set_difference = set_difference;
  _exports.set_intersection = set_intersection;
  _exports.sortByID = sortByID;
  _exports.split = split;
  _exports.uniqueVia = uniqueVia;
  _exports.zip = zip;
  // Returns set of elements in set a but not in b
  function set_difference(a, b) {
    return a.filter(function (member) {
      return !b.includes(member);
    });
  }

  // Returns set of element both in a and b
  function set_intersection(a, b) {
    return a.filter(function (member) {
      return b.includes(member);
    });
  }

  // [[a,b], c, [d,[e,f],g]] -> [a, b, c, d, e, f, g]
  function flatten(arr) {
    return arr.reduce(function (acc, x) {
      if ((0, _array.isArray)(x)) {
        x = flatten(x);
      }
      return acc.concat(x);
    }, []);
  }
  function list_init(arr) {
    return arr.slice(0, arr.length - 1);
  }
  function list_tail(arr) {
    return arr.slice(1);
  }

  // Splits <xs> into chunks of size <count>
  function chop(count, xs) {
    var rest = xs;
    var chunks = [];
    while (rest.length > 0) {
      chunks.push(rest.slice(0, count));
      rest = rest.slice(count);
    }
    return chunks;
  }
  function group(criterium, arr) {
    var grouped = {};
    arr.forEach(function (x) {
      var value = criterium(x);
      grouped[value] = grouped[value] || [];
      grouped[value].push(x);
    });
    return grouped;
  }

  // Non-desctructive reverse - for inplace just use xs.reverse()
  function reversed(xs) {
    var copy = xs.slice();
    copy.reverse();
    return copy;
  }
  function concat(arrays) {
    return Array.prototype.concat.apply([], arrays);
  }
  function concatMap(func, xs, context) {
    return concat(xs.map(func, context));
  }
  function zip(xs, ys) {
    const len = Math.max(xs.length, ys.length);
    let zipped = [];
    for (let i = 0; i < len; i++) {
      zipped.push([xs[i], ys[i]]);
    }
    return zipped;
  }
  function sortByID(records) {
    var arr = records.slice();
    arr.sort((x, y) => x.get('id') - y.get('id'));
    return arr;
  }
  function compareVia(fn) {
    return function (x, y) {
      const fx = fn(x);
      const fy = fn(y);
      if (fx === fy) {
        return 0;
      }
      return fx > fy ? 1 : -1;
    };
  }
  function makeArray(x) {
    if ((0, _array.isArray)(x)) {
      return x;
    }
    return (0, _utils.isNone)(x) ? [] : [x];
  }
  function uniqueVia(xs, hash = x => x) {
    let seen = new Set();
    let unique = [];
    for (let x of xs) {
      const xhash = hash(x);
      if (!seen.has(xhash)) {
        unique.push(x);
        seen.add(xhash);
      }
    }
    return unique;
  }
  function select(obj, fields) {
    let selection = {};
    for (const field of fields) {
      selection[field] = obj[field];
    }
    return selection;
  }
  function split(xs, predicate) {
    let matches = [];
    let others = [];
    for (const x of xs) {
      if (predicate(x)) {
        matches.push(x);
      } else {
        others.push(x);
      }
    }
    return [matches, others];
  }
  function mapSet(map, path, value) {
    const [key, ...rest] = path;
    if (rest.length > 0) {
      if (!map.has(key)) {
        map.set(key, new Map());
      }
      mapSet(map.get(key), rest, value);
    } else {
      map.set(key, value);
    }
  }
  function objectToMap(obj) {
    // Object.items not available
    const map = new Map();
    for (const key of Object.keys(obj)) {
      map.set(key, obj[key]);
    }
    return map;
  }
  function mapGetWithPresense(map, path) {
    if (path.length === 0) {
      return {
        present: true,
        value: map
      };
    }
    if (!map || !(map instanceof Map)) {
      return {
        present: false,
        value: undefined
      };
    }
    const [key, ...rest] = path;
    if (!map.has(key)) {
      return {
        present: false,
        value: undefined
      };
    }
    return mapGetWithPresense(map.get(key), rest);
  }
});