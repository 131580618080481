define("plutof/controllers/analysis/view", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "plutof/controllers/analysis/utils", "plutof/misc/fileupload", "plutof/utils/reflection"], function (_exports, _controller, _object, _computed, _service, _utils, _fileupload, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AnalysisViewController = (_dec = (0, _object.computed)('model.run.status', 'model.type.report'), _dec2 = (0, _computed.notEmpty)('model.run.results_file.id'), _class = class AnalysisViewController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      // Only owner can view analyses
      _defineProperty(this, "permissions", {
        canModify: true,
        canDelete: true
      });
      _initializerDefineProperty(this, "canDownloadResults", _descriptor2, this);
    }
    start() {
      return (0, _utils.startRun)(this.store, this.ajax, this.model.run);
    }
    get canGenerateReport() {
      return this.model.type.report && this.model.run.status === 'ready';
    }
    generateReport() {
      const recordURL = (0, _reflection.get_record_url)(this.model.run);
      window.open(recordURL + 'report/', '_blank');
    }
    async downloadResults() {
      const file = await this.model.run.results_file;
      if (!file) {
        return;
      }
      (0, _fileupload.downloadFile)(file.download_links.link);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canGenerateReport", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "canGenerateReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateReport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateReport"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canDownloadResults", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "downloadResults", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadResults"), _class.prototype), _class);
  var _default = _exports.default = AnalysisViewController;
});