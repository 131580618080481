define("plutof/components/multiple-selection", ["exports", "@ember/array", "@ember/component", "@ember/object", "@ember/runloop", "@ember/utils", "@ember-decorators/object"], function (_exports, _array, _component, _object, _runloop, _utils, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MultipleSelection = (_dec = (0, _object2.observes)('selectedValue'), _class = class MultipleSelection extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "prompt", null);
      _defineProperty(this, "content", null);
      _defineProperty(this, "optionValuePath", null);
      _defineProperty(this, "optionLabelPath", null);
      _defineProperty(this, "selectedValue", null);
      _defineProperty(this, "selections", (0, _array.A)());
      _defineProperty(this, "selectClass", 'form-control');
    }
    init() {
      super.init(...arguments);
      this.adder();
    }
    adder() {
      var selection = this.selectedValue;
      if ((0, _utils.isPresent)(selection)) {
        if ((0, _utils.isNone)(this.selections)) {
          this.set('selections', []);
        }
        let record = selection;
        const add = this.add;
        if (!(0, _utils.isNone)(add)) {
          record = add(selection);
        }
        if (!this.selections.includes(record)) {
          this.selections.pushObject(record);
          if (this.changed) {
            this.changed();
          }
        }
        (0, _runloop.next)(this, function () {
          this.set('selectedValue', null);
        });
      }
    }
    removeRecord(record) {
      const remove = this.remove;
      if ((0, _utils.isNone)(remove)) {
        this.selections.removeObject(record);
      } else {
        remove(record);
      }
      if (this.changed) {
        this.changed();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "adder", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "adder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRecord", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeRecord"), _class.prototype), _class);
  var _default = _exports.default = MultipleSelection;
});