define("plutof/components/transaction/utils", ["exports", "@ember/utils", "ember-data", "plutof/misc/abstract", "plutof/utils/has-many", "plutof/utils/store"], function (_exports, _utils, _emberData, _abstract, _hasMany, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ITEM_TYPES = void 0;
  _exports.TransactionHasManyViewMixin = TransactionHasManyViewMixin;
  _exports.getTransactionItemType = getTransactionItemType;
  function TransactionHasManyViewMixin(model) {
    return (0, _hasMany.HasManyViewMixin)(model, {
      parentField: 'transaction'
    });
  }
  function include(...fields) {
    let spec = {};
    for (let field of fields) {
      spec[field] = true;
    }
    return spec;
  }
  function additionalIDs(model, queryParams) {
    return function (store, item) {
      if ((0, _utils.isNone)(item)) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _abstract.wrap_as_promise)(item.get('target')).then(target => {
          if ((0, _utils.isNone)(target)) {
            return [];
          }
          return (0, _store.query)(store, model, queryParams(target));
        })
      });
    };
  }
  const ITEM_TYPES = _exports.ITEM_TYPES = [{
    code: 'specimen',
    label: 'General.Specimens',
    idLabel: 'transactions.itemTargetLabel.specimen',
    model: 'taxonoccurrence/specimen/specimen',
    contentType: 'specimen/specimen',
    viewRoute: 'specimen.view',
    contains: include('additionalID', 'taxon'),
    clipboard: 'specimens',
    getAdditionaldentifiers: additionalIDs('taxonoccurrence/specimen/additionalidentifier', specimen => ({
      specimen: specimen.get('id')
    }))
  }, {
    code: 'materialsample',
    label: 'General.materialSamples',
    idLabel: 'transactions.itemTargetLabel.materialsample',
    model: 'taxonoccurrence/materialsample/materialsample',
    contentType: 'materialsample/materialsample',
    viewRoute: 'materialsample.view',
    contains: include(),
    clipboard: 'materialsamples'
  }, {
    code: 'livingspecimen',
    label: 'General.LivingC',
    idLabel: 'transactions.itemTargetLabel.livingspecimen',
    model: 'taxonoccurrence/livingculture/strain',
    contentType: 'livingculture/strain',
    viewRoute: 'livingspecimen.view',
    contains: include('taxon', 'deposited_as'),
    clipboard: 'strains'
  }, {
    code: 'collectionobject',
    label: 'General.Photobank',
    idLabel: 'transactions.itemTargetLabel.photobank',
    model: 'photobank/collectionobject',
    contentType: 'photobank/collectionobject',
    viewRoute: 'photobank.view',
    contains: include('additionalID'),
    clipboard: 'collectionobjects',
    getAdditionaldentifiers: additionalIDs('photobank/additionalidentifier', object => ({
      collection_object: object.get('id')
    }))
  }];
  function getTransactionItemType(code) {
    return ITEM_TYPES.findBy('code', code);
  }
});