define("plutof/mixins/clear-ui-route", ["exports", "@ember/object/evented", "@ember/object/mixin"], function (_exports, _evented, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClearUIRoute = _mixin.default.create({
    activate: (0, _evented.on)('activate', function () {
      this._super();
      var appCtrl = this.controllerFor('application');
      appCtrl.set('emptyUI', true);
    }),
    deactivate: (0, _evented.on)('deactivate', function () {
      this._super();
      var appCtrl = this.controllerFor('application');
      appCtrl.set('emptyUI', false);
    })
  });
  var _default = _exports.default = ClearUIRoute;
});