define("plutof/components/tables/controls/row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="table-controls">
      <div class="table-controls__left">
          <Layout::SeparatedRow>
              {{yield to="left"}}
          </Layout::SeparatedRow>
      </div>
  
      <div class="table-controls__right">
          <Layout::SeparatedRow>
              {{yield to="right"}}
          </Layout::SeparatedRow>
      </div>
  </div>
  
  */
  {
    "id": "hAPiHOef",
    "block": "[[[10,0],[14,0,\"table-controls\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"table-controls__left\"],[12],[1,\"\\n        \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n            \"],[18,1,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"table-controls__right\"],[12],[1,\"\\n        \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n            \"],[18,2,null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&left\",\"&right\"],false,[\"layout/separated-row\",\"yield\"]]",
    "moduleName": "plutof/components/tables/controls/row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});