define("plutof/components/tables/list-route", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Headerless data-test="Tables::ListRoute" ...attributes>
      <Tables::List
          @records={{@records}}
          as |Field record|
      >
          {{yield Field record (hash
              modified=(component "tables/-list-view/modified" Field=Field record=record)
              relatedObjects=(component "tables/-list-view/related-objects" Field=Field record=record open=this.openGallery)
              clipboard=(component "tables/-list-view/clipboard" Field=Field record=record)
              access=(component "tables/-list-view/access" Field=Field record=record)
              tristate=(component "tables/-list-view/tristate-checkbox" Field=Field)
              order=(component "tables/-list-view/order" field=Field)
              orderGroup=(component "tables/-list-view/order-group" field=Field pagination=@pagination)
          )}}
      </Tables::List>
  </PlutofPanel::Headerless>
  
  {{! TODO: DRY with Filerepository::Gallery, need another comp that just takes a file instead of endpoint-specific item, which is stubbed here}}
  <Gallery::Gallery
      @open={{this.galleryOpen}}
      @close={{this.closeGallery}}
  >
      <:preview>
          <Filerepository::Gallery::-Preview
              @filename={{this.galleryFile.identifier}}
              @type={{this.galleryFile.type}}
              @links={{this.galleryFile.download_links}} />
      </:preview>
  
      <:footer-left as |footer|>
          <Filerepository::Gallery::-FooterLeft
              @footer={{footer}}
              @file={{this.galleryFile}} />
      </:footer-left>
  
      <:footer-right as |footer|>
          <Filerepository::Gallery::-FooterRight
              @footer={{footer}}
              @file={{this.galleryFile}}
              @close={{this.closeGallery}} />
      </:footer-right>
  </Gallery::Gallery>
  
  */
  {
    "id": "olzfR3U+",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Tables::ListRoute\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[18,8,[[30,3],[30,4],[28,[37,3],null,[[\"modified\",\"relatedObjects\",\"clipboard\",\"access\",\"tristate\",\"order\",\"orderGroup\"],[[50,\"tables/-list-view/modified\",0,null,[[\"Field\",\"record\"],[[30,3],[30,4]]]],[50,\"tables/-list-view/related-objects\",0,null,[[\"Field\",\"record\",\"open\"],[[30,3],[30,4],[30,0,[\"openGallery\"]]]]],[50,\"tables/-list-view/clipboard\",0,null,[[\"Field\",\"record\"],[[30,3],[30,4]]]],[50,\"tables/-list-view/access\",0,null,[[\"Field\",\"record\"],[[30,3],[30,4]]]],[50,\"tables/-list-view/tristate-checkbox\",0,null,[[\"Field\"],[[30,3]]]],[50,\"tables/-list-view/order\",0,null,[[\"field\"],[[30,3]]]],[50,\"tables/-list-view/order-group\",0,null,[[\"field\",\"pagination\"],[[30,3],[30,5]]]]]]]]],[1,\"\\n    \"]],[3,4]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@open\",\"@close\"],[[30,0,[\"galleryOpen\"]],[30,0,[\"closeGallery\"]]]],[[\"preview\",\"footer-left\",\"footer-right\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@filename\",\"@type\",\"@links\"],[[30,0,[\"galleryFile\",\"identifier\"]],[30,0,[\"galleryFile\",\"type\"]],[30,0,[\"galleryFile\",\"download_links\"]]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,7],null,[[\"@footer\",\"@file\"],[[30,6],[30,0,[\"galleryFile\"]]]],null],[1,\"\\n    \"]],[6]],[[[1,\"\\n        \"],[8,[39,8],null,[[\"@footer\",\"@file\",\"@close\"],[[30,7],[30,0,[\"galleryFile\"]],[30,0,[\"closeGallery\"]]]],null],[1,\"\\n    \"]],[7]]]]],[1,\"\\n\"]],[\"&attrs\",\"@records\",\"Field\",\"record\",\"@pagination\",\"footer\",\"footer\",\"&default\"],false,[\"plutof-panel/headerless\",\"tables/list\",\"yield\",\"hash\",\"component\",\"gallery/gallery\",\"filerepository/gallery/-preview\",\"filerepository/gallery/-footer-left\",\"filerepository/gallery/-footer-right\"]]",
    "moduleName": "plutof/components/tables/list-route.hbs",
    "isStrictMode": false
  });
  let ListRouteTable = (_class = class ListRouteTable extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "galleryFile", _descriptor2, this);
      _initializerDefineProperty(this, "galleryOpen", _descriptor3, this);
    }
    async openGallery(listEntry) {
      this.galleryFile = await this.store.findRecord('filerepository/file', listEntry.id);
      this.galleryOpen = true;
    }
    closeGallery() {
      this.galleryOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "galleryFile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "galleryOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openGallery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeGallery"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ListRouteTable);
});