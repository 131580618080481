define("plutof/components/related-objects/content/sequence-linked-to", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _object, _service, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.module}}
      <LinkTo @route={{this.module.routes.view}} @model={{@linkedToID}}>
          <span class={{this.module.icon}}></span>
          {{@linkedTo.name}}
      </LinkTo>
  {{/if}}
  
  */
  {
    "id": "tpqz+GFN",
    "block": "[[[41,[30,0,[\"module\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@linkedToID\",\"@linkedTo\"],false,[\"if\",\"link-to\"]]",
    "moduleName": "plutof/components/related-objects/content/sequence-linked-to.hbs",
    "isStrictMode": false
  });
  let RelatedSequenceLinkedTo = (_dec = (0, _object.computed)('args.linkedTo.content_type'), _class = class RelatedSequenceLinkedTo extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get module() {
      return _modules.default[this.args.linkedTo.content_type];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "module", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "module"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RelatedSequenceLinkedTo);
});