define("plutof/templates/components/load-more", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/BrDQBkH",
    "block": "[[[10,1],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\\n\"],[41,[33,2],[[[41,[33,3],[[[1,\"            \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],null]],[]],[[[41,[33,4],[[[41,[51,[33,6]],[[[1,\"                \"],[11,\"button\"],[16,\"disabled\",[36,2]],[24,0,\"btn btn-xs btn-link\"],[4,[38,7],[[30,0],\"more\"],null],[12],[1,\"\\n                    \"],[1,[28,[35,8],[\"General.loadMore\"],null]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"if\",\"isLoading\",\"showInterface\",\"showControls\",\"unless\",\"isLastPage\",\"action\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/load-more.hbs",
    "isStrictMode": false
  });
});