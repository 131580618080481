define("plutof/components/annotation/-fields/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{i18n-t "annotation.annotation"}}>
      <Annotation::-Link
          @annotation={{@annotation}}
          @highlight={{@highlight}} />
  </@field>
  
  */
  {
    "id": "eppCNby1",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"annotation.annotation\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@annotation\",\"@highlight\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@field\",\"@annotation\",\"@highlight\"],false,[\"i18n-t\",\"annotation/-link\"]]",
    "moduleName": "plutof/components/annotation/-fields/link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});