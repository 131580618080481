define("plutof/components/related-objects/content/transactions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::Content @pagination={{@pagination}} as |Field transaction|>
      <Field @label={{backend-translations/label "transaction.number"}}>
          <LinkTo @route="transaction.view" @model={{transaction.id}}>
              {{transaction.number}}
          </LinkTo>
      </Field>
  
      <Field @label={{i18n-t "transactions.type"}}>
          {{transaction.type}}
      </Field>
  
      <Field @label={{backend-translations/label "transaction.start_date"}}>
          {{formatted-date transaction.start_date}}
      </Field>
  
      <Field @label={{backend-translations/label "transaction.expected_end_date"}}>
          <Transaction::ReturnDate @transaction={{transaction}} />
      </Field>
  
      <Field @label={{i18n-t "transactions.agents"}}>
          {{transaction.agents}}
      </Field>
  
      <Field @label={{backend-translations/label "transaction.is_locked"}}>
          {{read-only-boolean transaction.is_locked}}
      </Field>
  
      <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
          {{formatted-date transaction.updated_at}}
      </Field>
  </RelatedObjects::Content>
  
  */
  {
    "id": "O/6LE1u+",
    "block": "[[[8,[39,0],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"transaction.number\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"transaction.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"number\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"transactions.type\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"type\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"transaction.start_date\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[[30,3,[\"start_date\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"transaction.expected_end_date\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@transaction\"],[[30,3]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"transactions.agents\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"agents\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"transaction.is_locked\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[[30,3,[\"is_locked\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[[30,3,[\"updated_at\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@pagination\",\"Field\",\"transaction\"],false,[\"related-objects/content\",\"backend-translations/label\",\"link-to\",\"i18n-t\",\"formatted-date\",\"transaction/return-date\",\"read-only-boolean\"]]",
    "moduleName": "plutof/components/related-objects/content/transactions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});