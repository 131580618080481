define("plutof/templates/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "CkAWIcjh",
    "block": "[[[10,0],[14,0,\"common-public-page\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"errorPage.notFoundTitle\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[10,2],[14,0,\"common-public-page__text\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"errorPage.notFoundMsg\"],null]],[1,\"\\n        \"],[8,[39,2],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"errorPage.backHome\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"common/legend\",\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/templates/not-found.hbs",
    "isStrictMode": false
  });
});