define("plutof/components/transaction/view/objects/ordering-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
     {{on "click" this._updateOrdering}}
     disabled={{this.pagination.isLoading}}
     class="btn-link {{if this.isActive 'table-header-btn' 'table-header-btn table-header-btn--inactive'}}"
  >
      {{#if (and this.isActive this.pagination.isLoading)}}
          <span class={{icon "loading"}}></span>
      {{else if this.isActive}}
          <span class="fas {{if this.isAscending 'fa-sort-up' 'fa-sort-down'}}"></span>
      {{else}}
          <span class="fas fa-sort"></span>
      {{/if}}
  </button>
  
  */
  {
    "id": "ihkzeoQa",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"pagination\",\"isLoading\"]]],[16,0,[29,[\"btn-link \",[52,[30,0,[\"isActive\"]],\"table-header-btn\",\"table-header-btn table-header-btn--inactive\"]]]],[4,[38,1],[\"click\",[30,0,[\"_updateOrdering\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"isActive\"]],[30,0,[\"pagination\",\"isLoading\"]]],null],[[[1,\"        \"],[10,1],[15,0,[28,[37,3],[\"loading\"],null]],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isActive\"]],[[[1,\"        \"],[10,1],[15,0,[29,[\"fas \",[52,[30,0,[\"isAscending\"]],\"fa-sort-up\",\"fa-sort-down\"]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"fas fa-sort\"],[12],[13],[1,\"\\n    \"]],[]]]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"on\",\"and\",\"icon\"]]",
    "moduleName": "plutof/components/transaction/view/objects/ordering-button.hbs",
    "isStrictMode": false
  });
  let TransactionViewObjectsOrderingButton = _exports.default = (_class = class TransactionViewObjectsOrderingButton extends _component2.default {
    get pagination() {
      return this.args.pagination;
    }
    get currentOrdering() {
      return this.pagination.get('filters.ordering');
    }
    get isActive() {
      return this.args.ordering === this.orderingProperty;
    }
    get isAscending() {
      if (!this.pagination) {
        return false;
      }
      const ordering = this.currentOrdering;
      return (0, _utils.isPresent)(ordering) && !ordering.startsWith('-');
    }
    get orderingProperty() {
      if (!this.pagination) {
        return null;
      }
      const ordering = this.currentOrdering;
      return this.isAscending ? ordering : ordering.substring(1);
    }
    _updateOrdering() {
      const ordering = this.args.ordering;
      let newOrdering = ordering;
      if (ordering === this.currentOrdering) {
        newOrdering = this.isAscending ? `-${ordering}` : ordering;
      }
      this.pagination.set('filters.ordering', newOrdering);
      this.pagination.switchPage(1);
      this.pagination.reload();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_updateOrdering", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_updateOrdering"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionViewObjectsOrderingButton);
});