define("plutof/mixins/component-validations", ["exports", "@ember/object", "@ember/object/mixin", "@ember/runloop", "@ember/utils"], function (_exports, _object, _mixin, _runloop, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ValidationsMixin = _mixin.default.create({
    validationChildren: [],
    init() {
      this._super(...arguments);
      this.createChildren();
      this.validityChanged();
      this.addObserver('validations.isValid', 'validationContext', () => this.validityChanged());
    },
    notifyValidity() {
      var valid = this.get('validations.isValid');
      var context = this.validationContext;
      if (!(0, _utils.isNone)(valid) && !(0, _utils.isNone)(context)) {
        context.set('validity', valid);
      }
    },
    createChildren: (0, _object.observer)('validationContext', function () {
      if ((0, _utils.isNone)(this.validationContext)) {
        return;
      }
      this.validationChildren.forEach(child => {
        if ((0, _utils.typeOf)(child) === 'object') {
          this.validationContext.addChild(child.name, this.i18n.t(child.label));
        } else {
          this.validationContext.addChild(child, child);
        }
      });
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _runloop.once)(this, 'notifyValidity');
      return true;
    },
    willDestroyElement() {
      this._super(...arguments);
      const context = this.validationContext;
      if ((0, _utils.isNone)(context) || context.isDestroyed) {
        return;
      }
      context.set('validity', true);
      context.set('anchor', null);

      // XXX: Basically validationContext.destroy() without actually destroying the root
      // TODO: Can unite? Doesn't look so, subcomponent has no business destroying the object which
      // was created by a parent component
      context.subcontexts.forEach(function (ctx) {
        context.removeChild(ctx.get('name'));
        ctx.destroy();
      });
      context.subcontexts.clear();
      context.children.clear();
    },
    willRender() {
      this._super(...arguments);
      if (!(0, _utils.isNone)(this.validationContext)) {
        this.set('validationContext.anchor', this.elementId);
      }
    },
    validityChanged() {
      (0, _runloop.once)(this, 'notifyValidity');
    }
  });
  var _default = _exports.default = ValidationsMixin;
});