define("plutof/templates/collection/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "j2j755gY",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoContent\"],[[28,[37,1],[\"collection\"],null],[28,[37,2],[\"collection.list.title\"],null],\"collection.add\",[28,[37,3],[\"goBack\"],null],[30,0,[\"model\",\"pagination\"]],\"information/content/collection/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"collection.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"agent_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"collection\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"route-action\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/collection/index.hbs",
    "isStrictMode": false
  });
});