define("plutof/controllers/referencebased/add", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "rsvp", "plutof/mixins/add-controller", "plutof/mixins/component-validations", "plutof/utils/cloning", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _service, _rsvp, _addController, _componentValidations, _cloning, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RefbasedAddController = (_dec = (0, _computed.reads)('model.tableData.entries.firstObject.occurrence'), _dec2 = (0, _computed.notEmpty)('template_id'), _class = class RefbasedAddController extends _controller.default.extend(_addController.default, _cloning.ControllerCloningMixin, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      _defineProperty(this, "queryParams", ['template_id', 'clone_area', 'clone_event', 'area_id', 'event_id', 'project_id', 'reference_id']);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "clone_area", null);
      _defineProperty(this, "clone_event", null);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "reference_id", null);
      _defineProperty(this, "validationChildren", [{
        name: 'sharedFields',
        label: 'General.generalData'
      }, {
        name: 'occurrences',
        label: 'ReferenceBased.addPanel.title'
      }, {
        name: 'areaEvent',
        label: 'General.samplingAreaEvent'
      }, {
        name: 'access',
        label: 'General.access'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }]);
      _initializerDefineProperty(this, "primaryOccurrence", _descriptor2, this);
      _initializerDefineProperty(this, "preserveZoom", _descriptor3, this);
    }
    async _save() {
      await this.model.areaEventData.save();
      await this.model.tableData.save({
        reference: this.model.reference,
        sample: this.model.areaEventData,
        access: this.model.accessRights
      });
      const occurrences = this.model.tableData.entries.map(entry => entry.occurrence);
      await this.model.itemData.save(occurrences, this.model.accessRights);
      await _rsvp.default.all([this.model.layers.save(occurrences)]);
      this.listRoutes.reset('referencebased/occurrence');
    }

    // TODO: ControllerCloningMixin should refer to _save. Better yet - remove mixin, use
    // the func directly or something
    save() {
      // TODO: Check that project is set correctly on all cases (including passing
      // area or project through QPs)
      return (0, _notifications.notifyProcess)(this._save(), this.i18n).then(() => {
        this.set('routeHasBeenLoaded', false);
        this.send('goBack');
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "primaryOccurrence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preserveZoom", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = RefbasedAddController;
});