define("plutof/templates/components/photobank/agents-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "9LqfFpTx",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Photobank::AgentsForm\"]],[[\"@title\"],[[28,[37,1],[\"Photobank.agents\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n                \"],[8,[39,3],null,[[\"@path\"],[\"agent.item.agent\"]],null],[1,\"\\n\\n\"],[6,[39,4],[[30,0,[\"newAgent\"]]],null,[[\"default\"],[[[[1,\"                    \"],[8,[39,5],[[24,\"data-test\",\"agent\"]],[[\"@value\",\"@update\",\"@params\"],[[30,1],[30,2],[28,[37,6],null,[[\"inputClasses\"],[\"form-control control\"]]]]],null],[1,\"\\n\"]],[1,2]]]]],[1,\"            \"],[13],[1,\"\\n\\n\"],[1,\"            \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n                \"],[11,\"button\"],[16,\"disabled\",[28,[37,7],[[30,0,[\"newAgent\"]]],null]],[24,0,\"btn btn-default control\"],[24,\"data-test\",\"add\"],[4,[38,8],[\"click\",[30,0,[\"addItem\"]]],null],[12],[1,\"\\n                    \"],[10,1],[15,0,[28,[37,9],[\"ok\"],null]],[12],[13],[1,\"\\n                    \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,10],null,[[\"@records\",\"@remove\"],[[30,3],[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"representation\"]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"value\",\"update\",\"@items\",\"item\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"agent-label-tooltip\",\"async/bind-relation\",\"agent/auto-complete\",\"hash\",\"not\",\"on\",\"icon\",\"common/record-list\"]]",
    "moduleName": "plutof/templates/components/photobank/agents-form.hbs",
    "isStrictMode": false
  });
});