define("plutof/templates/components/invalid-panels", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HsLXV/G0",
    "block": "[[[41,[30,0,[\"validationContext\",\"invalidContexts\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"invalidSubcontexts\"]]],null]],null],null,[[[41,[30,1,[\"label\"]],[[[1,\"                \"],[8,[39,3],[[24,0,\"plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[28,[37,4],[\"panel.invalidPanelTooltip\"],[[\"name\"],[[30,1,[\"label\"]]]]]]],[[\"default\"],[[[[1,\"\\n                    \"],[11,\"strong\"],[24,0,\"invalid-panel clickable\"],[4,[38,5],[[30,0],\"gotoAnchor\",[30,1]],null],[12],[1,\"\\n                        \"],[1,[30,1,[\"label\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\\n\"],[1,\"                     \\n                \"]],[]]]]],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"context\"],false,[\"if\",\"each\",\"-track-array\",\"tool-tip\",\"i18n-t\",\"action\"]]",
    "moduleName": "plutof/templates/components/invalid-panels.hbs",
    "isStrictMode": false
  });
});