define("plutof/components/unite/communication", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "UniteSH.shView.communication"}}>
      <table>
          {{#each @dois as |version|}}
              <tr>
                  <td class="field-name">
                      {{i18n-t "General.Doi"}}
  
                      {{#unless version.current}}
                          ({{i18n-t "UniteSH.shView.previousDOIVersion"}})
                      {{/unless}}
                  </td>
  
                  <td class="field-value">
                      {{#each version.dois as |doi|}}
                          <a href={{doi.doi}}>
                              {{doi.doi}}
                          </a>
  
                          <br>
                      {{/each}}
                  </td>
              </tr>
          {{/each}}
  
          <tr>
              <td class="field-name">
                  {{i18n-t "Taxonomy.taxonName"}}
              </td>
  
              <td class="field-value">
                  {{#if @taxon}}
                      <LinkTo @route="taxonomy.view" @model={{@taxon.id}}>
                          {{@taxon.taxon_name}}
                      </LinkTo>
                  {{/if}}
              </td>
          </tr>
      </table>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "tv1doG6v",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"UniteSH.shView.communication\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n                \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n                    \"],[1,[28,[35,1],[\"General.Doi\"],null]],[1,\"\\n\\n\"],[41,[51,[30,2,[\"current\"]]],[[[1,\"                        (\"],[1,[28,[35,1],[\"UniteSH.shView.previousDOIVersion\"],null]],[1,\")\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n\\n                \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2,[\"dois\"]]],null]],null],null,[[[1,\"                        \"],[10,3],[15,6,[30,3,[\"doi\"]]],[12],[1,\"\\n                            \"],[1,[30,3,[\"doi\"]]],[1,\"\\n                        \"],[13],[1,\"\\n\\n                        \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[3]],null],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[\"Taxonomy.taxonName\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"                    \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"taxonomy.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[30,4,[\"taxon_name\"]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dois\",\"version\",\"doi\",\"@taxon\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"each\",\"-track-array\",\"unless\",\"if\",\"link-to\"]]",
    "moduleName": "plutof/components/unite/communication.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});