define("plutof/templates/components/import/import-progress", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6ElCVDr3",
    "block": "[[[10,0],[15,0,[29,[\"import-progress__part import-progress__part--created \",[52,[33,1],\"import-progress__part--notempty\"]]]],[15,5,[36,2]],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"        \"],[1,[28,[35,3],[\"Import.progress.created\"],null]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"import-progress__part import-progress__part--ignored \",[52,[33,4],\"import-progress__part--notempty\"]]]],[15,5,[36,5]],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"        \"],[1,[28,[35,3],[\"Import.progress.ignored\"],null]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"import-progress__part import-progress__part--unprocessed\"],[15,5,[36,6]],[12],[1,\"\\n\"],[41,[33,7],[[[1,\"        \"],[1,[28,[35,3],[\"Import.progress.unprocessed\"],null]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"created\",\"createdPercentage\",\"i18n-t\",\"ignored\",\"ignoredPercentage\",\"unprocessedPercentage\",\"unprocessed\"]]",
    "moduleName": "plutof/templates/components/import/import-progress.hbs",
    "isStrictMode": false
  });
});