define("plutof/components/map/selectable-cluster-map", ["exports", "@ember/utils", "plutof/components/map/cluster-map"], function (_exports, _utils, _clusterMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SelectableClusterMap extends _clusterMap.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "popupEnabled", true);
      _defineProperty(this, "selectionInput", null);
      _defineProperty(this, "selectedFeature", null);
    }
    reinitializeMap() {
      super.reinitializeMap(...arguments);
      if (!(0, _utils.isNone)(this.get('attrs.selectionInput.value'))) {
        this.setMapSelection(this.get('attrs.selectionInput.value'));
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (!(0, _utils.isNone)(this.get('attrs.selectionInput.value'))) {
        this.setMapSelection(this.get('attrs.selectionInput.value'));
      }
    }
    setMapSelection(id) {
      const select = this.get('selectControl.select');
      if (!(0, _utils.isNone)(select)) {
        select.getFeatures().clear();
        if (!(0, _utils.isNone)(id)) {
          const feature = this.clusteredFeatureSource.getFeatureById(id);
          if (!(0, _utils.isNone)(feature)) {
            select.getFeatures().push(feature);
            this.focusOn(feature.getGeometry(), false);
          }
        }
      }
    }
    showSelectionInPopup(selection) {
      if (selection.length === 1 && selection[0].getId() === this.get('attrs.selectionInput.value')) {
        this.hidePopup();
      } else {
        super.showSelectionInPopup(selection);
      }
    }
    zoomToIncludeEveryFeature() {
      super.zoomToIncludeEveryFeature(...arguments);
      var selectedID = this.get('attrs.selectionInput.value');
      if (!(0, _utils.isNone)(selectedID)) {
        var feature = this.featureSource.getFeatureById(selectedID);
        if (!(0, _utils.isNone)(feature)) {
          this.focusOn(feature.getGeometry(), false);
        }
      }
    }
  }
  var _default = _exports.default = SelectableClusterMap;
});