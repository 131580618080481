define("plutof/templates/components/unite/sh-statistics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1QzWOyqW",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.Statistics\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[\"DoiSHref.minimumDistance\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n                \"],[1,[30,1,[\"threshold\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[\"DoiSHref.nrOfSeqInSH\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n                \"],[1,[30,2]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@threshold\",\"@count\"],false,[\"plutof-panel/simple\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/unite/sh-statistics.hbs",
    "isStrictMode": false
  });
});