define("plutof/templates/profile/groups/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4B5MQpEx",
    "block": "[[[10,0],[14,0,\"route-marker-profile-groups\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.groups.list\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@memberships\"],[[30,0,[\"model\",\"memberships\"]]]],null],[1,\"\\n\"]],[],false,[\"common/legend\",\"i18n-t\",\"profile/groups/user-groups\"]]",
    "moduleName": "plutof/templates/profile/groups/index.hbs",
    "isStrictMode": false
  });
});