define("plutof/components/annotation/unreviewed-annotations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/models/annotation/annotation"], function (_exports, _component, _templateFactory, _component2, _object, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @accessRights.canModify}}
      {{#if this.haveUnreviewedAnnotations}}
          <div  class="unreviewed-annotations">
              <button
                  {{on "click" this.scrollToAnnotations}}
                  class="btn-reset"
              >
                  <span class={{icon "annotation"}}></span>
                  {{i18n-t "annotation.unreviewedAnnotations"}}
              </button>
          </div>
      {{/if}}
  {{/if}}
  
  */
  {
    "id": "U92oXfgA",
    "block": "[[[41,[30,1,[\"canModify\"]],[[[41,[30,0,[\"haveUnreviewedAnnotations\"]],[[[1,\"        \"],[10,0],[14,0,\"unreviewed-annotations\"],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn-reset\"],[4,[38,1],[\"click\",[30,0,[\"scrollToAnnotations\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,2],[\"annotation\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,3],[\"annotation.unreviewedAnnotations\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@accessRights\"],false,[\"if\",\"on\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/annotation/unreviewed-annotations.hbs",
    "isStrictMode": false
  });
  let UnreviewedAnnotations = (_dec = (0, _object.computed)('args.annotations.objects.@each.status'), _class = class UnreviewedAnnotations extends _component2.default {
    get haveUnreviewedAnnotations() {
      return this.args.annotations.objects.some(ann => ann.status === _annotation.AnnotationStatus.BeingReviewed);
    }
    scrollToAnnotations() {
      const annotations = document.querySelector('[data-anchor=annotations]');
      if (annotations) {
        window.scrollTo({
          top: annotations.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "haveUnreviewedAnnotations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "haveUnreviewedAnnotations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToAnnotations", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToAnnotations"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UnreviewedAnnotations);
});