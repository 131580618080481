define("plutof/templates/components/measurements/method-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "USVbxKt4",
    "block": "[[[10,0],[14,0,\"measurement-method__toggle\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[[30,0],\"toggle\"],null],[12],[1,\"\\n\"],[41,[30,0,[\"open\"]],[[[1,\"            \"],[10,1],[14,0,\"fas fa-caret-up\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,1],[14,0,\"fas fa-caret-down\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"open\"]],[[[1,\"    \"],[10,0],[14,0,\"measurement-method__popup\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"measurement-method__content\"],[12],[1,\"\\n\"],[1,\"            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[28,[35,2],[\"General.measurementMethod\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[8,[39,3],[[24,0,\"form-control\"]],[[\"@value\"],[[99,4,[\"@value\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"action\",\"if\",\"i18n-t\",\"flexible-textarea\",\"method\"]]",
    "moduleName": "plutof/templates/components/measurements/method-description.hbs",
    "isStrictMode": false
  });
});