define("plutof/transforms/decimal", ["exports", "@ember/utils", "@ember-data/serializer/transform"], function (_exports, _utils, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DecimalTransform extends _transform.default {
    deserialize(serialized) {
      return (0, _utils.isEmpty)(serialized) ? null : `${serialized}`;
    }
  }
  _exports.default = DecimalTransform;
});