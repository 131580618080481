define("plutof/components/taxonomy/taxon/view/taxon-descriptions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "Taxonomy.descriptionsTitle"}}
          @count={{@taxonDescriptions.length}} />
  
      <panel.innerPanels>
          {{#each @taxonDescriptions as |taxonDescription|}}
              <PlutofPanel::Inner>
                  <Taxonomy::Taxon::View::TaxonDescription
                      @taxonDescription={{taxonDescription}} />
              </PlutofPanel::Inner>
          {{/each}}
      </panel.innerPanels>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "UGmsiN6G",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"Taxonomy.descriptionsTitle\"],null],[30,2,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n                \"],[8,[39,5],null,[[\"@taxonDescription\"],[[30,3]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@taxonDescriptions\",\"taxonDescription\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"each\",\"-track-array\",\"plutof-panel/inner\",\"taxonomy/taxon/view/taxon-description\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/view/taxon-descriptions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});