define("plutof/components/analysis/view/wrapper-content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Analysis.sourceData"}}>
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc path="wrapper-run.source_file"}}
  
              <a href={{@run.source_file.download_links.link}} download>
                  <p class="view-analysis__general-data__source-file">
                      {{short-string @run.source_file.representation 25}}&nbsp;
                  </p>
              </a>
          </div>
  
          <div>
              {{plutof-labelc path="wrapper-run.results_file"}}
  
              <a href={{@run.results_file.download_links.link}} download>
                  <p class="view-analysis__general-data__results-file">
                      {{short-string @run.results_file.representation 25}}&nbsp;
                  </p>
              </a>
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "MasOqxFC",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Analysis.sourceData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"path\"],[\"wrapper-run.source_file\"]]]],[1,\"\\n\\n            \"],[10,3],[15,6,[30,1,[\"source_file\",\"download_links\",\"link\"]]],[14,\"download\",\"\"],[12],[1,\"\\n                \"],[10,2],[14,0,\"view-analysis__general-data__source-file\"],[12],[1,\"\\n                    \"],[1,[28,[35,4],[[30,1,[\"source_file\",\"representation\"]],25],null]],[1,\" \\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"path\"],[\"wrapper-run.results_file\"]]]],[1,\"\\n\\n            \"],[10,3],[15,6,[30,1,[\"results_file\",\"download_links\",\"link\"]]],[14,\"download\",\"\"],[12],[1,\"\\n                \"],[10,2],[14,0,\"view-analysis__general-data__results-file\"],[12],[1,\"\\n                    \"],[1,[28,[35,4],[[30,1,[\"results_file\",\"representation\"]],25],null]],[1,\" \\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@run\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"plutof-labelc\",\"short-string\"]]",
    "moduleName": "plutof/components/analysis/view/wrapper-content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});