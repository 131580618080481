define("plutof/helpers/repeat-counts", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function ([name, count]) {
    return count === 1 ? name : `${name} (${count})`;
  });
});