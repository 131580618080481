define("plutof/components/layout/split-row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! XXX: Accepting proposals for a better name}}
  <div class="split-row">
      <div>
          {{yield to="left"}}
      </div>
  
      {{#if (has-block "center")}}
          <div>
              {{yield to="center"}}
          </div>
      {{/if}}
  
      <div>
          {{yield to="right"}}
      </div>
  </div>
  
  */
  {
    "id": "Oc0/enL1",
    "block": "[[[10,0],[14,0,\"split-row\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[48,[30,2]],[[[1,\"        \"],[10,0],[12],[1,\"\\n            \"],[18,2,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&left\",\"&center\",\"&right\"],false,[\"yield\",\"if\",\"has-block\"]]",
    "moduleName": "plutof/components/layout/split-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});