define("plutof/misc/routing", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRoutingStatus = getRoutingStatus;
  _exports.reloadCurrentRoute = reloadCurrentRoute;
  class RoutingStatus extends _object.default {
    init() {
      this.set('routeLoading', false);
    }
  }
  const status = RoutingStatus.create();
  function getRoutingStatus() {
    return status;
  }

  // Hackz like this, because there is no way to trigger reset from component. Even controllers have a problem,
  // because it requires defining an action on the current route and triggering it from controller. You can't
  // even put this action into mixin or something
  function reloadCurrentRoute(router) {
    const container = (0, _application.getOwner)(router);
    const route = container.lookup(`route:${router.currentRoute.name}`);
    return route.refresh();
  }
});