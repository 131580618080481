define("plutof/templates/publishing/ena/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0zKddjkN",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"ena\"],null],[28,[37,2],[\"ena.datasets\"],null],\"publishing.ena.add\",[28,[37,3],[\"goBack\"],null],[30,0,[\"pagination\"]],[28,[37,2],[\"ena.title\"],null],\"information/content/ena/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"publishing.ena.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"dataset\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"route-action\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/publishing/ena/index.hbs",
    "isStrictMode": false
  });
});