define("plutof/utils/pagination", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/utils", "rsvp", "ember-concurrency", "ember-inflector", "plutof/config/environment", "plutof/misc/config", "plutof/misc/abstract", "plutof/services/ajax", "plutof/utils/notifications"], function (_exports, _object, _computed, _mixin, _utils, _rsvp, _emberConcurrency, _emberInflector, _environment, _config, _abstract, _ajax, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RegularPagination = _exports.Pagination = _exports.EmptyPagination = _exports.EmberDataPagination = _exports.BasePagination = _exports.AjaxPagination = _exports.AccumulatingPaginationMixin = void 0;
  _exports.paginateAjaxEndpoint = paginateAjaxEndpoint;
  _exports.paginateIndexRoute = paginateIndexRoute;
  _exports.paginateJSONAPI = paginateJSONAPI;
  _exports.paginateLocalObjects = paginateLocalObjects;
  _exports.paginateModel = paginateModel;
  _exports.paginatePublishingModel = paginatePublishingModel;
  _exports.reloadPagination = reloadPagination;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // TODO: Probably don't need to derive pagiantions from EmberObject
  let BasePagination = _exports.BasePagination = (_dec = (0, _object.computed)('objectCount', 'pageSize'), _dec2 = (0, _object.computed)('pageNumber'), _dec3 = (0, _object.computed)('pageNumber', 'pageCount'), _dec4 = (0, _computed.equal)('objectCount', 0), _class = class BasePagination extends _object.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isEmpty", _descriptor, this);
    }
    get pageCount() {
      return Math.ceil(this.objectCount / this.pageSize);
    }
    get onFirstPage() {
      return this.pageNumber === 1;
    }
    get onLastPage() {
      return this.pageNumber >= this.pageCount;
    }
    isValidPageNumber(number) {
      return number > 0 && number <= this.pageCount;
    }
    previousPage() {
      return this.switchPage(this.pageNumber - 1);
    }
    nextPage() {
      return this.switchPage(this.pageNumber + 1);
    }
    switchPage(number, params) {
      // Override in subclasses
    }
  }, _applyDecoratedDescriptor(_class.prototype, "pageCount", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pageCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFirstPage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onFirstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLastPage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onLastPage"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // XXX: Necessary only because of class field/CPs interaction problem. Probably shouldn't be exported.
  class RegularPagination extends BasePagination {
    // (int) -> Promise<[RecordData]>
    loadPage(number) {
      return (0, _abstract.wrap_as_promise)([]);
    }

    // [RecordData] -> Promise<[Record]>
    preprocessPage(data) {
      return (0, _abstract.wrap_as_promise)(data);
    }
    handlePage(page) {
      this.set('objects', page);
    }

    // TODO: Make this a task?
    async _switchPage(number, {
      forced = false
    } = {}) {
      if (!forced && (this.pageNumber === number || !this.isValidPageNumber(number))) {
        return _abstract.EMPTY_PROMISE;
      }
      this.set('isLoading', true);
      const pageData = await this.loadPage(number);
      const page = await this.preprocessPage(pageData);
      this.set('isLoading', false);
      this.set('pageNumber', number);
      this.handlePage(page);
      return page;
    }
    switchPage(number, params) {
      const loaded = this._switchPage(number, params);
      this.set('loaded', loaded);
      return loaded;
    }

    // Note: will work properly only for models that get count in the process,
    // not from constructor. Mostly only JSON API endpoint fall here
    // TODO: Think about moving it into a mixin, or make a base class for
    // jsonapi and publshing paginations
    reload() {
      this.switchPage(this.pageNumber, {
        forced: true
      });
    }
  }
  _exports.RegularPagination = RegularPagination;
  class Pagination extends RegularPagination {
    constructor(...args) {
      super(...args);
      // * Core attributes *
      _defineProperty(this, "pageSize", _config.default.Common.PAGE_SIZE);
      _defineProperty(this, "pageNumber", 0);
      _defineProperty(this, "objectCount", 0);
      _defineProperty(this, "isLoading", false);
    }
    // * Methods *
    init() {
      super.init(...arguments);
      this.set('objects', []);
      this.set('loaded', _rsvp.default.Promise.resolve([]));
    }
  }
  _exports.Pagination = Pagination;
  class PublishingPagination extends Pagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "filters", {});
      _defineProperty(this, "model", null);
      _defineProperty(this, "store", null);
    }
    preprocessPage(page) {
      this.set('objectCount', page.get('meta.pagination.count'));
      return (0, _abstract.wrap_as_promise)(page.toArray());
    }
    loadPage(number) {
      const queryObject = Object.assign({
        'page[number]': number,
        'page[size]': this.pageSize
      }, this.filters);
      return this.store.query(this.model, queryObject);
    }
  }
  class QueryPagination extends Pagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "filters", {});
    }
    constructQuery(number) {
      return Object.assign({
        page: number,
        page_size: this.pageSize
      }, this.filters);
    }
  }
  class AjaxPagination extends QueryPagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "endpoint", null);
    }
    loadPage(number) {
      const query = this.constructQuery(number);
      const url = (0, _abstract.construct_request)(this.endpoint, query);
      return this.ajax.request(url).then(response => response, err => {
        (0, _notifications.reportError)(err);
        throw err;
      });
    }
  }
  _exports.AjaxPagination = AjaxPagination;
  class EmberDataPagination extends QueryPagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "model", null);
      _defineProperty(this, "store", null);
    }
    loadPage(number) {
      const query = this.constructQuery(number);
      return this.store.query(this.model, query).then(page => page.toArray(), err => {
        (0, _notifications.reportError)(err);
        throw err;
      });
    }
  }
  _exports.EmberDataPagination = EmberDataPagination;
  const AccumulatingPaginationMixin = _exports.AccumulatingPaginationMixin = _mixin.default.create({
    handlePage(page) {
      this.objects.pushObjects(page);
    }
  });
  function extend(klass, mixins) {
    if (mixins.length === 0) {
      return klass;
    }
    return class extends klass.extend(...mixins) {};
  }
  function paginateModel(store, model, filters = {}, mixins = [], properties = {}) {
    const ajax = (0, _ajax.ajaxFromControlled)(store);
    const modelEndpoint = `${_environment.default.API_HOST}/${(0, _emberInflector.pluralize)(model)}/count/`;
    const countURL = (0, _abstract.construct_request)(modelEndpoint, filters);
    return ajax.request(countURL).then(countResponse => {
      let count = countResponse.objects_count;
      if (store.inAnnotation) {
        count += store.annotatedStore.rawQueryAdditions(model, filters).length;
      }
      const pagination = extend(EmberDataPagination, mixins).create(Object.assign({
        objects: [],
        objectCount: count,
        model: model,
        filters: filters,
        store: store
      }, properties));
      pagination.switchPage(1);
      return pagination;
    }, () => {
      // Don't fail on missing access rights
      return Pagination.create();
    });
  }
  function paginatePublishingModel(store, model, filters = {}, mixins = [], properties = {}) {
    const pagination = extend(PublishingPagination, mixins).create(Object.assign({
      objects: [],
      objectCount: 1,
      // XXX Object count is unkown at this point.
      model: model,
      filters: filters,
      store: store
    }, properties));
    pagination.switchPage(1);
    return pagination;
  }
  async function paginateAjaxEndpoint(ajax, endpoint, {
    filters = {},
    mixins = [],
    countEndpoint,
    pageSize = _config.default.Common.PAGE_SIZE
  } = {}) {
    if (!countEndpoint) {
      countEndpoint = `${endpoint}/count/`;
    }
    const countResponse = await ajax.request(`${_environment.default.API_HOST}/${countEndpoint}`, {
      data: filters
    });
    const count = countResponse.objects_count;
    const pagination = extend(AjaxPagination, mixins).create({
      ajax,
      endpoint: `${_environment.default.API_HOST}/${endpoint}/`,
      objects: [],
      objectCount: count,
      filters: filters,
      pageSize
    });
    pagination.switchPage(1);
    return pagination;
  }
  class EmptyPagination extends Pagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "objectCount", 0);
      _defineProperty(this, "isLoading", false);
    }
  }
  _exports.EmptyPagination = EmptyPagination;
  class LocalPagination extends Pagination {
    init() {
      super.init(...arguments);
      this.set('objectCount', this.allObjects.length);
    }
    loadPage(number) {
      const start = (number - 1) * this.pageSize;
      return _rsvp.default.Promise.resolve(this.allObjects.slice(start, start + this.pageSize));
    }
  }
  function paginateLocalObjects(objects, {
    pageSize = _config.default.Common.PAGE_SIZE
  } = {}) {
    const pagination = LocalPagination.create({
      pageSize,
      allObjects: objects
    });
    pagination.switchPage(1);
    return pagination;
  }
  class JSONAPIPagination extends AjaxPagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sideloadedRelationships", []);
    }
    constructQuery() {
      let query = super.constructQuery(...arguments);
      query['include'] = this.sideloadedRelationships.join(',');
      return query;
    }
    _flattenRecord(record) {
      let flat = {
        id: record.id
      };
      Object.assign(flat, record.attributes);
      return Object.assign({
        id: record.id,
        type: record.type
      }, record.attributes, record.relationships);
    }
    _collectSideloaded(sideloaded) {
      let sideloadedByType = [];
      sideloaded.forEach(record => {
        if (!sideloadedByType[record.type]) {
          sideloadedByType[record.type] = [];
        }
        sideloadedByType[record.type][record.id] = this._flattenRecord(record);
      });
      return sideloadedByType;
    }
    preprocessPage(pageData) {
      this.set('objectCount', pageData.meta.pagination.count);
      const sideloaded = this._collectSideloaded(pageData.included || []);
      return pageData.data.map(recordData => {
        let record = this._flattenRecord(recordData);

        // Replace relationship links with sideloaded records if possible
        Object.keys(recordData.relationships).forEach(relationshipName => {
          let relationship = recordData.relationships[relationshipName].data;
          if (!(0, _utils.isNone)(relationship) && relationship.type in sideloaded) {
            const sideloadedRelationship = sideloaded[relationship.type][relationship.id];
            if (!(0, _utils.isNone)(sideloadedRelationship)) {
              record[relationshipName] = sideloadedRelationship;
            }
          }
        });
        return record;
      });
    }
  }
  function paginateJSONAPI(ajax, endpoint, {
    filters = {},
    mixins = [],
    sideloadedRelationships = []
  } = {}) {
    const pagination = extend(JSONAPIPagination, mixins).create({
      ajax,
      endpoint,
      objects: [],
      objectCount: 1,
      // XXX: Hack so that switchPage doesn't end early
      filters,
      sideloadedRelationships
    });

    // Resolving after the page is loaded because of the hack above
    return pagination.switchPage(1).then(() => pagination);
  }
  function paginateIndexRoute(ajax, model, queryParams = {}) {
    const filters = Object.assign({
      owner: 'self',
      ordering: '-updated_at'
    }, queryParams);
    return paginateAjaxEndpoint(ajax, (0, _emberInflector.pluralize)(model), {
      filters
    });
  }

  // Not an ideal extraction, still requires some boilerplate, but no ideas atm about how to encapsulate
  // it completely, given that the logic is required both in controller and a route (also, no mixins).
  // ember-concurrency is pretty strict about how a task gets attached to an instance. TODO
  function reloadPagination(constructor, {
    paginationField = 'pagination'
  } = {}) {
    return (0, _emberConcurrency.task)(function* () {
      const pagination = yield constructor.call(this);
      const oldPagination = this[paginationField];

      // If list is already populated, wait till the page is loaded before replacing to prevent
      // list view thrashing
      // TODO: This, however, means that load status during reorder is not indicated in the navbar
      if (oldPagination) {
        yield pagination.loaded;
        this.set(paginationField, pagination);
        oldPagination.destroy();
      } else {
        this.set(paginationField, pagination);
      }
    }).restartable();
  }
});