define("plutof/templates/components/my-menu/tool-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/FNtWdia",
    "block": "[[[10,\"ul\"],[14,0,\"list-inline\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tools\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n\"],[1,\"            \"],[11,3],[16,0,[29,[\"drag-widget-menu \",[52,[30,1,[\"isActive\"]],\"active-item\"]]]],[4,[38,3],[[30,0],\"switch\",[30,1]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[29,[[30,1,[\"icon\"]]]]],[12],[13],[1,\"\\n                \"],[1,[30,1,[\"title\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[18,2,[[28,[37,5],null,[[\"tool\"],[[50,\"my-menu/-tool\",0,null,[[\"selector\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"tool\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"action\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/templates/components/my-menu/tool-selector.hbs",
    "isStrictMode": false
  });
});