define("plutof/components/measurements/measurement-view/columns", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Layout::RegularGrid @columns={{@columnWidth}}>
      {{#each @measurements as |om|}}
          <div>
              <Measurements::MeasurementView::Single
                  @measurement={{om}}
                  @expand={{unless om.valueIsEmpty (fn @expand om)}} />
          </div>
      {{/each}}
  </Layout::RegularGrid>
  
  */
  {
    "id": "PpAfJK+c",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@measurement\",\"@expand\"],[[30,3],[52,[51,[30,3,[\"valueIsEmpty\"]]],[28,[37,5],[[30,4],[30,3]],null]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null]],[]]]]],[1,\"\\n\"]],[\"@columnWidth\",\"@measurements\",\"om\",\"@expand\"],false,[\"layout/regular-grid\",\"each\",\"-track-array\",\"measurements/measurement-view/single\",\"unless\",\"fn\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/columns.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});