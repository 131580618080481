define("plutof/templates/components/navbar/-navbar-base/title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SmjNN23c",
    "block": "[[[10,1],[15,0,[29,[[52,[30,0,[\"routingStatus\",\"routeLoading\"]],\"spinicon\"],\" \",[30,1],\" navbar-base__title-icon\"]]],[15,\"data-test-route-status\",[29,[[52,[30,0,[\"routingStatus\",\"routeLoading\"]],\"loading\",\"settled\"]]]],[12],[13],[1,\"\\n\\n\"],[1,[30,2]],[1,\" \\n\"]],[\"@icon\",\"@title\"],false,[\"if\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/title.hbs",
    "isStrictMode": false
  });
});