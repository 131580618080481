define("plutof/templates/components/unite/additional-views", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "RfGeAg5V",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"UniteSH.additionalViews\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[\"UniteSH.alignmentView\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n                \"],[8,[39,2],[[24,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"datacite.alignment\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-external-link-alt\"],[12],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,[28,[35,1],[\"UniteSH.clusterView\"],null]],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n                \"],[8,[39,2],[[24,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"compound.view\",[30,1,[\"cluster\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-external-link-alt\"],[12],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[14,0,\"field-name\"],[12],[1,[28,[35,1],[\"UniteSH.lineage\"],null]],[13],[1,\"\\n\\n            \"],[10,\"td\"],[14,0,\"field-value\"],[12],[1,\"\\n                \"],[8,[39,2],[[24,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"taxonomy.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-external-link-alt\"],[12],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dshcluster\",\"@treeTaxon\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"link-to\"]]",
    "moduleName": "plutof/templates/components/unite/additional-views.hbs",
    "isStrictMode": false
  });
});