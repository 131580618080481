define("plutof/templates/referencebased/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KqCN2xG2",
    "block": "[[[10,0],[14,0,\"route-marker-referencebased-index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\"],[[28,[37,1],[\"refbased\"],null],[28,[37,2],[\"ReferenceBased.title\"],null],\"referencebased.add\",[30,0,[\"model\",\"pagination\"]],[28,[37,2],[\"ReferenceBased.infoTitle\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@navbar\",\"@module\"],[[30,1],\"reference-based\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.taxon\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"referencebased.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"taxon_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.reference\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[[30,4,[\"reference_name\"]],50],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,5,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"relatedObjects\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"clipboard\"]],null,[[\"@contentType\"],[[30,0,[\"contentType\"]]]],null],[1,\"\\n    \"],[8,[30,5,[\"access\"]],null,null,null],[1,\"\\n\"]],[3,4,5]]]]],[1,\"\\n\"]],[\"navbar\",\"slots\",\"Field\",\"refbased\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"import/navbar-button\",\"tables/list-route\",\"link-to\",\"short-string\"]]",
    "moduleName": "plutof/templates/referencebased/index.hbs",
    "isStrictMode": false
  });
});