define("plutof/services/list-routes", ["exports", "@ember/service", "rsvp"], function (_exports, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // moduleID is one of utils/modules keys
  class ListRoutesService extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_paginations", new Map());
    }
    paginate(moduleID, init) {
      if (!this._paginations.has(moduleID)) {
        this._paginations.set(moduleID, _rsvp.default.Promise.resolve(init()));
      }
      return this._paginations.get(moduleID);
    }
    reset(moduleID) {
      if (this._paginations.has(moduleID)) {
        // No need to wait for this
        this._paginations.get(moduleID).then(pagination => pagination.destroy());
      }
      this._paginations.delete(moduleID);
    }
  }
  var _default = _exports.default = ListRoutesService;
});