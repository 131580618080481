define("plutof/components/measurements/measurement-view/panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Measurements::MeasurementView::Wrapper
      @mainform={{@mainform}}
      @object={{@object}}
      as |measurements empty expand|
  >
      <PlutofPanel::Simple
          @title={{i18n-t "General.measurements"}}
          @collapse={{empty}}
          ...attributes
      >
          <Measurements::MeasurementView::Columns
              @measurements={{measurements}}
              @columnWidth={{or @columnWidth 2}}
              @expand={{expand}} />
      </PlutofPanel::Simple>
  </Measurements::MeasurementView::Wrapper>
  
  */
  {
    "id": "8elkgyqA",
    "block": "[[[8,[39,0],null,[[\"@mainform\",\"@object\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[17,6]],[[\"@title\",\"@collapse\"],[[28,[37,2],[\"General.measurements\"],null],[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@measurements\",\"@columnWidth\",\"@expand\"],[[30,3],[28,[37,4],[[30,7],2],null],[30,5]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3,4,5]]]]],[1,\"\\n\"]],[\"@mainform\",\"@object\",\"measurements\",\"empty\",\"expand\",\"&attrs\",\"@columnWidth\"],false,[\"measurements/measurement-view/wrapper\",\"plutof-panel/simple\",\"i18n-t\",\"measurements/measurement-view/columns\",\"or\"]]",
    "moduleName": "plutof/components/measurements/measurement-view/panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});