define("plutof/components/access/-view-rights/owner", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#access/-view-rights/column}}
      <div class="plutof-tooltip-hover">
          <div class="plutof-label">
              {{i18n-t "General.owner"}}
          </div>
  
          <div class="plutof-tooltip">
              {{i18n-t "General.ownerTooltip"}}
          </div>
      </div>
  
      <p class="view-rights__object-owner">
          {{@owner}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "YgSGyIeP",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.owner\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"General.ownerTooltip\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[14,0,\"view-rights__object-owner\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@owner\"],false,[\"access/-view-rights/column\",\"i18n-t\"]]",
    "moduleName": "plutof/components/access/-view-rights/owner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});