define("plutof/templates/components/sample/gathering-agents-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "t+2KmY1r",
    "block": "[[[8,[39,0],null,[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"gathering-agents-view__agent\"]],[[\"@route\",\"@model\"],[\"person.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@agents\",\"agent\"],false,[\"common/record-list-view\",\"link-to\"]]",
    "moduleName": "plutof/templates/components/sample/gathering-agents-view.hbs",
    "isStrictMode": false
  });
});