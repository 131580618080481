define("plutof/templates/components/experiment/common/-labware-visualization/row-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y/gKSlIP",
    "block": "[[[10,\"td\"],[14,0,\"labware-table-header\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[11,\"button\"],[24,0,\"labware-table-header__button\"],[4,[38,1],[[30,0],[30,2,[\"toggleMultiple\"]],[30,3,[\"materials\"]]],null],[12],[1,\"\\n            \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"button\"],[14,0,\"labware-table-header__button\"],[12],[1,\"\\n            \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@enableTubeSelection\",\"@materialContainer\",\"@row\"],false,[\"if\",\"action\"]]",
    "moduleName": "plutof/templates/components/experiment/common/-labware-visualization/row-header.hbs",
    "isStrictMode": false
  });
});