define("plutof/components/search/filters/additional-identifiers", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="filter-input">
      {{! TODO: replace all this add-component stuff with a component proper}}
      <div class="add-component">
          <div class="component-part">
              <div class="control-group">
                  {{common/local-label "Specimen.additionalCode"}}
  
                  <Input
                      @type="text"
                      @value={{@filter.code}}
                      class="form-control control" />
              </div>
          </div>
  
          <div class="component-part">
              {{plutof-labelc "specimen.additionalidentifier.source"}}
  
              <Input
                  @type="text"
                  @value={{@filter.source}}
                  class="form-control control" />
          </div>
      </div>
  </div>
  
  */
  {
    "id": "DUY7Hiul",
    "block": "[[[10,0],[14,0,\"filter-input\"],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[14,0,\"add-component\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n                \"],[1,[28,[35,0],[\"Specimen.additionalCode\"],null]],[1,\"\\n\\n                \"],[8,[39,1],[[24,0,\"form-control control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"code\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"specimen.additionalidentifier.source\"],null]],[1,\"\\n\\n            \"],[8,[39,1],[[24,0,\"form-control control\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"source\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@filter\"],false,[\"common/local-label\",\"input\",\"plutof-labelc\"]]",
    "moduleName": "plutof/components/search/filters/additional-identifiers.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});