define("plutof/components/collection/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Agent::CollectionForm
      @collection={{@collection}}
      @setLogo={{@setLogo}}
      @validationContext={{this.validationContext.collection}} />
  
  <Agent::OrganizationAdditionalData
      @organization={{@collection}}
      @validationContext={{this.validationContext.additionalData}} />
  
  {{#if @workgroupData}}
      <Agent::OrganizationWorkgroup
          @workgroupData={{@workgroupData}}
          @validationContext={{this.validationContext.managingGroup}} />
  {{/if}}
  
  <Collection::Repositories
      @repositories={{@repositories}}
      @objectsToDelete={{@objectsToDelete}}
      @validationContext={{this.validationContext.repositories}} />
  
  {{#if @identifiers}}
      <Identifiers::Edit
          @model={{@identifiers}}
          @workgroupData={{@workgroupData}} />
  {{/if}}
  
  <LinkedItems::Edit
      @data={{@linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{this.validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{this.validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{@cancel}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "PCuvscpb",
    "block": "[[[8,[39,0],null,[[\"@collection\",\"@setLogo\",\"@validationContext\"],[[30,1],[30,2],[30,0,[\"validationContext\",\"collection\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@organization\",\"@validationContext\"],[[30,1],[30,0,[\"validationContext\",\"additionalData\"]]]],null],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,3],null,[[\"@workgroupData\",\"@validationContext\"],[[30,3],[30,0,[\"validationContext\",\"managingGroup\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@repositories\",\"@objectsToDelete\",\"@validationContext\"],[[30,4],[30,5],[30,0,[\"validationContext\",\"repositories\"]]]],null],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"    \"],[8,[39,5],null,[[\"@model\",\"@workgroupData\"],[[30,6],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,6],null,[[\"@data\",\"@validationContext\"],[[30,7],[30,8,[\"linkedItems\"]]]],null],[1,\"\\n\\n\"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@save\",\"@disabled\"],[[30,9],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[30,10]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@collection\",\"@setLogo\",\"@workgroupData\",\"@repositories\",\"@objectsToDelete\",\"@identifiers\",\"@linkedItems\",\"@validationContext\",\"@save\",\"@cancel\"],false,[\"agent/collection-form\",\"agent/organization-additional-data\",\"if\",\"agent/organization-workgroup\",\"collection/repositories\",\"identifiers/edit\",\"linked-items/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/components/collection/edit.hbs",
    "isStrictMode": false
  });
  class CollectionEdit extends _component.default.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'collection',
        label: 'collection.collection'
      }, {
        name: 'additionalData',
        label: 'Agents.additionalData'
      }, {
        name: 'repositories',
        label: 'collection.repository.repositories'
      }, {
        name: 'managingGroup',
        label: 'group.panels.workgroup'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionEdit);
});