define("plutof/components/collection/view/stats", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "@ember/service", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberConcurrency, _service, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "collection.transactionStats.title"}}>
      <Layout::RegularGrid @columns={{3}}>
          <div>
              <Common::LocalLabel @label="collection.transactionStats.allTransactions" />
  
              <a href="{{this.searchUrl}}?module=transaction&collection={{@collectionId}}">
                  {{this.transactionsStats.total.all}}
              </a>
          </div>
  
          <div>
              <Common::LocalLabel @label="collection.transactionStats.activeTransactions" />
  
              <a href="{{this.searchUrl}}?module=transaction&collection={{@collectionId}}&il=false">
                  {{this.transactionsStats.total.active}}
              </a>
          </div>
  
          <div>
              <Common::LocalLabel @label="collection.transactionStats.recordsWithImages" />
  
              <a href="{{this.searchUrl}}?module=taxonoccurrence&dp={{@collectionId}}&has_images=true">
                  {{this.recordsWithImages.count}}
              </a>
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ianAN+LQ",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"collection.transactionStats.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@label\"],[\"collection.transactionStats.allTransactions\"]],null],[1,\"\\n\\n            \"],[10,3],[15,6,[29,[[30,0,[\"searchUrl\"]],\"?module=transaction&collection=\",[30,1]]]],[12],[1,\"\\n                \"],[1,[30,0,[\"transactionsStats\",\"total\",\"all\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@label\"],[\"collection.transactionStats.activeTransactions\"]],null],[1,\"\\n\\n            \"],[10,3],[15,6,[29,[[30,0,[\"searchUrl\"]],\"?module=transaction&collection=\",[30,1],\"&il=false\"]]],[12],[1,\"\\n                \"],[1,[30,0,[\"transactionsStats\",\"total\",\"active\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@label\"],[\"collection.transactionStats.recordsWithImages\"]],null],[1,\"\\n\\n            \"],[10,3],[15,6,[29,[[30,0,[\"searchUrl\"]],\"?module=taxonoccurrence&dp=\",[30,1],\"&has_images=true\"]]],[12],[1,\"\\n                \"],[1,[30,0,[\"recordsWithImages\",\"count\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@collectionId\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"common/local-label\"]]",
    "moduleName": "plutof/components/collection/view/stats.hbs",
    "isStrictMode": false
  });
  let CollectionViewStats = _exports.default = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class CollectionViewStats extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "searchUrl", _descriptor3, this);
      _initializerDefineProperty(this, "transactionsStats", _descriptor4, this);
      _initializerDefineProperty(this, "recordsWithImages", _descriptor5, this);
      _initializerDefineProperty(this, "loadTransactionsStats", _descriptor6, this);
      _initializerDefineProperty(this, "loadRecordsStats", _descriptor7, this);
      this.loadTransactionsStats.perform();
      this.loadRecordsStats.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.router.urlFor('search');
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transactionsStats", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "recordsWithImages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadTransactionsStats", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const url = `agent/collections/${this.args.collectionId}/transaction-stats/`;
        this.transactionsStats = yield this.ajax.request(url).catch(_notifications.reportError);
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadRecordsStats", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const url = `taxonoccurrence/search/?&page_size=1&page=1&dp=${this.args.collectionId}&has_images=true`;
        this.recordsWithImages = yield this.ajax.request(url).catch(_notifications.reportError);
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionViewStats);
});