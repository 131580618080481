define("plutof/components/search/top-results", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "rsvp", "plutof/utils/chart", "plutof/utils/search"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _rsvp, _chart, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadTopResults = loadTopResults;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="spacer-above top-results-container">
      {{#resolve-promise @topResults as |topResults loaded isLoading|}}
          {{#if (or isLoading (not @canLoadTops))}}
              <Layout::RegularGrid @columns={{1}} @class="spacer-above top-results-container">
                  <Search::TopResults::Info
                      @canLoad={{@canLoadTops}}
                      @isLoading={{isLoading}} />
              </Layout::RegularGrid>
          {{else if loaded}}
              <Layout::RegularGrid @columns={{4}} @class="spacer-above top-results-container">
                  {{#each topResults as |top|}}
                      {{#unless top.isEmpty}}
                          <Search::TopResults::Top
                              @top={{top}}
                              @addRecordFilter={{this._addRecordFilter}} />
                      {{/unless}}
                  {{/each}}
              </Layout::RegularGrid>
          {{/if}}
      {{/resolve-promise}}
  </div>
  
  */
  {
    "id": "S6ijmV8N",
    "block": "[[[10,0],[14,0,\"spacer-above top-results-container\"],[12],[1,\"\\n\"],[6,[39,0],[[30,1]],null,[[\"default\"],[[[[41,[28,[37,2],[[30,4],[28,[37,3],[[30,5]],null]],null],[[[1,\"            \"],[8,[39,4],null,[[\"@columns\",\"@class\"],[1,\"spacer-above top-results-container\"]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,5],null,[[\"@canLoad\",\"@isLoading\"],[[30,5],[30,4]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"            \"],[8,[39,4],null,[[\"@columns\",\"@class\"],[4,\"spacer-above top-results-container\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,2]],null]],null],null,[[[41,[51,[30,6,[\"isEmpty\"]]],[[[1,\"                        \"],[8,[39,9],null,[[\"@top\",\"@addRecordFilter\"],[[30,6],[30,0,[\"_addRecordFilter\"]]]],null],[1,\"\\n\"]],[]],null]],[6]],null],[1,\"            \"]],[]]]]],[1,\"\\n        \"]],[]],null]],[]]]],[2,3,4]]]]],[13],[1,\"\\n\"]],[\"@topResults\",\"topResults\",\"loaded\",\"isLoading\",\"@canLoadTops\",\"top\"],false,[\"resolve-promise\",\"if\",\"or\",\"not\",\"layout/regular-grid\",\"search/top-results/info\",\"each\",\"-track-array\",\"unless\",\"search/top-results/top\"]]",
    "moduleName": "plutof/components/search/top-results.hbs",
    "isStrictMode": false
  });
  async function loadTopResults(ajax, searchModule, query) {
    return (await _rsvp.default.Promise.all(searchModule.topResults.map(async field => {
      const chart = await (0, _chart.getChartData)(ajax, searchModule.value + _search.TOP_RESULTS_ENDPOINT, [field.id], query);
      if (!chart[field.id]) {
        return null;
      }
      const data = chart[field.id].data;
      return {
        field: field,
        less: data.slice(0, _search.TOP_RESULTS_READ_MORE_LIMITER),
        more: data.slice(_search.TOP_RESULTS_READ_MORE_LIMITER),
        isEmpty: (0, _utils.isEmpty)(data)
      };
    }))).compact();
  }
  let TopResults = (_class = class TopResults extends _component2.default {
    async _addRecordFilter(field, result) {
      // Perhaps validate something here? Hmm?
      const value = await field.filterValue(this.store, result);
      this.args.addRecordFilterWithValue(field.id, value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_addRecordFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_addRecordFilter"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopResults);
});