define("plutof/components/datacite/view/creator", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{this.module.routes.view}} @model={{@creator.id}}>
      <span class={{this.module.icon}}></span>
  
      {{@creator.name}}
  
      {{#if @creator.affiliation}}
          ({{@creator.affiliation}})
      {{/if}}
  </LinkTo>
  
  */
  {
    "id": "NTlQrPes",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n\\n\"],[41,[30,1,[\"affiliation\"]],[[[1,\"        (\"],[1,[30,1,[\"affiliation\"]]],[1,\")\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"@creator\"],false,[\"link-to\",\"if\"]]",
    "moduleName": "plutof/components/datacite/view/creator.hbs",
    "isStrictMode": false
  });
  class DataciteViewCreator extends _component2.default {
    get module() {
      const ctype = this.args.creator.type === 'organization' ? 'agent/organization' : 'agent/person';
      return _modules.default[ctype];
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataciteViewCreator);
});