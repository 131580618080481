define("plutof/templates/collection/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "3zfK8LLw",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"collection\"],null],[28,[37,2],[\"collection.add.title\"],null],[30,0,[\"model\",\"collection\"]],[28,[37,3],[\"triggerReset\"],null],[28,[37,2],[\"collection.collection\"],null],\"information/content/collection/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@collection\",\"@repositories\",\"@linkedItems\",\"@objectsToDelete\",\"@validationContext\",\"@setLogo\",\"@save\",\"@cancel\"],[[30,0,[\"model\",\"collection\"]],[30,0,[\"model\",\"repositories\"]],[30,0,[\"model\",\"linkedItems\"]],[30,0,[\"model\",\"objectsToDelete\"]],[30,0,[\"validationContext\"]],[30,0,[\"setLogo\"]],[30,0,[\"save\"]],[28,[37,5],[[30,0],\"cancel\"],null]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"route-action\",\"collection/edit\",\"action\"]]",
    "moduleName": "plutof/templates/collection/add.hbs",
    "isStrictMode": false
  });
});