define("plutof/routes/taxonomy/add", ["exports", "@ember/utils", "rsvp", "plutof/components/linked-items/edit", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/routes"], function (_exports, _utils, _rsvp, _edit, _abstract, _config, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AddTaxonRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'taxonomy.trees');
      _defineProperty(this, "title", 'Taxonomy.newTaxon');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }

      // XXX: This shouldn't be needed, investigate
      if (params.parent_id === 'undefined') {
        params.parent_id = undefined;
      }
      let taxon;
      if (params.valid_name) {
        const validName = await this.store.findRecord('taxonomy/taxonnode', params.valid_name);
        taxon = this.store.createRecord('taxonomy/taxonnode', await _rsvp.default.hash({
          tree: validName.tree,
          taxon_rank: validName.taxon_rank,
          valid_name: validName,
          parent_described_in: validName,
          synonym_type: 'synonym'
        }));
      } else {
        let parentPromise = _abstract.EMPTY_PROMISE;
        if (!(0, _utils.isNone)(params.parent_id)) {
          parentPromise = this.store.findRecord('taxonomy/taxonnode', params.parent_id);
        }
        let rankPromise = _abstract.EMPTY_PROMISE;
        if (_config.default.Taxonomy.USE_DEFAULT_RANK) {
          rankPromise = this.store.findRecord('taxonomy/taxonrank', _config.default.Taxonomy.DEFAULT_RANK_ID);
        }
        taxon = await _rsvp.default.hash({
          tree: this.store.findRecord('taxonomy/tree', params.tree_id),
          taxon_rank: rankPromise,
          parent: parentPromise
        }).then(relations => this.store.createRecord('taxonomy/taxonnode', relations));
      }
      return _rsvp.default.hash({
        taxon,
        commonNames: [],
        linkedItems: (0, _edit.createLinkedItemsData)(this.store, taxon, {
          disabledTabs: ['files', 'glossaries', 'links', 'permits']
        })
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        keepQueryParams: true,
        objectsToDelete: [],
        taxonIsValid: true,
        currentlySaving: false
      });
    }
  }
  var _default = _exports.default = AddTaxonRoute;
});