define("plutof/templates/components/multiple-selection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WoviJ9ol",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"prompt\",\"selectClass\",\"disabled\"],[[30,1],[28,[37,1],[[30,2],\"id\"],null],[28,[37,1],[[30,3],\"representation\"],null],[33,2],[33,3],[33,4],[30,4]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@records\",\"@remove\"],[[30,0,[\"selections\"]],[30,0,[\"removeRecord\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,6],[[30,5],[30,3]],null]],[1,\"\\n\"]],[5]]]]],[1,\"\\n\"]],[\"@content\",\"@optionValuePath\",\"@optionLabelPath\",\"@disabled\",\"item\"],false,[\"plutof-select/object\",\"default-to\",\"selectedValue\",\"prompt\",\"selectClass\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/templates/components/multiple-selection.hbs",
    "isStrictMode": false
  });
});