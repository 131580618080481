define("plutof/components/clipboard/bulk-update", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/controllers/clipboard/utils/common", "plutof/misc/content_types", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _component, _object, _computed, _service, _utils, _environment, _common, _content_types, _access, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createInput = createInput;
  _exports.default = void 0;
  _exports.processInputs = processInputs;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const ACCESS_FIELDS = ['user_view', 'user_edit', 'group_view', 'group_edit', 'remove_user_view', 'remove_user_edit', 'remove_group_view', 'remove_group_edit', 'remove_all_user_view', 'remove_all_user_edit', 'remove_all_group_view', 'remove_all_group_edit', 'change_related_files', 'new_owner'];
  function createBulkUpdateData(mainformType) {
    return {
      data: {},
      fieldsToRemove: [],
      mainformType: mainformType
    };
  }

  // XXX(config) Do not modify argument value.
  function createInput(type, config) {
    config.canDelete = config.canDelete ?? !config.required;
    config.nullValue = null;
    config.defaultValue = null;
    config.type = type;
    if (type === 'string') {
      config.nullValue = '';
    }
    if (type === 'hasMany') {
      config.nullValue = [];
    }
    if (type === 'boolean') {
      config.canDelete = false;
      config.defaultValue = false;
    }
    return config;
  }

  /* XXX(dataKey) Careful now */
  function processInputs(inputs, data, dataKey = 'data') {
    Object.keys(inputs).forEach(key => {
      (0, _object.setProperties)(inputs[key], {
        key: key,
        data: data[dataKey],
        fieldsToRemove: data.fieldsToRemove,
        labelSource: (0, _object.get)(inputs[key], 'labelSource') ?? 'model'
      });
    });
    return inputs;
  }
  function postData(ajax, data, endpoint) {
    return ajax.request(`${_environment.default.API_HOST}/${endpoint}`, {
      type: 'POST',
      processPageData: false,
      contentType: 'application/json; charset=UTF-8',
      data: JSON.stringify(data)
    });
  }
  function getContentType(store, modelPath) {
    const modelName = modelPath.replace('taxonoccurrence/', ''); // XXX
    return (0, _content_types.get_ctype_by_name)(store, modelName);
  }
  function getAccessData(data) {
    const viewAndEdit = {
      access_view: data.get('visible'),
      access_edit: data.get('editable')
    };
    const trimmedData = (0, _object.getProperties)(data, ACCESS_FIELDS);
    (0, _object.setProperties)(trimmedData, viewAndEdit);
    return trimmedData;
  }
  let BulkUpdate = _exports.default = (_dec = (0, _computed.equal)('panelName', 'delete'), _dec2 = (0, _computed.gt)('selectedEntries.length', 0), _dec3 = (0, _computed.gt)('clipboardCount', 0), _dec4 = (0, _computed.gt)('activeInputs.length', 0), _dec5 = (0, _computed.and)('hasCount', 'measurementValidity', 'preparationIsValid', 'conditionAssessmentIsValid'), _dec6 = (0, _object.computed)('panelName'), _dec7 = (0, _object.computed)('panelName', 'formIsValid', 'isAddPanel', 'hasActiveInputs'), _dec8 = (0, _object.computed)('bulkUpdateData', 'panelName'), _dec9 = (0, _object.computed)('bulkUpdateData.data.preparation.isValid'), _dec10 = (0, _object.computed)('bulkUpdateData.data.condition_assessment.isValid'), _dec11 = (0, _object.computed)('bulkUpdateData.measurementData.content.fields.@each.isValid'), _dec12 = (0, _object.computed)('bulkUpdateData.inputs'), _dec13 = (0, _object.computed)('deleteOptions', 'hasCount'), _dec14 = (0, _computed.filterBy)('inputs', 'isActive'), _class = class BulkUpdate extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
      _defineProperty(this, "bulkUpdateData", null);
      _defineProperty(this, "panelName", null);
      _defineProperty(this, "deleteFiles", false);
      _defineProperty(this, "deleteOptions", null);
      _initializerDefineProperty(this, "isDeletePanel", _descriptor3, this);
      _initializerDefineProperty(this, "hasSelection", _descriptor4, this);
      _initializerDefineProperty(this, "hasCount", _descriptor5, this);
      _initializerDefineProperty(this, "hasActiveInputs", _descriptor6, this);
      _initializerDefineProperty(this, "formIsValid", _descriptor7, this);
      _initializerDefineProperty(this, "activeInputs", _descriptor8, this);
    }
    init() {
      super.init();
    }
    // Bulk update panel where a new record is created and linked with multiple records.
    get isAddPanel() {
      return ['specimen-preparations', 'livingculture-preparations', 'condition-assessments'].includes(this.panelName);
    }
    get canUpdate() {
      return !(0, _utils.isEmpty)(this.panelName) && this.formIsValid && (this.isAddPanel || this.hasActiveInputs);
    }
    get showContent() {
      return !(0, _utils.isEmpty)(this.panelName) && !(0, _utils.isEmpty)(this.bulkUpdateData);
    }
    get preparationIsValid() {
      // Or tab is not active, therefore is valid.
      const bulkPreparation = this.get('bulkUpdateData.data.preparation') || {
        isValid: true
      };
      return (0, _object.get)(bulkPreparation, 'isValid');
    }
    get conditionAssessmentIsValid() {
      // Or tab is not active, therefore is valid.
      const bulkConditionAssessment = this.get('bulkUpdateData.data.condition_assessment') || {
        isValid: true
      };
      return (0, _object.get)(bulkConditionAssessment, 'isValid');
    }
    get measurementValidity() {
      const fields = this.get('bulkUpdateData.measurementData.content.fields');
      return (0, _utils.isEmpty)(fields) || !fields.some(f => !f.get('isValid'));
    }
    get inputs() {
      const inputs = this.bulkUpdateData.inputs;
      return (0, _utils.isNone)(inputs) ? [] : Object.keys(inputs).map(key => inputs[key]);
    }
    get cannotDelete() {
      return !this.hasCount || (0, _utils.isNone)(this.deleteOptions);
    }
    switchPanel(panelName) {
      if (panelName === this.panelName) {
        return;
      }
      const newData = createBulkUpdateData(this.mainformType);
      // XXX This is done to auto-reset components in template.
      this.set('bulkUpdateData', null);
      if (panelName === 'access' || panelName === 'sequences-access') {
        (0, _access.create_access_rights)(this.store).then(access => {
          ACCESS_FIELDS.forEach(key => {
            (0, _object.set)(access, key, null);
          });
          newData.data = access;
          this.set('bulkUpdateData', newData);
        });
      } else {
        this.set('bulkUpdateData', newData);
      }
      this.set('panelName', panelName);
    }
    async confirmAndDelete(action) {
      if (!(await this.confirmationDialog.confirm(this.confirmationDialog.DELETE_CONFIRMATION))) {
        return;
      }
      await action(Object.assign({
        include_files: this.deleteFiles
      }, this.deleteOptions));
    }
    _deleteSelected() {
      return this.confirmAndDelete(this.deleteSelected).then(this.clearBulkUpdate);
    }
    _deleteAll() {
      return this.confirmAndDelete(this.deleteAll).then(this.clearBulkUpdate);
    }
    updateDeleteOptions(options) {
      this.set('deleteOptions', options);
    }
    updateEntries(updateAllEntries = false) {
      const panels = this.bulkUpdatePanels;
      const panelName = this.panelName;
      const fieldsToRemove = this.get('bulkUpdateData.fieldsToRemove');
      const measurementDataContent = this.get('bulkUpdateData.measurementData.content');
      const measurementData = (0, _utils.isNone)(measurementDataContent) ? null : measurementDataContent.getData();
      let bulkUpdateData = this.get('bulkUpdateData.data');
      // Get access data.
      // An exception because duplicating the whole access interface and data structure is too much.
      if (panelName === 'access' || panelName === 'sequences-access') {
        bulkUpdateData = getAccessData(bulkUpdateData);
      }

      // Add measurement data.
      if (!(0, _utils.isNone)(measurementData)) {
        (0, _object.setProperties)(bulkUpdateData, measurementData);
      }

      // Keep only properties that have values or have been marked for removal.
      bulkUpdateData = (0, _object.getProperties)(bulkUpdateData, Object.keys(bulkUpdateData).filter(key => {
        return !(0, _utils.isEmpty)(bulkUpdateData[key]) || fieldsToRemove.includes(key);
      }));

      // Add list of objects.
      bulkUpdateData.object_list = updateAllEntries ? [] : this.selectedEntries.mapBy('id');
      (0, _notifications.displayNotification)('status', i18n.t('Clipboard.loading'));
      return getContentType(this.store, this.modelPath).then(contentType => {
        const panelConfig = panels.findBy('name', panelName);
        const endpoint = panelConfig.endpoint;

        /**
         * Add content type.
         * TODO Make a list of panels that require content type or include content type in panel settings.
         */
        if (['access', 'samplingevents', 'samplingareas', 'sequences-access', 'condition-assessments'].includes(panelName)) {
          bulkUpdateData.content_type = contentType;
        }
        return postData(this.ajax, (0, _common.parseRecords)(bulkUpdateData), endpoint).then(response => {
          if (response.async) {
            (0, _notifications.displayNotification)('success', i18n.t('Clipboard.editFinishesLater'), null, 12000);
          } else {
            (0, _notifications.displayNotification)('success', i18n.t('General.done'));
          }
          this.set('panelName', null);
        });
      }).catch(_notifications.reportError);
    }
    clearBulkUpdate() {
      this.set('panelName', null);
      this.set('deleteOptions', null);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDeletePanel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasSelection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasCount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasActiveInputs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formIsValid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isAddPanel", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isAddPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canUpdate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "canUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showContent", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preparationIsValid", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "preparationIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conditionAssessmentIsValid", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "conditionAssessmentIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "measurementValidity", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "measurementValidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputs", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "inputs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cannotDelete", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cannotDelete"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeInputs", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "switchPanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_deleteSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_deleteSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_deleteAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_deleteAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDeleteOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeleteOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEntries", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEntries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearBulkUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearBulkUpdate"), _class.prototype), _class);
});