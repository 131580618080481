define("plutof/templates/components/multiple-selectizer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ns1Sqjjy",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"optionLabelPath\",\"optionValuePath\",\"disabled\",\"value\"],[[33,1],[33,2],[33,3],[33,4],[33,5]]]]],[1,\"\\n\\n\"],[41,[51,[33,7]],[[[1,\"    \"],[8,[39,8],null,[[\"@records\",\"@remove\"],[[30,1],[30,0,[\"removeRecord\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,9],[[30,2],[30,3]],null]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@selected\",\"item\",\"@nameProperty\"],false,[\"plutof-select/value\",\"content\",\"labelPath\",\"valuePath\",\"disabled\",\"selection\",\"unless\",\"hideList\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/templates/components/multiple-selectizer.hbs",
    "isStrictMode": false
  });
});