define("plutof/components/add-multiple-form/-column", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GuidedForm @path={{@path}} class="component-part">
      {{yield}}
  </GuidedForm>
  
  */
  {
    "id": "OwAR28fp",
    "block": "[[[8,[39,0],[[24,0,\"component-part\"]],[[\"@path\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@path\",\"&default\"],false,[\"guided-form\",\"yield\"]]",
    "moduleName": "plutof/components/add-multiple-form/-column.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});