define("plutof/components/progress-bar", ["exports", "@ember/component", "@ember/template-factory", "@ember/template", "@glimmer/component"], function (_exports, _component, _templateFactory, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{this.progressStyle}}
      aria-valuenow={{this.percentage}}
  >
      <span>
          {{this.percentage}}%
      </span>
  </div>
  
  */
  {
    "id": "bm2WstAa",
    "block": "[[[10,0],[14,0,\"progress-bar\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[30,0,[\"progressStyle\"]]],[15,\"aria-valuenow\",[30,0,[\"percentage\"]]],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n        \"],[1,[30,0,[\"percentage\"]]],[1,\"%\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "plutof/components/progress-bar.hbs",
    "isStrictMode": false
  });
  class ProgressBar extends _component2.default {
    get percentage() {
      return Math.round(this.args.progress * 100);
    }
    get progressStyle() {
      const percentage = this.args.progress * 100;
      return (0, _template.htmlSafe)(`width: ${percentage}%;`);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProgressBar);
});