define("plutof/transforms/string", ["exports", "@ember/utils", "@ember-data/serializer/transform"], function (_exports, _utils, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StringTransform extends _transform.default {
    deserialize(serialized) {
      return serialized;
    }
    serialize(string) {
      return (0, _utils.isNone)(string) ? '' : string;
    }
  }
  var _default = _exports.default = StringTransform;
});