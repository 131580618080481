define("plutof/components/datacite/alternate-identifier-row-form", ["exports", "@ember/component", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _component2, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'identifier.alternate_identifier': (0, _emberCpValidations.validator)('presence', true),
    'identifier.alternate_identifier_type': (0, _emberCpValidations.validator)('presence', true)
  });
  let AlternateIdentifierRowForm = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class AlternateIdentifierRowForm extends _component.default.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = AlternateIdentifierRowForm;
});