define("plutof/components/conservation/red-list/view/fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <GenericViews::Record @record={{@redlist}} as |view|>
          {{view.attr "name"}}
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  <Trait::List::View @form={{@redlist.form}} />
  
  <PlutofPanel::Simple @title={{i18n-t "conservation.redList.fields.area"}}>
      {{! TODO: ViewMap needs a better API }}
      <Map::ViewMap
          @geodata={{@redlist.area_polygon}}
          @singular={{true}}
          @geomProperty=""
          @defaultControl={{null}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "7ZXwyUt5",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"name\"],null]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@form\"],[[30,1,[\"form\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"conservation.redList.fields.area\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"    \"],[8,[39,4],null,[[\"@geodata\",\"@singular\",\"@geomProperty\",\"@defaultControl\"],[[30,1,[\"area_polygon\"]],true,\"\",null]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@redlist\",\"view\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"trait/list/view\",\"map/view-map\"]]",
    "moduleName": "plutof/components/conservation/red-list/view/fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});