define("plutof/components/information/content/project/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Information::MarkdownReader @path="area/types" @locale={{@locale}} />
  
  <Information::CollapsedSection @label={{i18n-t "Projects.managingProjects"}}>
      <Information::MarkdownReader @path="project" />
  </Information::CollapsedSection>
  
  */
  {
    "id": "kN6DDnRe",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@locale\"],[\"area/types\",[30,1]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"Projects.managingProjects\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,0],null,[[\"@path\"],[\"project\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@locale\"],false,[\"information/markdown-reader\",\"information/collapsed-section\",\"i18n-t\"]]",
    "moduleName": "plutof/components/information/content/project/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});