define("plutof/templates/organization/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6YReTZA0",
    "block": "[[[10,0],[14,0,\"route-marker-organization-index\"],[14,\"data-test\",\"route-organization.index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoContent\"],[[28,[37,1],[\"organization\"],null],[28,[37,2],[\"Agents.orgTitle\"],null],\"organization.add\",[30,0,[\"model\",\"pagination\"]],\"information/content/organization/index\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"organization.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"agent_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\\n\"]],[\"Field\",\"organization\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/organization/index.hbs",
    "isStrictMode": false
  });
});