define("plutof/controllers/study/index", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ProjectIndexController = (_dec = (0, _object.computed)('model.pagination.objects.[]'), _class = class ProjectIndexController extends _controller.default {
    get listViewParams() {
      return {
        columns: [{
          label: 'name',
          property: 'name',
          clickable: true
        }, {
          label: 'modified',
          property: 'updated_at'
        }, {
          label: 'edit'
        }],
        objects: this.model.pagination.objects,
        editRoute: 'study.edit',
        viewRoute: 'study.view'
      };
    }
  }, _applyDecoratedDescriptor(_class.prototype, "listViewParams", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "listViewParams"), _class.prototype), _class);
  var _default = _exports.default = ProjectIndexController;
});