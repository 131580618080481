define("plutof/components/tables/-list-view/order-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (component "tables/-list-view/order-group-field"
      field=@field
      orderedBy=@orderedBy
      ascending=@ascending
      disabled=@pagination.isLoading
      sortBy=this.sortBy
  )}}
  
  */
  {
    "id": "qmIrj07/",
    "block": "[[[18,5,[[50,\"tables/-list-view/order-group-field\",0,null,[[\"field\",\"orderedBy\",\"ascending\",\"disabled\",\"sortBy\"],[[30,1],[30,2],[30,3],[30,4,[\"isLoading\"]],[30,0,[\"sortBy\"]]]]]]],[1,\"\\n\"]],[\"@field\",\"@orderedBy\",\"@ascending\",\"@pagination\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "plutof/components/tables/-list-view/order-group.hbs",
    "isStrictMode": false
  });
  let ListRouteTableOrderGroup = (_class = class ListRouteTableOrderGroup extends _component2.default {
    sortBy(key) {
      let ascending = this.args.ascending;
      if (key === this.args.orderedBy) {
        ascending = !ascending;
      }
      this.args.update(key, ascending);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "sortBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortBy"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ListRouteTableOrderGroup);
});