define("plutof/components/navbar/-navbar-base/title", ["exports", "@ember/component", "@ember-decorators/component", "plutof/misc/routing"], function (_exports, _component, _component2, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let NavbarTitle = (_dec = (0, _component2.classNames)('navbar-base__title'), _dec(_class = class NavbarTitle extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "routingStatus", (0, _routing.getRoutingStatus)());
    }
  }) || _class);
  var _default = _exports.default = NavbarTitle;
});