define("plutof/templates/components/array-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "55oPjOgU",
    "block": "[[[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n    \"],[1,[34,0]],[1,\"\\n\"],[41,[33,2],[[[1,\"        ...\\n        \"],[10,1],[14,0,\"plutof-tooltip\"],[12],[1,[34,2]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"textArray\",\"if\",\"tooltip\"]]",
    "moduleName": "plutof/templates/components/array-view.hbs",
    "isStrictMode": false
  });
});