define("plutof/translations/eng/file-repository", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    file: 'File',
    files: 'Files',
    title: 'File Repository',
    infoTitle: 'File Repository',
    newLinkTitle: 'Add new',
    metadata: 'Metadata',
    linkedObjects: 'Linked objects',
    FASTACSVButton: 'Save as CSV',
    FASTACSVMessage: 'CSV file saved to File Repository.',
    FASTACSVLink: 'View',
    largeImage: 'Full',
    colFileName: 'File Name',
    colSize: 'Size',
    colExternalActions: 'Actions',
    colProgress: 'Progress',
    type: 'Type',
    object: 'Object',
    uploads: 'Uploads',
    creator: 'File creator',
    dimensions: 'Dimensions',
    areaSelectDescription: 'Make a selection that will represent the image in PlutoF. The original file will be stored without changes.',
    fileTooBig: bytes => `File is too big (max ${bytes} bytes)`,
    // Consider removing geojson file if area from geojson file creation is cancelled.
    alreadyExists: fileNames => `File(s) ${fileNames} already exist(s) in File Repository.`,
    // More general that .alreadyExists, works for files with the same name but different content
    filenameNotUnique: filename => `File name ${filename} is not unique. A different file with the same name is already attached.`,
    storageUsed: 'Used File Repository storage',
    sharedFileWarning: 'Files in associated data panel will be linked to every record. If a file is relevant only to a single record, remove it from Associated data panel and attach it to the record in the records\' table.',
    preview: 'Preview',
    upload: {
      progress: 'Uploading: {{progress}}%',
      retrying: 'Upload of {{filename}} interupted. Retrying.',
      failed: 'Upload of {{filename}} failed: {{reason}}'
    },
    share: {
      button: 'Share',
      copied: 'File link copied to clipboard'
    },
    types: {
      'other': 'Other',
      'dataset': 'Dataset',
      'sound': 'Sound',
      'text': 'Text',
      'application': 'Application',
      'video': 'Video',
      'image': 'Image'
    }
  };
});