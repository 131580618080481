define("plutof/models/study/dmp", ["exports", "@ember/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DMP = (_dec = (0, _model.attr)({
    defaultValue() {
      return {
        ethical_issues_exist: false,
        project: {
          funding: {
            funder: {},
            grant: {}
          }
        },
        dataset: {
          ids: [],
          keywords: [],
          distribution: {
            host: {
              pid_system: []
            },
            licence: {}
          },
          metadata: {
            metadata_standard_id: {}
          },
          security_and_privacy: [],
          technical_resource: []
        },
        contributors: [],
        costs: [],
        links: [],
        ids: []
      };
    }
  }), _dec2 = (0, _model.belongsTo)('study/study'), _dec3 = (0, _model.belongsTo)('users/user'), _dec4 = (0, _model.belongsTo)('users/user'), _dec5 = (0, _model.belongsTo)('users/user'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string', {
    defaultValue: ''
  }), _dec10 = (0, _object.computed)('dmp.title'), _class = class DMP extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "dmp", _descriptor, this);
      _initializerDefineProperty(this, "project", _descriptor2, this);
      _initializerDefineProperty(this, "created_by", _descriptor3, this);
      _initializerDefineProperty(this, "updated_by", _descriptor4, this);
      _initializerDefineProperty(this, "owner", _descriptor5, this);
      _initializerDefineProperty(this, "is_public", _descriptor6, this);
      _initializerDefineProperty(this, "created_at", _descriptor7, this);
      _initializerDefineProperty(this, "updated_at", _descriptor8, this);
      _initializerDefineProperty(this, "url", _descriptor9, this);
    }
    get representation() {
      return this.dmp.title;
    }
    destroyCompletely() {
      return this.destroyRecord().catch(error => {
        this.rollbackAttributes();
        throw error;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dmp", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "created_by", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updated_by", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "owner", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "is_public", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = DMP;
});