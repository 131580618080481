define("plutof/models/dna-lab/pcr-product", ["exports", "@ember/object", "@ember-data/model", "plutof/models/dna-lab/common", "plutof/utils/display-name-from-value"], function (_exports, _object, _model, _common, _displayNameFromValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const displayName = (0, _displayNameFromValue.displayNames)('dna-lab/pcr-product');
  let PCRProduct = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.belongsTo)('dna-lab/primer'), _dec11 = (0, _model.belongsTo)('dna-lab/primer'), _dec12 = (0, _model.belongsTo)('dna-lab/pcr-product', {
    inverse: null
  }), _dec13 = (0, _model.belongsTo)('dna-lab/pcr', {
    inverse: null
  }), _dec14 = (0, _model.belongsTo)('dna-lab/dna', {
    inverse: null
  }), _dec15 = displayName('purification'), _dec16 = (0, _common.hasSource)('pcr_product', 'dna'), _dec17 = (0, _object.computed)('name'), _class = class PCRProduct extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "identifier", _descriptor2, this);
      _initializerDefineProperty(this, "secondary_identifier", _descriptor3, this);
      _initializerDefineProperty(this, "remarks", _descriptor4, this);
      _initializerDefineProperty(this, "tube_nr", _descriptor5, this);
      _initializerDefineProperty(this, "purification", _descriptor6, this);
      _initializerDefineProperty(this, "forward_primer_tag", _descriptor7, this);
      _initializerDefineProperty(this, "reverse_primer_tag", _descriptor8, this);
      _initializerDefineProperty(this, "updated_at", _descriptor9, this);
      _initializerDefineProperty(this, "forward_primer", _descriptor10, this);
      _initializerDefineProperty(this, "reverse_primer", _descriptor11, this);
      _initializerDefineProperty(this, "pcr_product", _descriptor12, this);
      _initializerDefineProperty(this, "pcr", _descriptor13, this);
      _initializerDefineProperty(this, "dna", _descriptor14, this);
      _initializerDefineProperty(this, "purificationRepresentation", _descriptor15, this);
      _initializerDefineProperty(this, "hasSource", _descriptor16, this);
    }
    get representation() {
      return this.name;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "identifier", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "secondary_identifier", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tube_nr", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "purification", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "forward_primer_tag", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "reverse_primer_tag", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "forward_primer", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "reverse_primer", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pcr_product", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "pcr", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dna", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "purificationRepresentation", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasSource", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = PCRProduct;
});