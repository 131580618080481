define("plutof/components/sequence/regions", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "@ember/object", "@ember/service", "@ember/utils", "plutof/utils/model", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberConcurrency, _object, _service, _utils, _model, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test="Sequence::Regions">
      {{common/local-label "Sequences.sequencedRegions"}}
  
      <AutoComplete::Base
          @backend={{this.autocomplete}}
          @update={{this.add}}
          @params={{hash
              enableVoidQuery=true
              clearOnFocus=true
          }}
          data-test="regions" />
  
      <Common::RecordList
          @records={{@regions}}
          @remove={{this.remove}}
          as |item|
      >
          {{get item "representation"}}
      </Common::RecordList>
  </div>
  
  */
  {
    "id": "Pl5YBm9d",
    "block": "[[[10,0],[14,\"data-test\",\"Sequence::Regions\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"Sequences.sequencedRegions\"],null]],[1,\"\\n\\n    \"],[8,[39,1],[[24,\"data-test\",\"regions\"]],[[\"@backend\",\"@update\",\"@params\"],[[30,0,[\"autocomplete\"]],[30,0,[\"add\"]],[28,[37,2],null,[[\"enableVoidQuery\",\"clearOnFocus\"],[true,true]]]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@records\",\"@remove\"],[[30,1],[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[[30,2],\"representation\"],null]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@regions\",\"item\"],false,[\"common/local-label\",\"auto-complete/base\",\"hash\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/components/sequence/regions.hbs",
    "isStrictMode": false
  });
  let SequenceRegions = (_dec = (0, _model.choices)('taxonoccurrence.sequence.sequencetype.type'), _class = class SequenceRegions extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "types", _descriptor2, this);
      _initializerDefineProperty(this, "typeChoices", _descriptor3, this);
      _initializerDefineProperty(this, "load", _descriptor4, this);
      this.load.perform();
    }
    get autocomplete() {
      return new _backends.StaticAutocomplete({
        records: this.types,
        id: record => record.id,
        value: record => record.display_name
      });
    }
    add(typeChoice) {
      if ((0, _utils.isNone)(typeChoice)) {
        return;
      }
      const type = typeChoice.value;
      if (this.args.regions.some(region => region.type === type)) {
        return;
      }
      const region = this.store.createRecord('taxonoccurrence/sequence/sequencetype', {
        sequence: this.args.sequence,
        type
      });
      this.args.regions.pushObject(region);
    }
    remove(region) {
      this.args.regions.removeObject(region);
      this.args.objectsToDelete.pushObject(region);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "types", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "typeChoices", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "load", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const types = yield this.typeChoices.then(types => types.sortBy('display_name'));
        this.types = types;
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SequenceRegions);
});