define("plutof/validators/empty-or-present", ["exports", "@ember/utils", "ember-cp-validations/validators/base"], function (_exports, _utils, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmptyOrPresent extends _base.default {
    validate(value) {
      return (0, _utils.isEmpty)(value) || (0, _utils.isPresent)(value);
    }
  }
  EmptyOrPresent.reopenClass({
    getDependentsFor() {
      return [];
    }
  });
  var _default = _exports.default = EmptyOrPresent;
});