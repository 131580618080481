define("plutof/components/taxonomy/taxon/view/hybrid", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isHybrid}}
      <GenericViews::Record @record={{@taxon}} as |view|>
          <Layout::RegularGrid @columns={{2}}>
              {{view.link "hybrid_parent_1" route="taxonomy.view"}}
              {{view.link "hybrid_parent_2" route="taxonomy.view"}}
              {{view.field "notho_rank"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  {{/if}}
  
  */
  {
    "id": "FBPhjBYx",
    "block": "[[[41,[30,0,[\"isHybrid\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"hybrid_parent_1\"],[[\"route\"],[\"taxonomy.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"hybrid_parent_2\"],[[\"route\"],[\"taxonomy.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"notho_rank\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@taxon\",\"view\"],false,[\"if\",\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/view/hybrid.hbs",
    "isStrictMode": false
  });
  let TaxonViewHybrid = (_dec = (0, _object.computed)('args.taxon.{hybrid_parent_1,hybrid_parent_2}'), _class = class TaxonViewHybrid extends _component2.default {
    get isHybrid() {
      const taxon = this.args.taxon;
      if ((0, _utils.isNone)(taxon)) {
        return false;
      }
      const hasParent1 = !(0, _utils.isNone)(taxon.get('hybrid_parent_1.id'));
      const hasParent2 = !(0, _utils.isNone)(taxon.get('hybird_parent_2.id'));
      const hasNothoRank = !(0, _utils.isNone)(taxon.get('notho_rank'));
      return hasParent1 || hasParent2 || hasNothoRank;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isHybrid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isHybrid"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaxonViewHybrid);
});