define("plutof/components/layer/view/occurrence-layers", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "plutof/utils/reflection"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberConcurrency, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createLayersModel = createLayersModel;
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Layer.layers"}}>
      <div class="table-responsive">
          <table class="plutof-table table-hover">
              <thead>
                  <tr>
                      <th>{{i18n-t "Layer.layer"}}</th>
                      <th>{{i18n-t "Layer.layerArea"}}</th>
                  </tr>
              </thead>
  
              <tbody>
                  {{#each @layersData.layers as |layer|}}
                      <tr>
                          <td>
                              <LinkTo @route="layer.view" @model={{layer.layer_id}} target="_blank">
                                  {{layer.layer_name}}
                              </LinkTo>
                          </td>
  
                          <td>
                              {{layer.area_name}}
                          </td>
                      </tr>
                  {{/each}}
              </tbody>
          </table>
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "XtNpyZH+",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Layer.layers\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n        \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[1,\"\\n            \"],[10,\"thead\"],[12],[1,\"\\n                \"],[10,\"tr\"],[12],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"Layer.layer\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"Layer.layerArea\"],null]],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"layers\"]]],null]],null],null,[[[1,\"                    \"],[10,\"tr\"],[12],[1,\"\\n                        \"],[10,\"td\"],[12],[1,\"\\n                            \"],[8,[39,4],[[24,\"target\",\"_blank\"]],[[\"@route\",\"@model\"],[\"layer.view\",[30,2,[\"layer_id\"]]]],[[\"default\"],[[[[1,\"\\n                                \"],[1,[30,2,[\"layer_name\"]]],[1,\"\\n                            \"]],[]]]]],[1,\"\\n                        \"],[13],[1,\"\\n\\n                        \"],[10,\"td\"],[12],[1,\"\\n                            \"],[1,[30,2,[\"area_name\"]]],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@layersData\",\"layer\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "plutof/components/layer/view/occurrence-layers.hbs",
    "isStrictMode": false
  });
  let OccurrenceLayersModel = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OccurrenceLayersModel {
    constructor(occurrence) {
      _initializerDefineProperty(this, "layers", _descriptor, this);
      _initializerDefineProperty(this, "geoms", _descriptor2, this);
      this.occurrence = occurrence;
    }
    *load() {
      const layers = yield fetch(`${this.occurrence.url}layers/`).then(response => response.json());
      this.layers = layers.sortBy('layer_name').map(layer => {
        return Object.assign({
          layer_id: (0, _reflection.recordURLToID)(layer.layer)
        }, layer);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "layers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geoms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _class);
  function createLayersModel(occurrence) {
    const model = new OccurrenceLayersModel(occurrence);
    model.load.perform();
    return model;
  }
  class ViewOccurrencelayers extends _component2.default {}
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewOccurrencelayers);
});