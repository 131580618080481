define("plutof/templates/components/tables/bulk-fields-update", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "PETramfw",
    "block": "[[[18,1,[[30,0,[\"values\"]],[50,\"tables/-bulk-update/update-button\",0,null,[[\"update\"],[[28,[37,2],[[30,0],[30,0,[\"runUpdate\"]]],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"component\",\"action\"]]",
    "moduleName": "plutof/templates/components/tables/bulk-fields-update.hbs",
    "isStrictMode": false
  });
});