define("plutof/components/plutof-panel/base", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="panel">
      <div class="panel-heading">
          <div
              {{on "click" @toggle}}
              class="panel-title-container {{unless @open 'panel-title-container--collapsed'}}"
              data-test="panel-title"
          >
              <h4 class="panel-title {{if @invalid 'panel-title--invalid'}}">
                  {{yield (hash badge=(component "plutof-panel/-panel/badge")) to="title"}}
              </h4>
          </div>
      </div>
  
      <div {{collapse @open}}>
          <div class="panel-body {{if @outer 'no-padding'}}">
              {{yield to="body"}}
          </div>
      </div>
  </div>
  
  */
  {
    "id": "IYNxGTub",
    "block": "[[[10,0],[14,0,\"panel\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-heading\"],[12],[1,\"\\n        \"],[11,0],[16,0,[29,[\"panel-title-container \",[52,[51,[30,1]],\"panel-title-container--collapsed\"]]]],[24,\"data-test\",\"panel-title\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n            \"],[10,\"h4\"],[15,0,[29,[\"panel-title \",[52,[30,3],\"panel-title--invalid\"]]]],[12],[1,\"\\n                \"],[18,5,[[28,[37,4],null,[[\"badge\"],[[50,\"plutof-panel/-panel/badge\",0,null,null]]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[11,0],[4,[38,6],[[30,1]],null],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"panel-body \",[52,[30,4],\"no-padding\"]]]],[12],[1,\"\\n            \"],[18,6,null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@open\",\"@toggle\",\"@invalid\",\"@outer\",\"&title\",\"&body\"],false,[\"unless\",\"on\",\"if\",\"yield\",\"hash\",\"component\",\"collapse\"]]",
    "moduleName": "plutof/components/plutof-panel/base.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});