define("plutof/templates/components/experiment/common/material-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "edCduwM+",
    "block": "[[[18,1,[[28,[37,1],null,[[\"disable\",\"selected\",\"select\"],[[30,0,[\"disable\"]],[30,0,[\"selected\"]],[30,0,[\"select\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "plutof/templates/components/experiment/common/material-selector.hbs",
    "isStrictMode": false
  });
});