define("plutof/mixins/default-focus-route", ["exports", "@ember/object/mixin", "@ember/runloop", "@ember/utils", "plutof/misc/config"], function (_exports, _mixin, _runloop, _utils, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Works by just focusing on the first element with default-focus-control class
  const DefaultFocusMixin = _mixin.default.create({
    renderTemplate: function (controller, model) {
      this._super(controller, model);
      if (_config.default.Common.ENABLE_AUTOFOCUS) {
        (0, _runloop.schedule)('afterRender', () => {
          const element = document.querySelector('.default-focus-control');
          if (!(0, _utils.isNone)(element)) {
            element.focus();
          }
        });
      }
    }
  });
  var _default = _exports.default = DefaultFocusMixin;
});