define("plutof/templates/components/menu/profile-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rmJRCkl8",
    "block": "[[[41,[30,0,[\"username\"]],[[[41,[30,0,[\"settings\",\"profile\",\"default_owner\",\"id\"]],[[[1,\"        \"],[1,[30,0,[\"username\"]]],[1,\"\\n\\n        \"],[10,1],[12],[1,\" | \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"default-owner-name\"],[12],[1,\"\\n            \"],[1,[30,0,[\"settings\",\"profile\",\"default_owner\",\"username\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"username\"]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"    \"],[1,[28,[35,1],[\"General.loggingIn\"],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/menu/profile-name.hbs",
    "isStrictMode": false
  });
});