define("plutof/routes/event/add", ["exports", "@ember/utils", "rsvp", "plutof/components/linked-items/edit", "plutof/components/measurements/measurement-form", "plutof/utils/access", "plutof/utils/routes"], function (_exports, _utils, _rsvp, _edit, _measurementForm, _access, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AddSamplingEventRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "title", 'SamplingEvent.add');
    }
    getMainform(params) {
      const store = this.store;
      const id = params.mainform_id;
      if ((0, _utils.isNone)(id)) {
        return store.query('measurement/mainform', {
          type: 'samplingevent'
        }).then(mainforms => {
          return mainforms.get('firstObject');
        });
      } else {
        return store.findRecord('measurement/mainform', id);
      }
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const store = this.store;
      const areaPromise = store.findRecord('sample/samplingarea', params.area_id);
      const mainformPromise = this.getMainform(params);
      return areaPromise.then(area => {
        return mainformPromise.then(mainform => {
          const habitat = store.createRecord('habitat/habitat');
          const event = store.createRecord('sample/samplingevent', {
            samplingarea: area,
            mainform: mainform,
            habitat: habitat
          });
          const accessPromise = (0, _access.create_access_rights)(this.store, event);
          return _rsvp.default.hash({
            area: area,
            event: event,
            habitat: habitat,
            accessRights: accessPromise,
            originalGAs: [],
            gatheringAgents: [],
            measurementsData: _measurementForm.MeasurementsData.create({
              store: store
            }),
            habitatMeasurementsData: _measurementForm.MeasurementsData.create({
              store: store
            }),
            itemData: (0, _edit.createLinkedItemsData)(store, area, {
              useEventFiles: false,
              disabledTabs: ['references', 'glossaries', 'links', 'permits']
            })
          });
        });
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('objectsToDelete', []);
    }
  }
  var _default = _exports.default = AddSamplingEventRoute;
});