define("plutof/templates/components/information/terms-of-service", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "42bgZ5SC",
    "block": "[[[8,[39,0],null,[[\"@open\"],[[30,1]]],[[\"header\",\"header-buttons\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[30,0,[\"strings\",\"title\"]]],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"headerButtons\"]]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,3],[\"click\",[30,2,[\"action\"]]],null],[12],[1,\"\\n                \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"tos\"]],[[[1,\"            \"],[1,[30,0,[\"tos\"]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n            \"],[1,[28,[35,5],[\"General.loading\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n        \"],[8,[39,6],[[24,0,\"btn btn-default plutof-btn-green terms-of-service__accept\"]],[[\"@clicked\",\"@disabled\"],[[30,3],[28,[37,7],[[30,0,[\"tos\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"strings\",\"accept\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,6],[[24,0,\"btn btn-default\"]],[[\"@clicked\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"strings\",\"decline\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@open\",\"headerButton\",\"@accept\",\"@decline\"],false,[\"ui/modal/dialog\",\"each\",\"-track-array\",\"on\",\"if\",\"i18n-t\",\"promise-button\",\"not\"]]",
    "moduleName": "plutof/templates/components/information/terms-of-service.hbs",
    "isStrictMode": false
  });
});