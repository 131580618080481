define("plutof/components/information/content/unite/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4>Collapsible views</h4>
  
  <Information::Unite::Collapse />
  
  */
  {
    "id": "rFg79md2",
    "block": "[[[10,\"h4\"],[12],[1,\"Collapsible views\"],[13],[1,\"\\n\\n\"],[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"information/unite/collapse\"]]",
    "moduleName": "plutof/components/information/content/unite/view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});