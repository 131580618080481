define("plutof/components/collection/view/annotations/annotation-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _object, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.route}}
      <LinkTo @route={{this.route}} @models={{array @annotation.record.id @annotation.id}}>
          {{@annotation.status}}
      </LinkTo>
  {{else}}
      {{@annotation.status}}
  {{/if}}
  
  */
  {
    "id": "T/1+/nvN",
    "block": "[[[41,[30,0,[\"route\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[28,[37,2],[[30,1,[\"record\",\"id\"]],[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"status\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"status\"]]],[1,\"\\n\"]],[]]]],[\"@annotation\"],false,[\"if\",\"link-to\",\"array\"]]",
    "moduleName": "plutof/components/collection/view/annotations/annotation-link.hbs",
    "isStrictMode": false
  });
  class CollectionViewAnnotationLink extends _component2.default {
    get route() {
      const module = _modules.default[this.args.annotation.record.content_type];
      return (0, _object.get)(module, 'routes.annotations.view');
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionViewAnnotationLink);
});