define("plutof/templates/components/search/gbif-filter-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1sS4O+J8",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,1],[15,1,[29,[[30,2,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n\"],[41,[30,1,[\"config\",\"help_text\"]],[[[1,\"                    \"],[1,[30,1,[\"config\",\"help_text\"]]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,0,\"filter-input-ac\"]],[[\"@update\",\"@params\",\"@datasetType\"],[[30,3],[28,[37,4],null,[[\"enableVoidQuery\"],[true]]],[30,0,[\"recordType\"]]]],null],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]],null]],[\"@filter\",\"t\",\"add\"],false,[\"if\",\"tooltip-wrapper\",\"auto-complete/bind-multiple\",\"publishing/gbif/auto-complete\",\"hash\"]]",
    "moduleName": "plutof/templates/components/search/gbif-filter-input.hbs",
    "isStrictMode": false
  });
});