define("plutof/templates/components/datacite/view/general-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gBgg8pKW",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Datacite::View::GeneralPanel\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"identifier\"],null,[[\"default\"],[[[[41,[30,1,[\"registered\"]],[[[1,\"                    \"],[10,3],[15,6,[29,[[30,0,[\"identifierURL\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer\"],[12],[1,\"\\n                        \"],[1,[30,1,[\"identifier\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,1,[\"identifier\"]]],[1,\" (\"],[1,[28,[35,1],[\"Publishing.notRegistered\"],null]],[1,\")\\n\"]],[]]]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"citation\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"title\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"subtitle\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"abstract\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"resource_type_general\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"resource_type\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"publisher\"],[[\"route\"],[\"organization.view\"]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"publication_year\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"language\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"language\",\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"licence\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@metadata\",\"view\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\",\"if\"]]",
    "moduleName": "plutof/templates/components/datacite/view/general-panel.hbs",
    "isStrictMode": false
  });
});