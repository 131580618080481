define("plutof/templates/components/profile/groups/-user-groups-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "X+5pLnhH",
    "block": "[[[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"isUnverified\"]],[[[1,\"        \"],[1,[30,1,[\"group\",\"representation\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"profile.groups.group\",[30,0,[\"groupID\"]]]],[[\"default\"],[[[[1,[30,1,[\"group_name\"]]]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"canModerate\"]],[[[41,[30,1,[\"group\",\"users_waiting_approval\"]],[[[1,\"                \"],[10,1],[14,0,\"user-pending-approval\"],[12],[1,\"\\n                    \"],[10,1],[14,0,\"fas fa-exclamation-triangle\"],[12],[13],[1,\"\\n\\n                    \"],[1,[28,[35,2],[\"Settings.groups.requestsPending\"],[[\"count\"],[[30,1,[\"group\",\"users_waiting_approval\"]]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"rightsDisplayName\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"statusDisplayName\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@membership\"],false,[\"if\",\"link-to\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/profile/groups/-user-groups-row.hbs",
    "isStrictMode": false
  });
});