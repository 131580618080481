define("plutof/templates/components/sample/navbar-buttons/cloning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6hdRM4zr",
    "block": "[[[8,[39,0],null,[[\"@tooltip\"],[[28,[37,1],[\"cloning.cloneTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@clicked\"],[[28,[37,2],[\"clone\"],null],[28,[37,3],[[30,0,[\"clone\"]],true,true],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"cloning.clone\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@tooltip\"],[[28,[37,1],[\"cloning.linkToAreaTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@clicked\"],[[28,[37,2],[\"use-as-template\"],null],[28,[37,3],[[30,0,[\"clone\"]],false,true],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"cloning.linkToArea\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@tooltip\"],[[28,[37,1],[\"cloning.linkToEventTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@clicked\"],[[28,[37,2],[\"use-as-template\"],null],[28,[37,3],[[30,0,[\"clone\"]],false,false],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"cloning.linkToEvent\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@navbar\"],false,[\"tool-tip\",\"i18n-t\",\"icon\",\"fn\"]]",
    "moduleName": "plutof/templates/components/sample/navbar-buttons/cloning.hbs",
    "isStrictMode": false
  });
});