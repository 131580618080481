define("plutof/templates/components/menu/button-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KV02lkyc",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"main-menu-button-bar__btn\"],[4,[38,1],[\"click\",[30,0,[\"personalMenu\",\"open\"]]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__icon icon-my-menu\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"General.myMenu\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],[[24,0,\"main-menu-button-bar__btn\"]],[[\"@route\"],[\"profile\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__icon fas fa-sliders-h\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"General.Settings\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],[[24,0,\"main-menu-button-bar__btn\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__icon icon-person\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[1,\"\\n        \"],[1,[34,4]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[11,\"button\"],[24,1,\"logout-button\"],[24,0,\"main-menu-button-bar__btn\"],[24,4,\"button\"],[4,[38,5],[[30,0],[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__icon fas fa-sign-out-alt\"],[12],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"General.logout\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,6],null,[[\"switch\"],[[30,2]]]]],[1,\"\\n\"]],[\"@logOut\",\"@changeLanguage\"],false,[\"menu/-notifications\",\"on\",\"i18n-t\",\"link-to\",\"menu/profile-name\",\"action\",\"menu/language-buttons\"]]",
    "moduleName": "plutof/templates/components/menu/button-bar.hbs",
    "isStrictMode": false
  });
});