define("plutof/templates/sequence/annotation/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KZNZlhAE",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\"],[[28,[37,1],[\"sequence\"],null],[28,[37,2],[\"Sequences.annotation.view\"],null],[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]],\"sequence\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@annotation\",\"@permissions\"],[[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@sequence\",\"@parent\",\"@chimericScore\",\"@canModifySample\",\"@canModifyDeterminations\"],[[30,0,[\"model\",\"sequence\"]],[30,0,[\"model\",\"parent\"]],[30,0,[\"model\",\"chimericScore\"]],false,false]],null],[1,\"\\n\"]],[],false,[\"annotation/navbar/view\",\"icon\",\"i18n-t\",\"annotation/summary\",\"sequence/view\"]]",
    "moduleName": "plutof/templates/sequence/annotation/view.hbs",
    "isStrictMode": false
  });
});