define("plutof/components/agent/acronym", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "@ember-decorators/component", "ember-concurrency", "plutof/mixins/component-validations", "ember-cp-validations", "plutof/utils/modules", "plutof/utils/notifications", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _object, _runloop, _service, _utils, _component2, _emberConcurrency, _componentValidations, _emberCpValidations, _modules, _notifications, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{if this.validations.isInvalid 'has-error'}}">
      <ToolTip @tooltip={{i18n-t "Agents.acronymTooltip"}}>
          <PlutofLabelc @path="organization.abbreviation" @hideTooltip={{true}} />
      </ToolTip>
  
      <OneWayInput
          @value={{@acronym}}
          @update={{this.update}}
          @class={{@class}} />
  </div>
  
  */
  {
    "id": "Aa6Fg285",
    "block": "[[[10,0],[15,0,[29,[[52,[30,0,[\"validations\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[28,[37,2],[\"Agents.acronymTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@path\",\"@hideTooltip\"],[\"organization.abbreviation\",true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@value\",\"@update\",\"@class\"],[[30,1],[30,0,[\"update\"]],[30,2]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@acronym\",\"@class\"],false,[\"if\",\"tool-tip\",\"i18n-t\",\"plutof-labelc\",\"one-way-input\"]]",
    "moduleName": "plutof/components/agent/acronym.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'unique': (0, _emberCpValidations.validator)('is-truthy'),
    'acronym': (0, _validations.nonblank)(128)
  });
  let AcronymInput = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec(_class = (_class2 = class AcronymInput extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "unique", true);
      _initializerDefineProperty(this, "_check", _descriptor2, this);
    }
    check() {
      this._check.perform();
    }
    update(value) {
      this.set('acronym', value);
      (0, _runloop.debounce)(this, this.check, 500);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "_check", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const acronym = this.acronym;
        if ((0, _utils.isEmpty)(acronym)) {
          this.set('unique', true);
          return;
        }
        const model = this.agent.constructor.modelName;
        const match = yield this.store.queryRecord(model, {
          abbreviation: acronym
        });
        (0, _notifications.clearNotifications)();
        if (!match || match.id === this.agent.id) {
          this.set('unique', true);
          return;
        }
        this.set('unique', false);
        let warningMsg = this.i18n.t(`Agents.acronymClashWarning.${model}`);
        const route = this.router.urlFor(_modules.default[model].routes.view, match.id);
        warningMsg += `<p>
            <a href="${route}" target="_blank">
                ${match.abbreviation} - ${match.cheat_full_name}.
            </a>
        </p>`;
        (0, _notifications.displayNotification)('error', warningMsg);
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcronymInput);
});