define("plutof/components/generic-views/pojo-record-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{this.module.routes.view}} @model={{@id}}>
      <span class={{this.module.icon}}></span>
  
      {{#if (has-block)}}
          {{yield}}
      {{else}}
          {{@name}}
      {{/if}}
  </LinkTo>
  
  */
  {
    "id": "VCtyXzWr",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n\\n\"],[41,[48,[30,3]],[[[1,\"        \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"@id\",\"@name\",\"&default\"],false,[\"link-to\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "plutof/components/generic-views/pojo-record-link.hbs",
    "isStrictMode": false
  });
  // Note: @contentType is not ContentType record,
  // but its name: app_label/model. The dream is to replace
  // ContentType objects in API with those names, because
  // ctype objects and their ids are really an implementation
  // detail and just complicate things
  //
  // Basically, have { id, content_type, name } be the usual
  // way in which API points to records
  class POJORecordLink extends _component2.default {
    get module() {
      return _modules.default[this.args.contentType];
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, POJORecordLink);
});