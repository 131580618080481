define("plutof/templates/components/transaction/edit-shipments", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZYFOydX8",
    "block": "[[[8,[39,0],null,[[\"@collapse\"],[[28,[37,1],[[30,1,[\"count\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"transactions.shipments\"],null],[30,1,[\"count\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[28,[37,2],[\"transactions.fillAutoToolt\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[28,[37,4],[[30,0],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[10,1],[15,0,[28,[37,5],[\"copy\"],null]],[12],[13],[1,\"\\n\\n                \"],[1,[28,[35,2],[\"transactions.fillAutomatically\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[28,[37,4],[[30,0],[30,0,[\"add\"]]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@context\",\"@items\"],[[30,4],[30,1,[\"shipments\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,7],null,[[\"@shipment\",\"@validationContext\",\"@remove\"],[[30,5],[30,6],[28,[37,8],[[30,0,[\"remove\"]],[30,5]],null]]],null],[1,\"\\n        \"]],[5,6]]]]],[1,\"\\n\\n        \"],[8,[39,9],null,[[\"@pagination\"],[[30,1,[\"pagination\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@data\",\"panel\",\"@fill\",\"@validationContext\",\"shipment\",\"context\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"tool-tip\",\"action\",\"icon\",\"validation/split-context\",\"transaction/transaction-shipment\",\"fn\",\"pagination/load-more\"]]",
    "moduleName": "plutof/templates/components/transaction/edit-shipments.hbs",
    "isStrictMode": false
  });
});