define("plutof/components/agent/organization/associations/view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @title={{i18n-t "Agents.associations.persons"}}
      @pagination={{@associations}}
  >
      <RelatedObjects::Content @pagination={{@associations}} as |Field association|>
          <Field @label={{backend-translations/label "agentassociation.person"}}>
              <LinkTo @route="person.view" @model={{association.person.id}}>
                  {{association.person.representation}}
              </LinkTo>
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.agent_role"}}>
              {{association.agent_role}}
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.start_date"}}>
              {{association.start_date}}
          </Field>
  
          <Field @label={{backend-translations/label "agentassociation.end_date"}}>
              {{association.end_date}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "O6pt0yXd",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@pagination\"],[[28,[37,1],[\"Agents.associations.persons\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"agentassociation.person\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"person.view\",[30,3,[\"person\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"person\",\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"agentassociation.agent_role\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"agent_role\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"agentassociation.start_date\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"start_date\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"agentassociation.end_date\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"end_date\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@associations\",\"Field\",\"association\"],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/agent/organization/associations/view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});