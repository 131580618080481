define("plutof/templates/components/clipboard/clipboard-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vWdfaT0g",
    "block": "[[[10,0],[14,0,\"flex-row flex-row--wide\"],[12],[1,\"\\n\"],[1,\"    \"],[1,[28,[35,0],null,[[\"pagination\",\"maxPageSize\",\"removeSpacing\",\"updatePageSize\"],[[30,1],100,true,[28,[37,1],[[30,0],[30,2]],null]]]]],[1,\"\\n\\n\"],[1,\"    \"],[10,0],[14,0,\"table-control__button-panel\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@headers\",\"@absoluteOrdering\",\"@showMenu\",\"@toggleColumn\",\"@pinColumn\",\"@columnMenuButton\",\"@setColumnsVisibility\"],[[30,0,[\"headers\"]],[30,3],[30,0,[\"showColumnMenu\"]],[30,0,[\"toggleColumn\"]],[28,[37,3],[[30,4],[30,0,[\"headers\"]]],null],[30,0,[\"columnMenuButton\"]],[30,0,[\"setColumnsVisibility\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@used\",\"@available\",\"@orderableLimit\"],[[30,5,[\"totalCount\"]],[30,5,[\"clipboardSize\"]],[30,5,[\"orderableLimit\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@dbObjects\",\"@elasticObjects\",\"@viewRoute\",\"@removeDB\",\"@removeElastic\"],[[30,5,[\"invalid_db_objects\"]],[30,5,[\"invalid_elastic_objects\"]],[30,6],[30,7],[30,8]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showTable\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@headers\",\"@rows\",\"@absoluteOrdering\",\"@contentType\",\"@mustUpdate\",\"@hideRecordIcons\",\"@enableRowSelection\",\"@sortBy\",\"@pinColumn\",\"@selectRow\",\"@selectAllRows\",\"@toggleColumn\"],[[30,0,[\"headers\"]],[30,0,[\"rows\"]],[30,3],[99,8,[\"@contentType\"]],true,true,true,[30,9],[28,[37,3],[[30,4],[30,0,[\"headers\"]]],null],[30,0,[\"selectRow\"]],[30,0,[\"selectAllRows\"]],[30,0,[\"toggleColumn\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@pagination\",\"@updatePageSize\",\"@absoluteOrdering\",\"@pinColumn\",\"@pageData\",\"@viewRoute\",\"@removeInvalidDatabaseObjects\",\"@removeInvalidElasticObjects\",\"@orderBy\"],false,[\"search/pagination-controls\",\"action\",\"search/column-control\",\"fn\",\"clipboard/table-info\",\"clipboard/invalid-objects\",\"if\",\"search/results-table\",\"contentType\"]]",
    "moduleName": "plutof/templates/components/clipboard/clipboard-table.hbs",
    "isStrictMode": false
  });
});