define("plutof/controllers/clipboard/utils/remove-objects", ["exports", "@ember/object", "@ember/object/mixin", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/promises", "plutof/utils/string"], function (_exports, _object, _mixin, _service, _utils, _environment, _i18n, _notifications, _promises, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RemoveObjectsMixin = void 0;
  const i18n = (0, _i18n.getI18n)();
  const MAX_URL_LENGTH = 1900;
  function ajaxDelete(ajax, url) {
    return ajax.raw(url, {
      method: 'DELETE'
    }).then(({
      jqXHR
    }) => {
      return jqXHR.responseJSON;
    });
  }

  // TODO: Make this not a mixin, but atm it's so tightly coupled with
  // the clipboard, it's too much work and I'm here just to remove
  // partials. At least Clipboard::BulkUpdate is already wrapped and
  // not a partial.
  const RemoveObjectsMixin = _exports.RemoveObjectsMixin = _mixin.default.create({
    listRoutes: (0, _service.inject)(),
    bulkEndpoint: null,
    _batchDelete: function (baseUrl, idParam, objectIDs, options) {
      const paramsUrl = (0, _utils.isEmpty)(options) ? `?${idParam}=` : `?${new window.URLSearchParams(options)}&${idParam}=`;
      const url = baseUrl + paramsUrl;
      const maxLength = MAX_URL_LENGTH - url.length;
      const batches = (0, _string.chopString)(objectIDs.join(), ',', maxLength);
      const deleteFunctions = batches.map(ids => () => ajaxDelete(this.ajax, `${url}${ids}`));
      return _promises.default.batch(deleteFunctions, 1);
    },
    _removeObjectsFromClipboard: function (objectIDs) {
      const baseURL = `${this.clipboardURL}deletion/`;
      if ((0, _utils.isEmpty)(objectIDs)) {
        return ajaxDelete(this.ajax, baseURL);
      }
      return this._batchDelete(baseURL, 'object_list', objectIDs);
    },
    removeObjects: function (objectIDs) {
      (0, _notifications.displayNotification)('status', i18n.t('Clipboard.removingEntries'));
      return this._removeObjectsFromClipboard(objectIDs).then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        this.listRoutes.reset(this.contentType);
      }, error => {
        (0, _notifications.reportError)(error);
        throw error;
      });
    },
    deleteObjects: function (objectIDs, options) {
      let deleted;
      (0, _notifications.displayNotification)('status', i18n.t('General.deleting'));
      const baseURL = `${_environment.default.API_HOST}/${this.bulkEndpoint}`;
      if (objectIDs.length === 0) {
        const params = Object.assign({
          clipboard: true
        }, options);
        const url = `${baseURL}?${new window.URLSearchParams(params)}`;
        deleted = ajaxDelete(this.ajax, url);
      } else {
        deleted = this._batchDelete(baseURL, 'ids', objectIDs, options).then(batchResults => {
          return batchResults.reduce((acc, cur) => ({
            async: acc.async && cur.async,
            removed: acc.removed + cur.removed,
            unremod: acc.unremoved + cur.unremoved
          }));
        });
      }
      return deleted.then(stats => {
        return this._removeObjectsFromClipboard(objectIDs).then(() => {
          this.invalidatePageCache();
          const message = stats.async ? this.i18n.t('Clipboard.delete.result.async') : this.i18n.t('Clipboard.delete.result.sync', {
            hash: stats
          });
          (0, _notifications.displayNotification)('success', message);
        });
      }).catch(_notifications.reportError);
    },
    canNotRemove: (0, _object.computed)('pageData.results.[]', 'pageLoading', function () {
      return this.pageLoading || (0, _utils.isEmpty)(this.get('pageData.results'));
    }),
    actions: {
      // Remove selected clipboard objects
      removeSelectedObjects: function () {
        const selected = this.selectedEntries;
        this.bumpVersion();
        this.currentPage.removeObjects(selected);
        return this.removeObjects(selected.mapBy('id')).then(() => {
          this.selectedEntries.forEach(function (entry) {
            entry.set('selected', false);
          });
          this.invalidatePageCache();
        });
      },
      removeInvalidDatabaseObjects: function (objectIDs) {
        return this.removeObjects(objectIDs).then(() => {
          this.get('pageData.invalid_db_objects').removeObjects(objectIDs);
          this.invalidatePageCache();
        });
      },
      removeInvalidElasticObjects: function (objects) {
        return this.removeObjects(objects.mapBy('id')).then(() => {
          this.get('pageData.invalid_elastic_objects').removeObjects(objects);
          this.invalidatePageCache();
        });
      },
      deleteSelectedObjects(options) {
        const objectIDs = this.selectedEntries.mapBy('id');
        return this.deleteObjects(objectIDs, options);
      },
      deleteAllObjects(options) {
        return this.deleteObjects([], options);
      }
    }
  });
});