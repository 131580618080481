define("plutof/templates/components/filerepository/image-area/-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iYBEh/1f",
    "block": "[[[10,0],[14,0,\"image-preview\"],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,1]],[15,1,[29,[[30,0,[\"elementId\"]],\"-image\"]]],[14,0,\"image-preview__image\"],[12],[13],[1,\"\\n\\n    \"],[10,0],[15,1,[29,[[30,0,[\"elementId\"]],\"-selection\"]]],[14,0,\"image-preview__selection\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@source\"],false,[]]",
    "moduleName": "plutof/templates/components/filerepository/image-area/-selector.hbs",
    "isStrictMode": false
  });
});