define("plutof/components/sample/area/auto-complete", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _object, _service, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @disabled={{@disabled}}
      @params={{@params}}
      ...attributes
  >
      <:result as |result context|>
          {{hl-subs result.value context.query}}
  
          {{#if result.full.is_site}}
              <span class="plutof-ac-suggestion-description">
                  ({{i18n-t "Autocomplete.predefinedArea"}})
              </span>
          {{/if}}
  
          {{#if result.full.owner}}
              <span class="plutof-ac-suggestion-description">
                  {{result.full.owner}}
              </span>
          {{/if}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "r+KvCgWy",
    "block": "[[[8,[39,0],[[17,1]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[30,0,[\"backend\"]],[30,2],[30,3],[30,4],[30,5]]],[[\"result\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[[30,6,[\"value\"]],[30,7,[\"query\"]]],null]],[1,\"\\n\\n\"],[41,[30,6,[\"full\",\"is_site\"]],[[[1,\"            \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                (\"],[1,[28,[35,3],[\"Autocomplete.predefinedArea\"],null]],[1,\")\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,6,[\"full\",\"owner\"]],[[[1,\"            \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                \"],[1,[30,6,[\"full\",\"owner\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[6,7]]]]],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@update\",\"@disabled\",\"@params\",\"result\",\"context\"],false,[\"auto-complete/base\",\"hl-subs\",\"if\",\"i18n-t\"]]",
    "moduleName": "plutof/components/sample/area/auto-complete.hbs",
    "isStrictMode": false
  });
  let PersonAutoComplete = _exports.default = (_dec = (0, _object.computed)('args.site', 'args.project', 'args.access'), _class = class PersonAutoComplete extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get backend() {
      const filters = {};
      if (this.args.site !== undefined) {
        filters.site = this.args.site;
      }
      if (this.args.project) {
        filters.study = this.args.project.id;
      }

      // This is here because it was set in the original area
      // autocomplete by default. Should we also have it default
      // to 'edit' here?
      if (this.args.access !== undefined) {
        filters.access = this.args.access;
      }
      return new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'sample/samplingarea',
        filters
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "backend", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backend"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PersonAutoComplete);
});