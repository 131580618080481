define("plutof/templates/components/check-box", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "coVbt6ZU",
    "block": "[[[10,\"label\"],[14,0,\"plutof-label plutof-label--overflow\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[33,1],[33,2]]]]],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"        \"],[1,[28,[35,5],null,[[\"path\",\"source\",\"tagName\"],[[33,6],[33,7],\"span\"]]]],[1,\"\\n\"]],[]],[[[41,[33,6],[[[1,\"        \"],[10,1],[15,0,[29,[\"plutof-label plutof-label--overflow \",[52,[33,2],\"text-muted\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,8],[[33,6]],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[13],[1,\"\\n\"]],[],false,[\"input\",\"checked\",\"disabled\",\"if\",\"isField\",\"plutof-labelc\",\"label\",\"source\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/check-box.hbs",
    "isStrictMode": false
  });
});