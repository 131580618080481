define("plutof/utils/formatters", ["exports", "@ember/utils", "moment", "plutof/misc/config"], function (_exports, _utils, moment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.URL_REGEX = _exports.ORCID_URL_REGEX = _exports.INT_REGEX = _exports.EMAIL_REGEX = _exports.DEFAULT_DATETIME_FORMAT = _exports.DECIMAL_REGEX = _exports.ANY_PRECISION_DECIMAL_REGEX = _exports.ABS_REGEX = void 0;
  _exports.formatTimespan = formatTimespan;
  _exports.getAmbiguousDateFormat = getAmbiguousDateFormat;
  _exports.getFormattedAmbiguousDate = getFormattedAmbiguousDate;
  _exports.parseAmbiguousDate = parseAmbiguousDate;
  const EMAIL_REGEX = _exports.EMAIL_REGEX = /^[A-za-z0-9._-]+@[A-za-z0-9._-]+\.[a-z]{2,}$/;
  const DEFAULT_DATETIME_FORMAT = _exports.DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
  const ABS_REGEX = _exports.ABS_REGEX = /^[0-9]+$/;
  const DECIMAL_REGEX = _exports.DECIMAL_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;
  const ANY_PRECISION_DECIMAL_REGEX = _exports.ANY_PRECISION_DECIMAL_REGEX = new RegExp('^((-[1-9])|\\d)\\d*([.]\\d+)?$');
  const INT_REGEX = _exports.INT_REGEX = /^-?[0-9]+$/;
  const ORCID_URL_REGEX = _exports.ORCID_URL_REGEX = /^https:\/\/orcid\.org\/\d{4}-\d{4}-\d{4}-\d{4}$/;

  // From https://uibakery.io/regex-library/url
  const URL_REGEX = _exports.URL_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  function getAmbiguousDateFormat(date, ignoreTime = false) {
    if ((0, _utils.isNone)(date)) {
      return null;
    }
    let format = ignoreTime ? 'YYYY-MM-DD' : DEFAULT_DATETIME_FORMAT;
    const SHORT_DATE = 4;
    const MEDIUM_DATE = 7;
    const LONG_DATE = 10;
    switch (date.length) {
      case SHORT_DATE:
        format = 'YYYY';
        break;
      case MEDIUM_DATE:
        format = 'YYYY-MM';
        break;
      case LONG_DATE:
        format = 'YYYY-MM-DD';
        break;
      // no default
    }
    return format;
  }
  function formatTimespan(begin, end, ignoreTime = false) {
    if (end === begin) {
      end = null;
    }
    let strings = [begin, end].reject(_utils.isEmpty).map(function (datetime) {
      return moment(datetime).format(getAmbiguousDateFormat(datetime, ignoreTime));
    });
    if (strings.length === 2 && strings[0] === strings[1]) {
      strings = [strings[0]];
    }
    return strings.join(' - ');
  }

  // TODO: should rename the module to utils/datetime or smth
  // TODO: Clean up the TZ/format mess
  //
  // NB: This assumes that the date passed is in UTC, don't use it to handle user input
  function parseAmbiguousDate(date, format = undefined) {
    if (_config.default.COERCE_TO_LOCAL_UTC_OFFSET) {
      const offset = -new Date().getTimezoneOffset();
      const usedFormat = (0, _utils.isEmpty)(format) ? getAmbiguousDateFormat(date) : format;
      let mmnt = moment.utc(date);
      if (!mmnt.isValid()) {
        mmnt = moment.utc(date, usedFormat);
      }
      return mmnt.utcOffset(offset);
    } else {
      return moment(date);
    }
  }
  function getFormattedAmbiguousDate(value) {
    return moment(value).format(getAmbiguousDateFormat(value));
  }
});