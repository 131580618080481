define("plutof/templates/components/linked-items/edit-links", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "34RJasHt",
    "block": "[[[10,0],[14,0,\"linked-items__form-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"linked-items__form add-component\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n            \"],[1,[28,[35,0],null,[[\"path\"],[\"reference.externallink.link\"]]]],[1,\"\\n\\n            \"],[1,[28,[35,1],null,[[\"type\",\"value\",\"placeholder\",\"class\"],[\"text\",[33,2],\"https://www.example.com/\",\"form-control control linked-items__external-link__link\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n            \"],[1,[28,[35,0],null,[[\"path\"],[\"reference.externallink.description\"]]]],[1,\"\\n\\n            \"],[8,[39,3],[[24,0,\"linked-items__form-textarea form-control control\"]],[[\"@value\"],[[99,4,[\"@value\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n            \"],[11,\"button\"],[16,\"disabled\",[28,[37,5],[[33,6]],null]],[24,0,\"btn btn-default control linked-items__external-link__add-button\"],[4,[38,7],[[30,0],\"add\"],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,8],[\"ok\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,9],[\"General.Add\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@records\",\"@remove\"],[[30,1,[\"items\"]],[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"link\"]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\\n\"],[1,[28,[35,11],null,[[\"pagination\"],[[33,12,[\"pagination\"]]]]]],[1,\"\\n\"]],[\"@itemStore\",\"item\"],false,[\"plutof-labelc\",\"input\",\"link\",\"flexible-textarea\",\"linkDescription\",\"not\",\"canAdd\",\"action\",\"icon\",\"i18n-t\",\"common/record-list\",\"pagination/load-more\",\"itemStore\"]]",
    "moduleName": "plutof/templates/components/linked-items/edit-links.hbs",
    "isStrictMode": false
  });
});