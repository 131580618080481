define("plutof/components/annotation/bulk/multiple-field", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Annotation::Bulk::Field
      @label={{@label}}
      @field={{@field}}
      as |wrapper|
  >
      {{yield (hash
          disabled=wrapper.disabled
          add=@field.add
      ) to="input"}}
  
      <Common::RecordList
          @records={{@field.added}}
          @remove={{@field.noLongerAdd}}
          as |item|
      >
          {{#if (has-block "item")}}
              {{yield item to="item"}}
          {{else}}
              {{item.representation}}
          {{/if}}
      </Common::RecordList>
  </Annotation::Bulk::Field>
  
  */
  {
    "id": "XVGNd7Vb",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"disabled\",\"add\"],[[30,3,[\"disabled\"]],[30,2,[\"add\"]]]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@records\",\"@remove\"],[[30,2,[\"added\"]],[30,2,[\"noLongerAdd\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"            \"],[18,6,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,4,[\"representation\"]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[4]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@label\",\"@field\",\"wrapper\",\"item\",\"&input\",\"&item\"],false,[\"annotation/bulk/field\",\"yield\",\"hash\",\"common/record-list\",\"if\",\"has-block\"]]",
    "moduleName": "plutof/components/annotation/bulk/multiple-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});