define("plutof/templates/series/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qHCz2y1m",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"series.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\"],[[28,[37,2],[\"reference\"],null],[28,[37,3],[\"References.editRefSeries\"],null],[30,0,[\"model\",\"series\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@series\",\"@accessRights\",\"@validationContext\",\"@save\"],[[30,0,[\"model\",\"series\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\"]],[30,0,[\"save\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"series/edit\"]]",
    "moduleName": "plutof/templates/series/edit.hbs",
    "isStrictMode": false
  });
});