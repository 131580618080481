define("plutof/components/plutof-select/multiple/choices", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofSelect::Multiple::-Wrap
      @value={{this.items}}
      @add={{this.add}}
      @remove={{this.remove}}
  >
      <:select as |wrap|>
          <PlutofSelect::Value
              @content={{@choices}}
              @oneWay={{true}}
              @value={{wrap.value}}
              @selectionChanged={{wrap.update}} />
      </:select>
  
      <:selection as |choice|>
          {{choice.display_name}}
      </:selection>
  </PlutofSelect::Multiple::-Wrap>
  
  */
  {
    "id": "sb9Lg+Xk",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@add\",\"@remove\"],[[30,0,[\"items\"]],[30,0,[\"add\"]],[30,0,[\"remove\"]]]],[[\"select\",\"selection\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@content\",\"@oneWay\",\"@value\",\"@selectionChanged\"],[[30,2],true,[30,1,[\"value\"]],[30,1,[\"update\"]]]],null],[1,\"\\n    \"]],[1]],[[[1,\"\\n        \"],[1,[30,3,[\"display_name\"]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[\"wrap\",\"@choices\",\"choice\"],false,[\"plutof-select/multiple/-wrap\",\"plutof-select/value\"]]",
    "moduleName": "plutof/components/plutof-select/multiple/choices.hbs",
    "isStrictMode": false
  });
  let MultipleChoiceSelect = (_dec = (0, _object.computed)('args.choices.[]'), _dec2 = (0, _object.computed)('args.value.[]', 'choiceMap'), _class = class MultipleChoiceSelect extends _component2.default {
    get choiceMap() {
      const map = {};
      this.args.choices.forEach(choice => {
        map[choice.value] = choice;
      });
      return map;
    }
    get items() {
      return this.args.value.map(value => this.choiceMap[value]);
    }
    add(choice) {
      if (!this.args.value.includes(choice.value)) {
        this.args.value.pushObject(choice.value);
      }
    }
    remove(choice) {
      this.args.value.removeObject(choice.value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "choiceMap", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "choiceMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MultipleChoiceSelect);
});