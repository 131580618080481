define("plutof/components/search/filter-panels/unite-sh-taxon", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
      data-panel-name="taxon-properties"
  >
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput
              @filter={{@filterInputs.taxon_node}}
              @autocompletePreset="includeCommonnames" />
  
          <Search::FilterInput
              @filter={{@filterInputs.sequence_taxon}}
              @autocompletePreset="includeCommonnames" />
  
          <Search::Filters::Typification
              @filter={{@filterInputs.typification}}
              @existsFilter={{@filterInputs.has_typification}} />
  
          <Search::FilterInput @filter={{@filterInputs.interacting_taxa}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.parent_taxon}} />
          <Search::FilterInput @filter={{@filterInputs.sequence_parent_taxon}} />
          <Search::FilterInput @filter={{@filterInputs.has_conflict}} />
          <Search::FilterInput @filter={{@filterInputs.interacting_subtaxa}} />
          <Search::FilterInput @filter={{@filterInputs.has_interactions}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "bk96eMdE",
    "block": "[[[8,[39,0],[[24,\"data-panel-name\",\"taxon-properties\"]],[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"taxon_node\"]],\"includeCommonnames\"]],null],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"sequence_taxon\"]],\"includeCommonnames\"]],null],[1,\"\\n\\n        \"],[8,[39,4],null,[[\"@filter\",\"@existsFilter\"],[[30,1,[\"typification\"]],[30,1,[\"has_typification\"]]]],null],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"interacting_taxa\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"parent_taxon\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"sequence_parent_taxon\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"has_conflict\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"interacting_subtaxa\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"has_interactions\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"search/filter-input\",\"search/filters/typification\"]]",
    "moduleName": "plutof/components/search/filter-panels/unite-sh-taxon.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});