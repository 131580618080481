define("plutof/components/search/filter-panels/photobank/properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.agents}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}} as |grid|>
          <Search::FilterInput
              @filter={{@filterInputs.study}}
              @autocompletePreset="study/study" />
  
          {{#grid.span 2}}
              <Search::RepositoryInput
                  @repositoryFilter={{@filterInputs.deposited_in}}
                  @subrepositoryFilter={{@filterInputs.sub_repository}} />
          {{/grid.span}}
  
          <Search::FilterInput @filter={{@filterInputs.preparation_types}} />
          <Search::FilterInput @filter={{@filterInputs.category}} />
          <Search::FilterInput @filter={{@filterInputs.colour_scheme}} />
          <Search::FilterInput @filter={{@filterInputs.custom_classifications}} />
          <Search::FilterInput @filter={{@filterInputs.references}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
      </Layout::RegularGrid>
  
      <Search::FilterPanels::Photobank::Checkboxes @filterInputs={{@filterInputs}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "kq7bdf0c",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"agents\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\",\"@autocompletePreset\"],[[30,1,[\"study\"]],\"study/study\"]],null],[1,\"\\n\\n\"],[6,[30,2,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"            \"],[8,[39,5],null,[[\"@repositoryFilter\",\"@subrepositoryFilter\"],[[30,1,[\"deposited_in\"]],[30,1,[\"sub_repository\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"preparation_types\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"category\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"colour_scheme\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"custom_classifications\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"references\"]]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"keywords\"]]]],null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@filterInputs\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\",\"grid\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"search/filter-panels/common-properties/agents\",\"layout/regular-grid\",\"search/filter-input\",\"search/repository-input\",\"search/filter-panels/photobank/checkboxes\"]]",
    "moduleName": "plutof/components/search/filter-panels/photobank/properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});