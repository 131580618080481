define("plutof/components/referencebased/table-row", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/model"], function (_exports, _component, _object, _component2, _emberCpValidations, _componentValidations, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'entry.occurrence.taxon_node': (0, _emberCpValidations.validator)('presence', true)
  });
  let RefbasedTableRow = (_dec = (0, _component2.classNames)('refbased-table__row'), _dec2 = (0, _model.choices)('taxonoccurrence.referencebased.occurrence.identification_assessment'), _dec3 = (0, _model.choices)('taxonoccurrence.referencebased.occurrence.occurrence_assessment'), _dec(_class = (_class2 = class RefbasedTableRow extends _component.default.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "MARKER", true);
      _defineProperty(this, "biostatusOpen", false);
      _defineProperty(this, "assessmentOpen", true);
      _defineProperty(this, "taxonDetailsOpen", false);
      _defineProperty(this, "linkedFilesOpen", false);
      _initializerDefineProperty(this, "identificationAssessmentChoices", _descriptor, this);
      _initializerDefineProperty(this, "occurrenceAssessmentChoices", _descriptor2, this);
    }
    toggleBiostatus() {
      this.toggleProperty('biostatusOpen');
    }
    toggleTaxonDetails() {
      this.toggleProperty('taxonDetailsOpen');
    }
    toggleLinkedFiles() {
      this.toggleProperty('linkedFilesOpen');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "identificationAssessmentChoices", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "occurrenceAssessmentChoices", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleBiostatus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleBiostatus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleTaxonDetails", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleTaxonDetails"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleLinkedFiles", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleLinkedFiles"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = RefbasedTableRow;
});