define("plutof/templates/components/interaction/edit-multiple", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AVZOfBCH",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,1],[\"General.interactions\"],null],[30,2,[\"entries\",\"length\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@class\"],[[30,2,[\"add\"]],\"test-marker-btn-add\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"innerPanels\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@context\",\"@items\",\"@loopKey\"],[[30,3],[30,2,[\"entries\"]],\"fadeId\"]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,3],null,[[\"class\"],[\"edit-interactions__interaction\"]],[[\"default\"],[[[[1,\"                \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n                    \"],[8,[39,5],null,[[\"@data\",\"@validationContext\",\"@remove\"],[[30,4,[\"interaction\"]],[30,5],[28,[37,6],[[30,2,[\"remove\"]],[30,4]],null]]],null],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"        \"]],[4,5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"panel\",\"@data\",\"@validationContext\",\"entry\",\"context\"],false,[\"plutof-panel/item-list\",\"i18n-t\",\"validation/split-context\",\"fade-element\",\"plutof-panel/inner\",\"interaction/form\",\"fn\"]]",
    "moduleName": "plutof/templates/components/interaction/edit-multiple.hbs",
    "isStrictMode": false
  });
});