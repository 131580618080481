define("plutof/components/annotation/-summary/value", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (not @value)}}
      ∅
  {{else if (is-equal @kind "attribute")}}
      {{@value}}
  {{else if (is-equal @kind "belongsTo")}}
      {{@value.representation}}
  {{else}}
      <Common::RecordListView @records={{@value}} as |related|>
          {{related.representation}}
      </Common::RecordListView>
  {{/if}}
  
  */
  {
    "id": "thbGf6M+",
    "block": "[[[41,[28,[37,1],[[30,1]],null],[[[1,\"    ∅\\n\"]],[]],[[[41,[28,[37,2],[[30,2],\"attribute\"],null],[[[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,2],\"belongsTo\"],null],[[[1,\"    \"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"representation\"]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"@value\",\"@kind\",\"related\"],false,[\"if\",\"not\",\"is-equal\",\"common/record-list-view\"]]",
    "moduleName": "plutof/components/annotation/-summary/value.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});