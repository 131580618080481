define("plutof/components/information/content/general/area-project-filter", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @locale.eng}}
      When taxon occurrence <strong>project</strong> has been set, <strong>Parent area</strong> and <strong>Existing area</strong> can be selected only from the project's areas.
  {{else}}
      Kui taksoni esinemisel on valitud <strong>projekt</strong>, saab <strong>ülem ala</strong> ja <strong>olemasolevat ala</strong> valida ainult selle projekti alade hulgast.
  {{/if}}
  
  */
  {
    "id": "1IUZIXnp",
    "block": "[[[41,[30,1,[\"eng\"]],[[[1,\"    When taxon occurrence \"],[10,\"strong\"],[12],[1,\"project\"],[13],[1,\" has been set, \"],[10,\"strong\"],[12],[1,\"Parent area\"],[13],[1,\" and \"],[10,\"strong\"],[12],[1,\"Existing area\"],[13],[1,\" can be selected only from the project's areas.\\n\"]],[]],[[[1,\"    Kui taksoni esinemisel on valitud \"],[10,\"strong\"],[12],[1,\"projekt\"],[13],[1,\", saab \"],[10,\"strong\"],[12],[1,\"ülem ala\"],[13],[1,\" ja \"],[10,\"strong\"],[12],[1,\"olemasolevat ala\"],[13],[1,\" valida ainult selle projekti alade hulgast.\\n\"]],[]]]],[\"@locale\"],false,[\"if\"]]",
    "moduleName": "plutof/components/information/content/general/area-project-filter.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});