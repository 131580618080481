define("plutof/components/related-objects/content", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Pagination::PageControls @pagination={{@pagination}} />
  
  <Tables::List
      @records={{@pagination.objects}}
      @headersClasses="related-objects-view__headers"
      @rowClasses="related-objects-view__row"
      as |Field object|
  >
      {{yield Field object}}
  </Tables::List>
  
  <div class="spacer-large"></div>
  
  <Pagination::PageControls
      @pagination={{@pagination}}
      @removeSpacing={{true}} />
  
  */
  {
    "id": "es7a5W9w",
    "block": "[[[8,[39,0],null,[[\"@pagination\"],[[30,1]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@records\",\"@headersClasses\",\"@rowClasses\"],[[30,1,[\"objects\"]],\"related-objects-view__headers\",\"related-objects-view__row\"]],[[\"default\"],[[[[1,\"\\n    \"],[18,4,[[30,2],[30,3]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-large\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@pagination\",\"@removeSpacing\"],[[30,1],true]],null],[1,\"\\n\"]],[\"@pagination\",\"Field\",\"object\",\"&default\"],false,[\"pagination/page-controls\",\"tables/list\",\"yield\"]]",
    "moduleName": "plutof/components/related-objects/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});