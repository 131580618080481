define("plutof/templates/components/profile/groups/group-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nJu36dKz",
    "block": "[[[41,[30,0,[\"open\",\"isRunning\"]],[[[1,\"    \"],[10,1],[14,0,\"loading-icon\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[\"@group\"],false,[\"if\"]]",
    "moduleName": "plutof/templates/components/profile/groups/group-link.hbs",
    "isStrictMode": false
  });
});