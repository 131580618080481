define("plutof/components/project/view/record-project", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise @record.guardedProject as |project|}}
      <PlutofPanel::Simple
          @title={{i18n-t "General.Project"}}
          @collapse={{and (not project.isPrivate) (not project)}}
          data-test="Record::Project"
      >
          {{#if project.isPrivate}}
              <Common::PrivateRelation />
          {{else}}
              <Project::View::RecordProjectFields
                  @project={{project}}
                  @annotated={{annotation/fields-modified @record 'project'}} />
          {{/if}}
      </PlutofPanel::Simple>
  {{/resolve-promise}}
  
  */
  {
    "id": "oDvQRg1R",
    "block": "[[[6,[39,0],[[30,1,[\"guardedProject\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],[[24,\"data-test\",\"Record::Project\"]],[[\"@title\",\"@collapse\"],[[28,[37,2],[\"General.Project\"],null],[28,[37,3],[[28,[37,4],[[30,2,[\"isPrivate\"]]],null],[28,[37,4],[[30,2]],null]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"isPrivate\"]],[[[1,\"            \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,7],null,[[\"@project\",\"@annotated\"],[[30,2],[28,[37,8],[[30,1],\"project\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"@record\",\"project\"],false,[\"resolve-promise\",\"plutof-panel/simple\",\"i18n-t\",\"and\",\"not\",\"if\",\"common/private-relation\",\"project/view/record-project-fields\",\"annotation/fields-modified\"]]",
    "moduleName": "plutof/components/project/view/record-project.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});