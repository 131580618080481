define("plutof/utils/area-event", ["exports", "rsvp", "plutof/utils/push-to-store", "plutof/utils/reflection"], function (_exports, _rsvp, _pushToStore, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.occurrenceAreaEvent = occurrenceAreaEvent;
  _exports.occurrence_area = occurrence_area;
  _exports.updateOccurrenceArea = updateOccurrenceArea;
  _exports.updateOccurrenceEvent = updateOccurrenceEvent;
  async function occurrenceAreaEvent(store, occurrence) {
    if (store.inAnnotation) {
      const event = await occurrence.samplingevent;
      const area = await event.samplingarea;
      return {
        event,
        area
      };
    }
    const ajax = (0, _reflection.getService)(store, 'ajax');
    return _rsvp.default.hash({
      event: ajax.request(eventEndpoint(occurrence)).then(eventData => (0, _pushToStore.default)(store, 'sample/samplingevent', eventData)),
      area: ajax.request(areaEndpoint(occurrence)).then(areaData => (0, _pushToStore.default)(store, 'sample/samplingarea', areaData))
    });
  }
  function updateOccurrenceArea(occurrence, area) {
    return update(areaEndpoint(occurrence), area);
  }
  function updateOccurrenceEvent(occurrence, event) {
    return update(eventEndpoint(occurrence), event);
  }
  function eventEndpoint(occurrence) {
    const occurrenceURL = (0, _reflection.get_record_url)(occurrence);
    return occurrenceURL + 'event/';
  }
  function areaEndpoint(occurrence) {
    const occurrenceURL = (0, _reflection.get_record_url)(occurrence);
    return occurrenceURL + 'area/';
  }
  async function update(endpoint, record) {
    if (record.store.inAnnotation) {
      return record.saveToEndpoint(endpoint);
    }
    const ajax = (0, _reflection.getService)(record.store, 'ajax');
    const response = await ajax.put(endpoint, {
      data: (0, _reflection.serializeRecord)(record)
    });
    const updated = await (0, _pushToStore.default)(record.store, record.constructor.modelName, response);
    return updated;
  }
  function occurrence_area(store, occurrencePromise) {
    const ajax = (0, _reflection.getService)(store, 'ajax');
    return occurrencePromise.then(occurrence => ajax.request((0, _reflection.get_record_url)(occurrence) + 'samplingarea/').then(response => {
      // Do you want sites? Because this is how you get sites.
      const record = (0, _pushToStore.default)(store, 'sample/samplingarea', response);

      // XXX: Required because peekRecord (used in push_record) is not properly overridable by AnnotateStore
      return store.findRecord(record.constructor.modelName, record.id);
    }));
  }
});