define("plutof/components/event/edit", ["exports", "@ember/component", "@ember/template-factory", "plutof/mixins/component-validations"], function (_exports, _component, _templateFactory, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Sample::SampleBreadcrumbs @event={{@event}} />
  
  <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Sample::EventFieldsAddedit
          @event={{@event}}
          @agents={{@gatheringAgents}}
          @validationContext={{@validationContext.event}}
          data-test="Event::GeneralData" />
  </PlutofPanel::Simple>
  
  <Habitat::EditPanel
      @habitat={{@habitat}}
      @measurements={{@habitatMeasurementsData}}
      @validationContext={{validationContext.habitat}}
      @collapse={{false}}
      data-test="Event::HabitatData" />
  
  <Measurements::AddExtra::Panel
      @measurements={{@measurementsData}}
      @module="samplingevent"
  >
      <Measurements::MeasurementForm
          @object={{@event}}
          @data={{@measurementsData}}
          @row_length={{4}}
          @validationContext={{@validationContext.measurements}} />
  </Measurements::AddExtra::Panel>
  
  <LinkedItems::Edit
      @data={{@associatedData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <InvalidPanels @validationContext={{@validationContext}} />
  
  <div class="action-buttons">
      <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
      <Common::CancelButton @cancel={{route-action "goBack"}} />
  </div>
  
  */
  {
    "id": "uyQS2Ajw",
    "block": "[[[8,[39,0],null,[[\"@event\"],[[30,1]]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\"],[[28,[37,2],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],[[24,\"data-test\",\"Event::GeneralData\"]],[[\"@event\",\"@agents\",\"@validationContext\"],[[30,1],[30,2],[30,3,[\"event\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],[[24,\"data-test\",\"Event::HabitatData\"]],[[\"@habitat\",\"@measurements\",\"@validationContext\",\"@collapse\"],[[30,4],[30,5],[33,5,[\"habitat\"]],false]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@measurements\",\"@module\"],[[30,6],\"samplingevent\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@object\",\"@data\",\"@row_length\",\"@validationContext\"],[[30,1],[30,6],4,[30,3,[\"measurements\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@data\",\"@validationContext\"],[[30,7],[30,3,[\"associatedData\"]]]],null],[1,\"\\n\\n\"],[8,[39,9],null,[[\"@validationContext\"],[[30,3]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n    \"],[8,[39,10],null,[[\"@save\",\"@disabled\"],[[30,8],[30,3,[\"isInvalid\"]]]],null],[1,\"\\n    \"],[8,[39,11],null,[[\"@cancel\"],[[28,[37,12],[\"goBack\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@event\",\"@gatheringAgents\",\"@validationContext\",\"@habitat\",\"@habitatMeasurementsData\",\"@measurementsData\",\"@associatedData\",\"@save\"],false,[\"sample/sample-breadcrumbs\",\"plutof-panel/simple\",\"i18n-t\",\"sample/event-fields-addedit\",\"habitat/edit-panel\",\"validationContext\",\"measurements/add-extra/panel\",\"measurements/measurement-form\",\"linked-items/edit\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"route-action\"]]",
    "moduleName": "plutof/components/event/edit.hbs",
    "isStrictMode": false
  });
  class EventEdit extends _component.default.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'event',
        label: 'General.generalData'
      }, {
        name: 'habitat',
        label: 'SamplingEvent.habitat'
      }, {
        name: 'measurements',
        label: 'General.measurements'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }]);
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventEdit);
});