define("plutof/components/clipboard/external-actions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @externalActions as |externalAction|}}
      <button
          {{action externalAction.action}}
          disabled={{@disabled}}
          class="btn btn-default plutof-btn-green"
      >
          <span class="{{externalAction.icon}}"></span>
  
          <span class="hidden-sm hidden-xs">
              {{i18n-t externalAction.label count=(if @selectedCount (locale-string @selectedCount) (i18n-t 'Clipboard.all'))}}
          </span>
      </button>
  {{/each}}
  
  */
  {
    "id": "ufyU4VeA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[11,\"button\"],[16,\"disabled\",[30,3]],[24,0,\"btn btn-default plutof-btn-green\"],[4,[38,2],[[30,0],[30,2,[\"action\"]]],null],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[[30,2,[\"icon\"]]]]],[12],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"hidden-sm hidden-xs\"],[12],[1,\"\\n            \"],[1,[28,[35,3],[[30,2,[\"label\"]]],[[\"count\"],[[52,[30,4],[28,[37,5],[[30,4]],null],[28,[37,3],[\"Clipboard.all\"],null]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null]],[\"@externalActions\",\"externalAction\",\"@disabled\",\"@selectedCount\"],false,[\"each\",\"-track-array\",\"action\",\"i18n-t\",\"if\",\"locale-string\"]]",
    "moduleName": "plutof/components/clipboard/external-actions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});