define("plutof/components/search/filter-panels/area-fields", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <Search::Filters::Location @filter={{@filterInputs.geom}} />
          </div>
  
          <div>
              <Search::FilterInput @filter={{@filterInputs.study}} />
              <Search::FilterInput @filter={{@filterInputs.country}} />
              <Search::FilterInput @filter={{@filterInputs.state}} />
              <Search::FilterInput @filter={{@filterInputs.district}} />
              <Search::FilterInput @filter={{@filterInputs.commune}} />
              <Search::FilterInput @filter={{@filterInputs.locality_text}} />
              <Search::FilterInput @filter={{@filterInputs.parish}} />
              <Search::FilterInput @filter={{@filterInputs.is_site}} />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "YOBBM8FO",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"geom\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"study\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"country\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"state\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"district\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"commune\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"locality_text\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"parish\"]]]],null],[1,\"\\n            \"],[8,[39,4],null,[[\"@filter\"],[[30,1,[\"is_site\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"search/filters/location\",\"search/filter-input\"]]",
    "moduleName": "plutof/components/search/filter-panels/area-fields.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});