define("plutof/components/conservation/red-list/view/assessments", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @pagination={{@assessments}}
      @add={{@add}}
      @canModify={{@canModify}}
      @searchModule="red-list-assessments"
      @searchQuery={{hash red_list=@redlist.id}}
      @title={{i18n-t "conservation.redList.view.assessments"}}
  >
      <RelatedObjects::Content @pagination={{@assessments}} as |Field assessment|>
          <Field @label={{backend-translations/label "red-list-assessment.title"}}>
              <LinkTo @route="conservation-lab.red-list-assessment.view" @model={{assessment.id}}>
                  {{assessment.title}}
              </LinkTo>
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.taxon_node"}}>
              {{assessment.taxon_node.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.assessor"}}>
              {{assessment.assessor.representation}}
          </Field>
  
          <Field @label={{backend-translations/label "red-list-assessment.reviewer"}}>
              {{assessment.reviewer.representation}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "lBPWah/F",
    "block": "[[[8,[39,0],null,[[\"@pagination\",\"@add\",\"@canModify\",\"@searchModule\",\"@searchQuery\",\"@title\"],[[30,1],[30,2],[30,3],\"red-list-assessments\",[28,[37,1],null,[[\"red_list\"],[[30,4,[\"id\"]]]]],[28,[37,2],[\"conservation.redList.view.assessments\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,5],null,[[\"@label\"],[[28,[37,4],[\"red-list-assessment.title\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"conservation-lab.red-list-assessment.view\",[30,6,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,6,[\"title\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,5],null,[[\"@label\"],[[28,[37,4],[\"red-list-assessment.taxon_node\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,6,[\"taxon_node\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,5],null,[[\"@label\"],[[28,[37,4],[\"red-list-assessment.assessor\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,6,[\"assessor\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,5],null,[[\"@label\"],[[28,[37,4],[\"red-list-assessment.reviewer\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,6,[\"reviewer\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[5,6]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@assessments\",\"@add\",\"@canModify\",\"@redlist\",\"Field\",\"assessment\"],false,[\"related-objects/view\",\"hash\",\"i18n-t\",\"related-objects/content\",\"backend-translations/label\",\"link-to\"]]",
    "moduleName": "plutof/components/conservation/red-list/view/assessments.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});