define("plutof/components/annotation/-fields/moderator", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@field @label={{i18n-t "annotation.fields.moderator"}}>
      {{@annotation.moderator.name}}
  </@field>
  
  
  */
  {
    "id": "NGamxVsy",
    "block": "[[[8,[30,1],null,[[\"@label\"],[[28,[37,0],[\"annotation.fields.moderator\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"moderator\",\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"]],[\"@field\",\"@annotation\"],false,[\"i18n-t\"]]",
    "moduleName": "plutof/components/annotation/-fields/moderator.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});