define("plutof/templates/components/observation/taxon-sheet/form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SWD5W79h",
    "block": "[[[41,[51,[30,0,[\"sheet\",\"items\",\"length\"]]],[[[1,\"    \"],[10,1],[14,0,\"fas fa-sync-alt spinicon\"],[12],[13],[1,\"\\n     \\n    \"],[1,[28,[35,1],[\"General.loading\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"    \"],[1,[28,[35,4],null,[[\"item\",\"selected\",\"toggle\"],[[30,2,[\"item\"]],[30,2,[\"selected\"]],[28,[37,5],[[30,0],[30,3],[30,2]],null]]]]],[1,\"\\n\"]],[2]],null]],[\"@entries\",\"entry\",\"@toggle\"],false,[\"unless\",\"i18n-t\",\"each\",\"-track-array\",\"observation/taxon-sheet/item\",\"action\"]]",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/form.hbs",
    "isStrictMode": false
  });
});