define("plutof/templates/components/tables/-bulk-update/selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KbtpJii6",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[30,0,[\"selected\"]],[30,1]]]]],[1,\"\\n\"]],[\"@disabled\"],false,[\"input\"]]",
    "moduleName": "plutof/templates/components/tables/-bulk-update/selector.hbs",
    "isStrictMode": false
  });
});