define("plutof/templates/components/tables/bulk-update/measurements", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qZB5xrON",
    "block": "[[[41,[30,0,[\"load\",\"isIdle\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"load\",\"last\",\"value\"]]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"\\n            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,1,[\"type\"]],\"set\"],null],[[[1,\"                \"],[8,[39,4],null,[[\"@content\",\"@oneWay\",\"@selection\",\"@selectionChanged\",\"@optionLabelPath\",\"@optionValuePath\"],[[30,1,[\"set_choices\"]],true,[28,[37,5],[[30,2],[30,1,[\"id\"]]],null],[28,[37,6],[[30,0,[\"update\"]],[30,1]],null],\"element\",\"id\"]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[39,7],[[24,0,\"form-control\"]],[[\"@value\",\"@update\"],[[28,[37,5],[[30,2],[30,1,[\"id\"]]],null],[28,[37,6],[[30,0,[\"update\"]],[30,1]],null]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[1]],null]],[]],null]],[\"measurement\",\"@selection\"],false,[\"if\",\"each\",\"-track-array\",\"is-equal\",\"plutof-select/object\",\"get\",\"fn\",\"one-way-input\"]]",
    "moduleName": "plutof/templates/components/tables/bulk-update/measurements.hbs",
    "isStrictMode": false
  });
});