define("plutof/templates/components/import/relation-auto-complete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "G1Srcvrb",
    "block": "[[[41,[30,1,[\"field\",\"multiple\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@model\",\"@filters\",\"@update\"],[[30,0,[\"localModel\"]],[30,1,[\"field\",\"filters\"]],[30,0,[\"addMultipleInput\"]]]],null],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@records\",\"@remove\"],[[30,1,[\"relation\"]],[30,0,[\"removeMultipleInput\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@model\",\"@filters\",\"@value\",\"@update\"],[[30,0,[\"localModel\"]],[30,1,[\"field\",\"filters\"]],[30,1,[\"relation\"]],[30,0,[\"updateInput\"]]]],null],[1,\"\\n\"]],[]]]],[\"@cell\"],false,[\"if\",\"auto-complete/generic\",\"common/record-list\"]]",
    "moduleName": "plutof/templates/components/import/relation-auto-complete.hbs",
    "isStrictMode": false
  });
});