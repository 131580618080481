define("plutof/templates/components/occurrences-table/-responsive-row/cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "j/GIY1iY",
    "block": "[[[41,[30,1],[[[1,\"    \"],[46,[30,1],null,[[\"class\"],[\"occ-table-responsive-row__cell-label\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"@label\",\"&default\"],false,[\"if\",\"component\",\"yield\"]]",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/cell.hbs",
    "isStrictMode": false
  });
});