define("plutof/templates/components/clipboard/bulk-update-sampling-event", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "p4PomyDF",
    "block": "[[[41,[30,0,[\"inputs\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"description\"]]]],[[\"default\"],[[[[1,\"            \"],[8,[39,4],[[24,0,\"form-control event-fields-addedit__description\"]],[[\"@value\",\"@disabled\"],[[30,1,[\"value\"]],[30,1,[\"disabled\"]]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"gathering_agents\"]]]],[[\"default\"],[[[[1,\"            \"],[8,[39,5],null,[[\"@value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[8,[39,6],[[24,0,\"event-fields-addedit__gathering-agents\"]],[[\"@update\",\"@disabled\"],[[30,3],[30,2,[\"disabled\"]]]],null],[1,\"\\n            \"]],[3]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"timespan_begin\"]]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,7],null,[[\"value\",\"disabled\",\"validity\",\"dateIsRequired\",\"useEmptyLabels\",\"class\"],[[30,4,[\"value\"]],[30,4,[\"disabled\"]],[33,8],[33,9],false,\"event-fields-addedit__timespan-begin\"]]]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"timespan_end\"]]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,7],null,[[\"value\",\"disabled\",\"validity\",\"dateIsRequired\",\"after\",\"useEmptyLabels\",\"class\"],[[30,5,[\"value\"]],[30,5,[\"disabled\"]],[33,10],[33,11],[33,12,[\"timespan_begin\"]],false,\"event-fields-addedit__timespan-end\"]]]],[1,\"\\n\"]],[5]]]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"iw\",\"iw\",\"add\",\"iw\",\"iw\"],false,[\"if\",\"layout/regular-grid\",\"clipboard/input-wrapper\",\"inputs\",\"flexible-textarea\",\"auto-complete/bind-multiple\",\"agent/person/auto-complete\",\"date-time-input\",\"timespanBeginIsValid\",\"requireTimespanBegin\",\"timespanEndIsValid\",\"requireTimespanEnd\",\"event\"]]",
    "moduleName": "plutof/templates/components/clipboard/bulk-update-sampling-event.hbs",
    "isStrictMode": false
  });
});