define("plutof/components/tables/bulk-update", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TableBulkUpdate = (_dec = (0, _component2.classNames)('table-bulk-update'), _dec(_class = (_class2 = class TableBulkUpdate extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "selection", new Set());
      _defineProperty(this, "selectionSize", 0);
      _defineProperty(this, "isOpen", false);
    }
    // Manual sync needed because sets are not observable in Ember. Maybe I should just use an array, but,
    // logically, selection is a set. Will switch on further problems
    selectionChanged() {
      this.set('selectionSize', this.selection.size);
    }
    toggle(record, include) {
      if (include) {
        this.selection.add(record);
      } else {
        this.selection.delete(record);
      }
      this.selectionChanged();
    }
    add(records) {
      const updated = new Set(this.selection);
      records.forEach(record => updated.add(record));

      // Have to replace selection completely to trigger selector updates
      this.set('selection', updated);
      this.selectionChanged();
    }
    clean() {
      this.set('selection', new Set());
      this.selectionChanged();
      this.set('isOpen', false);
    }
    update(fn) {
      this.selection.forEach(fn);
      this.clean();
    }

    // Don't want to pass the actual set around
    isSelected(record) {
      return this.selection.has(record);
    }
    togglePanel() {
      this.toggleProperty('isOpen');
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clean", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clean"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "togglePanel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "togglePanel"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TableBulkUpdate;
});