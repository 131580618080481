define("plutof/templates/components/sample/gathering-agents", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vVb8Wq73",
    "block": "[[[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[33,1,[\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@value\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],[[24,0,\"gathering-agents__agents\"]],[[\"@update\"],[[30,2]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@agents\",\"add\"],false,[\"if\",\"validations\",\"auto-complete/bind-multiple\",\"agent/person/auto-complete\"]]",
    "moduleName": "plutof/templates/components/sample/gathering-agents.hbs",
    "isStrictMode": false
  });
});