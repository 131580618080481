define("plutof/components/agent/organization-view/substructures", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _component, _templateFactory, _object, _service, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @add={{this.add}}
      @canModify={{true}}
      @pagination={{this.pagination}}
      @title={{@title}}
  >
      <RelatedObjects::Content @pagination={{this.pagination}} as |Field organization|>
          <Field @label={{i18n-t "Agents.organization.fields.name"}}>
              <LinkTo @route={{@viewRoute}} @model={{organization.id}}>
                  {{organization.name}}
              </LinkTo>
          </Field>
  
          <Field @label={{i18n-t "General.occurrencesCount.label"}}>
              <Common::OccurrencesCount @queryParams={{hash dp=organization.id}} />
          </Field>
  
          <Field @label={{backend-translations/label "organization.updated_at"}}>
              {{formatted-date organization.updated_at}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "5KaLjne5",
    "block": "[[[8,[39,0],null,[[\"@add\",\"@canModify\",\"@pagination\",\"@title\"],[[30,0,[\"add\"]],true,[30,0,[\"pagination\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@pagination\"],[[30,0,[\"pagination\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"Agents.organization.fields.name\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@route\",\"@model\"],[[30,4],[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"General.occurrencesCount.label\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@queryParams\"],[[28,[37,5],null,[[\"dp\"],[[30,3,[\"id\"]]]]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,6],[\"organization.updated_at\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,7],[[30,3,[\"updated_at\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@title\",\"Field\",\"organization\",\"@viewRoute\"],false,[\"related-objects/view\",\"related-objects/content\",\"i18n-t\",\"link-to\",\"common/occurrences-count\",\"hash\",\"backend-translations/label\",\"formatted-date\"]]",
    "moduleName": "plutof/components/agent/organization-view/substructures.hbs",
    "isStrictMode": false
  });
  let OrganizationViewSubstructures = _exports.default = (_dec = (0, _object.computed)('organizationId'), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OrganizationViewSubstructures extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "updatePagination", _descriptor2, this);
    }
    add() {
      return this.router.transitionTo(this.addRoute, {
        queryParams: {
          parent_organization: this.organizationId
        }
      });
    }
    get queryParams() {
      return {
        parent_organization: this.organizationId,
        ordering: '-updated_at'
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updatePagination.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updatePagination", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const pagination = yield (0, _pagination.paginateModel)(this.store, this.modelPath, this.queryParams);
        this.set('pagination', pagination);
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OrganizationViewSubstructures);
});