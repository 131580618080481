define("plutof/templates/components/analysis/analysis-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HuTU8TJ6",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"run.analysis_type\"]]]],[1,\"\\n\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[33,3,[\"attrs\",\"type\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,4],null,[[\"oneWay\",\"content\",\"value\",\"selectionChanged\",\"disabled\",\"class\",\"selectClass\"],[true,[30,0,[\"enabledTypes\"]],[33,5,[\"id\"]],[28,[37,6],[[30,0],\"typeSelected\"],null],[28,[37,7],[[30,1]],null],\"analysis-data__type\",\"form-control default-focus-control\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"control-group \",[52,[33,3,[\"attrs\",\"name\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\"],[\"run.name\"]]]],[1,\"\\n\\n        \"],[1,[28,[35,8],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,2],\"form-control analysis-data__name\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@setType\",\"@name\"],false,[\"layout/regular-grid\",\"plutof-labelc\",\"if\",\"validations\",\"plutof-select/value\",\"type\",\"action\",\"not\",\"input\"]]",
    "moduleName": "plutof/templates/components/analysis/analysis-data.hbs",
    "isStrictMode": false
  });
});