define("plutof/components/observation/moderation/bulk-controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- ### BULK ACCEPT AND REJECT ### --}}
  <PromiseButton
      @clicked={{@bulkAccept}}
      @disabled={{not @activeEntries.length}}
      @restIcon="{{icon 'ok'}}"
      class="btn btn-default btn-xs"
  >
      {{i18n-t "moderation.accept"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </PromiseButton>
  
  <button {{on 'click' @showRejectPanel}} disabled={{not @activeEntries.length}} class="btn btn-default btn-xs">
      <span class="icon-remove--red"></span>
  
      {{i18n-t "moderation.reject"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </button>
  
  <button
      {{on "click" @bulkForward}}
      disabled={{not @activeEntries.length}}
      class="btn btn-default btn-xs"
  >
      <span class={{icon "observation-moderation-forward"}}></span>
  
      {{i18n-t "moderation.forward"}}
  
      <span class="count-badge count-badge--slim">
          {{@activeEntries.length}}
      </span>
  </button>
  
  */
  {
    "id": "kPO3C1C/",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-default btn-xs\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,1],[28,[37,1],[[30,2,[\"length\"]]],null],[29,[[28,[37,2],[\"ok\"],null]]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"moderation.accept\"],null]],[1,\"\\n\\n    \"],[10,1],[14,0,\"count-badge count-badge--slim\"],[12],[1,\"\\n        \"],[1,[30,2,[\"length\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[28,[37,1],[[30,2,[\"length\"]]],null]],[24,0,\"btn btn-default btn-xs\"],[4,[38,4],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n\\n    \"],[1,[28,[35,3],[\"moderation.reject\"],null]],[1,\"\\n\\n    \"],[10,1],[14,0,\"count-badge count-badge--slim\"],[12],[1,\"\\n        \"],[1,[30,2,[\"length\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[28,[37,1],[[30,2,[\"length\"]]],null]],[24,0,\"btn btn-default btn-xs\"],[4,[38,4],[\"click\",[30,4]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[28,[37,2],[\"observation-moderation-forward\"],null]],[12],[13],[1,\"\\n\\n    \"],[1,[28,[35,3],[\"moderation.forward\"],null]],[1,\"\\n\\n    \"],[10,1],[14,0,\"count-badge count-badge--slim\"],[12],[1,\"\\n        \"],[1,[30,2,[\"length\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@bulkAccept\",\"@activeEntries\",\"@showRejectPanel\",\"@bulkForward\"],false,[\"promise-button\",\"not\",\"icon\",\"i18n-t\",\"on\"]]",
    "moduleName": "plutof/components/observation/moderation/bulk-controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});