define("plutof/utils/string", ["exports", "@ember/string", "@ember/utils", "@ember/template", "escape-html"], function (_exports, _string, _utils, _template, _escapeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeAllWords = capitalizeAllWords;
  _exports.chopString = chopString;
  _exports.isUpperCase = isUpperCase;
  _exports.leftPad = leftPad;
  _exports.repeat = repeat;
  _exports.showWithLinebreaks = showWithLinebreaks;
  _exports.truncate = truncate;
  function repeat(str, count) {
    var parts = [];
    for (var i = 0; i < count; i++) {
      parts.push(str);
    }
    return parts.join('');
  }
  function truncate(string, limit) {
    var newLimit = isNaN(limit) ? 64 : limit;
    if ((0, _utils.isNone)(string) || (0, _utils.isNone)(string['length'])) {
      return null;
    }
    return string.length > newLimit ? string.slice(0, newLimit - 1) + '...' : string;
  }
  function chopString(s, separator, maxLength, stack) {
    stack = stack || [];
    if (s.length <= maxLength) {
      stack.push(s);
      return stack;
    }
    var roughSlice = s.slice(0, maxLength);
    var sliceIndex = roughSlice.lastIndexOf(separator);
    sliceIndex = sliceIndex === -1 ? maxLength : sliceIndex;
    var slice = s.slice(0, sliceIndex);
    var end = s.slice(sliceIndex + 1); // skip the separator

    stack.push(slice);
    return chopString(end, separator, maxLength, stack);
  }
  function leftPad(string, length, padding) {
    if (string.length >= length) {
      return string;
    }
    return repeat(padding, length - string.length) + string;
  }

  // JOHN smith => John Smith
  function capitalizeAllWords(words) {
    return words.toLowerCase().split(' ').map(str => (0, _string.capitalize)(str)).join(' ');
  }
  function isUpperCase(str) {
    return str === str.toUpperCase();
  }
  function showWithLinebreaks(str) {
    return (0, _template.htmlSafe)((0, _escapeHtml.default)(str).replace(/\n/g, '<br>'));
  }
});