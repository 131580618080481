define("plutof/components/gallery/footer/-right-control", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Gallery::Footer::-RightItem>
      <ToolTip @tooltip={{@label}}>
          <button
              {{on "click" @clicked}}
              class="gallery-control-btn"
          >
              <span class={{@icon}}></span>
          </button>
      </ToolTip>
  </Gallery::Footer::-RightItem>
  
  */
  {
    "id": "bn8Ky7+y",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@tooltip\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"button\"],[24,0,\"gallery-control-btn\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[30,3]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@label\",\"@clicked\",\"@icon\"],false,[\"gallery/footer/-right-item\",\"tool-tip\",\"on\"]]",
    "moduleName": "plutof/components/gallery/footer/-right-control.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});