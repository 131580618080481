define("plutof/controllers/specimen/add", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/controllers/specimen/edit", "plutof/misc/config", "plutof/mixins/add-controller", "plutof/utils/cloning", "plutof/utils/notifications"], function (_exports, _object, _computed, _service, _utils, _environment, _edit, _config, _addController, _cloning, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SpecimenAddController = (_dec = (0, _computed.alias)('model.specimenData.specimen.mainform'), _dec2 = (0, _computed.notEmpty)('template_id'), _dec3 = (0, _computed.alias)('model.specimenData.specimen'), _dec4 = (0, _computed.alias)('model.specimenData.sampleData'), _class = class SpecimenAddController extends _edit.default.extend(_addController.default, _cloning.ControllerCloningMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      _initializerDefineProperty(this, "occurrenceMainform", _descriptor2, this);
      _initializerDefineProperty(this, "preserveZoom", _descriptor3, this);
      _initializerDefineProperty(this, "primaryOccurrence", _descriptor4, this);
      _initializerDefineProperty(this, "formData", _descriptor5, this);
      _defineProperty(this, "queryParams", ['template_id', 'area_id', 'event_id', 'materialsample_id', 'project_id', 'mainform_id', 'clone_area', 'clone_event', 'collection_id', 'files']);
      _defineProperty(this, "materialsample_id", null);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "mainform_id", null);
      _defineProperty(this, "clone_area", null);
      _defineProperty(this, "clone_event", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "collection_id", null);
      _defineProperty(this, "files", null);
    }
    init() {
      super.init(...arguments);
      this.set('routeHasBeenLoaded', true);
      this.set('currentExtent', null);
      const forms = [{
        label: 'Forms.mammalia',
        id: _config.default.Specimen.MAMMALIA_MAINFORM_ID
      }, {
        label: 'Forms.bird',
        id: _config.default.Specimen.BIRD_MAINFORM_ID
      }, {
        label: 'Forms.bat',
        id: _config.default.Specimen.BAT_MAINFORM_ID
      }, {
        label: 'Forms.plant',
        id: _config.default.Specimen.PLANT_MAINFORM_ID
      }, {
        label: 'Forms.insect',
        id: _config.default.Specimen.INSECT_MAINFORM_ID
      }, {
        label: 'Forms.fungus',
        id: _config.default.Specimen.FUNGUS_MAINFORM_ID
      }, {
        label: 'Forms.algae',
        id: _config.default.Specimen.ALGAE_MAINFORM_ID
      }, {
        label: 'Forms.animal',
        id: _config.default.Specimen.ANIMAL_MAINFORM_ID
      }].concat(_environment.default.EXTRA_MAINFORMS.specimen);
      this.set('plutofMainforms', forms.map(({
        label,
        id
      }) => ({
        label: this.i18n.t(label),
        id
      })));
    }
    cleanup() {
      this.model.specimenData.cleanup();
    }
    _save() {
      return super._save().then(() => {
        this.listRoutes.reset('specimen/specimen');
      });
    }
    save() {
      return this._save().then(() => {
        this.set('routeHasBeenLoaded', false);
        this.cleanup();
        if ((0, _utils.isNone)(this.template_id)) {
          this.send('goBack');
        } else {
          this.set('template_id', null);
          this.transitionToRoute('specimen.view', this.get('model.specimenData.specimen.id'));
        }
      }).catch(_notifications.reportError);
    }
    cancel() {
      this.cleanup();
      this.set('routeHasBeenLoaded', false);
      this.set('template_id', null);
      this.send('goBack');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "occurrenceMainform", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preserveZoom", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "primaryOccurrence", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = SpecimenAddController;
});