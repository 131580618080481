define("plutof/templates/profile/classifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ylNkV8gY",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"profile.classifications\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-below\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.classifications.taxonCl\",\"Settings.classifications.taxonToolT\"],null]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@backend\",\"@update\",\"@params\"],[[30,0,[\"taxonomyAutocomplete\"]],[30,0,[\"addTaxonomy\"]],[28,[37,3],null,[[\"inputClasses\"],[\"form-control out-of-form-field\"]]]]],[[\"result\"],[[[[1,\"\\n            \"],[1,[28,[35,4],[[30,1,[\"value\"]],[30,2,[\"query\"]]],null]],[1,\"\\n\\n\"],[41,[30,1,[\"full\",\"owner\"]],[[[1,\"                \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                    \"],[1,[30,1,[\"full\",\"owner\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[1,2]]]]],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@records\",\"@remove\"],[[30,0,[\"model\",\"taxonomies\"]],[52,[30,0,[\"canRemoveTaxonomy\"]],[30,0,[\"removeTaxonomy\"]]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"spacer-below\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"Settings.classifications.habitatCl\",\"Settings.classifications.habitatToolT\"],null]],[1,\"\\n\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"model\",\"habitatClassifications\"]]],null]],null],null,[[[1,\"        \"],[8,[39,9],[[24,0,\"inline-flex\"]],[[\"@value\",\"@disabled\"],[[30,3,[\"enabled\"]],[28,[37,10],[[30,3,[\"enabled\"]],[28,[37,11],[[30,0,[\"canRemoveHabitat\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[3]],null],[13],[1,\"\\n\\n\"],[8,[39,12],null,[[\"@save\"],[[30,0,[\"save\"]]]],null],[1,\"\\n\"]],[\"result\",\"attrs\",\"classification\"],false,[\"test/route-marker\",\"common/local-label\",\"auto-complete/base\",\"hash\",\"hl-subs\",\"if\",\"common/record-list\",\"each\",\"-track-array\",\"common/check-box\",\"and\",\"not\",\"common/save-button\"]]",
    "moduleName": "plutof/templates/profile/classifications.hbs",
    "isStrictMode": false
  });
});