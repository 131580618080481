define("plutof/components/taxonomy/taxon/view/taxon-description", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@taxonDescription}}
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{#view.attr "language"}}
                  {{@taxonDescription.language.representation}}
              {{/view.attr}}
  
              {{#view.attr "created_by"}}
                  {{@taxonDescription.created_by.representation}}
              {{/view.attr}}
          </div>
  
          <div>
              <LinkTo @route="taxon-description.view" @model={{@taxonDescription.id}}>
                  {{plutof-labelc "taxondescription.description"}}
              </LinkTo>
  
              <LongText
                  @text={{@taxonDescription.description}}
                  @text-height="height-4" />
          </div>
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "aSO8C7dR",
    "block": "[[[8,[39,0],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"language\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"language\",\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,2,[\"attr\"]],[\"created_by\"],null,[[\"default\"],[[[[1,\"                \"],[1,[30,1,[\"created_by\",\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"taxon-description.view\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"taxondescription.description\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,4],null,[[\"@text\",\"@text-height\"],[[30,1,[\"description\"]],\"height-4\"]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@taxonDescription\",\"view\"],false,[\"generic-views/record\",\"layout/regular-grid\",\"link-to\",\"plutof-labelc\",\"long-text\"]]",
    "moduleName": "plutof/components/taxonomy/taxon/view/taxon-description.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});