define("plutof/components/experiment/primers", ["exports", "@ember/utils", "plutof/config/environment", "plutof/utils/caching"], function (_exports, _utils, _environment, _caching) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extrapolateTags = extrapolateTags;
  _exports.getTagSets = void 0;
  const getTagSets = _exports.getTagSets = (0, _caching.lazy)(function (ajax) {
    const url = `${_environment.default.API_HOST}/dna-lab/primer-tag-sets/`;
    return ajax.request(url).then(response => response.data.map(tagSet => ({
      id: tagSet.id,
      name: tagSet.attributes.name,
      tags: tagSet.attributes.tag_codes
    })));
  });
  async function extrapolateTags(ajax, existing) {
    const seedPosition = existing.findIndex(tag => !(0, _utils.isEmpty)(tag));
    if (seedPosition === -1) {
      return [];
    }
    const seed = existing[seedPosition];
    const tagSets = await getTagSets(ajax);
    const seedSet = tagSets.find(set => set.tags.includes(seed));
    if (!seedSet) {
      return [];
    }
    const seedIndex = seedSet.tags.indexOf(seed);
    let extrapolated = existing.slice();
    for (let setIdx = seedIndex, targetIdx = seedPosition; setIdx >= 0 && targetIdx >= 0; setIdx--, targetIdx--) {
      extrapolated[targetIdx] = seedSet.tags[setIdx];
    }
    for (let setIdx = seedIndex, targetIdx = seedPosition; setIdx < seedSet.tags.length && targetIdx < extrapolated.length; setIdx++, targetIdx++) {
      extrapolated[targetIdx] = seedSet.tags[setIdx];
    }
    return extrapolated;
  }
});