define("plutof/components/common/ui/one-way-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::CheckBox
      @value={{this.proxy}}
      @disabled={{@disabled}}
      ...attributes
  >
      {{yield}}
  </Common::CheckBox>
  
  */
  {
    "id": "GACyyZ7m",
    "block": "[[[8,[39,0],[[17,1]],[[\"@value\",\"@disabled\"],[[30,0,[\"proxy\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@disabled\",\"&default\"],false,[\"common/check-box\",\"yield\"]]",
    "moduleName": "plutof/components/common/ui/one-way-checkbox.hbs",
    "isStrictMode": false
  });
  let OneWayCheckbox = (_dec = (0, _object.computed)('args.value'), _class = class OneWayCheckbox extends _component2.default {
    get proxy() {
      return this.args.value;
    }
    set proxy(value) {
      this.args.update(value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "proxy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "proxy"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OneWayCheckbox);
});