define("plutof/components/generic-views/record-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _object, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.module}}
      <span class={{this.module.icon}}></span>
  
      <LinkTo @route={{this.module.routes.view}} @model={{@record.id}}>
          {{@record.representation}}
      </LinkTo>
  {{else}}
      {{@record.representation}}
  {{/if}}
  
  */
  {
    "id": "G7GgnHlm",
    "block": "[[[41,[30,0,[\"module\"]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"module\",\"icon\"]]],[12],[13],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"representation\"]]],[1,\"\\n\"]],[]]]],[\"@record\"],false,[\"if\",\"link-to\"]]",
    "moduleName": "plutof/components/generic-views/record-link.hbs",
    "isStrictMode": false
  });
  let RecordLink = (_dec = (0, _object.computed)('args.record'), _class = class RecordLink extends _component2.default {
    get module() {
      return this.args.record ? (0, _modules.getRecordModule)(this.args.record) : null;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "module", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "module"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordLink);
});