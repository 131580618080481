define("plutof/components/sequence/parent-selector", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Sequences.linkedTo"}}
      data-test="Sequence::ParentSelector"
  >
      <Common::RadioGroup
          @active={{this.activeType}}
          @activate={{this.switchType}}
          as |radioGroup|
      >
          {{#each this.types as |type|}}
              <radioGroup.choice
                  @name={{type}}
                  data-test={{type.id}}
              >
                  {{i18n-t type.name}}
              </radioGroup.choice>
          {{/each}}
      </Common::RadioGroup>
  
      {{#if this.activeType.model}}
          <Layout::RegularGrid @columns={{2}} @cellGap={{true}}>
              <div class="{{if this.localSelection '' 'has-error'}}">
                  <AutoComplete::Generic
                      @model={{this.activeType.model}}
                      @value={{this.localSelection}}
                      @update={{this.changeParent}}
                      @filters={{hash access=(if @requireEditPermissions "edit" "view")}}
                      data-test="selection" />
              </div>
          </Layout::RegularGrid>
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "gvpJyjce",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Sequence::ParentSelector\"]],[[\"@title\"],[[28,[37,1],[\"Sequences.linkedTo\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@active\",\"@activate\"],[[30,0,[\"activeType\"]],[30,0,[\"switchType\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"types\"]]],null]],null],null,[[[1,\"            \"],[8,[30,1,[\"choice\"]],[[16,\"data-test\",[30,2,[\"id\"]]]],[[\"@name\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,1],[[30,2,[\"name\"]]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"activeType\",\"model\"]],[[[1,\"        \"],[8,[39,6],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[15,0,[29,[[52,[30,0,[\"localSelection\"]],\"\",\"has-error\"]]]],[12],[1,\"\\n                \"],[8,[39,7],[[24,\"data-test\",\"selection\"]],[[\"@model\",\"@value\",\"@update\",\"@filters\"],[[30,0,[\"activeType\",\"model\"]],[30,0,[\"localSelection\"]],[30,0,[\"changeParent\"]],[28,[37,8],null,[[\"access\"],[[52,[30,3],\"edit\",\"view\"]]]]]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"radioGroup\",\"type\",\"@requireEditPermissions\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"common/radio-group\",\"each\",\"-track-array\",\"if\",\"layout/regular-grid\",\"auto-complete/generic\",\"hash\"]]",
    "moduleName": "plutof/components/sequence/parent-selector.hbs",
    "isStrictMode": false
  });
  let ParentSelector = (_dec = (0, _object.computed)('enableLivingSpecimens'), _class = class ParentSelector extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "activeType", null);
      _defineProperty(this, "localSelection", null);
      _defineProperty(this, "enableLivingSpecimens", false);
    }
    get types() {
      const modelType = (model, name) => ({
        id: model,
        name,
        model,
        activate: () => null,
        select: occurrence => this.setToOccurrence(occurrence)
      });
      return [{
        id: 'noParent',
        name: 'GenericSelectorModel.none',
        activate: () => this.unlinkParent(),
        select: () => null
      }, modelType('taxonoccurrence/specimen/specimen', 'GenericSelectorModel.specimen'), this.enableLivingSpecimens ? modelType('taxonoccurrence/livingculture/strain', 'GenericSelectorModel.strain') : null, modelType('taxonoccurrence/materialsample/materialsample', 'GenericSelectorModel.materialsample')].filter(Boolean);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.parent) {
        const type = this.types.findBy('model', this.parent.constructor.modelName);
        if (type) {
          this.setProperties({
            activeType: type,
            localSelection: this.parent
          });
        }
      } else {
        this.setProperties({
          activeType: this.types.findBy('id', 'noParent')
        });
      }
    }
    switchType(type) {
      this.set('activeType', type);
      if (this.parent && type.model === this.parent.constructor.modelName) {
        this.set('localSelection', this.parent);
      } else if (this.event && type.id === 'existingEvent') {
        this.set('localSelection', this.event);
      } else {
        this.set('localSelection', null);
      }
      type.activate();
    }
    changeParent(parent) {
      this.set('localSelection', parent);
      if (parent) {
        this.activeType.select(parent);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "types", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "types"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeParent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeParent"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ParentSelector);
});