define("plutof/mixins/reset", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    activate: function () {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});