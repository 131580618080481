define("plutof/components/chart/pheno-graph-taxon", ["exports", "@ember/component", "@ember/object", "@ember/utils", "moment", "plutof/components/chart/scatter-chart", "plutof/utils/chart", "plutof/utils/i18n"], function (_exports, _component, _object, _utils, moment, _scatterChart, _chart, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  function createChatterOptions(data, field) {
    const names = (0, _scatterChart.getUniqueLabelName)(data);
    return {
      responsive: true,
      title: {
        display: true,
        text: field.label
      },
      legend: {
        display: true,
        labels: {
          fontSize: 10,
          boxWidth: 20
        }
      },
      elements: {
        point: {
          pointStyle: 'rect'
        }
      },
      hover: {
        mode: 'nearest'
      },
      scales: {
        xAxes: [{
          type: 'linear',
          position: 'bottom',
          scaleLabel: {
            display: true,
            labelString: `${i18n.t('chart.time')} (${i18n.t('chart.month')})`
          },
          ticks: {
            min: 1,
            max: 13,
            stepSize: 1,
            callback: value => value === 13 ? '' : value.toString()
          }
        }],
        yAxes: [{
          ticks: {
            reverse: true,
            min: -1,
            max: names.length,
            callback: value => names[value]
          },
          scaleLabel: {
            display: true,
            labelString: `${i18n.t('chart.taxa')}`
          }
        }]
      },
      tooltips: {
        callbacks: {
          title: function ([tooltipItem], data) {
            const tooltipInfo = (0, _scatterChart.getTooltip)(tooltipItem, data.datasets);
            return tooltipInfo.date;
          },
          label: function (tooltipItem, data) {
            const tooltipInfo = (0, _scatterChart.getTooltip)(tooltipItem, data.datasets);
            return i18n.t('chart.count') + ': ' + tooltipInfo.count;
          }
        }
      }
    };
  }
  function createChatterData(results) {
    const years = (0, _scatterChart.getYearsUnique)(results).sort(); // 2011, 2012, 2013
    const names = (0, _scatterChart.getUniqueLabelName)(results); // Aves, Fungi, Algae
    const dataKeys = Object.keys(results); // 1203_2015, 1203_2016, 1203_2017

    const datasets = years.map((key, i) => {
      const chartColor = (0, _chart.getChartColor)(key, i);
      return {
        id: '_' + key,
        // Can later find _2017 _2016 datasets
        backgroundColor: chartColor,
        borderColor: chartColor,
        data: [],
        showLine: false,
        fill: false,
        label: key,
        pointRadius: [],
        pointHoverRadius: []
      };
    });
    datasets.forEach(dataset => {
      // For each year we must create a dataset that contains all taxons ( 2016 - taxon, aves, fungi, 2017 - taxon, aves, fungi etc)
      const matchingYearKeys = dataKeys.filter(k => k.includes(dataset.id)); // All keys that contains _2017 (dataset id)

      matchingYearKeys.forEach(key => {
        const result = results[key]; // (dataset id + year combo) 123_2017

        // Absolute maximum per taxon and per year ( 2017: 201 ) Int.
        let absoluteMaximum = Math.max.apply(Math, result.data.mapBy('count'));
        if (absoluteMaximum < 10) {
          absoluteMaximum = 10; // Looks pretty silly when count 3: is at 100%
        }
        result.data.forEach(datum => {
          const date = datum.key;
          const dayInNumericScale = parseInt(moment(date).format('DD')) / 30 * 100;
          const positionX = parseFloat(moment(date).format('MM') + '.' + Math.round(dayInNumericScale));
          const heightPercent = datum.count * 100 / absoluteMaximum;
          let heightInt = heightPercent * _scatterChart.MAX_RECT_HEIGHT / 100; // percent of max rect height to integer

          if (heightInt < 2) {
            heightInt = 2;
          }
          dataset.data.pushObject({
            x: positionX,
            y: names.indexOf(result.name),
            // Taxon name (fungi)
            count: datum.count,
            date: moment(date).format('MM.DD')
          });
          dataset.pointRadius.push(heightInt);
          dataset.pointHoverRadius.push(heightInt + 1);
        });
      });
    });
    return datasets;
  }
  function createScatterConfig({
    context,
    data,
    field
  }) {
    if ((0, _utils.isEmpty)(data)) {
      return {};
    }
    return {
      type: 'line',
      data: {
        labels: _scatterChart.DEFAULT_LABELS,
        datasets: createChatterData(data, this)
      },
      options: createChatterOptions(data, field)
    };
  }
  let ScatterChart = (_dec = (0, _object.computed)('config.isFulfilled'), _class = class ScatterChart extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", null);
    }
    get chartConfig() {
      const config = this.config;
      if (this.get('config.isFulfilled')) {
        return createScatterConfig(config.getProperties('context', 'data', 'field'));
      } else {
        return null;
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "chartConfig", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "chartConfig"), _class.prototype), _class);
  var _default = _exports.default = ScatterChart;
});