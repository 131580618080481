define("plutof/components/annotation/bulk/records", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _templateFactory, _component2, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RelatedObjects::View
      @title={{i18n-t "annotation.routes.bulk.add.records"}}
      @pagination={{@records}}
  >
      <RelatedObjects::Content @pagination={{@records}} as |Field record|>
          <Field @label={{i18n-t "PlutofListViewColumn.name"}}>
              <LinkTo @route={{this.module.routes.view}} @model={{record.id}}>
                  {{record.name}}
              </LinkTo>
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "Lipj3XU/",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@pagination\"],[[28,[37,1],[\"annotation.routes.bulk.add.records\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@pagination\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,3],null,[[\"@route\",\"@model\"],[[30,0,[\"module\",\"routes\",\"view\"]],[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@records\",\"Field\",\"record\"],false,[\"related-objects/view\",\"i18n-t\",\"related-objects/content\",\"link-to\"]]",
    "moduleName": "plutof/components/annotation/bulk/records.hbs",
    "isStrictMode": false
  });
  class BulkAnnotationRecords extends _component2.default {
    get module() {
      return _modules.default[this.args.contentType];
    }
  }
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkAnnotationRecords);
});