define("plutof/templates/components/filerepository/image-area/select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TszVVYgj",
    "block": "[[[41,[30,0,[\"preview\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n\"],[6,[30,1,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"            \"],[8,[39,2],null,[[\"@source\",\"@update\",\"@imageWidth\",\"@imageHeight\"],[[30,0,[\"preview\"]],[30,0,[\"updateArea\"]],[30,0,[\"width\"]],[30,0,[\"height\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[10,2],[12],[1,\"\\n                \"],[1,[28,[35,3],[\"FileRepository.areaSelectDescription\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[11,\"button\"],[24,0,\"btn btn-default control-attention\"],[4,[38,4],[\"click\",[28,[37,5],[[30,2],[30,0,[\"area\"]]],null]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,6],[\"ok\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,3],[\"General.Save\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n\"],[1,\"            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,4],[\"click\",[30,3]],null],[12],[1,\"\\n                \"],[10,1],[14,0,\"icon-remove--black\"],[12],[13],[1,\"\\n                \"],[1,[28,[35,3],[\"General.Cancel\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"grid\",\"@transform\",\"@cancel\"],false,[\"if\",\"layout/regular-grid\",\"filerepository/image-area/-selector\",\"i18n-t\",\"on\",\"fn\",\"icon\"]]",
    "moduleName": "plutof/templates/components/filerepository/image-area/select.hbs",
    "isStrictMode": false
  });
});