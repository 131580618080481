define("plutof/components/tables/list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="table-responsive" ...attributes>
      <table class="plutof-table table-hover">
          <thead>
              <tr class={{@headersClasses}}>
                  {{yield (component "tables/-list/field-header")}}
              </tr>
          </thead>
  
          <tbody>
              {{#each @records as |record|}}
                  <tr class={{@rowClasses}}>
                      {{yield (component "tables/-list/field") record}}
                  </tr>
              {{/each}}
          </tbody>
      </table>
  </div>
  
  */
  {
    "id": "pddsMlAQ",
    "block": "[[[11,0],[24,0,\"table-responsive\"],[17,1],[12],[1,\"\\n    \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n            \"],[10,\"tr\"],[15,0,[30,2]],[12],[1,\"\\n                \"],[18,6,[[50,\"tables/-list/field-header\",0,null,null]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"                \"],[10,\"tr\"],[15,0,[30,5]],[12],[1,\"\\n                    \"],[18,6,[[50,\"tables/-list/field\",0,null,null],[30,4]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[4]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@headersClasses\",\"@records\",\"record\",\"@rowClasses\",\"&default\"],false,[\"yield\",\"component\",\"each\",\"-track-array\"]]",
    "moduleName": "plutof/components/tables/list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});