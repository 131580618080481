define("plutof/components/collection/repository", ["exports", "@ember/component", "@ember/template-factory", "ember-cp-validations", "@ember-decorators/component", "plutof/mixins/component-validations", "plutof/utils/validations"], function (_exports, _component, _templateFactory, _emberCpValidations, _component2, _componentValidations, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class={{has-error this "repository.name"}}>
      <Input
          @value={{@repository.name}}
          @type="text"
          class="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-xs btn-link"
      >
          <span class="icon-remove icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "tphv01Gs",
    "block": "[[[10,\"td\"],[15,0,[28,[37,0],[[30,0],\"repository.name\"],null]],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"mini-input\"]],[[\"@value\",\"@type\"],[[30,1,[\"name\"]],\"text\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-link\"],[4,[38,2],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@repository\",\"@remove\"],false,[\"has-error\",\"input\",\"on\"]]",
    "moduleName": "plutof/components/collection/repository.hbs",
    "isStrictMode": false
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'repository.name': (0, _validations.notEmptySpace)(256)
  });
  let CollectionRepository = (_dec = (0, _component2.tagName)('tr'), _dec(_class = class CollectionRepository extends _component.default.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CollectionRepository);
});