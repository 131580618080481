define("plutof/components/unite/sequence-guidelines", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "plutof/utils/string"], function (_exports, _component, _object, _component2, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SequenceGuidelines = (_dec = (0, _component2.tagName)('tr'), _dec2 = (0, _component2.classNames)('its-sequence-guidelines', 'sh-sequences-header'), _dec3 = (0, _object.computed)('step', 'length'), _dec4 = (0, _object.computed)('guidelineString'), _dec(_class = _dec2(_class = (_class2 = class SequenceGuidelines extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "colorize", false);
      _defineProperty(this, "step", 50);
      _defineProperty(this, "length", 200);
    }
    get guidelineString() {
      var length = this.length;
      var step = this.step;
      var s = '';
      var nextStep = step;
      while (s.length < length) {
        s += (0, _string.repeat)(' ', nextStep - s.length);
        s += '|' + nextStep.toString();
        nextStep += step;
      }
      return s;
    }
    get guidelineStringChars() {
      return this.guidelineString.split('');
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "guidelineString", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "guidelineString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "guidelineStringChars", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "guidelineStringChars"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = SequenceGuidelines;
});