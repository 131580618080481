define("plutof/templates/livingspecimen/annotation/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/VAHED01",
    "block": "[[[8,[39,0],null,[[\"@kind\",\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[\"annotation\",[28,[37,1],[\"livingspecimen\"],null],[28,[37,2],[\"LivingCultures.annotation.edit\"],null],[30,0,[\"model\",\"strain\"]],\"information/content/living-specimen/add\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"livingSpecimenModel\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@annotate\",\"@disabled\"],[[30,0,[\"annotate\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,7],null,[[\"@cancel\"],[[28,[37,8],[\"goBack\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"livingspecimen/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"annotation/annotate-button\",\"common/cancel-button\",\"route-action\"]]",
    "moduleName": "plutof/templates/livingspecimen/annotation/edit.hbs",
    "isStrictMode": false
  });
});