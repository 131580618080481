define("plutof/routes/experiment/sequencing/edit", ["exports", "@ember/utils", "rsvp", "plutof/components/experiment/utils", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/routes"], function (_exports, _utils, _rsvp, _utils2, _edit, _access, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class SequencingEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'experiment.sequencing.index');
      _defineProperty(this, "title", 'experiment.sequencing.routeTitles.edit');
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const safeParams = (0, _utils.isNone)(params) ? {
        materials: null
      } : params;
      const [experiment, oldMaterials] = await _rsvp.default.all([this.store.findRecord('dna-lab/sequencing', safeParams.id), this.store.query('dna-lab/sequencing-result', {
        sequencing: safeParams.id,
        include: 'dna,pcr_product,primer,original_file'
      })]);
      const [linkedItemsData, accessRights] = await _rsvp.default.all([(0, _edit.loadLinkedItemsData)(this.store, experiment, {
        useEventFiles: false
      }), (0, _access.create_access_rights)(this.store, experiment)]);
      let newMaterials = [];
      if ((0, _utils.isPresent)(safeParams.materials)) {
        newMaterials = await _rsvp.default.all(safeParams.materials.split(',').map(id => {
          return this.store.findRecord('dna-lab/pcr-product', id).then(pcrProduct => {
            const newMaterial = this.store.createRecord('dna-lab/sequencing-result', {
              pcr_product: pcrProduct
            });
            return newMaterial;
          });
        }));
      }
      const materials = await (0, _utils2.makeMaterialStrips)({
        oldMaterials: oldMaterials.toArray(),
        newMaterials: newMaterials.toArray(),
        materialProperties: {
          sequencing: experiment
        },
        model: 'dna-lab/sequencing-result',
        store: this.store
      });
      return {
        experiment,
        materials,
        linkedItemsData,
        accessRights
      };
    }
  }
  var _default = _exports.default = SequencingEditRoute;
});