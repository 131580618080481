define("plutof/templates/components/unite/its-sequence", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sb77JnLx",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],\"@index\",[[[1,\"        \"],[10,\"td\"],[15,0,[29,[\"sequence-base sequence-base-\",[30,1]]]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[34,5]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[\"base\"],false,[\"if\",\"colorize\",\"each\",\"-track-array\",\"bases\",\"sequence\"]]",
    "moduleName": "plutof/templates/components/unite/its-sequence.hbs",
    "isStrictMode": false
  });
});