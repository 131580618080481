define("plutof/utils/model", ["exports", "@ember/object", "@ember/utils", "ember-data", "rsvp", "ember-inflector", "plutof/components/sample/sampling-area-event", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/misc/options-getter", "plutof/misc/profile_settings", "plutof/services/ajax", "plutof/utils/area-event", "plutof/utils/i18n", "plutof/utils/store", "plutof/utils/structures", "plutof/views/sample/event"], function (_exports, _object, _utils, _emberData, _rsvp, _emberInflector, _samplingAreaEvent, _environment, _abstract, _content_types, _optionsGetter, _profile_settings, _ajax, _areaEvent, _i18n, _store, _structures, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.autocompleteURL = autocompleteURL;
  _exports.choices = choices;
  _exports.computed = void 0;
  _exports.destroyCompletely = destroyCompletely;
  _exports.direct_property = direct_property;
  _exports.genericRelation = genericRelation;
  _exports.generic_relation = generic_relation;
  _exports.getDeterminations = getDeterminations;
  _exports.getFieldChoices = getFieldChoices;
  _exports.getLinkedObjects = getLinkedObjects;
  _exports.getObjectInteractions = getObjectInteractions;
  _exports.getOccurrenceSamplingData = getOccurrenceSamplingData;
  _exports.getSamplingDataForParams = getSamplingDataForParams;
  _exports.hasRelation = hasRelation;
  _exports.hasmany_property = hasmany_property;
  _exports.modelTexts = modelTexts;
  _exports.overridable = overridable;
  _exports.privateGuard = privateGuard;
  _exports.recordCount = recordCount;
  _exports.relationID = relationID;
  const i18n = (0, _i18n.getI18n)();
  function direct_property(properties, func, {
    noundef = false,
    default_return_value = null
  } = {}) {
    if (noundef) {
      func = (0, _abstract.lift_to_undefined)(func, default_return_value);
    }
    return (0, _object.computed)(...properties, function () {
      const values = properties.map(prop => this.get(prop));
      return func.apply(this, values);
    });
  }
  function hasmany_property(model, property_map, additional_deps = []) {
    const deps = Object.values(property_map);
    return (0, _object.computed)(...deps, ...additional_deps, function () {
      if (deps.any(prop => (0, _utils.isNone)(this.get(prop)))) {
        return [];
      }
      let q = {};
      Object.keys(property_map).forEach(key => {
        q[key] = this.get(property_map[key]);
      });
      return _emberData.default.PromiseArray.create({
        promise: (0, _store.query)(this.store, model, q)
      });
    });
  }

  // CP that can be overriden by a value from outside
  function overridable(...args) {
    const deps = args.slice(0, args.length - 1);
    const getter = args[args.length - 1];

    // This is an ideal use case for ES symbols, but we sort of need to at least not
    // crash on IE?
    const id = Math.ceil(Math.random() * 1e9);
    const containerField = `__overridable-${id}`;
    const overridenField = `${containerField}-overriden`;
    return (0, _object.computed)(...deps, {
      get() {
        if (this[overridenField]) {
          return this[containerField];
        }
        return getter.call(this);
      },
      set(key, value) {
        this[containerField] = value;
        this[overridenField] = true;
        return value;
      }
    });
  }
  function generic_relation(ctypeProperty, oidProperty) {
    return (0, _object.computed)(ctypeProperty, oidProperty, {
      get(key) {
        var self = this;
        return _emberData.default.PromiseObject.create({
          promise: self.get(ctypeProperty).then(function (ctype) {
            var oid = self.get(oidProperty);
            if ((0, _utils.isNone)(ctype) || (0, _utils.isEmpty)(oidProperty)) {
              return null;
            }
            return (0, _content_types.resolve_generic_link)(self.get('store'), ctype, oid);
          })
        });
      },
      set(key, value) {
        if ((0, _utils.isNone)(value)) {
          this.set(ctypeProperty, null);
          this.set(oidProperty, null);
        } else {
          (0, _content_types.get_object_ctype)(this.store, value).then(function (ctype) {
            this.set(ctypeProperty, ctype);
            this.set(oidProperty, value.get('id'));
          }.bind(this));
        }
        return _emberData.default.PromiseObject.create({
          promise: (0, _abstract.wrap_as_promise)(value)
        });
      }
    });
  }

  // If @relation cannod be resolved (because current user has no view permissions for it),
  // this allows to still work with the record
  // XXX: Need a way to decide in the template if relation is private or not
  function privateGuard(relation) {
    return (0, _object.computed)(`${relation.id}`, {
      get() {
        return _emberData.default.PromiseObject.create({
          promise: this.get(relation).then(record => {
            if (record) {
              // Don't like it match, but we do need to know whether relation is private or not
              record.set('isPrivate', false);
            }
            return record;
          }, error => {
            const id = this.toJSON()[relation];
            return {
              id,
              isPrivate: true,
              representation: i18n.t('Access.forbidden')
            };
          })
        });
      },
      set(key, value) {
        this.set(relation, value);
        return _emberData.default.PromiseObject.create({
          promise: _rsvp.default.Promise.resolve(value)
        });
      }
    });
  }
  function getFieldChoices(ajax, path, options) {
    let parts = path.split('.');
    const field = parts.pop();
    parts[parts.length - 1] = (0, _emberInflector.pluralize)(parts[parts.length - 1]);
    const modelPath = '/' + parts.join('/') + '/';
    return (0, _optionsGetter.getChoices)(ajax, modelPath, field, options);
  }
  function choices(path, options) {
    return (0, _object.computed)(function () {
      const ajax = this.ajax || (0, _ajax.ajaxFromControlled)(this);
      return _emberData.default.PromiseArray.create({
        promise: getFieldChoices(ajax, path, options)
      });
    });
  }
  function modelTexts(ajax, model) {
    return _emberData.default.PromiseObject.create({
      promise: (0, _optionsGetter.getModelTexts)(ajax, model)
    });
  }

  // TODO: Rename. And at least some of those should be removed
  var computed = _exports.computed = {
    concat(props) {
      const deps = (0, _structures.concatMap)(prop => [prop, prop + '.[]'], props);
      return (0, _object.computed)(...deps, function () {
        return (0, _structures.concatMap)(prop => this.get(prop), props);
      });
    },
    apply(props, func) {
      return (0, _object.computed)(...props, function () {
        return func.apply(this, props.map(prop => this.get(prop)));
      });
    },
    environmentProperty(name) {
      return (0, _object.computed)(function () {
        return _environment.default[name];
      });
    },
    notAllAreEmpty(props) {
      return (0, _object.computed)(...props, function () {
        return !props.every(prop => (0, _utils.isEmpty)((0, _object.get)(this, prop)));
      });
    },
    allAreTrue(props) {
      return (0, _object.computed)(...props, function () {
        return props.every(prop => this.get(prop));
      });
    }
  };
  async function getOccurrenceSamplingData(store, occurrence) {
    let projectIsPrivate = false;
    const [areaEvent, project] = await _rsvp.default.Promise.all([(0, _areaEvent.occurrenceAreaEvent)(store, occurrence), occurrence.get('project').catch(() => {
      projectIsPrivate = true;
      return null;
    })]);
    const eventData = await (0, _event.get_sampling_event_form_data)(store, areaEvent.event);
    const formData = (0, _samplingAreaEvent.createSamplingData)({
      store,
      eventData,
      area: areaEvent.area,
      project,
      parent: occurrence
    });
    formData.set('projectIsPrivate', projectIsPrivate);

    // TODO: Return data directly
    return {
      formData
    };
  }

  // TODO: Rename to getSamplingData
  function getSamplingDataForParams(store, params = {}) {
    const eventID = params.event_id;
    const areaID = params.area_id;
    const projectID = params.project_id;
    const materialSampleID = params.materialsample_id;
    const livingSpecimenID = params.livingspecimen_id;
    let data_p;
    if ((0, _utils.isPresent)(eventID)) {
      data_p = store.findRecord('sample/samplingevent', eventID).then(event => {
        return (0, _event.get_sampling_event_form_data)(store, event).then(eventFormData => {
          return event.get('samplingarea').then(area => {
            return area.get('project').then(project => {
              return {
                formData: (0, _samplingAreaEvent.createSamplingData)({
                  store,
                  eventData: eventFormData,
                  area,
                  project
                })
              };
            });
          });
        });
      });
    } else if ((0, _utils.isPresent)(areaID)) {
      data_p = store.findRecord('sample/samplingarea', areaID).then(area => {
        return area.get('project').then(project => {
          return (0, _event.create_sampling_event_form_data)(store, area).then(eventData => {
            return {
              formData: (0, _samplingAreaEvent.createSamplingData)({
                store,
                eventData,
                area,
                project
              })
            };
          });
        });
      });
    } else {
      let project_p;
      if ((0, _utils.isNone)(projectID)) {
        project_p = (0, _profile_settings.get_personal_settings)(store).then(settings => {
          return settings.get('default_study');
        });
      } else {
        project_p = store.findRecord('study/study', projectID);
      }
      data_p = project_p.then(project => {
        const area = store.createRecord('sample/samplingarea', {
          study: project
        });
        return (0, _event.create_sampling_event_form_data)(store, area).then(eventData => ({
          formData: (0, _samplingAreaEvent.createSamplingData)({
            store,
            eventData,
            area,
            project
          })
        }));
      });
    }
    if (!(0, _utils.isNone)(materialSampleID)) {
      return store.findRecord('taxonoccurrence/materialsample/materialsample', materialSampleID).then(msample => {
        return data_p.then(data => {
          data.materialsample = msample;
          return data;
        });
      });
    }
    if (!(0, _utils.isNone)(livingSpecimenID)) {
      return store.findRecord('taxonoccurrence/livingculture/strain', livingSpecimenID).then(ls => {
        return data_p.then(data => {
          data.livingspecimen = ls;
          return data;
        });
      });
    }
    return data_p;
  }
  function getDeterminations(store, ctype, oid) {
    return store.query('determination/determination', {
      content_type: ctype.get('id'),
      object_id: oid,
      ordering: '-is_current,-id'
    }).then(function (determinations) {
      return determinations.toArray();
    });
  }
  function getObjectInteractions(store, object) {
    return getLinkedObjects(store, object, 'taxonoccurrence/interaction/interaction');
  }
  function getLinkedObjects(store, object, linkedObjectModel, extraFilters = {}) {
    return (0, _content_types.get_object_ctype)(store, object).then(ctype => {
      const filters = Object.assign({
        content_type: ctype.id,
        object_id: object.get('id')
      }, extraFilters);
      return (0, _store.query)(store, linkedObjectModel, filters);
    });
  }
  function autocompleteURL(model, method = 'autocomplete') {
    return `/${(0, _emberInflector.pluralize)(model)}/${method}/`;
  }
  async function recordCount(ajax, modelName, query = {}) {
    const endpoint = `${_environment.default.API_HOST}/${(0, _emberInflector.pluralize)(modelName)}/count/`;
    const {
      objects_count
    } = await ajax.request(endpoint, {
      data: query
    });
    return objects_count;
  }
  function genericRelation({
    ctypeProperty = 'content_type',
    oidProperty = 'object_id'
  } = {}) {
    return overridable(ctypeProperty, oidProperty, function () {
      const promise = this[oidProperty] ? (0, _content_types.resolve_generic_link)(this.store, this[ctypeProperty], this[oidProperty]) : _rsvp.default.Promise.resolve(null);
      return _emberData.default.PromiseObject.create({
        promise
      });
    });
  }

  // destroyCompletely is defined on PlutoFModel, but there are
  // many models that don't inherit from it and atm there is not
  // much point in making a common base model class
  function destroyCompletely(record) {
    if (record.destroyCompletely) {
      return record.destroyCompletely();
    }
    return record.destroyRecord().catch(error => {
      record.rollbackAttributes();
      throw error;
    });
  }
  function relationID(record, relation) {
    return record.belongsTo(relation).id();
  }
  function hasRelation(record, relation) {
    return Boolean(relationID(record, relation));
  }
});