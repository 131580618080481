define("plutof/templates/components/save-and-reset", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "3flyqdCZ",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-default save-and-new-button\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[30,0,[\"save\"]],[30,1],[28,[37,1],[\"add-new\"],null],[28,[37,2],[\"cloning.saveAndAddNewTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"cloning.saveAndAddNew\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@disabled\"],false,[\"promise-button\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/save-and-reset.hbs",
    "isStrictMode": false
  });
});