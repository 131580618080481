define("plutof/components/information/content/profile/general", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4> {{i18n-t 'Profile.CreativeCommons'}}</h4>
  <Information::Content::General::DefaultLicence />
  
  */
  {
    "id": "mYh2u/CL",
    "block": "[[[10,\"h4\"],[12],[1,\" \"],[1,[28,[35,0],[\"Profile.CreativeCommons\"],null]],[13],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"]],[],false,[\"i18n-t\",\"information/content/general/default-licence\"]]",
    "moduleName": "plutof/components/information/content/profile/general.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});