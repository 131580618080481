define("plutof/templates/components/datacite/date-row-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fJf7/97s",
    "block": "[[[10,\"td\"],[15,0,[29,[\"data-table-short-cell \",[52,[33,1,[\"attrs\",\"date\",\"date_type\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"content\",\"value\",\"selectClass\",\"class\"],[[33,3],[30,1,[\"date_type\"]],\"mini-input\",\"datacite-edit-dates__type\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[33,1,[\"attrs\",\"dateStartIsValid\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"value\",\"validity\",\"class\"],[[30,1,[\"date_start\"]],[33,5],\"mini-input datacite-edit-dates__start\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[52,[33,1,[\"attrs\",\"date\",\"dateEndValidity\",\"isInvalid\"]],\"has-error\"]],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"value\",\"validity\",\"class\"],[[30,1,[\"date_end\"]],[33,6],\"mini-input datacite-edit-dates__end\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,7],[[24,0,\"mini-input datacite-edit-dates__info\"]],[[\"@value\"],[[33,8,[\"date_information\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,9],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@date\",\"@remove\"],false,[\"if\",\"validations\",\"plutof-select/value\",\"dateTypes\",\"pikaday-date\",\"dateStartValidity\",\"dateEndValidity\",\"flexible-textarea\",\"date\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/date-row-form.hbs",
    "isStrictMode": false
  });
});