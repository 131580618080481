define("plutof/components/transaction/view/objects/header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _templateFactory, _component2, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th
      {{on "click" this._toggleCollapse}}
      class="{{if @collapsed 'collapsed-header' 'clickable'}} {{if @tiny 'tiny-cell'}}"
  >
      {{#if @ordering}}
          <Transaction::View::Objects::OrderingButton
              @ordering={{@ordering}}
              @pagination={{@pagination}} />
      {{/if}}
  
      {{#if @tooltip}}
          <ToolTip
              @tooltip={{@tooltip}}
              class="plutof-tooltip-hover plutof-tooltip-hover--inline"
          >
              {{@label}}
          </ToolTip>
      {{else}}
          {{@label}}
      {{/if}}
  
      {{#if (and this.canCollapse @collapsed)}}
          …
      {{/if}}
  
      {{#unless @disableControls}}
          <@editButton />
      {{/unless}}
  </th>
  
  */
  {
    "id": "4XwR57gC",
    "block": "[[[11,\"th\"],[16,0,[29,[[52,[30,1],\"collapsed-header\",\"clickable\"],\" \",[52,[30,2],\"tiny-cell\"]]]],[4,[38,1],[\"click\",[30,0,[\"_toggleCollapse\"]]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[8,[39,2],null,[[\"@ordering\",\"@pagination\"],[[30,3],[30,4]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[8,[39,3],[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[30,5]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,6]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,6]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"canCollapse\"]],[30,1]],null],[[[1,\"        …\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,7]],[[[1,\"        \"],[8,[30,8],null,null,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@collapsed\",\"@tiny\",\"@ordering\",\"@pagination\",\"@tooltip\",\"@label\",\"@disableControls\",\"@editButton\"],false,[\"if\",\"on\",\"transaction/view/objects/ordering-button\",\"tool-tip\",\"and\",\"unless\"]]",
    "moduleName": "plutof/components/transaction/view/objects/header.hbs",
    "isStrictMode": false
  });
  let TransactionViewObjectHeader = _exports.default = (_dec = (0, _computed.notEmpty)('args.toggleCollapse'), _class = class TransactionViewObjectHeader extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canCollapse", _descriptor, this);
    }
    _toggleCollapse() {
      if (this.args.toggleCollapse) {
        this.args.toggleCollapse(this.args.name);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_toggleCollapse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_toggleCollapse"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "canCollapse", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TransactionViewObjectHeader);
});