define("plutof/templates/datacite/alignment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "M3a/GW6o",
    "block": "[[[10,0],[14,0,\"route-marker-datacite-alignment\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"fluid-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"datacite-alignment-table\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sequence-list-container\"],[12],[1,\"\\n            \"],[1,[28,[35,0],null,[[\"sequences\",\"openSH\"],[[33,1],[28,[37,2],[[30,0],\"openSH\"],null]]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"sequence-alignment-container\"],[12],[1,\"\\n            \"],[10,\"table\"],[14,0,\"plutof-table table-hover doi-sh-sequences sequence-alignment table\"],[12],[1,\"\\n                \"],[10,\"tbody\"],[12],[1,\"\\n                    \"],[1,[28,[35,3],null,[[\"colorize\",\"length\"],[[33,4],[33,5]]]]],[1,\"\\n\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,1]],null]],null],null,[[[1,\"                        \"],[1,[28,[35,8],null,[[\"sequence\",\"colorize\"],[[30,1,[\"its_sequence\"]],[33,4]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n                    \"],[1,[28,[35,3],null,[[\"colorize\",\"length\"],[[33,4],[33,5]]]]],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"colorize-checkbox\"],[12],[1,\"\\n        \"],[1,[28,[35,9],null,[[\"label\",\"checked\"],[\"UniteSH.colorSequences\",[33,4]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,10],null,[[\"pagination\"],[[33,11,[\"sequencePagination\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"sequence\"],false,[\"datacite/sh-doi/variable-sequence-list\",\"sequences\",\"action\",\"unite/sequence-guidelines\",\"colorSequences\",\"maxSequenceLength\",\"each\",\"-track-array\",\"unite/its-sequence\",\"check-box\",\"pagination/load-more\",\"model\"]]",
    "moduleName": "plutof/templates/datacite/alignment.hbs",
    "isStrictMode": false
  });
});