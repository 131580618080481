define("plutof/components/search/filters/sampling-area", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "plutof/components/auto-complete/backends"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _service, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="filter-input" ...attributes>
      {{common/local-label "Sample.samplingArea"}}
  
      <AutoComplete::Base
          @backend={{this.backend}}
          @value={{this.value}}
          @update={{this.updateArea}}
          @sideActionDisabled={{not this.value}}
          @params={{hash
              sideAction=this.convertToGeometry
              sideActionIcon=(icon "map-marker")
              sideActionTooltip=(i18n-t "Search.geometry.fromArea")
              allowIncompleteValue=true
          }}
          class="filter-input-ac"
      >
          <:result as |result attrs|>
              {{hl-subs result.value attrs.query}}
  
              {{#if result.full.object.is_site}}
                  <span class="plutof-ac-suggestion-description">
                      ({{i18n-t "Autocomplete.predefinedArea"}})
                  </span>
              {{/if}}
  
              {{#if result.full.owner}}
                  <span class="plutof-ac-suggestion-description">
                      {{result.full.owner}}
                  </span>
              {{/if}}
          </:result>
      </AutoComplete::Base>
  </div>
  
  */
  {
    "id": "kXl5uIzK",
    "block": "[[[11,0],[24,0,\"filter-input\"],[17,1],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"Sample.samplingArea\"],null]],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"filter-input-ac\"]],[[\"@backend\",\"@value\",\"@update\",\"@sideActionDisabled\",\"@params\"],[[30,0,[\"backend\"]],[30,0,[\"value\"]],[30,0,[\"updateArea\"]],[28,[37,2],[[30,0,[\"value\"]]],null],[28,[37,3],null,[[\"sideAction\",\"sideActionIcon\",\"sideActionTooltip\",\"allowIncompleteValue\"],[[30,0,[\"convertToGeometry\"]],[28,[37,4],[\"map-marker\"],null],[28,[37,5],[\"Search.geometry.fromArea\"],null],true]]]]],[[\"result\"],[[[[1,\"\\n            \"],[1,[28,[35,6],[[30,2,[\"value\"]],[30,3,[\"query\"]]],null]],[1,\"\\n\\n\"],[41,[30,2,[\"full\",\"object\",\"is_site\"]],[[[1,\"                \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                    (\"],[1,[28,[35,5],[\"Autocomplete.predefinedArea\"],null]],[1,\")\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"full\",\"owner\"]],[[[1,\"                \"],[10,1],[14,0,\"plutof-ac-suggestion-description\"],[12],[1,\"\\n                    \"],[1,[30,2,[\"full\",\"owner\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[2,3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"result\",\"attrs\"],false,[\"common/local-label\",\"auto-complete/base\",\"not\",\"hash\",\"icon\",\"i18n-t\",\"hl-subs\",\"if\"]]",
    "moduleName": "plutof/components/search/filters/sampling-area.hbs",
    "isStrictMode": false
  });
  let SearchAreaFilter = (_dec = (0, _computed.reads)('args.areaFilter.value.firstObject'), _class = class SearchAreaFilter extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "backend", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'sample/samplingarea'
      }));
      _initializerDefineProperty(this, "value", _descriptor3, this);
    }
    updateArea(area) {
      this.args.areaFilter.set('value', area ? [area] : []);
      this.args.geomFilter.set('value', null);
    }
    convertToGeometry() {
      this.args.geomFilter.set('value', this.value.geom);
      this.args.areaFilter.set('value', []);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateArea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateArea"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "convertToGeometry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "convertToGeometry"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchAreaFilter);
});