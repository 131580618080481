define("plutof/templates/components/datacite/geom-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AZIxOumE",
    "block": "[[[10,0],[14,0,\"input-group\"],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,0],[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\",\"@placeholder\"],[\"text\",[30,0,[\"source\"]],[28,[37,1],[[30,0],[30,0,[\"setGeometry\"]]],null],[28,[37,2],[\"Publishing.manualLocationPlaceholder\"],null]]],null],[1,\"\\n\\n    \"],[10,1],[14,0,\"input-group-btn\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[30,0,[\"sourceInvalid\"]]],[24,0,\"btn btn-default\"],[4,[38,3],[\"click\",[30,0,[\"setGeometry\"]]],null],[12],[1,\"\\n            \"],[1,[28,[35,2],[\"Publishing.setLocationInput\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"input\",\"action\",\"i18n-t\",\"on\"]]",
    "moduleName": "plutof/templates/components/datacite/geom-input.hbs",
    "isStrictMode": false
  });
});