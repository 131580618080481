define("plutof/components/datacite/view/creators", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @creators.length}}
      class="datacite-view-creators"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "datacite.panels.creators"}}
          @count={{@creators.length}} />
  
      <panel.content>
          <Common::RecordListView
              @records={{@creators}}
              data-test="creators"
              as |creator|
          >
              <Datacite::View::Creator @creator={{creator}} />
          </Common::RecordListView>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "ElZHaxGQ",
    "block": "[[[8,[39,0],[[24,0,\"datacite-view-creators\"]],[[\"@collapse\"],[[28,[37,1],[[30,1,[\"length\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@count\"],[[28,[37,2],[\"datacite.panels.creators\"],null],[30,1,[\"length\"]]]],null],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],[[24,\"data-test\",\"creators\"]],[[\"@records\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,4],null,[[\"@creator\"],[[30,3]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@creators\",\"panel\",\"creator\"],false,[\"plutof-panel/item-list\",\"not\",\"i18n-t\",\"common/record-list-view\",\"datacite/view/creator\"]]",
    "moduleName": "plutof/components/datacite/view/creators.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});