define("plutof/templates/components/clipboard/navbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "O+zhbFpL",
    "block": "[[[8,[39,0],null,[[\"@kind\"],[\"util\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"header\"]],null,[[\"@icon\",\"@title\"],[[30,3],[30,4]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"info\"]],null,[[\"@title\",\"@content\"],[[30,4],[30,5]]],null],[1,\"\\n\\n        \"],[8,[30,1,[\"bookmark\"]],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,6,[\"canReset\"]],[[[1,\"            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,6,[\"reset\"]],[28,[37,2],[\"reset\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"General.Reset\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[30,1,[\"goBack\"]],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"]],[\"navbar\",\"slots\",\"@icon\",\"@title\",\"@infoContent\",\"@export\"],false,[\"navbar/navbar-base\",\"if\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/clipboard/navbar.hbs",
    "isStrictMode": false
  });
});