define("plutof/utils/annotation/store", ["exports", "@ember/application", "@ember/object", "@ember/utils", "ember-data", "rsvp", "plutof/utils/objects", "plutof/utils/store/proxy", "plutof/utils/annotation/format"], function (_exports, _application, _object, _utils, _emberData, _rsvp, _objects, _proxy, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AnnotatedStore = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* eslint-disable no-console */
  class AnnotatedStore extends _proxy.default {
    // If annotation is passed, it will be used to augment data fetched from the
    // store. For example, if @annotation has change { sequence:1 : remarks -> foo },
    // then findRecord('sequence', 1) result will have 'foo' as remarks
    constructor(store, annotation = null, {
      showRemoved = false
    } = {}) {
      super(store);
      // model -> id -> PromiseObject<AnnotatedRecord>
      _defineProperty(this, "records", new Map());
      _defineProperty(this, "_changes", []);
      // XXX TODO: If augmentation provided, need to get last local ID from there and init with that+1!! Otherwise
      // additions will be combined by coalesceChanges
      _defineProperty(this, "nextID", 1);
      // :: model_name -> id -> changes
      _defineProperty(this, "augmentations", new Map());
      (0, _application.setOwner)(this, (0, _application.getOwner)(store));
      if (annotation) {
        const format = _format.AnnotationFormatImplementations[annotation.format];
        const changes = format.getChanges(annotation.annotation);

        // XXX TODO: Figure out how to apply deletes. And all kinds of stuff, it's a deep hole
        // TODO: Format-dependent initialisation
        for (let {
          operation,
          id,
          model,
          fields,
          status
        } of changes) {
          if (status && status.applied) {
            // No need to augment, the server version is already synced
            // (or has even moved forward)
            continue;
          }
          if (!this.augmentations.has(model)) {
            this.augmentations.set(model, new Map());
          }
          this.augmentations.get(model).set(id, {
            operation,
            fields
          });
        }
        this.showRemoved = showRemoved;
      }
    }
    _modelRecords(model) {
      if (!this.records.has(model)) {
        this.records.set(model, {});
      }
      return this.records.get(model);
    }
    find() {
      throw new Error('find() is long deprecated, use something else');
    }

    // XXX TODO: Other Store methods
    findRecord(model, id) {
      const modelRecords = this._modelRecords(model);
      if (!modelRecords[id]) {
        let promise;
        if (id[0] === '@') {
          let toBeResolved = Object.assign({}, this.augmentations.get(model).get(id).fields);
          this.modelFor(model).eachRelationship((field, meta) => {
            if (toBeResolved[field]) {
              toBeResolved[field] = meta.meta.kind === 'hasMany' ? _rsvp.default.all(toBeResolved[field].map(id => this.findRecord(meta.type, id))) : this.findRecord(meta.type, toBeResolved[field]);
            }
          });
          promise = _rsvp.default.hash(toBeResolved).then(resolvedFields => {
            console.debug('Added augmentation-only', model, 'with fields resolved to', resolvedFields);
            return this.createRecord(model, resolvedFields, id);
          });
        } else {
          promise = super.findRecord(model, id).then(rec => annotateAndAugmentRecord(this, rec));
        }
        modelRecords[id] = _emberData.default.PromiseObject.create({
          id,
          promise
        });
      }
      return modelRecords[id];
    }

    // XXX: Not currentyle properly expressable (need to have modelRecords be records instead of PromiseObjects;
    // also it must be sync, which is incompatilbe with augmentations)
    peekRecord(model, id) {
      return super.peekRecord(model, id);
    }

    // Not sure if we need to do anything here
    pushPayload(model, data) {
      return super.pushPayload(model, data);
    }
    async _annotateLiveArray(model, resultsLiveArray) {
      const results = resultsLiveArray.toArray();
      resultsLiveArray.destroy();

      // TODO: Deal with annotateAndAugmentRecord resolving to null (for deleted relations)
      const annotatedResults = (await _rsvp.default.all(results.map(result => annotateAndAugmentRecord(this, result)))).compact();
      const modelRecords = this._modelRecords(model);
      annotatedResults.forEach(result => {
        // TODO: This case is probably not handled correctly (already loaded records refer
        // to old ember-data one)
        if (modelRecords[result.id]) {
          console.warn(`AnnotatedStore.query overwriting ${model}:${result.id}`);
        }

        // TODO: Reorganize so that we don't need to wrap these in PromiseObject?
        modelRecords[result.id] = _emberData.default.PromiseObject.create({
          id: result.id,
          promise: _rsvp.default.Promise.resolve(result)
        });
      });

      // XXX XXX: Needed becouse of downstream wrap()
      annotatedResults.destroy = function () {
        // Do nothing
      };
      return annotatedResults;
    }
    query(model, filters) {
      const backendResponsePromise = (() => {
        const filteredByAugmentation = Object.values(filters).filter(v => typeof v === 'string').some(v => v[0] === '@');
        if (filteredByAugmentation) {
          return _rsvp.default.Promise.resolve([]);
        } else {
          return super.query(model, filters).then(results => this._annotateLiveArray(model, results));
        }
      })();
      return _emberData.default.PromiseArray.create({
        promise: backendResponsePromise.then(async results => {
          let augmentationMatches = [];

          // Add augmentation-only records that match the query. Yes, this is a huge hack,
          // but that's just how life is
          if (this.augmentations.has(model)) {
            for (const [augmentationID, {
              operation,
              fields
            }] of this.augmentations.get(model).entries()) {
              if (operation !== _format.Operation.Add) {
                continue;
              }
              const IGNORED_FIELDS = new Set(['page', 'page_size', 'ordering']);

              // Check that fields match query
              if (Object.keys(filters).filter(f => !IGNORED_FIELDS.has(f))
              // .filter(f => f !== 'page' && f !== 'page_size')
              .some(f => filters[f] !== fields[f])) {
                continue;
              }
              if (filters.page && filters.page > 1) {
                continue;
              }
              const added = await this.findRecord(model, augmentationID);
              augmentationMatches.push(added);
            }
          }
          let fullResults = augmentationMatches.concat(results);
          fullResults.destroy = function () {
            // XXX
          };
          return fullResults;
        })
      });
    }
    findAll(model) {
      return _emberData.default.PromiseArray.create({
        promise: super.findAll(model).then(results => this._annotateLiveArray(model, results))
      });
    }
    generateID() {
      return `@${this.nextID++}`;
    }
    createRecord(model, values = {}, id = undefined) {
      const record = annotateRecord(this, super.createRecord(model));
      record.id = id || this.generateID();
      record.isNew = true;
      // Not passing to constructor so that these would count as changes
      record.setProperties(values);

      // XXX: Don't want augmented records to create another change
      if (id) {
        record._hackAugmentedFromExistingCreate = true;
      }
      this._modelRecords(model)[record.id] = _emberData.default.PromiseObject.create({
        id: record.id,
        promise: _rsvp.default.Promise.resolve(record)
      });
      return record;
    }
    addChange(change) {
      this._changes.push(change);
    }
    extractChanges() {
      return Array.from(this._changes);
    }
    rawQueryAdditions(model, query) {
      if (!this.augmentations.has(model)) {
        return [];
      }
      let results = [];
      for (const {
        operation,
        fields
      } of this.augmentations.get(model).values()) {
        if (operation !== _format.Operation.Add) {
          continue;
        }

        // Check that fields match query
        if (Object.keys(query).filter(f => f !== 'page' && f !== 'page_size' && f !== 'ordering').some(f => query[f] !== fields[f])) {
          continue;
        }
        if (query.page && query.page > 1) {
          continue;
        }
        results.push(fields);
      }
      return results;
    }
    getRecordAugmentation(model, id) {
      const change = this.getRecordChange(model, id);
      if (!change) {
        return {};
      }
      return change.operation === _format.Operation.Modify ? change.fields : {};
    }
    getRecordChange(model, id) {
      if (!this.augmentations.has(model)) {
        return null;
      }
      if (!this.augmentations.get(model).has(id)) {
        return null;
      }
      return this.augmentations.get(model).get(id);
    }
    get inAnnotation() {
      return true;
    }
  }
  _exports.AnnotatedStore = AnnotatedStore;
  class AnnotatedRecord {
    constructor(store, emberDataRecord) {
      // fieldAnnotated.foo is true if 'foo' is overriden
      _defineProperty(this, "fieldAnnotated", {});
      _defineProperty(this, "hasDirtyAttributes", true);
      this.store = store;
      this._emberDataRecord = emberDataRecord;
      this._overrides = new Map();
      this._relations = new Map();
      this.id = emberDataRecord.id;
      this.emberContainer = (0, _application.getOwner)(emberDataRecord);
      this.container = this.emberContainer; // XXX: Required for service injections to work on model
      (0, _application.setOwner)(this, this.emberContainer);
      this.addedInAnnotation = emberDataRecord.isNew;
      this.removedInAnnotation = false;
      this._belongToRelationCache = new Map();
      this._hasManyRelationCache = new Map();
    }
    _registerRelation(field, meta) {
      this._relations.set(field, meta);
    }
    set(path, value) {
      return (0, _object.set)(this, path, value);
    }
    setProperties(props) {
      Object.entries(props).forEach(([key, value]) => (0, _object.set)(this, key, value));
    }
    override(field, value) {
      this._overrides.set(field, value);
      (0, _object.set)(this.fieldAnnotated, field, true);
    }
    get(path) {
      return (0, _object.get)(this, path);
    }
    getProperties(props) {
      return (0, _object.getProperties)(this, props);
    }
    rollbackAttributes() {
      this._overrides = {};
      return _rsvp.default.Promise.resolve(null);
    }
    reload() {
      // this._overrides = {};

      return _rsvp.default.Promise.resolve(this);
    }
    unloadRecord() {
      return _rsvp.default.Promise.resolve();
    }

    // TODO: Do we need to list only changes since the last save? That would be logical, but not
    // really working with how overrides are implemented atm. Probably can get away with just deduplicating
    // changes when finalizing annotation.
    //
    // Maybe we should just store changed records and let some format class generate the annotation? Seems
    // promising
    _createChange() {
      let fields = {};
      let previousFields = {};
      let fieldComments = {};
      const attributes = new Map();
      this._emberDataRecord.eachAttribute((field, meta) => {
        attributes.set(field, meta);
      });
      let operation = _format.Operation.Modify;
      if (this.removedInAnnotation) {
        // TODO: Not need to construct diff here
        operation = _format.Operation.Remove;
      } else if (this.addedInAnnotation && !this._hackAugmentedFromExistingCreate) {
        operation = _format.Operation.Add;
      }
      for (let [field, value] of this._overrides.entries()) {
        let oldValue;
        let newValue;
        if (this._relations.has(field)) {
          const relation = this._relations.get(field);
          if (relation.meta.kind === 'hasMany') {
            oldValue = this._emberDataRecord.belongsTo(field).ids();
            newValue = value.map(v => v.id);
          } else {
            oldValue = this._emberDataRecord.belongsTo(field).id();
            newValue = value ? value.id : value;
          }
        } else {
          oldValue = this._emberDataRecord.get(field);
          newValue = value;
        }
        let changed = false;
        const isJSONAttr = attributes.has(field) && attributes.get(field).type === undefined;
        if (isJSONAttr) {
          changed = !(0, _objects.deepEquality)(oldValue, newValue);
        } else if (Array.isArray(newValue)) {
          changed = JSON.stringify(oldValue) !== JSON.stringify(newValue);
        } else {
          // != because of a PITA of generic relations' object id that somehow gets
          // initialised as a number instead of string in Interaction
          /* eslint-disable eqeqeq */
          // isEmpty stuff is to consider null <-> undefined <-> "" as "not modifed" (don't tell me about ==)
          changed = oldValue != newValue && (!(0, _utils.isEmpty)(newValue) || !(0, _utils.isEmpty)(oldValue));
          /* eslint-enable eqeqeq */
        }

        // undefined fields get dropped by JSON.stringify
        if (oldValue === undefined) {
          oldValue = null;
        }
        if (newValue === undefined) {
          newValue = null;
        }
        if (changed) {
          fields[field] = newValue;
          if (operation === _format.Operation.Modify) {
            previousFields[field] = oldValue;
          }
        }
      }

      // TODO: Switch to { field: { oldValue, newValue, comment }}, but would have to
      // handle old-format annotations, which is messy atm
      return {
        operation,
        model: this.constructor.modelName,
        id: this.id,
        fields,
        previousFields,
        fieldComments,
        // By default use the same adapter. This can be
        // overriden in this.saveToEndpoint
        endpoint: null
      };
    }
    deleteRecord() {
      this.removedInAnnotation = true;
    }
    destroyRecord() {
      this.removedInAnnotation = true;
      return this.save();
    }
    belongsTo(relationship) {
      return {
        id: () => {
          return this.get(relationship).id;
        }
      };
    }
    save() {
      const change = this._createChange();
      if (change.operation !== _format.Operation.Modify || Object.keys(change.fields).length > 0) {
        this.store.addChange(change);
      }
      return _rsvp.default.Promise.resolve(this);
    }
    saveToEndpoint(endpoint) {
      const change = this._createChange();
      if (change.operation !== _format.Operation.Modify || Object.keys(change.fields).length > 0) {
        change.endpoint = endpoint;
        this.store.addChange(change);
      }
      return _rsvp.default.Promise.resolve(this);
    }
    serialize() {
      // XXX: Not really, but will do for a couple of places where we use it
      return this._emberDataRecord.serialize();
    }
  }
  function annotateRecord(store, emberDataRecord) {
    var _Class;
    const modelName = emberDataRecord.constructor.modelName;
    const model = (_Class = class model extends AnnotatedRecord {
      static get fields() {
        return emberDataRecord.constructor.fields;
      }
      static get attributes() {
        return emberDataRecord.constructor.attributes;
      }
      static get relationshipsByName() {
        return emberDataRecord.constructor.relationshipsByName;
      }
    }, _defineProperty(_Class, "modelName", modelName), _Class);
    let annotated = new model(store, emberDataRecord);
    let modelFields = new Set();
    emberDataRecord.eachRelationship((field, meta) => {
      let hasManyResponse = null;
      Object.defineProperty(annotated, field, {
        get() {
          if (meta.meta.kind === 'belongsTo') {
            if (annotated._overrides.has(field)) {
              if (!annotated._belongToRelationCache.has(field)) {
                const related = annotated._overrides.get(field);
                annotated._belongToRelationCache.set(field, _emberData.default.PromiseObject.create({
                  id: related ? related.id : null,
                  promise: _rsvp.default.Promise.resolve(related)
                }));
              }
              return annotated._belongToRelationCache.get(field);
            }
            const relation = emberDataRecord.belongsTo(field);
            const id = relation.id();
            return id ? store.findRecord(relation.type, id) : _rsvp.default.Promise.resolve(null);
          } else {
            if (annotated._overrides.has(field)) {
              if (!annotated._hasManyRelationCache.has(field)) {
                annotated._hasManyRelationCache.set(field, _emberData.default.PromiseArray.create({
                  promise: _rsvp.default.Promise.resolve(annotated._overrides.get(field))
                }));
              }
              return annotated._hasManyRelationCache.get(field);
            }
            const relation = emberDataRecord.hasMany(field);
            const ids = relation.ids();
            if (!hasManyResponse) {
              const promise = _rsvp.default.all(ids.map(id => {
                return store.findRecord(relation.type, id);
              }));
              hasManyResponse = _emberData.default.PromiseArray.create({
                promise
              });
            }
            return hasManyResponse;
          }
        },
        set(value) {
          annotated._belongToRelationCache.delete(field);
          annotated.override(field, value);
          return value;
        }
      });
      modelFields.add(field);
      annotated._registerRelation(field, meta);
    });
    emberDataRecord.eachAttribute((field, meta) => {
      Object.defineProperty(annotated, field, {
        get() {
          return this._overrides.has(field) ? this._overrides.get(field) : this._emberDataRecord.get(field);
        },
        set(value) {
          annotated._belongToRelationCache.delete(field);
          annotated.override(field, value);
          return value;
        }
      });
      modelFields.add(field);
    });

    // Everything that's not a model field: CP's etc
    // NB: THIS ONLY USES THE IMMEDIATE PROTOPYPE. No mixins, propbably no
    // base class fields
    const proto = Object.getPrototypeOf(emberDataRecord);
    Object.keys(proto).filter(field => !modelFields.has(field)).forEach(field => {
      const descriptor = Object.getOwnPropertyDescriptor(proto, field);
      Object.defineProperty(annotated, field, {
        get() {
          return descriptor.get.call(annotated);
        },
        set(value) {
          return descriptor.set.call(annotated, value);
        }
      });
    });
    return annotated;
  }
  function annotateAndAugmentRecord(store, emberDataRecord) {
    return augmentRecord(annotateRecord(store, emberDataRecord));
  }

  // Destructive
  async function augmentRecord(annotated) {
    const modelName = annotated.constructor.modelName;

    // Augmentation
    if (!annotated._emberDataRecord.isNew && annotated.store.augmentations.has(modelName) && annotated.store.augmentations.get(modelName).has(annotated.id)) {
      console.debug(`Applying augmentation to ${modelName}:${annotated.id}`);
      const {
        operation,
        fields
      } = annotated.store.augmentations.get(modelName).get(annotated.id);
      if (operation === _format.Operation.Remove) {
        if (annotated.store.showRemoved) {
          annotated.removedInAnnotation = true;
        } else {
          return null;
        }
      }
      for (let [field, value] of Object.entries(fields)) {
        if (value && annotated._relations.has(field)) {
          const meta = annotated._relations.get(field);
          if (meta.meta.kind === 'hasMany') {
            value = await _rsvp.default.all(value.map(id => annotated.store.findRecord(meta.type, id)));
          } else {
            // XXX: Need to wait for this, but can't make annotateRecord async
            value = await annotated.store.findRecord(meta.type, value);
          }
        }
        annotated.override(field, value);
        console.debug(`\t ${field} set to`, value);
      }
    }
    return annotated;
  }
});