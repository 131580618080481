define("plutof/components/agent/view/organization-additional-data", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GenericViews::Record
      @record={{@organization}}
      data-test="Agent::View::OrganizationAdditionalData"
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          {{view.attr "original_name"}}
  
          {{#view.attr "original_language"}}
              {{@organization.original_language.representation}}
          {{/view.attr}}
  
          {{view.attr "herbarium_name"}}
          {{view.attr "original_herbarium_name"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "oPs+Yz//",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Agent::View::OrganizationAdditionalData\"]],[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"original_name\"],null]],[1,\"\\n\\n\"],[6,[30,2,[\"attr\"]],[\"original_language\"],null,[[\"default\"],[[[[1,\"            \"],[1,[30,1,[\"original_language\",\"representation\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"herbarium_name\"],null]],[1,\"\\n        \"],[1,[28,[30,2,[\"attr\"]],[\"original_herbarium_name\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@organization\",\"view\"],false,[\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/agent/view/organization-additional-data.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});