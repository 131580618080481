define("plutof/templates/unite/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gX/dQJ3l",
    "block": "[[[10,0],[14,0,\"route-marker-unite-view\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@infoContent\"],[[28,[37,1],[\"unite-sh-cluster\"],null],[28,[37,2],[\"UniteSH.shView.title\"],null],[30,0,[\"model\",\"dshcluster\"]],[30,0,[\"permissions\"]],\"information/content/unite/view\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"downloadFASTA\"]],[28,[37,1],[\"download\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"UniteSH.downloadFASTA\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@dshcluster\",\"@currentRefSeq\",\"@refSeqDesignatedAt\",\"@modified\",\"@canModifyRefseqs\",\"@canModifyExclusions\",\"@treeTaxon\",\"@thLineage\",\"@dois\",\"@mapData\",\"@collapseLevels\",\"@cancel\"],[[30,0,[\"model\",\"dshcluster\"]],[30,0,[\"refSeq\"]],[30,0,[\"model\",\"refSeqDesignatedAt\"]],[30,0,[\"modified\"]],[30,0,[\"canModifyRefseqs\"]],[30,0,[\"canModifyExclusions\"]],[30,0,[\"_loadTaxon\",\"last\",\"value\"]],[30,0,[\"model\",\"thLineage\"]],[30,0,[\"model\",\"dois\"]],[30,0,[\"model\",\"mapData\"]],[30,0,[\"model\",\"collapseLevels\"]],[28,[37,4],[\"goBack\"],null]]],null],[1,\"\\n\"]],[\"navbar\",\"slots\"],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"unite-table\",\"route-action\"]]",
    "moduleName": "plutof/templates/unite/view.hbs",
    "isStrictMode": false
  });
});