define("plutof/templates/components/clipboard/list-view-clipboard-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yPuUzdt/",
    "block": "[[[11,3],[24,\"data-test\",\"add-to-clipboard\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"toggleInclusion\"]]],null]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[[30,0,[\"iconClass\"]]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\",\"perform\"]]",
    "moduleName": "plutof/templates/components/clipboard/list-view-clipboard-button.hbs",
    "isStrictMode": false
  });
});