define("plutof/templates/components/information/popup-term", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "GlepE0XH",
    "block": "[[[10,1],[14,0,\"information-popup-term\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"information-popup-term__trigger\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"information-popup-term__popup\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[29,[[30,1]]]],[14,0,\"information-popup-term__image\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@image\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "plutof/templates/components/information/popup-term.hbs",
    "isStrictMode": false
  });
});