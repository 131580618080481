define("plutof/components/search/filters/choice-or-exists", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @choiceFilter}}
      <div class="plutof-tooltip-hover">
          <div class="filter-input">
              <div class="label-control">
                  <Search::Filters::Checkbox
                      @filter={{@existsFilter}}
                      @changed={{this.updateFilters}}
                  >
                      {{@choiceFilter.config.label}}
                  </Search::Filters::Checkbox>
              </div>
  
              <PlutofSelect::Value
                  @content={{this.choices}}
                  @value={{@choiceFilter.value}}
                  @disabled={{this.disableSelection}} />
  
              <div class="plutof-tooltip">
                  <div>{{@existsFilter.config.help_text}}</div>
  
                  <Search::TriStateTooltip />
              </div>
          </div>
      </div>
  {{/if}}
  
  */
  {
    "id": "dKH4ce9d",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"plutof-tooltip-hover\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"filter-input\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"label-control\"],[12],[1,\"\\n                \"],[8,[39,1],null,[[\"@filter\",\"@changed\"],[[30,2],[30,0,[\"updateFilters\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@disabled\"],[[30,0,[\"choices\"]],[30,1,[\"value\"]],[30,0,[\"disableSelection\"]]]],null],[1,\"\\n\\n            \"],[10,0],[14,0,\"plutof-tooltip\"],[12],[1,\"\\n                \"],[10,0],[12],[1,[30,2,[\"config\",\"help_text\"]]],[13],[1,\"\\n\\n                \"],[8,[39,3],null,null,null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@choiceFilter\",\"@existsFilter\"],false,[\"if\",\"search/filters/checkbox\",\"plutof-select/value\",\"search/tri-state-tooltip\"]]",
    "moduleName": "plutof/components/search/filters/choice-or-exists.hbs",
    "isStrictMode": false
  });
  let ChoiceOrExistsFilter = _exports.default = (_dec = (0, _object.computed)('args.choiceFilter.choices'), _dec2 = (0, _object.computed)('args.existsFilter.value'), _class = class ChoiceOrExistsFilter extends _component2.default {
    get choices() {
      if ((0, _utils.isNone)(this.args.choiceFilter)) {
        return [];
      }
      const choices = this.args.choiceFilter.config.choices;
      return choices.map(choice => {
        return {
          display_name: choice.display_name,
          value: String(choice.value)
        };
      });
    }
    get disableSelection() {
      const filter = this.args.existsFilter;
      return (0, _utils.isNone)(filter) || !(0, _utils.isEmpty)(filter.value);
    }
    updateFilters(exists) {
      if (!(0, _utils.isNone)(exists)) {
        this.args.choiceFilter.set('value', null);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "choices", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "choices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableSelection", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disableSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChoiceOrExistsFilter);
});