define("plutof/components/gallery/footer/-right-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
      class="
          gallery-footer-right__item
          {{if @small 'gallery-footer-right__item--small'}}
      "
  >
      {{yield}}
  </span>
  
  */
  {
    "id": "BkaVBNW3",
    "block": "[[[10,1],[15,0,[29,[\"\\n        gallery-footer-right__item\\n        \",[52,[30,1],\"gallery-footer-right__item--small\"],\"\\n    \"]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@small\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "plutof/components/gallery/footer/-right-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});