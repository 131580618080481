define("plutof/templates/import/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FEcdTUJ+",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"import.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"import\"],null],[28,[37,3],[\"Import.import\"],null],[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"Import.import\"],null],\"information/content/import/general\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\",\"@disabled\"],[[30,0,[\"generateTemplate\"]],[28,[37,2],[\"file\"],null],[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"Import.indexTemplatePage\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"button\"]],[[24,0,\"navbar-list__add\"]],[[\"@clicked\",\"@icon\",\"@disabled\"],[[30,0,[\"add\"]],[28,[37,2],[\"add-new\"],null],[30,3]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"General.New\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@processes\",\"@stop\",\"@delete\",\"@download\"],[[30,0,[\"model\",\"pagination\",\"objects\"]],[30,0,[\"stop\"]],[30,0,[\"remove\"]],[30,0,[\"stopAndDownload\"]]]],null],[1,\"\\n\"]],[\"navbar\",\"slots\",\"@disableAdd\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"import/process-list\"]]",
    "moduleName": "plutof/templates/import/index.hbs",
    "isStrictMode": false
  });
});