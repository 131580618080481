define("plutof/components/publishing/gbif/records/existing-record", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "plutof/controllers/publishing/utils"], function (_exports, _component, _templateFactory, _object, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="{{if validations.attrs.record.plutof_id.isInvalid 'has-error'}}">
      <LinkTo @route={{this.type.viewRoute}} @model={{@record.id}}>
          {{@record.representation}}
      </LinkTo>
  </td>
  
  <td>
      {{#if @record.taxon_node}}
          <LinkTo @route="taxonomy.view" @model={{@record.taxon_node.id}}>
              {{@record.taxon_node.name}}
          </LinkTo>
      {{/if}}
  </td>
  
  <td>
      {{#if @record.project.id}}
          <LinkTo @route="study.view" @model={{@record.project.id}}>
              {{@record.project.name}}
          </LinkTo>
      {{/if}}
  </td>
  
  <td>{{@record.district}}</td>
  <td>{{@record.commune}}</td>
  <td>{{@record.locality_text}}</td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-link data-table-btn"
      >
          <span class="icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "4MUR4kh/",
    "block": "[[[10,\"td\"],[15,0,[29,[[52,[33,1,[\"attrs\",\"record\",\"plutof_id\",\"isInvalid\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[[30,0,[\"type\",\"viewRoute\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"representation\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,1,[\"taxon_node\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"taxonomy.view\",[30,1,[\"taxon_node\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"taxon_node\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,1,[\"project\",\"id\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"study.view\",[30,1,[\"project\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"project\",\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,[30,1,[\"district\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,[30,1,[\"commune\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,[30,1,[\"locality_text\"]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"icon-remove--red\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\",\"@remove\"],false,[\"if\",\"validations\",\"link-to\",\"on\"]]",
    "moduleName": "plutof/components/publishing/gbif/records/existing-record.hbs",
    "isStrictMode": false
  });
  let ExistingRecord = (_dec = (0, _component2.tagName)('tr'), _dec2 = (0, _object.computed)('record.type'), _dec(_class = (_class2 = class ExistingRecord extends _component.default {
    get type() {
      return (0, _utils.getRecordType)(this.record.type);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "type", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExistingRecord);
});