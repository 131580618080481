define("plutof/components/forms/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/config/environment"], function (_exports, _component, _templateFactory, _component2, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Forms::Form
          @mainform={{@data.mainform}}
          @taxonMainforms={{@data.taxonMainforms}}
          @traitForms={{@data.measurementForms}}
          @areaTraits={{@data.areaForm}}
          @eventTraits={{@data.eventForm}}
          @habitatTraits={{@data.habitatForm}}
          @itemsToDelete={{@data.itemsToDelete}}
          @validationContext={{@validationContext.generalData}} />
  </PlutofPanel::Simple>
  
  {{#if @managingGroup}}
      {{! TODO: This is the only place this component is used }}
      <Access::ManagingGroup::Edit
          @model={{@managingGroup}}
          @includeModerationRights={{@data.mainform.requires_moderation}}
          @validationContext={{@validationContext.managingGroup}} />
  {{/if}}
  
  {{#if this.enableTaxonSheet}}
      <Forms::TaxonSheet
          @data={{@data.taxonSheetData}}
          @validationContext={{@validationContext.taxonSheet}} />
  {{/if}}
  
  */
  {
    "id": "3WxuyBoo",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@mainform\",\"@taxonMainforms\",\"@traitForms\",\"@areaTraits\",\"@eventTraits\",\"@habitatTraits\",\"@itemsToDelete\",\"@validationContext\"],[[30,1,[\"mainform\"]],[30,1,[\"taxonMainforms\"]],[30,1,[\"measurementForms\"]],[30,1,[\"areaForm\"]],[30,1,[\"eventForm\"]],[30,1,[\"habitatForm\"]],[30,1,[\"itemsToDelete\"]],[30,2,[\"generalData\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,4],null,[[\"@model\",\"@includeModerationRights\",\"@validationContext\"],[[30,3],[30,1,[\"mainform\",\"requires_moderation\"]],[30,2,[\"managingGroup\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"enableTaxonSheet\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@data\",\"@validationContext\"],[[30,1,[\"taxonSheetData\"]],[30,2,[\"taxonSheet\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@data\",\"@validationContext\",\"@managingGroup\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"forms/form\",\"if\",\"access/managing-group/edit\",\"forms/taxon-sheet\"]]",
    "moduleName": "plutof/components/forms/edit.hbs",
    "isStrictMode": false
  });
  let FormsEdit = (_dec = (0, _object.computed)('args.data.mainform.id'), _class = class FormsEdit extends _component2.default {
    get enableTaxonSheet() {
      return _environment.default.TAXON_SHEET_MAINFORMS.includes(this.args.data.mainform.id);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "enableTaxonSheet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "enableTaxonSheet"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormsEdit);
});