define("plutof/templates/components/search/habitat-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aq5M6wUV",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[1,[28,[35,1],[\"Photobank.classifications\"],null]],[1,\" \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"classifications\"]],[[[1,\"            \"],[8,[39,2],null,[[\"@content\",\"@value\"],[[30,0,[\"classifications\"]],[30,0,[\"classificationId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[1,[33,3,[\"config\",\"label\"]]],[1,\" \"],[13],[1,\"\\n\\n        \"],[8,[39,4],null,[[\"@value\"],[[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@model\",\"@update\",\"@filters\"],[\"habitat/community\",[30,2],[30,0,[\"autocompleteFilters\"]]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@filter\",\"add\"],false,[\"if\",\"i18n-t\",\"plutof-select/value\",\"filter\",\"auto-complete/bind-multiple\",\"auto-complete/model\"]]",
    "moduleName": "plutof/templates/components/search/habitat-input.hbs",
    "isStrictMode": false
  });
});