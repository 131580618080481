define("plutof/components/information/content/general/map-controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h4> {{i18n-t 'Map.controls.controls'}}</h4>
  <img src="/assets/images/map-controls.png" class="img-responsive">
  
  <br>
  
  <h4> {{i18n-t 'Map.markers'}}</h4>
  <img src="/assets/images/map-markers.png" class="img-responsive">
  
  */
  {
    "id": "1fCyO3Sv",
    "block": "[[[10,\"h4\"],[12],[1,\" \"],[1,[28,[35,0],[\"Map.controls.controls\"],null]],[13],[1,\"\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/map-controls.png\"],[14,0,\"img-responsive\"],[12],[13],[1,\"\\n\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\\n\"],[10,\"h4\"],[12],[1,\" \"],[1,[28,[35,0],[\"Map.markers\"],null]],[13],[1,\"\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/map-markers.png\"],[14,0,\"img-responsive\"],[12],[13],[1,\"\\n\"]],[],false,[\"i18n-t\"]]",
    "moduleName": "plutof/components/information/content/general/map-controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});