define("plutof/templates/components/menu/language-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "q9DKci0f",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"visibleButtons\"]]],null]],null],null,[[[1,\"    \"],[11,\"button\"],[24,0,\"main-menu-button-bar__btn\"],[24,4,\"button\"],[4,[38,2],[[30,0],[30,2],[30,1,[\"locale\"]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[\"btn\",\"@switch\"],false,[\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "plutof/templates/components/menu/language-buttons.hbs",
    "isStrictMode": false
  });
});