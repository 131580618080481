define("plutof/components/identifiers/identifier-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="identifier-input">
      <div class="identifier-input__prefix">
          {{@prefix}}
      </div>
  
      <Input
          @type="number"
          @value={{this.stringValue}}
          class="form-control identifier-input__number" />
  </div>
  
  */
  {
    "id": "WdxYYlfy",
    "block": "[[[10,0],[14,0,\"identifier-input\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"identifier-input__prefix\"],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,0],[[24,0,\"form-control identifier-input__number\"]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"stringValue\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@prefix\"],false,[\"input\"]]",
    "moduleName": "plutof/components/identifiers/identifier-input.hbs",
    "isStrictMode": false
  });
  let IdentifierInput = (_dec = (0, _object.computed)('args.number'), _class = class IdentifierInput extends _component2.default {
    get stringValue() {
      return (0, _utils.isNone)(this.args.number) ? '' : this.args.number.toString();
    }
    set stringValue(value) {
      const number = parseInt(value);
      if (!isNaN(number)) {
        this.args.update(number);
      } else {
        this.args.update(null);
      }
      return value;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "stringValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "stringValue"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IdentifierInput);
});