define("plutof/controllers/clipboard/clipboard", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/runloop", "@ember/service", "@ember/utils", "rsvp", "plutof/config/environment", "plutof/controllers/clipboard/utils/remove-objects", "plutof/misc/abstract", "plutof/misc/profile_settings", "plutof/utils/notifications", "plutof/utils/pagination"], function (_exports, _object, _computed, _mixin, _runloop, _service, _utils, _rsvp, _environment, _removeObjects, _abstract, _profile_settings, _notifications, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Entry = _exports.ClipboardControllerMixin = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const PAGE_SIZE = 100;
  const ORDERABLE_COUNT_LIMIT = 10000;
  const Entry = _exports.Entry = _object.default.extend({
    id: null,
    name: null,
    geom: null,
    cbdatum: null,
    init: function () {
      this._super(...arguments);
      this.set('selected', false);
    }
  });

  // Instead of being the source of truth (maybe later, this is far from the usual case), adapts
  // clipboard pagination-related stuff to Pagination interface
  let ClipboardPagination = (_dec = (0, _computed.reads)('controller.pageSize'), _dec2 = (0, _object.computed)('controller.pageData.totalCount'), _dec3 = (0, _computed.reads)('controller.pageNumber'), _dec4 = (0, _computed.reads)('controller.currentPage'), _dec5 = (0, _computed.reads)('controller.pageLoading'), _class = class ClipboardPagination extends _pagination.BasePagination {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "pageSize", _descriptor, this);
      _initializerDefineProperty(this, "pageNumber", _descriptor2, this);
      _initializerDefineProperty(this, "objects", _descriptor3, this);
      _initializerDefineProperty(this, "isLoading", _descriptor4, this);
    }
    get objectCount() {
      return this.controller.pageData.totalCount || 0;
    }
    switchPage(number, params) {
      return this.controller.switchPage(number, this.pageSize);
    }

    // XXX Required for map atm. Have to expose it, because of its interaction
    // with clipboard cache (which is not really a thing that should exist, instead
    // just paginate and hold selection separately)
    async loadAndProcessPage(number, pageSize) {
      const {
        processedPage
      } = await this.controller.loadPage(number, pageSize);
      const page = await processedPage;
      return page;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pageSize", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "objectCount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "objectCount"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pageNumber", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "objects", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // TODO XXX: Remove this
  const ClipboardControllerMixin = _exports.ClipboardControllerMixin = _mixin.default.create(_removeObjects.RemoveObjectsMixin, {
    ajax: (0, _service.inject)(),
    clipboardService: (0, _service.inject)('clipboard'),
    setSelectedEntries: function () {
      const selection = (0, _utils.isEmpty)(this.entries) ? [] : this.entries.filterBy('selected');
      this.set('selectedEntries', selection);
      this.clipboardService.forContentType(this.contentType)._selection = selection;
    },
    /**
     * Since we are not going to move all of clipboard's mass operation
     * functionality to back-end as of yet, most of clipboard's code remains
     * as it is.
     *
     * selectedEntries is observed by several functions thus triggering
     * updates on it triggers exponentially more calculations elsewhere,
     * making the entire endeavor slow and cumbersome.
     *
     * Though not pretty, a gated observer is a solution to counter the
     * above-mentioned rampart updates. It'll have to do for now.
     */
    triggerSetSelectedEntries: (0, _object.observer)('entries.@each.selected', function () {
      (0, _runloop.debounce)(this, this.setSelectedEntries, 200);
    }),
    pinnedColumnName: null,
    selectedTab: null,
    // Clipboard tab name from tab-group component.
    selectedEntries: [],
    selectedCount: (0, _computed.alias)('selectedEntries.length'),
    // XXX: utils/remove-objects
    canNotStart: (0, _computed.alias)('canNotRemove'),
    init: function () {
      this._super(...arguments);
      this.set('entries', []);
      this.set('pageRequests', {});
      this.set('pageNumber', 1);
      this.set('currentPage', []);
      this.set('pageData', []); // It's not really an array, is it?
      this.set('ordering', 'name'); // Default ordering
      this.set('pageSize', PAGE_SIZE);

      // XXX: Not proud of this
      // Used to fix a bug:
      // 1. User removes an entry from the page N
      // 2. Cache is invalidated, therefore a new request for page N is sent
      // 3. While the request is in flight, user removes another entry
      // 4. Another request is sent
      // 5. User still sees results of the first one, at least until the second resolves
      this.set('version', 1);
      this.set('pagination', ClipboardPagination.create({
        controller: this
      }));
    },
    invalidatePageCache: function () {
      this.set('entries', []);
      this.set('pageRequests', {});
      this.set('currentPage', []);

      // TODO Make it less loose
      return (0, _profile_settings.get_personal_settings)(this.store).then(settings => {
        this.set('clipboardSize', settings.get('clipboard_size'));
        return this.switchPage(1, this.pageSize);
      }).catch(_notifications.reportError);
    },
    bumpVersion: function () {
      this.set('version', this.version + 1);
    },
    // Take a single object from backend response and returns a promise
    // that resolves to an Entry subclass
    processClipboardJSON: function (cbdatum) {
      return (0, _abstract.wrap_as_promise)(Entry.create({
        id: cbdatum.id,
        name: cbdatum.name,
        geom: cbdatum.geom,
        cbdatum: cbdatum
      }));
    },
    // Returns a promise of processed clipboard data, to display cb table.
    processPageData: function (data) {
      return _rsvp.default.all(data.map(datum => this.processClipboardJSON(datum))).then(entries => {
        this.entries.pushObjects(entries);
        return entries;
      });
    },
    clipboardURL: null,
    loadPage: function (page_number, pageSize, disableOrdering = false) {
      const pageRequests = this.pageRequests;
      const currentVersion = this.version;
      const cacheKey = `${page_number}-${pageSize}-${currentVersion}`;
      const cachedPage = pageRequests[cacheKey];
      if ((0, _utils.isPresent)(cachedPage)) {
        return cachedPage;
      } else {
        const url = (0, _abstract.construct_request)(this.clipboardURL, {
          page: page_number,
          page_size: pageSize,
          ordering: disableOrdering ? null : this.ordering
        });
        const pageRequest = this.ajax.request(url).then(data => {
          if (this.version === currentVersion) {
            return {
              data: data,
              // Not resolved so user can skip pages, I guess
              processedPage: (0, _abstract.wrap_as_promise)(this.processPageData((0, _object.get)(data, 'results')))
            };
          } else {
            return {
              data: data,
              processedPage: (0, _abstract.wrap_as_promise)([])
            };
          }
        }, _notifications.reportError);
        pageRequests[cacheKey] = pageRequest;
        return pageRequest;
      }
    },
    noEntriesSelected: (0, _computed.empty)('selectedEntries'),
    pageCount: (0, _object.computed)('pageData.totalCount', 'pageSize', function () {
      return Math.ceil(this.get('pageData.totalCount') / this.pageSize) || 1;
    }),
    getClipboardCount: function () {
      return this.ajax.request(`${_environment.default.API_HOST}/clipboard/count/`).then(response => {
        return response[this.countField];
      });
    },
    switchPage: function (page_number, pageSize = PAGE_SIZE) {
      if (this.pageSize !== pageSize) {
        this.set('pageSize', pageSize);
        return this.invalidatePageCache();
      }
      this.set('pageLoading', true);
      this.set('pageNumber', page_number);
      var start_version = this.version;

      // XXX Too much inception
      return this.getClipboardCount().then(count => {
        const disableOrdering = count > ORDERABLE_COUNT_LIMIT;
        return this.loadPage(page_number, pageSize, disableOrdering).then(response => {
          if (this.pageNumber === page_number && start_version === this.version) {
            return response.processedPage.then(processedPage => {
              const pageData = response.data;

              /*
               * In the absence of a better idea, we are going to
               * stuff pageData with fresh count and less fresh
               * clipboard limit.
               */
              (0, _object.set)(pageData, 'totalCount', count);
              (0, _object.set)(pageData, 'clipboardSize', this.clipboardSize); // XXX TODO WIP
              (0, _object.set)(pageData, 'orderableLimit', ORDERABLE_COUNT_LIMIT);
              this.set('pageLoading', false);
              this.set('currentPage', processedPage); // For clipboard functions
              this.set('pageData', pageData); // For results-table component
            });
          } else {
            this.set('pageLoading', false);
            return _abstract.EMPTY_PROMISE;
          }
        });
      });
    },
    // UI
    pageLoading: false,
    actions: {
      triggerPageSwitch: function (pageNumber, pageSize) {
        this.switchPage(pageNumber, pageSize);
      },
      orderBy: function (header) {
        const orderColumn = this.get('absoluteOrdering');
        if (orderColumn === header.name) {
          this.set('ordering', header.ascendingOrder ? `-${header.name}` : header.name);
          header.toggleProperty('ascendingOrder');
        } else {
          this.set('ordering', `-${header.name}`);
          this.set('absoluteOrdering', header.name);
          header.set('ascendingOrder', false);
        }

        // It's done here to keep it in the controller
        this.invalidatePageCache();
      },
      // XXX
      iFeelLucky: function () {
        this.notifyPropertyChange('pageNumber');
      },
      setProperty: function (propertyName, value) {
        this.set(propertyName, value);
      },
      // XXX TODO: use validation context
      updateDarwinCoreExportValidity(valid) {
        this.set('darwinCoreExportValidity', valid);
      },
      updatePageSize(pageSize) {
        this.set('pageSize', pageSize);
        this.pagination.switchPage(1);
      },
      pinColumn(headers, targetHeader) {
        headers.forEach(header => {
          if (header === targetHeader) {
            header.toggleProperty('isPinned');
          } else {
            header.set('isPinned', false);
          }
        });
        this.set('pinnedColumnName', targetHeader.name);
      }
    }
  });
  var _default = _exports.default = ClipboardControllerMixin;
});