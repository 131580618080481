define("plutof/templates/components/profile/bookmark-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "H+r+tK6v",
    "block": "[[[10,0],[14,\"data-test\",\"bookmarks-list\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,1],[14,0,\"fas fa-arrows-rotate spinicon\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n            \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[1,\"\\n                \"],[10,\"tbody\"],[12],[1,\"\\n                    \"],[10,\"tr\"],[12],[1,\"\\n                        \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[1,\"\\n                            \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\" \"],[1,[28,[35,1],[\"General.Bookmarks\"],null]],[1,\" \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"generalBookmarks\"]]],null]],null],null,[[[1,\"                        \"],[8,[39,4],null,[[\"@bookmark\",\"@remove\"],[[30,2],[28,[37,5],[[30,3],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n                    \"],[10,\"tr\"],[12],[1,\"\\n                        \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[1,\"\\n                            \"],[10,0],[15,0,[29,[\"plutof-label \",[52,[30,0,[\"generalBookmarks\"]],\"spacer-above\"]]]],[12],[1,\" \"],[1,[28,[35,1],[\"General.searches\"],null]],[1,\" \"],[13],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"searchBookmarks\"]]],null]],null],null,[[[1,\"                        \"],[8,[39,4],null,[[\"@bookmark\",\"@remove\"],[[30,4],[28,[37,5],[[30,3],[30,4]],null]]],null],[1,\"\\n\"]],[4]],null],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@isLoading\",\"bookmark\",\"@remove\",\"bookmark\"],false,[\"if\",\"i18n-t\",\"each\",\"-track-array\",\"profile/-bookmark-list-row\",\"fn\"]]",
    "moduleName": "plutof/templates/components/profile/bookmark-list.hbs",
    "isStrictMode": false
  });
});