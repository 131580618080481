define("plutof/components/ui/modal/frame", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _object, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <dialog class="dialog-frame"
      {{get-reference this.register}}
      {{on "click" this.clickedOnFrame}}
      {{on "cancel" this.close}}
  >
      {{#if @open}}
          {{yield}}
      {{/if}}
  </dialog>
  
  */
  {
    "id": "c0hwaeOx",
    "block": "[[[11,\"dialog\"],[24,0,\"dialog-frame\"],[4,[38,0],[[30,0,[\"register\"]]],null],[4,[38,1],[\"click\",[30,0,[\"clickedOnFrame\"]]],null],[4,[38,1],[\"cancel\",[30,0,[\"close\"]]],null],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[18,2,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@open\",\"&default\"],false,[\"get-reference\",\"on\",\"if\",\"yield\"]]",
    "moduleName": "plutof/components/ui/modal/frame.hbs",
    "isStrictMode": false
  });
  let DialogFrame = (_class = class DialogFrame extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "dialog", null);
    }
    register(element) {
      this.dialog = element;
      try {
        if (this.args.open) {
          element.showModal();
        } else if (element.open) {
          element.close();
        }
      } catch (error) {
        (0, _notifications.reportError)(error);
      }
    }
    clickedOnFrame(event) {
      if (event.target === this.dialog) {
        this.close();
      }
    }
    close() {
      this.dialog.close();
      if (this.args.close) {
        this.args.close();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickedOnFrame", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickedOnFrame"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DialogFrame);
});