define("plutof/templates/components/filter-selection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y8Iw2aos",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"placeholder\",\"autocomplete\",\"classNames\"],[[33,1],[33,2],\"off\",[33,3]]]]],[1,\"\\n\\n\"],[41,[33,5],[[[1,\"    \"],[10,0],[14,0,\"plutof-ac-dropdown-menu\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,8]],null]],null],null,[[[1,\"                \"],[11,0],[16,0,[29,[\"plutof-ac-suggestion \",[52,[30,1,[\"hasFocus\"]],\"plutof-ac-suggestion-focus\"]]]],[4,[38,9],[\"touchend\",[28,[37,10],[[30,0,[\"touchItem\"]],[30,1]],null]],null],[4,[38,9],[\"mouseenter\",[28,[37,10],[[30,0,[\"focusItem\"]],[30,1]],null]],null],[4,[38,9],[\"touchstart\",[28,[37,10],[[30,0,[\"focusItem\"]],[30,1]],null]],null],[4,[38,9],[\"mouseleave\",[28,[37,10],[[30,0,[\"unfocusItem\"]],[30,1]],null]],null],[12],[1,\"\\n                    \"],[10,2],[12],[1,\"\\n                        \"],[1,[28,[35,11],[[30,1,[\"label\"]],[33,1]],null]],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"input\",\"value\",\"placeholder\",\"inputClassNames\",\"if\",\"showDropdownMenu\",\"each\",\"-track-array\",\"visibleItems\",\"on\",\"fn\",\"hl-subs\"]]",
    "moduleName": "plutof/templates/components/filter-selection.hbs",
    "isStrictMode": false
  });
});