define("plutof/utils/reflection", ["exports", "@ember/application", "@ember/utils", "ember-inflector", "plutof/config/environment"], function (_exports, _application, _utils, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getService = getService;
  _exports.get_record_url = get_record_url;
  _exports.recordURLToID = recordURLToID;
  _exports.serializeRecord = serializeRecord;
  function get_record_url(record) {
    var url;
    if (!(0, _utils.isNone)(record._data)) {
      url = record._data.url;
    }
    if ((0, _utils.isNone)(url)) {
      var type = record.constructor.modelName;
      url = _environment.default.API_HOST + '/' + (0, _emberInflector.pluralize)(type) + '/' + record.get('id') + '/';
    }
    return url;
  }
  function recordURLToID(url) {
    var parts = url.split('/');
    return parts[parts.length - 2];
  }
  function serializeRecord(record) {
    const serializer = record.store.serializerFor(record.constructor.modelName);
    const snapshot = record._createSnapshot();
    return serializer.serialize(snapshot);
  }
  function getService(owned, serviceName) {
    const container = (0, _application.getOwner)(owned);
    return container.lookup(`service:${serviceName}`);
  }
});