define("plutof/templates/components/experiment/common/time-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xcQ9RjN3",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"inputClasses\"]]]],[[\"@value\",\"@update\",\"@disabled\"],[[30,0,[\"minutes\"]],[30,0,[\"updateMinutes\"]],[30,1]]],null],[1,\"\\n\\n\"],[10,1],[15,0,[29,[[30,0,[\"separatorClasses\"]]]]],[12],[1,\"\\n    :\\n\"],[13],[1,\"\\n\\n\"],[8,[39,0],[[16,0,[30,0,[\"inputClasses\"]]]],[[\"@value\",\"@update\",\"@disabled\"],[[30,0,[\"seconds\"]],[30,0,[\"updateSeconds\"]],[30,1]]],null],[1,\"\\n\"]],[\"@disabled\"],false,[\"one-way-input\"]]",
    "moduleName": "plutof/templates/components/experiment/common/time-input.hbs",
    "isStrictMode": false
  });
});