define("plutof/templates/components/referencebased/view/general-data", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "onlFtUNV",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Referencebased::View::GeneralData\"]],[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[5]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"link\"]],[\"taxon_node\"],[[\"label\",\"route\"],[[28,[37,1],[\"ReferenceBased.nameTitle\"],null],\"taxonomy.view\"]]]],[1,\"\\n\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"new_name\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"misspelling\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"identification_assessment\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"identification_comment\"],null]],[1,\"\\n\\n\"],[6,[30,3,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,2,[\"link\"]],[\"reference\"],[[\"route\"],[\"reference.view\"]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,3,[\"span\"]],[3],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,2,[\"attr\"]],[\"pages\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"first_occurrence\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"field\"]],[\"occurrence_assessment\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"occurrence_comment\"],null]],[1,\"\\n\\n\"],[6,[30,3,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,2,[\"attr\"]],[\"remarks\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n            \"],[1,[28,[30,2,[\"attr\"]],[\"occurrence_source\"],null]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@refbased\",\"view\",\"grid\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/templates/components/referencebased/view/general-data.hbs",
    "isStrictMode": false
  });
});