define("plutof/components/search/clipboard-controls", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ToolTip @tooltip={{i18n-t "Search.clipboard.sendPageToClipboard"}}>
      <PromiseButton
          @clicked={{@sendPage}}
          @restIcon="panel-header-btn__icon {{icon 'clipboard'}}"
          @disabled={{@disabled}}
          class="panel-header-btn" />
  </ToolTip>
  
  <ToolTip @tooltip={{i18n-t "Search.clipboard.sendAllToClipboard"}}>
      <PromiseButton
          @clicked={{@sendAll}}
          @restIcon="panel-header-btn__icon {{icon 'clipboard-add-all'}}"
          @disabled={{@disabled}}
          class="panel-header-btn" />
  </ToolTip>
  
  */
  {
    "id": "6VEUMhGB",
    "block": "[[[8,[39,0],null,[[\"@tooltip\"],[[28,[37,1],[\"Search.clipboard.sendPageToClipboard\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"panel-header-btn\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,1],[29,[\"panel-header-btn__icon \",[28,[37,3],[\"clipboard\"],null]]],[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@tooltip\"],[[28,[37,1],[\"Search.clipboard.sendAllToClipboard\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"panel-header-btn\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[30,3],[29,[\"panel-header-btn__icon \",[28,[37,3],[\"clipboard-add-all\"],null]]],[30,2]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@sendPage\",\"@disabled\",\"@sendAll\"],false,[\"tool-tip\",\"i18n-t\",\"promise-button\",\"icon\"]]",
    "moduleName": "plutof/components/search/clipboard-controls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});