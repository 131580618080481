define("plutof/components/taxonomy/edit-remarks-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{@title}}
      data-test="Taxonomy::TaxonNode::EditRemarksPanel"
  >
      <p>
          <span class={{icon "info"}}></span>
          {{i18n-t "Taxonomy.editPanelInfo"}}
      </p>
  
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc "taxonomy.taxonnode.remarks"}}
  
              <Input
                  @type="text"
                  @value={{@taxon.remarks}}
                  class="form-control"
                  data-test="remarks" />
          </div>
  
          <div>
              {{plutof-labelc "taxonomy.taxonnode.citation_text"}}
  
              <Input
                  @type="text"
                  @value={{@taxon.citation_text}}
                  class="form-control"
                  data-test="citation_text" />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "dE15dIHs",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Taxonomy::TaxonNode::EditRemarksPanel\"]],[[\"@title\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n        \"],[10,1],[15,0,[28,[37,1],[\"info\"],null]],[12],[13],[1,\"\\n        \"],[1,[28,[35,2],[\"Taxonomy.editPanelInfo\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"taxonomy.taxonnode.remarks\"],null]],[1,\"\\n\\n            \"],[8,[39,5],[[24,0,\"form-control\"],[24,\"data-test\",\"remarks\"]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"remarks\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"taxonomy.taxonnode.citation_text\"],null]],[1,\"\\n\\n            \"],[8,[39,5],[[24,0,\"form-control\"],[24,\"data-test\",\"citation_text\"]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"citation_text\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@title\",\"@taxon\"],false,[\"plutof-panel/simple\",\"icon\",\"i18n-t\",\"layout/regular-grid\",\"plutof-labelc\",\"input\"]]",
    "moduleName": "plutof/components/taxonomy/edit-remarks-panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});