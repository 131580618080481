define("plutof/helpers/sum", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getSum = getSum;
  function getSum(elements) {
    return elements.reduce((a, b) => a + b, 0);
  }
  var _default = _exports.default = (0, _helper.helper)(getSum);
});