define("plutof/templates/components/auth/identity-provider-buttons/google-oauth2", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "febi/y7c",
    "block": "[[[11,\"img\"],[24,\"src\",\"/assets/images/auth/google-button.png\"],[24,0,\"social-login-button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[13],[1,\"\\n\"]],[\"@clicked\"],false,[\"on\"]]",
    "moduleName": "plutof/templates/components/auth/identity-provider-buttons/google-oauth2.hbs",
    "isStrictMode": false
  });
});