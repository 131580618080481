define("plutof/templates/components/profile/groups/email-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8b2VqwVX",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@close\"],[[30,1],[99,1,[\"@close\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"Settings.groups.email.title\"],null]],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[51,[30,0,[\"subjectValid\"]]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"Settings.groups.email.subject\"],null]],[1,\"\\n\\n            \"],[1,[28,[35,5],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,0,[\"subject\"]],\"form-control\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[51,[30,0,[\"contentValid\"]]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"Settings.groups.email.content\"],null]],[1,\"\\n\\n            \"],[8,[39,6],[[24,0,\"form-control\"]],[[\"@value\",\"@rows\"],[[30,0,[\"content\"]],5]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n        \"],[8,[39,7],[[24,0,\"btn btn-default\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[30,0,[\"_send\"]],[28,[37,8],[[30,0,[\"isValid\"]]],null],\"far fa-envelope\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"Settings.groups.email.send\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@open\"],false,[\"ui/modal/dialog\",\"_close\",\"i18n-t\",\"unless\",\"common/local-label\",\"input\",\"flexible-textarea\",\"promise-button\",\"not\"]]",
    "moduleName": "plutof/templates/components/profile/groups/email-dialog.hbs",
    "isStrictMode": false
  });
});