define("plutof/templates/components/analysis/wrapper-source", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qyDuJBhU",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"Analysis.sourceData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[[52,[51,[30,2,[\"present\"]]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"Analysis.fileLabel\"],null]],[1,\"\\n\\n            \"],[8,[39,5],null,[[\"@model\",\"@disabled\"],[[30,2,[\"source\"]],[30,2,[\"fasta_in_text\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[6,[30,1,[\"span\"]],[2],null,[[\"default\"],[[[[1,\"            \"],[1,[28,[35,4],[\"Analysis.fastaTextLabel\"],null]],[1,\"\\n\\n            \"],[8,[39,6],[[24,0,\"form-control analysis-data__fasta-in-text\"],[16,\"disabled\",[30,2,[\"source\",\"present\"]]]],[[\"@value\",\"@rows\"],[[30,2,[\"fasta_in_text\"]],5]],null],[1,\"\\n\"]],[]]]]],[1,\"    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[51,[30,2,[\"fileSizeIsAcceptable\"]]],[[[1,\"        \"],[10,0],[14,0,\"failed-check-message\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"Analysis.fileTooBigInfo\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"grid\",\"@data\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"unless\",\"common/local-label\",\"filerepository/select-or-upload\",\"flexible-textarea\"]]",
    "moduleName": "plutof/templates/components/analysis/wrapper-source.hbs",
    "isStrictMode": false
  });
});