define("plutof/routes/experiment/dna/view", ["exports", "@ember/utils", "rsvp", "plutof/components/experiment/utils", "plutof/misc/content_types", "plutof/utils/routes"], function (_exports, _utils, _rsvp, _utils2, _content_types, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  async function getParentRecord(dna) {
    const collection = await dna.collection;
    const extraction = await dna.dna_extraction;
    if ((0, _utils.isPresent)(collection)) {
      return collection;
    }
    if ((0, _utils.isPresent)(extraction)) {
      return extraction.laboratory;
    }
    return null;
  }
  class DNAView extends _routes.ViewRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'experiment.dna.index');
      _defineProperty(this, "title", 'experiment.dna.routeTitles.view');
    }
    async model(params) {
      // XXX: There is a deep bug with the way sideloaded records are handled -
      // Opening a pcr.view sideloads product-linked DNAs, but while those can be
      // shown and seem ok, fetching collection on them leads to an agent/organization
      // request and not agent/collection one. I've spent way too much time on this already
      // and just ended up with a workaround. TODO: Revisit this when agent/collection
      // is a proper model
      let dna = this.store.peekRecord('dna-lab/dna', params.id);
      if (dna) {
        await this.store.unloadRecord(dna);
      }
      dna = await this.store.findRecord('dna-lab/dna', params.id, {
        include: 'specimen,material_sample,living_specimen,dna_extraction'
      });
      const permissions = await (0, _utils2.experimentPermissions)(this.store, dna, await getParentRecord(dna));

      // Sideloaded stuff is partial, because it is potentially private (yay permissions).
      // Therefore we cannot use it to show determinations/sample. And not sideloading doesn't
      // solven anything, because we have to use @privateGuard then which means no source name.
      // What a mess
      const sources = await _rsvp.default.all(['specimen', 'material_sample', 'living_specimen'].map(s => dna[s]));
      const sourcePointer = sources.find(Boolean);
      let source, sourceCType;
      try {
        source = await this.store.findRecord(sourcePointer.constructor.modelName, sourcePointer.id);
        sourceCType = await (0, _content_types.get_object_ctype)(this.store, source);
      } catch (e) {
        source = null;
      }
      return {
        dna,
        source,
        sourceCType,
        permissions
      };
    }
  }
  var _default = _exports.default = DNAView;
});