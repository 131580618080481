define("plutof/components/add-multiple-form/-add-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="component-part component-part--button">
      <button
          {{on "click" @add}}
          disabled={{@disable}}
          class="btn btn-default control"
      >
          <span class="fas fa-check"></span>
          {{i18n-t "General.Add"}}
      </button>
  </div>
  
  */
  {
    "id": "/d7s6Q1s",
    "block": "[[[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,1]],[24,0,\"btn btn-default control\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"fas fa-check\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,1],[\"General.Add\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disable\",\"@add\"],false,[\"on\",\"i18n-t\"]]",
    "moduleName": "plutof/components/add-multiple-form/-add-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});