define("plutof/components/tables/row-selector/selector", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
      @type="checkbox"
      @checked={{this.selected}}
      @disabled={{@disabled}}
      class="table-row-selector__selector" />
  
  */
  {
    "id": "NPwEOav+",
    "block": "[[[8,[39,0],[[24,0,\"table-row-selector__selector\"]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[30,0,[\"selected\"]],[30,1]]],null],[1,\"\\n\"]],[\"@disabled\"],false,[\"input\"]]",
    "moduleName": "plutof/components/tables/row-selector/selector.hbs",
    "isStrictMode": false
  });
  class TableRowSelectorItem extends _component2.default {
    get selected() {
      return Boolean(this.getSelf());
    }
    getSelf() {
      return this.args.selection.find(v => this.args.equality(v, this.args.item));
    }
    set selected(value) {
      if (value) {
        this.args.selection.pushObject(this.args.item);
      } else {
        const match = this.getSelf();
        this.args.selection.removeObject(match);
      }
      return value;
    }
  }
  _exports.default = TableRowSelectorItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableRowSelectorItem);
});