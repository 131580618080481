define("plutof/templates/components/navbar/-navbar-base/bookmark", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WzIKVNhB",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"bookmark-button\"]],[[\"@clicked\"],[[30,0,[\"show\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[28,[37,1],[\"bookmark\"],null]],[12],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"General.Bookmark\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"construct\"]],[[[1,\"    \"],[8,[39,4],[[24,\"data-test\",\"bookmark-dialog\"]],[[\"@open\",\"@close\"],[[30,0,[\"open\"]],[30,0,[\"close\"]]]],[[\"header\",\"body\",\"footer\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[\"General.Bookmarks\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,5],[[24,\"data-test\",\"description\"],[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,0,[\"description\"]],[30,0,[\"save\"]]]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n            \"],[8,[39,6],[[24,0,\"btn btn-default\"],[24,\"data-test\",\"save\"]],[[\"@clicked\",\"@restIcon\"],[[30,0,[\"save\"]],[28,[37,1],[\"ok\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,2],[\"General.Save\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,7],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,1],[\"cancel\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,2],[\"General.Cancel\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"navbar/-navbar-base/button\",\"icon\",\"i18n-t\",\"if\",\"ui/modal/dialog\",\"input\",\"promise-button\",\"on\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/bookmark.hbs",
    "isStrictMode": false
  });
});