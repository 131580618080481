define("plutof/components/collection-lab/permit/edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/computed", "plutof/components/linked-items/edit", "plutof/utils/validations", "plutof/utils/access"], function (_exports, _component, _templateFactory, _component2, _computed, _edit, _validations, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createPermitModel = createPermitModel;
  _exports.default = void 0;
  _exports.loadPermitModel = loadPermitModel;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Layout::RegularGrid @columns={{3}} as |grid|>
          <GenericForms::Record @record={{@model.permit}} as |form|>
              {{form.bind "name" valid=this.nameValid}}
              {{form.bind "status" valid=this.statusValid}}
              {{form.bind "permit_type" valid=this.typeValid}}
  
              {{#grid.span 3}}
                  {{form.bind "status_qualifier"}}
              {{/grid.span}}
  
              {{#grid.span 3}}
                  {{form.bind "permit_text"}}
              {{/grid.span}}
          </GenericForms::Record>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  <LinkedItems::Edit
      @data={{@model.linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  */
  {
    "id": "/Ulp/nU9",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@record\"],[[30,2,[\"permit\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"name\"],[[\"valid\"],[[30,0,[\"nameValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"status\"],[[\"valid\"],[[30,0,[\"statusValid\"]]]]]],[1,\"\\n            \"],[1,[28,[30,3,[\"bind\"]],[\"permit_type\"],[[\"valid\"],[[30,0,[\"typeValid\"]]]]]],[1,\"\\n\\n\"],[6,[30,1,[\"span\"]],[3],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,3,[\"bind\"]],[\"status_qualifier\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[30,1,[\"span\"]],[3],null,[[\"default\"],[[[[1,\"                \"],[1,[28,[30,3,[\"bind\"]],[\"permit_text\"],null]],[1,\"\\n\"]],[]]]]],[1,\"        \"]],[3]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@data\",\"@validationContext\"],[[30,2,[\"linkedItems\"]],[30,4,[\"linkedItems\"]]]],null],[1,\"\\n\"]],[\"grid\",\"@model\",\"form\",\"@validationContext\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"generic-forms/record\",\"linked-items/edit\"]]",
    "moduleName": "plutof/components/collection-lab/permit/edit.hbs",
    "isStrictMode": false
  });
  class PermitModel {
    constructor({
      store,
      permit,
      linkedItems
    }) {
      this.store = store;
      this.permit = permit;
      this.linkedItems = linkedItems;
    }
    async save() {
      await this.permit.save();
      const access = await (0, _access.create_access_rights)(this.store);
      access.set('visible', 'PUBLIC');
      await this.linkedItems.save([this.permit], access);
    }
  }
  async function createPermitModel(store) {
    const permit = store.createRecord('permit/permit');
    const linkedItems = await (0, _edit.createLinkedItemsData)(store, permit);
    return new PermitModel({
      store,
      permit,
      linkedItems
    });
  }
  async function loadPermitModel(store, permit) {
    const linkedItems = await (0, _edit.loadLinkedItemsData)(store, permit);
    return new PermitModel({
      store,
      permit,
      linkedItems
    });
  }
  let PermitEdit = (_dec = (0, _computed.notEmpty)('args.model.permit.name'), _dec2 = (0, _computed.notEmpty)('args.model.permit.status'), _dec3 = (0, _computed.notEmpty)('args.model.permit.permit_type'), _class = class PermitEdit extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "nameValid", _descriptor, this);
      _initializerDefineProperty(this, "statusValid", _descriptor2, this);
      _initializerDefineProperty(this, "typeValid", _descriptor3, this);
      this.args.validationContext.addChild('linkedItems');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "nameValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "statusValid", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "typeValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PermitEdit);
});