define("plutof/components/project/record-project", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRecordProjectModel = createRecordProjectModel;
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.Project"}}
      data-test="Record::Project"
  >
      {{#if @data.projectIsPrivate}}
          <Common::PrivateRelation />
      {{else}}
          <Layout::RegularGrid
              @columns={{2}}
              @cellGap={{true}}
          >
              <div>
                  <div class="column-heading flex-row">
                      <Common::LocalLabel @label="Projects.project" />
  
                      {{#unless @hideHelper}}
                          <Information::DialogTrigger
                              @title={{i18n-t "Sample.area.areaSelectionHelper"}}
                              @content="information/content/general/area-project-filter" />
                      {{/unless}}
                  </div>
  
                  {{#async/bind-relation @data.project as |value update|}}
                      <Project::AutoComplete
                          @value={{value}}
                          @update={{fn this.update update}}
                          @disabled={{@disabled}}
                          data-test="project" />
                  {{/async/bind-relation}}
              </div>
          </Layout::RegularGrid>
  
          <Project::View::RecordProjectFields @project={{@data.project}} />
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "dSFMOHyE",
    "block": "[[[8,[39,0],[[24,\"data-test\",\"Record::Project\"]],[[\"@title\"],[[28,[37,1],[\"General.Project\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"projectIsPrivate\"]],[[[1,\"        \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[10,0],[14,0,\"column-heading flex-row\"],[12],[1,\"\\n                    \"],[8,[39,5],null,[[\"@label\"],[\"Projects.project\"]],null],[1,\"\\n\\n\"],[41,[51,[30,2]],[[[1,\"                        \"],[8,[39,7],null,[[\"@title\",\"@content\"],[[28,[37,1],[\"Sample.area.areaSelectionHelper\"],null],\"information/content/general/area-project-filter\"]],null],[1,\"\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n\\n\"],[6,[39,8],[[30,1,[\"project\"]]],null,[[\"default\"],[[[[1,\"                    \"],[8,[39,9],[[24,\"data-test\",\"project\"]],[[\"@value\",\"@update\",\"@disabled\"],[[30,3],[28,[37,10],[[30,0,[\"update\"]],[30,4]],null],[30,5]]],null],[1,\"\\n\"]],[3,4]]]]],[1,\"            \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[39,11],null,[[\"@project\"],[[30,1,[\"project\"]]]],null],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"@data\",\"@hideHelper\",\"value\",\"update\",\"@disabled\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"if\",\"common/private-relation\",\"layout/regular-grid\",\"common/local-label\",\"unless\",\"information/dialog-trigger\",\"async/bind-relation\",\"project/auto-complete\",\"fn\",\"project/view/record-project-fields\"]]",
    "moduleName": "plutof/components/project/record-project.hbs",
    "isStrictMode": false
  });
  // XXX TODO: Atm most of the modules store project in sample model, where it was
  // relevant originally. So that is what gets passed to Project::RecordProject.
  //
  // However, it should be replaces with this, because sample and occurrence project
  // can now be different (for example, when sequence is linked to the specimen and
  // reuses its sample). Because there is no time atm for a proper refactor, this
  // class is duck-compatible with sample model for the purposes of RecordProject
  let RecordProjectModel = (_class = class RecordProjectModel {
    constructor({
      project,
      projectIsPrivate
    }) {
      _initializerDefineProperty(this, "_project", _descriptor, this);
      _initializerDefineProperty(this, "projectIsPrivate", _descriptor2, this);
      this._project = project;
      this.projectIsPrivate = projectIsPrivate;
    }
    get project() {
      return this._project;
    }
    set project(project) {
      this._project = project;
      this.projectIsPrivate = false;
      return project;
    }
    apply(occurrence) {
      if (!this.projectIsPrivate) {
        occurrence.set('project', this.project);
      }
    }
    applyToSample(sample) {
      if (!this.projectIsPrivate) {
        sample.area.set('study', this.project);
        sample.set('project', this.project);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_project", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "projectIsPrivate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  async function createRecordProjectModel(occurrence) {
    try {
      const project = await occurrence.project;
      return new RecordProjectModel({
        project,
        projectIsPrivate: false
      });
    } catch (err) {
      return new RecordProjectModel({
        project: null,
        projectIsPrivate: true
      });
    }
  }
  let RecordProject = (_class2 = class RecordProject extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
    }
    async checkRecordPermissionsChanges(project) {
      const record = this.args.permissionCheckRecord;
      const oldProject = await record.project;
      const isPublic = oldProject ? oldProject.is_public : record.is_public;
      const willBePublic = project ? project.is_public : record.is_public;
      (0, _notifications.clearNotifications)();
      if (isPublic !== willBePublic) {
        const message = `Access.derivedVisibilityChange.${willBePublic ? 'toPublic' : 'toPrivate'}`;
        (0, _notifications.displayNotification)('warning', this.i18n.translate(message), null, 0, false);
      }
    }
    update(sync, project) {
      // Hacky but that's how 2-way binding work. If there are more such
      // places, might want to add a way to pass post-update to bind-relation
      sync(project);
      if (this.args.permissionCheckRecord && !this.args.permissionCheckRecord.isNew) {
        this.checkRecordPermissionsChanges(project);
      }
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "checkRecordPermissionsChanges", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "checkRecordPermissionsChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _class2);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RecordProject);
});