define("plutof/components/access/-view-rights/agent-list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Common::RecordListView
      @records={{@groups}}
      @terminator={{if @usernames.length "," ""}}
      as |group|
  >
      {{profile/groups/group-link group=group}}
  </Common::RecordListView>
  
  <Common::RecordListView @records={{@usernames}} as |username|>
      {{username}}
  </Common::RecordListView>
  
  */
  {
    "id": "nraEp+kR",
    "block": "[[[8,[39,0],null,[[\"@records\",\"@terminator\"],[[30,1],[52,[30,2,[\"length\"]],\",\",\"\"]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"group\"],[[30,3]]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@records\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,4]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"@groups\",\"@usernames\",\"group\",\"username\"],false,[\"common/record-list-view\",\"if\",\"profile/groups/group-link\"]]",
    "moduleName": "plutof/components/access/-view-rights/agent-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});