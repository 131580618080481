define("plutof/components/tables/-list/field-header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th>{{@label}}</th>
  
  */
  {
    "id": "4CzgcKF1",
    "block": "[[[10,\"th\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[\"@label\"],false,[]]",
    "moduleName": "plutof/components/tables/-list/field-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});