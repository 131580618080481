define("plutof/controllers/clipboard/utils/common", ["exports", "@ember/utils", "plutof/utils/reflection"], function (_exports, _utils, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseRecords = parseRecords;
  function parseRecords(property) {
    switch ((0, _utils.typeOf)(property)) {
      case 'object':
        {
          const newObject = {};
          Object.keys(property).without('representation').forEach(key => {
            newObject[key] = parseRecords(property[key]);
          });
          return newObject;
        }
      case 'instance':
        return (0, _reflection.get_record_url)(property);
      case 'array':
        return property.map(parseRecords);
      default:
        return property;
    }
  }
});